import classNames from 'classnames';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import { useSpring, animated } from 'react-spring';
import { useMeasure } from 'react-use';
import { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import useBlockActions from '../../../hooks/useBlockActions';
import { dispatch } from '../../../store';
import { onBlockUpdate, onDeleteBlock } from '../../../store/api/career-page.api';
import { setUpperBlock } from '../../../store/slices/settings.slice';
import Icon from '../Icon';
import CircularLoader from '../../Loader/CircularLoader';
import InformationItemsForm from './Blocks/InformationItems/InformationItemsForm';
import KeyFiguresForm from './Blocks/KeyFigures/KeyFiguresForm';
import PositionForm from './Blocks/Position/PositionForm';
import SlideshowForm from './Blocks/Slideshow/SlideshowForm';
import SubscribersForm from './Blocks/Subscribers/SubscribersForm';
import TextForm from './Blocks/Text/TextForm';
import TextPictureForm from './Blocks/TextPicture/TextPictureForm';
import SubAccountPositionsForm from './Blocks/SubAccountPositions/SubAccountPositionsForm';

const MARGIN_BOTTOM_BUFFER = 30;

const BlockLayout = (props) => {
  const {
    item,
    children,
    label,
    icon,
    actionsArray = ['customize'], // 'customize' | 'delete' | 'duplicate' | 'visibility'
    dragHandleProps = {},
    anySelected = 0,
    testId,
  } = props;

  const { t } = useTranslation();
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [isActionsLoading, setIsActionsLoading] = useState(false);
  const blockActionsElem = useBlockActions();
  const { data, loading, reloadData } = useApiRequestContext();

  const getBlock = (item) => {
    switch (item?.type) {
      case 0:
        return {
          label,
          customizeArray: [
            'headerTextColorHex',
            'subtitleTextColorHex',
            'titleFontSizeScale',
            'subtitleFontSizeScale',
            'textShadowColorHex',
            'overlayColorHex',
          ],
        };

      case 1:
        return {
          label,
          customizeArray: ['backgroundColorHex', 'textColorHex'],
        };

      case 2:
        return {
          label: t('general.positions'),
          actions: ['customize', 'visibility', 'delete'],
          component: <PositionForm item={item} />,
          customizeArray: [
            'backgroundColorHex',
            'headerTextColorHex',
            'subtitleTextColorHex',
            'textColorHex',
            'titleFontSizeScale',
            'subtitleFontSizeScale',
            'bodyFontSizeScale',
          ],
        };

      case 3:
        return {
          label: t('pages.subscriptions'),
          actions: ['customize', 'visibility', 'delete'],
          component: <SubscribersForm item={item} />,
          customizeArray: [
            'backgroundColorHex',
            'headerTextColorHex',
            'textColorHex',
            'titleFontSizeScale',
            'bodyFontSizeScale',
          ],
        };

      case 25:
        return {
          label: t('pages.positions-sub-accounts'),
          actions: ['customize', 'visibility', 'delete'],
          component: <SubAccountPositionsForm item={item} />,
          customizeArray: [
            'backgroundColorHex',
            'headerTextColorHex',
            'subtitleTextColorHex',
            'textColorHex',
            'titleFontSizeScale',
            'subtitleFontSizeScale',
            'bodyFontSizeScale',
          ],
        };

      case 100:
        return {
          label: t('pages.text'),
          actions: ['customize', 'visibility', 'duplicate', 'delete'],
          component: <TextForm item={item} />,
          customizeArray: [
            'backgroundColorHex',
            'headerTextColorHex',
            'subtitleTextColorHex',
            'textColorHex',
            'titleFontSizeScale',
            'subtitleFontSizeScale',
            'bodyFontSizeScale',
          ],
        };

      case 101:
        return {
          label: t('career-page.key-figures'),
          actions: ['customize', 'visibility', 'duplicate', 'delete'],
          component: <KeyFiguresForm item={item} />,
          customizeArray: [
            'backgroundColorHex',
            'headerTextColorHex',
            'subtitleTextColorHex',
            'textColorHex',
            'titleFontSizeScale',
            'subtitleFontSizeScale',
            'bodyFontSizeScale',
          ],
        };

      case 102:
        return {
          label: t('pages.carousel'),
          actions: ['customize', 'visibility', 'duplicate', 'delete'],
          component: <SlideshowForm item={item} />,
          customizeArray: ['backgroundColorHex', 'subtitleFontSizeScale'],
        };

      case 103:
        return {
          label: t('pages.information-items'),
          actions: ['customize', 'visibility', 'duplicate', 'delete'],
          component: <InformationItemsForm item={item} />,
          customizeArray: [
            'backgroundColorHex',
            'headerTextColorHex',
            'subtitleTextColorHex',
            'textColorHex',
            'titleFontSizeScale',
            'subtitleFontSizeScale',
            'bodyFontSizeScale',
          ],
        };

      case 104:
        return {
          label: t('pages.text-picture'),
          actions: ['customize', 'visibility', 'duplicate', 'delete'],
          component: <TextPictureForm item={item} />,
          customizeArray: [
            'backgroundColorHex',
            'headerTextColorHex',
            'subtitleTextColorHex',
            'textColorHex',
            'titleFontSizeScale',
            'subtitleFontSizeScale',
            'bodyFontSizeScale',
          ],
        };

      default:
        return {};
    }
  };

  const block = getBlock(item);

  const actions = useMemo(() => {
    if (block?.actions) {
      return block?.actions;
    }

    return actionsArray;
  }, []);

  const customizeElement = blockActionsElem.customize(async () => {
    dispatch(
      setUpperBlock({
        ...item,
        actionType: 'customize',
        actionLabel: label || block?.label,
        customizeArray: block?.customizeArray,
      })
    );
  });

  const deleteElement = blockActionsElem.delete(async () => {
    setIsActionsLoading(true);
    await onDeleteBlock(data?.details, item, async () => {
      await reloadData();
      data?.renderSuccessNotification();
    });
    setIsActionsLoading(false);
  });

  const duplicateElement = blockActionsElem.duplicate(async () => {
    setIsActionsLoading(true);

    await onBlockUpdate(
      data?.details,
      {
        ...item,
        pageBlockId: 0,
        image: null,
        keyFigures: item?.keyFigures?.map((keyFigure) => ({
          ...keyFigure,
          keyFigureId: 0,
        })),
        carouselItems: [],
        informationItems: [],
        sortOrder: data?.details?.blocks?.length + 1,
      },
      async () => {
        await reloadData();
        data?.renderSuccessNotification();
      }
    );
    setIsActionsLoading(false);
  });

  const visibilityElement = blockActionsElem.toggleVisibility(item?.visible, async () => {
    setIsActionsLoading(true);
    await onBlockUpdate(
      data?.details,
      {
        ...item,
        visible: !item?.visible,
      },
      async () => {
        await reloadData();
        data?.renderSuccessNotification();
      }
    );
    setIsActionsLoading(false);
  });

  const toggleFormState = () => {
    setIsFormHidden(!isFormHidden);
  };

  const [ref, { height }] = useMeasure();
  const [showElement, setShowElement] = useState(false);
  const isAnimating = useRef(null);

  const isShown = useMemo(() => {
    return !isFormHidden && !loading && !anySelected;
  }, [isFormHidden, loading, anySelected]);

  useEffect(() => {
    clearTimeout(isAnimating?.current);
    if (!isShown) {
      isAnimating.current = setTimeout(() => {
        setShowElement(false);
      }, 1000);
    } else {
      setShowElement(true);
    }
  }, [isShown]);

  const styles = useSpring({
    from: {
      height: 0,
    },
    to: {
      height: isShown ? height + MARGIN_BOTTOM_BUFFER : 0,
    },
    onRest: () => {
      return {
        height: 'auto',
      };
    },
  });

  return (
    <>
      <div
        className={classNames(
          'bg-white rounded-sm !pl-3 h-[38px] flex items-center justify-between text-[13px] relative bg-accent-light',
          !dragHandleProps?.onMouseDown && '!mb-[10px]'
        )}
      >
        <div className="flex items-center flex-grow h-full gap-x-2">
          <Tippy content={t('general.drag')} placement="bottom">
            <button
              type="button"
              {...dragHandleProps}
              className="w-[16.25px] flex items-center justify-center cursor-grab"
            >
              {(icon || block?.label) && dragHandleProps?.onMouseDown && (
                <Icon name="grip-vertical" type="fas" color="var(--text-color-secondary)" />
              )}
            </button>
          </Tippy>
          <Tippy content={isFormHidden ? t('general.expand') : t('general.collapse')} placement="right">
            <button
              type="button"
              className="flex items-center h-full flex-grow text-black font-bold"
              onClick={toggleFormState}
              data-testid={testId || 'career-page-block'}
            >
              {label || block?.label} {!item?.visible && `(${t('general.hidden')})`}
            </button>
          </Tippy>
        </div>
        {!!actions?.length && (
          <div className="flex items-center gap-x-3 absolute top-0 bottom-0 right-3 z-10">
            {!isActionsLoading &&
              actions?.map((action) => {
                if (action === 'customize') {
                  return customizeElement;
                }

                if (action === 'delete') {
                  return deleteElement;
                }

                if (action === 'visibility') {
                  return visibilityElement;
                }

                if (action === 'duplicate') {
                  return duplicateElement;
                }

                return null;
              })}
            {isActionsLoading && <CircularLoader color="var(--primary-color)" />}
          </div>
        )}
      </div>
      {showElement && (
        <animated.div style={styles} className="overflow-hidden">
          <div className={classNames('!px-4 !pb-6', dragHandleProps?.onMouseDown ? '!mt-4' : '!mt-1')} ref={ref}>
            {children || block?.component}
          </div>
        </animated.div>
      )}
    </>
  );
};

export default BlockLayout;
