import moment from 'moment';
import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import DraggableList from 'react-draggable-list';
import Icon from '../../../components/@v2/Icon';
import ApiRequestProvider, { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import { dispatch, useSelector } from '../../../store';
import { getCareerPageDetails, onBlockUpdate } from '../../../store/api/career-page.api';
import { selectClient, selectGuid } from '../../../store/selectors/client.selector';
import CareerPageHeader from '../../../components/@v2/CareerPage/CareerPageHeader';
import FooterBlock from '../../../components/@v2/CareerPage/Blocks/Footer/FooterBlock';
import HeaderBlock from '../../../components/@v2/CareerPage/Blocks/Header/HeaderBlock';
import CareerPageFooter from '../../../components/@v2/CareerPage/CareerPageFooter';
import BlockSelector from '../../../components/@v2/CareerPage/BlockSelector';
import BlockLayout from '../../../components/@v2/CareerPage/BlockLayout';
import { setUpperBlock, setIsAddingBlock } from '../../../store/slices/settings.slice';
import GeneralSettings from '../../../components/@v2/CareerPage/Blocks/GeneralSettings/GeneralSettings';
import CustomizeSettings from '../../../components/@v2/CareerPage/Blocks/CustomizeSettings/CustomizeSettings';
import useToast from '../../../hooks/useToast';
import { TYPES } from '../../../constants/CareerPage';

const CareerPage = () => {
  const { t } = useTranslation();
  const draggableContainer = useRef(null);
  const [preview, setPreview] = useState('desktop');
  const activeClient = useSelector(selectClient);
  const clientGuid = useSelector(selectGuid);
  const settings = useSelector((state) => state?.settings?.careerPage);
  const { data, loading: getDetailsLoading, reloadData } = useApiRequestContext();

  const details = data?.details || {};
  const blocks = data?.details?.blocks || [];
  const header = blocks?.find((item) => item?.type === TYPES.HEADER);
  const footer = blocks?.find((item) => item?.type === TYPES.FOOTER);
  const [updatedBlocks, setUpdatedBlocks] = useState([]);

  const loading = getDetailsLoading;

  const blockItems = useMemo(() => {
    if (updatedBlocks?.length) {
      return updatedBlocks;
    }

    return [...blocks]?.filter(
      (item) =>
        item?.type !== TYPES.HEADER &&
        item?.type !== TYPES.FOOTER &&
        !(item?.type === TYPES.SUB_ACCOUNT_POSITIONS && activeClient?.isMainClient === false)
    );
  }, [blocks, updatedBlocks, activeClient]);

  const getStyle = useCallback(() => {
    if (preview === 'mobile') {
      return {
        maxWidth: '375px',
        height: '100%',
      };
    }

    if (preview === 'tablet') {
      return {
        maxWidth: '768px',
        height: '100%',
      };
    }

    if (preview === 'laptop') {
      return {
        maxWidth: '1024px',
        height: '100%',
      };
    }

    return {
      maxWidth: '100%',
      height: '100%',
    };
  }, [preview]);

  const onMoveEnd = async (newList) => {
    const blocks = newList.map((item, index) => {
      item.sortOrder = index + 2;
      return item;
    });

    const finalData = {
      ...data?.details,
      blocks: [header, ...blocks, footer],
    };

    setUpdatedBlocks(blocks);
    await onBlockUpdate(finalData);
    await reloadData();
    data?.renderSuccessNotification();
    setUpdatedBlocks([]);
  };

  useEffect(() => {
    // reset store when the page initializes
    dispatch(setIsAddingBlock(false));
    dispatch(setUpperBlock(null));
  }, []);

  return (
    <div className="flex items-start text-white">
      <div className="w-[350px] flex flex-col h-screen bg-custom-gray-200">
        <CareerPageHeader
          publishedDate={details?.publishedDate}
          publishedBy={details?.publishedBy}
          changedDate={details?.changedDate}
          changedBy={details?.changedBy}
          clientGuid={clientGuid}
          clientKey={activeClient?.key}
        />
        {loading ? (
          <div className="flex-grow flex flex-col !py-8 !px-6">
            {Array(10)
              .fill()
              .map((v, i) => i)
              .map((item) => (
                <div key={item} className="gray-badge bg-accent-light rounded-sm !px-3 h-[38px] !mb-[10px]" />
              ))}
          </div>
        ) : (
          <>
            <div className="scrollbar flex-grow">
              <div className="!py-8 !px-6 text-career-page-block-button-label">
                {settings?.addingBlock && <BlockSelector />}
                {settings?.upperBlock?.actionType === 'settings' && (
                  <div className="animate-fade-to-right">
                    <GeneralSettings />
                  </div>
                )}
                {settings?.upperBlock?.actionType === 'customize' && (
                  <div className="animate-fade-to-right">
                    <CustomizeSettings />
                  </div>
                )}
                {!settings?.addingBlock && !settings?.upperBlock && (
                  <div className="animate-fade-to-right">
                    <button
                      type="button"
                      className="bg-white bg-accent-light rounded-sm !px-3 h-[38px] flex items-center justify-between text-[13px] w-full !mb-[10px]"
                      onClick={() => {
                        dispatch(
                          setUpperBlock({
                            id: 0,
                            actionType: 'settings',
                          })
                        );
                      }}
                      data-testid="career-page-block-general-settings"
                    >
                      <div className="flex items-center gap-x-2 text-black font-bold">
                        <div className="w-[16.25px] flex items-center justify-center">
                          <Icon name="cogs" color="var(--text-color-secondary)" />
                        </div>
                        {t('general.general-settings')}
                      </div>
                    </button>
                    <HeaderBlock />
                    <div className="flex flex-col" ref={draggableContainer}>
                      <DraggableList
                        itemKey="pageBlockId"
                        template={BlockLayout}
                        list={blockItems || []}
                        onMoveEnd={onMoveEnd}
                        container={() => {
                          if (draggableContainer && draggableContainer.current) {
                            return draggableContainer.current;
                          }
                          return false;
                        }}
                      />
                    </div>
                    <FooterBlock />
                  </div>
                )}
              </div>
            </div>
            {!settings?.addingBlock && <CareerPageFooter />}
          </>
        )}
      </div>
      <div
        className="flex items-center justify-center relative h-screen"
        style={{
          width: 'calc(100% - 350px)',
          padding: preview === 'mobile' || preview === 'tablet' ? 20 : null,
          background: '#181a1b',
        }}
      >
        {data?.iframeSrc && (
          <iframe
            title="talent-software"
            src={data?.iframeSrc}
            style={{
              ...getStyle(),
              width: '100%',
            }}
          />
        )}
        <div className="flex items-center gap-x-4 absolute bottom-5 left-1/2 -translate-x-1/2 bg-white px-3 py-2.5 rounded-sm">
          {['mobile', 'tablet', 'laptop', 'desktop'].map((item) => (
            <button key={item} type="button" onClick={() => setPreview(item)} className="grid place-content-center">
              <Icon name={item} color={preview === item ? 'var(--primary-color)' : '#909090'} className="clickable" />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const CareerPageWrapper = () => {
  const { t } = useTranslation();
  const clientGuid = useSelector(selectGuid);
  const iframeUrl = useMemo(() => {
    const origin =
      global?.appConfig?.environment === 'development'
        ? `${global?.appConfig?.external_api_for_cp}/api`
        : global?.appConfig?.api_url;

    return `${origin}/page/${clientGuid}`;
  }, [clientGuid]);
  const { createNotification } = useToast();
  const renderSuccessNotification = () => {
    createNotification('success', t('general.success'), t('general.career-page-preview-updated'));
  };

  const [iframeSrc, setIframeSrc] = useState(iframeUrl);

  const onIframeUpdate = useCallback(() => {
    const datetime = moment().format('YYYY-MM-DD HH:mm:ss');
    setIframeSrc(`${iframeUrl}?datetime=${datetime}`);
  }, [iframeUrl]);

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source, data) => {
        let details = data?.details || {};

        details = await getCareerPageDetails({
          cancelToken: source.token,
        });

        onIframeUpdate();

        return {
          details: {
            ...details,
            blocks: sortBy(details?.blocks || [], 'sortOrder'),
          },
          iframeSrc,
          renderSuccessNotification,
        };
      }}
    >
      <CareerPage />
    </ApiRequestProvider>
  );
};

export default CareerPageWrapper;
