import 'babel-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';
import 'number-to-locale-string-polyfill';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import './assets/sass/base.sass';
import { merge } from 'lodash';
import { init } from './main';

/* eslint-disable import/no-extraneous-dependencies */
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

const environment = process.env.NODE_ENV || 'development';
window.__COMMIT_HASH__ = process.env.__COMMIT_HASH__ || new Date().toDateString();

if (window.Cypress) {
  require('console-log-div');
}

fetch('/config.json')
  .then((res) => res.json())
  .then((data) => {
    if (data) {
      window.appConfig = merge(data, { environment });

      const meta = document.createElement('meta');
      meta.httpEquiv = 'Content-Security-Policy';
      meta.content = window.appConfig.csp_rules;
      document.getElementsByTagName('head')[0].appendChild(meta);

      /* eslint-disable global-require */
      const App = require('./App').default;
      init(App);
    } else {
      throw new Error('App configuration not found');
    }
  })
  .catch((err) => {
    throw new Error(err);
  });
