export const transformApplySettingsToFormCompliantFormat = (settings, refSettings, defaultSettings) => {
  if (!settings || !defaultSettings) {
    return {};
  }

  const selectedSettings = settings || defaultSettings;
  return Object.keys(selectedSettings).reduce((acc, current) => {
    const setting = selectedSettings[current];
    if (setting !== undefined && setting.value === undefined) {
      const foundElement = [...refSettings].find((applySetting) => applySetting.value === setting);

      return {
        ...acc,
        [current]: foundElement,
      };
    }

    return {
      ...acc,
      [current]: setting,
    };
  }, defaultSettings);
};

export const convertApplySettingsToKeyValueFormat = (applySettings) => {
  return applySettings
    ? Object.keys(applySettings).reduce((acc, key) => {
        if (key === 'firstName' || key === 'lastName' || key === 'email') {
          return acc;
        }

        return {
          ...acc,
          [key]: key === 'usePositionSpecificApplyForm' ? applySettings[key] : applySettings[key].value,
        };
      }, {})
    : null;
};
