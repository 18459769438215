import {
  ApplicationInsights,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

let reactPlugin = null;
let appInsights = null;

const createTelemetryService = () => {
  const initialize = (
    instrumentationKey,
    browserHistory,
    connectionString = ''
  ) => {
    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service.');
    }
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided.');
    }

    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        connectionString,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableCorsCorrelation: true,
        correlationHeaderExcludedDomains: ['*.auth0.com'],
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
      },
    }).loadAppInsights();
  };

  return { reactPlugin, appInsights, initialize };
};

// Init
export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;

// Logging
export const logInfo = (name, message) =>
  appInsights &&
  appInsights.trackTrace &&
  appInsights.trackTrace({
    message: name,
    properties: message,
    severityLevel: SeverityLevel.Information,
  });

export const logError = (error) =>
  appInsights &&
  appInsights.trackException &&
  appInsights.trackException({
    error: new Error(error),
    severityLevel: SeverityLevel.Error,
  });
