import { get } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import { getCategory } from '../../helpers/utils';

const CandidateName = ({ details, selectedCandidateCount, activeCandidate }) => {
  const multipleSelected = details?.selected?.length >= 2 || details?.selectAll;
  const { t } = useTranslation();

  const selected = useMemo(() => {
    if (details.selectAll && (get(details, 'unchecked') || []).length === 0) {
      return details.totalCount;
    }
    if (details.selectAll && (get(details, 'unchecked') || []).length !== 0) {
      return details.totalCount - (get(details, 'unchecked') || []).length;
    }
    return details.selected.length || 2;
  }, [details]);

  return (
    <div className="flex items-center flex-grow mb-3">
      {(multipleSelected && selectedCandidateCount > 1) && (
        <h3 style={{ marginBottom: 0 }}>
          {t('candidates.candidate-name.bulk-manage')} -{' '}
          {t('general.number-selected', { selected })}
        </h3>
      )}
      {(!multipleSelected || selectedCandidateCount === 1) && (
        <div className="flex items-center">
          <div className="mr-2 flex items-center">
            <h3 className="text-lg font-semibold mb-0">
              {get(activeCandidate, 'name')}
            </h3>
          </div>
          {getCategory(get(activeCandidate, 'category'))}
          {get(activeCandidate, 'rejected') && (
            <Badge className="ml-1" color="danger">
              {t('candidates.table-header.rejected')}
            </Badge>
          )}
        </div>
      )}
    </div>
  );
};

export default CandidateName;
