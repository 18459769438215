import moment from 'moment';
import i18n from '../i18n';
import { capitalizeFirstLetter } from './utils';

moment.locale(i18n.language);

export const defaultFormat = 'YYYY-MM-DD';

export const defaultDatePickerFormat = 'yyyy-MM-dd';

export const format = (type, date, dateFormat = defaultFormat) => {
  const momentDate = moment(date);
  if (!momentDate.isValid()) return '-';
  if (type === 'date') return momentDate.format(dateFormat);
  return momentDate.format('YYYY-MM-DD HH:mm');
};

export const convertStringToDate = (dateString, dateFormat = defaultFormat) => {
  const date = dateString;
  return moment(new Date(date)).format(dateFormat);
};

export const isValidDate = (date) => {
  if (!date) return false;
  const convertedDate = convertStringToDate(date);
  return moment(convertedDate, defaultFormat, true).isValid();
};

export const isSameOrGreater = (date, dateForComparison = new Date()) => {
  if (!date) return false;
  return moment(date).isSameOrAfter(dateForComparison);
};

export const isAfter = (date1, date2) => moment(date1).isAfter(date2);

export const getReadableDate = (date, humanReadable) => {
  if (humanReadable) {
    const fromNow = moment.utc(date).fromNow();
    return capitalizeFirstLetter(fromNow);
  }

  return moment.utc(date).format('YYYY-MM-DD');
};

export default {};
