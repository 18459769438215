import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import generator from 'generate-password';
import classNames from 'classnames';
import Axios from 'axios';
import { NotificationManager } from 'react-notifications';
import YupPassword from 'yup-password';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import Layout from '../../../../components/layouts/default';
import Button from '../../../../components/Button';
import Field from '../../../../components/@v2/Form/Field';
import {
  addAdminUser,
  checkEmail,
  getAdminUser,
  getUser,
  updateAdminUser,
  updatePassword,
} from '../../../../store/api/user.api';
import { getImageServiceMediaUrl, getConvertedImageUrlString } from '../../../../helpers/image';
import { PermissionLevel, UserLanguage } from '../../../../constants/User';
import ImageDropzone from '../../../../components/input/ImageDropzone';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../../store';
import Icon from '../../../../components/@v2/Icon';
import Table from '../../../../components/@v2/Table/Table';
import Select from '../../../../components/@v2/Select/Select';
import { selectUser } from '../../../../store/selectors/user.selector';
import { setUserData } from '../../../../store/slices/user.slice';
import { setLocale } from '../../../../store/slices/settings.slice';
import Card from '../../../../components/@v2/Card';
import Search from '../../../../components/@v2/Search/Search';

YupPassword(Yup);

const RECRUITER_MANAGER_VALUE = 1;
const PERMISSION_OPTIONS = Object.entries(PermissionLevel)
  .map((value, key) => ({
    label: value[1],
    value: parseInt(key, 10),
  }))
  .filter((item) => item.value !== RECRUITER_MANAGER_VALUE);

const AdminUser = () => {
  const { t } = useTranslation();
  const { loading, data } = useApiRequestContext();
  const history = useHistory();
  const isEditing = !!data?.id;

  const user = useSelectorToolkit(selectUser);
  const CLIENT_OPTIONS = useSelectorToolkit((state) => state?.settings?.options?.clients);
  const [usersInputFilterValue, setUsersInputFilterValue] = useState('');

  const getFullName = () => {
    if (isEditing) {
      return `${data?.firstName} ${data?.lastName}`;
    }

    return null;
  };

  const PASSWORD_SCHEMA = Yup.string()
    .nullable()
    .min(8, t('form-validator.minlength', { length: 8 }))
    .minLowercase(1, t('form-validator.with-lowercase'))
    .minUppercase(1, t('form-validator.with-uppercase'))
    .minNumbers(1, t('form-validator.with-number'));

  const CONFIRM_PASSWORD_SCHEMA = Yup.string()
    .nullable()
    .oneOf([Yup.ref('password'), null], t('form-validator.same-password'));

  const AdminUserSchema = Yup.object().shape({
    firstName: Yup.string().required(t('form-validator.required')),
    lastName: Yup.string().required(t('form-validator.required')),
    email: Yup.string().email().required(t('form-validator.required')),
    users: !isEditing ? Yup.array().min(1, t('form-validator.required')) : Yup.array().optional(),
    password: !isEditing ? PASSWORD_SCHEMA.required(t('form-validator.required')) : PASSWORD_SCHEMA,
    confirmPassword: !isEditing
      ? CONFIRM_PASSWORD_SCHEMA.required(t('form-validator.required'))
      : CONFIRM_PASSWORD_SCHEMA,
  });

  const onSubmit = async (values) => {
    try {
      const formData = {
        ...values,
        language: values?.language?.value || 'en-US',
        users: values.users
          .filter((user) => !!user.id)
          .map((user) => {
            return {
              ...user,
              permissionLevel: user?.permissionLevel?.value,
              clientId: user?.id,
            };
          }),
      };

      if (formData.avatar instanceof File) {
        const imageUrlString = await getConvertedImageUrlString(formData.avatar);

        formData.avatarUpload = {
          filename: formData.avatar?.path,
          content: imageUrlString.split('base64,')[1],
        };
      }

      if (isEditing) {
        let requests = [updateAdminUser(formData)];

        formData.loginId = formData.id;

        if (formData.password) {
          requests = [
            ...requests,
            updatePassword({
              auth0UserId: formData.auth0UserId,
              newPassword: formData.password,
            }),
          ];
        }

        formData.avatar = formData.avatar === null && data?.avatar ? '' : data?.existingAvatar;

        const [userResponse] = await Axios.all(requests);

        if (userResponse) {
          const userData = await getUser();

          dispatchToolkit(
            setUserData({
              ...user,
              ...userData,
              locale: userData.language,
            })
          );

          dispatchToolkit(setLocale(userData.language));
        }
      } else {
        const userExists = await checkEmail(formData?.email);
        if (userExists === true) {
          NotificationManager.error(t('error-message.email-not-unique'));
        } else {
          await addAdminUser(formData);
        }
      }

      history.push('/admin/users');
    } catch (error) {
      throw new Error(error);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Layout
      pretitle={t('general.admin')}
      title={!isEditing ? t('admin.users.add-user') : `${t('general.edit')} ${getFullName()}`}
    >
      <Formik initialValues={data} validationSchema={AdminUserSchema} onSubmit={onSubmit}>
        {({ values, setFieldValue, handleSubmit, isSubmitting, submitCount, errors }) => {
          const PARSED_CLIENT_OPTIONS = CLIENT_OPTIONS.filter((CLIENT_OPTION) => {
            return !values?.users.find((USER) => {
              return CLIENT_OPTION?.id === USER?.id;
            });
          });
          return (
            <form
              onSubmit={handleSubmit}
              className="anim-table-delayed max-w-4xl w-full shadow-sm p-4 bg-white rounded-lg"
            >
              <div className="mb-4 flex justify-center">
                <ImageDropzone
                  avatar
                  value={values?.avatar}
                  onChange={([file]) => {
                    setFieldValue('avatar', file);
                  }}
                  onDeleteAvatar={() => {
                    setFieldValue('avatar', null);
                  }}
                  resource="users-avatar"
                />
              </div>
              <div className="flex items-start gap-x-4">
                <Field required name="firstName" label={t('general.first-name')} testId="users-first-name-input" />
                <Field required name="lastName" label={t('general.last-name')} testId="users-last-name-input" />
              </div>
              <div className="flex items-start gap-x-4">
                <Field
                  required={!isEditing}
                  disabled={isEditing}
                  name="email"
                  label={t('admin.users.email')}
                  testId="users-email-input"
                />
                <Field
                  type="number"
                  name="phoneNumber"
                  label={t('admin.users.phone-number')}
                  testId="users-phone-number-input"
                />
              </div>
              <div className="flex items-start gap-x-4">
                <Field
                  required={!isEditing}
                  type="password"
                  name="password"
                  label={t('admin.users.password')}
                  testId="users-password-input"
                />
                <Field
                  required={!isEditing}
                  type="password"
                  name="confirmPassword"
                  label={t('admin.users.confirm-password')}
                  testId="users-confirm-password-input"
                />
              </div>
              <div className="flex items-start gap-x-4">
                <Field
                  className="!w-1/2 pr-2"
                  type="select"
                  defaultOptions={UserLanguage}
                  name="language"
                  label={t('general.language')}
                  testId="users-language-select"
                />
              </div>
              <div
                className={classNames('flex gap-x-4', {
                  'mb-4': !values?.generatedPassword,
                })}
              >
                <Button
                  className="whitespace-nowrap"
                  onClick={() => {
                    const password = generator.generate({
                      length: 10,
                      numbers: true,
                      symbols: true,
                      strict: true,
                    });
                    setFieldValue('generatedPassword', password);
                    setFieldValue('password', password);
                    setFieldValue('confirmPassword', password);
                  }}
                  data-testid="users-generate-password-button"
                >
                  {t('general.generate-password')}
                </Button>
                {values?.generatedPassword && (
                  <Field
                    name="generatedPassword"
                    className="inline-flex !w-auto"
                    testId="users-generate-password-input"
                  />
                )}
              </div>
              {!data?.superAdmin && (
                <div>
                  <label className="block" htmlFor="accountAccess">
                    {t('general.add-account-access')} *
                  </label>
                  <div className="flex gap-x-4">
                    <Field
                      type="select"
                      defaultOptions={PARSED_CLIENT_OPTIONS}
                      name="accountAccess"
                      keys={['id', 'name']}
                      overrideError={submitCount && errors?.users && !values?.users?.length ? errors?.users : ''}
                      testId="users-account-access-select"
                    />
                    <Button
                      className="whitespace-nowrap"
                      color="primary"
                      disabled={!values?.accountAccess}
                      onClick={() => {
                        if (values?.accountAccess) {
                          setFieldValue('users', [
                            ...values?.users,
                            {
                              ...values?.accountAccess,
                              userId: 0,
                              deleted: false,
                              visible: true,
                              permissionLevel: PERMISSION_OPTIONS[0],
                            },
                          ]);
                        }
                      }}
                      data-testid="users-account-access-button"
                    >
                      {t('general.add')}
                    </Button>
                  </div>
                </div>
              )}
              <Card className="anim-table-delayed mb-4 !shadow-none !border">
                <Search
                  placeholder={t('general.search-using', { resource: 'name' })}
                  resource="email"
                  withPageSize={false}
                  onChange={(value) => {
                    setUsersInputFilterValue(value);
                  }}
                  debounceTimeout={0}
                />
                <Table
                  isLoaded
                  items={
                    values?.users?.filter(
                      (item) =>
                        !usersInputFilterValue || item?.clientName?.toLowerCase()?.includes(usersInputFilterValue)
                    ) || []
                  }
                  headers={[
                    {
                      label: t('general.name'),
                      key: 'name',
                      sort: false,
                    },
                    {
                      label: t('general.select-access-level'),
                      key: 'access-level',
                      sort: false,
                      width: 400,
                    },
                    {
                      label: t('general.actions'),
                      key: 'actions',
                      sort: false,
                      sticky: true,
                      headerClassName: 'text-center',
                      width: 1,
                    },
                  ]}
                  renderBody={(item, index) => {
                    return (
                      <tr key={JSON.stringify(item)} data-testid={`tableBody-${index}`}>
                        <td data-testid={`users-td-${index}-0`}>{item?.name}</td>
                        <td data-testid={`users-td-${index}-1`}>
                          <Select
                            searchable
                            searchKey="label"
                            defaultOptions={PERMISSION_OPTIONS}
                            value={[item?.permissionLevel]}
                            onSelect={([value]) => {
                              setFieldValue(
                                'users',
                                values?.users?.map((user) => {
                                  if (user?.id === item?.id) {
                                    return {
                                      ...user,
                                      permissionLevel: value,
                                    };
                                  }

                                  return user;
                                })
                              );
                            }}
                            id={`permission-level-${index}`}
                            resource={`user-permission-level-${index}`}
                          />
                        </td>
                        <td className="column-sticky" data-testid={`users-td-${index}-2`}>
                          <div className="flex justify-center gap-x-4">
                            <button
                              type="button"
                              data-testid={`user-visible-button-${index}`}
                              id={`btn-visible-${index}`}
                              onClick={() => {
                                setFieldValue(
                                  'users',
                                  values?.users?.map((user) => {
                                    if (user?.id === item?.id) {
                                      return {
                                        ...user,
                                        visible: !item?.visible,
                                      };
                                    }

                                    return user;
                                  })
                                );
                              }}
                            >
                              <Icon name={item.visible ? 'eye' : 'eye-slash'} />
                            </button>
                            <button
                              type="button"
                              data-testid={`user-deleted-button-${index}`}
                              id={`btn-deleted-${index}`}
                              onClick={() => {
                                setFieldValue(
                                  'users',
                                  values?.users?.map((user) => {
                                    if (user?.id === item?.id) {
                                      return {
                                        ...user,
                                        deleted: !item?.deleted,
                                      };
                                    }

                                    return user;
                                  })
                                );
                              }}
                            >
                              <Icon name={item.deleted ? 'archive-restore' : 'trash'} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }}
                />
              </Card>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                data-testid="users-submit-button"
              >
                {t('general.submit')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

const AdminUserFormWrapper = () => {
  const { type, id } = useParams();
  const CLIENT_OPTIONS = useSelectorToolkit((state) => state?.settings?.options?.clients);

  const DEFAULT_USER_FORM = {
    auth0UserId: null,
    loginId: 0,
    avatar: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    language: UserLanguage[0],
    password: '',
    confirmPassword: '',
    users: [],
  };

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source) => {
        if (type === 'edit' && id) {
          const response = await getAdminUser(id, {
            cancelToken: source.token,
          });

          return {
            ...DEFAULT_USER_FORM,
            ...response,
            avatar: response?.avatar
              ? getImageServiceMediaUrl({
                  ...response?.avatar,
                  // avatar id being returned by api isn't used in image generation
                  name: response?.id,
                })
              : null,
            existingAvatar: response?.avatar,
            language: UserLanguage.find((item) => item.value === response?.language),
            users: (response?.users || []).map((item) => {
              return {
                ...item,
                ...CLIENT_OPTIONS?.find((client) => client.id === item.clientId),
                permissionLevel: PERMISSION_OPTIONS.find((level) => level?.value === item?.permissionLevel),
              };
            }),
          };
        }

        return DEFAULT_USER_FORM;
      }}
    >
      <AdminUser />
    </ApiRequestProvider>
  );
};

export default AdminUserFormWrapper;
