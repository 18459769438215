const pipelineStepsOptions = [
  'comments',
  'phone',
  'microphone-alt',
  'building',
  'calendar-alt',
  'coffee',
  'keyboard',
  'mobile-android-alt',
  'search',
  'trophy',
  'inbox',
];

export const pipelineSteps = pipelineStepsOptions.map((option) => {
  return {
    value: option,
    label: option,
    icon: option,
  };
});

export const pipelineStepTabs = (t, hasAssessments) => {
  const steps = [
    {
      id: 1,
      name: 'pipeline-information',
      label: t('general.pipeline-information'),
    },
    {
      id: 2,
      name: 'test-event',
      label: t('general.test-event'),
    },
  ];

  if (hasAssessments) {
    return steps;
  }

  return [steps[0]];
};

export default {
  pipelineSteps,
};
