import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import { CancelToken } from 'axios';
import { truncate, omit, isEmpty, sortBy } from 'lodash';
import { NotificationManager } from 'react-notifications';
import events from '../helpers/events';
import { log } from '../helpers/utils';
import { useAPI } from '../context/api';
import { AmsProductFields } from '../constants/Product';
import Layout from '../components/layouts/default';
import Product from '../components/Product';
import Button from '../components/Button';
import SelectV2 from '../components/@v2/Select/Select';
import { addItem } from '../store/slices/cart.slice';
import SpecialOffer from '../components/products/SpecialOffer';
import ProductLoader from '../components/ProductLoader';
import { SalaryType } from '../constants/Position';
import { setProductsLastViewed } from '../store/slices/settings.slice';
import { getClient } from '../store/thunks/client.thunk';
import { setAmsGroups } from '../store/thunks/settings.thunks';
import { selectUser } from '../store/selectors/user.selector';
import { selectAmsGroups } from '../store/selectors/settings.selector';
import {
  dispatch as dispatchToolkit,
  useSelector as useSelectorToolkit,
} from '../store';

const StyledProduct = styled(Product)`
  overflow: hidden;
`;

const shop = () => {
  const { t } = useTranslation();
  const [productAPI] = useAPI('products');
  const [isFetching, setIsFetching] = useState(false);
  const [products, setProducts] = useState([]);
  const [toggledAmsProduct, setToggledAmsProduct] = useState([]);
  const [isValidating, setIsValidating] = useState(false);
  const currentUser = useSelectorToolkit(selectUser);
  const amsGroups = useSelectorToolkit(selectAmsGroups);
  const testId = 'shop-page';
  const AmsProductType = 100;

  const amsFieldValues = AmsProductFields(t).map(({ value }) => value);

  const salaryOptions = useMemo(
    () =>
      SalaryType.map((item) => ({
        ...item,
        label: t(item.label),
      })),
    [SalaryType]
  );

  const amsCategoryOptions = useMemo(
    () =>
      (amsGroups || []).map((item) => ({
        value: item.name,
        label: item.name,
        groups: item.groups,
      })),
    [amsGroups]
  );

  const toggledAmsIDs = useMemo(
    () => (toggledAmsProduct || []).map(({ id }) => id),
    [toggledAmsProduct]
  );

  const getProducts = async (source) => {
    try {
      setIsFetching(true);
      const { items } = await productAPI.getShopProducts(
        {
          filters: 'isActive==true',
          sorts: '-id',
          pageSize: -1,
        },
        {
          cancelToken: source.token,
        }
      );

      setProducts(items);
    } catch (error) {
      log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const getClientData = async () => {
    try {
      const { clientId, superAdmin } = currentUser;
      await dispatchToolkit(
        getClient({
          superAdmin,
          id: clientId,
        })
      );
    } catch (error) {
      log(error);
    }
  };

  const pushToCart = (item) => {
    dispatchToolkit(addItem({ ...item, key: uuidv4() }));
    events.$emit('cart--toggle-popover', true);
  };

  useEffect(() => {
    getClientData();
    const source = CancelToken.source();
    getProducts(source);
    dispatchToolkit(setAmsGroups());
    dispatchToolkit(setProductsLastViewed('/shop'));

    return () => {
      source.cancel();
    };
  }, []);

  const isInvalidAmsProduct = useCallback(
    (key, id) => {
      if (!key || !id) return false;
      const amsProduct = toggledAmsProduct.find((item) => item.id === id);
      const foundField = amsProduct?.customFields[key];
      return isValidating === true && (!foundField || foundField?.value === '');
    },
    [toggledAmsProduct, isValidating]
  );

  const updateProductCustomField = useCallback(
    (key, value, selectedProduct) => {
      setToggledAmsProduct(
        toggledAmsProduct.length
          ? [...toggledAmsProduct].map((item) => {
              if (item?.id === selectedProduct.id) {
                const getAmsValue = (keys) =>
                  AmsProductFields(t)
                    .filter((item) => (keys || []).includes(item.value))
                    .map(({ value }) => value);
                const mappedItem = {
                  ...item,
                  customFields: {
                    ...item.customFields,
                    type: AmsProductType,
                    [key]: value,
                  },
                };
                const fieldsForRemoval =
                  key === 'amsOccupationOptions'
                    ? getAmsValue(['occupationNameId'])
                    : getAmsValue(['amsOccupationOptions', 'occupationNameId']);

                return key === 'occupationNameId' || key === 'salaryType'
                  ? mappedItem
                  : {
                      ...mappedItem,
                      customFields: omit(
                        mappedItem.customFields,
                        fieldsForRemoval
                      ),
                    };
              }
              return item;
            })
          : [selectedProduct]
      );
    },
    [toggledAmsProduct]
  );

  const getAmsGroupOptions = useCallback(
    (productId) => {
      if (!toggledAmsProduct || !productId) return [];
      const amsProduct = toggledAmsProduct.find(({ id }) => id === productId);
      return amsProduct !== undefined
        ? amsProduct?.customFields?.amsGroupOptions?.groups?.map((group) => ({
            label: group.name,
            value: group.name,
            occupations: group.occupations,
          }))
        : [];
    },
    [toggledAmsProduct]
  );

  const getAmsOccupationOptions = useCallback(
    (productId) => {
      if (!toggledAmsProduct || !productId) return [];
      const amsProduct = toggledAmsProduct.find(({ id }) => id === productId);
      return amsProduct !== undefined
        ? amsProduct?.customFields?.amsOccupationOptions?.occupations?.map(
            (group) => ({
              label: group.name,
              value: group.id,
            })
          )
        : [];
    },
    [toggledAmsProduct]
  );

  const renderProductContent = (product, index) => {
    const updatedProduct = {
      ...product,
      customFields: {},
    };
    const displayBtnText = () => {
      const defaultText = product.isBoostAuto
        ? t('general.already-included')
        : t('general.add-to-cart');

      return product.ctaText || defaultText;
    };

    const setAMSProductFields = (key, value) =>
      updateProductCustomField(key, value, updatedProduct);

    const defaultAmsFieldValue = (key) => {
      const foundField = toggledAmsProduct.find(({ id }) => id === product.id);
      if (!foundField) return [];
      return foundField?.customFields[key] || [];
    };

    return (
      <>
        {product.specialOffer && !toggledAmsIDs.includes(product.id) && (
          <SpecialOffer>
            {truncate(product.specialOffer, { length: 10 })}
          </SpecialOffer>
        )}
        {toggledAmsIDs.includes(product.id) && (
          <div className="d-flex flex-column flex-grow-1">
            <p className="product-title mb-4">{t('general.publish-on-ams')}</p>
            <p className="text-base">{t('general.occupation')}</p>
            <div className="mb-4">
              {/* TODO: Needs to be refactored inside a formik form */}
              <SelectV2
                label={AmsProductFields(t)[0].label}
                searchable={false}
                onSelect={([selected]) => {
                  setAMSProductFields('amsGroupOptions', selected);
                }}
                defaultOptions={amsCategoryOptions}
                className="mb-2"
                id="amsGroupOptions"
                resource="amsGroupOptions"
                invalid={isInvalidAmsProduct('amsGroupOptions', product.id)}
              />
              <SelectV2
                label={AmsProductFields(t)[1].label}
                searchable={false}
                onSelect={([selected]) => {
                  setAMSProductFields('amsOccupationOptions', selected);
                }}
                defaultOptions={getAmsGroupOptions(product.id)}
                className="mb-2"
                id="amsOccupationOptions"
                resource="amsOccupationOptions"
                value={defaultAmsFieldValue('amsOccupationOptions')}
                invalid={isInvalidAmsProduct(
                  'amsOccupationOptions',
                  product.id
                )}
                disabled={
                  !(toggledAmsProduct || []).find(({ id }) => id === product.id)
                    ?.customFields?.amsGroupOptions
                }
              />
              <SelectV2
                label={AmsProductFields(t)[2].label}
                searchable={false}
                onSelect={([selected]) => {
                  setAMSProductFields('occupationNameId', selected?.value);
                }}
                defaultOptions={getAmsOccupationOptions(product.id)}
                className="mb-2"
                id="occupationNameId"
                resource="occupationNameId"
                value={defaultAmsFieldValue('occupationNameId')}
                invalid={isInvalidAmsProduct('occupationNameId', product.id)}
                disabled={
                  !(toggledAmsProduct || []).find(({ id }) => id === product.id)
                    ?.customFields?.amsOccupationOptions
                }
              />
              <p className="text-base text-color-secondary mt-4">
                {`${t('edit-position.salary')} ${t('general.type')}`}
              </p>
              <SelectV2
                label={`${t('general.select')} ${t('edit-position.salary')}`}
                searchable={false}
                onSelect={([selected]) => {
                  setAMSProductFields('salaryType', selected?.value);
                }}
                defaultOptions={salaryOptions}
                className="mb-2"
                id="amsSalaryType"
                resource="amsSalaryType"
                value={defaultAmsFieldValue('salaryType')}
                invalid={isInvalidAmsProduct('salaryType', product.id)}
              />
            </div>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-center">
          <Button
            data-testid={`${testId}-addToCart-button-${index}`}
            color="success"
            className="btn-rounded px-4"
            outline
            size="xs"
            onClick={() => {
              setIsValidating(true);
              if (
                updatedProduct.adTypes.includes(AmsProductType) &&
                !toggledAmsIDs.includes(updatedProduct.id)
              ) {
                const newAmsProduct = { ...updatedProduct, isAms: true };
                setToggledAmsProduct([...toggledAmsProduct, newAmsProduct]);
                setIsValidating(false);
                return;
              }

              const foundAmsProduct =
                toggledAmsProduct.length &&
                toggledAmsProduct.find(({ id }) => id === product.id);

              if (
                foundAmsProduct &&
                (isEmpty(foundAmsProduct?.customFields) ||
                  amsFieldValues.filter((item) =>
                    isInvalidAmsProduct(item, product.id)
                  ).length >= 1)
              ) {
                NotificationManager.warning(
                  t('error-message.field-is-required')
                );
                return;
              }

              if (product.externalLink) {
                let externalUrl = product.externalLink;
                if (!externalUrl.match(/^https?:\/\//i)) {
                  externalUrl = `http://${externalUrl}`;
                }
                const win = window.open(externalUrl, '_blank');
                if (win != null) win.focus();
              } else {
                pushToCart(
                  toggledAmsProduct.length
                    ? toggledAmsProduct.find(
                        (item) => item.id === updatedProduct.id
                      )
                    : { ...updatedProduct }
                );
              }
            }}
            type="button"
          >
            {product.adTypes.includes(AmsProductType) &&
            !toggledAmsIDs.includes(product.id)
              ? t('general.configure')
              : displayBtnText()}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Layout
      testId={testId}
      title={t('general.shop')}
      data-testid={`${testId}-layout`}
    >
      {!isFetching && (
        <Row className="flex-wrap">
          {products.length ? (
            sortBy(products, 'sortOrder').map((item, index) => (
              <div key={item.id}>
                <StyledProduct
                  key={item.id}
                  title={
                    toggledAmsIDs ? t('general.publish-on-ams') : item.title
                  }
                  {...item}
                  isPromoted
                  toggledAmsProduct={toggledAmsIDs}
                  className="product-promoted"
                  data-testid={`${testId}-addToCart-${index}`}
                >
                  {renderProductContent(item, index)}
                </StyledProduct>
              </div>
            ))
          ) : (
            <p style={{ padding: '5px 15px' }}>
              {t('edit-position.no-products-available')}
            </p>
          )}
        </Row>
      )}
      {isFetching && <ProductLoader />}
    </Layout>
  );
};

export default shop;
