import { api } from '../helpers/api';

const resource = 'Mail';

export default {
  sendEmail: (body = {}, options = {}) =>
    api.post(
      `${resource}/SendMail`,
      {
        delaySendHours: 0,
        type: 'text/html',
        ...body,
      },
      {
        ...options,
      }
    ),
};
