import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import styled from 'styled-components';
import classnames from 'classnames';
import Icon from '../@v2/Icon';
import Layout from './default';
import Button from '../Button';
import { log } from '../../helpers/utils';
import { selectUser } from '../../store/selectors/user.selector';
import { useSelector as useSelectorToolkit } from '../../store';

const PageContainer = styled(Card)`
  max-width: 870px;
`;

const SchedulerLayout = ({
  title,
  footer,
  bodyClassName,
  children,
  syncing,
}) => {
  const { t } = useTranslation();
  const { email, hasToken } = useSelectorToolkit(selectUser);
  const parsedTitle = useMemo(
    () => (
      <div className="d-flex">
        {title.map((item, index) => {
          if (index === 0) {
            return (
              <h3 key={item} className="mb-0">
                {t(item)}
              </h3>
            );
          }

          return (
            <>
              <Icon name="chevron-right" className="ml-3 mr-3" />
              <h3 className="mb-0">{t(item)}</h3>
            </>
          );
        })}
      </div>
    ),
    [title]
  );

  const authorizeToNylas = () => {
    try {
      window.openRef = window.open(
        `https://ireland.api.nylas.com/oauth/authorize?client_id=${global?.appConfig?.nylas_client_id}&redirect_uri=${global?.appConfig?.nylas_redirect_uri}&response_type=code&scopes=calendar,email.send,email.read_only&login_hint=${email}`
      );

      setTimeout(() => {
        window.openRef.close();
      }, 500);
    } catch (error) {
      log(error);
    }
  };

  const rightContent = useMemo(() => {
    if (syncing) return null;

    if (!hasToken) {
      return (
        <Button
          className="btn-rounded"
          color="primary"
          data-testid="position--newPosition"
          onClick={async () => {
            authorizeToNylas();
          }}
          id="sync-button"
        >
          <Icon name="sync-alt" />
          {t('general.sync-calendar')}
        </Button>
      );
    }

    return (
      <Alert
        color="success"
        style={{
          padding: '0.35rem 0.75rem',
          fontSize: 13,
          margin: 0,
        }}
      >
        {t('general.connected')}
      </Alert>
    );
  }, [hasToken]);

  return (
    <Layout
      pretitle={t('general.settings')}
      title={t('pages.scheduler')}
      className="users-page"
      rightContent={rightContent}
    >
      <Row
        className={classnames({
          'flex-grow-1': syncing,
          'anim-table-delayed': !syncing,
        })}
      >
        <Col>
          {!syncing && (
            <PageContainer>
              <CardHeader>{parsedTitle}</CardHeader>
              <CardBody className={bodyClassName}>{children}</CardBody>
              {footer && <CardFooter>{footer}</CardFooter>}
            </PageContainer>
          )}
          {syncing && children}
        </Col>
      </Row>
    </Layout>
  );
};

SchedulerLayout.defaultProps = {
  title: ['pages.scheduling-pages'],
  bodyClassName: 'pb-0',
  footer: null,
  syncing: false,
};

export default SchedulerLayout;
