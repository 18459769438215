import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import uuidv4 from 'uuid/v4';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledDraggableContainer = styled.div`
  div {
    left: inherit !important;
  }
`;

const DraggableList = ({
  items,
  component: Component,
  callback,
  onDragMarginBottom,
  ...rest
}) => {
  const { t } = useTranslation();
  const testId = 'draggableList';
  const onDragEnd = ({ destination, draggableId }) => {
    if (!destination || !callback) return;

    const currentItems = [...items];
    const newList = [...items].filter((item) => item.uuid !== draggableId);
    const currentItem = currentItems.find((item) => item.uuid === draggableId);

    newList.splice(destination.index, 0, currentItem);

    callback(newList);
  };

  if (items.length === 0) {
    return <p>{t('general.no-items-found')}</p>;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`droppable-${uuidv4()}`}>
        {(provided, snapshot) => (
          <StyledDraggableContainer
            ref={provided.innerRef}
            style={{
              marginBottom: snapshot.isDraggingOver
                ? onDragMarginBottom ?? 76
                : 0,
            }}
          >
            {items.map((item, index) => (
              <Draggable
                key={item.uuid}
                draggableId={item.uuid.toString()}
                index={index}
              >
                {(provided) => (
                  <div
                    data-testid={`${testId}--drag--${index}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <Component
                      item={item}
                      provided={provided}
                      callback={callback}
                      items={items}
                      {...rest}
                    />
                  </div>
                )}
              </Draggable>
            ))}
          </StyledDraggableContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
