import React from 'react';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';

const StyledProductLoader = styled.div`
  height: 500px;
  width: 300px;
  border-radius: 0.5rem;
  background: white;
  margin: 0 15px 1.5rem;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  padding: 35px 1.5em;
  display: flex;
  flex-direction: column;
`;

const StyledProductLoaderTitle = styled.div`
  margin: 0 auto 25px;
  height: 28px;
  width: 150px;
  border-radius: 4px;
  background: #c5c5c5;
`;

const StyledProductLoaderDesc = styled.div`
  margin: 0 auto 10px;
  height: 18px;
  width: ${(props) => `${props.width}px`};
  border-radius: 4px;
  background: #d8d8d8;
`;

const StyledProductLoaderPriceContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
`;

const StyledProductLoaderPrice = styled.div`
  width: 80px;
  height: 20px;
  background: #cacaca;
  border-radius: 4px;
  margin-left: ${(props) => `${props.marginLeft || 0}px`};
`;

const StyledProductLoaderIconContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

const StyledProductLoaderIcon = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 18px;
  margin-right: 10px;
  background: #d8d8d8;
`;

const StyledProductLoaderIconName = styled.div`
  height: 18px;
  width: 90px;
  background: #d8d8d8;
  border-radius: 4px;
`;

const StyledProductLoaderButtonContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const StyledProductLoaderButton = styled.div`
  height: 30px;
  width: 98px;
  border-radius: 30px;
  background: #b7b7b7;
`;

const ProductLoader = () => (
  <Row className="flex-wrap anim-table-delayed">
    {new Array(10).fill(0).map(() => (
      <StyledProductLoader key={uuidv4()}>
        <StyledProductLoaderTitle />
        <StyledProductLoaderDesc width={240} />
        <StyledProductLoaderDesc width={180} />
        <StyledProductLoaderDesc width={100} />
        <StyledProductLoaderPriceContainer>
          <StyledProductLoaderPrice />
          <StyledProductLoaderPrice marginLeft={20} />
        </StyledProductLoaderPriceContainer>
        <StyledProductLoaderIconContainer>
          <StyledProductLoaderIcon />
          <StyledProductLoaderIconName />
        </StyledProductLoaderIconContainer>
        <StyledProductLoaderIconContainer>
          <StyledProductLoaderIcon />
          <StyledProductLoaderIconName />
        </StyledProductLoaderIconContainer>
        <StyledProductLoaderIconContainer>
          <StyledProductLoaderIcon />
          <StyledProductLoaderIconName />
        </StyledProductLoaderIconContainer>
        <StyledProductLoaderButtonContainer>
          <StyledProductLoaderButton />
        </StyledProductLoaderButtonContainer>
      </StyledProductLoader>
    ))}
  </Row>
);

export default ProductLoader;
