import qs from 'query-string';
import helper, { api } from '../helpers/api';

const resource = 'products';

export default {
  ...helper(resource, 'restful'),
  getProducts: (filters = {}, options = {}) => api.get(`${resource}?${qs.stringify(filters)}`, options),
  getShopProducts: (filters = {}, options = {}) => api.post(`${resource}/shop`, filters, options),
  addProduct: (payload = {}) => api.post(`${resource}/Add`, payload),
  getCustomProducts: (filters = {}, options = {}) => api.post(`${resource}/custom-fields/search`, filters, options),
  addCustomProduct: (payload = {}) => api.post(`${resource}/custom-fields/`, payload),
  updateCustomProduct: (payload = {}) => api.put(`${resource}/custom-fields/${payload.customProductId}`, payload),
  deleteCustomProduct: (id) => api.delete(`${resource}/custom-fields/${id}`),
};
