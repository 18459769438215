import Login from '../pages/login';
import ForgotPassword from '../pages/forgot-password';
import ChangePassword from '../pages/change-password';
import SelectClient from '../pages/select-client';
import Positions from '../pages/@v2/positions/Positions';
import PositionsAction from '../pages/positions/action-position';
import TemplateSelector from '../pages/template-selector';
import Candidates from '../pages/@v2/candidates/Candidates';
import CandidatesAction from '../pages/@v2/candidates/CandidatesForm';
import CareerPage from '../pages/@v2/career-page/CareerPage';
import FAQ from '../pages/faq';
import Checkout from '../pages/checkout';
import Cart from '../pages/cart';
import Shop from '../pages/shop';
import SettingsTemplates from '../pages/settings/templates';
import SettingsPositionTemplates from '../pages/settings/position-templates/action';
import SettingsLocation from '../pages/@v2/settings/location/Location';
import SettingsLocationAction from '../pages/@v2/settings/location/LocationForm';
import SettingsProfile from '../pages/@v2/settings/profile/Profile';
import SettingsCareerPage from '../pages/@v2/settings/career-page/SettingsCareerPageForm';
import SettingsPipeline from '../pages/@v2/settings/pipelines/Pipelines';
import SettingsPipelineActionTemplate from '../pages/@v2/settings/pipelines/PipelineTemplateForm';
import SettingsPipelineActionStep from '../pages/@v2/settings/pipelines/PipelineStepForm';
import SettingsUsers from '../pages/@v2/settings/users/User';
import SettingsUsersForm from '../pages/@v2/settings/users/UserForm';
import SettingsDepartment from '../pages/@v2/settings/departments/Department';
import SettingsDepartmentAction from '../pages/@v2/settings/departments/DepartmentForm';
import SettingsSubscriptions from '../pages/@v2/settings/subscriptions/Subscriptions';
import AdminFacebook from '../pages/@v2/admin/facebook/Facebook';
import AdminFacebookAction from '../pages/@v2/admin/facebook/FacebookForm';
import AdminOrders from '../pages/@v2/admin/order/Order';
import AdminOrdersView from '../pages/admin/orders/view';
import AdminClients from '../pages/@v2/admin/clients/Client';
import AdminClientsActionTemplate from '../pages/@v2/admin/clients/ClientActions';
import AdminUsers from '../pages/@v2/admin/user/User';
import AdminUsersForm from '../pages/@v2/admin/user/UserForm';
import AdminCustomFont from '../pages/@v2/admin/custom-font/CustomFontForm';
import AdminProducts from '../pages/@v2/admin/products/Product';
import AdminProductsForm from '../pages/@v2/admin/products/ProductForm';
import AdminProductsView from '../pages/admin/products/view';
import AdminProductTranslation from '../pages/admin/products/product-translation';
import Analytics from '../pages/analytics';
import Gdpr from '../pages/@v2/settings/GdprForm';
import SettingsScreeningQuestion from '../pages/@v2/settings/screening-questions/ScreeningQuestions';
import SettingsScreeningQuestionController from '../pages/settings/screening-questions/controller';
import SettingsScheduler from '../pages/settings/scheduler';
import SettingsSchedulerAction from '../pages/settings/scheduler/action';
import AddIntegrationForm from '../pages/@v2/settings/integrations/AddIntegration/AddIntegrationForm';
import EditRefappForm from '../pages/@v2/settings/integrations/EditIntegration/EditRefappForm';
import EditAmsForm from '../pages/@v2/settings/integrations/EditIntegration/EditAmsForm';
// TODO: change these to correct convention
// import UsersTable from '../pages/settings/users';
import NylasCallback from '../pages/settings/scheduler/callback';
import IntegrationsPage from '../pages/@v2/settings/integrations/Integrations';
import CandidateView from '../pages/@v2/candidates/CandidateView';
import EditAlvaLabsForm from '../pages/@v2/settings/integrations/EditIntegration/EditAlvaLabsForm';
import EditAssessioForm from '../pages/@v2/settings/integrations/EditIntegration/EditAssessioForm';
import EditSriForm from '../pages/@v2/settings/integrations/EditIntegration/EditSriForm';
// import UnderConstruction from '../pages/UnderConstruction';

const hasAccessEmails = [
  'mattias.eklund@weselect.com',
  'amy.dyvelcrona@weselect.com',
  'rickard.rydstrom@weselect.com',
  'emilia.eriksson@weselect.com',
  'edvin.haggstrom@weselect.com',
  'jonathan.persosn@weselect.com',
  'piotr.wnek@euvic.pl',
  'michal.sawzdargo@euvic.pl',
  'bryanpampola@pinion.ph',
  'aurezzadunque@pinion.ph',
  'manueluntalan@pinion.ph',
  'hermanluna@pinion.ph',
  'facebook@weselect.com',
  'piotr@hasooni.com',
  'pawel@klimczyk.pl',
  'pawel@spock.se',
];

export const emailHasAccessToPage = (email) => hasAccessEmails.includes(email);

export const UnauthorizeRoutes = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/change-password',
    component: ChangePassword,
  },
];

export const LoggedInUserMenu = [
  {
    path: '/',
    component: Positions,
  },
  {
    path: '/select-client',
    component: SelectClient,
  },
  {
    path: '/analytics',
    component: Analytics,
  },
  {
    path: '/shop',
    component: Shop,
  },
  {
    path: '/positions',
    component: Positions,
  },
  {
    path: '/positions/:type(add|edit)/:id?',
    component: PositionsAction,
  },
  {
    path: '/template-selector',
    component: TemplateSelector,
  },
  {
    path: '/candidates',
    component: Candidates,
  },
  {
    path: '/candidates/view/:id',
    component: CandidateView,
  },
  {
    path: '/candidates/:type(add|edit)/:id?',
    component: CandidatesAction,
  },
  {
    path: '/career-page',
    component: CareerPage,
    permission: [2],
  },
  {
    path: '/faq',
    component: FAQ,
  },
  {
    path: '/checkout',
    component: Checkout,
  },
  {
    path: '/cart',
    component: Cart,
  },
  {
    path: '/settings/profile',
    component: SettingsProfile,
  },
  {
    path: '/settings/career-pages',
    component: SettingsCareerPage,
    permission: [2],
  },
  {
    path: '/settings/location/:type(add|edit)/:id?',
    component: SettingsLocationAction,
    permission: [2],
  },
  {
    path: '/settings/location',
    component: SettingsLocation,
    permission: [2],
  },
  {
    path: '/settings/subscriptions',
    component: SettingsSubscriptions,
    permission: [1, 2],
  },
  {
    path: '/settings/departments',
    component: SettingsDepartment,
    permission: [2],
  },
  {
    path: '/settings/departments/:type(add|edit)/:id?',
    component: SettingsDepartmentAction,
    permission: [2],
  },
  {
    path: '/settings/templates',
    component: SettingsTemplates,
    permission: [1, 2],
  },
  {
    path: '/settings/integrations',
    component: IntegrationsPage,
    permission: [2],
  },
  {
    path: '/settings/templates/position/:type(add|edit)/:id?',
    component: SettingsPositionTemplates,
    permission: [1, 2],
  },
  {
    path: '/settings/screening-questions',
    component: SettingsScreeningQuestion,
    permission: [1, 2],
  },
  {
    path: '/settings/screening-questions/:type(add|edit)/:id?',
    component: SettingsScreeningQuestionController,
    permission: [1, 2],
  },
  {
    path: '/settings/users',
    component: SettingsUsers,
    permission: [2],
  },
  {
    path: '/settings/users/:type(add|edit)/:id?',
    component: SettingsUsersForm,
    permission: [2],
  },
  {
    path: '/settings/gdpr',
    component: Gdpr,
    permission: [2],
  },
  {
    path: '/settings/integrations/add',
    component: AddIntegrationForm,
    permission: [2],
  },
  {
    path: '/settings/integrations/edit/refapp',
    component: EditRefappForm,
    permission: [2],
  },
  {
    path: '/settings/integrations/edit/ams',
    component: EditAmsForm,
    permission: [2],
  },
  {
    path: '/settings/integrations/edit/alvalabs',
    component: EditAlvaLabsForm,
    permission: [2],
  },
  {
    path: '/settings/integrations/edit/assessio',
    component: EditAssessioForm,
    permission: [2],
  },
  {
    path: '/settings/integrations/edit/sri',
    component: EditSriForm,
    permission: [2],
  },
  {
    path: '/settings/pipeline',
    component: SettingsPipeline,
    permission: [2],
  },
  {
    path: '/settings/pipeline/template/:type(add|edit)/:id?',
    component: SettingsPipelineActionTemplate,
    permission: [1, 2],
  },
  {
    path: '/settings/pipeline/step/:type(add|edit)/:id?',
    component: SettingsPipelineActionStep,
    permission: [2],
  },
  {
    path: '/settings/scheduler',
    component: SettingsScheduler,
  },
  {
    path: '/settings/scheduler/:type(add|edit)/:id?',
    component: SettingsSchedulerAction,
  },
  {
    path: '/calendar/auth/callback',
    component: NylasCallback,
    permission: [1, 2],
  },
  {
    path: '/admin/facebook',
    component: AdminFacebook,
    permission: [2],
    showFn: ({ email }) => emailHasAccessToPage(email),
  },
  {
    path: '/admin/facebook/:type(add|edit)/:id?',
    component: AdminFacebookAction,
    permission: [2],
    showFn: ({ email }) => emailHasAccessToPage(email),
  },
  {
    path: '/admin/clients',
    component: AdminClients,
    permission: [2],
    showFn: (item) => item.superAdmin,
  },
  {
    path: '/admin/clients/:type(add|edit)/:id?',
    component: AdminClientsActionTemplate,
    permission: [2],
    showFn: (item) => item.superAdmin,
  },
  {
    path: '/admin/users',
    component: AdminUsers,
    permission: [2],
    showFn: (item) => item.superAdmin,
  },
  {
    path: '/admin/orders',
    component: AdminOrders,
    permission: [2],
    showFn: (item) => item.superAdmin,
  },
  {
    path: '/admin/orders/:id?',
    component: AdminOrdersView,
    permission: [2],
    showFn: (item) => item.superAdmin,
  },
  {
    path: '/admin/products',
    component: AdminProducts,
    permission: [2],
    showFn: (item) => item.superAdmin,
  },
  {
    path: '/admin/products/:id(\\d+)',
    component: AdminProductsView,
    permission: [2],
    showFn: (item) => item.superAdmin,
  },
  {
    path: '/admin/products/translation/:id',
    component: AdminProductTranslation,
    permission: [2],
    showFn: (item) => item.superAdmin,
  },
  {
    path: '/admin/products/:type(add|edit)/:id?',
    component: AdminProductsForm,
    permission: [2],
    showFn: (item) => item.superAdmin,
  },
  {
    path: '/admin/users/:type/:id?',
    component: AdminUsersForm,
    permission: [2],
    showFn: (item) => item.superAdmin,
  },
  {
    path: '/admin/tool',
    component: AdminCustomFont,
    permission: [2],
    showFn: (item) => item.superAdmin,
  },
];

export default {
  UnauthorizeRoutes,
  LoggedInUserMenu,
};
