import qs from 'query-string';
import helper, { api } from '../helpers/api';

const resource = 'Clients';

export default {
  ...helper(resource),
  browse: (queryParams = {}, options = {}) => api.get(`${resource}?${qs.stringify(queryParams)}`, options),
  getClient: (id) => api.get(`${resource}/${id}`),
  getClientInfo: () => api.get(`${resource}/info`),
  getSettings: (headers = {}) => api.get(`${resource}/settings`, headers),
  getPageSettings: (headers = {}) => api.get(`${resource}/page-settings`, headers),
  getGDPRSettings: (headers = {}) => api.get(`${resource}/gdpr-settings`, headers),
  updatePageSettings: (body = {}) => api.put(`${resource}/page-settings`, body),
  getAllClients: () => api.get(`${resource}/GetAll`),
  addClient: (form = {}, options = {}) => api.post(`${resource}/Add`, form, options),
  updateClient: (form = {}, options = {}) => api.put(`${resource}/${form.id || form.clientId}`, form, options),
  getClientSettings: () => {
    return api.get(`${resource}/settings`);
  },
  updateClientSettings: (form = {}, options = {}) => api.put(`${resource}/settings`, form, options),
  updateGDPRSettings: (form = {}, options = {}) => api.put(`${resource}/gdpr-settings`, form, options),
  getApplicationEventsData: () => api.get(`${resource}/app-event-settings`),
  updateApplicationEvents: (param, options = {}) => api.put(`${resource}/app-event-settings`, param, { ...options }),
  deleteClient: (form) => api.delete(`${resource}/Delete`, form),
  getSubscriptionSettings: () => api.get(`${resource}/subscription-settings`),
  updateSubscriptionSettings: (form = {}, options = {}) => {
    return api.put(`${resource}/subscription-settings`, form, options);
  },
};
