import React from 'react';
import styled from 'styled-components';
import Icon from './@v2/Icon';

const Container = styled.h3`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  text-transform: uppercase;
  color: var(--text-color-secondary);
  font-size: 0.85em;
  font-weight: 100;
`;

const Line = styled.div`
  flex: 1;
  height: 1px;
  background: #d2ddec;
  margin-left: 10px;
`;

const IconContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 25px;
  height: 25px;
  border-radius: 30px;
  background: var(--primary-color);
  cursor: ;
`;

const LineBehind = ({ title, icon, onClick, dataTestId }) => (
  <Container data-testid="line-behind-component">
    {title}
    <Line />
    {icon && (
      <IconContainer
        onClick={() => {
          if (onClick) onClick();
        }}
      >
        <Icon
          name={icon}
          color="white"
          data-testid={dataTestId || `${title || ''}-line-behind`}
        />
      </IconContainer>
    )}
  </Container>
);

LineBehind.defaultProps = {
  icon: null,
  onClick: null,
};

export default LineBehind;
