import React from 'react';
import { useTranslation } from 'react-i18next';
import { get, uniqueId } from 'lodash';
import { useHistory } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import Layout from '../../../../components/layouts/default';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import Card from '../../../../components/@v2/Card';
import Table from '../../../../components/@v2/Table/Table';
import Search from '../../../../components/@v2/Search/Search';
import Paginator from '../../../../components/@v2/Paginator/Paginator';
import { getOrdersList, retryOrder } from '../../../../store/api/order.api';
import { format } from '../../../../helpers/date';
import { log } from '../../../../helpers/utils';
import { getOrderStatus } from '../../../../constants/Order';
import Badge from '../../../../components/@v2/Badge';

const Order = () => {
  const { t } = useTranslation();
  const { loading, data, meta, setMeta, pageSize, reloadData } = useApiRequestContext();
  const history = useHistory();

  const onRetry = async (id) => {
    try {
      await retryOrder(id);
      reloadData();
    } catch (error) {
      log(error);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Layout pretitle={t('general.admin')} title={t('general.orders')}>
      <Card className="anim-table-delayed shadow-table !border !border-line-default">
        <Search resource="product" meta={meta} setMeta={setMeta} />
        <Table
          isLoaded={!loading}
          activeSort={get(meta, 'sorts') || ''}
          items={get(data, 'items')}
          headers={[
            {
              label: t('general.order-id'),
              key: 'id',
              sort: false,
            },
            {
              label: t('general.order-date-of-purchase'),
              key: 'purchasedDate',
              sort: false,
            },
            {
              label: t('general.order-purchased-by'),
              key: 'purchasedBy',
              sort: false,
            },
            {
              label: t('general.customer'),
              key: 'clientName',
              sort: false,
            },
            {
              label: t('general.price'),
              key: 'total',
              sort: false,
            },
            {
              label: t('general.status'),
              key: 'status',
              sort: false,
            },
            {
              label: t('general.items'),
              key: 'products',
              sort: false,
            },
          ]}
          pageSize={pageSize}
          renderBody={(item, index) => {
            const itemStatus = getOrderStatus(item?.status);
            const hasStatusFailed = itemStatus === 'failed';

            return (
              <tr key={item?.id} id={`order-table-row--${index}`}>
                <td data-testid={`orders-td-${index}-0`}>
                  <button
                    type="button"
                    className="clickable mb-0 text-left w-full h-full"
                    onClick={() => {
                      history.push({
                        pathname: `/admin/orders/${item?.id}`,
                      });
                    }}
                    data-testid={`order-button-${index}`}
                  >
                    {item?.id}
                  </button>
                </td>
                <td>{format('date', item?.purchasedDate)}</td>
                <td>{item?.purchasedBy}</td>
                <td>{item?.clientName}</td>
                <td>{item?.total || '-'}</td>
                <td>
                  <Tippy
                    content={<p className="text-xs mb-0">{t('general.click-to-retry')}</p>}
                    placement="top"
                    key={item?.id || uniqueId()}
                    theme="light"
                  >
                    <button
                      type="button"
                      disabled={!hasStatusFailed}
                      onClick={() => {
                        if (!hasStatusFailed) {
                          return;
                        }

                        onRetry(item?.orderId);
                      }}
                    >
                      <Badge type={hasStatusFailed ? 'danger' : 'success'}>{t(`general.${itemStatus}`)}</Badge>
                    </button>
                  </Tippy>
                </td>
                <td>{item?.products?.map((product) => product?.title).join('; ')}</td>
              </tr>
            );
          }}
          setMeta={setMeta}
        />
        <div className="h-14 flex items-center bg-white">
          <Paginator
            pageCount={data?.totalCount / meta?.pageSize}
            initialPage={meta.page - 1}
            onPageChange={(value) => {
              if (meta.page !== value) {
                setMeta({
                  page: value,
                });
              }
            }}
            disableInitialCallback
          />
        </div>
      </Card>
    </Layout>
  );
};

const OrderWrapper = () => {
  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source, ...rest) => {
        const newFilters = {};

        const filters = rest?.[2] || [];

        filters.forEach((item) => {
          newFilters[item.key] = item.value;
        });

        const sort = {
          by: meta?.sorts?.replace('-', ''),
          direction: meta.sorts.includes('-') ? 'desc' : 'asc',
        };

        const response = await getOrdersList(
          {
            filter: newFilters,
            page: {
              number: meta.page,
              size: meta.pageSize,
            },
            sort: meta.sorts ? sort : {},
          },
          {
            cancelToken: source.token,
          }
        );
        return response;
      }}
    >
      <Order />
    </ApiRequestProvider>
  );
};

export default OrderWrapper;
