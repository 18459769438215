import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Input, CustomInput } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { useAPI } from '../../../context/api';
import Icon from '../../@v2/Icon';
import RadioButton from '../../input/RadioButton';
import RangeSlider from '../../input/RangeSlider';
import Switch from '../../input/Switch';
import CustomButton from '../../Button';
import '../../../assets/sass/screening-criteria.sass';
import { getScreeningTypeIdByType } from '../../../helpers/screening-criteria';
import ScreeningQuestionsType from '../../../constants/ScreeningQuestionsType';
import { createClassName, log } from '../../../helpers/utils';
import { selectClient } from '../../../store/selectors/client.selector';
import { useSelector as useSelectorToolkit } from '../../../store';

const ScreeningCriteria = ({ questionTypes, onSave }) => {
  const { t } = useTranslation();
  const [screeningAPI] = useAPI('screeningCriteriaV2');
  const [isFormActive, setIsFormActive] = useState(false);
  const [heading, setHeading] = useState('');
  const [selectedType, setSelectedType] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [isInvalidQuestion, setIsInvalidQuestion] = useState(false);
  const [form, setForm] = useState({});
  const [headingInvalid, setHeadingInvalid] = useState(false);
  const { id } = useSelectorToolkit(selectClient);
  const [screeningTypes, setScreeningTypes] = useState({});

  const MinimumSelectionOptions = 2;

  const types = useMemo(
    () =>
      Object.values(questionTypes).map((item) => ({
        value: item,
        name: item,
      })),
    [questionTypes]
  );

  const renderErrorMessage = (error) => <div className="invalid-field">{t(`error-message.${error}`)}</div>;

  useEffect(() => {
    const loadScreeningTypes = async () => {
      const types = await screeningAPI.getTypes();
      setScreeningTypes(types);
    };
    loadScreeningTypes();
  }, []);

  const displayQuestionBuilder = (suffix = '') => {
    const { value } = selectedType;

    switch (value) {
      case ScreeningQuestionsType.YEARS_TYPE:
        return (
          <RangeSlider
            value={form.value || 0}
            subText={
              form.value && form.value > 1
                ? t('position.screening-criteria.years')
                : t('position.screening-criteria.year')
            }
            onChange={(selected) => {
              setForm({ value: selected });
            }}
            id={`years-range-slider${suffix ? `-${suffix}` : ''}`}
            name={`years-range-slider${suffix ? `-${suffix}` : ''}`}
            data-testid={`position-years${suffix ? `-${suffix}` : ''}-range-slider`}
          />
        );

      case ScreeningQuestionsType.SLIDER_TYPE:
        return (
          <>
            <Label for="min">{t('position.screening-criteria.lowest-value')}</Label>
            <Input
              id={`min${suffix ? `-${suffix}` : ''}`}
              name={`min${suffix ? `-${suffix}` : ''}`}
              type="number"
              placeholder={t('position.screening-criteria.min')}
              onChange={({ target }) => {
                setForm({
                  ...form,
                  min: target.value,
                });
              }}
              data-testid={`position-min${suffix ? `-${suffix}` : ''}-input`}
            />
            <Label for="max">{t('position.screening-criteria.highest-value')}</Label>
            <Input
              id={`max${suffix ? `-${suffix}` : ''}`}
              name={`max${suffix ? `-${suffix}` : ''}`}
              type="number"
              placeholder={t('position.screening-criteria.max')}
              onChange={({ target }) => {
                setForm({
                  ...form,
                  max: target.value,
                });
              }}
              data-testid={`position-max${suffix ? `-${suffix}` : ''}-input`}
            />
            {!!(parseInt(form.max, 10) < parseInt(form.min, 10)) &&
              renderErrorMessage('the-highest-value-cannot-be-lower-than-lowest')}
            <RangeSlider
              id={`value-range-slider${suffix ? `-${suffix}` : ''}`}
              name={`value-range-slider${suffix ? `-${suffix}` : ''}`}
              min={form.min || 0}
              max={form.max || 10}
              value={form.value || 0}
              onChange={(selected) => {
                setForm({
                  ...form,
                  value: selected,
                });
              }}
              data-testid={`position-value${suffix ? `-${suffix}` : ''}-range-slider`}
            />
          </>
        );

      case ScreeningQuestionsType.TEXT_TYPE:
        return (
          <>
            <Switch
              id={`mandatory-switch${suffix ? `-${suffix}` : ''}`}
              name={`mandatory-switch${suffix ? `-${suffix}` : ''}`}
              checked={form.mandatory || false}
              label={t('Mandatory')}
              onChange={(state) => {
                setForm({
                  ...form,
                  mandatory: state,
                });
              }}
              disabled={false}
              data-testid={`position-mandatory${suffix ? `-${suffix}` : ''}-switch`}
            />
            <Input
              id={`mandatory-text${suffix ? `-${suffix}` : ''}`}
              name={`mandatory-text${suffix ? `-${suffix}` : ''}`}
              type="textarea"
              value={form.value || ''}
              onChange={({ target }) => {
                setForm({
                  ...form,
                  value: target.value,
                });
              }}
              style={{ height: 150 }}
              placeholder={t('edit-position.insert-text')}
              data-testid={`position-mandatory${suffix ? `-${suffix}` : ''}-input`}
            />
          </>
        );

      case ScreeningQuestionsType.SELECTIONS_TYPE:
        return (
          <>
            {(form.selections || []).map((item, index) => (
              <div
                key={item.screeningCriteriaSelectionId}
                className="d-flex align-items-center justify-content-between"
                style={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <Input
                  autoFocus
                  id={`selection-${item.screeningCriteriaSelectionId}${suffix ? `-${suffix}` : ''}`}
                  name={`selection-${item.screeningCriteriaSelectionId}${suffix ? `-${suffix}` : ''}`}
                  type="text"
                  onChange={({ target }) => {
                    setForm({
                      ...form,
                      selections: form.selections.map((selection) => {
                        if (selection.screeningCriteriaSelectionId === item.screeningCriteriaSelectionId) {
                          selection.name = target.value;
                        }

                        return selection;
                      }),
                    });
                  }}
                  data-testid={`position-selection-${index}${suffix ? `-${suffix}` : ''}-input`}
                />
                <button
                  type="button"
                  data-testid={`position-selection-${index}${suffix ? `-${suffix}` : ''}-trash-icon`}
                  className="ml-2"
                  onClick={() => {
                    setForm({
                      ...form,
                      selections: form.selections.filter(
                        (selection) => selection.screeningCriteriaSelectionId !== item.screeningCriteriaSelectionId
                      ),
                    });
                  }}
                >
                  <Icon name="trash" color="var(--error-color)" />
                </button>
              </div>
            ))}
            <CustomButton
              outline
              color="primary"
              type="button"
              id="add-selection-item"
              onClick={() => {
                const selections = form.selections || [];

                setForm({
                  ...form,
                  selections: [
                    ...selections,
                    {
                      screeningCriteriaSelectionId: selections.length + 1,
                      name: '',
                    },
                  ],
                });
              }}
              data-testid="position-selection-add-button"
            >
              <div className="flex items-center">
                <Icon name="plus" className="mr-1" color="var(--text-color-secondary)" />
                {t('position.screening-criteria.add-choice')}
              </div>
            </CustomButton>
          </>
        );

      case ScreeningQuestionsType.CHECKBOX_TYPE:
        return [
          {
            value: 'yes',
            label: t('Yes'),
          },
          {
            value: 'no',
            label: t('No'),
          },
        ].map((item, index) => (
          <RadioButton
            key={item.value}
            value={item.value}
            onChange={(selected) => {
              setForm({ value: selected });
            }}
            id={createClassName(`checkbox-${value}`, suffix)}
            name={createClassName(`checkbox-${value}`, suffix)}
            isSelected={form.value === item.value}
            label={item.label}
            data-testid={`position-selection-${index}${suffix ? `-${suffix}` : ''}-radio`}
          />
        ));

      case ScreeningQuestionsType.VIDEO_TYPE:
        return (
          <>
            <Switch
              id={`mandatory-switch${suffix ? `-${suffix}` : ''}`}
              name={`mandatory-switch${suffix ? `-${suffix}` : ''}`}
              checked={form.mandatory || false}
              label={t('Mandatory')}
              onChange={(state) => {
                setForm({
                  ...form,
                  mandatory: state,
                });
              }}
              disabled={false}
              data-testid={`position-mandatory${suffix ? `-${suffix}` : ''}-switch`}
            />
            <CustomInput
              className="mt-2"
              type="file"
              accept="video/mp4"
              data-testid="screening-questions-preview-video"
              disabled
            />
          </>
        );

      case ScreeningQuestionsType.MULTI_SELECT:
        return (
          <>
            {(form.selections || []).map((item, index) => (
              <div
                key={item.screeningCriteriaSelectionId}
                className="d-flex align-items-center justify-content-between"
                style={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                }}
              >
                <Input
                  type="text"
                  value={item.name}
                  onChange={({ target }) => {
                    setForm({
                      ...form,
                      selections: form.selections.map((selection) => {
                        if (selection.screeningCriteriaSelectionId === item.screeningCriteriaSelectionId) {
                          selection.name = target.value;
                        }
                        return selection;
                      }),
                    });
                  }}
                />
                <button
                  type="button"
                  data-testid={`position-selection-${index}${suffix ? `-${suffix}` : ''}-trash-icon`}
                  className="ml-2"
                  onClick={() => {
                    setForm({
                      ...form,
                      selections: form.selections.filter(
                        (selection) => selection.screeningCriteriaSelectionId !== item.screeningCriteriaSelectionId
                      ),
                    });
                  }}
                >
                  <Icon name="trash" color="var(--error-color)" />
                </button>
              </div>
            ))}
            <CustomButton
              outline
              color="primary"
              type="button"
              id="add-selection-item"
              onClick={() => {
                const selections = form.selections || [];

                setForm({
                  ...form,
                  selections: [
                    ...selections,
                    {
                      screeningCriteriaSelectionId: selections.length + 1,
                      name: '',
                    },
                  ],
                });
              }}
              data-testid="position-selection-add-button"
            >
              <div className="flex items-center">
                <Icon name="plus" className="mr-1" color="var(--text-color-secondary)" />
                {t('position.screening-criteria.add-choice')}
              </div>
            </CustomButton>
          </>
        );

      default:
        return t('position.screening-criteria.no-question-builder');
    }
  };

  useEffect(() => {
    if (form.min > form.max) {
      setIsInvalidQuestion(true);
    }
  }, [form]);

  const addQuestion = async () => {
    if (isSending) return;
    if (!heading) {
      setHeadingInvalid(true);
      return;
    }

    if (
      (!form.selections &&
        (selectedType.value === ScreeningQuestionsType.SELECTIONS_TYPE ||
          selectedType.value === ScreeningQuestionsType.MULTI_SELECT)) ||
      form.selections?.filter((item) => item.name).length < MinimumSelectionOptions
    ) {
      NotificationManager.error(t('error-message.at-least-two-selection-must-be-added'));
      return;
    }

    setHeadingInvalid(false);

    try {
      setIsSending(true);
      const question = {
        clientId: id,
        sliderMin: form.min || 0,
        sliderMax: form.max || 10,
        selections: [...(form.selections || [])]
          .filter((selection) => !!selection.name)
          .map((selection, index) => {
            selection.screeningCriteriaId = 0;
            selection.sortOrder = index;
            selection.screeningCriteriaSelectionId = index + 1;
            selection.value = index + 1;

            return selection;
          }),
        screeningCriteriaId: 0,
        name: heading,
        screeningCriteriaTypeId: getScreeningTypeIdByType(selectedType.value, screeningTypes),
        usedByCandidates: false,
        archived: false,
        mandatory: form.mandatory || false,
      };

      const response = await screeningAPI.create(question);
      onSave(response);
      setHeading('');
      setIsFormActive(false);
    } catch (error) {
      log('error', error);
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    setForm({});
  }, [selectedType]);

  useEffect(() => {
    if (types.length) setSelectedType(types[0]);
  }, [types]);

  return (
    <div className="screening-questions">
      {!isFormActive && (
        <div
          className="question-adder"
          onClick={() => {
            setIsFormActive(true);
          }}
        >
          <p className="faded">
            <Icon name="plus" />
            {t('position.screening-criteria.add-question')}
          </p>
        </div>
      )}
      {isFormActive && (
        <FormGroup>
          <div className="d-flex align-items-center justify-content-between">
            {types.map((item, index) => (
              <RadioButton
                id={`radio-button-add-${index}`}
                name="radio-button-add"
                key={item.value}
                value={item.value}
                onChange={(selected) => {
                  setSelectedType(types.find((type) => type.value === selected.target.value));
                }}
                isSelected={selectedType.value === item.value}
                label={t(`screening-types.${item.name}`)}
                data-testid={`position-criteria-type-${index}-radio`}
              />
            ))}
          </div>
          <div className="screening-questions-previewer">
            <Input
              id="heading"
              name="heading"
              type="text"
              value={heading}
              invalid={headingInvalid}
              placeholder={t('edit-position.type-in-question')}
              onChange={({ target }) => {
                setHeading(target.value);
              }}
              className="mb-2"
              data-testid="position-criteria-preview-input"
            />
            {displayQuestionBuilder('new')}
          </div>
          <CustomButton
            id="add-question-button"
            color="success"
            type="button"
            onClick={addQuestion}
            loading={isSending}
            disabled={isInvalidQuestion || isSending}
            style={{
              paddingRight: '',
              color: '#fff',
            }}
          >
            {t('position.screening-criteria.add-question')}
          </CustomButton>
        </FormGroup>
      )}
    </div>
  );
};

export default ScreeningCriteria;
