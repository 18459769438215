import { useEffect, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Input, Form, Row, Col } from 'reactstrap';
import { kebabCase, get } from 'lodash';
import { displaySectionLabel, renderErrorField } from '../../../helpers/form';
import ImageDropzone from '../../input/ImageDropzone';
import Wysiwyg from '../../wysiwyg/WysiwygEditor';
import TopImageConfigurator from '../../@v2/CareerPage/TopImageConfigurator';
import Icon from '../../@v2/Icon';

const resourceName = 'settings-position-template-page';
export const TemplateInformationForm = ({
  initialValues,
  editorTemplates,
  setFormDefaults,
}) => {
  const updateImageFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve({
          FileName: file.path,
          Content: reader.result.split(',')[1],
        });
      };
      reader.onerror = () => reject(new Error({}));
      reader.readAsDataURL(file);
    });
  };

  const onDrop = useCallback((acceptedFiles, valueSetter) => {
    const reader = new FileReader();

    reader.onload = () => {
      const dataURL = reader.result;
      if (valueSetter) valueSetter(dataURL);
    };

    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { t } = useTranslation('');
  const formik = useFormik({
    initialValues: {
      name: initialValues.name || '',
      positionName: initialValues.positionName || '',
      description: initialValues.description || '',
      pitch: initialValues.pitch || '',
      imageUrl: initialValues.imageUrl || '',
      pictureUrl: initialValues.pictureUrl || '',
      image: initialValues.image || null,
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    setFormDefaults({ ...initialValues, ...formik.values });
  }, [formik.values]);

  const DEFAULT_COORDS = {
    percentageX: 0,
    percentageY: 0,
    focusX: 0,
    focusY: 0,
  };

  const activeImageCoords = useMemo(() => {
    return formik?.values?.image?.focusPointJsonString
      ? JSON.parse(formik?.values?.image?.focusPointJsonString)
      : DEFAULT_COORDS;
  }, [formik.values.image]);

  return (
    <Form className="form-validator anim-table-delayed">
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>{t('settings.template-name')} *</Label>
            <Input
              data-testid={`${resourceName}-${kebabCase('name')}`}
              name="name"
              required
              value={formik.values.name}
              onChange={formik.handleChange}
              invalid={formik.touched.name && Boolean(formik.errors.name)}
            />
          </FormGroup>
        </Col>
      </Row>
      {displaySectionLabel(t('settings.position-information'))}
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>{t('settings.position-name')} *</Label>
            <Input
              data-testid={`${resourceName}-${kebabCase('positionName')}`}
              name="positionName"
              required
              value={formik.values.positionName}
              onChange={formik.handleChange}
              invalid={
                formik.touched.positionName &&
                Boolean(formik.errors.positionName)
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup>
            <Label>{t('general.description')} *</Label>
            <Wysiwyg
              data-testid={`${resourceName}-${kebabCase('description')}`}
              resource={`${resourceName}-${kebabCase('description')}`}
              defaultValue={formik.values.description}
              invalid={
                formik.touched.description && Boolean(formik.errors.description)
              }
              onEditorStateChange={(value) => {
                const isEmpty = value === '<p><br></p>' || value === '';
                formik.setFieldValue('description', isEmpty ? '' : value);
              }}
              options={{
                listItems: editorTemplates,
              }}
              toolbarId="position"
              toolbar="position"
              theme="snow"
              className="position-form-template-wysiwyg"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup>
            <Label>{t('edit-position.pitch')}</Label>
            <p className="sub-label">{t('edit-position.pitch-description')}</p>
            <Input
              data-testid={`${resourceName}-${kebabCase('pitch')}`}
              name="pitch"
              type="textarea"
              autoComplete="off"
              value={formik.values.pitch}
              onChange={formik.handleChange}
              invalid={formik.touched.pitch && Boolean(formik.errors.pitch)}
              rows={6}
              maxLength={200}
            />
            <p className="sub-label">
              {t('error-message.maximum-x-characters', { x: 200 })}
            </p>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <TopImageConfigurator
              data-testid={`${resourceName}-${kebabCase('imageUrl')}`}
              inputId={`${resourceName}-${kebabCase('imageUrl')}`}
              imageDivId="input-image-value"
              label={t('general.image')}
              image={formik.values.imageUrl}
              activeImageCoords={activeImageCoords}
              callback={(coords) => {
                if (formik.values.image) {
                  formik.setFieldValue('image', {
                    ...(formik.values.image || {}),
                    focusPointJsonString: JSON.stringify(coords),
                  });
                }

                if (formik.values.imageInput) {
                  formik.setFieldValue('imageInput', {
                    ...(formik.values.imageInput || {}),
                    focusPointJsonString: JSON.stringify(coords),
                  });
                }
              }}
              onDrop={(files) => {
                onDrop(files, async (value) => {
                  formik.setFieldValue('imageUrl', value);
                  const image = await updateImageFile(files[0]);
                  image.focusPointJsonString = JSON.stringify(DEFAULT_COORDS);

                  formik.setFieldValue('imageInput', image);
                });
              }}
              resource="position-image"
              page="position"
              headerActions={
                formik.values?.imageUrl || formik.values?.image ? (
                  <button
                    type="button"
                    data-testid="position-image-delete-icon"
                    onClick={() => {
                      formik.setFieldValue('imageUrl', null);
                      formik.setFieldValue('image', null);
                      formik.setFieldValue('ImageInput', null);
                    }}
                  >
                    <Icon name="trash" />
                  </button>
                ) : null
              }
              error={renderErrorField(
                get(initialValues, 'image?.size') / 1000 > 5000,
                'file-size-is-too-big',
                { size: '5mb' }
              )}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <Label className="mb-0">{t('general.logo')}</Label>
              {formik.values.pictureUrl && (
                <div className="d-flex align-items-center mb-2">
                  <button
                    type="button"
                    data-testid={`${resourceName}-${kebabCase(
                      'pictureUrl'
                    )}-logo-delete-icon`}
                    onClick={() => {
                      formik.setFieldValue('pictureUrl', null);
                      formik.setFieldValue('picture', null);
                      formik.setFieldValue('PictureInput', null);
                    }}
                  >
                    <Icon name="trash" />
                  </button>
                </div>
              )}
            </div>
            <ImageDropzone
              data-testid={`${resourceName}-${kebabCase('pictureUrl')}`}
              resource={`${resourceName}-${kebabCase('pictureUrl')}`}
              value={formik.values.pictureUrl}
              onChange={async ([file]) => {
                formik.setFieldValue('pictureUrl', file);
                const image = await updateImageFile(file);
                formik.setFieldValue('PictureInput', image);
              }}
              canvasStyle={{
                width: 'auto',
                cursor: 'pointer',
              }}
              inputId="pictureUrl"
              name="pictureUrl"
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
