import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import './ButtonFilter.sass';

const ButtonFilter = ({ isShown, callback, filters }) => {
  const { t } = useTranslation();

  const uniqueFilters = filters.filter(
    (filter, index, self) => index === self.findIndex((f) => f.key === filter.key)
  );

  return (
    <button
      type="button"
      className={classNames(
        'transition text-xs border px-3 py-1.5 rounded-lg flex items-center relative hover:bg-table-hover hover:text-black',
        isShown && 'bg-table-hover text-black'
      )}
      onClick={() => {
        callback(!isShown);
      }}
      data-testid="table-filter-button"
    >
      <Icon name="sliders-h" className="mr-2" size={12} />
      <span>{t('general.filter')}</span>
      {!!uniqueFilters.length && (
        <Badge className="button-filter-badge absolute py-1 px-2 h-auto">{uniqueFilters.length}</Badge>
      )}
    </button>
  );
};

ButtonFilter.propTypes = {
  isShown: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      condition: PropTypes.string,
    })
  ),
};

ButtonFilter.defaultProps = {
  filters: [],
};

export default ButtonFilter;
