import { api } from '../helpers/api';

const resource = 'bulk';

export default {
  email: (payload) => api.post(`${resource}/send-email`, payload),
  reject: (payload) => api.post(`${resource}/reject`, payload),
  stepUpdate: (payload) => api.post(`${resource}/step-update`, payload),
  comment: (payload) => api.post(`${resource}/comment`, payload),
};
