import React, { useState } from 'react';
import { Formik } from 'formik';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { log } from '../../../../helpers/utils';
import { previewtMarketingColorSettings } from '../../../../store/api/client.api';
import Field from '../../../../components/@v2/Form/Field';
import Divider from '../../../../components/@v2/Divider/Divider';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';

const validationSchema = Yup.object().shape({
  sampleLocationName: Yup.string().nullable(),
  samplePositionName: Yup.string().nullable(),
});

const ClientPreviewCustomizedColors = ({ clientId }) => {
  const { t } = useTranslation();
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [previewImageUrls, setPreviewImageUrls] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const defaultImageUrl = `${global?.appConfig?.public_url}/images/default-image.png`;

  const renderImage = (key) => {
    return (
      <div className="grid place-items-center h-full w-full overflow-hidden bg-upload-gray">
        {!isImageLoaded ? <img src={defaultImageUrl} alt="" className="object-cover object-center h-full" /> : ''}
        <img
          data-testid="client-preview-image"
          src={previewImageUrls ? previewImageUrls[key] : ''}
          style={isImageLoaded ? {} : { display: 'none' }}
          onLoad={() => {
            if (!isLoadingPreview) {
              setIsImageLoaded(true);
            }
          }}
          alt=""
          className="object-cover object-center h-full"
        />
        {isLoadingPreview || (previewImageUrls && !isImageLoaded) ? <Loader size={50} color="black" /> : ''}
      </div>
    );
  };

  const renderPreviewColorSettings = () => (
    <div className="mt-2 mb-4">
      <h3 className="mt-4">{t('general.feed')}</h3>
      <Row className="max-w-2xl">
        <Col md={6}>{renderImage('adImageWithAdPhotoBackground')}</Col>
        <Col md={6} className="mdMax:mt-4">
          {renderImage('adImageWithAdSolidBackground')}
        </Col>
      </Row>
      <h3 className="mt-4">{t('general.story')}</h3>
      <Row className="max-w-2xl">
        <Col md={6}>{renderImage('storyImageWithAdPhotoBackground')}</Col>
        <Col md={6} className="mdMax:mt-4">
          {renderImage('storyImageWithAdSolidBackground')}
        </Col>
      </Row>
    </div>
  );

  const onSubmit = async (values, actions) => {
    setIsLoadingPreview(true);
    setIsImageLoaded(false);
    actions.setSubmitting(true);
    try {
      const response = await previewtMarketingColorSettings({
        clientId: parseInt(clientId, 10),
        sampleLocationName: values?.sampleLocationName,
        samplePositionName: values?.samplePositionName,
      });
      if (response) {
        setPreviewImageUrls(response?.previewImageUrls);
        setIsLoadingPreview(false);
        actions.setSubmitting(false);
      }
    } catch (error) {
      log(error);
    }
  };

  return (
    <div className="mt-4">
      <Formik
        enableReinitialize
        initialValues={{
          sampleLocationName: '',
          samplePositionName: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Divider className="pt-0">{t('general.preview')}</Divider>
            <Row form>
              <Col md={6}>
                <Field
                  name="samplePositionName"
                  label={t('general.position')}
                  testId="clients-preview-customized-color-sample-position-name"
                />
              </Col>
              <Col md={6}>
                <Field
                  name="sampleLocationName"
                  label={t('general.location')}
                  testId="clients-preview-customized-color-sample-location-name"
                />
              </Col>
            </Row>
            <Button
              type="submit"
              color="primary"
              data-testid="clients-colors-submit-button"
              className="mb-4"
              loading={isSubmitting}
              disabled={!values?.samplePositionName || !values?.sampleLocationName || isSubmitting}
            >
              {t('general.click-to', { action: t('general.preview') })}
            </Button>
          </form>
        )}
      </Formik>
      {renderPreviewColorSettings()}
    </div>
  );
};

export default ClientPreviewCustomizedColors;
