import React from 'react';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import RejectCandidate from './candidates/Reject';
import DeleteCandidate from './candidates/Delete';
import MoveCandidate from './candidates/Move';
import ScheduleInterview from './candidates/ScheduleInterview';
import DeleteCandidateDocument from './candidates/DeleteDocument';
import DeleteUser from './admin/users/DeleteUser';
import DeleteLocation from './admin/Location';
import DeleteFacebookRule from './admin/FacebookRule';
import DeleteShopItem from './admin/shop/DeleteShopItem';
import ArchivePosition from './positions/ArchivePosition';
import PipelineSteps from './settings/pipelines/Steps';
import PipelineTemplates from './settings/pipelines/Templates';
import DeleteDepartment from './settings/DeleteDepartment';
import SuccessCheckout from './orders/SuccessCheckout';
import IntegrationLogs from './orders/IntegrationLogs';
import DeployStart from './system/deploy/deploy-start';
import DeployFinished from './system/deploy/deploy-finished';
import PositionPublished from './positions/PositionPublished';
import DuplicatePosition from './positions/DuplicatePosition';
import CartCondition from './CartCondition';
import DeleteFacebookToken from './admin/FacebookToken';

const modals = [
  {
    Component: PipelineSteps,
    link: 'pipeline-settings-step',
  },
  {
    Component: PipelineTemplates,
    link: 'pipeline-settings-templates',
  },
  {
    Component: ScheduleInterview,
    link: 'schedule-interview',
  },
  {
    Component: RejectCandidate,
    link: 'reject-candidate',
  },
  {
    Component: DeleteCandidate,
    link: 'delete-candidate',
  },
  {
    Component: MoveCandidate,
    link: 'move-candidate',
  },
  {
    Component: DeleteCandidateDocument,
    link: 'delete-candidate-document',
  },
  {
    Component: DeleteUser,
    link: 'delete-user',
    position: 'right',
  },
  {
    Component: DeleteLocation,
    link: 'delete-location',
  },
  {
    Component: DeleteShopItem,
    link: 'delete-shop-item',
  },
  {
    Component: ArchivePosition,
    link: 'archive-position',
  },
  // {
  //   Component: PublishPosition,
  //   link: 'publish-position',
  // },
  {
    Component: SuccessCheckout,
    link: 'success-checkout',
  },
  {
    Component: PositionPublished,
    link: 'position-published',
  },
  {
    Component: DeployStart,
    link: 'deploy-start',
  },
  {
    Component: DeployFinished,
    link: 'deploy-finished',
  },
  {
    Component: DuplicatePosition,
    link: 'position-duplicate-success',
  },
  {
    Component: DeleteFacebookRule,
    link: 'delete-facebook-rule',
  },
  {
    Component: DeleteFacebookToken,
    link: 'delete-facebook-token',
  },
  {
    Component: DeleteDepartment,
    link: 'delete-department',
  },
  {
    Component: CartCondition,
    link: 'cart-confirmation',
  },
  {
    Component: IntegrationLogs,
    link: 'integration-logs',
  },
];

const ModalContainer = () => {
  const { pathname, search, state } = useLocation();
  const history = useHistory();
  const query = qs.parse(search);

  const closeModal = () => {
    const newQuery = { ...query };

    Reflect.deleteProperty(newQuery, 'modal');

    history.push({
      location: pathname,
      search: qs.stringify(newQuery),
      state,
    });
  };

  // useEffect(() => {
  //   window.addEventListener('keydown', ({ keyCode }) => {
  //     if (keyCode === 27) closeModal();
  //   });
  // }, []);

  return (
    <div
      className={classnames('modal-container', {
        'modal-container--active': query.modal && !!modals.filter((item) => item.link === query.modal).length,
      })}
    >
      <CSSTransition
        in={!!modals.filter((modal) => modal.link === query.modal).length}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div
          className="modal-overlay"
          onClick={() => {
            closeModal();
          }}
        />
      </CSSTransition>
      {modals.map(({ link, Component, position = 'center' }) => (
        <CSSTransition
          key={link}
          in={query.modal && query.modal === link}
          timeout={300}
          classNames={classnames({
            modal: position === 'center',
            [`modal--${position}`]: position !== 'center',
          })}
          unmountOnExit
        >
          <Component position={position} />
        </CSSTransition>
      ))}
    </div>
  );
};

export default ModalContainer;
