import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import debounce from 'debounce-promise';
import moment from 'moment';
import { FormFeedback } from 'reactstrap';
import { get } from 'lodash';
import Icon from '../@v2/Icon';
import { removeItem, updateItem } from '../../store/slices/cart.slice';
import Select from '../input/Select';
import TableWrapper from '../table/TableWrapper';
import '../../assets/sass/cart.sass';
import { log } from '../../helpers/utils';
import { selectIntegrationSettings } from '../../store/selectors/client.selector';
import { getPositionListV2 } from '../../store/api/positions.api';
import { dispatch as dispatchToolkit, useSelector as useSelectorToolkit } from '../../store';

const CartContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
`;

const StyledIcon = styled(Icon)`
  &:hover {
    color: red;
  }
`;

const CartTable = ({ checkout }) => {
  const { t } = useTranslation();
  const table = useRef();
  const [selectMarginLeft, setSelectMarginLeft] = useState(0);
  const cart = useSelectorToolkit(({ cart }) => cart);
  const clientSettings = useSelectorToolkit(selectIntegrationSettings);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultOptionsForPosition, setDefaultOptionsForPosition] = useState([]);

  const removeProduct = useCallback(
    (key) => {
      dispatchToolkit(removeItem(key));
      setIsLoading(false);
    },
    [setIsLoading]
  );

  const updateProduct = useCallback((item) => {
    dispatchToolkit(updateItem(item));
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { items } = await getPositionListV2({
          filter: {
            status: 0,
            applicationDeadline: moment().add(1, 'd').format('MM-DD-YYYY'),
          },
          page: {
            size: 10,
            number: 1,
          },
        });

        setDefaultOptionsForPosition(
          (items || []).map((item) => {
            item.label = `${item.name} ${
              item.locationName && item.locationName !== '' ? `- ${item.locationName}` : ''
            }`;
            item.value = item.id;

            return item;
          })
        );
      } catch (error) {
        log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (table.current && table.current.children[0] && table.current.children[0].children[0]) {
      table.current.children[0].children[0].addEventListener('scroll', (e) => {
        setSelectMarginLeft(e.target.scrollLeft);
      });
    }
  }, [table]);

  const getOptionsTest = debounce(async (value, localCallback) => {
    const { items } = await getPositionListV2({
      filter: {
        status: 0,
        applicationDeadline: moment().add(1, 'd').format('MM-DD-YYYY'),
        name: value,
      },
      page: {
        size: 10,
        number: 1,
      },
    });

    localCallback(
      (items || []).map((item) => {
        item.label = `${item.name} ${item.locationName && item.locationName !== '' ? `- ${item.locationName}` : ''}`;
        item.value = item.id;
        return item;
      })
    );
  }, 1000);

  return (
    <CartContainer ref={table} className="anim-table-delayed">
      <TableWrapper
        resource="cart"
        filterType="client-side"
        data={cart}
        loading={isLoading}
        headers={[
          {
            label: 'Product',
            key: 'title',
          },
          {
            label: 'Position',
            style: {},
            customBody: (item) => {
              const { positionName, positionId, locationName, position, availableForPosition } = item;

              if (!checkout && get(item, 'availableForPosition')) {
                return (
                  <div style={{ height: positionId ? 32 : 55 }}>
                    <div
                      style={{
                        marginLeft: `-${selectMarginLeft}px`,
                      }}
                    >
                      <Select
                        async
                        keys={['id', 'position']}
                        resource="cart-select-position"
                        defaultOptions={defaultOptionsForPosition}
                        defaultValue={
                          positionName && positionId
                            ? {
                                value: positionId,
                                label: `${positionName} ${locationName ? `- ${locationName}` : ''}`,
                              }
                            : null
                        }
                        loadOptions={getOptionsTest}
                        onChange={(optionItem) => {
                          const { value, label, location, position } = optionItem;
                          updateProduct({
                            ...item,
                            positionId: value,
                            positionName: label,
                            locationName: location,
                            position: position || '',
                            availableForPosition,
                          });
                        }}
                      />
                      {!positionId && <FormFeedback className="d-block">{t('form-validator.required')}</FormFeedback>}
                    </div>
                  </div>
                );
              }

              return `${position || positionName || ''}${!checkout && locationName ? ` - ${locationName}` : ''}`;
            },
          },
          {
            label: t('general.location'),
            customBody: ({ locationName }) => locationName,
            hide: !checkout,
          },
          {
            label: 'Duration',
            customBody: ({ expiration }) => {
              if (expiration) {
                return `${expiration} ${expiration > 1 ? t('cart.days') : t('cart.day')}`;
              }

              return t('cart.no-expiration');
            },
          },
          {
            label: 'Price',
            customBody: ({ price }) => {
              return get(clientSettings, 'showShopPrices') ? `${price} kr` : t('shop.price-according-to-contract');
            },
          },
          {
            label: '',
            width: 20,
            customBody: ({ key }) => (
              <StyledIcon
                className="clickable"
                name="times"
                onClick={() => {
                  setIsLoading(true);
                  removeProduct(key);
                }}
              />
            ),
          },
        ]}
      />
    </CartContainer>
  );
};

CartTable.defaultProps = {
  checkout: false,
};

export default CartTable;
