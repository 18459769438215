/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';

const MediaUploader = ({
  accept,
  label,
  media,
  onDrop,
  video,
  labelElem,
  disabled,
  headerActions,
  resource,
}) => {
  const { t } = useTranslation();

  const createContent = () => {
    if (video) {
      return (
        <video width="100%" autoPlay loop muted data-testid={resource}>
          <source
            src={media}
            type="video/mp4"
            data-testid={`${resource}-source`}
          />
          {t('pages.video-not-supported-on-browser')}
        </video>
      );
    }

    return (
      <img src={media} alt="top" className="img-fluid" data-testid={resource} />
    );
  };

  return (
    <FormGroup>
      {!labelElem ? (
        <div className="d-flex align-items-center justify-content-between mb-2">
          <Label for={label} className="mb-0">
            {label}
          </Label>
          {headerActions}
        </div>
      ) : (
        labelElem
      )}
      <Dropzone
        id={label}
        maxFiles={1}
        multiple={false}
        onDrop={(files) => {
          if (onDrop) onDrop(files);
        }}
        accept={accept}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="bg-upload-gray mb-4">
            <input {...getInputProps()} data-testid={`${resource}-input`} />
            {media ? (
              createContent()
            ) : (
              <div
                className="mb-0 d-flex align-items-center justify-content-center text-center"
                style={{
                  height: 150,
                }}
              >
                <Icon
                  name="upload"
                  color="var(--text-color-secondary)"
                  size="24px"
                />
              </div>
            )}
          </div>
        )}
      </Dropzone>
    </FormGroup>
  );
};

MediaUploader.defaultProps = {
  accept: 'image/png, image/jpeg, image/jpg, image/webp',
  video: false,
  labelElem: null,
  label: '',
  disabled: false,
  headerActions: null,
  resource: 'general',
};

export default MediaUploader;
