import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import styled from 'styled-components';
import Button from '../components/Button';
import { useAPI } from '../context/api';
import { positionPageURL, templatePageURL } from '../constants/PositionTemplate';
import Select from '../components/@v2/Select/Select';
import Icon from '../components/@v2/Icon';
import Layout from '../components/layouts/default';
import { useSelector as useSelectorToolkit } from '../store';
import { selectUser } from '../store/selectors/user.selector';

const StyledSection = styled.div`
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;

  &:first-child {
    margin-right: 20px;
  }

  .card-body {
    min-height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h2 {
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
  }

  button {
    margin-top: 20px;
    width: auto;
  }

  @media (max-width: 768px) {
    &:first-child {
      margin-right: 0;
    }
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TemplateSelector = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [templateAPI] = useAPI('positionTemplate');
  const [templateOptions, setTemplateOptions] = useState([]);
  const [template, setTemplate] = useState(null);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const { permissionLevel } = useSelectorToolkit(selectUser);

  const getTemplates = async () => {
    try {
      const response = await templateAPI.browse({ pageSize: -1 });

      if (response) {
        setTemplateOptions(
          response.map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        );
      }
    } catch (error) {
      // log('error', error)
    }
  };

  const getTemplate = async (id) => {
    setIsLoadingTemplate(true);
    try {
      const response = await templateAPI.find(id);
      setTemplate(response);
    } catch (error) {
      // log('error', error)
    } finally {
      setIsLoadingTemplate(false);
    }
  };

  const renderCopyText = (isNewPosition = true) => {
    const supportPage = isNewPosition === true ? positionPageURL : templatePageURL;

    return (
      <p className="ts-card-text text-[color:var(--text-color-secondary)]" style={{ textAlign: 'center' }}>
        {isNewPosition ? t('general.create-position-from-scratch') : t('general.add-position-template-description')}
        <span
          style={{ fontWeight: 500, marginLeft: 4 }}
          className="clickable"
          onClick={() => {
            const win = window.open(supportPage, '_blank');
            if (win != null) win.focus();
            return false;
          }}
        >
          {t('general.learn-more')}
        </span>
      </p>
    );
  };

  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <Layout
      pretitle={t('positions.new')}
      title={t('general.position')}
      testId="templateSelector"
      headerTextStyle={{
        marginBottom: 10,
        paddingBottom: 10,
      }}
      rightContent={
        <div className="d-flex">
          <Button
            color="light"
            outline
            className="px-4 !h-8"
            onClick={() => {
              history.goBack();
            }}
          >
            {t('general.cancel')}
          </Button>
        </div>
      }
    >
      <StyledSection>
        <StyledCardWrapper className="anim-table-delayed">
          <StyledCard>
            <CardBody>
              <p className="ts-card-heading text-primary">{t('general.start-with-template')}</p>
              {renderCopyText(false)}
              <Select
                searchable
                keys={['id', 'name']}
                searchKey="name"
                placeholder={t('general.choose-template')}
                defaultOptions={templateOptions}
                onSelect={(item) => {
                  const template = Array.isArray(item) ? item[0] : null;

                  if (template) {
                    getTemplate(template.value);
                  }
                }}
                multiple={false}
              />
              {permissionLevel !== 0 && (
                <div
                  className="question-adder"
                  onClick={() => {
                    history.push('/settings/templates/position/add');
                  }}
                >
                  <p className="faded">
                    <Icon name="plus" />
                    {t('general.add-template')}
                  </p>
                </div>
              )}
              <StyledButtonWrapper>
                <Button
                  className="btn-rounded text-white px-4"
                  color="primary"
                  onClick={() => {
                    history.push({
                      pathname: 'positions/add',
                      state: {
                        section: 'VisiblePosition',
                        statusNumber: 0,
                        selectedTemplate: template,
                      },
                    });
                  }}
                  loading={isLoadingTemplate}
                  disabled={template === null}
                >
                  {t('settings.use-template')}
                </Button>
              </StyledButtonWrapper>
            </CardBody>
          </StyledCard>
          <StyledCard>
            <CardBody>
              <p className="ts-card-heading text-primary">{t('general.new-position')}</p>
              {renderCopyText(true)}
              <StyledButtonWrapper>
                <Button
                  className="btn-rounded px-4 gap-x-2 !flex items-center"
                  color="primary"
                  id="new-position"
                  data-testid="template-selector-create-position-button"
                  onClick={() => {
                    history.push({
                      pathname: 'positions/add',
                      state: {
                        section: 'VisiblePosition',
                        statusNumber: 0,
                      },
                    });
                  }}
                >
                  <div className="flex items-center">
                    <Icon name="plus" />
                    {t('general.new-position')}
                  </div>
                </Button>
              </StyledButtonWrapper>
            </CardBody>
          </StyledCard>
        </StyledCardWrapper>
      </StyledSection>
    </Layout>
  );
};

export default TemplateSelector;
