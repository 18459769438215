import { FC } from 'react';
import { get } from 'lodash';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { History } from 'history';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { I18nextProvider } from 'react-i18next';
import { ToastProvider } from 'react-toast-notifications';
import TagManager from 'react-gtm-module';
import { ErrorBoundary } from 'react-error-boundary';

// Missing type annotation
// @ts-ignore
import { NotificationContainer } from 'react-notifications';
// Missing type annotation
// @ts-ignore
import SnackbarProvider from 'react-simple-snackbar';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ApiProvider } from './context/api';
import { repositories } from './factories/api';
import { storeToolkit, persistorToolkit, storeToolkitContext, useSelector as useSelectorToolkit } from './store';
import { selectIntegrationSettings } from './store/selectors/client.selector';
import Routes from './Routes';
import i18n from './i18n';
import { logError } from './services/app-insights.service';
import NotFound from './pages/NotFound';
import { log } from './helpers/utils';
import api from './api';
import { api as apiV2 } from './helpers/api-v2';
import {
  onFullFilled as onFullFilledLoginV2,
  onRejected as onRejectedLoginV2,
} from './interceptors/login-v2.interceptors';
import { onFullFilled as onFullFilledLogin, onRejected as onRejectedLogin } from './interceptors/login.interceptors';
import { onFullFilled as onFullFilledAuth, onRejected as onRejectedAuth } from './interceptors/auth-v2.interceptors';
import theme from './theme';

api.interceptors.request.use(onFullFilledAuth, onRejectedAuth);
api.interceptors.response.use(onFullFilledLoginV2, onRejectedLoginV2);

apiV2.interceptors.request.use(onFullFilledAuth, onRejectedAuth);
apiV2.interceptors.response.use(onFullFilledLogin, onRejectedLogin);

/**
 * @returns {component} React Component
 */

export type AppRoutesProps = {
  history: History;
};

const AppRoutes: FC<AppRoutesProps> = ({ history }) => {
  const integrationSettings = useSelectorToolkit(selectIntegrationSettings);

  if (get(integrationSettings, 'googleTagManagerEnabled')) {
    const tagManagerArgs = {
      gtmId: window?.appConfig?.gtm_id,
    };
    TagManager.initialize(tagManagerArgs);
  }

  return <Routes />;
};

const App = () => {
  const history = useHistory();
  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider>
        <ApiProvider value={repositories}>
          <ToastProvider>
            <Provider store={storeToolkit} context={storeToolkitContext}>
              <ErrorBoundary
                FallbackComponent={NotFound}
                onError={(error, info) => {
                  log(error.message, info?.componentStack);
                  logError(error.message);
                }}
              >
                <I18nextProvider i18n={i18n}>
                  <IntercomProvider appId={window?.appConfig?.intercom_app_id}>
                    <PersistGate persistor={persistorToolkit}>
                      <Router>
                        <AppRoutes history={history} />
                      </Router>
                    </PersistGate>
                  </IntercomProvider>
                </I18nextProvider>
              </ErrorBoundary>
            </Provider>
          </ToastProvider>
          <NotificationContainer />
        </ApiProvider>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

export default App;
