/* eslint-disable no-restricted-syntax */
import React, { useMemo } from 'react';
import classnames from 'classnames';
import uuidv4 from 'uuid/v4';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import '../assets/sass/paginator.sass';
import { useSelector as useSelectorToolkit } from '../store';
import { selectTablePageSize } from '../store/selectors/settings.selector';

const Paginator = ({
  count,
  currentPage,
  serverSide,
  totalCount,
  onClick,
  size,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const testId = 'paginator';
  const urlParams = useMemo(() => qs.parse(search), [search]);
  const pageSize = useSelectorToolkit(selectTablePageSize);
  let max = Math.ceil(count / (size || pageSize));

  if (serverSide === true) {
    const maxCount = totalCount || size || pageSize;
    max = Math.ceil(maxCount / (size || pageSize));
  }

  const range = useMemo(() => {
    const current = parseInt(currentPage, 10);
    const delta = 7;
    const items = [];

    for (let i = 1; i <= max; i += 1) items.push(i);

    if (max > 9) {
      if (current + 5 > max) {
        items.splice(1, max - (delta + 1), '...');
      } else if (current < delta) {
        items.splice(delta, max - (delta + 1), '...');
      } else if (current >= delta) {
        items.splice(current + 2, max - current - 3, '...');
        items.splice(1, current - 4, '...');
      }
    }

    return items;
  }, [currentPage, max]);

  return (
    <div
      data-testid={testId}
      className="custom-pagination d-flex align-items-center justify-content-center"
    >
      {range.map((item, index) => (
        <button
          key={uuidv4()}
          data-testid={`paginator-item-${index}`}
          type="button"
          className={classnames(
            'pagination-item d-flex align-items-center justify-content-center',
            {
              'pagination-item--active': item === currentPage,
            }
          )}
          onClick={() => {
            if (onClick) {
              onClick(item);
            } else if (typeof item === 'number') {
              const params = { ...urlParams };

              Reflect.deleteProperty(params, 'r');

              history.push({
                ...location,
                search: qs.stringify({
                  ...params,
                  currentPage: item,
                }),
              });
            }
          }}
        >
          {item}
        </button>
      ))}
    </div>
  );
};

Paginator.defaultProps = {
  serverSide: true,
  totalCount: 10,
  count: 10,
  onClick: null,
  size: null,
};

export default Paginator;
