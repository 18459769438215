export const GenericYesNo = (t) => [
  {
    value: true,
    label: t('general.yes'),
  },
  {
    value: false,
    label: t('general.no'),
  },
];

export const createTitleSizeOptions = (t) => [
  {
    label: t('settings.position-list.title-large-html-element'),
    value: 'h1',
  },
  {
    label: t('settings.position-list.title-medium-html-element'),
    value: 'h2',
  },
  {
    label: t('settings.position-list.title-small-html-element'),
    value: 'h3',
  },
];

export const createLinkTypeOptions = (t) => [
  {
    label: t('settings.position-list.link-column'),
    value: 'column',
  },
  {
    label: t('settings.position-list.link-row'),
    value: 'row',
  },
];

export const createLinkFunctionOptions = (t) => [
  {
    label: t('settings.position-list.link-function-positionpage'),
    value: 'always_positionpage',
  },
  {
    label: t('settings.position-list.link-function-applyform'),
    value: 'always_form',
  },
  {
    label: t(
      'settings.position-list.link-function-careerpage-otherwise-positionpage'
    ),
    value: 'careerpage_when_available_otherwise_positionpage',
  },
  {
    label: t(
      'settings.position-list.link-function-careerpage-otherwise-applyform'
    ),
    value: 'careerpage_when_available_otherwise_form',
  },
];

export const LOGO_POSITION_OPTIONS = (t) => {
  return [
    {
      value: 0,
      label: t('career.header.left'),
    },
    {
      value: 1,
      label: t('career.header.center'),
    },
    {
      value: 2,
      label: t('career.header.right'),
    },
  ];
};

export default {};
