import classNames from 'classnames';
import React from 'react';

const Badge = ({ children, type = 'success', className }) => {
  return (
    <div
      className={classNames(
        '!px-1.5 !py-[1px] !text-[10px] rounded-md inline-flex',
        type === 'success' && 'bg-success text-white',
        type === 'primary' && 'bg-primary-light !text-primary',
        type === 'default' && 'bg-white !text-secondary',
        className
      )}
    >
      {children}
    </div>
  );
};

export default Badge;
