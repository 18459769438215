import helper, { api } from '../helpers/api';

const resource = 'screening-criteria';

export default {
  ...helper(resource, 'restful'),
  restore: (id) => api.post(`${resource}/${id}/restore`),
  getTypes: () => api.get(`${resource}/types`),
  lookup: () => api.post(`${resource}/lookup`),
};
