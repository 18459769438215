import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import Field from '../../../../components/@v2/Form/Field';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../../store';
import { selectClientTypes, selectCompanyIndustries } from '../../../../store/selectors/settings.selector';
import { ClientLanguage, RekryShopOptions, SiteType } from '../../../../constants/Client';
import Button from '../../../../components/Button';
import { createOrUpdateClient, getAdminClients } from '../../../../store/api/client.api';
import { selectClient } from '../../../../store/selectors/client.selector';
import { setClient } from '../../../../store/slices/client.slice';
import { createLinkSuffix } from '../../../../helpers/utils';

const ClientForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { data } = useApiRequestContext();
  const activeClient = useSelectorToolkit(selectClient);
  const clientTypes = useSelectorToolkit(selectClientTypes);
  const companyIndustries = useSelectorToolkit(selectCompanyIndustries);

  const required = t('form-validator.required');
  const arrMin = (length = 1) =>
    t('form-validator.arr-minlength', {
      length,
    });
  const objectShape = {
    label: Yup.string().required(required),
    value: Yup.string().required(required),
  };

  const ClientFormSchema = Yup.object().shape({
    name: Yup.string().required(required),
    key: Yup.string().required(required),
    contactFirstName: Yup.string().required(required),
    contactLastName: Yup.string().required(required),
    companyIndustry: Yup.array().min(1, arrMin()).required(required),
    contactEmail: Yup.string().email().required(required),
    type: Yup.object().shape(objectShape).nullable().required(required),
    siteType: Yup.object().shape(objectShape).nullable().required(required),
    language: Yup.object().shape(objectShape).nullable().required(required),
    settingsModel: Yup.object().shape({
      showRekryShop: Yup.object().shape(objectShape).nullable().required(required),
      gdprDeleteDirectApplicationMonths: Yup.number().min(1, t('error-message.has-atleast-one-month')),
      gdprDeleteSpontanenousApplicationMonths: Yup.number().min(1, t('error-message.has-atleast-one-month')),
    }),
  });

  const onSubmit = async (values) => {
    try {
      const client = await createOrUpdateClient(values);

      if (activeClient.id === parseInt(id, 10)) {
        dispatchToolkit(
          setClient({
            ...activeClient,
            ...client,
            settingsModel: client?.settingsModel || {},
          })
        );
      }

      history.push('/admin/clients');
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <Formik
      initialValues={{
        ...data,
        subClients: (data?.subClients || []).map((item) => ({
          ...item,
          id: item.clientId || item.id,
          name: item.clientName || item.name,
        })),
      }}
      validationSchema={ClientFormSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, handleSubmit, isSubmitting }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className="anim-table-delayed max-w-4xl w-full shadow-sm p-4 bg-white rounded-lg"
          >
            <div className="flex gap-x-4">
              <Field
                name="name"
                label={t('general.name')}
                callback={(value) => {
                  if (!data?.clientId) {
                    setFieldValue('key', createLinkSuffix(value));
                  }
                }}
                testId="clients-name-input"
                required
              />
              <Field name="key" label={t('general.key')} testId="clients-key-input" required />
            </div>
            <div className="flex gap-x-4">
              <Field
                name="contactFirstName"
                label={t('edit-client.contact-first-name')}
                testId="clients-contact-first-name-input"
                required
              />
              <Field
                name="contactLastName"
                label={t('edit-client.contact-last-name')}
                testId="clients-contact-last-name-input"
                required
              />
            </div>
            <Field
              multiple
              name="companyIndustry"
              type="select"
              defaultOptions={sortBy(companyIndustries, ['label'])}
              label={t('edit-client.company-industry')}
              testId="company-industry"
              required
            />
            <div className="flex gap-x-4">
              <Field
                name="contactEmail"
                type="email"
                label={t('edit-client.contact-email')}
                testId="clients-contact-email-input"
                required
              />
              <Field
                name="contactPhoneNumber"
                label={t('edit-client.contact-phone-number')}
                testId="clients-contact-phone-number-input"
              />
            </div>
            <div className="flex gap-x-4">
              <Field
                name="type"
                type="select"
                defaultOptions={clientTypes}
                label={t('edit-client.customer-type')}
                testId="customer-type"
                required
              />
              <Field
                name="language"
                type="select"
                defaultOptions={ClientLanguage}
                label={t('general.language')}
                testId="language"
                required
              />
            </div>
            <div className="flex gap-x-4">
              <Field
                name="siteType"
                type="select"
                defaultOptions={Object.keys(SiteType).map((item) => {
                  return {
                    value: parseInt(item, 10),
                    label: SiteType[item]?.label || '',
                  };
                })}
                label={t('edit-client.site-type')}
                testId="site-type"
                required
              />
              <Field name="customUrl" label={t('edit-client.custom-url')} testId="clients-custom-url-input" />
            </div>
            <div className="flex gap-x-4">
              <Field
                name="settingsModel.showRekryShop"
                type="select"
                defaultOptions={RekryShopOptions}
                label={t('edit-client.visa-shop')}
                testId="visa-shop"
                required
              />
              <Field
                name="inactivationDate"
                label={t('edit-client.deactivation-date')}
                testId="inactivation-date"
                type="date"
              />
            </div>
            <Field
              name="settingsModel.gdprAutoDeleteCandidates"
              label={t('settings.gdpr.delete-candidates-automatically')}
              testId="gdpr-delete-candidates"
              type="switch"
            />
            <div className="flex gap-x-4">
              <Field
                name="settingsModel.gdprDeleteDirectApplicationMonths"
                label={t('settings.gdpr.direct-application-months')}
                testId="clients-integration-settings-gdpr-delete-direct-application-months-input"
                type="number"
              />
              <Field
                name="settingsModel.gdprDeleteSpontanenousApplicationMonths"
                label={t('settings.gdpr.spontaneous-application-months')}
                testId="clients-integration-settings-gdpr-delete-spontanenous-application-months-input"
                type="number"
              />
            </div>
            <div className="flex gap-x-4">
              <Field
                name="googleAnalyticsId"
                label={t('edit-client.google-analytics-id')}
                testId="clients-google-analytics-id-input"
              />
              <Field
                name="indeedConversionCode"
                label={t('edit-client.indeed-conversion-code')}
                testId="clients-indeed-conversion-code-input"
              />
            </div>
            <div className="flex gap-x-4">
              <Field
                name="settingsModel.assessioOrgId"
                label={t('edit-client.assessio-id')}
                testId="clients-integration-settings-assessio-org-id-input"
              />
              <Field
                name="settingsModel.finnPartnerId"
                label={t('edit-client.finn-partner-id')}
                testId="clients-integration-settings-finn-partner-id-input"
              />
            </div>
            <div className="flex gap-x-4">
              <Field
                name="settingsModel.facebookPageId"
                label={t('edit-client.facebook-page-id')}
                testId="clients-integration-settings-facebook-page-id-input"
              />
              <Field
                name="settingsModel.facebookAdAccountId"
                label={t('edit-client.facebook-ads-account-id')}
                testId="clients-integration-settings-facebook-ad-account-id-input"
              />
            </div>
            <div className="flex gap-x-4">
              <Field
                name="settingsModel.instagramAccountId"
                label={t('edit-client.instagram-account-id')}
                testId="clients-integration-settings-instagram-account-id-input"
              />
              <Field
                multiple
                name="subClients"
                type="select"
                keys={['id', 'name']}
                searchKey="search"
                label={t('edit-client.sub-accounts')}
                testId="clients-integration-sub-clients"
                api={getAdminClients}
                objectFilters={{
                  page: {
                    size: 10,
                    number: 1,
                  },
                }}
                callback={(currentArr) => {
                  setFieldValue(
                    'subClients',
                    (currentArr || []).map((item) => ({
                      ...item,
                      clientId: item.id || item.clientId,
                      clientName: item.name || item.clientName,
                    }))
                  );
                }}
              />
            </div>
            <Field
              name="settingsModel.showPublishOnAms"
              label={t('edit-client.publish-to-ams')}
              type="switch"
              testId="show-publish-on-ams"
            />
            <Field
              name="settingsModel.showNewsAndSubscribers"
              label={t('edit-client.view-news-subscribers')}
              type="switch"
              testId="show-news-and-subscribers"
            />
            <Field name="forceHttps" label={t('edit-client.force-https')} type="switch" testId="force-https" />
            <Field name="active" label={t('general.active')} type="switch" testId="active" />
            <Field
              name="settingsModel.gdprShowOnApplyForm"
              label={t('edit-client.gpdr-show-apply-form')}
              type="switch"
              testId="gdpr-show-on-apply-form"
            />
            <Field
              name="settingsModel.gdprShowImportAndExport"
              label={t('edit-client.gpdr-show-import-export')}
              type="switch"
              testId="gdpr-show-import-and-export"
            />
            <Field
              name="settingsModel.showShopPrices"
              label={t('edit-client.show-shop-prices')}
              type="switch"
              testId="show-shop-prices"
            />
            <Field
              name="settingsModel.showPrepaidAdsQuestion"
              label={t('edit-client.show-prepaid-ad-question')}
              type="switch"
              testId="show-prepaid-ads-question"
            />
            <Field
              name="settingsModel.boostAutoToggle"
              label={t('edit-client.toggle-fb')}
              type="switch"
              testId="facebook-auto-toggle"
            />
            <Field
              name="settingsModel.googleTagManagerEnabled"
              label={t('edit-client.toggle-gtm')}
              type="switch"
              testId="gtm-enabled"
            />
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="clients-submit-button"
            >
              {t('admin.clients.save-client')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default ClientForm;
