import React from 'react';
import InfiniteScroller from 'react-infinite-scroller';

const InfiniteScroll = ({
  initialLoad,
  loadMore,
  hasMore,
  threshold,
  children,
  ...rest
}) => (
  <InfiniteScroller
    initialLoad={initialLoad}
    pageStart={0}
    loadMore={loadMore}
    hasMore={hasMore}
    threshold={threshold}
    {...rest}
  >
    {children}
  </InfiniteScroller>
);

InfiniteScroll.defaultProps = {
  threshold: 250,
};

export default InfiniteScroll;
