/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { debounce, get, isArray, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { log } from '../../../helpers/utils';
import DropdownMenu from '../Dropdown/Dropdown';
import Icon from '../Icon';
import InfiniteScroll from '../../InfiniteScroll';
import CircularLoader from '../../Loader/CircularLoader';
import './Select.sass';

const Select = ({
  dark = false,
  id,
  defaultFilters,
  objectFilters,
  useObjectFilters = false,
  defaultValue,
  searchKey,
  api,
  onSelect,
  value,
  keys,
  invalid = false,
  disabled,
  defaultOptions,
  multiple = false,
  clearable,
  searchable,
  resource,
  insertedElement = null,
  renderList,
  filterOptionsFn,
  onSelectListener,
  label,
  preload = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const selectId = id || 'selectV2';
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [options, setOptions] = useState(defaultOptions || []);
  const [selected, setSelected] = useState(isArray(defaultValue) ? defaultValue.filter((item) => item) : []);
  const [isFetchingApi, setIsFetchingApi] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [name, setName] = useState('');

  useEffect(() => {
    if (isArray(value)) {
      setSelected(value.filter((item) => item) || []);
    }
  }, [value]);

  useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions]);

  const computedOptions = useMemo(() => {
    if (filterOptionsFn) return filterOptionsFn(options);
    return options;
  }, [options, filterOptionsFn]);

  const getOptions = async (value = '', search = true, page = 1, reset) => {
    try {
      setIsFetchingApi(true);

      let filters = defaultFilters;

      if (value !== '') {
        if (filters) {
          filters += ',';
        }

        filters += `${searchKey}@=${value}`;
      }

      const response = await api(
        objectFilters || useObjectFilters
          ? {
              ...objectFilters || {},
              filter: {
                [searchKey]: value,
              },
            }
          : {
              filters,
              filtersV2: {
                [searchKey]: value,
              },
              page,
              pageSize: 20,
            }
      );

      if (response || response?.payload || response?.items) {
        if (page === 1) {
          setOptions(uniqBy(response.items || response.payload || response, keys[0]));
        } else {
          setOptions(uniqBy([...options, ...(response.items || response.payload || response)], keys[0]));
        }
      }

      if (response && objectFilters) {
        setOptions(uniqBy(response.items || response.payload || response, keys[0]));
      }

      if (get(response, 'totalCount')) {
        setTotalCount(response.totalCount);
      }
      setCurrentIndex(search ? 2 : currentIndex + 1);

      if (reset) {
        setSelected(response.items.filter((position) => position.id === Number(defaultValue)));
      }
    } catch (error) {
      log(error);
    } finally {
      setIsFetchingApi(false);
    }
  };

  const debouncedSearch = debounce((value) => {
    getOptions(value);
    setName(value);
  }, 500);

  const onChange = (event) => {
    event.persist(event);
    const value = get(event, 'target.value');

    if (api) {
      debouncedSearch(value);
    } else {
      setOptions(
        (defaultOptions || []).filter((option) =>
          (option[searchKey] || option?.label).toLowerCase().includes(value.toLowerCase())
        )
      );
      setName(value);
    }
  };

  const handleClick = (item) => {
    let newItems = null;

    if (multiple) {
      if (selected && selected.find((member) => member[keys[0]] === item[keys[0]])) {
        newItems = selected.filter((member) => member[keys[0]] !== item[keys[0]]);
      } else {
        newItems = [...selected, item];
      }
    } else {
      newItems = [item];
    }

    setSelected(newItems);
    onSelect(newItems);

    if (!multiple) setIsDropdownOpen(false);
  };

  const didRun = useRef(false);
  const inputRef = useRef();

  useEffect(() => {
    if (api && isDropdownOpen && !name && !didRun?.current) {
      getOptions();
    }

    didRun.current = isDropdownOpen;
  }, [isDropdownOpen, name, api]);

  useEffect(() => {
    if (preload) {
      getOptions(undefined, false, 1, true);
    }
  }, [preload]);

  const focusInput = () => {
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 500);
  };

  const handleOnVisibleChange = (state) => {
    if (state && searchable) {
      focusInput();
    }
    if (!disabled) setIsDropdownOpen(state);
  };

  const onRemove = (item) => {
    const newSelected = selected.filter((member) => member[keys[0]] !== item[keys[0]]);
    setSelected(newSelected);
    onSelect(newSelected);

    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 50);
  };

  useEffect(() => {
    if (onSelectListener) {
      onSelectListener(selected, setIsDropdownOpen);
    }
  }, [onSelectListener, selected]);

  useEffect(() => {
    if (!isDropdownOpen) {
      if (inputRef?.current?.value) {
        inputRef.current.value = '';
        setName('');
      }
    }
  }, [isDropdownOpen]);

  return (
    <div className="relative searchable-select">
      <DropdownMenu
        {...rest}
        dark={dark}
        placement="bottomLeft"
        id={selectId}
        visible={isDropdownOpen}
        onVisibleChange={handleOnVisibleChange}
        buttonProps={{
          className: 'block w-full',
          style: { width: '100%' },
          ...rest?.buttonProps,
          'data-testid': `${resource || selectId || 'selectV2'}-button`,
        }}
        resource={resource}
        renderBody={() => (
          <div className="w-full" id={`${selectId}-container`}>
            {searchable && (
              <div className="px-4 pt-4 mb-2">
                <div className="flex items-center input-holder border border-line-default px-3 py-2 rounded-md relative">
                  <Icon color="var(--text-color-secondary)" name="search" />
                  <input
                    data-testid={`${resource || selectId}-input`}
                    id={`${selectId}-input`}
                    className={classNames('fake-input text-left text-xs w-full outline-none ml-2')}
                    placeholder={t('general.search')}
                    onChange={onChange}
                    onClick={focusInput}
                    ref={inputRef}
                  />
                  {isFetchingApi && (
                    <CircularLoader size={30} className="select-circular-loader absolute top-0 right-0" />
                  )}
                </div>
              </div>
            )}
            {insertedElement && insertedElement(handleOnVisibleChange)}
            <div className="select-options pb-2 overflow-auto max-h-[400px]">
              <InfiniteScroll
                initialLoad={false}
                loadMore={() => {
                  getOptions(name, false, currentIndex);
                }}
                hasMore={api && !isFetchingApi && !!(Math.ceil(totalCount / 20) >= currentIndex)}
                threshold={250}
                useWindow={false}
              >
                <div className="py-2">
                  {(computedOptions || []).map((item, index) => (
                    <button
                      id={`${selectId}-option-${index}`}
                      type="button"
                      className={classNames(
                        'w-full px-4 py-3 mb-0 cursor-pointer flex items-center justify-between',
                        dark
                          ? 'text-black hover:text-white hover:bg-gray-800'
                          : `hover:bg-gray-100 ${resource}-option-${index}`
                      )}
                      style={
                        item?.hidden
                          ? {
                              display: 'none',
                            }
                          : null
                      }
                      key={item?.[keys[0]]}
                      data-testid={`option-${index}`}
                      onClick={() => handleClick(item)}
                    >
                      <p className="flex items-center justify-start gap-x-2 mb-0 text-left">
                        {item?.icon && <Icon name={item?.icon} size={12} />}
                        {item?.[keys[1]]}
                      </p>
                      {selected && !!selected.find((member) => member?.[keys[0]] === item?.[keys[0]]) && (
                        <Icon name="check" size={12} className="ml-2" />
                      )}
                    </button>
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        )}
      >
        <div
          className={classNames(
            'input-box !border !border-line-default px-3 py-1 rounded-lg text-left text-sm flex items-center justify-between w-full bg-white',
            disabled && 'opacity-50',
            invalid && '!border-invalid',
            !dark && !disabled && 'bg-white'
          )}
        >
          {!selected.length && (
            <p className="mb-0 text-xs text-hint-default">
              {label === '' ? t('general.click-to', { action: 'select' }) : label}
            </p>
          )}
          <ul className="flex items-center flex-wrap flex-grow mb-0">
            {renderList && renderList(selected, onRemove)}
            {!renderList && !multiple && !!selected.length && selected[0] && (
              <li className="mr-2 my-1 text-xs">{selected[0][keys[1]]}</li>
            )}
            {!renderList &&
              multiple &&
              !!selected.length &&
              selected.map((item, index) => (
                <li
                  className="mr-2 my-1 text-xs bg-gray-200 flex items-center justify-between px-2 py-1 rounded-full"
                  key={item[keys[0]]}
                >
                  {item[keys[1]]}
                  <div
                    onClick={() => {
                      if (disabled) return;
                      onRemove(item);
                    }}
                    data-testid={`${resource || selectId}-${index}-delete-icon`}
                  >
                    <Icon name="times" size={12} className="ml-2" />
                  </div>
                </li>
              ))}
          </ul>
          <Icon
            name="chevron-down"
            className={classNames('text-gray-500', clearable && !disabled && !!selected.length && '!mr-4')}
          />
        </div>
      </DropdownMenu>
      {clearable && !disabled && !!selected.length && (
        <button
          className="absolute right-0 top-0 bottom-0 items-center pr-3 flex"
          type="button"
          onClick={() => {
            if (disabled) return;
            onSelect([]);
            setSelected([]);
          }}
          data-testid={`${resource || selectId}-clear-icon`}
        >
          <Icon name="times" className="text-gray-500" />
        </button>
      )}
    </div>
  );
};

Select.propTypes = {
  clearable: PropTypes.bool,
  id: PropTypes.string,
  defaultFilters: PropTypes.string,
  searchKey: PropTypes.string.isRequired,
  api: PropTypes.func,
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.oneOfType(
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    )
  ).isRequired,
  keys: PropTypes.arrayOf(PropTypes.string),
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  searchable: PropTypes.bool,
  resource: PropTypes.string,
  renderList: PropTypes.func,
  filterOptionsFn: PropTypes.func,
  label: PropTypes.string,
};

Select.defaultProps = {
  clearable: false,
  id: null,
  defaultFilters: '',
  invalid: false,
  disabled: false,
  multiple: false,
  api: null,
  defaultOptions: null,
  searchable: false,
  resource: '',
  keys: ['value', 'label'],
  renderList: null,
  filterOptionsFn: null,
  label: '',
};

export default Select;
