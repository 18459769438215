/* eslint-disable jsx-a11y/control-has-associated-label */
import { Formik } from 'formik';
import { kebabCase, uniqueId } from 'lodash';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { CustomInput, FormGroup, Label } from 'reactstrap';
import { useApiRequestContext } from '../../../../../context/@v2/ApiRequestContext';
import {
  getDefaultFontScale,
  hslaStringToHslaObjConverter,
  hslaObjToRgbaConverter,
} from '../../../../../helpers/utils';
import { dispatch, useSelector } from '../../../../../store';
import { onBlockUpdate } from '../../../../../store/api/career-page.api';
import { setUpperBlock } from '../../../../../store/slices/settings.slice';
import Button from '../../../../Button';
import Field from '../../../Form/Field';
import BlockTitle from '../BlockTitle/BlockTitle';

interface FontScaleComponentProps {
  value: any;
  label: string;
  callback?: (value: string | number) => void;
  testId: string;
}

const FontScaleComponent = ({ value, label = '', callback, testId }: FontScaleComponentProps) => {
  return (
    <FormGroup>
      <Label>{label}</Label>
      <div className="flex items-center gap-x-2">
        <CustomInput
          id={uniqueId()}
          type="range"
          min={20}
          max={300}
          step={5}
          value={value || 0}
          onChange={(e) => {
            const { value } = e.target;
            callback(value || 0);
          }}
          data-testid={`form-${kebabCase(testId)}`}
        />
        <span>{value}%</span>
      </div>
    </FormGroup>
  );
};

interface ShadowColorPickerComponentProps {
  value: any;
  label: string;
  callback?: (value: string) => void;
  testId: string;
  shadowKey?: string;
  isShown?: boolean;
}

const ShadowColorPickerComponent = ({
  value,
  shadowKey = '',
  label = '',
  callback,
  isShown,
  testId,
}: ShadowColorPickerComponentProps) => {
  const defaultColorValue = hslaStringToHslaObjConverter(value);

  return (
    <>
      <div className="flex items-center !mb-4" data-testid={`form-${kebabCase(testId)}`}>
        <span>{label}</span>
        {/* @ts-ignore */}
        <Field dark name={shadowKey} type="switch" className="!w-auto !mb-0 !ml-4" />
      </div>
      {isShown && (
        <ChromePicker
          className="!w-full !mb-6"
          id={uniqueId()}
          color={defaultColorValue}
          onChangeComplete={(color) => {
            if (color?.hsl !== value) {
              callback(color?.hsl);
            }
          }}
        />
      )}
    </>
  );
};

interface ColorPickerComponentProps {
  value: any;
  label: string;
  callback?: (value: string) => void;
  testId: string;
}

const ColorPickerComponent = ({ value = '#ffffff', label = '', callback, testId }: ColorPickerComponentProps) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      <div
        className="flex items-center justify-between !mb-4 !bg-white !border-career-page-header-button text-black rounded-sm h-[38px] text-sm !pl-3.5"
        data-testid={`form-${kebabCase(testId)}`}
      >
        <span>{label}</span>
        <button
          type="button"
          className="rounded-sm h-[30px] w-[30px] mr-[4px]"
          style={{
            backgroundColor: value,
          }}
          onClick={() => {
            setIsShown(!isShown);
          }}
          data-testid={`form-${kebabCase(testId)}-clicker`}
        />
      </div>
      {isShown && (
        <ChromePicker
          disableAlpha
          className="!w-full !mb-6"
          id={uniqueId()}
          color={value}
          onChangeComplete={(color) => {
            callback(color?.hex);
          }}
        />
      )}
    </>
  );
};

const CustomizeSettings = () => {
  const { t } = useTranslation();
  const settings = useSelector((state) => state?.settings?.careerPage);
  const { data, reloadData } = useApiRequestContext();

  const getShadowKey = (key) => {
    if (key === 'textShadowColorHex') {
      return 'useTextShadow';
    }

    if (key === 'overlayColorHex') {
      return 'useOverlay';
    }

    return '';
  };

  const getLabel = (key) => {
    if (key === 'backgroundColorHex') {
      return t('career-page.background-color');
    }

    if (key === 'headerTextColorHex') {
      return t('career-page.headline-color');
    }

    if (key === 'subtitleTextColorHex') {
      return t('career-page.subtitle-color');
    }

    if (key === 'textColorHex') {
      return t('career-page.body-text-color');
    }

    if (key === 'titleFontSizeScale') {
      return t('pages.headline-font-scale');
    }

    if (key === 'subtitleFontSizeScale') {
      return t('pages.subtitle-font-scale');
    }

    if (key === 'bodyFontSizeScale') {
      return t('pages.body-font-scale');
    }

    if (key === 'textShadowColorHex') {
      return t('career-page.text-shadow');
    }

    if (key === 'overlayColorHex') {
      return t('pages.overlay-color');
    }

    return '';
  };

  const onSubmit = async (values) => {
    await onBlockUpdate(
      data?.details,
      {
        ...values,
        textShadowColorHex: hslaObjToRgbaConverter(values?.textShadowColorHex),
        overlayColorHex: hslaObjToRgbaConverter(values?.overlayColorHex),
      },
      async () => {
        await reloadData();
        data?.renderSuccessNotification();
        dispatch(setUpperBlock(null));
      }
    );
  };

  const upperBlock = settings?.upperBlock;

  return (
    <div className="flex flex-col gap-y-6">
      <BlockTitle
        label={t('career-page.settings', {
          // @ts-ignore
          blockTitle: upperBlock?.actionLabel || '',
        })}
        onBack={() => {
          dispatch(setUpperBlock(null));
        }}
      />
      <Formik initialValues={settings?.upperBlock || {}} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
          return (
            <form onSubmit={handleSubmit} className="w-full">
              {/* @ts-ignore */}
              {upperBlock?.customizeArray?.map((item) => {
                if (
                  item === 'headerTextColorHex' ||
                  item === 'subtitleTextColorHex' ||
                  item === 'backgroundColorHex' ||
                  item === 'textColorHex'
                ) {
                  return (
                    <ColorPickerComponent
                      value={values?.[item]}
                      callback={(color) => {
                        setFieldValue(item, color);
                      }}
                      testId={item}
                      label={getLabel(item)}
                    />
                  );
                }

                if (item === 'titleFontSizeScale' || item === 'subtitleFontSizeScale' || item === 'bodyFontSizeScale') {
                  return (
                    <FontScaleComponent
                      label={getLabel(item)}
                      value={getDefaultFontScale(values?.[item])}
                      callback={(fontScale: number) => {
                        setFieldValue(item, fontScale / 100);
                      }}
                      testId={item}
                    />
                  );
                }

                if (item === 'textShadowColorHex' || item === 'overlayColorHex') {
                  const shadowKey = getShadowKey(item);

                  return (
                    <ShadowColorPickerComponent
                      value={values?.[item]}
                      label={getLabel(item)}
                      shadowKey={shadowKey}
                      isShown={values?.[shadowKey]}
                      callback={(color) => {
                        setFieldValue(item, color);
                      }}
                      testId={item}
                    />
                  );
                }

                return null;
              })}
              {/* @ts-ignore */}
              <Button
                type="submit"
                color="primary"
                className="w-full !rounded-full"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {t('general.submit')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CustomizeSettings;
