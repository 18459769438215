import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Field from '../../../../components/@v2/Form/Field';
import Button from '../../../../components/Button';
import Layout from '../../../../components/layouts/default';
import ApiRequestProvider, {
  useApiRequestContext,
} from '../../../../context/@v2/ApiRequestContext';
import { useAPI } from '../../../../context/api';
import { useSelector as useSelectorToolkit } from '../../../../store';
import { createOrUpdateLocation } from '../../../../store/api/location.api';
import { selectClient } from '../../../../store/selectors/client.selector';

export const LocationFormSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().nullable().required(t('form-validator.required')),
    placeId: Yup.string()
      .nullable()
      .required(t('form-validator.invalid-location')),
    latitude: Yup.number().nullable().required(t('form-validator.required')),
    longitude: Yup.number().nullable().required(t('form-validator.required')),
    title: Yup.string().required(t('form-validator.required')),
  });
};

const LocationForm = () => {
  const { t } = useTranslation();
  const { loading, data } = useApiRequestContext();
  const history = useHistory();

  const onSubmit = async (values) => {
    try {
      await createOrUpdateLocation(values);

      history.push('/settings/location');
    } catch (error) {
      throw new Error(error);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Layout
      pretitle={t('general.settings')}
      title={t('general.location')}
      data-testid="settings-location-page-action-layout"
    >
      <Formik
        initialValues={data}
        validationSchema={LocationFormSchema(t)}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, submitCount, errors }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="anim-table-delayed max-w-2xl w-full shadow-sm p-4 bg-white rounded-lg"
            >
              <Field
                name="name"
                testId="settings-location-page-place-id-input"
                initialValue={data?.name || ''}
                label={`${t('general.street-address')} / ${t('general.city')}`}
                type="gmapplaces"
                dirty={!!submitCount}
                overrideError={errors?.name || errors?.placeId}
                required
              />
              <Field
                name="title"
                testId="settings-location-page-title-input"
                label={t('settings.location.name')}
                dirty={!!submitCount}
                required
              />
              {data?.gMapData && (
                <>
                  <div className="flex items-center gap-x-4 my-5">
                    <p className="mb-0 text-divider text-xs uppercase">
                      {t('general.address-details')}
                    </p>
                    <div className="h-[1px] bg-divider flex-grow" />
                  </div>
                  <div className="flex items-center flex-wrap gap-x-4">
                    {data?.gMapData?.province && (
                      <Field
                        className="!w-1/2"
                        name="gMapData.province"
                        label={t('general.province')}
                        disabled
                      />
                    )}
                    {data?.gMapData?.city && (
                      <Field
                        className="!w-1/2"
                        name="gMapData.city"
                        label={t('general.city')}
                        disabled
                      />
                    )}
                    {data?.gMapData?.country && (
                      <Field
                        className="!w-1/3"
                        name="gMapData.country"
                        label={t('general.country')}
                        disabled
                      />
                    )}
                    {data?.gMapData?.countryCode && (
                      <Field
                        className="!w-1/3"
                        name="gMapData.countryCode"
                        label={t('general.country-code')}
                        disabled
                      />
                    )}
                    {data?.gMapData?.postalCode && (
                      <Field
                        className="!w-1/3"
                        name="gMapData.postalCode"
                        label={t('general.postal-code')}
                        disabled
                      />
                    )}
                  </div>
                </>
              )}
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                data-testid="settings-location-page-submit-button"
              >
                {t('general.submit')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

const LocationFormWrapper = ({ fromPosition = false }) => {
  const [locationAPI] = useAPI('location');
  const { type, id } = useParams();
  const activeClient = useSelectorToolkit(selectClient);

  const DEFAULT_LOCATION_FORM = {
    id: 0,
    name: '',
    clientId: activeClient?.id,
    placeId: '',
    latitude: null,
    longitude: null,
  };

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source) => {
        if (type === 'edit' && id && !fromPosition) {
          const response = await locationAPI.read(id, {
            cancelToken: source.token,
          });

          return {
            ...DEFAULT_LOCATION_FORM,
            ...response,
          };
        }

        return DEFAULT_LOCATION_FORM;
      }}
    >
      <LocationForm />
    </ApiRequestProvider>
  );
};

export default LocationFormWrapper;
