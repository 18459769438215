import classNames from 'classnames';
import { uniqBy, uniqueId } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Badge } from 'reactstrap';
import Placeholder from '../../../components/@v2/Placeholder';
import Avatar from '../../../components/Avatar';
import Icon from '../../../components/@v2/Icon';
import { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import { getReadableDate } from '../../../helpers/date';
import { getImageServiceMediaUrl } from '../../../helpers/image';
import { getCategory } from '../../../helpers/utils';
import i18n from '../../../i18n';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../store';
import { setCandidatesWithKey } from '../../../store/slices/settings.slice';

const CandidateViewList = ({ loading, candidates }) => {
  moment.locale(i18n.language);
  const { t } = useTranslation();
  const history = useHistory();
  const candidatesSettings = useSelectorToolkit(({ settings }) => settings.candidates);
  const { data } = useApiRequestContext();
  const { id: activeIdInUrl } = useParams();

  const onSelectCandidate = (state, candidate) => {
    if (state) {
      if (candidatesSettings?.unchecked.length === 1) {
        dispatchToolkit(
          setCandidatesWithKey({
            key: 'selected',
            payload: [],
          })
        );
      } else {
        dispatchToolkit(
          setCandidatesWithKey({
            key: 'selected',
            payload: uniqBy([...candidatesSettings?.selected, candidate], (e) => e.id),
          })
        );
      }

      dispatchToolkit(
        setCandidatesWithKey({
          key: 'unchecked',
          payload: candidatesSettings?.unchecked.filter((c) => c.id !== candidate.id),
        })
      );

      return;
    }

    if (candidatesSettings?.selectAll) {
      dispatchToolkit(
        setCandidatesWithKey({
          key: 'unchecked',
          payload:
            candidatesSettings?.selected.length === 1
              ? []
              : uniqBy([...candidatesSettings?.unchecked, candidate], (e) => e.id),
        })
      );

      if (candidatesSettings?.unchecked?.length === data?.totalCount - 1) {
        dispatchToolkit(
          setCandidatesWithKey({
            key: 'selected',
            payload: [],
          })
        );

        dispatchToolkit(
          setCandidatesWithKey({
            key: 'unchecked',
            payload: [],
          })
        );

        dispatchToolkit(
          setCandidatesWithKey({
            key: 'selectAll',
            payload: false,
          })
        );

        history.push('/candidates');
      }
    } else {
      const filteredCandidate = candidatesSettings?.selected.filter((c) => c.id !== candidate.id);

      if (filteredCandidate.length === 1) {
        history.push(`/candidates/view/${filteredCandidate[0].id}`);
      }

      dispatchToolkit(
        setCandidatesWithKey({
          key: 'selected',
          payload: filteredCandidate,
        })
      );

      if (!filteredCandidate?.length) {
        history.push('/candidates');
      }
    }
  };

  const onClickCandidate = (item) => {
    if (item?.id === parseInt(activeIdInUrl, 10)) {
      return;
    }

    dispatchToolkit(
      setCandidatesWithKey({
        key: 'selected',
        payload: [item],
      })
    );

    history.push(`/candidates/view/${item.id}`);
  };
  return (
    <div className="h-full overflow-y-auto border-r border-light-100">
      {loading &&
        Array(10)
          .fill()
          .map(() => {
            return (
              <div key={uniqueId()} className="flex items-center px-3 py-3.5 border-b border-light-100">
                <Placeholder height={14} width={14} />
                <div className="flex items-center flex-grow">
                  <div className="mx-3">
                    <Placeholder width={40} circle />
                  </div>
                  <div className="flex flex-col flex-grow">
                    <div className="mb-2">
                      <Placeholder width={100} height={14} />
                    </div>
                    <Placeholder width={60} height={12} />
                  </div>
                </div>
              </div>
            );
          })}
      {!loading &&
        candidates?.map((item) => {
          const isCandidateSelected =
            candidatesSettings?.selected.find((candidate) => candidate.id === item.id) ||
            candidatesSettings.active.id === item.id;

          const isCandidateNotSelected = candidatesSettings?.unchecked.find((candidate) => candidate.id === item.id);

          return (
            <div key={item.id} className="flex items-center px-3 py-3.5 border-b border-light-100">
              <input
                type="checkbox"
                checked={!isCandidateNotSelected && (candidatesSettings.selectAll || !!isCandidateSelected)}
                onChange={(e) => {
                  onSelectCandidate(e.target.checked, item);
                }}
                className="accent-checkbox-accent"
              />
              <button
                className="flex items-center flex-grow text-left"
                type="button"
                onClick={() => {
                  onClickCandidate(item);
                }}
              >
                <Avatar
                  src={getImageServiceMediaUrl({
                    ...item?.picture,
                    name: item?.picture?.id,
                  })}
                  size={40}
                  className="mx-3"
                />
                <div className="flex flex-col flex-grow">
                  <p className="mb-0 text-sm font-bold">{item.name}</p>
                  <span className="text-xs">{getReadableDate(item?.previousActivity, true)}</span>
                </div>
                <div className="flex flex-col items-end justify-between h-[40px]">
                  {!!item.stars && (
                    <div className="inline-flex items-center mb-2">
                      <span className="mr-1 text-xs">{item.stars}</span>
                      <Icon name="star" type="fas" fontSize={10} color="rgb(255, 230, 35)" />
                    </div>
                  )}
                  <div className="flex-grow" />
                  <div className="flex items-center gap-x-3 ">
                    <div className="flex items-center">
                      {!!item.category && getCategory(item.category)}
                      {item.rejected && (
                        <Badge
                          color="danger"
                          className={classNames({
                            'ml-1': !!item.category,
                          })}
                        >
                          {t('candidates.table-header.rejected')}
                        </Badge>
                      )}
                    </div>
                  </div>
                </div>
              </button>
            </div>
          );
        })}
    </div>
  );
};

export default CandidateViewList;
