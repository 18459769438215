import Axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import CandidateEvaluationItemResponse from '@ts/apicontracts/candidates/evaluations/i-candidate-evaluation-item-response';
import UpdateCandidateCommand from '@ts/apicontracts/candidates/update-candidate-command';
import Tag from '@ts/apicontracts/candidates/details/tag';
import AddCandidateManuallyCommand from '@ts/apicontracts/candidates/add-candidate-manually-command';
import ResponseListItem from '@ts/apicontracts/candidates/list/response-list-item';
import TimelineResponseListItem from '@ts/apicontracts/candidates/timeline/response-list-item';
import UpdateCandidateRatingCommand from '@ts/apicontracts/candidates/ratings/update-candidate-rating-command';
import LinkedPositionResponseListItem from '@ts/apicontracts/candidates/linked-positions/response-list-item';

import { AxiosResponseDirect, api } from '../../helpers/api-v2';
import { format, isValidDate } from '../../helpers/date';
import { cleanUpFilter } from '../../helpers/utils';

const resource = 'candidates';

export const getCandidates = async (data, options) => {
  // todo: remove once all references to status is gone
  const payloadFilter = {
    ...data,
    filter: cleanUpFilter(data?.filter, ['status']),
  };
  const response = await api.post(`${resource}/list`, payloadFilter, options);
  return response as unknown as AxiosResponseDirect<ResponseListItem[]>;
};

export const getEvaluations = async (candidateId: string) => {
  try {
    const response = await api.get(`${resource}/${candidateId}/evaluations`);
    return response as AxiosResponse<CandidateEvaluationItemResponse[]>;
  } catch (error) {
    throw error;
  }
};

export const getTests = async (candidateId: string) => {
  try {
    const response = await api.get(`${resource}/${candidateId}/tests`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllTests = async (candidateId: string) => {
  try {
    const data = await Axios.all([getTests(candidateId), getEvaluations(candidateId)]);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateCandidate = async (candidate: UpdateCandidateCommand) => {
  try {
    const data = await api.put(resource, {
      sourceType: 11,
      agreeToBeContactedRegardingOtherPositions: false,
      applicationDate: moment().utc().format(),
      consent: false,
      fromBoostPackage: false,
      mobileApplication: false,
      rejected: false,
      talent: false,
      sourceAddress: '',
      pictureFileExt: '',
      pictureGuid: '',
      pictureUploaded: false,
      ...candidate,
    });

    return data as AxiosResponse<UpdateCandidateCommand>;
  } catch (error) {
    throw error;
  }
};

export const getAllTags = async () => {
  try {
    const data = await api.get(`${resource}/tags`);

    return data as AxiosResponse<Tag[]>;
  } catch (error) {
    throw error;
  }
};

export const addManual = async (candidate: AddCandidateManuallyCommand) => {
  try {
    const data = await api.post(resource, {
      sourceType: 11,
      agreeToBeContactedRegardingOtherPositions: false,
      applicationDate: moment().utc().format(),
      consent: false,
      fromBoostPackage: false,
      mobileApplication: false,
      rejected: false,
      talent: false,
      sourceAddress: '',
      pictureFileExt: '',
      pictureGuid: '',
      pictureUploaded: false,
      ...candidate,
    });

    return data as AxiosResponse<AddCandidateManuallyCommand>;
  } catch (error) {
    throw error;
  }
};

export const createOrUpdateCandidate = async (
  values: UpdateCandidateCommand & {
    position: { id: string; label: string; position: string; value: string; pipelines: { id: string }[] };
    pipelineStepId: string;
  }
) => {
  try {
    const payload = {
      ...values,
      dateOfBirth: isValidDate(values.dateOfBirth) ? new Date(format('date', values.dateOfBirth)) : null,
      positionId: values?.position?.id || values?.position?.value,
      positionName: values?.position?.position || values?.position?.label,
      pipelineStepId:
        values?.position?.pipelines && values?.position?.pipelines?.length
          ? values?.position?.pipelines?.[0]?.id
          : values?.pipelineStepId,
    };

    let data = null;

    if (values?.id) {
      data = await updateCandidate(payload);
    } else {
      // @ts-ignore
      data = await addManual(payload);
    }

    return data;
  } catch (error) {
    throw error;
  }
};

export const getCandidate = async (id: string) => {
  try {
    const [candidate, timelines] = await Axios.all([
      api.get(`${resource}/${id}`),
      api.get(`${resource}/${id}/timelines`),
    ]);

    return {
      ...candidate,
      timelines,
    } as AxiosResponse<ResponseListItem & TimelineResponseListItem>;
  } catch (error) {
    throw error;
  }
};

export const updateComment = async (params) => {
  const response = await api.put(`${resource}/comments`, {
    candidateId: params.candidateId,
    commentId: params.id,
    value: params.valueString,
    valueMentions: params.valueMentions,
    mentionsAuth0Id: params.mentionsAuth0Id,
  });

  return response;
};

export const setCandidateRating = async ({
  params,
  type,
}: {
  params: UpdateCandidateRatingCommand & { ratingId: string };
  type: string;
}) => {
  try {
    const rate = await api[type](`${resource}${type === 'delete' ? `/${params.candidateId}` : ''}/ratings`, params);

    return rate;
  } catch (error) {
    throw error;
  }
};

export const getLinkedPositions = async (candidateId: string) => {
  try {
    const response = await api.get(`${resource}/${candidateId}/linked-positions`);
    return response as AxiosResponse<LinkedPositionResponseListItem>;
  } catch (error) {
    throw error;
  }
};

interface TagPayload {
  candidateId: number;
  dateStamp: string;
  name: string;
  userId: number;
}

export const createTag = async (payload: TagPayload) => {
  try {
    const response = await api.post(`${resource}/tags`, payload);
    return response as AxiosResponse<any>;
  } catch (error) {
    throw error;
  }
};

export const getTagsLookup = async (options = {}) => {
  try {
    const response = await api.post(`${resource}/tags/lookup`, options);
    return response as AxiosResponse<any>;
  } catch (error) {
    throw error;
  }
};
