import React from 'react';
import { Field as FieldFormik } from 'formik';
import { FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap';
import { get, isArray, isNull, kebabCase } from 'lodash';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import classNames from 'classnames';
import WysiwygEditor from '../../wysiwyg/WysiwygEditor';
import Select from '../Select/Select';
import Switch from '../../input/Switch';
import DatePicker from '../../input/DatePicker';

const GOOGLE_API_KEY = 'AIzaSyB0Jwl1rPEQnqtrAa_qgZyqka5Ok9nW8lE';

interface Props {
  name: string;
  type?: string;
  required?: boolean;
  label?: string;
  helperText?: string;
  toolbar?: string;
  disabled?: boolean;
  multiple?: boolean;
  defaultOptions?: any;
  defaultFilters?: string;
  objectFilters?: any;
  useObjectFilters?: boolean;
  callback?: (params1: any, params2?: any) => void;
  searchKey?: string;
  keys?: string[];
  api?: any;
  placeholder?: string;
  initialValue?: string;
  className?: string;
  testId?: string;
  overrideError?: string;
  inputGroupClassName?: string;
  inputGroupComponent?: any;
  style?: any;
  clearable?: boolean;
  dark?: boolean;
  id?: number | string | null;
  searchable?: boolean;
  wysiwygOptions?: any;
  preload?: boolean;
  readOnly?: boolean;
  color?: string;
}

const Field = ({
  name,
  type = 'text',
  required = false,
  label,
  helperText,
  toolbar = 'base',
  disabled = false,
  multiple = false,
  defaultOptions = [],
  defaultFilters = '',
  objectFilters = null,
  useObjectFilters = false,
  callback = null,
  searchKey = 'label',
  keys = ['value', 'label'],
  api,
  placeholder = '',
  initialValue = '',
  className = '',
  testId = '',
  overrideError = '',
  inputGroupClassName = '',
  inputGroupComponent = null,
  style = {},
  clearable = false,
  dark = false,
  id = null,
  searchable = true,
  wysiwygOptions = {},
  preload,
  ...rest
}: Props) => {
  const dataTestId = kebabCase(testId || `form-${name}`);
  const getFormInput = ({ field, form }, showError) => {
    const { values, setFieldValue } = form;
    const defaultValue = field?.value || get(values, name);

    if (type === 'gmapplaces') {
      return (
        <GooglePlacesAutocomplete
          apiKey={GOOGLE_API_KEY}
          debounce={200}
          initialValue={initialValue}
          renderInput={(props) => (
            <Input
              autoComplete="off"
              invalid={showError}
              name={name}
              {...props}
              data-testid={dataTestId}
              placeholder=""
              onChange={(e) => {
                props.onChange(e);

                setFieldValue(name, e.target.value);
              }}
            />
          )}
          onSelect={({ description, place_id: placeId }) => {
            if (placeId) {
              geocodeByPlaceId(placeId)
                .then(([result]) => getLatLng(result))
                .then((data) => {
                  const { lat, lng } = data;
                  setFieldValue('latitude', lat);
                  setFieldValue('longitude', lng);
                  setFieldValue('placeId', placeId);
                  setFieldValue('name', description);

                  if (callback) {
                    callback({
                      ...data,
                      placeId,
                      description,
                    });
                  }
                });
            }
          }}
        />
      );
    }

    if (type === 'switch') {
      return (
        // @ts-ignore
        <Switch
          dark={dark}
          data-testid={dataTestId}
          checked={defaultValue || false}
          label={label}
          onChange={(state) => {
            setFieldValue(name, state);

            if (callback) {
              callback(values, state);
            }
          }}
          disabled={disabled}
          {...rest}
        />
      );
    }

    if (type === 'date') {
      return (
        <DatePicker
          testId={dataTestId}
          {...field}
          date={defaultValue ? new Date(defaultValue) : null}
          invalid={showError}
          onChange={(date) => {
            setFieldValue(name, date);

            if (callback) {
              callback(date);
            }
          }}
        />
      );
    }

    if (type === 'select') {
      let value = [];

      if (defaultValue) {
        if (isArray(defaultValue)) {
          value = defaultValue;
        } else {
          value = [defaultValue];
        }
      }

      return (
        // @ts-ignore
        <Select
          preload={preload}
          dark={dark}
          searchable={searchable}
          clearable={clearable}
          searchKey={searchKey}
          multiple={multiple}
          defaultOptions={api ? null : defaultOptions}
          api={api}
          keys={keys}
          defaultFilters={defaultFilters}
          value={value}
          invalid={showError}
          onSelect={(values) => {
            setFieldValue(name, multiple ? values : values?.[0]);

            if (callback) {
              callback(values);
            }
          }}
          resource={dataTestId}
          id={dataTestId}
          disabled={disabled}
          defaultValue={initialValue}
          objectFilters={objectFilters}
          useObjectFilters={useObjectFilters}
        />
      );
    }

    if (type === 'textarea') {
      return (
        <Input
          type="textarea"
          data-testid={dataTestId}
          autoComplete="off"
          name={name}
          invalid={showError}
          disabled={disabled}
          style={{ height: 150 }}
          {...field}
          onChange={(e) => {
            field.onChange(e);

            if (callback) {
              callback(e.target.value);
            }
          }}
          value={defaultValue}
          placeholder={placeholder}
        />
      );
    }

    if (type === 'wysiwyg') {
      return (
        <WysiwygEditor
          id={id}
          theme={dark ? 'dark' : null}
          resource={dataTestId}
          value={defaultValue || ''}
          toolbar={toolbar}
          toolbarId={dataTestId}
          onEditorStateChange={(content) => {
            let text = content;

            if (content === '<p><br></p>') {
              text = '';
            }

            setFieldValue(name, text);

            if (callback) {
              callback(values);
            }
          }}
          invalid={showError}
          disabled={disabled}
          {...wysiwygOptions}
        />
      );
    }

    if (type === 'number') {
      return (
        <Input
          data-testid={dataTestId}
          autoComplete="off"
          type="text"
          name={name}
          invalid={showError}
          disabled={disabled}
          {...field}
          className={classNames(
            'form-control'
            // dark &&
            //   '!bg-white !border-career-page-header-button text-black'
          )}
          onChange={(e) => {
            const { value } = e.target;
            if (!value) {
              field.onChange(e);
              return;
            }
            if (!value.match(/^[0-9]*\.?[0-9]*$/)) return;

            setFieldValue(name, value);

            if (callback) {
              callback(value);
            }
          }}
          value={!isNull(defaultValue) ? defaultValue : ''}
          placeholder={placeholder}
        />
      );
    }

    return (
      <Input
        data-testid={dataTestId}
        type={type}
        name={name}
        invalid={showError}
        disabled={disabled}
        {...field}
        className={classNames(
          'form-control'
          // dark &&
          //   '!bg-white !border-career-page-header-button text-black'
        )}
        onChange={(e) => {
          field.onChange(e);

          if (callback) {
            callback(e.target.value);
          }
        }}
        value={defaultValue || ''}
        placeholder={placeholder}
      />
    );
  };

  return (
    <FieldFormik name={name}>
      {(formikMeta) => {
        const { meta, form } = formikMeta;
        const showError = (!!form?.submitCount && meta?.error) || !!overrideError;

        return (
          <FormGroup className={classNames('w-full', className)} style={style}>
            {!!label && type !== 'switch' && (
              <Label className={classNames('block', dark ? '!text-black' : '')}>
                {label} {required && '*'}
              </Label>
            )}
            <div className={classNames(inputGroupClassName)}>
              {getFormInput({ ...formikMeta, value: formikMeta?.value || '' }, !!showError)}
              {inputGroupComponent}
            </div>
            {helperText && <FormText>{helperText}</FormText>}
            {showError && (
              <FormFeedback className="!block">
                {overrideError || typeof meta?.error === 'object' ? JSON.stringify(meta.error) : meta.error}
              </FormFeedback>
            )}
          </FormGroup>
        );
      }}
    </FieldFormik>
  );
};

export default Field;
