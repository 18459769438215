import moment from 'moment';

const publicUrl = global?.appConfig?.public_url;
const storageURL = global?.appConfig?.storage_url;
const imageServiceBaseUrl = global?.appConfig?.image_service_base_url;
// TODO - Based on discussion with Michal we are going to keep it local until BE returnes this value explicitly
const IMAGE_BASE_FOLDER = 'loginpictures';
// Image files always need a width value
const MinimumImageWidth = '50';
const MaximumImageWidth = '500';
const imageFallback = (src) => {
  switch (src) {
    case 'user':
      return `${publicUrl || ''}/images/avatars/default-avatar.svg`;
    case 'canditate':
      return `${publicUrl || ''}/images/avatars/default-avatar.svg`;
    case 'customer':
      return `${publicUrl || ''}/images/avatars/default-avatar.svg`;
    default:
      break;
  }
  return '';
};

export const getMediaUrl = (image) => {
  if (!image) return null;

  const { type, path, container, id, name, guid, fileExt } = image;
  let params = '';

  if (path && path.includes('vimeo')) {
    params = '?dnt=true';
  }

  return `${storageURL || ''}/${type || path || container}/${name || id}-${guid}.${fileExt}${params}`;
};

export const getImageServiceMediaUrl = (image) => {
  if ((!image && !image?.fileExt) || (image && !image?.name)) return null;

  const { path, container, name, guid, fileExt, width } = image;

  const largeImages = ['pagepictures', 'positionpictures', 'positionimages', 'loginpictures'];
  const imageWidthFallback = largeImages.some((item) => (path || container).includes(item))
    ? MaximumImageWidth
    : MinimumImageWidth;

  return `${imageServiceBaseUrl}/${path || container}/${name}-${guid}.${fileExt}?width=${width || imageWidthFallback}`;
};

export const validateImgUrl = (imageUrl, imageSource) => {
  const img = new Image();
  img.src = imageUrl;
  img.onerror = () => imageFallback(imageSource);

  return imageUrl;
};

export const getImageUrlValue = (value, src = 'user') => {
  const { loginId, pictureGuid, pictureFileExt } = value;
  if (!pictureGuid || !pictureFileExt) return null;
  if (pictureGuid !== 'no-image') {
    const imageServer = `${imageServiceBaseUrl}/${IMAGE_BASE_FOLDER}/`;

    return validateImgUrl(`${imageServer}${loginId}-${pictureGuid}.${pictureFileExt}`, src);
  }

  return `${publicUrl}/images/avatars/default-avatar.svg`;
};

export const defaultImageUpload = {
  documentId: 0,
  path: '',
  name: '',
  guid: '',
  fileExt: '',
  uploadedDate: moment().utc().format(),
  focusPointJsonString: null,
};

export const defaultFocusPointCoords = {
  percentageX: 0,
  percentageY: 0,
  focusX: 0,
  focusY: 0,
};

export const getConvertedImageUrlString = (file) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.readAsDataURL(file);
  });
};
