/* eslint-disable no-useless-escape */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { useToasts } from 'react-toast-notifications';
import { log } from '../../../helpers/utils';
import Icon from '../Icon';
import { useSelector } from '../../../store';
import { selectClient } from '../../../store/selectors/client.selector';
import dispatchCustomEvent from '../../../helpers/dispatch-custom-event';

const toastConfig = {
  appearance: 'info',
  autoDismiss: true,
  autoDismissTimeout: 5000,
};

const CopyActionButton = ({
  id,
  index,
  alternativeApplyFormLink,
  publicLink,
  publicApplyFormLink,
  useAlternativeApplyForm,
}) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const history = useHistory();
  const activeClient = useSelector(selectClient);
  const isUrl = (string) => {
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(string);
  };

  const positionFormUrl = '/p';

  const copyToClipBoard = (urlText, isApplyForm) => {
    const textField = document.createElement('textarea');
    textField.innerText = isUrl(urlText)
      ? urlText
      : `${global?.appConfig?.external_protocol}${activeClient.key}.${
          global?.appConfig?.external_domain_name
        }${positionFormUrl}/${urlText}${isApplyForm ? '#apply-form' : ''}`;
    if (document.body) {
      document.body.appendChild(textField);
    }

    try {
      textField.select();
      document.execCommand('copy');
      textField.remove();
      addToast(t('general.the-link-is-copied'), toastConfig);
      dispatchCustomEvent('clipboard-copy', { text: urlText });
    } catch (error) {
      log(error);
    }
  };

  const applyFormLink = useAlternativeApplyForm ? alternativeApplyFormLink : publicApplyFormLink;

  const onSelect = ({ key }) => {
    switch (key) {
      case 'edit':
        history.push({
          pathname: `positions/edit/${id}`,
          state: {
            section: 'VisiblePosition',
            statusNumber: 0,
          },
        });
        return;

      case 'public-link':
        copyToClipBoard(publicLink, false);
        return;

      case 'apply-form-link':
        copyToClipBoard(applyFormLink, true);
        return;

      default:
        log('cannot find an action');
        break;
    }
  };

  const menu = (
    <Menu onSelect={onSelect}>
      <MenuItem key="public-link">
        <button type="button" data-testid={`option-${index}-copy-public-link`} data-url={publicLink}>
          <div className="flex items-center">
            <Icon name="link" className="mr-2" />
            {`${t('general.copy-link')} - ${t('general.position-page')}`}
          </div>
        </button>
      </MenuItem>
      <MenuItem key="apply-form-link">
        <button type="button" data-testid={`option-${index}-copy-apply-form-link`} data-url={applyFormLink}>
          <div className="flex items-center">
            <Icon name="link" className="mr-2" />
            {`${t('general.copy-link')} - ${t('general.apply-form')}`}
          </div>
        </button>
      </MenuItem>
    </Menu>
  );

  return (
    <div className="flex align-items-center justify-content-center flex-grow-1">
      <Dropdown trigger={['click']} overlay={menu} animation="slide-up">
        <button type="button" data-testid={`position-${index}-copy`}>
          <Icon name="link" />
        </button>
      </Dropdown>
    </div>
  );
};

export default CopyActionButton;
