import { AxiosResponse } from 'axios';
import DepartmentsDetailsResponse from '@ts/apicontracts/departments/details/response';
import ResponseListItem from '@ts/apicontracts/departments/search/response-list-item';
import RestoreDepartmentCommand from '@ts/apicontracts/departments/restore-department-command';
import DeleteDepartmentCommand from '@ts/apicontracts/departments/delete-department-command';
import { api } from '../../helpers/api-v2';

const resource = '/departments';

export const departmentLookup = async (params, options = {}) => {
  const departments = await api.post(`${resource}/lookup`, params, options);
  return departments;
};

export const browseDepartments = async (params = {}, options = {}) => {
  const departments = await api.post(`${resource}/list`, params, options);
  return departments as AxiosResponse<DepartmentsDetailsResponse>;
};

export const restoreDeparment = async (id: RestoreDepartmentCommand, options = {}) => {
  try {
    const data = await api.post(`${resource}/${id}/restore`, options);
    return data as AxiosResponse<DepartmentsDetailsResponse>;
  } catch (error) {
    throw error;
  }
};

export const deleteDeparment = async (id: DeleteDepartmentCommand, options = {}) => {
  try {
    const data = await api.delete(`${resource}/${id}`, options);
    return data as AxiosResponse<DepartmentsDetailsResponse>;
  } catch (error) {
    throw error;
  }
};
export const findDepartment = async (id, options = {}) => {
  try {
    const data = await api.get(`${resource}/${id}`, options);
    return data as AxiosResponse<ResponseListItem>;
  } catch (error) {
    throw error;
  }
};

export const createOrUpdateDepartment = async (values, options = {}) => {
  try {
    let data = null;

    if (values?.id) {
      data = await api.put(resource, values, options);
    } else {
      data = await api.post(resource, values, options);
    }

    return data;
  } catch (error) {
    throw error;
  }
};
