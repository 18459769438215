import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'quill-mention';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.css';
import '../../assets/sass/wysiwyg/quill.ws.sass';
import classnames from 'classnames';
import '../../assets/sass/wysiwyg/quill.dark.sass';
import '../../assets/sass/wysiwyg/quill.fix.sass';
import v4 from 'uuid/v4';
import ToolbarFactory from './ToolbarFactory';
import placeHolderConstants from '../../constants/PlaceHolderWysiwyg';
import { EditorVariables as placeholderPositionTemplateConstants } from '../../constants/PositionTemplate';
import '../../assets/sass/wysiwyg.sass';
import events from '../../helpers/events';
import { TEMPLATE_CHANGE_EVENT } from './constants';

function insertTemplate(value) {
  const template = JSON.parse(value);
  const textLength = this.quill.getLength();
  this.quill.deleteText(0, textLength);
  this.quill.pasteHTML(0, template.value);
  this.quill.setSelection(this.quill.getLength() + 1);
  events.$emit(TEMPLATE_CHANGE_EVENT, template.item);
}

function onBackSpace(range) {
  if (range.index === 0) return;
  const editorContent = this.quill.getContents();
  const editorContentOps = editorContent && editorContent?.ops ? editorContent?.ops : [];
  if (range.length === 0) {
    if (editorContentOps.length >= 2) {
      if (
        editorContentOps[editorContentOps.length - 1]?.insert === '\n' &&
        editorContentOps[editorContentOps.length - 2]?.insert?.placeholder
      ) {
        editorContentOps[editorContentOps.length - 2] = { insert: '\n' };
        this.quill.setContents({
          ops: editorContentOps
        }, 'user');
      }
    }
    this.quill.deleteText(range.index - 1, 1, Quill.sources.USER);
  } else {
    this.quill.deleteText(range, Quill.sources.USER);
  }
}

function WysiwygEditor({
  defaultValue,
  onEditorStateChange,
  onTemplateChange,
  onBlur,
  toolbar,
  placeholder,
  readOnly,
  className,
  theme,
  options,
  bounds,
  invalid,
  resource,
  editorName,
  onEditorBlur,
  apiFunction,
  onMentionAdd,
  ...rest
}) {
  const positionTemplateTypeToolbars = ['position-template-variables', 'pitch'];
  const listTypeToolbars = [
    'position',
    'email-var-templates',
    'position-template-variables',
    'pitch',
    'schedule-interview',
    'candidate-email-toolbar',
  ];
  const [toolbarId] = useState(`toolbar-${v4()}`);

  const modules = {
    toolbar: {
      container: `#${toolbarId}`,
      handlers: {
        template: insertTemplate,
      },
    },
    placeholder: {
      delimiters: ['{', '}'],
      placeholders: positionTemplateTypeToolbars.includes(toolbar)
        ? placeholderPositionTemplateConstants
        : placeHolderConstants,
    },
    clipboard: {
      matchVisual: false,
    },
    keyboard: {
      bindings: {
        list: {
          key: 'backspace',
          context: {
            format: ['list']
          },
          handler: onBackSpace
        }
      }
    },
  };

  if (onMentionAdd) {
    modules.mention = {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      source: (searchTerm, renderList) => {
        if (searchTerm.length === 0) {
          renderList([], searchTerm);
        } else if (apiFunction) {
          apiFunction(searchTerm)
            .then((matches) => {
              renderList(matches, searchTerm);
            })
            .catch((error) => {
              console.error('Error fetching mentions:', error);
            });
        }
      },
      renderItem: (item) => {
        return `<span>${item.value}</span>`;
      },
      onSelect(item, insertItem) {
        onMentionAdd(item.id);
        insertItem(item);
      },
    };
  }

  useEffect(() => {
    events.$on(TEMPLATE_CHANGE_EVENT, (item) => {
      onTemplateChange(item);
    });
    return () => {
      events.$off(TEMPLATE_CHANGE_EVENT);
    };
  }, [onTemplateChange]);

  let wysiwyg;
  if (listTypeToolbars.includes(toolbar)) {
    wysiwyg = options.listItems && (
      <>
        <ToolbarFactory toolbar={toolbar} onTemplateChange={onTemplateChange} toolbarId={toolbarId} options={options} />
        <ReactQuill
          defaultValue={defaultValue || ''}
          readOnly={readOnly}
          theme="snow"
          modules={modules}
          placeholder={placeholder}
          formats={[
            'bold',
            'italic',
            'font',
            'underline',
            'list',
            'bullet',
            'link',
            'header',
            'size',
            'format',
            'align',
            'image',
            'video',
            'placeholder',
            'template',
            'mention',
          ]}
          onChange={(content) => {
            onEditorStateChange(content);
          }}
          bounds={bounds}
          onBlur={onEditorBlur}
          {...rest}
        />
      </>
    );
  } else {
    wysiwyg = (
      <>
        <ToolbarFactory toolbar={toolbar} toolbarId={toolbarId} />
        <ReactQuill
          defaultValue={defaultValue || ''}
          readOnly={readOnly}
          theme="snow"
          modules={modules}
          placeholder={placeholder}
          formats={[
            'bold',
            'italic',
            'font',
            'underline',
            'list',
            'bullet',
            'link',
            'header',
            'size',
            'format',
            'align',
            'image',
            'video',
            'placeholder',
            'mention',
          ]}
          bounds={bounds}
          onChange={(content) => {
            onEditorStateChange(content);
          }}
          invalid={invalid}
          onBlur={onEditorBlur}
          {...rest}
        />
      </>
    );
  }

  return (
    <div
      className={classnames(`ql-${theme}`, className, {
        'is-invalid': invalid === true,
      })}
      onBlur={onBlur}
      data-testid={`${resource}-${editorName ? `${editorName}-` : ''}wysiwyg`}
    >
      {wysiwyg}
    </div>
  );
}

WysiwygEditor.defaultProps = {
  toolbar: 'base',
  onTemplateChange: () => {},
  placeholder: '',
  className: '',
  theme: 'snow',
  options: {},
  bounds: '.transition-group-container',
  invalid: false,
  onBlur: () => {},
  onEditorBlur: () => {},
  resource: 'general',
};

export default WysiwygEditor;
