/* eslint-disable quote-props */

const mapOptions = (constantObj) =>
  Object.entries(constantObj).map(([value, label]) => ({
    value,
    label,
  }));

export const SiteType = mapOptions({
  0: 'Karriär',
  1: 'Kampanj',
  2: 'Nätverk',
});

export const ClientLanguage = mapOptions({
  'sv-SE': 'Svenska',
  'en-US': 'English',
  'da-DK': 'Dansk',
  'de-DE': 'Deutsch',
  'fi-FI': 'Suomi',
  'nb-NO': 'Norsk',
  'it-IT': 'Italian',
});

export const ClientFormDefaults = {
  clientId: 0,
  name: '',
  key: '',
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPhoneNumber: '',
  type: null,
  language: 'en-US',
  siteType: null,
  customUrl: '',
  created: new Date(),
  numPositions: 0,
  numCandidates: 0,
  numCandidatesLastWeek: 0,
  applySettings: {
    applySettingsId: 0,
    phoneNumber: 0,
    birthDate: 0,
    motivation: 0,
    cv: 0,
    showLinkedInButton: false,
    showApprovalToBeContactedRegardingOtherPositions: false,
    showImage: false,
  },
  sendGlobalMailToCandidate: false,
  globalEmailSubject: '',
  globalEmailText: '',
  globalEmailDelayHours: 0,
  globalNotifiedUserIdJsonString: '',
  settingsModel: {
    clientSettingsId: 0,
    showTagsInCandidatesTable: false,
    sendRejectEmailDefaultState: false,
    alwaysNotifyResponsibleWhenCandidatesApply: false,
    notifyResponsibleWhenPositionPublishEnds: false,
    showRekryShop: null,
    positionLists: [],
    showPublishOnAms: false,
    gdprShowImportAndExport: false,
    gdprShowOnApplyForm: false,
    showShopPrices: false,
    useNewPublicPage: false,
    usePositionTypes: false,
    useTestIntegration: false,
    assessioOrgId: '',
    gdprAutoHideCandidates: false,
    gdprHideDirectApplicationMonths: 0,
    gdprHideSpontaneousApplicationMonths: 0,
    gdprAutoDeleteCandidates: false,
    gdprDeleteDirectApplicationMonths: 24,
    gdprDeleteSpontanenousApplicationMonths: 24,
    gdprCustomPrivacyPolicyLink: '',
    showNewsAndSubscribers: false,
    showPrepaidAdsQuestion: false,
    legacyOrderProcessing: true,
    facebookPageId: '',
    facebookAdAccountId: '',
    instagramAccountId: '',
  },
  clientSettingsId: 0,
  clientSettingsJsonString: null,
  clientStatistics: {
    clientStatisticsId: 0,
    conversionRate: 0,
    visitorsLastWeek: 0,
    totalVisitorsOverTimeString: null,
    uniqueVisitorsOverTimeString: null,
    organicApplicationsOverTimeString: null,
    boostApplicationsOverTimeString: null,
    visitorReferralStatisticsString: null,
    lastUpdated: new Date(),
  },
  guid: '',
  googleAnalyticsId: '',
  indeedConversionCode: '',
  boostPackageStatistics: [],
  logoUrl: null,
  forceHttps: false,
  inactivationDate: null,
  active: false,
  privacyPolicy: '',
  pageSettings: {
    clientPageSettingsId: 0,
    clientId: 0,
    browserTabTitle: '',
    pageDescription: '',
    companyWebUrl: '',
    allowRobotsToIndexPages: false,
    showRecruiterInformation: false,
  },
  stylesString: '',
  companyIndustry: [],
};

export const RekryShopOptions = [
  {
    value: 0,
    label: 'No',
  },
  {
    value: 1,
    label: 'Yes',
  },
  {
    value: 2,
    label: 'Only recruiting managers or administrators',
  },
];

export const SubscriptionDeliveryOptions = [
  {
    label: 'settings.subscriptions.weekly',
    value: 2,
  },
  {
    label: 'settings.subscriptions.direct',
    value: 1,
  },
  {
    label: 'settings.subscriptions.both',
    value: 0,
  },
];

export default {
  SiteType,
  ClientLanguage,
  RekryShopOptions,
  ClientFormDefaults,
  SubscriptionDeliveryOptions,
};
