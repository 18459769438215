import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

const Switch = ({ checked, onChange, label, className, disabled, dark, ...rest }) => {
  const [isActive, setIsActive] = useState(checked || false);

  useEffect(() => {
    if (checked === undefined) return;
    setIsActive(checked);
  }, [checked]);

  return (
    <div
      data-testid="switch-component"
      data-checked={isActive}
      {...rest}
      onClick={() => {
        if (disabled) return;
        setIsActive((current) => {
          const newState = !current;
          if (onChange) onChange(newState);
          return newState;
        });
      }}
      className={classnames('flex items-center switch data-test-switch', className)}
    >
      <div
        className={classnames(
          'switch-placeholder',
          {
            'switch-placeholder--active': isActive,
          },
          // rest?.color === 'secondary' && isActive ? '!bg-light-blue-700' : '',
          // rest?.color !== 'secondary' && isActive ? '!bg-primary' : ''
          isActive ? '!bg-primary' : ''
        )}
      >
        <span />
      </div>
      {label && <p className={classnames(dark ? 'text-black' : '')}>{label}</p>}
    </div>
  );
};

Switch.defaultProps = {
  checked: false,
  onChange: () => {},
  label: '',
  className: '',
  dark: false,
};

export default Switch;
