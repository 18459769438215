import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUser, setUser } from '../store/api/user.api';
import { dispatch as dispatchToolkit, useSelector as useSelectorToolkit } from '../store';
import { resetCart } from '../store/slices/cart.slice';
import { setUserClientId, setUserData } from '../store/slices/user.slice';
import { getClient, getClientSettings, getClientsConfig } from '../store/thunks/client.thunk';
import { selectUser } from '../store/selectors/user.selector';
import { getAllSettings } from '../store/thunks/settings.thunks';
import { selectClient } from '../store/selectors/client.selector';
import { toggleSplash } from '../helpers/toggle-splash';

export const useSelectClient = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const selectedClient = useSelectorToolkit(selectClient);
  const currentUser = useSelectorToolkit(selectUser);

  const history = useHistory();

  const selectClientById = async (id: number, withRedirect: boolean = true): Promise<number | null> => {
    if (!id || loading) {
      return null;
    }

    if (id === selectedClient.id && withRedirect) {
      history.push('/positions');
      return new Promise((resolve) => {
        resolve(id);
      });
    }

    toggleSplash(true);
    setLoading(true);

    try {
      await setUser({
        clientId: id,
      });
      const response = await getUser();

      await dispatchToolkit(resetCart());
      await dispatchToolkit(
        setUserData({
          ...currentUser,
          ...response,
          // @ts-ignore
          userId: response?.activeUserId,
          authenticated: true,
        })
      );
      // @ts-ignore
      dispatchToolkit(setUserClientId({ clientId: response.clientId }));
      await dispatchToolkit(getAllSettings(true)).unwrap();
      await dispatchToolkit(getClientSettings()).unwrap();
      await dispatchToolkit(
        getClient({
          superAdmin: currentUser.superAdmin,
          id,
        })
      ).unwrap();
      await dispatchToolkit(getClientsConfig()).unwrap();

      if (withRedirect) {
        history.push('/positions');
      }

      return new Promise((resolve) => {
        resolve(id);
        setLoading(false);
        toggleSplash(false);
      });
    } catch (error) {
      setLoading(false);
      toggleSplash(false);
      return null;
    }
  };

  return { selectClientById, selectClientId: selectedClient.id, loading };
};
