import React from 'react';
import '../../assets/sass/candidates/comments.sass';
import Timeline from './Timeline';
import CommentForm from './CommentForm';

const Comments = ({
  candidates,
  activeCandidate,
  filters,
  callback,
  entries = [],
  ...rest
}) => (
  <div className="comment-area">
    <CommentForm
      candidates={candidates}
      {...rest}
      filters={filters}
      callback={callback}
    />
    {candidates?.length === 1 && candidates[0] && (
      <Timeline
        callback={callback}
        candidates={candidates}
        activeCandidate={activeCandidate}
        {...rest}
        entries={entries?.filter((item) => [4]?.includes(item?.type))}
      />
    )}
  </div>
);

export default Comments;
