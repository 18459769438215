import React from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import uuidv4 from 'uuid/v4';

const id = uuidv4();
const TextSizeToolbar = () => {
  const { t } = useTranslation();
  const testId = 'textSizeToolbar';

  return (
    <div
      className="ql-formats"
      href="#"
      data-testid={testId}
      id={`text-size-${id}`}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <UncontrolledTooltip
        placement="top"
        data-testid={`${testId}--textSize--tooltip`}
        target={`text-size-${id}`}
      >
        {t('html-editor.text-size')}
      </UncontrolledTooltip>
      <select className="ql-size" defaultValue="normal">
        <option data-testid={`${testId}--textSize--large`} value="large">
          {t('html-editor.large-text')}
        </option>
        <option data-testid={`${testId}--textSize--normal`} value="normal">
          {t('html-editor.normal-text')}
        </option>
        <option data-testid={`${testId}--textSize--small`} value="small">
          {t('html-editor.small-text')}
        </option>
      </select>
    </div>
  );
};

export default TextSizeToolbar;
