import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import classNames from 'classnames';

library.add(fab);
library.add(fal);
library.add(far);
library.add(fas);
library.add(fad);

const Icon = ({ name, type, size, color, fontSize, className, ...rest }) => (
  <FontAwesomeIcon
    icon={[type, name]}
    size={size}
    color={color}
    style={{
      fontSize,
      ...(rest.style || {}),
    }}
    {...rest}
    className={classNames(className, 'stroke-current')}
  />
);

Icon.defaultProps = {
  type: 'fal',
  size: '1x',
  color: 'inherit',
  fontSize: null,
};

export default Icon;
