import helper, { api } from '../helpers/api';

const resource = 'ScreeningCriterias';

export default {
  ...helper(resource),
  getListById: (id) => api.get(`${resource}/List/?filters=positionId==${id}`),
  getScreeningCriteria: (id) => api.get(`${resource}/Get/${id}`),
  addScreeningCriteria: (form = {}, options = {}) =>
    api.post(`${resource}/Add`, form, options),
  updateScreeningCriteria: (form = {}, options = {}) =>
    api.put(`${resource}/Update`, form, options),
  deleteScreeningCriteria: (form) => api.delete(`${resource}/Delete`, form),
  getTypes: () => api.get(`${resource}/GetTypes`),
};
