/* eslint-disable no-undef */
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Fullscreen from 'react-full-screen';
import Icon from './@v2/Icon';
import { isIOS } from '../helpers/utils';

const LightboxContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  display: flex;
  flex-direction: column;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  flex-grow: 0;
  padding: 15px 10px;
  position: relative;
  z-index: 3;
  width: 100px;
  margin-bottom: 5px;
`;

const HeaderControls = styled.div`
  display: inline-flex;
  align-items: center;
  flex-grow: 0;
`;

const Body = styled.div`
  max-width: 1000px;
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
  overflow-y: auto;
  z-index: 2;
  margin: 0 auto;
`;

const BodyContent = styled.div`
  height: 100%;
  overflow-y: auto;
  border-radius: 5px;
`;

const Lightbox = ({ onClose, activeDocument }) => {
  const bodyContent = useRef();
  const testId = 'lightbox-component';
  const [isWindowFullScreen, setIsWindowFullScreen] = useState(false);

  const renderLightBox = () => (
    <LightboxContainer data-testid={testId}>
      <Overlay onClick={onClose} />
      <Header>
        <HeaderControls>
          <Icon
            color="white"
            name={isWindowFullScreen ? 'compress-wide' : 'expand-wide'}
            className="clickable lightbox--fullscreen"
            size="2x"
            onClick={() => {
              setIsWindowFullScreen(!isWindowFullScreen);
            }}
          />
          <Icon
            color="white"
            name="times"
            className="clickable lightbox--close"
            size="2x"
            onClick={onClose}
            style={{
              marginLeft: 20,
            }}
          />
        </HeaderControls>
      </Header>
      <Body>
        <BodyContent ref={bodyContent}>
          <iframe
            src={activeDocument.documentUrl}
            title={activeDocument.documentUrl}
            frameBorder="0"
            style={{
              overflow: 'hidden',
              height: '100%',
              width: '100%',
            }}
            height="100%"
            width="100%"
          />
        </BodyContent>
      </Body>
    </LightboxContainer>
  );

  return (
    <>
      {!isIOS() ? (
        <Fullscreen
          enabled={isWindowFullScreen}
          onChange={(fullscreen) => {
            if (!fullscreen) {
              setIsWindowFullScreen(false);
            }
          }}
        >
          {renderLightBox()}
        </Fullscreen>
      ) : (
        renderLightBox()
      )}
    </>
  );
};

export default Lightbox;
