import moment from 'moment';
import qs from 'query-string';
import helper, { api } from '../helpers/api';
import { convertMultipartToJson } from '../helpers/convert-multipart-to-json';

const resource = 'candidates';

export default {
  ...helper(resource),
  delete: (id) => api.delete(`${resource}/${id}`),
  getList: (queryParams = {}, options = {}) => api.get(`${resource}?${qs.stringify(queryParams)}`, options),
  find: (id, options = {}) => api.get(`${resource}/${id}`, options),
  getFiltered: (options = {}, body = {}, headers = {}) =>
    api.post(
      `${resource}/GetFiltered`,
      {
        Category: [],
        clients: [],
        companyIndustry: [],
        jobFunction: [],
        positionName: [],
        OnLastStep: false,
        minimalRating: 0,
        ...body,
      },
      {
        ...options,
        headers: {
          ...headers,
        },
      }
    ),
  export: (options = {}, body = {}) =>
    api.post(
      `${resource}/Export`,
      {
        Category: [],
        clients: [],
        companyIndustry: [],
        jobFunction: [],
        positionName: [],
        OnLastStep: false,
        minimalRating: 0,
        ...body,
      },
      {
        ...options,
      }
    ),
  exportToExcel: (candidates) =>
    api.post(`${resource}/ExportToExcel`, candidates, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    }),
  import: (file) => api.post(`${resource}/ImportFromExcel`, file),
  getHistoryEntries: (id, options = {}) => api.get(`${resource}/GetCandidateHistoryEntriesString/${id}`, options),
  addHistoryEntry: (body = {}, options = {}) =>
    api.post(
      `${resource}/AddHistoryEntry`,
      {
        type: 4,
        ...body,
        dateStamp: moment().utc().format(),
      },
      options
    ),
  deleteComment: (payload) => api.delete(`${resource}/${payload.candidateId}/comments/${payload.id}`),
  getTags: () => api.get(`${resource}/GetAllTags`),
  addTag: (body = {}, options = {}) =>
    api.post(
      `${resource}/${body.candidateId || body.id}/tags`,
      {
        ...body,
        dateStamp: moment().utc().format(),
      },
      {
        ...options,
      }
    ),
  deleteTag: (body = {}, options = {}) =>
    api.delete(
      `${resource}/${body.candidateId || body.id}/tags/${body.tagId}`,
      {
        data: {
          ...body,
          dateStamp: moment().utc().format(),
        },
      },
      options
    ),
  addDocument: async (id, body = {}, options = {}) => {
    const data = await convertMultipartToJson(body);
    await api.post(`${resource}/documents`, { id, ...data }, options);
  },
  deleteDocument: async (body = {}, options = {}) => {
    await api.delete(`${resource}/${body.candidateId}/documents/${body.fileId}`, {}, options);
  },
  addManual: (body = {}, options = {}) =>
    api.post(
      `${resource}/AddManual`,
      {
        sourceType: 11,
        agreeToBeContactedRegardingOtherPositions: false,
        applicationDate: moment().utc().format(),
        consent: false,
        fromBoostPackage: false,
        mobileApplication: false,
        rejected: false,
        talent: false,
        sourceAddress: '',
        pictureFileExt: '',
        pictureGuid: '',
        pictureUploaded: false,
        ...body,
      },
      options
    ),
  updateCandidate: (body = {}, options = {}) =>
    api.put(
      `${resource}/Update`,
      {
        sourceType: 11,
        agreeToBeContactedRegardingOtherPositions: false,
        applicationDate: moment().utc().format(),
        consent: false,
        fromBoostPackage: false,
        mobileApplication: false,
        rejected: false,
        talent: false,
        sourceAddress: '',
        pictureFileExt: '',
        pictureGuid: '',
        pictureUploaded: false,
        ...body,
      },
      options
    ),
  getScreenings: (candidateId) => api.get(`${resource}/${candidateId}/answers`),
  actionRating: (payload, type = 'UpdateRating') =>
    api[type === 'UpdateRating' ? 'put' : 'post'](`${resource}/${type}`, payload),
  getTimelines: (candidateId) => api.get(`${resource}/${candidateId}/timelines`),
  bulk: (type, payload) => api.post(`${resource}/${type}`, payload),
  getLinkedPositions: (id) => api.get(`${resource}/${id}/linked-position`),
  moveToPosition: (payload) => api.post(`${resource}/move-to-position`, payload),
  getTestResult: (candidateId) => api.get(`${resource}/${candidateId}/tests`),
  getEvaluations: (candidateId) => api.get(`${resource}/${candidateId}/evaluations`),
  getAlvaLabs: (candidateId) => api.get(`${resource}/${candidateId}/alva-labs`),
  getCandidateClientId: (candidateId) => api.get(`${resource}/${candidateId}/clientId`),
};
