import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import qs from 'query-string';
import Layout from '../../../../components/layouts/default';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import { ClientFormDefaults, ClientLanguage, RekryShopOptions, SiteType } from '../../../../constants/Client';
import { useSelector as useSelectorToolkit } from '../../../../store';
import { selectClientTypes, selectCompanyIndustries } from '../../../../store/selectors/settings.selector';
import Button from '../../../../components/Button';
import ClientForm from './ClientForm';
import ClientCustomizeProducts from './ClientCustomizeProducts';
import CustomizeClientColors from './ClientCustomizeColors';
import { getAdminClientById } from '../../../../store/api/client.api';

const ClientActions = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { loading, data } = useApiRequestContext();
  const { type } = useParams();
  const queryParams = qs.parse(location?.search);

  const tabs = [
    {
      id: 1,
      name: 'information',
      label: t('general.information'),
    },
    {
      id: 2,
      name: 'customize-colors',
      label: t('general.customize-colors'),
    },
  ];

  if (data?.clientId && type === 'edit') {
    tabs.push({
      id: 3,
      name: 'customize-products',
      label: t('general.customize-products'),
    });
  }

  const parseStringTab = (item) => {
    return item.toLowerCase().replace(' ', '-');
  };

  if (loading) {
    return null;
  }

  const isEditable = type === 'edit' && data?.clientId;
  return (
    <Layout
      pretitle={t('general.admin')}
      testId="clientForm"
      title={
        data?.clientId && data?.name
          ? t('admin.clients.edit-client', {
              clientName: `- ${data.name}` || '',
            })
          : t('admin.clients.create-client')
      }
      rightContent={
        <div className="d-flex">
          <Button
            outline
            className="flex items-center !px-5"
            onClick={() => {
              history.push('/admin/clients');
            }}
            data-testid="client-cancel-button"
          >
            {t('general.cancel')}
          </Button>
        </div>
      }
      headerTextStyle={{
        marginBottom: 0,
        borderBottom: 0,
      }}
    >
      <div className="user-profile-form anim-table-delayed">
        <Nav tabs>
          {tabs.map((item, index) => (
            <NavItem key={item.id}>
              <NavLink
                className={classNames({
                  active: parseStringTab(item.name) === queryParams.tab || (!queryParams.tab && item.id === 1),
                })}
                onClick={() => {
                  history.push({
                    ...location,
                    location: location?.pathname,
                    search: qs.stringify({
                      ...queryParams,
                      tab: parseStringTab(item.name),
                    }),
                  });
                }}
                data-testid={`client-navigation-${index}`}
              >
                {item.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        {!queryParams.tab || (queryParams.tab === 'information' && isEditable) ? <ClientForm /> : null}
        {queryParams.tab === 'customize-colors' && isEditable ? <CustomizeClientColors /> : null}
        {queryParams.tab === 'customize-products' && isEditable ? <ClientCustomizeProducts /> : null}
      </div>
    </Layout>
  );
};

const ClientActionsWrapper = () => {
  const { type, id } = useParams();
  const clientTypes = useSelectorToolkit(selectClientTypes);
  const companyIndustries = useSelectorToolkit(selectCompanyIndustries);

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, token) => {
        if (type === 'edit' && id) {
          const response = await getAdminClientById(id, token);
          if (!response) return ClientFormDefaults;

          const rekryShop = response?.settingsModel?.showRekryShop;

          return {
            ...ClientFormDefaults,
            ...response,
            clientId: id,
            language: ClientLanguage.find((item) => item.value === response?.language),
            type: clientTypes.find((item) => parseInt(item.value, 10) === response?.type),
            siteType: SiteType[response?.siteType]
              ? {
                  label: SiteType[response?.siteType]?.label,
                  value: parseInt(response?.siteType, 10),
                }
              : null,
            companyIndustry: (response?.companyIndustries || []).map((item) => {
              const foundItem = companyIndustries.find((ci) => ci.value === parseInt(item, 10));

              return foundItem;
            }),
            settingsModel: {
              ...response?.settingsModel,
              showRekryShop: RekryShopOptions.find((item) => item.value === rekryShop) || null,
              facebookPageId: response?.settingsModel?.facebookPageId || global?.appConfig?.fb_page_id || '',
              facebookAdAccountId:
                response?.settingsModel?.facebookAdAccountId || global?.appConfig?.fb_ads_account_id || '',
              instagramAccountId:
                response?.settingsModel?.instagramAccountId || global?.appConfig?.instagram_account_id || '',
              gdprDeleteDirectApplicationMonths: response?.settingsModel?.gdprDeleteDirectApplicationMonths,
              gdprDeleteSpontanenousApplicationMonths: response?.settingsModel?.gdprDeleteSpontanenousApplicationMonths,
            },
          };
        }

        return ClientFormDefaults;
      }}
    >
      <ClientActions />
    </ApiRequestProvider>
  );
};

export default ClientActionsWrapper;
