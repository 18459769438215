import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { NotificationManager } from 'react-notifications';
import Tippy, { useSingleton } from '@tippyjs/react';
import { hideAll } from 'tippy.js';
import Layout from '../../../../components/layouts/default';
import Button from '../../../../components/Button';
import Icon from '../../../../components/@v2/Icon';
import WithData from '../../../../components/WithData';
import Loader from '../../../../components/Loader';
import { deleteIntegrationResource, getIntegrations } from '../../../../store/api/client-integrations.api';
import { log } from '../../../../helpers/utils';
import { selectUser } from '../../../../store/selectors/user.selector';
import { getClient } from '../../../../store/thunks/client.thunk';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../../store';
import DeleteModelTooltip from '../../../../components/@v2/DeleteModelTooltip/DeleteModelTooltip';

const Integrations = () => {
  const [source, target] = useSingleton();
  const { t } = useTranslation();
  const history = useHistory();
  const currentUser = useSelectorToolkit(selectUser);
  const [integrations, setIntegrations] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteIntegration = async (id, callback = null) => {
    try {
      hideAll();
      setIsDeleting(true);
      if (id) {
        await deleteIntegrationResource(id);

        if (callback) {
          callback();
        }
      }
    } catch (error) {
      log(error);
      const errorMsg = () => {
        if (error.response) {
          return error.response.data?.error?.message;
        }
        return error;
      };
      NotificationManager.error(errorMsg() || '');
    } finally {
      setIsDeleting(false);
    }
  };

  const setItemImageHeight = (integrationType) => {
    const defaultHeight = integrationType === 'Assessio' ? '1.5rem' : '2.5rem';
    return integrationType === 'Ams' ? '2rem' : defaultHeight;
  };

  useEffect(() => {
    (async () => {
      if (currentUser) {
        const { clientId, superAdmin } = currentUser;
        await dispatchToolkit(
          getClient({
            superAdmin,
            id: clientId,
          })
        );
      }
    })();
  }, [currentUser]);

  return (
    <Layout
      pretitle={t('general.settings')}
      title={t('settings.integrations.title')}
      className="users-page"
      rightContent={
        <Button
          className="btn-rounded"
          color="primary"
          data-testid="settings-add-integration-button"
          onClick={() => history.push('/settings/integrations/add')}
        >
          <div className="flex items-center gap-x-1">
            <Icon name="plus" />
            {t('settings.integrations.add')}
          </div>
        </Button>
      }
    >
      <Tippy
        trigger="click mouseenter"
        singleton={source}
        theme="light"
        interactive
        maxWidth={200}
        appendTo={document.body}
        placement="bottom"
      />
      <WithData
        api={() => getIntegrations()}
        setItems={({ items }) => {
          setIntegrations(items);
        }}
      >
        {({ loading, reload }) => {
          return (
            <Row className="anim-table-delayed">
              <Col>
                {loading &&
                  [0, 1, 2, 3].map((item, index) => (
                    <div
                      className={classNames(
                        'flex items-center justify-between bg-white rounded-2xl shadow-md p-4',
                        index !== 0 && 'mt-4'
                      )}
                      key={uniqueId()}
                    >
                      <div className="h-[2.5rem] w-[350px] bg-gray-100 rounded-lg" />
                      <div className="flex items-center">
                        <div className="transition h-[35px] w-[35px] leading-[35px] bg-gray-100 hover:bg-gray-200 rounded-full mr-3" />
                        <div className="transition h-[35px] w-[35px] leading-[35px] bg-gray-100 hover:bg-gray-200 rounded-full" />
                      </div>
                    </div>
                  ))}
                {integrations.map((item, index) => (
                  <div
                    className={classNames(
                      'flex items-center justify-between bg-white rounded-2xl shadow-md p-4',
                      index !== 0 && 'mt-4'
                    )}
                    key={item.id}
                  >
                    <img
                      alt={item.type}
                      style={{
                        height: setItemImageHeight(item.type),
                      }}
                      src={`/img/integrations/${item.type.toLowerCase()}.png`}
                    />
                    <div className="flex items-center">
                      <div className="mr-4 flex items-center w-[70px]">
                        <span
                          className={classNames(
                            'h-[7px] w-[7px] rounded-full mr-2',
                            item.isActive ? 'bg-green-500' : 'bg-gray-500'
                          )}
                        />
                        <span className="text-gray-400 text-sm mb-0">
                          {t(`general.${item.isActive ? 'active' : 'disabled'}`)}
                        </span>
                      </div>
                      <Tippy content={t('general.edit')} theme="light" placement="bottom" interactive>
                        <button
                          type="button"
                          className="transition h-[35px] w-[35px] leading-[35px] bg-gray-100 hover:bg-gray-200 grid place-content-center rounded-full mr-3"
                          onClick={() => {
                            history.push({
                              pathname: `/settings/integrations/edit/${item.type.toLowerCase()}`,
                              state: {
                                active: item.isActive,
                              },
                            });
                          }}
                          data-testid="integration-edit"
                        >
                          <Icon name="pencil" />
                        </button>
                      </Tippy>
                      {isDeleting && <Loader color="primary" className="mr-3" />}
                      <DeleteModelTooltip
                        content={<p>{t('settings.integrations.delete-integration')}</p>}
                        onDelete={async () => {
                          await deleteIntegration(item.type, reload);
                        }}
                        singleton={target}
                      >
                        <button
                          type="button"
                          className="transition h-[35px] w-[35px] leading-[35px] bg-gray-100 hover:bg-gray-200 rounded-full grid place-content-center"
                          data-testid="integration-delete"
                        >
                          <Icon name="trash" />
                        </button>
                      </DeleteModelTooltip>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          );
        }}
      </WithData>
    </Layout>
  );
};

export default Integrations;
