import i18n from '../i18n';

export default [
  {
    label: i18n.t('general.optional'),
    value: 0
  },
  {
    label: i18n.t('general.mandatory'),
    value: 1
  },
  {
    label: i18n.t('general.hidden'),
    value: 2
  },
  {
    label: i18n.t('general.mandatory-optional-on-phone'),
    value: 3
  }
];

export const ShowHideOptions = [
  {
    label: i18n.t('general.show'),
    value: true
  },
  {
    label: i18n.t('general.hide'),
    value: false
  },
];

export const EmailDelayOptions = [
  {
    label: i18n.t('general.send-directly'),
    value: 0
  },
  {
    label: i18n.t('general.send-four-hours-after-application'),
    value: 4
  },
  {
    label: i18n.t('general.send-24-hours-after-application'),
    value: 24
  },
];
