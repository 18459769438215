import { FieldArray, Formik } from 'formik';
import { sortBy, uniqueId } from 'lodash';
import React, { useRef } from 'react';
import DraggableList from 'react-draggable-list';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { TEXT_ORIENTATION_OPTIONS } from '../../../../../constants/CareerPage';
import { useApiRequestContext } from '../../../../../context/@v2/ApiRequestContext';
import {
  defaultFocusPointCoords,
  defaultImageUpload,
} from '../../../../../helpers/image';
import { addSortOrder } from '../../../../../helpers/utils';
import { onBlockUpdate } from '../../../../../store/api/career-page.api';
import Button from '../../../../Button';
import Icon from '../../../Icon';
import SlideshowItem from './SlideshowItem';

const SlideshowForm = ({ item }) => {
  const { t } = useTranslation();
  const draggableContainer = useRef(null);
  const { data, reloadData } = useApiRequestContext();

  const onSubmit = async (values) => {
    await onBlockUpdate(
      data?.details,
      {
        ...values,
        carouselItems: addSortOrder(
          values?.carouselItems?.map((carouselItem) => {
            const textOrientation = carouselItem?.textOrientation?.value;

            if (carouselItem?.imageUpload) {
              return {
                ...carouselItem,
                image: {
                  ...defaultImageUpload,
                  focusPointJsonString: JSON.stringify(defaultFocusPointCoords),
                },
                textOrientation,
              };
            }

            return {
              ...carouselItem,
              textOrientation,
            };
          })
        ),
      },
      async () => {
        await reloadData();
        data?.renderSuccessNotification();
      }
    );
  };

  const ORIENTATION_OPTIONS = TEXT_ORIENTATION_OPTIONS(t);

  return (
    <Formik
      initialValues={{
        ...item,
        carouselItems: sortBy(item?.carouselItems, 'sortOrder').map(
          (carouselItem, index) => ({
            ...carouselItem,
            index,
            uuid: uniqueId(),
            textOrientation: ORIENTATION_OPTIONS.find(
              (orientation) =>
                orientation?.value === carouselItem?.textOrientation
            ),
          })
        ),
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
        const createNewSlideshow = () => {
          return {
            index: values?.carouselItems?.length,
            sortOrder: values?.carouselItems?.length + 1,
            uuid: uniqueId(),
            image: null,
            imageUpload: false,
            title: '',
            description: '',
            textColorHex: '#000000',
            textShadowColorHex: '#000000',
            textOrientation: ORIENTATION_OPTIONS[0],
            isEditing: true,
          };
        };

        return (
          <form onSubmit={handleSubmit} className="w-full">
            <div className="flex items-center justify-between !mb-4">
              <Label for="carousel-items" className="mb-0">
                {t('pages.carousel')}
              </Label>
              <button
                type="button"
                data-testid="add-slideshow-button"
                onClick={() => {
                  setFieldValue('carouselItems', [
                    ...values?.carouselItems?.map((carouseItem) => {
                      return {
                        ...carouseItem,
                        isEditing: false,
                      };
                    }),
                    createNewSlideshow(),
                  ]);
                }}
              >
                <Icon
                  name="plus"
                  color="var(--text-color-secondary)"
                  className="clickable"
                />
              </button>
            </div>
            <FieldArray
              name="carouselItems"
              render={() => (
                <div
                  className="flex flex-col gap-y-4 !mb-3"
                  ref={draggableContainer}
                >
                  <DraggableList
                    itemKey="imageCarouselItemId"
                    template={SlideshowItem}
                    list={values?.carouselItems || []}
                    onMoveEnd={(newList) => {
                      const items = addSortOrder(
                        newList.map((item, index) => {
                          return {
                            ...item,
                            index,
                          };
                        })
                      );

                      setFieldValue('carouselItems', items);
                    }}
                    container={() => {
                      if (draggableContainer && draggableContainer.current) {
                        return draggableContainer.current;
                      }
                      return false;
                    }}
                    commonProps={{
                      setFieldValue,
                      carouselItems: values?.carouselItems,
                    }}
                  />
                  {!values?.carouselItems?.length && (
                    <p className="text-color-secondary">
                      {t('general.no-items-found')}.
                    </p>
                  )}
                </div>
              )}
            />
            <Button
              type="submit"
              color="primary"
              className="w-full text-white"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="career-page-block-form-submit"
            >
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default SlideshowForm;
