import React, { useEffect, useMemo } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import events from '../../helpers/events';
import useIsMobile from '../../hooks/useIsMobile';

const Layout = ({
  children,
  pretitle,
  testId,
  title,
  rightContent,
  full,
  mainContentStyle,
  headerTextStyle,
  noPadding,
  hasTabs,
  ...rest
}) => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const { search, state } = location;
  const queryParams = qs.parse(search);
  const history = useHistory();
  const shouldTopbarHidden = useMemo(
    () => location.pathname.startsWith('/career-page'),
    [location]
  );

  useEffect(() => {
    events.$on(
      'system--backend-deployment-start',
      () => {
        history.push({
          search: qs.stringify({
            ...queryParams,
            modal: 'deploy-start',
          }),
          state,
        });
      },
      'default'
    );

    events.$on(
      'system--backend-deployment-finished',
      () => {
        history.push({
          search: qs.stringify({
            ...queryParams,
            modal: 'deploy-finished',
          }),
          state,
        });
      },
      'default'
    );
  }, []);

  useEffect(() => {
    const viewport = document.querySelector('meta[name=viewport]');
    if (isMobile && location.pathname.startsWith('/candidates')) {
      viewport.setAttribute('content', 'width=1024');
    } else {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1');
    }
  }, [location]);

  return (
    <>
      {full ? (
        <div
          className="main-content main-content--full"
          style={{ height: shouldTopbarHidden ? '100vh' : null }}
          data-testid={rest['data-testid'] || ''}
        >
          {children}
        </div>
      ) : (
        <TransitionGroup className="transition-group-container">
          <CSSTransition
            appear
            classNames="page-content"
            timeout={{
              enter: 0,
              exit: 200,
            }}
            unmountOnExit
          >
            <div
              className={classNames('main-content', noPadding && 'pb-0')}
              style={mainContentStyle}
              data-testid={rest['data-testid'] || testId || ''}
            >
              {(rightContent || pretitle || title) && (
                <div
                  className={classNames(
                    'header-text-container flex items-center justify-between gap-x-4',
                    hasTabs && '!mb-0 !pb-0'
                  )}
                  style={{
                    borderBottom:
                      rightContent && !hasTabs ? '1px solid #dadee2' : 'none',
                    marginBottom: rightContent ? 30 : 15,
                    paddingBottom: !pretitle ? 16 : null,
                    ...headerTextStyle,
                  }}
                >
                  <div className="flex-grow">
                    {!!pretitle && (
                      <h6 className="header-pretitle text-xs !text-secondary !mb-0">
                        {pretitle}
                      </h6>
                    )}
                    {!!title && (
                      <h1 className="header-title flex items-center text-2xl mb-0 font-semibold">
                        {title}
                      </h1>
                    )}
                  </div>
                  <div className="header-right-content">{rightContent}</div>
                </div>
              )}
              {children}
            </div>
          </CSSTransition>
        </TransitionGroup>
      )}
    </>
  );
};

Layout.defaultProps = {
  pretitle: '',
  title: '',
  rightContent: null,
  hasTabs: false,
  testId: null,
  full: false,
  mainContentStyle: {},
  headerTextStyle: {},
  noPadding: false,
};

export default Layout;
