import React, { useState } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { useToasts } from 'react-toast-notifications';
import Modal, { ModalHeader, ModalBody } from '../Modal';
import CustomButton from '../../Button';
import { archivePosition } from '../../../store/api/positions.api';
import events from '../../../helpers/events';

const toastConfig = {
  appearance: 'info',
  autoDismiss: true,
  autoDismissTimeout: 5000,
};

const ArchivePosition = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname, search } = location;
  const history = useHistory();
  const { addToast } = useToasts();
  const [sending, setSending] = useState(false);
  const queryParams = qs.parse(search);

  const closeModal = () => {
    const queryParamsCopy = { ...queryParams };
    Reflect.deleteProperty(queryParamsCopy, 'modal');
    Reflect.deleteProperty(queryParamsCopy, 'id');
    Reflect.deleteProperty(queryParamsCopy, 'length');

    if (queryParams.length === '1' && queryParams.currentPage > 1) {
      queryParamsCopy.currentPage -= 1;
    }

    history.push({
      ...location,
      location: pathname,
      search: qs.stringify(queryParamsCopy),
    });
  };

  const onArchive = async (e) => {
    e.preventDefault();
    if (sending) return;
    setSending(true);

    try {
      await archivePosition(parseInt(queryParams.id, 10));

      if (queryParams.currentPage === null || queryParams.currentPage === undefined) {
        events.$emit('refresh-table--positions');
      }

      closeModal();
    } catch (error) {
      if (error.error && error.error.message) {
        addToast(error.error.message, {
          ...toastConfig,
          appearance: 'error',
        });
        closeModal();
      }
    }

    setSending(false);
  };

  return (
    <Modal width={600}>
      <ModalHeader>{t('general.archive-position')}</ModalHeader>
      <ModalBody>
        <Form onSubmit={onArchive}>
          <FormGroup>
            <p className="text-center">{t('positions.the-position-will-be-moved')}</p>
          </FormGroup>
          <div className="d-flex align-items-center justify-content-center">
            <CustomButton
              type="sumbit"
              color="danger"
              style={{ marginRight: 5 }}
              loading={sending}
              data-testid="archive-button"
            >
              {t('general.archive')}
            </CustomButton>
            <Button type="button" color="light" onClick={closeModal}>
              {t('general.cancel')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ArchivePosition;
