import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { get } from 'lodash';
import Modal, { ModalHeader, ModalBody } from './Modal';
import { logout } from '../../store/thunks/auth.thunks';
import events from '../../helpers/events';
import {
  useSelector as useSelectorToolkit,
  dispatch as dispatchToolkit,
} from '../../store';
import { selectClient } from '../../store/selectors/client.selector';

const CartContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const activeClient = useSelectorToolkit(selectClient);

  const callback = async () => {
    const { type } = location.state;

    if (type === 'logout') {
      await dispatchToolkit(logout());
      history.replace('/');
    }
    if (type === 'select-client') {
      events.$emit('get-client', state.clientId);
    }
  };

  return (
    <Modal width={600}>
      <ModalHeader>{t('cart.confirmation-title')}</ModalHeader>
      <ModalBody>
        <div className="text-center">
          <p>{t('cart.confirmation-body')}</p>
          <p>
            {get(activeClient, 'id')
              ? t('cart.confirmation-body-2', {
                  clientName: get(activeClient, 'name'),
                })
              : null}
          </p>
          <div className="buttons mt-4">
            <Button
              type="button"
              color="primary"
              onClick={() => {
                callback();
              }}
            >
              {t('general.continue')}
            </Button>
            <Button
              type="button"
              color="light"
              onClick={() => {
                history.push({ search: '' });
              }}
            >
              {t('general.cancel')}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CartContainer;
