import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CancelToken } from 'axios';
import { Row, Col, ListGroup, ListGroupItem, Collapse } from 'reactstrap';
import uuidv4 from 'uuid/v4';
import { get, sortBy, isEqual, range } from 'lodash';
import Tippy, { useSingleton } from '@tippyjs/react';
import Layout from '../../components/layouts/default';
import Card from '../../components/Card';
import { useAPI } from '../../context/api';
import { positionTemplateForm } from '../../constants/PositionTemplate';
import DraggableList from '../../components/DraggableList';
import { StyledDivider } from '../../components/form/Form.styled';
import Icon from '../../components/@v2/Icon';
import Form from '../../components/form/Form';
import CustomButton from '../../components/Button';
import Placeholder from '../../components/Placeholder';
import Field from '../../components/@v2/Form/Field';
import CircularLoader from '../../components/Loader/CircularLoader';
import { log } from '../../helpers/utils';
import {
  createEmailTemplate,
  deleteEmailTemplate,
  updateEmailTemplate,
  getAllEmailTemplates,
} from '../../store/api/template.api';
import {
  getPositionTemplates,
  getPositionTemplate,
  createOrUpdatePositionTemplate,
  deletePositionTemplate,
  batchSortOrderUpdate,
} from '../../store/api/position-template.api';
import { batchSortOrderUpdate as batchSortOrderUpdateEmail } from '../../store/api/email-templates.api';
import DeleteModelTooltip from '../../components/@v2/DeleteModelTooltip/DeleteModelTooltip';

const LoadingComponent = () => (
  <>
    <ListGroup>
      {range(5).map(() => (
        <ListGroupItem key={uuidv4()} className="d-flex d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <Placeholder square height={20} className="mr-3" />
            <Placeholder
              height={20}
              unitType="px"
              random={{
                min: 100,
                max: 300,
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            <Placeholder square height={20} className="ml-3" />
            <Placeholder square height={20} className="ml-3" />
            <Placeholder square height={20} className="ml-3" />
          </div>
        </ListGroupItem>
      ))}
      <Placeholder width={180} height={38} borderRadius={38} unitType="px" className="mt-4" />
    </ListGroup>
  </>
);

const ListItem = ({
  item,
  provided,
  formOpen,
  setFormOpen,
  setTemplate,
  handleSubmit,
  onDelete,
  isEditingIds,
  type,
  editorTemplates,
  target,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const inputs = useMemo(() => {
    let defaultInputs = [
      {
        key: 'name',
        label: t('general.name'),
        rules: {
          required: true,
        },
      },
    ];

    if (type === 'email') {
      defaultInputs = [
        ...defaultInputs,
        {
          key: 'subject',
          label: t('general.subject'),
          rules: {
            required: true,
          },
        },
        {
          key: 'text',
          label: t('general.message'),
          type: 'wysiwyg',
          toolbarId: `wysiwyg-${item.id}`,
          toolbar: 'candidate-email-toolbar',
          options: {
            listItems: editorTemplates,
          },
          rules: {
            required: true,
          },
          acceptValueFromApi: true,
        },
      ];
    } else {
      defaultInputs = [
        ...defaultInputs,
        {
          key: 'text',
          label: t('general.description'),
          type: 'wysiwyg',
          toolbarId: `wysiwyg-${item.id}`,
          rules: {
            required: true,
          },
        },
      ];
    }

    return defaultInputs;
  }, [type]);

  return (
    <ListGroupItem
      key={item.uuid}
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{ position: 'relative' }}
      className="list-group-draggable-item"
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div {...provided.dragHandleProps}>
            <Icon type="fas" name="grip-vertical" className="flex-grow-0 mr-3" />
          </div>
          {item.name}
        </div>
        <div className="flex items-center gap-x-4">
          <Tippy content={t('general.copy')} singleton={target}>
            <button
              type="button"
              onClick={async () => {
                if (!item.id) return;

                try {
                  const existingTemplate = await getPositionTemplate(item.id);
                  if (existingTemplate) {
                    setTemplate({
                      ...item,
                      positionName: existingTemplate.positionName || '',
                      description: existingTemplate.description || '',
                      id: undefined,
                      isOpen: true,
                    });
                  }
                } catch (error) {
                  log(error);
                  setTemplate({
                    ...item,
                    id: undefined,
                    uuid: undefined,
                    isOpen: true,
                  });
                }
              }}
            >
              <Icon name="copy" className="clickable" />
            </button>
          </Tippy>
          <Tippy content={t('general.edit')} singleton={target}>
            <button
              type="button"
              onClick={() => {
                if (type === 'position') {
                  history.push(`/settings/templates/position/edit/${item.id}`);
                } else {
                  setFormOpen({
                    ...formOpen,
                    [type]: formOpen[type].find((id) => id === item.id)
                      ? formOpen[type].filter((id) => id !== item.id)
                      : [...formOpen[type], item.id],
                  });
                }
              }}
            >
              <Icon name="edit" className="clickable" />
            </button>
          </Tippy>
          <DeleteModelTooltip
            content={<p>{t('settings.delete-template')}</p>}
            singleton={target}
            onDelete={() => {
              onDelete(item);
            }}
          >
            <button type="button">
              <Icon name="trash" />
            </button>
          </DeleteModelTooltip>
        </div>
      </div>
      <Collapse isOpen={formOpen[type]?.includes(item.id)}>
        <Form
          onSubmit={async (values) => {
            await handleSubmit(values);
            setFormOpen({
              ...formOpen,
              [type]: formOpen[type].filter((id) => id !== item.id),
            });
          }}
          defaultValues={item}
          className="mb-2 mt-4"
          resourceName="settings-template-page"
          button={{
            cancelLabel: 'Cancel',
            cancelCallback: () => {
              setFormOpen({
                ...formOpen,
                [type]: formOpen[type].filter((id) => id !== item.id),
              });
            },
            loading: isEditingIds?.includes(item.id),
          }}
          inputs={inputs}
        />
      </Collapse>
    </ListGroupItem>
  );
};

ListItem.defaultProps = {
  type: 'position',
  editorTemplates: null,
};

const defaultTemplate = {
  isOpen: false,
  name: '',
  subject: '',
  message: '',
  text: '',
  sortOrder: 0,
  type: 1,
};

const TemplatesPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [templateAPI] = useAPI('template');
  const [form, setForm] = useState({
    positionTemplates: [],
    emailTemplates: [],
  });
  const [source, target] = useSingleton();
  const [emailTemplate, setEmailTemplate] = useState(defaultTemplate);
  const [positionTemplate, setPositionTemplate] = useState({
    ...defaultTemplate,
    type: 0,
  });
  const [isAdding, setIsAdding] = useState({
    position: false,
    email: false,
  });
  const [isEditingIds, setIsEditingIds] = useState([]);
  const [isDeletingIds, setIsDeletingIds] = useState([]);
  const [formOpen, setFormOpen] = useState({
    position: [],
    email: [],
  });
  const [editorTemplates, setEditorTemplates] = useState([]);
  const [isRendered, setIsRendered] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);

  const PositionTemplateSchema = Yup.object().shape({
    name: Yup.string().required(t('form-validator.required')),
    positionName: Yup.string().required(t('form-validator.required')),
    description: Yup.string().required(t('form-validator.required')),
  });

  const addDraggableProps = (arr) =>
    arr.map((item) => ({
      ...item,
      type: item.type || 0,
      uuid: uuidv4(),
    }));

  const getPositionTemplatesList = async () => {
    try {
      const positionTemplates = await getPositionTemplates();

      const emailTemplates = await getAllEmailTemplates();

      setForm((state) => ({
        ...state,
        positionTemplates: addDraggableProps(sortBy(positionTemplates, 'sortOrder')),
        emailTemplates: addDraggableProps(sortBy(emailTemplates?.items || [], 'sortOrder')),
      }));
    } catch (error) {
      log(error);
    } finally {
      setIsRendered(true);
    }
  };

  const getAllTemplates = async (source) => {
    try {
      const items = await templateAPI.browse();

      if (Array.isArray(items)) {
        setEditorTemplates(
          items
            .filter((item) => item.text !== '')
            .map((item) => ({
              value: item.text,
              label: item.name,
              templateId: item.id,
              item,
            }))
        );
      }
    } catch (error) {
      log(error);
    } finally {
      getPositionTemplatesList(source);
    }
  };

  useEffect(() => {
    const source = CancelToken.source();
    getAllTemplates(source);

    return () => {
      source.cancel();
    };
  }, []);

  const addTemplate = async (payload) => {
    const { type } = payload;

    if (type === 0 && isAdding.position) return;
    if (type === 1 && isAdding.email) return;

    try {
      setIsAdding((state) => ({
        ...state,
        [type === 0 ? 'position' : 'email']: true,
      }));
      const values = { ...payload };

      if (values.type === 0) {
        values.sortOrder = form.positionTemplates.length + 1;

        let currentForm = { ...positionTemplateForm };

        if (values.id && values.id !== 0) {
          const existingTemplate = await getPositionTemplate(values.id);
          currentForm = {
            ...existingTemplate,
            criterias:
              get(existingTemplate, 'criterias').length !== 0 && existingTemplate.criterias[0].screeningCriteriaId !== 0
                ? existingTemplate.criterias
                : [],
          };
        }

        await createOrUpdatePositionTemplate({
          sortOrder: values.sortOrder + 1,
          pipelineSteps: [],
          description: values.description,
          ...currentForm,
          ...values,
          id: 0,
        });
        setPositionTemplate({
          ...defaultTemplate,
          type: 0,
        });
      } else {
        values.sortOrder = form.emailTemplates.length + 1;
        await createEmailTemplate(values);
        setEmailTemplate(defaultTemplate);
      }

      const source = CancelToken.source();

      getPositionTemplatesList(source);
    } catch (error) {
      log(error);
    } finally {
      setIsAdding((state) => ({
        ...state,
        [type === 0 ? 'position' : 'email']: false,
      }));
    }
  };

  const onDeletePositionTemplate = async (payload) => {
    if (isDeletingIds?.includes(payload.id)) return;

    try {
      setIsDeletingIds((state) => [...state, payload.id]);

      await deletePositionTemplate(payload.id);

      getPositionTemplatesList();
    } catch (error) {
      log(error);
    } finally {
      setIsDeletingIds((state) => state.filter((id) => payload.id !== id));
    }
  };

  const onDeleteEmailTemplate = async (payload) => {
    if (isDeletingIds?.includes(payload.id)) return;

    try {
      setIsDeletingIds((state) => [...state, payload.id]);
      await deleteEmailTemplate(payload.id);

      getPositionTemplatesList();
    } catch (error) {
      log(error);
    } finally {
      setIsDeletingIds((state) => state.filter((id) => payload.id !== id));
    }
  };

  const handleSubmit = async (payload) => {
    if (isEditingIds?.includes(payload.templateId)) return;
    try {
      setIsEditingIds((state) => [...state, payload.id]);
      await updateEmailTemplate(payload);
      getPositionTemplatesList();
    } catch (error) {
      log(error);
    } finally {
      setIsEditingIds((state) => state.filter((id) => id !== payload.id));
    }
  };

  return (
    <Layout title={t('general.templates')} pretitle={t('general.settings')} data-testid="settings-template-page-layout">
      <Tippy
        trigger="click mouseenter"
        singleton={source}
        theme="light"
        interactive
        maxWidth={200}
        appendTo={document.body}
        placement="bottom"
      />
      <Row className="anim-table-delayed mb-4">
        <StyledDivider xs="12" style={{ paddingTop: 0 }}>
          <h3>{t('settings.position-templates')}</h3>
        </StyledDivider>
        <Col>
          {!isRendered && <LoadingComponent />}
          {isRendered && (
            <>
              <ListGroup className="mb-4" data-testid="settings-template-page-position-template-list-group">
                <DraggableList
                  items={form.positionTemplates}
                  formOpen={formOpen}
                  setFormOpen={setFormOpen}
                  setTemplate={setPositionTemplate}
                  handleSubmit={handleSubmit}
                  onDelete={onDeletePositionTemplate}
                  component={(item) => <ListItem {...item} type="position" />}
                  isEditingIds={isEditingIds}
                  setIsDuplicating={setIsDuplicating}
                  onDragMarginBottom={43}
                  callback={async (state) => {
                    if (isEqual(state, form.positionTemplates)) return;

                    const templates = state.map((item, index) => ({
                      ...item,
                      sortOrder: index + 1,
                    }));

                    setForm({
                      ...form,
                      positionTemplates: templates,
                    });

                    await batchSortOrderUpdate(
                      templates.map((item, index) => ({
                        id: item.id,
                        order: index + 1,
                      }))
                    );
                  }}
                  target={target}
                />
              </ListGroup>
              {isDuplicating && <CircularLoader color="var(--primary-color)" className="mb-4" />}
              <Collapse isOpen={positionTemplate.isOpen}>
                <Formik
                  enableReinitialize
                  initialValues={positionTemplate}
                  validationSchema={PositionTemplateSchema}
                  onSubmit={addTemplate}
                >
                  {({ handleSubmit, isSubmitting }) => {
                    return (
                      <Card className="overflow-hidden form-validator card p-2" containerClassName="anim-table-delayed">
                        <form onSubmit={handleSubmit} className="anim-table-delayed w-full !p-4">
                          <Field name="name" label={t('general.name')} testId="products-title-input" required />
                          <Field
                            name="positionName"
                            label={t('settings.position-name')}
                            testId="products-title-input"
                            required
                          />
                          <Field
                            toolbar="base"
                            type="wysiwyg"
                            name="description"
                            label={t('general.description')}
                            testId="products-description"
                            loading={isDuplicating}
                            required
                          />
                          <div class="d-flex align-items-center">
                            <CustomButton
                              outline
                              type="button"
                              color="danger"
                              className="btn-rounded d-flex align-items-center"
                              onClick={() => {
                                setPositionTemplate({
                                  ...positionTemplate,
                                  isOpen: false,
                                });
                              }}
                            >
                              {t('general.cancel')}
                            </CustomButton>
                            <CustomButton
                              outline
                              color="light"
                              className="btn-rounded btn-outline-light px-4 d-flex align-items-center "
                              data-testid="settings-template-page-add-position-template-button"
                              disabled={isSubmitting}
                              loading={isSubmitting}
                              onClick={handleSubmit}
                            >
                              {t('general.save')}
                            </CustomButton>
                          </div>
                        </form>
                      </Card>
                    );
                  }}
                </Formik>
              </Collapse>
              {!positionTemplate.isOpen && (
                <CustomButton
                  color="primary"
                  className="btn-rounded d-flex align-items-center"
                  data-testid="settings-template-page-add-position-template-button"
                  onClick={() => {
                    history.push('/settings/templates/position/add');
                  }}
                >
                  <Icon name="plus" />
                  {t('settings.add-position-template')}
                </CustomButton>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row className="anim-table-delayed form-template-with-variable">
        <StyledDivider xs="12">
          <h3>{t('settings.email-templates')}</h3>
        </StyledDivider>
        <Col>
          {!isRendered && <LoadingComponent />}
          {isRendered && (
            <>
              <ListGroup className="mb-4" data-testid="settings-template-page-email-template-list-group">
                <DraggableList
                  type="email"
                  editorTemplates={editorTemplates}
                  items={form.emailTemplates}
                  formOpen={formOpen}
                  setFormOpen={setFormOpen}
                  setTemplate={setEmailTemplate}
                  handleSubmit={handleSubmit}
                  onDelete={onDeleteEmailTemplate}
                  component={(item) => <ListItem {...item} type="email" />}
                  isEditingIds={isEditingIds}
                  onDragMarginBottom={43}
                  callback={async (state) => {
                    if (isEqual(state, form.emailTemplates)) return;

                    const templates = state.map((item, index) => ({
                      ...item,
                      sortOrder: index + 1,
                    }));

                    setForm({
                      ...form,
                      emailTemplates: templates,
                    });

                    await batchSortOrderUpdateEmail(
                      templates.map((item, index) => ({
                        id: item.id,
                        order: index + 1,
                      }))
                    );
                  }}
                  target={target}
                />
              </ListGroup>
              <Collapse isOpen={emailTemplate.isOpen}>
                {emailTemplate.isOpen && (
                  <Form
                    card
                    onSubmit={(item) => addTemplate({ ...item, type: 1 })}
                    defaultValues={emailTemplate}
                    className="mb-4"
                    resourceName="settings-template-page-email-template"
                    button={{
                      cancelLabel: 'Cancel',
                      cancelCallback: () => {
                        setEmailTemplate({
                          ...emailTemplate,
                          isOpen: false,
                        });
                      },
                      loading: isAdding.email,
                    }}
                    inputs={[
                      {
                        key: 'name',
                        label: 'Name',
                        rules: {
                          required: true,
                        },
                      },
                      {
                        key: 'subject',
                        label: 'Subject',
                        rules: {
                          required: true,
                        },
                      },
                      {
                        key: 'text',
                        label: 'Message',
                        type: 'wysiwyg',
                        toolbarId: 'wysiwyg-add-email-templates',
                        toolbar: 'candidate-email-toolbar',
                        options: {
                          listItems: editorTemplates,
                        },
                        rules: {
                          required: true,
                        },
                        acceptValueFromApi: true,
                      },
                    ]}
                  />
                )}
              </Collapse>
              {!emailTemplate.isOpen && (
                <CustomButton
                  color="primary"
                  className="btn-rounded d-flex align-items-center"
                  data-testid="settings-template-page-add-email-template-button"
                  onClick={() => {
                    setEmailTemplate({
                      ...emailTemplate,
                      isOpen: true,
                    });
                  }}
                >
                  <Icon name="plus" />
                  {t('settings.add-email-template')}
                </CustomButton>
              )}
            </>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default TemplatesPage;
