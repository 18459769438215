import React from 'react';
import Loader from '../Loader';

const InputLoaderWrapper = ({ condition, loaderStyles, height, children }) => {
  const testId = 'input-loader-component';

  return (
    <div className="position-relative" data-testid={testId}>
      {children}
      {condition && (
        <Loader
          type="primary"
          className="input-loader"
          size={height}
          style={{
            top: -1,
            right: 0,
            ...loaderStyles,
            zIndex: 9999999,
          }}
        />
      )}
    </div>
  );
};

InputLoaderWrapper.defaultProps = {
  loaderStyles: {},
  height: 40,
};

export default InputLoaderWrapper;
