/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { get } from 'lodash';
import { logout, refreshToken } from '../store/thunks/auth.thunks';
import { dispatch as dispatchToolkit } from '../store';
import { getTokenExpiration, setDataInLocalStorage, removeDataFromLocalStorage } from '../helpers/auth';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

const onFullFilled = (response) => {
  if (response.config?.data) {
    try {
      const data = JSON.parse(response.config.data);
      if (data.withPagination) {
        return response;
      }
    } catch (error) {
      // do nothing
    }
  }

  return response && (get(response.data, 'payload') || response.data || response);
};
const onRejected = async (error) => {
  const originalRequest = error.config;

  if (get(error, 'response.status') === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        })
        .catch((err) => Promise.reject(err));
    }

    originalRequest._retry = true;
    isRefreshing = true;

    try {
      const data = await dispatchToolkit(refreshToken()).unwrap();
      const newToken = get(data, 'id_token');
      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      processQueue(null, newToken);
      const response = await axios(originalRequest);
      isRefreshing = false;
      return get(response, 'data.payload') || get(response, 'payload') || response;
    } catch (error) {
      isRefreshing = false;
      processQueue(error, null);
      dispatchToolkit(logout());
    }
  }

  if (error.status && error.status !== 401 && global?.appConfig?.environment !== 'production') {
    NotificationManager.error('Something went wrong');
    console.error(error);
  }

  return Promise.reject(error);
};

export { onFullFilled, onRejected, getTokenExpiration, setDataInLocalStorage, removeDataFromLocalStorage };
