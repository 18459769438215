import Axios, { AxiosResponse } from 'axios';
import ProcessFacebookTokenCommand from '@ts/apicontracts/admin/process-facebook-token-command';
import userAPI from '../../api/user';
import { api } from '../../helpers/api-v2';

const resource = 'admin';

export const restoreUser = async ({ email, userId }) => {
  try {
    const response = await Axios.all([userAPI.restore(userId), userAPI.resetPassword(email)]);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAdminList = async (meta, source) => {
  try {
    const response = await userAPI.getAdminList(meta, {
      cancelToken: source?.token,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await userAPI.delete(id);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getFacebookToken = async (options = {}) => {
  try {
    const response = await api.get(`${resource}/fbtoken`, options);
    return response as AxiosResponse<ProcessFacebookTokenCommand>;
  } catch (error) {
    throw error;
  }
};

export const addFacebookToken = async (payload: ProcessFacebookTokenCommand, options = {}) => {
  try {
    const response = await api.post(`${resource}/fbtoken`, payload, options);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteFacebookToken = async (options = {}) => {
  try {
    const response = await api.delete(`${resource}/fbtoken`, options);
    return response;
  } catch (error) {
    throw error;
  }
};
