import React from 'react';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import Avatar from './Avatar';
import Icon from './@v2/Icon';

const FacebookLinkedAccount = ({ fbUserData }) => {
  const testId = 'facebookLinkedAccount';
  const history = useHistory();
  const location = useLocation();
  const { search } = location;

  return (
    <div
      data-testid={testId}
      className="flex items-center border !py-1 !pl-1 !pr-3 gap-x-2 rounded-sm"
    >
      <Avatar src={fbUserData.avatarUrl} square size={30} />
      <p
        data-testid={`${testId}-userNameStyled`}
        className="!mb-0 text-sm font-semibold"
      >
        {fbUserData.userName}
      </p>
      <button
        type="button"
        onClick={() => {
          history.push({
            ...location,
            search: qs.stringify({
              ...qs.parse(search),
              modal: 'delete-facebook-token',
            }),
          });
        }}
        data-testid="facebook-remove-account-button"
      >
        <Icon name="trash" fontSize={16} />
      </button>
    </div>
  );
};

export default FacebookLinkedAccount;
