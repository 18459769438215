import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import Layout from '../../../../components/layouts/default';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import Card from '../../../../components/@v2/Card';
import Table from '../../../../components/@v2/Table/Table';
import Search from '../../../../components/@v2/Search/Search';
import Paginator from '../../../../components/@v2/Paginator/Paginator';
import Button from '../../../../components/Button';
import Icon from '../../../../components/@v2/Icon';
import { getProductsList } from '../../../../store/api/products.api';
import Badge from '../../../../components/@v2/Badge';
import ProductFilter from './ProductFilter';

const Product = () => {
  const { t } = useTranslation();
  const { loading, data, meta, setMeta, pageSize } = useApiRequestContext();
  const history = useHistory();

  return (
    <Layout
      pretitle={t('general.admin')}
      title={t('general.products')}
      rightContent={
        <Button
          color="primary"
          onClick={() => {
            history.push('/admin/products/add');
          }}
          data-testid="add-products-button"
        >
          <div className="flex items-center gap-x-1">
            <Icon name="plus" />
            {t('admin.products.add-products')}
          </div>
        </Button>
      }
    >
      <Card className="anim-table-delayed shadow-table !border !border-line-default">
        <Search resource="title" meta={meta} setMeta={setMeta} filter={<ProductFilter />} />
        <Table
          isLoaded={!loading}
          activeSort={get(meta, 'sorts') || ''}
          items={get(data, 'items')}
          headers={[
            {
              label: t('general.title'),
              key: 'title',
              sort: false,
            },
            {
              label: t('admin.products.linked-to-position'),
              key: 'availableForPosition',
              sort: false,
            },
            {
              label: t('admin.products.expiration'),
              key: 'expiration',
              sort: false,
            },
            {
              label: t('general.price'),
              key: 'price',
              sort: false,
            },
            {
              label: t('general.active'),
              key: 'isActive',
              sort: false,
            },
            {
              label: t('general.promote'),
              key: 'promote',
              sort: false,
            },
            {
              label: t('general.translated'),
              key: 'translated',
              sort: false,
            },
            {
              label: t('general.tags'),
              key: 'tags',
              sort: false,
            },
          ]}
          pageSize={pageSize}
          renderBody={(item, index) => {
            const getBadgeLabel = (state) => {
              if (state) {
                return t('admin.products.yes');
              }

              return t('admin.products.no');
            };

            return (
              <tr key={item?.id} id={`product-table-row--${index}`}>
                <td>
                  <button
                    type="button"
                    className="text-left w-full"
                    onClick={() => {
                      history.push({
                        state: item,
                        pathname: `/admin/products/edit/${item?.id}`,
                      });
                    }}
                    data-testid={`order-button-${index}`}
                  >
                    {item?.title}
                  </button>
                </td>
                <td>
                  <Badge type={item?.availableForPosition ? 'success' : 'danger'}>
                    {getBadgeLabel(item?.availableForPosition)}
                  </Badge>
                </td>
                <td>{item?.expiration || '-'}</td>
                <td>{item?.price || 0}</td>
                <td>
                  <Badge type={item?.isActive ? 'success' : 'danger'}>{getBadgeLabel(item?.isActive)}</Badge>
                </td>
                <td>
                  <Badge type={item?.promote ? 'success' : 'danger'}>{getBadgeLabel(item?.promote)}</Badge>
                </td>
                <td>
                  <Badge type={item?.translated ? 'success' : 'danger'}>{getBadgeLabel(item?.translated)}</Badge>
                </td>
                <td>{item?.tags || '-'}</td>
              </tr>
            );
          }}
          setMeta={setMeta}
        />
        <div className="h-14 flex items-center bg-white">
          <Paginator
            pageCount={data?.totalCount / meta?.pageSize}
            initialPage={meta.page - 1}
            onPageChange={(value) => {
              if (meta.page !== value) {
                setMeta({
                  page: value,
                });
              }
            }}
            disableInitialCallback
          />
        </div>
      </Card>
    </Layout>
  );
};

const ProductWrapper = () => {
  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source, ...rest) => {
        const newFilters = {};

        const filters = rest?.[2] || [];

        filters.forEach((item) => {
          newFilters[item.key] = item.value;
        });

        const sort = {
          by: meta?.sorts?.replace('-', ''),
          direction: meta.sorts.includes('-') ? 'desc' : 'asc',
        };

        const response = await getProductsList(
          {
            filter: newFilters,
            page: {
              number: meta.page,
              size: meta.pageSize,
            },
            sort: meta.sorts ? sort : {},
          },
          {
            cancelToken: source.token,
          }
        );
        return response;
      }}
      defaultFilters={[{ key: 'isActive', value: 'true' }]}
    >
      <Product />
    </ApiRequestProvider>
  );
};

export default ProductWrapper;
