import i18n from '../i18n';

export default [
  {
    value: 1,
    label: i18n.t('general.job-grouping.workplace'),
  },
  {
    value: 2,
    label: i18n.t('general.job-grouping.department'),
  },
  {
    value: 3,
    label: i18n.t('general.job-grouping.location'),
  },
  {
    value: 4,
    label: i18n.t('general.job-grouping.type'),
  },
  {
    value: 5,
    label: i18n.t('general.job-grouping.category'),
  },
];
