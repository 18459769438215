import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import UpdateClientSettingsCommand from '@ts/apicontracts/clients/settings/update-client-settings-command';
import { getClient, getClientSettings, updateGDPR, getClientsConfig } from '../thunks/client.thunk';
import { Client } from '../models/client.models';

export type InitialState = {
  loading: boolean;
  gdprLoading: boolean;
  config:
    | (UpdateClientSettingsCommand & {
        isMainClient: boolean;
        applyFormSettings: {
          usePositionSpecificApplyForm: boolean;
        };
      })
    | {};
  error: string | null;
  data: {
    id: number;
    name: string;
    key: string;
    language: string;
    guid: string;
    integrationSettings: {
      showRekryShop: number;
      showShopPrices: boolean;
      showNewsAndSubscribers: boolean;
      showPublishOnAms: boolean;
    };
    assessmentTypes: {
      id: number;
      label: string;
    }[];
    items: Client[];
    totalCount: number;
  };
};

export const initialState: InitialState = {
  loading: false,
  gdprLoading: false,
  config: {
    isMainClient: false,
    applyFormSettings: {
      phoneNumber: 0,
      birthDate: 0,
      motivation: 0,
      cv: 0,
      showLinkedInButton: false,
      showImage: false,
      usePositionSpecificApplyForm: false,
    },
  },
  error: null,
  data: {
    id: 0,
    name: '',
    key: '',
    language: '',
    guid: '',
    integrationSettings: {
      showRekryShop: 0,
      showShopPrices: false,
      showNewsAndSubscribers: false,
      showPublishOnAms: false,
    },
    assessmentTypes: [],
    items: [],
    totalCount: 0,
  },
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClient: (state, { payload }) => {
      return {
        ...state,
        data: {
          ...state?.data,
          ...payload,
        },
      };
    },
    setClientConfig: (state, { payload }) => {
      return {
        ...state,
        config: {
          ...state?.config,
          ...payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientSettings.fulfilled, (state, { payload }) => {
      return {
        ...state,
        config: {
          ...state.config,
          applyFormSettings: payload.applyFormSettings,
        },
        data: {
          ...state.data,
          ...payload,
        },
      };
    });

    builder
      .addCase(getClientsConfig.fulfilled, (state, { payload }) => {
        return {
          ...state,
          config: {
            ...state.config,
            ...payload,
          },
          data: {
            ...state.data,
            ...payload,
            assessmentTypes: payload.assessmentTypes,
            integrationSettings: payload.integrationSettings,
          },
        };
      })
      .addCase(getClientsConfig.rejected, (state) => {
        return {
          ...state,
          config: {},
        };
      });

    builder
      .addCase(getClient.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getClient.fulfilled, (state, { payload }) => {
        return {
          ...state,
          data: {
            ...state.data,
            ...payload,
          },
          loading: false,
        };
      })
      .addCase(getClient.rejected, (state, { payload }) => {
        const error = get(payload, 'error_description');
        return {
          ...state,
          loading: false,
          error: error ? (error as string) : null,
        };
      });

    builder
      .addCase(updateGDPR.pending, (state) => {
        return {
          ...state,
          gdprLoading: true,
        };
      })
      .addCase(updateGDPR.fulfilled, (state, { payload }) => {
        return {
          ...state,
          gdprLoading: false,
          data: {
            ...state.data,
            integrationSettings: {
              ...state.data.integrationSettings,
              ...payload,
            },
          },
        };
      })
      .addCase(updateGDPR.rejected, (state, { payload }) => {
        const error = get(payload, 'error_description');
        return {
          ...state,
          gdprLoading: false,
          error: error ? (error as string) : null,
        };
      });
  },
});

export const { setClient, setClientConfig } = slice.actions;
export default slice.reducer;
