import React from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import uuidv4 from 'uuid/v4';
import BaseToolbar from './BaseToolbar';

const id = uuidv4();
const BaseTextToolbar = ({ withVideo }) => {
  const { t } = useTranslation();
  const testId = 'baseTextToolbar';

  return (
    <>
      <BaseToolbar withVideo={withVideo} />
      <div
        className="ql-formats"
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        href="#"
        id={`base-text-toolbar-${id}`}
      >
        <UncontrolledTooltip placement="top" target={`base-text-toolbar-${id}`}>
          {t('html-editor.text-type')}
        </UncontrolledTooltip>
        <select
          data-testid={`${testId}--option-regular-header`}
          className="ql-header"
          defaultValue="4"
        >
          <option data-testid={`${testId}--option-title`} value="1">
            {t('html-editor.title')}
          </option>
          <option data-testid={`${testId}--option-subtitle`} value="3">
            {t('html-editor.subtitle')}
          </option>
          <option data-testid={`${testId}--option-regular-text`} value="4">
            {t('html-editor.regular-text')}
          </option>
        </select>
      </div>
    </>
  );
};

BaseTextToolbar.defaultProps = {
  withVideo: false,
};

export default React.memo(BaseTextToolbar);
