import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import { get } from 'lodash';
import Modal, { ModalHeader, ModalBody } from '../Modal';
import Form from '../../form/Form';
import { useAPI } from '../../../context/api';
import events from '../../../helpers/events';
import withFilters from '../../withFilters';
import {
  setCandidatesWithKey,
  setActivePosition,
} from '../../../store/slices/settings.slice';
import { log } from '../../../helpers/utils';
import {
  useSelector as useSelectorToolkit,
  dispatch as dispatchToolkit,
} from '../../../store';

const Reject = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname, search, state } = useLocation();
  const queryParams = qs.parse(search);
  const filters = useSelectorToolkit(({ settings }) =>
    get(settings, 'filters.candidates')
  );
  const [sending, setSending] = useState(false);
  const [candidateAPI, positionAPI] = useAPI('candidate', 'position');
  const activePosition = useSelectorToolkit(({ settings }) =>
    get(settings, 'position')
  );

  const closeModal = () => {
    const queryParamsCopy = { ...queryParams };
    const stateCopy = { ...state };
    Reflect.deleteProperty(queryParamsCopy, 'modal');

    history.push({
      state: stateCopy,
      location: pathname,
      search: qs.stringify(queryParamsCopy),
    });
  };

  const getPipelineSteps = async (params) => {
    const filters = { ...params };

    let stringifyFilter = '';

    Object.keys(filters).forEach((key) => {
      if (stringifyFilter !== '') stringifyFilter += ',';
      stringifyFilter += `${key}==${filters[key]}`;
    });

    const pipelines = await positionAPI.getPipelineSteps(params.positionId, {
      filters: stringifyFilter,
    });

    dispatchToolkit(
      setActivePosition({
        ...activePosition,
        pipelines,
      })
    );
  };

  const onDelete = async () => {
    if (sending) return;

    try {
      setSending(true);

      await candidateAPI.delete(state.activeCandidate.id);
      events.$emit('refresh-candidates-reject');

      const { totalCount } = await candidateAPI.getList({
        filters: `positionId==${activePosition.id || activePosition.positionId},status==Rejected`,
      });

      dispatchToolkit(
        setCandidatesWithKey({
          key: 'rejected',
          payload: totalCount,
        })
      );
      getPipelineSteps(filters);

      closeModal();
    } catch (error) {
      log(error);
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal width={600}>
      <ModalHeader>{t('general.delete')}</ModalHeader>
      <ModalBody>
        <p className="text-center mb-3">
          {t('candidates.the-candidate-x-y-is-deleted-permanently', {
            x: get(state, 'activeCandidate.firstName'),
            y: get(state, 'activeCandidate.lastName'),
          })}
        </p>
        <Form
          className="anim-table-delayed"
          maxWidth="none"
          defaultValues={{}}
          onSubmit={onDelete}
          button={{
            parentClass: 'justify-content-center',
            label: t('general.delete'),
            submitProps: {
              color: 'danger',
              style: { marginRight: 5 },
            },
            loading: sending,
            cancelCallback: () => {
              closeModal();
            },
            cancelLabel: t('candidate.cancel'),
            cancelProps: {
              color: 'light',
            },
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export default withFilters(Reject);
