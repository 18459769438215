import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import CircularLoader from '../../../components/Loader/CircularLoader';

const PositionName = ({ position, isLoading, width = 300 }) => {
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  const checkIfEllipsisActive = (e) => {
    return e.offsetWidth < e.scrollWidth;
  };

  return (
    <div
      className="flex items-center justify-between font-bold !mb-0.5"
      style={{
        maxWidth: width,
      }}
    >
      {isEllipsisActive ? (
        <Tippy content={position} theme="light" placement="bottom-start">
          <p className="truncate mb-0">{position}</p>
        </Tippy>
      ) : (
        <p
          className="truncate mb-0"
          ref={(ref) => {
            if (ref) {
              setIsEllipsisActive(checkIfEllipsisActive(ref));
            }
          }}
        >
          {position}
        </p>
      )}

      {isLoading && (
        <div className="pl-3 pr-1">
          <CircularLoader size={30} />
        </div>
      )}
    </div>
  );
};

export default PositionName;
