import { get, isArray } from 'lodash';

export const getScreeningTypeIdByType = (value, screeningTypes) => {
  const screeningType = screeningTypes.filter((item) => item.type === value);
  return get(screeningType, '[0].screeningCriteriaTypeId');
};

export const getScreeningTypeById = (value, screeningTypes) => {
  const screeningType = (isArray(screeningTypes) ? screeningTypes : []).filter(
    (item) => item.screeningCriteriaTypeId === value
  );
  return get(screeningType, '[0].type');
};

export default {};
