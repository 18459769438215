/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Card = ({ children, className, style }) => (
  <div
    className={classNames('shadow-md rounded-lg overflow-hidden', className)}
    style={style}
  >
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

Card.defaultProps = {
  className: '',
  style: {},
};

export default Card;
