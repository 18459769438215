import { FieldArray } from 'formik';
import React from 'react';
import Item from './Item';

const AssesioTests = ({ items, setFieldValue, isDisabled }) => {
  if (!items) {
    return null;
  }

  return (
    <div className="assessio-container">
      <FieldArray
        render={() => (
          <div>
            {items.map((item, index) => (
              <Item
                defaultOpen={index === 0}
                key={item?.testId || item?.assessmentType}
                index={index}
                {...item}
                inputData={item}
                isDisabled={isDisabled}
                setFieldValue={setFieldValue}
              />
            ))}
          </div>
        )}
        name="tests"
      />
    </div>
  );
};

export default AssesioTests;
