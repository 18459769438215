import { uniqueId } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import { hideAll } from 'tippy.js';
import Icon from '../components/Icon';

const useBlockActions = () => {
  const { t } = useTranslation();

  return {
    customize: (callback = null) => {
      return (
        <Tippy
          key={uniqueId()}
          content={t('pages.customize-appearance')}
          placement="bottom"
        >
          <button
            type="button"
            onClick={() => {
              if (callback) {
                callback();
              }
            }}
            data-testid="career-page-block-customize"
          >
            <Icon name="paint-brush" color="var(--text-color-secondary)" />
          </button>
        </Tippy>
      );
    },
    toggleVisibility: (visible, callback = null) => {
      return (
        <Tippy
          key={uniqueId()}
          content={t(visible ? 'general.hide' : 'general.show')}
          placement="bottom"
        >
          <button
            type="button"
            onClick={() => {
              if (callback) {
                callback();
              }
            }}
            data-testid="career-page-block-visible"
          >
            <Icon
              name={visible ? 'eye' : 'eye-slash'}
              type="fal"
              color="var(--text-color-secondary)"
            />
          </button>
        </Tippy>
      );
    },
    duplicate: (callback = null) => {
      return (
        <Tippy
          key={uniqueId()}
          content={t('general.duplicate')}
          placement="bottom"
        >
          <button
            type="button"
            onClick={() => {
              if (callback) {
                callback();
              }
            }}
            data-testid="career-page-block-duplicate"
          >
            <Icon name="copy" color="var(--text-color-secondary)" />
          </button>
        </Tippy>
      );
    },
    delete: (callback = null) => {
      return (
        <Tippy
          key={uniqueId()}
          content={
            <div className="flex flex-col gap-2 !pb-1">
              <p className="mb-0">
                {t('career-page.delete-block-confirmation')}
              </p>
              <div className="flex items-center gap-x-2 text-xs">
                <button
                  type="button"
                  data-testid="career-page-block-cancel-button"
                  className="w-1/2"
                  onClick={() => {
                    hideAll();
                  }}
                >
                  {t('general.cancel')}
                </button>
                <button
                  type="button"
                  data-testid="career-page-block-delete-button"
                  className="w-1/2 text-white rounded-md h-[24px] flex items-center justify-center bg-primary"
                  onClick={() => {
                    if (callback) {
                      callback();
                    }
                  }}
                >
                  {t('general.remove')}
                </button>
              </div>
            </div>
          }
          maxWidth={170}
          placement="bottom"
          interactive
          trigger="click"
          theme="light"
          appendTo={document.body}
        >
          <button
            key={uniqueId()}
            type="button"
            data-testid="career-page-block-delete"
          >
            <Icon name="times" color="var(--text-color-secondary)" />
          </button>
        </Tippy>
      );
    },
  };
};

export default useBlockActions;
