import styled from 'styled-components';

export const QueryBuilderStyled = styled.div`
  .query-builder {
    margin: 0.5rem auto 1rem;
  }

  .group {
    background: rgb(245 246 247);
    border: 1px solid #f5f6f7;
  }

  .group--conjunctions {
    display: flex;
    align-items: center;
  }

  .group--header button {
    color: white;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    border: 0;
    border-radius: 0.375rem;
    margin: 4px;
    padding: 0.5rem 0.75rem;
  }

  .group--header .group--conjunctions label {
    padding: 0 4px;
    margin: 0;
  }

  .group-or-rule-container.group-container:first-child {
    padding-right: 0;
  }

  .rule--body {
    display: flex;
    align-items: center;
  }

  .rule--field,
  .group--field,
  .rule--operator,
  .rule--value,
  .rule--operator-options,
  .rule--widget,
  .widget--widget,
  .widget--valuesrc,
  .widget--sep,
  .operator--options--sep,
  .rule--before-widget,
  .rule--after-widget {
    display: flex;
    width: 100%;
  }

  .rule--operator div:first-child,
  .rule--field div:first-child,
  .widget--widget div:first-child,
  .query-builder-multiselect,
  .query-builder-select {
    width: 100%;
  }

  .rule--header button {
    border: 0;
    color: #283e59;
    margin-left: 4px;
  }

  .rule--widget--MULTISELECT .widget--valuesrc {
    display: none;
  }

  .query-preview {
    background-color: rgba(185, 218, 244, 0.2);
    padding: 10px;
    margin-top: 10px;
  }
`;

export const QueryBuilderStyledPreview = styled.div`
  background-color: rgba(185, 218, 244, 0.2);
  padding: 10px;
  margin-top: 10px;
`;
