/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { createPortal } from 'react-dom';

export const ModalContent = ({ children, onClose, isOpen }) => {
  if (!document.body || !isOpen) {
    return null;
  }

  return createPortal(
    <div className="fixed inset-0 z-[99999] flex items-center justify-center">
      <button
        className="absolute inset-0 bg-overlay"
        type="button"
        onClick={() => {
          onClose();
        }}
      />
      <div className="bg-white rounded-xl shadow-lg relative z-10 !p-4 min-w-[300px] w-full max-w-[600px]">
        {children}
      </div>
    </div>,
    document.body
  );
};

const Modal = ({ children, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {children({ isOpen, setIsOpen })}
      <ModalContent
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {content({ isOpen, setIsOpen })}
      </ModalContent>
    </>
  );
};

export default Modal;
