export const candidatesOptions = (t) => [
  {
    label: t('general.show-all'),
    value: null,
    rejectValue: null,
  },
  {
    label: t('candidates.table-header.rejected'),
    value: 'Rejected',
    rejectValue: true,
  },
  {
    label: t('candidates.table-header.not-rejected'),
    value: 'Active',
    rejectValue: false,
  },
];

export const documentsOptions = (t) => [
  {
    label: t('general.show-all'),
    value: null,
  },
  {
    label: t('candidates.table-header.with-documents'),
    value: 'true',
  },
  {
    label: t('candidates.table-header.no-documents'),
    value: 'false',
  },
];

export const categoriesOptions = (t) => [
  {
    label: t('general.show-all'),
    value: null,
  },
  {
    label: t('candidates.table-header.a-candidate'),
    value: 'A',
  },
  {
    label: t('candidates.table-header.b-candidate'),
    value: 'B',
  },
  {
    label: t('candidates.table-header.c-candidate'),
    value: 'C',
  },
];
