import { uniqueId } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';
import { TEXT_ORIENTATION_OPTIONS } from '../../../../../constants/CareerPage';
import { getImageServiceMediaUrl } from '../../../../../helpers/image';
import { addSortOrder, hexaToRgbaConverter, rgbaToHexaConverter } from '../../../../../helpers/utils';
import Icon from '../../../Icon';
import Field from '../../../Form/Field';

const SlideshowItem = (props) => {
  const { t } = useTranslation();
  const { item, dragHandleProps, commonProps } = props;
  const index = item?.index;
  const [isEditing, setIsEditing] = useState(!!item?.isEditing);
  const [shadowColor, setShadowColor] = useState(item?.textShadowColorHex);
  const image = item?.imageUpload ? item?.image : getImageServiceMediaUrl(item?.image);

  const onDrop = useCallback((files) => {
    const reader = new FileReader();

    reader.onload = () => {
      const dataURL = reader.result;
      commonProps.setFieldValue(`carouselItems[${index}].image`, dataURL);
      commonProps.setFieldValue(`carouselItems[${index}].imageUpload`, files[0]);
    };
    reader.readAsDataURL(files[0]);
  }, []);

  useEffect(() => {
    setIsEditing(item?.isEditing);
  }, [item?.isEditing]);

  return (
    <div className="flex flex-col gap-y-4 bg-white rounded-sm" data-testid="slideshow-item">
      <div className="flex items-center justify-between !px-4 py-[3px]">
        <div className="flex items-center flex-grow gap-x-2">
          <button type="button" {...dragHandleProps}>
            <Icon name="grip-vertical" fontSize={12} />
          </button>
          <div className="w-[30px] h-[30px] overflow-hidden rounded-sm">
            <img src={image} className="object-cover object-center h-full" alt="" />
          </div>
          <div className="text-xs">
            <p className="mb-0 truncate">{item?.imageUpload?.name || item?.image?.name}</p>
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <button
            type="button"
            onClick={() => {
              setIsEditing(!isEditing);
            }}
          >
            <Icon name="edit" fontSize={12} />
          </button>
          <button
            type="button"
            onClick={() => {
              commonProps.setFieldValue(
                'carouselItems',
                addSortOrder(
                  commonProps.carouselItems.filter((keyFigure) => keyFigure.sortOrder !== item.sortOrder)
                ).map((keyFigure, index) => ({
                  ...keyFigure,
                  index,
                }))
              );
            }}
          >
            <Icon name="trash" fontSize={12} />
          </button>
        </div>
      </div>
      {isEditing && (
        <div className="!px-4">
          <div className="flex justify-center !mb-4">
            <Dropzone
              maxFiles={1}
              multiple={false}
              onDrop={(files) => {
                onDrop(files);
              }}
              accept="image/png, image/jpeg, image/jpg, image/webp, image/gif"
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <div
                    className="grid place-items-center h-[180px] w-[180px] bg-upload-gray overflow-hidden"
                    style={{
                      borderRadius: item?.roundImage ? 99999 : 3,
                    }}
                  >
                    {image && <img src={image} className="object-cover object-center h-full" alt="" />}
                    {!image && <Icon name="upload" color="var(--text-color-secondary)" size="24px" />}
                    <input {...getInputProps()} data-testid="slideshow-item-image-input" />
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
          <Field dark name={`carouselItems[${index}].title`} label={t('general.title')} />
          <Field dark name={`carouselItems[${index}].description`} label={t('pages.caption')} />
          <FormGroup>
            <Label for="text-color">{t('career-page.text-color')}</Label>
            <div data-testid="slideshow-text-color">
              <ChromePicker
                color={item?.textColorHex || ''}
                onChange={(color) => {
                  commonProps.setFieldValue(`carouselItems[${index}].textColorHex`, color.hex);
                }}
              />
            </div>
          </FormGroup>
          <Field
            dark
            type="select"
            defaultOptions={TEXT_ORIENTATION_OPTIONS(t)}
            name={`carouselItems[${index}].textOrientation`}
            label={t('pages.text-orientation')}
          />
          <div className="flex items-center !mb-4">
            <span>{t('career-page.text-shadow')}</span>
            <Field dark name={`carouselItems[${index}].useTextShadow`} type="switch" className="!w-auto !mb-0 !ml-4" />
          </div>
          {item?.useTextShadow && (
            <ChromePicker
              className="!w-full !mb-6"
              id={uniqueId()}
              color={hexaToRgbaConverter(shadowColor)}
              onChangeComplete={(color) => {
                const { r, g, b, a } = color?.rgb;
                const rgba = `rgba(${r}, ${g}, ${b}, ${a})`;
                setShadowColor(rgbaToHexaConverter(rgba));

                commonProps.setFieldValue(`carouselItems[${index}].textShadowColorHex`, rgbaToHexaConverter(rgba));
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SlideshowItem;
