export const EditorVariables = [
  {
    id: '1',
    label: 'location.name',
    translationKey: 'html-editor.location-name',
  },
  {
    id: '2',
    label: 'application.deadline',
    translationKey: 'general.application-deadline',
  },
  {
    id: '3',
    label: 'recruiter.name',
    translationKey: 'html-editor.recruiter-name',
  },
  {
    id: '4',
    label: 'recruiter.phonenumber',
    translationKey: 'html-editor.recruiter-phone',
  },
  {
    id: '5',
    label: 'department',
    translationKey: 'general.department',
  },
  {
    id: '6',
    label: 'position.name',
    translationKey: 'html-editor.position-name',
  },
];

export const positionTemplateForm = {
  name: '',
  description: '',
  pitch: '',
  criterias: [
    {
      screeningCriteriaId: 0,
      requirementA: 0,
      requirementB: 0,
      requirementC: 0,
      sortOrder: 0,
    },
  ],
  pipelineSteps: [],
  categorySettingsA: {
    candidateCategoryId: 0,
    active: false,
    sendMailToCandidate: false,
    candidateEmailSubject: '',
    candidateEmailText: '',
    candidateEmailDelayHours: 0,
    autoRejectCandidate: false,
  },
  categorySettingsB: {
    candidateCategoryId: 0,
    active: false,
    sendMailToCandidate: false,
    candidateEmailSubject: '',
    candidateEmailText: '',
    candidateEmailDelayHours: 0,
    autoRejectCandidate: false,
  },
  categorySettingsC: {
    candidateCategoryId: 0,
    active: false,
    sendMailToCandidate: false,
    candidateEmailSubject: '',
    candidateEmailText: '',
    candidateEmailDelayHours: 0,
    autoRejectCandidate: false,
  },
  categorySettingsOthers: {
    candidateCategoryId: 0,
    active: false,
    sendMailToCandidate: false,
    candidateEmailSubject: '',
    candidateEmailText: '',
    candidateEmailDelayHours: 0,
    autoRejectCandidate: false,
  },
};

export const templatePageURL = 'https://support.weselect.com/en/articles/5326872-how-to-use-a-position-template';
export const positionPageURL = 'https://support.weselect.com/sv/articles/4876782-sa-lagger-du-upp-en-ny-tjanst-4-min';
