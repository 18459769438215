export default (payloadCreator, errorSettings) => {
  return async (args, options) => {
    try {
      return await payloadCreator(args, options);
    } catch (exception) {
      if (exception && !errorSettings?.customErrorHandling) {
        // TODO - plug in global notification
        return options.rejectWithValue(exception);
      }
      return options.rejectWithValue(exception);
    }
  };
};
