import qs from 'query-string';
import config from '../api';

export const api = config;

/**
 * @deprecated
 * @param resource
 * @returns {{search: (function(*): Promise<AxiosResponse<any>>), getList: (function(*=): Promise<AxiosResponse<any>>), find: (function(number, {}=): Promise<AxiosResponse<any>>), create: (function({}, {}=): Promise<AxiosResponse<any>>), update: (function({}, {}=): Promise<AxiosResponse<any>>), updateOrCreate: updateOrCreate, delete: (function({}=, {}=): Promise<AxiosResponse<any>>), browse: (function({}=, ({only}|null)=, {}=): Promise<AxiosResponse<any>>)}}
 */
const rpcStyleAPI = (resource) => ({
  search: (filters) => api.get(`${resource}/List?filters=${filters}`),
  getList: (queryParams = {}, options = {}) =>
    api.get(`${resource}/List?${qs.stringify(queryParams)}`, options),
  browse: (options = {}, queryParams = {}, headers = {}) =>
    api.get(
      `${resource}/list?${qs.stringify(
        queryParams && queryParams.only ? queryParams : { ...queryParams }
      )}`,
      { ...headers, ...options }
    ),
  find: (id, options = {}) => api.get(`${resource}/Get/${id}`, options),
  create: (payload, headers = {}) =>
    api.post(`${resource}/Add`, payload, headers),
  update: (payload, headers = {}) =>
    api.put(`${resource}/Update`, payload, headers),
  delete: (payload = {}, headers = {}) =>
    api.delete(`${resource}/Delete`, {
      headers,
      data: payload,
    }),
  updateOrCreate: ({ id, ...rest }, headers = {}) => {
    if (id) return api.put(`${resource}/${id}`, rest, headers);
    return api.post(resource, rest, headers);
  },
});

const restStyleAPI = (resource) => ({
  search: (filters) => api.get(`${resource}?filters=${filters}`),
  getList: (queryParams = {}, options = {}) =>
    api.get(`${resource}?${qs.stringify(queryParams)}`, options),
  browse: (options = {}, queryParams = {}, headers = {}) =>
    api.get(
      `${resource}?${qs.stringify(
        queryParams && queryParams.only ? queryParams : { ...queryParams }
      )}`,
      { ...headers, ...options }
    ),
  find: (id, options = {}) => api.get(`${resource}/${id}`, options),
  create: (payload, headers = {}) => api.post(resource, payload, headers),
  update: (payload, id, headers = {}) =>
    api.put(`${resource}/${id}`, payload, headers),
  delete: (id, payload = {}, headers = {}) =>
    api.delete(`${resource}/${id}`, {
      headers,
      data: payload,
    }),
  updateOrCreate: ({ id, ...rest }, headers = {}) => {
    if (id) return api.put(`${resource}/${id}`, rest, headers);
    return api.post(resource, rest, headers);
  },
});

/**
 *
 * @param resource - resurce which we want to browse
 * @param type - leave empty if using old API version, use 'restful' if we need RESTful
 * @returns {{search: (function(*): Promise<AxiosResponse<any>>), getList: (function(*=): Promise<AxiosResponse<any>>), find: (function(number, {}=): Promise<AxiosResponse<any>>), create: (function({}, {}=): Promise<AxiosResponse<any>>), update: (function({}, {}=): Promise<AxiosResponse<any>>), updateOrCreate: updateOrCreate, delete: (function({}=, {}=): Promise<AxiosResponse<any>>), browse: (function({}=, ({only}|null)=, {}=): Promise<AxiosResponse<any>>)}}
 */
export default (resource, type = '') => {
  let api = rpcStyleAPI(resource);

  if (type === 'restful') {
    api = restStyleAPI(resource);
  }

  return api;
};
