import { NotificationManager } from 'react-notifications';

const useToast = () => {
  const createNotification = (type = 'success', title = '', message = '') => {
    if (!title) return;

    switch (type) {
      case 'info':
        NotificationManager.info(message, title);
        break;
      case 'success':
        NotificationManager.success(message, title);
        break;
      case 'warning':
        NotificationManager.warning(message, title);
        break;
      case 'error':
        NotificationManager.error(message, title);
        break;
      default:
        throw new Error('notification type not found!');
    }
  };

  return {
    info: NotificationManager.info,
    success: NotificationManager.success,
    warning: NotificationManager.warning,
    error: NotificationManager.error,
    createNotification,
  };
};

export default useToast;
