import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { useApiRequestContext } from '../../../../../context/@v2/ApiRequestContext';
import { defaultImageUpload, getImageServiceMediaUrl } from '../../../../../helpers/image';
import { onBlockUpdate } from '../../../../../store/api/career-page.api';
import Button from '../../../../Button';
import MediaUploader from '../../MediaUploader';
import Icon from '../../../Icon';
import Field from '../../../Form/Field';
import { IFile } from '../../../../../interfaces/IFile';

interface Props {
  item: {
    logo: IFile;
    logoLink: string;
    pageBlockId: number;
    pageId: number;
    type: number;
    sortOrder: number;
    visible: boolean;
    backgroundColorHex: string;
    headerTextColorHex: string;
    subtitleTextColorHex: string;
    textColorHex: string;
    positionId?: number | null;
    titleFontSizeScale: string;
    subtitleFontSizeScale: string;
    bodyFontSizeScale: string;
  };
}

const FooterForm = ({ item }: Props) => {
  const { t } = useTranslation();
  const { data, reloadData } = useApiRequestContext();

  const onSubmit = async (values) => {
    await onBlockUpdate(
      data?.details,
      {
        ...values,
        logo: values?.logoUpload ? defaultImageUpload : values?.logo,
      },
      async () => {
        await reloadData();
        data?.renderSuccessNotification();
      }
    );
  };

  const onDrop = useCallback(
    (acceptedFiles, callback = null) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (callback) callback(reader.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
    [onSubmit]
  );

  return (
    <Formik initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit} className="w-full">
            <MediaUploader
              labelElem={
                <div className="flex items-center justify-between mb-2">
                  <Label for="logo" className="mb-0">
                    {t('general.logo')}
                  </Label>
                  {values?.logo && (
                    <button type="button">
                      <Icon
                        name="trash"
                        color="var(--text-color-secondary)"
                        className="clickable"
                        onClick={() => {
                          setFieldValue('logo', null);
                          setFieldValue('logoUpload', null);
                        }}
                      />
                    </button>
                  )}
                </div>
              }
              media={values?.logo && values?.logoUpload ? values?.logo : getImageServiceMediaUrl(values?.logo)}
              onDrop={(files) => {
                onDrop(files, (url) => {
                  setFieldValue('logo', url);
                  setFieldValue('logoUpload', files[0]);
                });
              }}
              resource="form-footer-image"
            />
            <Field dark name="logoLink" label={t('pages.logo-url')} />
            {/* @ts-ignore */}
            <Button
              type="submit"
              color="primary"
              className="w-full text-white"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="career-page-block-form-submit"
            >
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default FooterForm;
