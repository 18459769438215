import React from 'react';
import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import Icon from './@v2/Icon';

const FacebookLogin = ({ onFacebookResponse }) => {
  const { t } = useTranslation();
  const testId = 'facebookLogin';

  return (
    <ReactFacebookLogin
      data-testid={`${testId}-fbLogin`}
      appId={global?.appConfig?.fb_login_marketing_app_id}
      callback={(response) => onFacebookResponse(response)}
      scope="public_profile,ads_management,email"
      render={(renderProps) => (
        <Button
          color="primary"
          onClick={renderProps.onClick}
          data-testid="facebook-link-account-button"
        >
          <Icon type="fab" name="facebook-f" />
          {t('facebook.link-account')}
        </Button>
      )}
    />
  );
};

export default FacebookLogin;
