import React, { useMemo, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, FormGroup, Label, Collapse, Input, CustomInput } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { addSortOrder } from '../../../helpers/utils';
import Field from '../../@v2/Form/Field';
import ApplySettings, { ShowHideOptions } from '../../../constants/ApplySettings';
import Select from '../../input/Select';
import ScreeningCriteria from './ScreeningCriteria';
import Icon from '../../@v2/Icon';
import RangeSlider from '../../input/RangeSlider';
import RadioButton from '../../input/RadioButton';
import ScreeningQuestionsType from '../../../constants/ScreeningQuestionsType';
import { selectClientConfig } from '../../../store/selectors/client.selector';
import { useSelector as useSelectorToolkit } from '../../../store';
import {
  transformApplySettingsToFormCompliantFormat,
  convertApplySettingsToKeyValueFormat,
} from '../../../helpers/apply-settings';
import LineBehind from '../../LineBehind';

const ScreeningQuestions = ({
  state,
  setState,
  questionsOptions,
  addedQuestions,
  parsedQuestions,
  getQuestions,
  questionTypes,
}) => {
  const [isShown, setIsShown] = useState(false);
  const { applyFormSettings } = useSelectorToolkit(selectClientConfig);
  const applySettingsInitialValues = transformApplySettingsToFormCompliantFormat(
    state.applySettings,
    [...ApplySettings, ...ShowHideOptions],
    applyFormSettings
  );
  const { t } = useTranslation();
  const parsedQuestionsOptions = useMemo(() => {
    let questions = [];

    questionsOptions.forEach((option) => {
      const foundQuestion = addedQuestions.find(
        (question) => question.screeningCriteriaId === option.screeningCriteriaId
      );

      if (!foundQuestion) questions = [...questions, option];
    });

    return questions;
  }, [questionsOptions, addedQuestions]);

  const onDragEnd = ({ destination, draggableId }) => {
    if (!destination) return;
    const question = addedQuestions.find((item) => item.screeningCriteriaId.toString() === draggableId);
    const questions = [...addedQuestions].filter((item) => item.screeningCriteriaId.toString() !== draggableId);

    questions.splice(destination.index, 0, question);
    setState('questionsJsonString', JSON.stringify(addSortOrder(questions)));
  };

  const onQuestionRemove = (index) => {
    const questions = [...parsedQuestions];
    questions.splice(index, 1);

    setState('questionsJsonString', JSON.stringify(addSortOrder(questions)));
  };

  const questionJson = (value) => ({
    id: value,
    screeningCriteriaId: value,
    requirementA: null,
    requirementB: null,
    requirementC: null,
  });

  const [previewQuestion, setPreviewQuestion] = useState([]);

  const [form, setForm] = useState(
    addedQuestions.reduce((accumulator, currentValue) => {
      if (accumulator[currentValue.screeningCriteriaId]) return accumulator;

      return {
        ...accumulator,
        [currentValue.screeningCriteriaId]: {
          value: null,
        },
      };
    }, {})
  );

  useEffect(() => {
    const existingQuestions = addedQuestions.reduce((accumulator, currentValue) => {
      if (accumulator[currentValue.screeningCriteriaId]) return accumulator;

      return {
        ...accumulator,
        [currentValue.screeningCriteriaId]: {
          ...currentValue,
          value: null,
        },
      };
    }, {});

    setForm(existingQuestions);
    setState('addedQuestions', addedQuestions);
  }, [addedQuestions]);

  const renderRangeSlider = ({ screeningCriteriaId }, props = {}, type = '') => {
    const value = get(form, `${screeningCriteriaId}.value`);
    const sliderMin = parseInt(get(form, `${screeningCriteriaId}.sliderMin`) || 0, 10);
    const sliderMax = parseInt(get(form, `${screeningCriteriaId}.sliderMax`) || 0, 10);

    return (
      <RangeSlider
        data-testid={`position-${type}-${screeningCriteriaId}-input`}
        value={value || sliderMin || 0}
        min={sliderMin || 0}
        max={sliderMax}
        onChange={(selected) => {
          setForm({
            ...form,
            [screeningCriteriaId]: {
              ...form[screeningCriteriaId],
              value: selected,
            },
          });
        }}
        {...props}
      />
    );
  };

  const displayQuestionBuilder = (question) => {
    const { type, screeningCriteriaId } = question;

    const value = get(form, `${screeningCriteriaId}.value`);
    const sliderMin = get(form, `${screeningCriteriaId}.sliderMin`);
    const sliderMax = get(form, `${screeningCriteriaId}.sliderMax`);

    switch (type) {
      case ScreeningQuestionsType.YEARS_TYPE:
        return renderRangeSlider(
          question,
          {
            subText:
              value && value > 1 ? t('position.screening-criteria.years') : t('position.screening-criteria.year'),
          },
          type
        );

      case ScreeningQuestionsType.SLIDER_TYPE:
        return (
          <>
            <Label for="min">{t('position.screening-criteria.lowest-value')}</Label>
            <Input
              type="number"
              id={`${type}-${screeningCriteriaId}`}
              name={`${type}-${screeningCriteriaId}`}
              data-testid={`position-${type}-${screeningCriteriaId}-input`}
              defaultValue={sliderMin}
              placeholder={t('position.screening-criteria.min')}
              onChange={(e) => {
                setForm({
                  ...form,
                  [screeningCriteriaId]: {
                    ...form[screeningCriteriaId],
                    sliderMin: parseInt(get(e, 'target.value') || 0, 10) || null,
                  },
                });
              }}
            />
            <Label for="max" className="mt-2">
              {t('position.screening-criteria.highest-value')}
            </Label>
            <Input
              type="number"
              id={`${type}-${screeningCriteriaId}`}
              name={`${type}-${screeningCriteriaId}`}
              data-testid={`position-${type}-${screeningCriteriaId}-input`}
              defaultValue={sliderMax}
              placeholder={t('position.screening-criteria.max')}
              onChange={(e) => {
                setForm({
                  ...form,
                  [screeningCriteriaId]: {
                    ...form[screeningCriteriaId],
                    sliderMax: parseInt(get(e, 'target.value') || 0, 10) || null,
                  },
                });
              }}
            />
            {renderRangeSlider(question)}
          </>
        );

      case ScreeningQuestionsType.TEXT_TYPE:
        return (
          <Input
            type="textarea"
            id={`${type}-${screeningCriteriaId}`}
            name={`${type}-${screeningCriteriaId}`}
            data-testid={`position-${type}-${screeningCriteriaId}-input`}
            defaultValue={value || ''}
            onChange={(e) => {
              setForm({
                ...form,
                [screeningCriteriaId]: {
                  ...form[screeningCriteriaId],
                  value: get(e, 'target.value') || '',
                },
              });
            }}
            style={{ height: 150 }}
            placeholder={t('edit-position.insert-text')}
            className="mb-2"
          />
        );

      case ScreeningQuestionsType.SELECTIONS_TYPE:
        return (
          <Select
            options={
              get(form, `${screeningCriteriaId}.selections`)
                ? get(form, `${screeningCriteriaId}.selections`).map((item) => ({
                    ...item,
                    label: item.label.replace('Must be chosen', ''),
                  }))
                : []
            }
            className="mb-2"
            id={`${type}-${screeningCriteriaId}`}
            name={`${type}-${screeningCriteriaId}`}
            resource={`position-${type}-${screeningCriteriaId}`}
          />
        );

      case ScreeningQuestionsType.CHECKBOX_TYPE:
        return [
          {
            value: 'yes',
            label: t('general.yes'),
          },
          {
            value: 'no',
            label: t('general.no'),
          },
        ].map((item) => (
          <RadioButton
            key={item.value}
            value={item.value}
            onChange={(e) => {
              if (!get(e, 'target.value')) return;

              setForm({
                ...form,
                [screeningCriteriaId]: {
                  ...form[screeningCriteriaId],
                  value: get(e, 'target.value'),
                },
              });
            }}
            isSelected={value === item.value}
            label={item.label}
            id={`${type}-${screeningCriteriaId}-value`}
            data-testid={`position-${type}-${screeningCriteriaId}-radio`}
            name={`${type}-${screeningCriteriaId}`}
          />
        ));

      case ScreeningQuestionsType.VIDEO_TYPE:
        return (
          <CustomInput
            className="mt-2 mb-2"
            type="file"
            accept="video/mp4"
            data-testid="screening-questions-preview-video"
            disabled
          />
        );

      case ScreeningQuestionsType.MULTI_SELECT:
        return (
          <>
            {/* TODO: can be completed once the api is done */}
            {(form[screeningCriteriaId]?.selections || []).map((item, index) => (
              <div
                key={item.screeningCriteriaSelectionId}
                className="d-flex align-items-center justify-content-between"
                style={{
                  marginTop: '.2rem',
                  marginBottom: '.2rem',
                }}
              >
                <div className="d-flex align-items-center justify-content-between" style={{ width: '100%' }}>
                  <input
                    id={`multi-selection-${item.screeningCriteriaSelectionId}`}
                    name={`multi-selection-${item.screeningCriteriaSelectionId}`}
                    type="checkbox"
                    checked={item.selected}
                    onChange={({ target }) => {
                      setForm({
                        ...form,
                        [screeningCriteriaId]: {
                          ...form[screeningCriteriaId],
                          multiselection: form[screeningCriteriaId].multiselection.map((selection) => {
                            if (selection.screeningCriteriaSelectionId === item.screeningCriteriaSelectionId) {
                              selection.selected = target.checked;
                            }

                            return selection;
                          }),
                        },
                      });
                    }}
                    data-testid={`position-multi-selection-${index}}-input`}
                    className="mr-2"
                  />
                  <Input
                    style={{ flex: 1 }}
                    type="text"
                    value={item.name}
                    onChange={({ target }) => {
                      setForm({
                        ...form,
                        selections: form.selections.map((selection) => {
                          if (selection.screeningCriteriaSelectionId === item.screeningCriteriaSelectionId) {
                            selection.name = target.value;
                          }

                          return selection;
                        }),
                      });
                    }}
                  />
                </div>
              </div>
            ))}
          </>
        );

      default:
        return t('position.screening-criteria.no-question-builder');
    }
  };

  return (
    <>
      <LineBehind
        title={t('general.apply-form')}
        icon={`angle-${isShown ? 'up' : 'down'}`}
        onClick={() => setIsShown(!isShown)}
        dataTestId="position-deep-link-line-behind"
      />
      <Collapse isOpen={isShown}>
        <Formik
          enableReinitialize
          initialValues={{
            firstName: ApplySettings[1],
            lastName: ApplySettings[1],
            email: ApplySettings[1],
            phoneNumber: ApplySettings[applySettingsInitialValues.phoneNumber],
            birthDate: ApplySettings[applySettingsInitialValues.birthDate],
            motivation: ApplySettings[applySettingsInitialValues.motivation],
            cv: ApplySettings[applySettingsInitialValues.cv],
            showImage: ApplySettings[applySettingsInitialValues.showImage],
            ...applySettingsInitialValues,
            usePositionSpecificApplyForm:
              state?.applySettings && state?.applySettings?.usePositionSpecificApplyForm
                ? state?.applySettings?.usePositionSpecificApplyForm
                : ApplySettings[applySettingsInitialValues.usePositionSpecificApplyForm],
          }}
          validate={(values) => {
            setState('applySettings', convertApplySettingsToKeyValueFormat(values));
          }}
        >
          {({ values }) => {
            const isSpecificPositionApplyFormEnabled =
              values?.usePositionSpecificApplyForm && values?.usePositionSpecificApplyForm === true;
            return (
              <div>
                <div className="grid grid-cols-1 gap-x-4">
                  <Field
                    name="usePositionSpecificApplyForm"
                    type="switch"
                    label={t('general.use-specific-apply-form')}
                    callback={(formValues, switchValue) => {
                      setState('applySettings', {
                        ...(formValues || {}),
                        usePositionSpecificApplyForm: switchValue,
                      });
                    }}
                    className="font-bold"
                  />
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  <Field
                    disabled
                    name="firstName"
                    type="select"
                    label={t('general.first-name')}
                    defaultOptions={ApplySettings}
                  />
                  <Field
                    disabled
                    name="lastName"
                    type="select"
                    label={t('general.last-name')}
                    defaultOptions={ApplySettings}
                  />
                  <Field
                    disabled
                    name="email"
                    type="select"
                    label={t('general.email')}
                    defaultOptions={ApplySettings}
                  />
                  <Field
                    disabled={!isSpecificPositionApplyFormEnabled}
                    name="phoneNumber"
                    type="select"
                    label={t('general.phone-number')}
                    defaultOptions={ApplySettings}
                  />
                  <Field
                    disabled={!isSpecificPositionApplyFormEnabled}
                    name="birthDate"
                    type="select"
                    label={t('general.date-of-birth')}
                    defaultOptions={ApplySettings}
                  />
                  <Field
                    disabled={!isSpecificPositionApplyFormEnabled}
                    name="motivation"
                    type="select"
                    label={t('general.motivation')}
                    defaultOptions={ApplySettings}
                  />
                  <Field
                    disabled={!isSpecificPositionApplyFormEnabled}
                    name="cv"
                    type="select"
                    label={t('general.at-least-one-attachment-cv')}
                    defaultOptions={ApplySettings}
                  />
                  <Field
                    disabled={!isSpecificPositionApplyFormEnabled}
                    name="showImage"
                    type="select"
                    label={t('general.image')}
                    defaultOptions={ShowHideOptions}
                  />
                </div>
              </div>
            );
          }}
        </Formik>
      </Collapse>
      <h3 className="line-behind-title" style={{ marginTop: '10px' }}>
        {t('general.screening-questions')}
      </h3>
      {state.useAlternativeApplyForm && <p>{t('edit-position.not-available-when-external-apply-form')}</p>}
      {!state.useAlternativeApplyForm && (
        <div className="position-form-holder">
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="questions">{t('edit-position.chosen-questions')}</Label>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="questions-droppable">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={{
                          marginBottom: snapshot.isDraggingOver ? 64 : 0,
                        }}
                      >
                        {addedQuestions.map((question, index) => (
                          <Draggable
                            key={question.screeningCriteriaId}
                            draggableId={question.screeningCriteriaId.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className="card mb-2 pt-2 pb-2 pl-3 pr-3"
                              >
                                <div
                                  {...provided.dragHandleProps}
                                  className="d-flex align-items-center justify-content-between flex-row card p-0 mb-0"
                                  style={{
                                    boxShadow: 'none',
                                    border: 'none',
                                  }}
                                  id={`pptr-sq-handle-${index}`}
                                >
                                  {question.name}
                                  <div className="d-flex align-items-center">
                                    <button
                                      data-testid={`position-sq-item-${index}-eye-icon`}
                                      type="button"
                                      onClick={() => {
                                        setPreviewQuestion((state) => {
                                          if (state.includes(question.screeningCriteriaId)) {
                                            return state.filter((item) => item !== question.screeningCriteriaId);
                                          }
                                          return [...state, question.screeningCriteriaId];
                                        });
                                      }}
                                    >
                                      <Icon
                                        id={`pptr-sq-eye-${index}`}
                                        className="ml-2 clickable"
                                        name={
                                          previewQuestion.includes(question.screeningCriteriaId) ? 'eye-slash' : 'eye'
                                        }
                                      />
                                    </button>
                                    <button
                                      data-testid={`position-sq-item-${index}-trash-icon`}
                                      type="button"
                                      onClick={() => {
                                        onQuestionRemove(index);
                                      }}
                                    >
                                      <Icon id={`pptr-sq-trash-${index}`} className="ml-2 clickable" name="trash" />
                                    </button>
                                  </div>
                                </div>
                                <Collapse isOpen={previewQuestion.includes(question.screeningCriteriaId)}>
                                  <div className="d-flex flex-column mt-3">{displayQuestionBuilder(question)}</div>
                                </Collapse>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="questions">{t('edit-position.select-question')}</Label>
                <Select
                  value={null}
                  placeholder={`${t('position.screening-questions.select-question')}...`}
                  resource="position-sq"
                  options={parsedQuestionsOptions}
                  onChange={(selected) => {
                    if (parsedQuestions.find((item) => item.screeningCriteriaId === selected.value)) {
                      return;
                    }
                    setState(
                      'questionsJsonString',
                      JSON.stringify(addSortOrder([...parsedQuestions, questionJson(selected.value)]))
                    );
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="questions-list">{t('edit-position.create-question')}</Label>
                <ScreeningCriteria
                  questionTypes={questionTypes}
                  onSave={(response) => {
                      setState(
                        'questionsJsonString',
                        JSON.stringify(addSortOrder([...parsedQuestions, questionJson(response.screeningCriteriaId)]))
                      );
                      getQuestions();
                    }}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ScreeningQuestions;
