import { get } from 'lodash';
import { emailHasAccessToPage } from './UserMenu';

export default [
  // {
  //   label: 'general.dashboard',
  //   icon: 'home-alt',
  //   href: '/dashboard',
  // },
  {
    label: 'general.shop',
    icon: 'shopping-cart',
    href: '/shop',
    showFn: ({ integrationSettings, permissionLevel }) => {
      const shop = get(integrationSettings, 'showRekryShop');

      return !(shop === 2 && permissionLevel === 0);
    },
  },
  {
    label: 'general.positions',
    icon: 'suitcase',
    href: '/positions',
  },
  {
    label: 'general.candidates',
    icon: 'users',
    href: '/candidates',
  },
  {
    label: 'general.career-page',
    icon: 'globe',
    href: '/career-page',
    access: [2],
  },
  {
    label: 'general.analytics',
    icon: 'pie-chart',
    href: '/analytics',
  },
  {
    label: 'general.settings',
    icon: 'settings',
    href: '/settings',
    dropdown: [
      {
        to: '/settings/profile',
        label: 'general.profile',
        icon: 'user-edit',
      },
      {
        to: '/settings/subscriptions',
        label: 'general.subscriptions',
        icon: 'file-alt',
        showFn: ({ integrationSettings, permissionLevel }) => {
          if (permissionLevel === 0) return false;
          const subscriptions = get(integrationSettings, 'showNewsAndSubscribers');
          if (subscriptions) {
            return true;
          }

          return false;
        },
      },
      {
        to: '/settings/career-pages',
        label: 'general.career-pages',
        access: [2],
        icon: 'file-alt',
      },
      {
        to: '/settings/pipeline',
        label: 'general.pipeline',
        access: [2],
        icon: 'chart-network',
      },
      {
        to: '/settings/location',
        label: 'general.location',
        access: [2],
        icon: 'map-marker',
      },
      {
        to: '/settings/departments',
        label: 'general.departments',
        access: [2],
        icon: 'building',
      },
      {
        to: '/settings/templates',
        label: 'general.templates',
        access: [1, 2],
        icon: 'file-alt',
      },
      {
        to: '/settings/screening-questions',
        label: 'general.screening-questions',
        access: [1, 2],
        icon: 'file-search',
      },
      {
        to: '/settings/users',
        label: 'settings.users.sidebar-label',
        access: [2],
        icon: 'shield-check',
      },
      {
        to: '/settings/gdpr',
        label: 'general.gpdr',
        access: [2],
        icon: 'shield-check',
      },
      // {
      //   to: '/settings/tests',
      //   label: 'general.tests',
      //   access: [2],
      // },
      {
        to: '/settings/integrations',
        label: 'settings.integrations.title',
        access: [2],
      },
      {
        to: '/settings/scheduler',
        label: 'pages.scheduler',
      },
    ],
  },
  {
    label: 'general.admin',
    icon: 'hand-spock',
    href: '/admin',
    access: [2],
    showFn: ({ superAdmin }) => superAdmin,
    dropdown: [
      {
        to: '/admin/facebook',
        label: 'general.facebook',
        icon: 'facebook-square',
        showFn: ({ email }) => emailHasAccessToPage(email),
      },
      {
        to: '/admin/orders',
        label: 'general.orders',
        icon: 'users-class',
      },
      {
        to: '/admin/clients',
        label: 'general.clients',
        icon: 'users-class',
      },
      {
        to: '/admin/users',
        label: 'general.users',
        icon: 'user',
      },
      {
        to: '/admin/products',
        label: 'general.products',
        icon: 'inventory',
      },
      {
        to: '/admin/tool',
        label: 'tool.label',
        icon: 'tools',
      },
    ],
  },
  {
    label: 'general.faq',
    icon: 'help-circle',
    href: 'https://support.weselect.com/sv',
    target: '_blank',
    link: true,
    showFn: ({ locale }) => locale === 'sv-SE',
  },
  {
    label: 'general.faq',
    icon: 'help-circle',
    href: 'http://faq.weselect.com/en/',
    target: '_blank',
    link: true,
    showFn: ({ locale }) => locale !== 'sv-SE',
  },
];
