import React from 'react';
import { Card, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import Icon from './@v2/Icon';
import '../assets/sass/product.sass';
import { selectIntegrationSettings } from '../store/selectors/client.selector';
import { useSelector as useSelectorToolkit } from '../store';

const Product = ({
  title,
  description,
  expiration,
  price,
  children,
  toggledAmsProduct,
  icons,
  ...rest
}) => {
  const integrationSettings = useSelectorToolkit(selectIntegrationSettings);
  const { t } = useTranslation();

  return (
    <Card
      data-testid="product-component"
      style={rest.style ? { ...rest.style } : null}
      className={classnames('product d-flex ', rest.className)}
    >
      <CardBody className="d-flex flex-column flex-grow-1">
        {!toggledAmsProduct.includes(rest.id) && (
          <>
            <p className="product-title">{title}</p>
            <div
              className="product-description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className="mb-auto" />
            <div className="product-price-expiration d-flex align-items-center justify-content-center">
              {!rest.isBoostAuto && (
                <div className="d-flex align-items-center product-price boost-auto">
                  <Icon name="usd-circle" />
                  <span>
                    {get(integrationSettings, 'showShopPrices')
                      ? `${price} kr`
                      : t('shop.price-according-to-contract')}
                  </span>
                </div>
              )}
              <div className="d-flex align-items-center product-expiration">
                <Icon name="calendar-alt" />
                <span>{`${expiration} ${t('shop.days')}`}</span>
              </div>
            </div>
            <div className="flex-grow-1 product-icons">
              <div
                className="product-icons-wrapper"
                dangerouslySetInnerHTML={{ __html: icons }}
              />
            </div>
          </>
        )}
        {children}
      </CardBody>
    </Card>
  );
};

Product.defaultProps = {
  toggledAmsProduct: [],
};

export default Product;
