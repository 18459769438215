import qs from 'query-string';
import { api } from '../../helpers/api-v2';

const resource = 'adrulesets';

export const findAdRulesById = async (id, options = {}) => {
  try {
    const response = api.get(`${resource}/${id}`, options);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createOrUpdateAdRules = async (values) => {
  try {
    const response = await (values?.id
      ? api.put(`${resource}/${values?.id}`, values)
      : api.post(resource, values));
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAdRule = async (id) => {
  try {
    const response = await api.delete(`${resource}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAdRulesList = async (queryParams = {}, options = {}) => {
  try {
    const response = api.get(
      `${resource}?${qs.stringify(queryParams)}`,
      options
    );
    return response;
  } catch (error) {
    throw error;
  }
};
