import { Formik } from 'formik';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useSelector } from '../../../../store';
import Field from '../../../../components/@v2/Form/Field';
import Button from '../../../../components/Button';
import {
  addOrUpdateIntegration,
  toggleIntegrationsActive,
} from '../../../../store/api/client-integrations.api';
import Divider from '../../../../components/@v2/Divider/Divider';
import AssesioTests from '../../../../components/@v2/AssessioTests';
import { selectClient } from '../../../../store/selectors/client.selector';
import { selectUser } from '../../../../store/selectors/user.selector';
import { getClient } from '../../../../store/thunks/client.thunk';
import { scrollElementIntoView } from '../../../../helpers/utils';

export const AssessioFormSchema = (t) => {
  return Yup.object().shape({
    organizationId: Yup.string()
      .nullable()
      .required(t('form-validator.required')),
  });
};

const ID = 'assessio';

const AssessioForm = ({
  initialValues = {
    organizationId: '',
    tests: [],
    isActive: true,
  },
}) => {
  const { id: clientId } = useSelector(selectClient);
  const { superAdmin } = useSelector(selectUser);
  const { t } = useTranslation();
  const history = useHistory();
  const isEditing = !!initialValues?.id;
  const [error, setError] = useState('');
  const formRef = useRef();

  const onSubmit = async (values) => {
    const configModel = {
      isActive: values?.isActive,
      organizationId: values?.organizationId,
      settings: values?.tests?.map((item) => {
        return {
          ...item,
          reminderDelayHours: item?.reminderDelayHours?.value,
        };
      }),
    };

    const finalData = {
      configModel,
      type: values?.type?.toLowerCase() || ID,
    };

    if (values?.id) {
      finalData.id = values?.id;
    }

    if (values?.type) {
      finalData.type = values?.type;
    }

    try {
      if (values?.id && initialValues?.isActive !== values?.isActive) {
        await toggleIntegrationsActive(
          ID,
          values?.isActive ? 'activate' : 'deactivate'
        );
      }
      await addOrUpdateIntegration(finalData);
      await getClient({
        superAdmin,
        id: clientId,
      });

      if (isEditing) {
        history.push('/settings/integrations');

        return;
      }

      history.push(`/settings/integrations/edit/${ID}`);
    } catch (error) {
      // TODO: display actual translated error message once feature from BE for setting error keys is finished
      // modify and use getError from utils
      setError(error ? t('error-message.something-went-wrong') : '');
      scrollElementIntoView(formRef);
      throw error;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AssessioFormSchema(t)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit} ref={formRef}>
            {!!error && (
              <Alert color="danger" className="mb-4">
                {error}
              </Alert>
            )}
            {isEditing && (
              <>
                <div className="flex items-center justify-between mb-5">
                  <img
                    className="logo w-[150px]"
                    src={`${global?.appConfig?.public_url}/img/integrations/${ID}.png`}
                    alt="logo"
                  />
                  <Field
                    name="isActive"
                    label={t('general.active')}
                    type="switch"
                    className="!w-auto !mb-0"
                  />
                </div>
              </>
            )}
            <Divider>{t('general.tests')}</Divider>
            <div className="mb-4">
              <AssesioTests
                items={values?.tests}
                setFieldValue={setFieldValue}
              />
            </div>
            <Divider>
              {isEditing
                ? t('settings.integrations.acceptance-threshold')
                : t('settings.integrations.api-secret-key')}
            </Divider>
            <Field
              name="organizationId"
              label={t('settings.integrations.organization-id')}
              required
            />
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="settings-integration-submit-button"
            >
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default AssessioForm;
