import React, { useState, useEffect, useMemo } from 'react';
import { sortBy } from 'lodash';
import ScreeningQuestions from './ScreeningQuestions';
import Categorizing from './Categorizing';
import { useAPI } from '../../../context/api';
import QuestionTypes from '../../../constants/ScreeningQuestionsType';
import { getScreeningTypeById } from '../../../helpers/screening-criteria';

const Screening = ({ state, ...rest }) => {
  const [screeningCriteriaAPI] = useAPI('screeningCriteriaV2');
  const [questionsOptions, setQuestionsOptions] = useState([]);
  const [screeningTypes, setScreeningTypes] = useState({});

  useEffect(() => {
    const loadScreeningTypes = async () => {
      const types = await screeningCriteriaAPI.getTypes();
      setScreeningTypes(types);
    };
    loadScreeningTypes();
  }, []);

  const parsedQuestions = useMemo(
    () => sortBy(JSON.parse(state.questionsJsonString), 'sortOrder'),
    [state.questionsJsonString]
  );

  const addedQuestions = useMemo(() => {
    let items = [];

    parsedQuestions.forEach((parentItem) => {
      const foundQuestion = questionsOptions.find(
        (question) => question.screeningCriteriaId === parentItem.screeningCriteriaId
      );

      if (foundQuestion) {
        const screeningType = getScreeningTypeById(foundQuestion.screeningCriteriaTypeId, screeningTypes);
        items = [
          ...items,
          {
            ...foundQuestion,
            ...parentItem,
            type: screeningType,
            selections: foundQuestion.selections.map((item, index) => ({
              ...item,
              value: index + 1,
              label: item.name,
            })),
          },
        ];
      }
    });

    return items;
  }, [parsedQuestions, questionsOptions, QuestionTypes, screeningTypes]);

  const getQuestions = async () => {
    try {
      const items = await screeningCriteriaAPI.lookup({
        filters: 'deleted==false',
      });

      setQuestionsOptions(
        items.map((item) => ({
          ...item,
          id: item.screeningCriteriaId,
          value: item.screeningCriteriaId,
          label: item.name,
          selections: item.selections.map((selection, index) => ({ ...selection, value: index + 1 })),
        }))
      );
    } catch (error) {
      // log(error)
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <div className="position-form">
      <ScreeningQuestions
        state={state}
        questionsOptions={questionsOptions}
        parsedQuestions={parsedQuestions}
        addedQuestions={addedQuestions}
        getQuestions={getQuestions}
        questionTypes={QuestionTypes}
        {...rest}
      />
      <Categorizing state={state} addedQuestions={addedQuestions} parsedQuestions={parsedQuestions} {...rest} />
    </div>
  );
};

export default Screening;
