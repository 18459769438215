import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { Card, Input, Table as TableReactstrap } from 'reactstrap';
import styled from 'styled-components';
import classnames from 'classnames';
import uuidv4 from 'uuid/v4';
import qs from 'query-string';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableLoader from './TableLoader';
import Icon from '../@v2/Icon';
import Paginator from '../Paginator';
import { selectTablePageSize } from '../../store/selectors/settings.selector';
import { useSelector as useSelectorToolkit } from '../../store';

const TableContainer = styled(TableReactstrap)`
  margin-bottom: 0 !important;
`;

const id = uuidv4();
const Table = ({
  filterType,
  filterDesign,
  filterCondition,
  api,
  callback,
  headers,
  search,
  filterInput,
  tableHeaderAfterComponent,
  onClearFilterCallback,
  customTableHeader,
  data,
  defaultFilter,
  defaultSort,
  maxWidth,
  resource,
  filterEventsKeys,
  searchText,
  customTableHeaderButton,
  loading,
  ...rest
}) => {
  const defaultPageSize = useSelectorToolkit(selectTablePageSize);
  const history = useHistory();
  const location = useLocation();
  const testId = 'tableWrapper';
  const queryParams = useMemo(
    () => qs.parse(location.search),
    [location.search]
  );
  const [isLoading, setIsLoading] = useState(loading);
  const [totalCount, setTotalCount] = useState(0);
  const stateFilter = get(location, 'state.filters') || defaultFilter;

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const clearFilters = useCallback(() => {
    const filters = {};

    filterInput
      .filter((f) => f.persist)
      .forEach((filter) => {
        if (stateFilter[filter.filterKey]) {
          filters[filter.filterKey] = stateFilter[filter.filterKey];
        }
      });

    history.push({
      ...location,
      state: {
        ...location.state,
        filters,
      },
    });
  }, [stateFilter]);

  const getCurrentSortKeyIcon = (key) => {
    const currentSort = get(location, 'state.sorts') || '';

    if (currentSort.includes(`-${key}`)) return 'sort-up';
    if (currentSort.includes(key)) return 'sort-down';
    return 'sort';
  };

  const pageSize = useSelectorToolkit(selectTablePageSize);

  useEffect(() => {
    setTotalCount(0);
  }, [pageSize]);

  return (
    <Card
      className={classnames('mb-0', rest.className)}
      style={{ maxWidth }}
      data-testid={testId}
    >
      {(!!filterInput.length ||
        search !== '' ||
        customTableHeader !== null) && (
        <TableHeader
          search={search}
          clearId={id}
          clearFilters={clearFilters}
          clearEventKey={`clear-${id}`}
          isLoading={isLoading}
          filterType={filterType}
          filterDesign={filterDesign}
          filterInput={filterInput}
          onClearFilterCallback={onClearFilterCallback}
          customTableHeader={customTableHeader}
          stateFilter={stateFilter}
          filterEventsKeys={filterEventsKeys}
          searchText={searchText}
          defaultSort={defaultSort}
          customTableHeaderButton={customTableHeaderButton}
        >
          {tableHeaderAfterComponent}
        </TableHeader>
      )}
      <TableContainer
        responsive
        hover
        size="sm"
        className={classnames('ts-custom-table', rest.tableClassname)}
      >
        <thead>
          <tr>
            {headers.map((item) => {
              if (item.hide) return null;
              if (item.type === 'checkbox') {
                return (
                  <th key={uuidv4()}>
                    <div className="d-flex align-items-center justify-content-center">
                      <Input
                        data-testid={`${resource}-checkbox-all`}
                        type="checkbox"
                        checked={item.unchecked.length ? false : item.all}
                        onChange={() => {
                          item.onClick(
                            null,
                            true,
                            !(item.unchecked.length ? false : item.all)
                          );
                        }}
                      />
                    </div>
                  </th>
                );
              }

              return (
                <th
                  key={uuidv4()}
                  style={{
                    position: item.sticky ? 'sticky' : null,
                    right: item.sticky ? 0 : null,
                    background: item.sticky ? '#dfe6ec' : null,
                    color: item.sticky ? '#696969' : null,
                    width: item.width || null,
                    minWidth: item.width || null,
                    whiteSpace: 'nowrap',
                  }}
                >
                  <div
                    style={item.headerStyle || {}}
                    className={classnames(
                      'table-header d-flex align-items-center clickable',
                      item.headerClassName,
                      { [`table-sort--${item.key}`]: item.key },
                      { 'justify-content-center': item.sticky }
                    )}
                    onClick={() => {
                      if (
                        !item.key ||
                        item.sort === false ||
                        filterType !== 'server-side'
                      ) {
                        return;
                      }
                      const currentSort = get(location, 'state.sorts') || '';

                      if (currentSort.includes(item.key)) {
                        const newSort = currentSort
                          .split(',')
                          .map((sort) => {
                            if (sort.includes(`-${item.key}`)) {
                              return item.key;
                            }

                            if (sort.includes(item.key)) {
                              return '';
                            }

                            return `-${item.key}`;
                          })
                          .filter((sort) => sort)
                          .join(',');

                        history.push({
                          ...location,
                          state: {
                            ...location.state,
                            sorts: newSort,
                          },
                        });
                      } else {
                        history.push({
                          ...location,
                          state: {
                            ...location.state,
                            sorts: `-${item.key}`,
                          },
                        });
                      }
                    }}
                  >
                    {item.label}
                    {!!item.key &&
                      item.sort !== false &&
                      filterType === 'server-side' && (
                        <Icon
                          className="ml-3 ts-table-sort-icon"
                          type="fad"
                          name={getCurrentSortKeyIcon(item.key)}
                          size="1x"
                        />
                      )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <TableBody
          data={data}
          api={api}
          defaultFilter={defaultFilter}
          defaultSort={defaultSort || ''}
          filterType={filterType}
          filterCondition={filterCondition}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          callback={callback}
          headers={headers}
          pageSize={defaultPageSize}
          stateCallback={(totalCount) => {
            setTotalCount(totalCount);
          }}
          resource={resource}
        />
        {isLoading && (
          <TableLoader headers={headers} pageSize={defaultPageSize} />
        )}
      </TableContainer>
      {filterType === 'server-side' && defaultPageSize < totalCount && (
        <Paginator
          count={defaultPageSize}
          currentPage={parseInt(queryParams.currentPage || 1, 10)}
          totalCount={totalCount}
        />
      )}
    </Card>
  );
};

Table.defaultProps = {
  filterType: 'server-side',
  filterDesign: 'default',
  filterCondition: {},
  filterInput: [],
  api: null,
  callback: null,
  headers: [],
  search: '',
  tableHeaderAfterComponent: <></>,
  onClearFilterCallback: null,
  customTableHeader: null,
  data: null,
  defaultFilter: null,
  maxWidth: null,
  filterEventsKeys: [],
  searchText: null,
  customTableHeaderButton: null,
  resource: 'general',
  loading: true,
};

export default Table;
