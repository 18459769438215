import qs from 'query-string';
import helper, { api } from '../helpers/api';

const resource = 'Locations';

export default {
  ...helper(resource, 'restful'),
  browse: (queryParams = {}, options = {}) =>
    api.get(`${resource}?${qs.stringify(queryParams)}`, options),
  read: (id, options = {}) => api.get(`${resource}/${id}`, options),
  add: (data) => api.post(resource, data),
  update: (data) => api.put(`${resource}/${data.id}`, data),
  destroy: (id) => api.delete(`${resource}/${id}`),
  restore: (id) => api.post(`${resource}/${id}/restore`),
};
