import React from 'react';
import classnames from 'classnames';

const randomize = (max = 100, min = 59) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const Placeholder = ({
  square,
  circle,
  height,
  width,
  borderRadius,
  random,
  unitType,
  ...rest
}) => {
  const testId = 'placeholder-component';

  const getWidth = () => {
    if (square || circle) return height;
    if (width && width !== 0) return `${width}${unitType}`;
    if (random.min && random.max) {
      return `${randomize(random.max, random.min)}${unitType}`;
    }
    return `${randomize()}${unitType}`;
  };

  return (
    <div
      {...rest}
      data-testid={testId}
      className={classnames('placeholder', rest.className)}
      style={{
        height,
        borderRadius: circle ? height : borderRadius,
        width: getWidth(),
        ...rest.style,
      }}
    />
  );
};

Placeholder.defaultProps = {
  square: false,
  circle: false,
  width: 0,
  borderRadius: 4,
  className: '',
  style: {},
  random: {},
  unitType: '%',
};

export default Placeholder;
