import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import Icon from '../@v2/Icon';
import events from '../../helpers/events';
import { useAPI } from '../../context/api';
import Loader from '../Loader';
import { selectUser } from '../../store/selectors/user.selector';
import { selectTags } from '../../store/selectors/settings.selector';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../store';
import { getAllTags } from '../../store/thunks/settings.thunks';
import { createTag } from '../../store/api/candidates.api';

const Tags = ({ items, candidateId, callback }) => {
  const { t } = useTranslation();
  const [candidateAPI] = useAPI('candidate');
  const [focus, setFocus] = useState(false);
  const { userId } = useSelectorToolkit(selectUser);
  const tagsOptions = useSelectorToolkit(selectTags);
  const [name, setName] = useState('');
  const [isSending, setIsSending] = useState(false);
  const filteredTags = useMemo(() => {
    if (name) {
      return tagsOptions
        .filter(({ value }) => value.includes(name))
        .filter(({ value }) => !items.find((item) => item.name === value));
    }

    return tagsOptions;
  }, [tagsOptions, name, items]);

  const onSuccessCallback = () => {
    events.$emit('find-candidate', candidateId);
    callback(candidateId);
    setName('');
  };

  const onSubmit = async (selectedName = '', options = {}) => {
    if (
      (selectedName === '' && name === '') ||
      isSending ||
      items.find((item) => item.name === name || item.name === selectedName)
    ) {
      setName('');
      return;
    }

    try {
      setIsSending(true);
      await createTag({
        candidateId,
        userId,
        name: selectedName || name,
        ...options,
      });

      await dispatchToolkit(getAllTags());

      onSuccessCallback();
    } catch (error) {
      // log(error);
    } finally {
      setIsSending(false);
    }
  };

  const onDelete = async (tagId, tagName) => {
    try {
      await candidateAPI.deleteTag({
        candidateId,
        userId,
        tagId,
        name: tagName,
      });

      onSuccessCallback();
    } catch (error) {
      // log(error);
    }
  };

  return (
    <div className="flex flex-wrap gap-2">
      {items.map((tag, index) => (
        <div
          data-testid={`candidates-badge-${index}`}
          className="flex items-center gap-x-2 bg-accent-light !h-6 !px-2 overflow-hidden rounded-md"
          key={tag.id}
        >
          <p className="!mb-0 text-sm">#{tag.name}</p>
          <Tippy content={<span>{t('general.delete')}</span>} theme="light">
            <button
              className="!h-full flex items-center justify-center"
              data-testid="candidates-delete-tag-button"
              type="button"
              onClick={() => {
                onDelete(tag.id, tag.name);
              }}
            >
              <Icon name="times" color="var(--text-color-secondary)" className="relative top-px" />
            </button>
          </Tippy>
        </div>
      ))}
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit(name, {
            tagId: 0,
          });
        }}
        style={{ overflow: isSending ? 'hidden' : null }}
        className="flex items-center rounded-full border border-medium-gray gap-x-2 relative !h-6"
        // className="badge-tags d-flex badge-tags--input"
      >
        <span className="flex items-center justify-content-center rounded-tl-full rounded-bl-full bg-medium-gray px-2">
          #
        </span>
        <input
          data-testid="candidates-badge-input"
          className="text-xs flex-grow !border-none !outline-none w-[100px]"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setTimeout(() => {
              setFocus(false);
            }, 200);
          }}
          placeholder="Type tag"
        />
        {focus && !!filteredTags.length && (
          <ul className="absolute z-50 bg-white shadow-xl top-7 left-5 border rounded-md overflow-hidden">
            {filteredTags.map((item, index) => (
              <li
                data-testid={`candidates-badge-item-${index}`}
                key={item.value}
                className={classNames('truncate w-[140px]', index !== 0 && 'border-t')}
              >
                <button
                  className="px-2 py-1 transition truncate text-black text-xs text-left w-full hover:bg-accent-light"
                  type="button"
                  onClick={() => {
                    onSubmit(item.value);
                  }}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        )}
        {!isSending && (
          <button
            id="button--submit-tag"
            data-testid="candidates-badge-button"
            type="submit"
            className="!px-2"
            onClick={() => {
              onSubmit(name, {
                tagId: 0,
              });
            }}
          >
            <Icon name="plus" color="#9299ab" />
          </button>
        )}
        {isSending && (
          <Loader
            type="primary"
            size={24}
            parentStyles={{
              right: 10,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          />
        )}
      </form>
    </div>
  );
};

Tags.defaultProps = {
  callback: () => {},
};

export default Tags;
