/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { random, range, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import classNames from 'classnames';
import Placeholder from '../Placeholder';
import Icon from '../Icon';
import './Table.sass';

const arrayLoader = range(100).map(() => range(100).map(() => random(60, 100)));

const Table = ({ isLoaded, activeSort, items, headers, pageSize = 10, renderBody, setMeta, className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('ts-table-container overflow-x-auto', className)}>
      <table className="ts-table w-full">
        <thead>
          <tr className="table-headers">
            {headers.map((item) => {
              const sortKey = item?.sortKey || item.key;
              const getIconName = () => {
                if (activeSort && activeSort?.includes(sortKey)) {
                  if (activeSort.includes('-')) {
                    return 'sort-up';
                  }

                  return 'sort-down';
                }

                return 'sort';
              };

              const iconName = getIconName();

              const getComponent = () => {
                if (item.sort === false) {
                  if (item.component) return item.component;

                  return t(item.label);
                }

                return null;
              };

              return (
                <th
                  key={sortKey || v4()}
                  className={classNames(item.headerClassName, !!item.sticky && 'column-sticky')}
                  style={{
                    width: item?.width,
                    maxWidth: item?.width,
                  }}
                >
                  {item.sort !== false && (
                    <button
                      type="button"
                      className="flex items-center justify-between sort pr-3"
                      onClick={() => {
                        let newSort = '';

                        if (iconName === 'sort') {
                          newSort = sortKey.toString();
                        }

                        if (iconName === 'sort-down') {
                          newSort = `-${sortKey}`;
                        }

                        setMeta({
                          sorts: newSort,
                        });
                      }}
                    >
                      <span className="uppercase font-semibold">{t(item.label)}</span>
                      <Icon name={iconName} type="fad" className="!ml-4" fontSize={12} color="var(--gray-badge)" />
                    </button>
                  )}
                  {getComponent()}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {isLoaded && (items || []).map((item, index) => renderBody(item, index))}
          {!isLoaded &&
            range(pageSize).map((item) => (
              <tr key={item}>
                {headers.map((header, headerIndex) => (
                  <td
                    key={`${header.key}-${item}`}
                    style={{
                      height: 55,
                    }}
                    className={header.className}
                  >
                    {(get(header, 'className') || '').includes('actions') ? (
                      <div className="flex items-center justify-center">
                        <Placeholder height={20} width={20} widthUnit="px" />
                      </div>
                    ) : (
                      <Placeholder widthUnit="%" width={arrayLoader[headerIndex][item]} />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          {isLoaded && !(items || []).length && (
            <tr>
              <td colSpan={headers.length} className="text-center">
                <div className="py-1">{t('general.table-no-data')}</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  activeSort: PropTypes.string,
  isLoaded: PropTypes.bool.isRequired,
  items: PropTypes.array,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string,
      headerClassName: PropTypes.string,
      component: PropTypes.element,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      sort: PropTypes.bool,
    })
  ).isRequired,
  pageSize: PropTypes.number,
  setMeta: PropTypes.func,
  renderBody: PropTypes.func,
};

Table.defaultProps = {
  activeSort: '',
  items: [],
  renderBody: null,
  setMeta: null,
  pageSize: 10,
};

export default Table;
