import { mapValuesDeep } from 'deepdash-es/standalone';

export const replaceAllText = (jsonString) => {
  if (!jsonString) return '';
  return jsonString.replace(/"all/g, '"some');
};

export const replaceSomeText = (jsonString) => {
  if (!jsonString) return '';
  return jsonString.replace(/"some/g, '"all');
};

export const formatJsonLogic = (jsonLogicData) => {
  const formattedJsonLogic = replaceAllText(jsonLogicData);
  const parsedJsonLogic = JSON.parse(formattedJsonLogic);
  const mappedJsonLogic = mapValuesDeep(
    parsedJsonLogic,
    (item) => {
      if (item.some && Array.isArray(item.some)) {
        const [first, second] = item.some;
        const someItemValues = [first];
        const secondArr = second.in.length > 1 ? second.in[1] : second.in[0];
        return { some: [...someItemValues, ...secondArr] };
      }

      return item;
    },
    {
      childrenPath: 'and',
      checkCircular: true,
    }
  );

  return JSON.stringify(mappedJsonLogic);
};

export const deformatJsonLogic = (jsonString = null) => {
  if (!jsonString) {
    return '';
  }

  const parsedJsonLogic = JSON.parse(jsonString);
  const mapped = mapValuesDeep(
    parsedJsonLogic,
    (item) => {
      if (item.some && Array.isArray(item.some)) {
        const shiftedArr = item.some.slice(1);
        const updatedArr = [{ in: [{ var: '' }, [...shiftedArr]] }];
        return { some: [{ var: 'industries' }, ...updatedArr] };
      }
      return item;
    },
    {
      childrenPath: 'and',
      checkCircular: true,
    }
  );

  return JSON.stringify(mapped);
};
