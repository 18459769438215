import { useCopyToClipboard } from 'react-use';
import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '../@v2/Icon';
import { getPositionOptions } from '../../store/thunks/settings.thunks';
import { dispatch as dispatchToolkit, useSelector as useSelectorToolkit } from '../../store';
import CandidateMoveModal from '../../pages/@v2/candidates/CandidateMoveModal';
import Modal from '../@v2/Modal';
import { selectClient } from '../../store/selectors/client.selector';
import { LinkRedirectScope } from '../../Routes';

const toastConfig = {
  appearance: 'success',
  autoDismiss: true,
  autoDismissTimeout: 5000,
};

const CandidateAction = ({ all, selected, activeCandidate }) => {
  const { t } = useTranslation();
  const [, copyToClipboard] = useCopyToClipboard();
  const { addToast } = useToasts();
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const client = useSelectorToolkit(selectClient);

  useEffect(() => {
    dispatchToolkit(getPositionOptions());
  }, []);

  const endcodeToBase64Ascii = (str) => {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(parseInt(p1, 16));
    }));
  };

  return (
    <Modal content={({ setIsOpen }) => <CandidateMoveModal id={selected?.[0]?.id} setIsOpen={setIsOpen} />}>
      {({ setIsOpen }) => (
        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => {
            setDropdownOpen((state) => !state);
          }}
          className="flex-grow-0 candidate-more-action-button"
          style={{
            marginTop: '-0.25rem',
          }}
        >
          <DropdownToggle
            className="btn-naked d-flex align-items-star justify-content-end !shadow-none"
            style={{ width: 30 }}
            data-testid="candidates-other-actions-button"
          >
            <Icon name="more-vertical" color="black" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem
              data-testid="candidates-edit-action-button"
              disabled={selected.length > 1 || all}
              onClick={() => {
                if (selected.length > 1) return;
                history.push({
                  pathname: `/candidates/edit/${activeCandidate.id || activeCandidate.candidateId}`,
                  state: activeCandidate,
                });
              }}
            >
              <div className="flex items-center gap-x-2">
                <Icon name="edit" />
                {t('candidates.edit-candidates')}
              </div>
            </DropdownItem>
            <DropdownItem
              type="button"
              data-testid="candidates-move-action-button"
              disabled={selected.length > 1 || all}
              onClick={() => {
                setIsOpen(true);
                setDropdownOpen(false);
              }}
            >
              <div className="flex items-center gap-x-2">
                <Icon name="send" />
                {t('candidates.move-to-other-position')}
              </div>
            </DropdownItem>
            <DropdownItem
              type="button"
              data-testid="candidates-share-action-button"
              disabled={selected.length > 1 || all}
              onClick={() => {
                const redirectObject = {
                  redirect: LinkRedirectScope.Candidate,
                  model: {
                    candidateId: activeCandidate.id,
                    clientId: client.id,
                    positionId: activeCandidate.positionId,
                    pipelineStepId: activeCandidate.pipelineStepId,
                  },
                  additionalParams: window.location.search,
                };
                const url = `${window.location.origin}?redirect=${endcodeToBase64Ascii(JSON.stringify(redirectObject))}`;
                copyToClipboard(url);
                addToast(t('general.the-link-is-copied'), toastConfig);
              }}
            >
              <div className="flex items-center gap-x-2">
                <Icon name="link" />
                {t('candidates.share')}
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
    </Modal>
  );
};

export default CandidateAction;
