import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import qs from 'query-string';
import Modal, { ModalBody } from '../Modal';
import { selectUser } from '../../../store/selectors/user.selector';
import { resetCart } from '../../../store/slices/cart.slice';
import {
  useSelector as useSelectorToolkit,
  dispatch as dispatchToolkit,
} from '../../../store';

const SuccessCheckout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { search, state, pathname } = location;
  const { isSuperAdmin } = useSelectorToolkit(selectUser);
  const history = useHistory();
  const queryParams = qs.parse(search);
  const { positionId } = queryParams;
  const isPublishOnly = queryParams.isPublishOnly === 'true';

  const closeModal = () => {
    const queryParamsCopy = { ...queryParams };
    Reflect.deleteProperty(queryParamsCopy, 'modal');
    Reflect.deleteProperty(queryParamsCopy, 'positionId');
    Reflect.deleteProperty(queryParamsCopy, 'isPublishOnly');

    history.push({
      location: pathname,
      // search: qs.stringify(queryParamsCopy),
      state,
    });
  };

  useEffect(() => {
    return () => {
      dispatchToolkit(resetCart());
    };
  }, []);

  return (
    <Modal width={500}>
      <ModalBody>
        <div
          className="d-flex justify-content-center align-items-center flex-column"
          style={{ padding: 20 }}
        >
          <h2>
            {isPublishOnly || positionId !== 'null'
              ? t('edit-position.position-is-published')
              : t('general.order-completed')}
          </h2>
          <p>{t('shop.add-advertising-anytime')}</p>
          <div className="flex justify-center items-center gap-x-2">
            {positionId !== 'null' && isSuperAdmin && (
              <Button
                className="btn-rounded px-4"
                type="button"
                color="success"
                onClick={() => {
                  if (isPublishOnly) {
                    return closeModal();
                  }
                  return history.push('admin/orders');
                }}
              >
                {isPublishOnly ? t('general.confirm') : t('order.view-orders')}
              </Button>
            )}
            {isSuperAdmin ? (
              <Button
                className="btn-rounded px-4"
                type="button"
                color="light"
                outline
                onClick={() => {
                  if (isPublishOnly) {
                    history.push({
                      pathname: `/positions/edit/${positionId}`,
                      state: {
                        ...state,
                        fromOrder: 1,
                      },
                    });
                  } else if (positionId !== 'null') {
                    history.push('positions');
                  } else {
                    history.push('/admin/orders');
                  }
                }}
              >
                {isPublishOnly || positionId !== 'null'
                  ? t('edit-position.view-position')
                  : t('order.view-orders')}
              </Button>
            ) : (
              <Button
                type="button"
                className="btn-rounded px-4"
                color="light"
                outline
                onClick={() => history.push('positions')}
              >
                {t('general.confirm')}
              </Button>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SuccessCheckout;
