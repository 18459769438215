import qs from 'query-string';
import helper, { api } from '../helpers/api';

const resource = 'position-templates';

export default {
  ...helper(resource, 'restful'),
  browse: (queryParams = {}, options = {}) =>
    api.get(`${resource}/list?${qs.stringify(queryParams)}`, options),
  setPreviewData: (payload) => api.post(`${resource}/preview`, payload),
};
