import React, { useEffect, useRef, useState, useCallback, useMemo, Fragment } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { get, omit } from 'lodash';
import debounce from 'debounce-promise';
import { useHistory } from 'react-router-dom';
import { FormFeedback } from 'reactstrap';
import ApiRequestProvider, { useApiRequestContext } from '../../context/@v2/ApiRequestContext';
import { getPositionListV2 } from '../../store/api/positions.api';
import { removeItem, updateItem } from '../../store/slices/cart.slice';
import { selectIntegrationSettings } from '../../store/selectors/client.selector';
import { selectAmsGroups } from '../../store/selectors/settings.selector';
import { AmsProductFields } from '../../constants/Product';
import { SalaryType } from '../../constants/Position';
import '../../assets/sass/cart.sass';
import Table from './Table/Table';
import Icon from './Icon';
import Select from '../input/Select';
import SelectV2 from './Select/Select';
import { dispatch as dispatchToolkit, useSelector as useSelectorToolkit } from '../../store';

const CartTable = ({ checkout, isValidating, setCheckedItems }) => {
  const { t } = useTranslation();
  const history = useHistory();
  // const [positionAPI] = useAPI('position');
  const table = useRef();
  const { loading, data } = useApiRequestContext();
  const [selectMarginLeft, setSelectMarginLeft] = useState(0);
  const cart = useSelectorToolkit(({ cart }) => cart);
  const clientSettings = useSelectorToolkit(selectIntegrationSettings);
  const amsGroups = useSelectorToolkit(selectAmsGroups);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultOptionsForPosition, setDefaultOptionsForPosition] = useState([]);
  const [maximizedProducts, setMaximizedProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [allProductsSelected, setAllProductsSelected] = useState(false);

  const AmsProductType = 100;

  const salaryOptions = useMemo(
    () =>
      SalaryType.map((item) => ({
        ...item,
        label: t(item.label),
      })),
    [SalaryType]
  );

  const amsCategoryOptions = useMemo(
    () =>
      (amsGroups || []).map((item) => ({
        value: item.name,
        label: item.name,
        groups: item.groups,
      })),
    [amsGroups]
  );

  const checkedProducts = useMemo(
    () =>
      (maximizedProducts || []).map(({ key }) => {
        return key;
      }),
    [maximizedProducts]
  );

  const isInvalidAmsProduct = useCallback(
    (fieldKey, key) => {
      if (!fieldKey || !key) return false;
      const amsProduct = maximizedProducts.find((item) => item.key === key);
      const foundField = amsProduct?.customFields[fieldKey];

      return isValidating === true && (!foundField || foundField?.value === '');
    },
    [maximizedProducts, isValidating]
  );

  const getAmsGroupOptions = useCallback(
    (productKey) => {
      if (!maximizedProducts || !productKey) return [];
      const amsProduct = maximizedProducts.find(({ key }) => key === productKey);
      return amsProduct !== undefined
        ? amsProduct?.customFields?.amsGroupOptions?.groups?.map((group) => ({
            label: group.name,
            value: group.name,
            occupations: group.occupations,
          }))
        : [];
    },
    [maximizedProducts]
  );

  const getAmsOccupationOptions = useCallback(
    (productKey) => {
      if (!maximizedProducts || !productKey) return [];
      const amsProduct = maximizedProducts.find(({ key }) => key === productKey);
      return amsProduct !== undefined
        ? amsProduct?.customFields?.amsOccupationOptions?.occupations?.map((group) => ({
            label: group.name,
            value: group.id,
          }))
        : [];
    },
    [maximizedProducts]
  );

  const removeProduct = useCallback(
    (key) => {
      dispatchToolkit(removeItem(key));
      setIsLoading(false);
    },
    [setIsLoading]
  );

  const updateProduct = (item) => {
    dispatchToolkit(updateItem(item));
  };

  useEffect(() => {
    if (data?.items) {
      setDefaultOptionsForPosition(
        (data.items || []).map((item) => {
          item.label = `${item.name} ${item.locationName && item.locationName !== '' ? `- ${item.locationName}` : ''}`;
          item.value = item.id;

          return item;
        })
      );
    }

    if (!loading && !data?.items?.length) {
      history.push('/positions');
    }
  }, [data?.items, loading]);

  useEffect(() => {
    if (table.current && table.current.children[0] && table.current.children[0].children[0]) {
      table.current.children[0].children[0].addEventListener('scroll', (e) => {
        setSelectMarginLeft(e.target.scrollLeft);
      });
    }
  }, [table]);

  useEffect(() => {
    if (setCheckedItems && selectedProducts.length) {
      setCheckedItems(
        (cart || [])
          .filter(({ key }) => selectedProducts.includes(key))
          .map(({ key }) => {
            return key;
          })
      );
    }
  }, [selectedProducts, setCheckedItems]);

  const getOptionsTest = async (value, localCallback) => {
    const { items } = await getPositionListV2({
      filter: {
        status: 0,
        applicationDeadline: moment().add(1, 'd').format('MM-DD-YYYY'),
        id: value,
      },
      page: {
        number: 1,
        size: 20,
      },
    });
    localCallback(
      items.map((item) => {
        item.label = `${item.position} ${item.location && item.location !== '' ? `- ${item.location}` : ''}`;
        item.value = item.id;
        return item;
      })
    );
  };

  const getOptionsTestHandler = useMemo(() => debounce(getOptionsTest, 1000), []);

  const defaultAmsFieldValue = useCallback(
    (fieldKey, customFields) => {
      if (!customFields) return [];
      if (fieldKey === 'salaryType') {
        return salaryOptions.find((item) => item.value === customFields?.salaryType);
      }
      if (fieldKey === 'occupationNameId') {
        const occupationNameValue = customFields?.occupationNameId;
        const foundObj = (customFields?.amsOccupationOptions?.occupations || []).find(
          ({ id }) => id === occupationNameValue
        );
        return foundObj
          ? [
              {
                label: foundObj?.name || '',
                value: foundObj?.id || '',
              },
            ]
          : null;
      }
      return customFields[fieldKey] ? [customFields[fieldKey]] : null;
    },
    [maximizedProducts]
  );

  const updateProductCustomField = useCallback(
    (fieldKey, value, selectedProduct) => {
      const updatedProducts = maximizedProducts.length
        ? [...maximizedProducts].map((item) => {
            if (item?.key === selectedProduct.key) {
              const mappedItem = {
                ...item,
                customFields: {
                  ...item.customFields,
                  type: AmsProductType,
                  [fieldKey]: value,
                  filledAllAmsFields: true,
                },
              };

              const fieldsForRemoval = () => {
                const getAmsValue = (keys) =>
                  AmsProductFields(t)
                    .filter((item) => keys.includes(item.value))
                    .map(({ value }) => value);
                if (fieldKey === 'amsOccupationOptions') {
                  return getAmsValue(['occupationNameId']);
                }
                if (fieldKey === 'amsGroupOptions') {
                  return getAmsValue(['amsOccupationOptions', 'occupationNameId']);
                }
                return [];
              };
              return fieldKey === 'occupationNameId' || fieldKey === 'salaryType'
                ? mappedItem
                : {
                    ...mappedItem,
                    customFields: omit(mappedItem.customFields, fieldsForRemoval()),
                  };
            }
            return item;
          })
        : [selectedProduct];
      setMaximizedProducts(updatedProducts);
      updateProduct(updatedProducts.find((item) => item?.key === selectedProduct.key));
    },
    [maximizedProducts]
  );

  return (
    <>
      <Table
        items={cart || []}
        isLoaded={!isLoading}
        className="mb-4"
        headers={[
          {
            label: '',
            component: (
              <input
                type="checkbox"
                onChange={() => {
                  setAllProductsSelected((state) => !state);
                  if (!allProductsSelected) {
                    setSelectedProducts(
                      cart.map(({ key }) => {
                        return key;
                      })
                    );
                  } else {
                    setSelectedProducts([]);
                  }
                }}
                checked={allProductsSelected}
              />
            ),
            sort: false,
            width: 1,
            // headerClassName: '!pr-0',
          },
          {
            label: 'cart.product',
            key: 'title',
            sort: false,
          },
          {
            label: 'general.position',
            key: 'position',
            sort: false,
          },
          {
            label: 'general.location',
            key: 'location',
            sort: false,
          },
          {
            label: 'general.duration',
            key: 'duration',
            sort: false,
          },
          {
            label: 'general.price',
            key: 'price',
            sort: false,
          },
          {
            label: '',
            key: 'icon',
            width: 20,
            sort: false,
          },
        ]}
        renderBody={(item) => {
          const {
            // id,
            title,
            positionName,
            positionId,
            locationName,
            position,
            availableForPosition,
            expiration,
            price,
            key,
            customFields,
          } = item;

          const displayPosition = () => {
            if (!checkout && (get(item, 'availableForPosition') || get(item, 'positionId') === 0)) {
              return (
                <div style={{ height: positionId ? 32 : 55 }}>
                  <div
                    style={{
                      position: 'absolute',
                      height: positionId ? 32 : 55,
                      width: 153,
                      marginLeft: `-${selectMarginLeft}px`,
                    }}
                  >
                    <Select
                      async
                      resource="cart-select-position"
                      keys={['id', 'name']}
                      defaultOptions={defaultOptionsForPosition}
                      defaultValue={
                        positionName && positionId
                          ? {
                              value: positionId,
                              label: `${positionName} ${locationName ? `- ${locationName}` : ''}`,
                            }
                          : null
                      }
                      loadOptions={getOptionsTestHandler}
                      onChange={(optionItem) => {
                        const { value, label, location, position } = optionItem;
                        updateProduct({
                          ...item,
                          positionId: value,
                          positionName: label,
                          locationName: location,
                          position: position || '',
                          availableForPosition,
                        });
                      }}
                    />
                    {!positionId && <FormFeedback className="d-block">{t('form-validator.required')}</FormFeedback>}
                  </div>
                </div>
              );
            }

            return `${position || positionName || ''}${!checkout && locationName ? ` - ${locationName}` : ''}`;
          };

          const displayDuration = () => {
            if (expiration) {
              return `${expiration} ${expiration > 1 ? t('cart.days') : t('cart.day')}`;
            }

            return t('cart.no-expiration');
          };

          const setAMSProductFields = (fieldKey, fieldValue) => updateProductCustomField(fieldKey, fieldValue, item);

          const getDefaultFields = (fieldKey) => defaultAmsFieldValue(fieldKey, customFields);

          return (
            <Fragment key={`cart-product-${key}`}>
              <tr>
                <td>
                  <div className="mt-2">
                    <input
                      type="checkbox"
                      onChange={() => {
                        setSelectedProducts(
                          selectedProducts.includes(item.key)
                            ? [...selectedProducts].filter((existing) => existing !== item.key)
                            : [...selectedProducts, item.key]
                        );
                      }}
                      checked={selectedProducts.includes(item.key)}
                    />
                  </div>
                </td>
                <td>{title}</td>
                <td>{displayPosition()}</td>
                <td>{!checkout ? '' : locationName}</td>
                <td>{displayDuration()}</td>
                <td>{get(clientSettings, 'showShopPrices') ? `${price} kr` : t('shop.price-according-to-contract')}</td>
                <td>
                  <>
                    <div className="btn-menu-container">
                      {!item.adTypes?.includes(AmsProductType) ? (
                        <Icon
                          className="clickable"
                          name="times"
                          onClick={() => {
                            setIsLoading(true);
                            removeProduct(key);
                          }}
                        />
                      ) : (
                        <button
                          className={classnames(
                            'shadow-none btn btn-primary btn-naked btn-menu pr-2 d-flex align-items-center justify-content-center',
                            {
                              'btn-menu--minimized': (checkedProducts || []).includes(key),
                            }
                          )}
                          type="button"
                          onClick={() => {
                            setMaximizedProducts(
                              (checkedProducts || []).includes(key)
                                ? maximizedProducts.filter((item) => item.key !== key)
                                : [...maximizedProducts, item]
                            );
                          }}
                        >
                          <Icon name={`chevron-${(checkedProducts || []).includes(key) ? 'up' : 'down'}`} />
                        </button>
                      )}
                    </div>
                  </>
                </td>
              </tr>
              {(checkedProducts || []).includes(key) && (
                <tr>
                  <td colSpan="6">
                    <h6 className="header-pretitle text-xs">{t('cart.details-advanced-configuration')}</h6>
                    <p className="font-bold">{t('general.occupation')}</p>
                    <div className="w-50">
                      <SelectV2
                        label={AmsProductFields(t)[0].label}
                        searchable={false}
                        onSelect={([selected]) => {
                          setAMSProductFields('amsGroupOptions', selected);
                        }}
                        defaultOptions={amsCategoryOptions}
                        className="mb-2"
                        id="amsGroupOptions"
                        resource="amsGroupOptions"
                        value={getDefaultFields('amsGroupOptions')}
                        invalid={isInvalidAmsProduct('amsGroupOptions', key)}
                      />
                      <SelectV2
                        label={AmsProductFields(t)[1].label}
                        searchable={false}
                        onSelect={([selected]) => {
                          setAMSProductFields('amsOccupationOptions', selected);
                        }}
                        defaultOptions={getAmsGroupOptions(key)}
                        className="mb-2"
                        id="amsOccupationOptions"
                        resource="amsOccupationOptions"
                        value={getDefaultFields('amsOccupationOptions')}
                        invalid={isInvalidAmsProduct('amsOccupationOptions')}
                      />
                      <SelectV2
                        label={AmsProductFields(t)[2].label}
                        searchable={false}
                        onSelect={([selected]) => {
                          setAMSProductFields('occupationNameId', selected?.value);
                        }}
                        defaultOptions={getAmsOccupationOptions(key)}
                        className="mb-2"
                        id="occupationNameId"
                        resource="occupationNameId"
                        value={getDefaultFields('occupationNameId')}
                        invalid={isInvalidAmsProduct('occupationNameId', key)}
                      />
                      <SelectV2
                        label={t('edit-position.salary')}
                        searchable={false}
                        onSelect={([selected]) => {
                          setAMSProductFields('salaryType', selected?.value);
                        }}
                        defaultOptions={salaryOptions}
                        className="mb-2"
                        id="amsSalaryType"
                        resource="amsSalaryType"
                        value={getDefaultFields('salaryType')}
                        invalid={isInvalidAmsProduct('salaryType', key)}
                      />
                    </div>
                  </td>
                  <td colSpan="1">
                    <Icon
                      className="clickable"
                      name="times"
                      onClick={() => {
                        setIsLoading(true);
                        removeProduct(key);
                      }}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          );
        }}
      />
    </>
  );
};

CartTable.defaultProps = {
  checkout: false,
};

const CartTableWrapper = ({ checkout, setCheckedItems }) => {
  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source) => {
        const response = await getPositionListV2(
          {
            filter: {
              status: 0,
              applicationDeadline: moment().add(1, 'd').format('MM-DD-YYYY'),
            },
            sort: meta.sorts || {},
          },
          {
            cancelToken: source.token,
          }
        );
        return response;
      }}
    >
      <CartTable setCheckedItems={setCheckedItems} checkout={checkout} />
    </ApiRequestProvider>
  );
};

export default CartTableWrapper;
