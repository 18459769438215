import Assessments from './Assessments';

export const DefaultClientIntegration = [];

if (Assessments.assessio) {
  DefaultClientIntegration.push({
    id: Assessments.assessio,
    label: 'Assessio',
    isActive: true,
    image: 'assessio',
    type: 'Assessio',
  });
}

if (Assessments.refapp) {
  DefaultClientIntegration.push({
    id: Assessments.refapp,
    label: 'Refapp',
    isActive: true,
    image: 'refapp',
    type: 'Refapp',
  });
}

if (Assessments.alvaLabs) {
  DefaultClientIntegration.push({
    id: Assessments.alvaLabs,
    label: 'AlvaLabs',
    isActive: true,
    image: 'alva',
    type: 'Alvalabs',
  });
}

if (Assessments.ams) {
  DefaultClientIntegration.push({
    id: Assessments.ams,
    label: 'AMS',
    isActive: true,
    image: 'ams',
    type: 'AMS',
  });
}

if (Assessments.sri) {
  DefaultClientIntegration.push({
    id: Assessments.sri,
    label: 'SRI',
    isActive: true,
    image: 'sri',
    type: 'Sri',
  });
}

export const DefaultTests = [
  {
    id: 1,
    name: global?.appConfig?.assessment_type_1,
  },
  { id: 2, name: global?.appConfig?.assessment_type_2 },
];

export const TestTypeValueConverter = (typeId) => {
  switch (typeId) {
    case 1:
      return 'MINT';

    case 2:
      return 'Service First';

    default:
      return '<Unknown test type>';
  }
};

export const DefaultTestValues = [1, 2].map((item) => ({
  isActive: true,
  autoMoveGreenCandidatesToNextStep: true,
  autoMoveYellowCandidatesToNextStep: true,
  autoRejectRedCandidates: true,
  assessmentType: item,
  greenScoreLowerBoundary: 10,
  reminderDelayHours: 0,
  testId: 0,
  testName: TestTypeValueConverter(item),
  testSettingsId: 0,
  yellowScoreLowerBoundary: 0,
}));

export const CreateIntegrationsHrsOptions = (t) => {
  return [24, 36, 48, 60, 72].map((item) => ({
    label: `${t('settings.integrations.x-hrs', { x: item })}`,
    value: item,
    disabled: false,
  }));
};

export const RefappAutomationMailOptions = (t) => {
  return [
    {
      label: t('settings.tests.do-not-send-automatically'),
      value: '0',
    },
    {
      label: 'Mail',
      value: '1',
    },
    {
      label: 'SMS',
      value: '2',
    },
    {
      label: 'Mail + SMS',
      value: '4',
    },
  ];
};

export const RefappRefereesOptions = ['1', '2', '3', '4', '5'].map((item) => ({
  label: item,
  value: item,
}));

// nth iteration of client integration type IDs
export const ClientIntegrationTypeIdByName = (typeName) => {
  switch (typeName) {
    case 'assessio':
      return 10;

    case 'refapp':
      return 20;

    case 'alvalabs':
      return 30;

    case 'ams':
      return 40;

    case 'sri':
      return 50;

    default:
      return '';
  }
};
