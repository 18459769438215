import qs from 'query-string';
import PositionResponse from '@ts/apicontracts/positions/get/response';
import PositionUpdateRequestModel from '@ts/apicontracts/positions/position-update-request-model';
import PositionSearchResponseListItem from '@ts/apicontracts/positions/search/response-list-item';
import SearchPositionsQuery from '@ts/apicontracts/positions/search/search-positions-query';
import LookupResponseListItem from '@ts/apicontracts/positions/lookup/response-list-item';
import CountResponse from '@ts/apicontracts/positions/count/response';
import StepsResponseListItem from '@ts/apicontracts/positions/steps/response-list-item';
import RecruitersResponseListItem from '@ts/apicontracts/positions/recruiters/response-list-item';
import PositionCriteriaResponseModel from '@ts/apicontracts/positions/criterias/position-criteria-response-model';
import { AxiosResponseDirect, api } from '../../helpers/api-v2';

const resource = '/positions';

export const getPosition = (id: string) => {
  const response = api.get(`${resource}/${id}`);
  return response as unknown as AxiosResponseDirect<PositionResponse>;
};

export const getList = async (queryParams: SearchPositionsQuery) => {
  const positions = await api.get(`${resource}?${qs.stringify(queryParams || {})}`);
  return positions as unknown as AxiosResponseDirect<PositionSearchResponseListItem[]>;
};

export const getPositionListV2 = async (params: Partial<SearchPositionsQuery>, options = {}) => {
  const positions = await api.post(`${resource}/list`, params, options);
  return positions as unknown as AxiosResponseDirect<PositionSearchResponseListItem[]>;
};

export const getPositionLookupList = async (options = {}) => {
  const positions = (await api.post(`${resource}/lookup`, options)) as unknown as AxiosResponseDirect<
    LookupResponseListItem[]
  >;

  return positions.map((item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));
};

export const getPositionCount = async (filters = {}, options = {}) => {
  const positions = await api.post(
    `${resource}/counters`,
    {
      filters,
    },
    options
  );
  return positions as unknown as AxiosResponseDirect<CountResponse>;
};

export const getPipelineSteps = async (id: string | number, filters = {}) => {
  const pipelineSteps = (await api.get(
    `${resource}/${id}/steps?${qs.stringify(filters)}`
  )) as unknown as AxiosResponseDirect<StepsResponseListItem[]>;
  return pipelineSteps;
};

export const getRecruiters = async (payload: any = {}) => {
  const f = {
    filters: payload.filters,
    page: payload.page,
    pageSize: payload.pageSize,
  };

  const recruiters = (await api.get(`${resource}/recruiters?${qs.stringify(f)}`)) as unknown as AxiosResponseDirect<
    RecruitersResponseListItem[]
  >;
  return recruiters;
};

export const getScreeningCriterias = async (id: string) => {
  const criterias = (await api.get(`${resource}/${id}/criterias`)) as unknown as AxiosResponseDirect<
    PositionCriteriaResponseModel[]
  >;
  return criterias;
};

export const exportCandidates = async (id: string) => {
  const data = await api.get(`${resource}/${id}/export`, {
    responseType: 'blob',
  });
  return data;
};

export const archivePosition = async (id: string, options = {}) => {
  const response = await api.post(`${resource}/${id}/archive`, options);
  return response;
};

export const restorePosition = async (id: string, options = {}) => {
  const response = await api.post(`${resource}/${id}/restore`, options);
  return response;
};

export const duplicatePosition = async (id: string, options = {}) => {
  const response = await api.post(`${resource}/${id}/duplicate`, options);
  return response;
};

export const generatePreviewPosition = async (id: string, payload: PositionUpdateRequestModel, options = {}) => {
  const response = await api.post(`${resource}/${id}/preview`, payload, options);
  return response;
};
