import React from 'react';
import { Button } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import ReactJson from 'react-json-view';
import Modal, { ModalHeader, ModalBody } from '../Modal';

const IntegrationLogs = () => {
  const history = useHistory();
  const location = useLocation();
  const { state, search, pathname } = location;
  const queryParams = qs.parse(search);
  const { t } = useTranslation();

  const closeModal = (params = {}) => {
    const queryParamsCopy = { ...queryParams };
    Reflect.deleteProperty(queryParamsCopy, 'modal');
    history.push({
      state: {
        ...state,
        filters: {
          ...state.filters,
          ...params,
        },
      },
      location: pathname,
      search: qs.stringify(queryParamsCopy),
    });
  };

  return (
    <Modal width="90%" height="90vh">
      <ModalHeader>{t('modal.orders.integration-logs')}</ModalHeader>
      <ModalBody>
        <div className="flex flex-fd--column">
          {get(state, 'orderLogs') && state.orderLogs.length ? (
            <ReactJson
              src={state.orderLogs}
              style={{ overflowX: 'auto' }}
              theme="apathy:inverted"
            />
          ) : (
            <div style={{ textAlign: 'center' }}>
              {t('general.no-data-found')}
            </div>
          )}
          <div className="d-flex align-items-center justify-content-center">
            <Button
              type="button"
              color="light"
              style={{
                marginTop: 20,
              }}
              onClick={() => closeModal()}
            >
              {t('general.close')}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default IntegrationLogs;
