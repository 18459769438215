import { slice } from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Filter from '../../../../components/@v2/Filter/Filter';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import useElementClickHandlers from '../../../../hooks/useElementClickHandlers';
import { dispatch as dispatchToolkit } from '../../../../store';
import useFilterModal from '../../../../hooks/useFilterModal';
import { setRecruitersOptions } from '../../../../store/thunks/settings.thunks';
import { log } from '../../../../helpers/utils';
import { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import { deleteUser } from '../../../../store/api/admin.api';
import { getUserReferences } from '../../../../store/api/user.api';
import { getRecruiters } from '../../../../store/api/positions.api';

const UserDeleteModal = ({ id, name, closeModal }) => {
  const { t } = useTranslation();
  const { reloadData } = useApiRequestContext();
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showMorePositions, setShowMorePositions] = useState(false);
  const [userPositions, setUserPositions] = useState([]);

  const closeDeleteModal = () => {
    setIsFilterShown(false);
    setTimeout(() => {
      closeModal();
    }, 300);
  };

  useElementClickHandlers({
    condition: isFilterShown,
    callback: closeDeleteModal,
    elements: ['.ts-filter-button', '.ts-filter', '.rc-dropdown', '.searchable-select'],
  });

  useFilterModal({
    show: isFilterShown,
  });

  const onDeleteUser = async () => {
    if (isDeleting) return;

    try {
      setIsDeleting(true);
      await deleteUser(id);
      closeDeleteModal();
      reloadData();

      const { items: recruiters } = getRecruiters();

      dispatchToolkit(setRecruitersOptions(recruiters));
    } catch (error) {
      log(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const getData = async () => {
    setIsFetchingData(true);
    const { positions } = await getUserReferences(id);

    setUserPositions(positions || []);
    setIsFetchingData(false);
  };

  const parsedPositions = useMemo(() => {
    if (!showMorePositions) return slice(userPositions, 0, 5);

    return userPositions;
  }, [userPositions, showMorePositions]);

  useEffect(() => {
    setIsFilterShown(!!id);

    if (id) {
      getData();
    } else {
      setUserPositions([]);
    }
  }, [id]);

  return (
    <Filter show={isFilterShown}>
      <div className="flex items-center justify-between mb-5">
        <p className="text-lg font-bold mb-0 flex-grow truncate mr-4">{name}</p>
        <Button
          color="danger"
          outline
          disabled={isDeleting || isFetchingData}
          loading={isDeleting}
          className="card-header--filter-modal-button flex items-center !rounded-full !px-4"
          data-testid="user-filter-modal-delete"
          onClick={onDeleteUser}
        >
          {t('general.delete')}
        </Button>
      </div>
      <div className="mb-4 bg-gray-100 p-3 rounded-lg flex flex-col gap-y-2">
        <div className="flex items-center justify-between">
          <p className="mb-0 text-sm font-bold">
            {t('general.linked-positions')} {!isFetchingData && `(${userPositions.length})`}
          </p>
          {isFetchingData && (
            <div className="relative pr-4">
              <Loader type="primary" />
            </div>
          )}
          {!isFetchingData && userPositions.length > 5 && (
            <button
              type="button"
              className="btn-naked text-uppercase modal-action-button"
              style={{ fontSize: 11 }}
              onClick={() => setShowMorePositions(!showMorePositions)}
            >
              {showMorePositions ? t('general.show-less') : t('general.show-more')}
            </button>
          )}
        </div>
        {!!parsedPositions.length && (
          <ul className="mb-0 flex flex-col gap-y-1">
            {parsedPositions.map((item) => (
              <li key={item.id} className="text-sm">
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </Filter>
  );
};

export default UserDeleteModal;
