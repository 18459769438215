import React, { useState, useMemo } from 'react';
import { FormText } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import { NotificationManager } from 'react-notifications';
import { get, forEach } from 'lodash';
import Modal, { ModalHeader, ModalBody } from '../Modal';
import Form from '../../form/Form';
import { useAPI } from '../../../context/api';
import events from '../../../helpers/events';
import withFilters from '../../withFilters';
import {
  setCandidatesWithKey,
  setActivePosition,
} from '../../../store/slices/settings.slice';
import {
  useSelector as useSelectorToolkit,
  dispatch as dispatchToolkit,
} from '../../../store';

const Reject = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname, search, state } = useLocation();
  const queryParams = qs.parse(search);
  const [sending, setSending] = useState(false);
  const [bulkAPI, positionAPI, candidateAPI] = useAPI(
    'bulk',
    'position',
    'candidate'
  );
  const storeCandidates = useSelectorToolkit(
    ({ settings }) => settings.candidates
  );
  const filters = useSelectorToolkit(({ settings }) =>
    get(settings, 'filters.candidates')
  );
  const activePosition = useSelectorToolkit(({ settings }) =>
    get(settings, 'position')
  );
  const [values, setValues] = useState({ motivation: '' });

  const closeModal = () => {
    const queryParamsCopy = { ...queryParams };
    const stateCopy = { ...state };
    Reflect.deleteProperty(queryParamsCopy, 'modal');

    history.push({
      state: stateCopy,
      location: pathname,
      search: qs.stringify(queryParamsCopy),
    });
  };

  const alreadyRejected = useMemo(() => {
    if (get(state, 'data.candidates')) {
      const { candidates } = state.data;
      return candidates.filter(({ rejected }) => rejected === true);
    }
    return [];
  }, [state]);

  const getPipelineSteps = async (params) => {
    const filters = { ...params };

    let stringifyFilter = '';

    if (get(state, 'unreject') === true) {
      filters.pipelineStepId = null;
      filters.status = 'Active';
    }

    Object.keys(filters).forEach((key) => {
      if (stringifyFilter !== '') stringifyFilter += ',';
      stringifyFilter += `${key}==${filters[key]}`;
    });

    const pipelines = await positionAPI.getPipelineSteps(params.positionId, {
      filters: stringifyFilter,
    });

    dispatchToolkit(
      setActivePosition({
        ...activePosition,
        pipelines,
      })
    );
  };

  const onReject = async ({ motivation }) => {
    setValues({
      ...values,
      motivation,
    });
    if (sending) return;

    if (alreadyRejected.length) {
      forEach(alreadyRejected, (reject) => {
        if (reject.rejected === true) {
          const message = `${reject.name} ${t('candidate.already-rejected')}`;
          NotificationManager.warning(message);
        }
      });
    }

    try {
      setSending(true);

      const selectAll = storeCandidates.selectAll || false;

      const payload = {
        selectAll,
        reject: !get(state, 'unreject'),
        rejectMessage: motivation,
      };

      if (selectAll) {
        let payloadFilters = [];

        Object.keys(filters).forEach((key) => {
          if (filters[key]) {
            payloadFilters = [...payloadFilters, `${key}==${filters[key]}`];
          }
        });

        payload.filters = payloadFilters.join(',');

        if (get(storeCandidates, 'unchecked').length) {
          payload.unchecked = (get(storeCandidates, 'unchecked') || []).map(
            (item) => item.id
          );
        }
      } else {
        payload.candidates = (get(storeCandidates, 'selected') || []).map(
          (item) => item.id
        );
      }

      await bulkAPI.reject(payload);
      const { totalCount } = await candidateAPI.getList({
        filters: `positionId==${activePosition.id || activePosition.positionId},status==Rejected`,
      });

      dispatchToolkit(
        setCandidatesWithKey({
          key: 'rejected',
          payload: totalCount,
        })
      );
      getPipelineSteps(filters);

      events.$emit('refresh-candidates-reject');

      closeModal();
    } catch (error) {
      // log(error);
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal width={600}>
      <ModalHeader>
        {t(
          get(state, 'unreject')
            ? 'candidates.unreject'
            : 'candidate.reject-candidate'
        )}
      </ModalHeader>
      <ModalBody>
        {get(state, 'unreject') && (
          <p className="text-center mb-3">{t('general.are-you-sure')}</p>
        )}
        <Form
          className="anim-table-delayed"
          maxWidth="none"
          defaultValues={values}
          onSubmit={onReject}
          button={{
            parentClass: 'justify-content-center',
            label: t(
              get(state, 'unreject') ? 'general.continue' : 'candidate.reject'
            ),
            submitProps: {
              color: get(state, 'unreject') ? 'primary' : 'danger',
              style: { marginRight: 5 },
            },
            loading: sending,
            cancelCallback: () => {
              closeModal();
            },
            cancelLabel: t('candidate.cancel'),
            cancelProps: {
              color: 'light',
            },
          }}
          inputs={[
            {
              key: 'motivation',
              label: t('candidate.motivation'),
              type: 'textarea',
              footer: () => (
                <FormText color="muted">
                  {t('candidate.email-not-automatic')}
                </FormText>
              ),
              hide: get(state, 'unreject'),
            },
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

export default withFilters(Reject);
