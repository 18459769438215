import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  auth as authRequest,
  refresh as refreshTokenRequest,
} from '../api/auth.api';
import {
  setDataInLocalStorage,
  removeDataFromLocalStorage,
} from '../../helpers/auth';
import withError from '../../helpers/with-thunk-error';

export const refreshToken = createAsyncThunk('auth/refreshToken', async () => {
  const data = await refreshTokenRequest();
  setDataInLocalStorage(data);
  return data;
});

export const logout = createAsyncThunk(
  'auth/logout',
  async (arg, { dispatch }) => {
    removeDataFromLocalStorage();

    await dispatch({ type: 'RESET_APP' });
  }
);

interface AuthRequest {
  username: string;
  password: string;
}

export const authorize = createAsyncThunk(
  'auth/authorize',
  withError(async ({ username, password }: AuthRequest) => {
    const data = await authRequest(username, password);

    // TODO: put into persist
    setDataInLocalStorage(data);
    // ------

    return data;
  })
);
