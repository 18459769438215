import { Formik } from 'formik';
import { sortBy, uniqueId } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { FormGroup, Label } from 'reactstrap';
import * as Yup from 'yup';
import Field from '../../../../components/@v2/Form/Field';
import Button from '../../../../components/Button';
import DraggableList from '../../../../components/DraggableList';
import { ListItem } from '../../../../components/form/Form';
import Layout from '../../../../components/layouts/default';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import { addSortOrder, capitalizeFirstLetter } from '../../../../helpers/utils';
import {
  createOrUpdatePipelineTemplate,
  getAllPipelineSteps,
  getPipelineTemplate,
} from '../../../../store/api/pipelines.api';

export const PipelineStepFormSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().nullable().required(t('form-validator.required')),
  });
};

const PipelineTemplateForm = () => {
  const { type } = useParams();
  const { t } = useTranslation();
  const { data, loading } = useApiRequestContext();
  const history = useHistory();

  const onSubmit = async (values) => {
    const finalData = {
      id: values?.id,
      name: values?.name,
      archived: values?.archived,
      pipelineSteps: addSortOrder(values?.connections)?.map((item) => ({
        ...item,
        id: item?.value,
        sortOrder: item?.sortOrder,
      })),
    };
    await createOrUpdatePipelineTemplate(finalData);
    history.push('/settings/pipeline');
  };

  if (loading) {
    return null;
  }

  return (
    <Layout title={t(`${capitalizeFirstLetter(type)} Pipeline Template`)} pretitle={t('general.settings')}>
      <div className="user-profile-form anim-table-delayed">
        <Formik initialValues={data} onSubmit={onSubmit} validationSchema={PipelineStepFormSchema(t)}>
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
            const onSelectPipelineStep = () => {
              if (!values?.pipelineStep) return;

              setFieldValue('connections', [
                ...values?.connections,
                {
                  ...values?.pipelineStep,
                  label: values?.pipelineStep?.name,
                  value: values?.pipelineStep?.id,
                  uuid: uniqueId(),
                },
              ]);

              setFieldValue('pipelineStep', null);
            };

            const onDrag = (newOrderList) => {
              setFieldValue('connections', newOrderList);
            };

            return (
              <form
                onSubmit={handleSubmit}
                className="anim-table-delayed max-w-2xl w-full shadow-sm p-4 bg-white rounded-lg"
              >
                <Field required name="name" label={t('general.name')} />
                <FormGroup>
                  <Label className="block">{t('general.pipeline-steps')}</Label>
                  <div className="flex items-start gap-x-4">
                    <Field
                      name="pipelineStep"
                      type="select"
                      className="mb-0"
                      api={getAllPipelineSteps}
                      objectFilters={{
                        filters: {
                          positionId: null,
                        },
                      }}
                      searchKey="name"
                      keys={['id', 'name']}
                    />
                    <Button
                      type="button"
                      color="primary"
                      className="whitespace-nowrap"
                      onClick={onSelectPipelineStep}
                      data-testid="add-step"
                    >
                      {t('general.add-step')}
                    </Button>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label className="block">{t('settings.pipeline.chosen-steps')}</Label>
                  <DraggableList deletable items={values?.connections} callback={onDrag} component={ListItem} />
                </FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  data-testid="form-submit"
                >
                  {t('general.submit')}
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

const PipelineTemplateFormWrapper = () => {
  const { id, type } = useParams();

  const DEFAULT_PIPELINE_TEMPLATE_FORM = {
    name: '',
    pipelineStep: null,
    archived: false,
    connections: [],
  };

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source) => {
        if (type === 'edit' && id) {
          try {
            const response = await getPipelineTemplate(id, {
              cancelToken: source.token,
            });

            return {
              ...DEFAULT_PIPELINE_TEMPLATE_FORM,
              ...response,
              connections: sortBy(
                response?.pipelineSteps?.map((item) => {
                  return {
                    ...item,
                    value: item?.id,
                    label: item?.name,
                    uuid: uniqueId(),
                  };
                }),
                'sortOrder'
              ),
            };
          } catch (error) {
            return DEFAULT_PIPELINE_TEMPLATE_FORM;
          }
        }

        return DEFAULT_PIPELINE_TEMPLATE_FORM;
      }}
    >
      <PipelineTemplateForm />
    </ApiRequestProvider>
  );
};

export default PipelineTemplateFormWrapper;
