import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import { get } from 'lodash';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { useLocation, useParams } from 'react-router-dom';
import { useAPI } from '../../context/api';
import { setFiltersWithKey, setActivePosition, setCandidatesWithKey } from '../../store/slices/settings.slice';
import { log } from '../../helpers/utils';
import { selectUser } from '../../store/selectors/user.selector';
import { getLinkedPositions } from '../../store/api/candidates.api';
import { getPosition, getPipelineSteps } from '../../store/api/positions.api';
import { useApiRequestContext } from '../../context/@v2/ApiRequestContext';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../store';

const LinkedPositions = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { id: activeIdInUrl } = useParams();
  const { meta, setMeta } = useApiRequestContext();
  const { state } = location;
  const [candidateAPI] = useAPI('candidate');
  const [linkedPositions, setLinkedPositions] = useState([]);
  const [isFetchingId, setIsFetchingId] = useState(null);
  const [hasFoundCandidate, setHasFoundCandidate] = useState(true);
  const currentUser = useSelectorToolkit(selectUser);
  const activePosition = useSelectorToolkit(({ settings }) => get(settings, 'position'));
  const candidatesSettings = useSelectorToolkit(({ settings }) => settings.candidates);

  const selectedCandidate = {
    ...candidatesSettings?.selected?.[0],
  };

  const currentPositionId = useMemo(() => {
    if (get(state, 'filters') && get(state.filters, 'positionId')) {
      return state.filters.positionId;
    }
    return null;
  }, [state]);

  const canSeePositionCandidates = useMemo(() => {
    if (currentPositionId && activePosition) {
      const { recruiter, teamMembers } = activePosition;
      const { userId, permissionLevel } = currentUser;
      if (permissionLevel > 0) return true;
      return !!(userId === get(recruiter, 'id') || (teamMembers || []).includes(userId));
    }

    return true;
  }, [currentPositionId, currentUser, activePosition]);

  // if (!linkedPositions.length) return null;

  useEffect(() => {
    dispatchToolkit(
      setCandidatesWithKey({
        key: 'previousCandidate',
        payload: null,
      })
    );
  }, []);

  const getCandidateLinkedPositions = async (activeCandidateId) => {
    if (!activeCandidateId) return;
    try {
      const response = await getLinkedPositions(activeCandidateId);
      if (response) {
        setLinkedPositions(response);
      }
    } catch (error) {
      // do nothing
    }
  };

  useEffect(() => {
    if (selectedCandidate?.id || activeIdInUrl) {
      getCandidateLinkedPositions(selectedCandidate?.id || activeIdInUrl);
    }
  }, [activeIdInUrl, selectedCandidate?.id]);

  return (
    !!linkedPositions.length && (
      <div className="d-flex align-items-start justify-content-start flex-column mb-5">
        <p style={{ fontSize: 14 }}>{t('general.has-also-applied-for-position')}</p>
        <div className="d-flex flex-wrap align-items-center justify-content">
          {linkedPositions.map(
            (item, index) =>
              currentPositionId !== item.positionId && (
                <div
                  className="my-2"
                  key={`${item.position}-${item.positionId}`}
                  data-testid={`candidates-linked-position-${index}`}
                  onClick={async () => {
                    if (isFetchingId || canSeePositionCandidates === false) {
                      return;
                    }

                    setIsFetchingId(item.positionId);
                    try {
                      const position = await getPosition(item.positionId);

                      if (position) {
                        const requests = [
                          candidateAPI.getList({
                            filters: `positionId==${position.positionId}`,
                          }),
                        ];

                        if (item.positionId) {
                          requests.push(
                            getPipelineSteps(item.positionId, {
                              filters: `positionId==${item.positionId}`,
                            })
                          );
                        }

                        const [data, pipelines] = await axios.all(requests);

                        if (data && data?.candidates) {
                          const foundCandidate = data?.candidates
                            ? data?.candidates.find((item) => item.name === selectedCandidate.name)
                            : null;
                          setHasFoundCandidate(!!foundCandidate);

                          dispatchToolkit(
                            setFiltersWithKey({
                              key: 'candidates',
                              payload: {
                                positionId: foundCandidate?.positionId || null,
                                pipelineStepId: foundCandidate?.pipelineStepId || null,
                              },
                            })
                          );

                          if (pipelines) {
                            dispatchToolkit(
                              setActivePosition({
                                ...position,
                                pipelines,
                                activePipelineStepId: foundCandidate?.pipelineStepId || null,
                              })
                            );

                            dispatchToolkit(
                              setCandidatesWithKey({
                                key: 'previousCandidate',
                                payload: foundCandidate,
                              })
                            );

                            setMeta({
                              ...meta,
                              filters: [
                                {
                                  key: 'positionId',
                                  value: foundCandidate?.positionId,
                                },
                              ],
                            });
                          }
                        }
                      }
                    } catch (error) {
                      log(error);
                    } finally {
                      setIsFetchingId(null);
                    }
                  }}
                >
                  <p
                    id={`position-linked--${item.positionId}`}
                    className="linked-positions"
                    style={{
                      cursor: canSeePositionCandidates && hasFoundCandidate ? 'pointer' : 'default',
                    }}
                  >
                    <span className="linked-positions-title">{item.position}</span>
                    <Badge color="secondary" className="flex items-center badge-pill m-1 p-2 rounded">
                      {item.applyCount}
                    </Badge>
                  </p>
                  <UncontrolledTooltip
                    placement="top"
                    target={`position-linked--${item.positionId}`}
                    style={{ textAlign: 'left', padding: 10 }}
                  >
                    <div>
                      <span className="font-weight-bold">{`${t('general.recruiter')}: `}</span>
                      {item.recruiter}
                    </div>
                    <div>
                      <span className="font-weight-bold">{`${t('general.location')}: `}</span>
                      {item.location}
                    </div>
                    <div>
                      <span className="font-weight-bold">{`${t('general.latest-apply-date')}: `}</span>
                      {moment(item.latestAppliedDate).format('YYYY-MM-DD HH:mm')}
                    </div>
                  </UncontrolledTooltip>
                </div>
              )
          )}
        </div>
      </div>
    )
  );
};

export default LinkedPositions;
