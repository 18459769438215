import Tippy from '@tippyjs/react';
import { hideAll } from 'tippy.js';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import CircularLoader from '../../Loader/CircularLoader';

const DeleteModelTooltip = ({
  content,
  onDelete,
  children,
  singleton,
  deleteButtonLabel,
  placement = 'bottom-end',
  theme = 'light',
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation();

  return (
    <Tippy
      maxWidth={200}
      interactive
      content={
        <div className="!p-2">
          <p className="mb-0 text-base">{t('general.are-you-sure')}</p>
          <div className="!text-secondary">{content}</div>
          <div className="d-flex align-items-center justify-content-center">
            <Button
              color="light"
              outline
              data-testid="cancel"
              className="!h-7 !flex items-center !px-4 !rounded-full"
              onClick={() => {
                hideAll();
              }}
            >
              {t('general.cancel')}
            </Button>
            <Button
              color="danger"
              outline
              className={classNames('!h-7 !rounded-full !flex items-center', isDeleting ? '!pl-4 !pr-2' : '!px-4')}
              data-testid="delete-restore-button"
              disabled={isDeleting}
              onClick={async () => {
                setIsDeleting(true);
                await onDelete();
                setIsDeleting(false);
              }}
            >
              {deleteButtonLabel || t('general.delete')}
              {isDeleting && <CircularLoader color="var(--error-color)" size={24} />}
            </Button>
          </div>
        </div>
      }
      placement={placement}
      theme={theme}
      singleton={singleton}
    >
      {children}
    </Tippy>
  );
};

export default DeleteModelTooltip;
