import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { NotificationManager } from 'react-notifications';
import dispatchCustomEvent from '../helpers/dispatch-custom-event';
import Icon from './@v2/Icon';
import '../assets/sass/tools.sass';

const FileComponent = ({
  onChange,
  label,
  icon,
  accept,
  downloadableLink,
  copyLink,
  testId,
}) => {
  const { t } = useTranslation();
  const [fileNames, setFileNames] = useState(null);

  return (
    <div
      data-testid={testId}
      className="file-container flex flex-col items-center"
    >
      <div className="file-box flex items-center justify-center">
        {fileNames && <span className="text-center">{fileNames}</span>}
        {!fileNames && icon && <Icon name={icon} />}
        {onChange && (
          <input
            accept={accept}
            data-testid={`${testId}-input`}
            type="file"
            onChange={(e) => {
              const { files } = e.target;
              if (files.length) {
                const names = Array.from(files).reduce((accumulator, file) => {
                  if (
                    accept === '.woff' &&
                    file.type !== 'application/font-woff'
                  ) {
                    return '';
                  }
                  if (accumulator !== '') return `${accumulator}, ${file.name}`;
                  return file.name;
                }, '');
                onChange(files);
                setFileNames(names);
              }
            }}
          />
        )}
      </div>
      {label && <h5 style={{ marginBottom: 3 }}>{label}</h5>}
      {!!downloadableLink && (
        <a
          href={downloadableLink}
          rel="noopener noreferrer"
          target="_blank"
          data-testid={`${testId}-downloadable-link`}
          style={{
            color: '#0f75f8',
            fontSize: 13,
          }}
        >
          <Icon name="file" style={{ marginRight: 3 }} />
          {t('general.download')}
        </a>
      )}
      {!!copyLink && (
        <CopyToClipboard
          text={copyLink}
          data-testid={`${testId}-copy-to-clipboard`}
          onCopy={(text) => {
            dispatchCustomEvent('clipboard-copy', { text });
            NotificationManager.success(t('general.copied-successfully'));
          }}
        >
          <button
            type="button"
            data-testid={`${testId}-button`}
            style={{
              color: '#0f75f8 !important',
              fontSize: 13,
              background: 'none',
              border: 'none',
            }}
          >
            <Icon name="copy" style={{ marginRight: 3 }} />
            {t('general.copy-link')}
          </button>
        </CopyToClipboard>
      )}
    </div>
  );
};

FileComponent.defaultProps = {
  onChange: null,
  icon: 'plus',
  label: null,
  accept: '.woff',
  downloadableLink: null,
  copyLink: null,
};

export default FileComponent;
