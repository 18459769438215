import EmailTemplateItem from '@ts/apicontracts/emailTemplates/list/response-list-item';
import CreateEmailTemplateCommand from '@ts/apicontracts/emailTemplates/create-email-template-command';
import UpdateEmailTemplateCommand from '@ts/apicontracts/emailTemplates/update-email-template-command';
import { AxiosResponseDirect, api } from '../../helpers/api-v2';

const resource = '/email-templates';

export const getAllEmailTemplates = async (options = {}) => {
  const template = await api.post(`${resource}/list`, options);
  return template as unknown as AxiosResponseDirect<EmailTemplateItem[]>;
};

export const createEmailTemplate = async (form: CreateEmailTemplateCommand) => {
  const template = await api.post(resource, form);
  return template;
};

export const updateEmailTemplate = async (form: UpdateEmailTemplateCommand) => {
  const template = await api.put(resource, form);
  return template;
};

export const deleteEmailTemplate = async (id: string) => {
  const template = await api.delete(`${resource}/${id}`);
  return template;
};
