import Tippy, { useSingleton } from '@tippyjs/react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CustomInput, Input } from 'reactstrap';
import { hideAll } from 'tippy.js';
import Card from '../../../../components/@v2/Card';
import Paginator from '../../../../components/@v2/Paginator/Paginator';
import Search from '../../../../components/@v2/Search/Search';
import Table from '../../../../components/@v2/Table/Table';
import Button from '../../../../components/Button';
import Icon from '../../../../components/@v2/Icon';
import RadioButton from '../../../../components/input/RadioButton';
import RangeSlider from '../../../../components/input/RangeSlider';
import Layout from '../../../../components/layouts/default';
import ScreeningQuestionsType from '../../../../constants/ScreeningQuestionsType';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import { getScreeningTypeById } from '../../../../helpers/screening-criteria';
import { log } from '../../../../helpers/utils';
import {
  archiveScreeningQuestion,
  getAllScreeningQuestions,
  getTypes,
  restoreScreeningQuestion,
} from '../../../../store/api/screening-criteria.api';
import ScreeningQuestionsFilter from './ScreeningQuestionsFilter';
import DeleteModelTooltip from '../../../../components/@v2/DeleteModelTooltip/DeleteModelTooltip';
import Checkbox from '../../../../components/Checkbox';

const ScreeningQuestions = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, data, meta, setMeta, pageSize, reloadData } = useApiRequestContext();
  const [deletingIds, setDeletingIds] = useState([]);
  const [source, target] = useSingleton();

  const onDelete = async (id, isDeleted) => {
    if (deletingIds.includes(id)) {
      return;
    }

    try {
      setDeletingIds([...deletingIds, id]);
      if (isDeleted) {
        await restoreScreeningQuestion(id);
      } else {
        await archiveScreeningQuestion(id);
      }

      hideAll();
      setDeletingIds(deletingIds.filter((itemId) => itemId !== id));
      setTimeout(() => {
        reloadData();
      }, 750);
    } catch (error) {
      log(error);
    }
  };

  return (
    <Layout
      pretitle={t('general.settings')}
      title={t('general.screening-questions')}
      rightContent={
        <Button
          className="btn-rounded"
          color="primary"
          data-testid="settings-screening-questions-add-button"
          onClick={() => {
            history.push({
              pathname: '/settings/screening-questions/add',
            });
          }}
        >
          <div className="flex items-center">
            <Icon name="plus" />
            {t('settings.screening-question.add-question')}
          </div>
        </Button>
      }
    >
      <Card className="anim-table-delayed shadow-table !border !border-line-default">
        <Tippy
          trigger="click mouseenter"
          singleton={source}
          theme="light"
          interactive
          maxWidth={200}
          appendTo={document.body}
          placement="bottom"
        />
        <Search
          placeholder={t('general.search-using', { resource: 'name' })}
          resource="name"
          meta={meta}
          setMeta={setMeta}
          filter={<ScreeningQuestionsFilter />}
        />
        <Table
          isLoaded={!loading}
          activeSort={meta?.sorts || ''}
          items={data?.items || []}
          headers={[
            {
              label: t('settings.screening-question.name'),
              key: 'name',
            },
            {
              label: t('settings.screening-question.type'),
              key: 'type',
            },
            {
              label: t('general.actions'),
              key: 'actions',
              sticky: true,
              sort: false,
              width: 100,
              headerClassName: 'text-center',
            },
          ]}
          pageSize={pageSize}
          renderBody={(item, index) => {
            let type = '-';

            if (item?.screeningCriteriaTypeId) {
              type = getScreeningTypeById(item?.screeningCriteriaTypeId, data?.types);
            }

            let element = null;

            if (type !== '-') {
              if (type === ScreeningQuestionsType.CHECKBOX_TYPE) {
                element = [
                  {
                    value: 'yes',
                    label: t('general.yes'),
                  },
                  {
                    value: 'no',
                    label: t('general.no'),
                  },
                ].map((selection, index) => (
                  <RadioButton
                    isSelected={false}
                    key={selection.value}
                    value={selection.value}
                    label={selection.label}
                    name={`screening-questions--${item.screeningCriteriaId}`}
                    data-testid={`screening-questions-preview-radio-${index}`}
                    onChange={() => {}}
                  />
                ));
              }

              if (type === ScreeningQuestionsType.YEARS_TYPE || type === ScreeningQuestionsType.SLIDER_TYPE) {
                element = (
                  <div>
                    <RangeSlider
                      min={item?.sliderMin || 0}
                      max={item?.sliderMax || 10}
                      className="mb-0"
                      data-testid="screening-questions-preview-range-slider"
                    />
                    <div className="flex items-center justify-between !pl-3 !pr-4">
                      <p className="mb-0">{item?.sliderMin}</p>
                      <p className="mb-0">{item?.sliderMax}</p>
                    </div>
                  </div>
                );
              }

              if (type === ScreeningQuestionsType.SELECTIONS_TYPE) {
                if (!item?.selections?.length) {
                  element = <p className="mb-0 text-gray-400">{t('general.no-options')}</p>;
                } else {
                  element = item?.selections?.map((selection, index) => (
                    <RadioButton
                      isSelected={false}
                      key={selection?.screeningCriteriaId || selection?.id}
                      value={selection?.screeningCriteriaId || selection?.id}
                      label={selection?.name || `Option ${index + 1}`}
                      name={`screening-questions--${item?.screeningCriteriaId || item?.id}`}
                      onChange={() => {}}
                      data-testid={`screening-questions-preview-radio-${index}`}
                    />
                  ));
                }
              }

              if (type === ScreeningQuestionsType.TEXT_TYPE) {
                element = (
                  <Input
                    type="textarea"
                    name="text"
                    id="text"
                    style={{ height: 80 }}
                    placeholder={t('edit-position.insert-text')}
                    className="mt-3"
                    data-testid="screening-questions-preview-input"
                  />
                );
              }

              if (type === ScreeningQuestionsType.VIDEO_TYPE) {
                element = (
                  <CustomInput
                    type="file"
                    name="video"
                    id="video"
                    className="mt-3"
                    accept="video/mp4"
                    data-testid="screening-questions-preview-video"
                    disabled
                  />
                );
              }

              if (type === ScreeningQuestionsType.MULTI_SELECT) {
                if (!item?.multiselection?.length) {
                  element = <p className="mb-0 text-gray-400">{t('general.no-options')}</p>;
                } else {
                  element = item?.multiselection?.map((selection, index) => (
                    <Checkbox
                      isSelected={selection.selected}
                      key={selection.screeningCriteriaId || selection?.id}
                      value={selection.screeningCriteriaId || selection?.id}
                      label={selection.name || `Option ${index + 1}`}
                      name={`screening-questions--${item?.screeningCriteriaId || item?.id}`}
                      onChange={() => {}}
                      data-testid={`screening-questions-preview-multi-select-${index}`}
                    />
                  ));
                }
              }
            }

            return (
              <tr key={item?.screeningCriteriaId || item?.id} id={`screening-question-table-row--${index}`}>
                <td>{item?.name}</td>
                <td>{type !== '-' ? t(`screening-types.${type}`) : type}</td>
                <td className="column-sticky">
                  <div className="flex items-center justify-center gap-x-2">
                    <Tippy
                      theme="light"
                      interactive
                      placement="right"
                      appendTo={document.body}
                      content={
                        <div>
                          <p className="mb-0 font-bold">{item?.name}</p>
                          {element}
                        </div>
                      }
                    >
                      <button type="button">
                        <Icon name="eye" />
                      </button>
                    </Tippy>
                    <DeleteModelTooltip
                      onDelete={async () => {
                        hideAll();
                        await onDelete(item?.screeningCriteriaId || item?.id, item?.deleted);
                      }}
                      content={
                        <p>
                          {t(
                            item?.deleted ? 'settings.restore-screening-question' : 'settings.delete-screening-question'
                          )}
                        </p>
                      }
                      deleteButtonLabel={t(!item?.deleted ? 'general.delete' : 'general.restore')}
                      singleton={target}
                    >
                      <button type="button" data-testid="delete-sq">
                        <Icon name={item?.deleted ? 'archive-restore' : 'trash'} />
                      </button>
                    </DeleteModelTooltip>
                  </div>
                </td>
              </tr>
            );
          }}
          setMeta={setMeta}
        />
        <div className="h-14 flex items-center bg-white">
          <Paginator
            pageCount={data?.totalCount / meta?.pageSize}
            initialPage={meta?.page - 1}
            onPageChange={(value) => {
              if (meta?.page !== value) {
                setMeta({
                  page: value,
                });
              }
            }}
            disableInitialCallback
          />
        </div>
      </Card>
    </Layout>
  );
};

const ScreeningQuestionsWrapper = () => {
  const typeRef = useRef([]);

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source, ...rest) => {
        let types = typeRef.current;
        const newFilters = {};

        const filters = rest?.[2] || [];

        filters.forEach((item) => {
          newFilters[item.key] = item.value;
        });

        const sort = {
          by: meta?.sorts?.replace('-', ''),
          direction: meta?.sorts?.includes('-') ? 'desc' : 'asc',
        };
        const response = await getAllScreeningQuestions(
          {
            filter: newFilters,
            page: {
              number: meta?.page?.number || meta?.page || 1,
              size: meta?.page?.size || meta?.pageSize || 20,
            },
            sort: meta.sorts ? sort : {},
          },
          {
            cancelToken: source.token,
          }
        );

        if (!typeRef?.current?.length) {
          types = await getTypes();

          typeRef.current = types;
        }

        return {
          ...response,
          types,
        };
      }}
      defaultFilters={[
        {
          key: 'deleted',
          value: 'false',
        },
      ]}
    >
      <ScreeningQuestions />
    </ApiRequestProvider>
  );
};

export default ScreeningQuestionsWrapper;
