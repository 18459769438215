import PropTypes from 'prop-types';
import React from 'react';
import RCDropdown from 'rc-dropdown';
import Card from '../Card';
import 'rc-dropdown/assets/index.css';
import './Dropdown.sass';

const DropdownMenu = ({
  // dark = false,
  id,
  placement,
  children,
  renderBody,
  resource,
  buttonProps,
  ...rest
}) => {
  const menu = (
    <Card className="p-0 bg-white" containerClassName="mx-0">
      {!!renderBody && renderBody()}
    </Card>
  );

  return (
    <RCDropdown
      trigger={['click']}
      overlay={menu}
      placement={placement}
      overlayClassName="ts-dropdown-overlay"
      {...rest}
    >
      <button
        type="button"
        id={id}
        data-testid={`${resource}-button`}
        {...buttonProps}
      >
        {children}
      </button>
    </RCDropdown>
  );
};

DropdownMenu.propTypes = {
  id: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.element.isRequired,
  renderBody: PropTypes.func.isRequired,
  resource: PropTypes.string,
};

DropdownMenu.defaultProps = {
  id: null,
  placement: 'bottomLeft',
  resource: 'general',
};

export default DropdownMenu;
