import { get } from 'lodash';
import ScreeningQuestionsType from './ScreeningQuestionsType';

const showAllOption = (t) => ({
  label: t('general.show-all'),
  value: null,
});

const filterOptions = (t) => {
  return [
    showAllOption(t),
    {
      label: t('candidates.filter.only-those-that-answered-yes'),
      value: 'true',
    },
    {
      label: t('candidates.filter.only-those-that-answered-no'),
      value: 'false',
    },
  ];
};

const videoOptions = (t) => {
  return [
    showAllOption(t),
    {
      label: t('candidates.table-header-screening-questions.video-sent'),
      value: 'true',
    },
    {
      label: t('candidates.table-header-screening-questions.video-not-sent'),
      value: 'false',
    },
  ];
};

const textOptions = (t) => {
  return [
    showAllOption(t),
    {
      label: t('candidates.filter.only-those-who-replied'),
      value: 'true',
    },
    {
      label: t('candidates.filter.only-those-who-did-not-reply'),
      value: 'false',
    },
  ];
};

export const createYears = ({ min, max }, t) => {
  const isMinZero = min === 0;
  const defaultMin = isMinZero ? 1 : min;
  const emptyArr = new Array(max - (min - (isMinZero ? 0 : 1))).fill(undefined);

  return emptyArr.map((item, index) => ({
    label: t('general.atleast-x-years', { x: index + defaultMin }),
    value: index + defaultMin,
  }));
};

export const createQuestions = (screeningQuestion, t) => {
  const screeningQuestionType = get(screeningQuestion, 'type', '').toLowerCase();

  switch (screeningQuestionType) {
    case ScreeningQuestionsType.YEARS_TYPE: {
      return [
        showAllOption(t),
        ...createYears(
          {
            min: screeningQuestion.sliderMin,
            max: screeningQuestion.sliderMax,
          },
          t
        ),
      ];
    }

    case ScreeningQuestionsType.SLIDER_TYPE: {
      return [
        showAllOption(t),
        ...createYears(
          {
            min: screeningQuestion.sliderMin,
            max: screeningQuestion.sliderMax,
          },
          t
        ),
      ];
    }

    case ScreeningQuestionsType.TEXT_TYPE: {
      return textOptions(t);
    }

    case ScreeningQuestionsType.SELECTIONS_TYPE: {
      return [
        showAllOption(t),
        ...screeningQuestion.selections.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      ];
    }

    case ScreeningQuestionsType.VIDEO_TYPE: {
      return videoOptions(t);
    }

    case ScreeningQuestionsType.MULTI_SELECT: {
      return [
        showAllOption(t),
        ...screeningQuestion.selections.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      ];
    }

    default: {
      return filterOptions(t);
    }
  }
};
