import React, { useMemo, useRef } from 'react';
import ReactSelect, { components } from 'react-windowed-select';
import AsyncSelect from 'react-select/async';
import ReactTooltip from 'react-tooltip';
import debounce from 'debounce-promise';
import classnames from 'classnames';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import uuidv4 from 'uuid/v4';
import PropTypes from 'prop-types';
import '../../assets/sass/custom-select.sass';

const SelectContainer = ({ children, ...props }) => {
  const { selectProps } = props;
  const tooltipValue = selectProps && selectProps.tooltip && selectProps.value ? selectProps.value.label : null;
  return (
    <div data-tip={tooltipValue}>
      <components.SelectContainer {...props}>{children}</components.SelectContainer>
      {tooltipValue && tooltipValue.length > 15 && <ReactTooltip effect="float" place="right" />}
    </div>
  );
};

const Select = ({ async, callback, keys, type, resource, ...rest }) => {
  const { t } = useTranslation();
  const loadOptions = debounce(async (value, localCallback) => {
    const response = await callback(value);
    const mappedItems = (get(response, 'items') || get(response, 'candidates')).map((item) => {
      item.value = item[keys[0]];
      item.label = item[keys[1]];
      return item;
    });
    if (rest.onUpdateOptions) {
      rest.onUpdateOptions(mappedItems);
    }
    localCallback(mappedItems);
  }, 1000);
  const defaultValue = useMemo(() => rest.value || {}, [rest]);
  const selectClientMenu = useRef(null);
  const renderSelect = () => {
    const customComponents = { SelectContainer };
    const customStyles = {
      menuPortal: (styles) => ({
        ...styles,
        zIndex: 90000,
      }),
    };

    if (async) {
      return (
        <AsyncSelect
          classNamePrefix="custom-react-select"
          loadOptions={loadOptions}
          isOptionDisabled={(option) => option.disabled}
          menuPlacement="auto"
          inputId={uuidv4()}
          menuPortalTarget={document.body}
          styles={customStyles}
          {...rest}
        />
      );
    }

    return (
      <ReactSelect
        classNamePrefix="custom-react-select"
        components={customComponents}
        isOptionDisabled={(option) => option.disabled}
        menuPlacement="auto"
        inputId={uuidv4()}
        ref={selectClientMenu}
        menuPortalTarget={document.body}
        styles={customStyles}
        {...rest}
      />
    );
  };

  const getElement = () => {
    if (type === 'normal-select') {
      return (
        <select
          className={classnames('custom-react-normal-select', rest.className)}
          value={defaultValue.value || ''}
          onChange={(e) => {
            if (rest.onChange) {
              const { value } = e.target;
              rest.onChange(rest.options.find((option) => `${option.value}` === value));
            }
          }}
          data-testid="select-component"
        >
          <option value="" disabled>
            {t(rest.defaultOptionText || 'Select Value')}
          </option>
          {rest.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
    }

    return <div data-testid={`${resource}-select`}>{renderSelect()}</div>;
  };

  return getElement();
};

Select.propTypes = {
  async: PropTypes.bool,
  callback: PropTypes.func,
  keys: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  resource: PropTypes.string,
};

Select.defaultProps = {
  async: false,
  keys: [],
  callback: null,
  type: '',
  isDropDownSearch: false,
  resource: 'general',
};
export default Select;
