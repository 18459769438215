import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isNaN } from 'lodash';
import Icon from '../Icon';
import { dispatch, useSelector } from '../../../store';
import { setTablePageSize } from '../../../store/slices/settings.slice';
import DropdownMenu from '../Dropdown/DropdownMenu';
import './Paginator.sass';

const Paginator = ({
  pageCount = 0,
  pageRangeDisplayed,
  marginPagesDisplayed,
  initialPage = 1,
  onPageChange,
  disableInitialCallback,
  loading,
  withPageSize = true,
}) => {
  const localPageCount = isNaN(pageCount) ? 0 : pageCount;
  const pageSize = useSelector(({ settings }) => settings?.table?.pageSize);
  const onLeftDisabled = initialPage <= 0;
  const onRightDisabled = localPageCount === 0 || initialPage + 1 === Math.ceil(localPageCount);

  return (
    <div className="flex items-center justify-between px-2 w-full">
      <div className="spacer" />
      <div className="left-slot flex items-center gap-x-2 overflow-hidden">
        {localPageCount !== 0 && (
          <button
            type="button"
            className={classNames(
              'flex items-center gap-x-2 text-xs',
              onLeftDisabled ? 'text-[#dadee2]' : '!text-secondary'
            )}
            disabled={onLeftDisabled}
            onClick={() => {
              onPageChange(initialPage <= 1 ? 1 : initialPage);
            }}
          >
            <Icon
              name="arrow-left"
              style={{
                fontSize: 12,
              }}
            />
            Prev
          </button>
        )}
        <div className="flex-1 overflow-hidden">
          <ReactPaginate
            breakLabel="..."
            previousLabel=""
            nextLabel=""
            disabledClassName="disabled"
            containerClassName="ts-paginator"
            activeLinkClassName="ts-paginator-item-active"
            pageCount={localPageCount}
            pageRangeDisplayed={pageRangeDisplayed || 5}
            marginPagesDisplayed={marginPagesDisplayed}
            initialPage={initialPage}
            onPageChange={({ selected }) => {
              return !loading ? onPageChange(selected + 1) : null;
            }}
            disableInitialCallback={disableInitialCallback}
            forcePage={initialPage}
          />
        </div>

        {localPageCount !== 0 && (
          <button
            type="button"
            className={classNames(
              'flex items-center gap-x-2 text-xs',
              onRightDisabled ? 'text-[#dadee2]' : '!text-secondary'
            )}
            disabled={onRightDisabled}
            onClick={() => {
              onPageChange(initialPage + 2);
            }}
          >
            Next
            <Icon
              name="arrow-right"
              style={{
                fontSize: 12,
              }}
            />
          </button>
        )}
      </div>
      <div className="right-slot flex items-center gap-x-4">
        {withPageSize && (
          <DropdownMenu
            onSelect={(item) => {
              const selected = parseInt(item, 10);
              dispatch(setTablePageSize(selected));
            }}
            placement="bottomRight"
            menuItems={[
              { label: '10', value: 10 },
              { label: '20', value: 20 },
              { label: '50', value: 50 },
              { label: '100', value: 100 },
            ]}
            buttonProps={{
              'data-testid': 'table-per-page-dropdown',
              className:
                'transition text-gray-400 border !py-1.5 !px-2.5 rounded-md hover:bg-gray-100 hover:text-black',
            }}
          >
            <div className="flex items-center">
              <span className="text-xs mr-1 whitespace-nowrap">{pageSize} per page</span>
              <Icon name="chevron-down" className="ml-2" fontSize={14} />
            </div>
          </DropdownMenu>
        )}
      </div>
    </div>
  );
};

Paginator.propTypes = {
  pageCount: PropTypes.number.isRequired,
  pageRangeDisplayed: PropTypes.number,
  marginPagesDisplayed: PropTypes.number,
  initialPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  disableInitialCallback: PropTypes.bool,
  loading: PropTypes.bool,
};

Paginator.defaultProps = {
  pageRangeDisplayed: 8,
  marginPagesDisplayed: 2,
  initialPage: 0,
  disableInitialCallback: false,
  loading: false,
};

export default Paginator;
