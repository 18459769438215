import Axios from 'axios';
import classNames from 'classnames';
import { isNumber } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Input } from 'reactstrap';
import Card from '../../../../components/@v2/Card';
import Paginator from '../../../../components/@v2/Paginator/Paginator';
import Search from '../../../../components/@v2/Search/Search';
import Table from '../../../../components/@v2/Table/Table';
import Button from '../../../../components/Button';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import { useAPI } from '../../../../context/api';
import { addProductCustomFields, updateProductCustomFields, deleteProductCustomFields } from '../../../../store/api/products.api';

const FACEBOOK = 200;
const TYPES = {
  PRICE: 'Price',
  LIFETIME_BUDGET: 'LifetimeBudget',
};

const ClientCustomizeProducts = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { loading, data, meta, setMeta, reloadData } = useApiRequestContext();
  const [updatedItems, setUpdatedItems] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const addOrUpdateModifiedItem = (values) => {
    let edited = false;

    const editedItems = [...updatedItems].map((item) => {
      if (item.id === values.id && item.type === values.type) {
        edited = true;

        return {
          ...item,
          ...values,
        };
      }

      return item;
    });

    if (!edited) {
      setUpdatedItems([
        ...updatedItems,
        {
          ...values,
          clientId: parseInt(id, 10),
        },
      ]);
    } else {
      setUpdatedItems(editedItems);
    }
  };

  const saveProducts = async () => {
    try {
      setIsUpdating(true);
      await Axios.all(
        [...updatedItems].map((item) => {
          if (!item?.value && item?.customProductId) {
            return deleteProductCustomFields({ id: item?.customProductId });
          }

          if (item?.customProductId) {
            return updateProductCustomFields({
              id: item?.customProductId,
              newValue: item?.value,
            });
          }

          return addProductCustomFields(item);
        })
      );
      await reloadData();

      setUpdatedItems([]);
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Card className="anim-table-delayed shadow-table !border !border-line-default">
      <Search
        placeholder={t('general.search-using', { resource: 'name' })}
        resource="title"
        meta={meta}
        setMeta={setMeta}
        filter={
          <Button
            className={classNames('!py-1.5 !px-4 !text-xs !h-auto', isUpdating && '!pr-10')}
            onClick={() => {
              saveProducts();
            }}
            loading={isUpdating}
          >
            {t('general.save-changes')}
          </Button>
        }
      />
      <Table
        isLoaded={!loading}
        activeSort={meta?.sorts || ''}
        items={data?.items}
        pageSize={meta?.pageSize}
        headers={[
          {
            label: t('admin.client.product-name'),
            key: 'title',
          },
          {
            label: t('admin.client.standard-price'),
            key: 'standard-price',
            sort: false,
          },
          {
            label: t('admin.client.custom-price'),
            key: 'custom-price',
            sort: false,
          },
          {
            label: t('admin.client.custom-budget'),
            key: 'custom-budget',
            sort: false,
          },
        ]}
        renderBody={(item, index) => {
          const customPrice =
            updatedItems?.find((updatedItem) => updatedItem.productId === item.id && updatedItem.type === TYPES.PRICE)
              ?.value || item?.customPrice;

          const customBudget =
            updatedItems.find(
              (updatedItem) => updatedItem.productId === item.id && updatedItem.type === TYPES.LIFETIME_BUDGET
            )?.value || item?.customLifetimeBudget;

          return (
            <tr key={item.id} id={`products-table-row--${index}`}>
              <td data-testid={`custom-products-td-${index}-0`}>{item?.title}</td>
              <td data-testid={`custom-products-td-${index}-1`}>{item?.price}</td>
              <td data-testid={`custom-products-td-${index}-2`}>
                <Input
                  data-testid={`form-custom-price-${index}`}
                  name="customPrice"
                  type="number"
                  defaultValue={customPrice}
                  placeholder={t('admin.client.custom-price')}
                  readOnly={isUpdating}
                  onBlur={(e) => {
                    let value = e?.target?.value;

                    if (value) {
                      value = parseInt(value, 10);
                    }

                    if (value === item?.customPrice || (value === '' && item?.customPrice === null)) {
                      return;
                    }

                    addOrUpdateModifiedItem({
                      customProductId: item?.customFieldIdForPrice,
                      productId: item?.id,
                      type: TYPES.PRICE,
                      value: isNumber(value) ? value : null,
                    });
                  }}
                />
              </td>
              <td data-testid={`custom-products-td-${index}-3`}>
                {item?.marketings?.includes(FACEBOOK) && (
                  <Input
                    data-testid={`form-custom-budget-${index}`}
                    name="customBudget"
                    type="number"
                    defaultValue={customBudget}
                    placeholder={t('admin.client.custom-budget')}
                    readOnly={isUpdating}
                    onBlur={(e) => {
                      let value = e?.target?.value;

                      if (value) {
                        value = parseInt(value, 10);
                      }

                      if (
                        value === item?.customLifetimeBudget ||
                        (value === '' && item?.customLifetimeBudget === null)
                      ) {
                        return;
                      }

                      addOrUpdateModifiedItem({
                        customProductId: item?.customFieldIdForLifetimeBudget,
                        productId: item?.id,
                        type: TYPES.LIFETIME_BUDGET,
                        value: isNumber(value) ? value : null,
                      });
                    }}
                  />
                )}
              </td>
            </tr>
          );
        }}
        setMeta={setMeta}
      />
      <div className="h-14 flex items-center bg-white">
        <Paginator
          pageCount={(data?.totalCount || data?.totalCount === 0 ? data.totalCount : 300) / meta.pageSize}
          initialPage={meta.page - 1}
          onPageChange={(value) => {
            if (meta.page !== value) {
              setMeta({
                page: value,
              });
            }
          }}
          disableInitialCallback
        />
      </div>
    </Card>
  );
};

const ClientCustomizeProductsWrapper = () => {
  const [productsAPI] = useAPI('products');
  const { id: clientId } = useParams();

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, token) => {
        return productsAPI.getCustomProducts(
          {
            filter: {
              clientId,
            },
            page: {
              number: meta.page,
              size: meta.pageSize,
            },
            // ...queryParams,
          },
          token
        );
        // return productsAPI.getCustomProducts({ ...meta, clientId }, token);
      }}
    >
      <ClientCustomizeProducts />
    </ApiRequestProvider>
  );
};

export default ClientCustomizeProductsWrapper;
