import React from 'react';
import { Col, FormGroup, Label, Input } from 'reactstrap';
import Dropzone from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import WysiwygEditor from '../components/wysiwyg/WysiwygEditor';
import Icon from '../components/@v2/Icon';
import Switch from '../components/input/Switch';
import DatePicker from '../components/input/DatePicker';
import Select from '../components/input/Select';
import i18n from '../i18n';

const defaultImage = `${
  global?.appConfig?.public_url || ''
}/images/default-image.png`;

const isInValid = (item) => {
  if (item.isFocused) {
    if (!item.value) return true;
    if (item.value && Array.isArray(item.value)) {
      if (item.value.length === 0) return true;
      return false;
    }
  }
  if (item.error) return true;
  return false;
};

// const renderErrorMessage = (item) => (<div className="invalid-field">{item.errorText || i18n.t('error-message.field-is-required')}</div>);
const renderErrorMessage = (item) => (
  <div className="invalid-field">
    {item.error || i18n.t('error-message.field-is-required')}
  </div>
);

const renderInputField = (item) => {
  const fieldProps = {
    type: item.type || 'text',
    id: item.key,
    placeholder: item.placeholder || '',
    onChange: ({ target }) => {
      item.setState(target.value);
    },
    disabled: item.disabled || false,
    // invalid: item.invalid || isInValid(item),
    invalid: item.isFocused && !item.value,
    className: `${item.className || ''} ${
      item.disabled && item.disabled === true ? 'disabled' : ''
    }`,
    name: item.name || item.key,
    innerRef: item.innerRef || null,
  };

  fieldProps[item.defaultValue ? 'defaultValue' : 'value'] = item.defaultValue
    ? item.defaultValue || ''
    : item.value || '';

  return <Input {...fieldProps} />;
};

const renderField = (type, item) => {
  if (!item) return '';
  item.noPLaceholder = true;
  switch (type) {
    case 'wysiwyg-editor':
      return (
        <WysiwygEditor
          key={item.key || 'wysiwyg-editor'}
          id={item.key || 'wysiwyg-editor'}
          readOnly={item.sending || false}
          toolbar={item.toolbar || 'base'}
          toolbarId={item.toolbarId || item.key || 'wysiwyg-editor'}
          placeholder={item.placeholder || ''}
          options={item.options || {}}
          defaultValue={item.value || ''}
          onEditorStateChange={item.setState}
          onBlur={item.onBlur}
          className={classnames('custom-wysiwyg', item.className)}
        />
      );
    case 'switch':
      return (
        <Switch
          checked={item.value}
          label={!item.noPLaceholder && item.label}
          onChange={(state) => item.setState(state)}
          disabled={item.disabled || false}
          className={`${item.className || ''} ${
            item.disabled && item.disabled === true ? 'disabled' : ''
          }`}
        />
      );
    case 'date':
      return (
        <DatePicker
          date={item.value}
          onChange={(val) => {
            item.setState(val);
          }}
          invalid={item.isFocused && !item.value}
          name={item.name || ''}
          {...item}
        />
      );
    case 'react-select':
      return (
        <Select
          value={item.value}
          callback={item.setState}
          options={item.options}
          isInvalid={isInValid(item)}
        />
      );
    default:
      return renderInputField(item);
  }
};

const handleImageChange = (acceptedFiles, setImageURL) => {
  if (setImageURL) setImageURL(acceptedFiles);
};

const renderImageField = (item, key) => {
  if (!item) return '';
  return (
    <Dropzone
      key={key}
      maxFiles={1}
      multiple={false}
      onDrop={(files) => {
        handleImageChange(files, item.setState);
      }}
      accept="image/png, image/jpeg, image/jpg, image/webp"
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div
            className="mb-0 d-flex align-items-center justify-content-center text-center image-upload-container"
            style={{
              backgroundImage: `url(${item.value || defaultImage})`,
              backgroundSize: item.key === 'logo' ? 'contain' : 'cover',
              backgroundPosition: 'center',
              backgroundColor: '#e9eef1',
              backgroundRepeat: 'no-repeat',
              height: 200,
              border: '1px solid #eee',
              borderRadius: 20,
            }}
          />
        </div>
      )}
    </Dropzone>
  );
};

const renderFormField = (item) => (
  <FormGroup
    key={item.key}
    className={`${item.customClass || ''} ${
      item.disabled && item.disabled === true ? 'disabled' : ''
    }`}
  >
    <Label for={item.key}>
      {item.label}
      {item.required ? '*' : ''}
    </Label>
    {item.additionalInfo ? (
      <Icon
        name="question-circle"
        type="fas"
        data-tip={item.additionalInfo || i18n.t('general.no-info')}
        className="additional-info"
      >
        <ReactTooltip effect="solid" place="right" />
      </Icon>
    ) : (
      ''
    )}
    <div style={{ marginBottom: '20px' }}>
      {item.fieldType !== 'image'
        ? renderField(item.fieldType, item)
        : renderImageField(item, item.key)}
      {isInValid(item) && renderErrorMessage(item)}
    </div>
  </FormGroup>
);

const displayFields = (item) => (
  <Col key={item.key} md={item.width || 3} sm="12" xs="12">
    {!item.isGrouped
      ? renderFormField(item)
      : item.fields.map((fieldItem) => renderFormField(fieldItem))}
  </Col>
);

export const renderErrorField = (
  condition,
  errorMsgKey = 'field-is-required',
  vars = {}
) =>
  condition && (
    <div data-testid={`errorField-${errorMsgKey}`} className="invalid-field">
      {i18n.t(`error-message.${errorMsgKey}`, vars)}
    </div>
  );

export const displaySectionLabel = (label, style = {}) => (
  <h3 className="line-behind-title" style={{ ...style, marginTop: '10px' }}>
    {label}
  </h3>
);

export const questionJson = (value) => ({
  id: value,
  screeningCriteriaId: value,
  requirementA: null,
  requirementB: null,
  requirementC: null,
});

export const formatQuestions = (questions) => {
  if (
    questions === null ||
    (questions || []).find(
      ({ screeningCriteriaId }) => screeningCriteriaId === 0
    )
  ) {
    return [];
  }
  return questions;
};

export default {
  displayFields,
};
