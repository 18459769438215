import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import classnames from 'classnames';
import Icon from '../Icon';
import ImageDropzone from '../../input/ImageDropzone';

const TopImageConfigurator = ({
  label,
  image,
  activeImageCoords,
  callback,
  onDrop,
  resource,
  page,
  headerActions,
  error,
  inputId,
  imageDivId,
  accept = 'image/png, image/jpeg, image/jpg, image/webp',
}) => {
  const { t } = useTranslation();
  const imageSelector = useRef(null);
  const [width, height, offsetTop, offsetLeft] = useMemo(() => {
    if (imageSelector.current) {
      const selector = imageSelector.current;
      const rect = selector?.getBoundingClientRect();

      return [selector?.clientWidth ?? 0, selector?.clientHeight ?? 0, rect?.top ?? 0, rect?.left ?? 0];
    }
    return [0, 0, 0, 0];
  }, [imageSelector.current]);
  const [currentImageCoords, setCurrentImageCoords] = useState({
    x: get(activeImageCoords, 'focusX', 0),
    y: get(activeImageCoords, 'focusY', 0),
  });
  const [currentClickedPosition, setCurrentClickedPosition] = useState({
    x: get(activeImageCoords, 'focusX', 0),
    y: get(activeImageCoords, 'focusY', 0),
  });
  const [isTopConfiguratorActive, setIsTopConfiguratorActive] = useState(false);

  const getPecentage = (partial, total) => (100 * partial) / total;

  const computeCoordinates = () => {
    if (imageSelector.current && currentClickedPosition) {
      if (!callback) return;
      callback({
        percentageX: getPecentage(currentClickedPosition.x, width),
        percentageY: getPecentage(currentClickedPosition.y, height),
        focusX: (currentClickedPosition.x - 0.5) * 2,
        focusY: (currentClickedPosition.y - 0.5) * -2,
      });
    }
  };

  useEffect(() => {
    setCurrentClickedPosition({
      x: get(activeImageCoords, 'focusX', 0),
      y: get(activeImageCoords, 'focusY', 0),
    });
  }, [activeImageCoords]);

  const onMouseMove = (e) => {
    const { clientX, clientY } = e;

    setCurrentImageCoords((currentState) => ({
      ...currentState,
      x: clientX - offsetLeft,
      y: clientY - offsetTop,
    }));
  };

  useEffect(() => {
    if (activeImageCoords && activeImageCoords.percentageX && activeImageCoords.percentageY && imageSelector.current) {
      setCurrentClickedPosition((currentState) => ({
        ...currentState,
        x: (activeImageCoords.percentageX / 100) * width,
        y: (activeImageCoords.percentageY / 100) * height,
      }));
    }
  }, [isTopConfiguratorActive, activeImageCoords, width, height, imageSelector]);

  const activePage = useMemo(() => {
    if (!isTopConfiguratorActive) {
      return page;
    }

    return null;
  }, [isTopConfiguratorActive, page]);

  return (
    <FormGroup>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <Label for="top-image" className="mb-0">
          {label}
        </Label>
        <div className="d-flex align-items-center mb-2">
          {!isTopConfiguratorActive && headerActions}
          {image && !isTopConfiguratorActive && (
            <>
              <button
                type="button"
                data-testid={`${resource}-crosshair-icon`}
                onClick={() => {
                  setIsTopConfiguratorActive(true);
                }}
              >
                <Icon
                  name="crosshairs"
                  color={page === 'career-page' ? 'var(--text-color-secondary)' : null}
                  className="clickable ml-2"
                />
              </button>
            </>
          )}
          {isTopConfiguratorActive && (
            <Button
              color="link"
              size="xs"
              onClick={() => {
                computeCoordinates();
                setIsTopConfiguratorActive(false);
              }}
              className={classnames('ml-2', {
                'color-secondary': page === 'career-page',
              })}
              style={{
                padding: 0,
                height: 'inherit',
              }}
              data-testid={`${resource}-save-button`}
            >
              {t('general.save').toUpperCase()}
            </Button>
          )}
        </div>
      </div>
      {isTopConfiguratorActive && (
        <div className="top-image-container relative">
          <div
            data-testid={`${resource}-selector`}
            className="absolute inset-0 overflow-hidden"
            onClick={() => {
              setCurrentClickedPosition({
                x: currentImageCoords.x,
                y: currentImageCoords.y,
              });
            }}
            ref={imageSelector}
            onMouseMove={onMouseMove}
          >
            <div
              draggable
              data-testid={`${resource}-selector-point`}
              className="bg-black bg-opacity-25 absolute h-[30px] w-[30px] rounded-full"
              style={{
                top: currentClickedPosition.y - 15,
                left: currentClickedPosition.x - 15,
              }}
            />
          </div>
          <img
            data-testid={`${resource}-canvas`}
            src={image}
            alt="top"
            className="w-full rounded-sm overflow-hidden"
            style={{ userSelect: 'none' }}
          />
        </div>
      )}
      {activePage === 'career-page' && (
        <Dropzone
          maxFiles={1}
          multiple={false}
          onDrop={(files) => {
            if (onDrop) onDrop(files, resource, 'topImage');
          }}
          accept={accept}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="bg-upload-gray mb-4">
              <input {...getInputProps()} id={inputId} data-testid={`${resource}-input`} />
              {image ? (
                <img src={image} alt="top" className="img-fluid" />
              ) : (
                <div
                  id={imageDivId}
                  className="mb-0 d-flex align-items-center justify-content-center text-center"
                  style={{
                    backgroundImage: `url(${image || ''})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: 150,
                  }}
                >
                  <Icon name="upload" color="var(--text-color-secondary)" size="24px" />
                </div>
              )}
            </div>
          )}
        </Dropzone>
      )}
      {activePage === 'position' && (
        <ImageDropzone
          resource={resource}
          divId={imageDivId}
          value={image}
          keepAspectRatio
          type="picture"
          onChange={onDrop}
          inputId={inputId}
        />
      )}
      {error}
    </FormGroup>
  );
};

TopImageConfigurator.defaultProps = {
  image: null,
  resource: '',
  page: 'career-page',
  headerActions: null,
  error: null,
  inputId: null,
  imageDivId: null,
};

export default TopImageConfigurator;
