import qs from 'query-string';
import helper, { api } from '../helpers/api';

const resource = 'Departments';

export default {
  ...helper(resource, 'restful'),
  browse: (queryParams = {}, options = {}) =>
    api.get(`${resource}?${qs.stringify(queryParams)}`, options),
  destroy: (id, headers = {}) => api.delete(`${resource}/${id}`, headers),
  restore: (id) => api.post(`${resource}/${id}/restore`),
};
