import helper, { api } from '../helpers/api';

const resource = 'PipelineSteps';

export default {
  ...helper(resource),
  getAllTemplates: () => api.get(`${resource}/GetAllTemplates`),
  addStep: (body = {}, options = {}) => api.post(
    `${resource}/Add`,
    body,
    options,
  ),
  updateStep: (body = {}, options = {}) => api.put(
    `${resource}/Update`,
    body,
    options,
  ),
  deleteStep: (body = {}, options = {}) => api.delete(
    `${resource}/Delete`,
    {
      data: body,
      ...options,
    },
  ),
  addTemplate: (body = {}, options = {}) => api.post(
    `${resource}/AddTemplate`,
    {
      archived: false,
      pipelineTemplateId: 0,
      ...body,
    },
    options,
  ),
  updateTemplate: (body = {}, options = {}) => api.put(
    `${resource}/UpdateTemplate`,
    {
      archived: false,
      ...body,
    },
    options,
  ),
  deleteTemplate: (body = {}, options = {}) => api.delete(
    `${resource}/DeleteTemplate`,
    {
      data: {
        archived: false,
        ...body,
      },
      ...options,
    },
  ),
};
