import React from 'react';
import EmailVariablesToolbar from './EmailVariablesToolbar';
import EmailTemplateToolbar from './EmailTemplateToolbar';

const EmailVariablesTemplatesToolbar = ({ listItems }) => {
  return (
    <>
      <EmailVariablesToolbar />
      <EmailTemplateToolbar listItems={listItems} className="position" />
    </>
  );
};

export default EmailVariablesTemplatesToolbar;
