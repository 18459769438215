import classNames from 'classnames';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/@v2/Icon';
import { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import { log } from '../../../helpers/utils';

const PositionSort = ({ activeTab }) => {
  const { t } = useTranslation();
  const { meta, setMeta } = useApiRequestContext();

  const getLabel = (meta) => {
    switch (meta?.sorts) {
      case '': {
        return t('general.standard-sorting');
      }

      case 'publishDate': {
        return t('general.published-date-desc');
      }

      case '-publishDate': {
        return t('general.published-date-asc');
      }

      case 'applicationDeadline': {
        return t('general.application-desc');
      }

      case '-applicationDeadline': {
        return t('general.application-asc');
      }

      case 'name': {
        return t('general.a-z-position-name');
      }

      case '-name': {
        return t('general.z-a-position-name');
      }

      case 'location': {
        return t('general.a-z-location-name');
      }

      case '-location': {
        return t('general.z-a-location-name');
      }

      case 'position': {
        return t('general.a-z-position-name');
      }

      case '-position': {
        return t('general.z-a-position-name');
      }

      case 'Location': {
        return t('general.a-z-location-name');
      }

      case '-Location': {
        return t('general.z-a-location-name');
      }

      case 'recruiter': {
        return t('general.a-z-recruiter-name');
      }

      case '-recruiter': {
        return t('general.z-a-recruiter-name');
      }

      case 'applications': {
        return t('general.applications-desc');
      }

      case '-applications': {
        return t('general.applications-asc');
      }

      case 'unhandledApplications': {
        return t('general.unhandled-desc');
      }

      case '-unhandledApplications': {
        return t('general.unhandled-asc');
      }

      default: {
        return '';
      }
    }
  };

  const label = getLabel(meta);

  const onSelect = ({ key }) => {
    switch (key) {
      case '':
        setMeta({
          sorts: '',
        });
        return;

      case 'published-date-desc':
        setMeta({
          sorts: 'publishDate',
        });
        return;

      case 'published-date-asc':
        setMeta({
          sorts: '-publishDate',
        });
        return;

      case 'application-desc':
        setMeta({
          sorts: 'applicationDeadline',
        });
        return;

      case 'application-asc':
        setMeta({
          sorts: '-applicationDeadline',
        });
        return;

      case 'a-z-position-name':
        setMeta({
          sorts: 'name',
        });
        return;

      case 'z-a-position-name':
        setMeta({
          sorts: '-name',
        });
        return;

      case 'a-z-location-name':
        setMeta({
          sorts: 'location',
        });
        return;

      case 'z-a-location-name':
        setMeta({
          sorts: '-location',
        });
        return;

      default:
        log('cannot find an action');
        break;
    }
  };

  const menu = (
    <Menu onSelect={onSelect}>
      {activeTab === 0 && (
        <MenuItem key="">
          <button
            type="button"
            className={classNames({
              'font-bold': meta?.sorts === '',
            })}
            data-testid="option-standard-sorting"
          >
            {t('general.standard-sorting')}
          </button>
        </MenuItem>
      )}
      <MenuItem key="published-date-desc">
        <button
          type="button"
          className={classNames({
            'font-bold': meta?.sorts === 'publishDate',
          })}
          data-testid="option-published-date-desc"
        >
          {t('general.published-date-desc')}
        </button>
      </MenuItem>
      <MenuItem key="published-date-asc">
        <button
          type="button"
          className={classNames({
            'font-bold': meta?.sorts === '-publishDate',
          })}
          data-testid="option-published-date-asc"
        >
          {t('general.published-date-asc')}
        </button>
      </MenuItem>
      <MenuItem key="application-desc">
        <button
          type="button"
          className={classNames({
            'font-bold': meta?.sorts === 'applicationDeadline',
          })}
          data-testid="option-application-desc"
        >
          {t('general.application-desc')}
        </button>
      </MenuItem>
      <MenuItem key="application-asc">
        <button
          type="button"
          className={classNames({
            'font-bold': meta?.sorts === '-applicationDeadline',
          })}
          data-testid="option-application-asc"
        >
          {t('general.application-asc')}
        </button>
      </MenuItem>
      <MenuItem key="a-z-position-name">
        <button
          type="button"
          className={classNames({
            'font-bold': meta?.sorts === 'position',
          })}
          data-testid="option-a-z-position-name"
        >
          {t('general.a-z-position-name')}
        </button>
      </MenuItem>
      <MenuItem key="z-a-position-name">
        <button
          type="button"
          className={classNames({
            'font-bold': meta?.sorts === '-position',
          })}
          data-testid="option-z-a-position-name"
        >
          {t('general.z-a-position-name')}
        </button>
      </MenuItem>
      <MenuItem key="a-z-location-name">
        <button
          type="button"
          className={classNames({
            'font-bold': meta?.sorts === 'location',
          })}
          data-testid="option-a-z-location-name"
        >
          {t('general.a-z-location-name')}
        </button>
      </MenuItem>
      <MenuItem key="z-a-location-name">
        <button
          type="button"
          className={classNames({
            'font-bold': meta?.sorts === '-location',
          })}
          data-testid="option-z-a-location-name"
        >
          {t('general.z-a-location-name')}
        </button>
      </MenuItem>
    </Menu>
  );

  return (
    <Dropdown
      trigger={['click']}
      overlay={menu}
      animation="slide-up"
      placement="bottomRight"
    >
      <button
        type="button"
        className="transition text-xs border px-3 py-1.5 rounded-lg flex items-center relative hover:bg-table-hover hover:text-black"
        data-testid="table-sort-button"
      >
        <Icon name="sort" className="mr-2" size={12} />
        <p className="mb-0">
          {t('general.sort-by')}
          {label !== '' && (
            <>
              <span>: </span>
              <span className="font-bold">{label}</span>
            </>
          )}
        </p>
      </button>
    </Dropdown>
  );
};

export default PositionSort;
