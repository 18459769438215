import React, { useState, useRef, useMemo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
import classnames from 'classnames';
import '../../assets/sass/candidates/documents.sass';
import qs from 'query-string';
import Loader from '../Loader';
import LightboxComponent from '../Lightbox';
import { useAPI } from '../../context/api';
import Icon from '../@v2/Icon';
import events from '../../helpers/events';
import { log, isIOS } from '../../helpers/utils';
import { getMediaUrl } from '../../helpers/image';

const Documents = ({ activeCandidate, callback }) => {
  const { body } = document;
  const history = useHistory();
  const location = useLocation();
  const queryParams = useMemo(
    () => (get(location, 'search') ? qs.parse(get(location, 'search')) : {}),
    [get(location, 'search')]
  );
  const { t } = useTranslation();
  const { id } = useParams();
  const inputFile = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [isDeleting, setIsDeleting] = useState([]);

  const documents = useMemo(
    () =>
      (get(activeCandidate, 'documents') || [])
        .filter((item) => item?.fileExt === 'pdf')
        .map((item, index) => {
          const clonedItem = { ...item };
          if (index === 0) {
            clonedItem.active = true;
          }
          clonedItem.pages = [];
          clonedItem.numPages = 0;
          clonedItem.url = getMediaUrl(item);
          clonedItem.name = `${t('general.document')} ${index + 1}`;

          return clonedItem;
        }),
    [activeCandidate.documents]
  );

  const [candidateAPI] = useAPI('candidate');
  const [active, setActive] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isIOSDevice, setIsIOSDevice] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setIsIOSDevice(isIOS());
  }, []);

  useEffect(() => {
    setActiveTab(0);
  }, [activeCandidate]);

  function updateMobileState() {
    setIsMobile(window.innerWidth < 426);
  }

  useEffect(() => {
    window.addEventListener('resize', updateMobileState);
    updateMobileState();

    return () => window.removeEventListener('resize', updateMobileState);
  }, []);

  const deleteDocument = async (item) => {
    try {
      setIsDeleting((state) => [...state, item.id]);
      await candidateAPI.deleteDocument({
        candidateId: activeCandidate.id,
        fileId: item.id,
      });

      callback();
    } catch (error) {
      log(error);
    }
  };

  const uploadDocument = async (file) => {
    if (isSending) return;
    const formData = new FormData();
    formData.append('file', file);

    try {
      setIsSending(true);
      await candidateAPI.addDocument(id, formData);

      callback();
      if (inputFile.current) inputFile.current.value = null;
    } catch (error) {
      // log(error);
    } finally {
      setIsSending(false);
    }
  };

  const renderMobileHeaderIcons = (item, index) => (
    <div className="document-header d-flex align-items-center justify-content-end">
      <div
        data-testid={`candidates-document-download-${index}-button`}
        id={`button--download-${index}`}
        onClick={() => {
          if (isDeleting.includes(item.id)) return;
          const win = window.open(item.url, '_blank');
          win.focus();
        }}
      >
        <Icon className="clickable mr-2" name="download" />
      </div>
      <div
        data-testid={`candidates-document-delete-${index}-button`}
        id={`button--delete-${index}`}
        className="position-relative"
        style={{ width: 14 }}
        onClick={() => {
          if (isDeleting.includes(item.id)) return;
          deleteDocument(item);
        }}
      >
        {isDeleting.includes(item.id) ? (
          <Loader size={24} color="black" />
        ) : (
          <Icon className="clickable" name="trash" />
        )}
      </div>
    </div>
  );

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const renderAltDocument = (item) => (
    <div className="ios-document d-flex flex-column align-items-center" key={item.id}>
      <div className="p-2 px-4 w-100">
        <div className="py-2">{renderMobileHeaderIcons(item, activeCandidate)}</div>
        {numPages > 1 && (
          <div className="py-2 d-flex justify-content-between">
            <button type="button" onClick={goToPrevPage}>
              {`< ${t('general.previous')}`}
            </button>

            <p className="mb-0">{t('general.page-of', { x: pageNumber, y: numPages })}</p>

            <button type="button" onClick={goToNextPage}>
              {`${t('general.next')} >`}
            </button>
          </div>
        )}
      </div>

      <div
        className="clickable"
        onClick={() => {
          if (!item.url) return;
          const win = window.open(item.url, '_blank');
          win.focus();
        }}
      >
        <Document file={item.url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
    </div>
  );

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setActive(null);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    events.$on('delete-document-callback', () => {
      callback();
    });

    return () => events.$off('delete-document-callback');
  }, []);

  return (
    <div className="candidates-documents d-flex flex-wrap" data-testid="candidate-documents-iframe">
      {body &&
        active &&
        !!documents.length &&
        createPortal(<LightboxComponent onClose={() => setActive(null)} activeDocument={active} />, body)}
      {isMobile &&
        documents.map((item, index) => (
          <div className="document document--light clickable d-flex flex-column position-relative">
            {renderMobileHeaderIcons(item, index)}
            <div
              className="pb-2 flex-grow-1 d-flex align-items-center justify-content-center"
              onClick={() => {
                if (isDeleting.includes(item.id)) return;
                setActive(item);
              }}
            >
              {t('general.document')} {index + 1}
            </div>
          </div>
        ))}
      {isMobile && (
        <div className="document document--light clickable d-flex flex-column">
          <div className="document-header d-flex align-items-center justify-content-between">
            <Icon name="plus" />
            {t('candidates.document.upload-document')}
          </div>
          <div className="plus-icon flex-grow-1 d-flex align-items-center justify-content-center">
            <input
              id="button--upload-file"
              data-testid="candidates-document-upload-input"
              type="file"
              accept=".pdf,.docx"
              ref={inputFile}
              onChange={(e) => {
                const { files } = e.target;
                if (files.length) {
                  uploadDocument(files[0]);
                }
              }}
            />
            {isSending ? <Loader size={50} color="black" /> : <Icon name="plus" size="3x" color="#dce0e6" />}
          </div>
        </div>
      )}
      {!isMobile && (
        <div className="flex flex-col w-full">
          <Nav tabs className="mb-6">
            {documents.map((item, index) => (
              <NavItem key={item.id || index}>
                <NavLink
                  className={classnames('relative !px-4 !flex !items-center', {
                    active: activeTab === index,
                  })}
                  onClick={() => {
                    if (index !== activeTab) setActiveTab(index);
                  }}
                >
                  <span
                    className={classnames({
                      'mr-3': !isDeleting.includes(item.id),
                      'mr-4': isDeleting.includes(item.id),
                    })}
                    id={`button--active-file-${index}`}
                  >
                    {item.name}
                  </span>
                  {isDeleting.includes(item.id) ? (
                    <Loader
                      size={30}
                      color="black"
                      parentStyles={{
                        right: 18,
                        top: 16,
                      }}
                    />
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        if (isDeleting.includes(item.id)) return;
                        if (documents.length) setActiveTab(0);
                        history.push({
                          ...location,
                          search: qs.stringify({
                            ...queryParams,
                            name: item.name,
                            modal: 'delete-candidate-document',
                            params: JSON.stringify({
                              candidateId: activeCandidate.id,
                              fileId: item.id,
                            }),
                          }),
                        });
                      }}
                    >
                      <Icon
                        id={`button--delete-${index}`}
                        data-testid={`candidates-document-delete-${index}-button`}
                        className="clickable"
                        name="trash"
                        color="red"
                      />
                    </button>
                  )}
                </NavLink>
              </NavItem>
            ))}
            <NavItem>
              <NavLink
                className={classnames('position-relative !px-4', {
                  'pr-4': isSending,
                })}
                onClick={() => {
                  if (!isSending && inputFile.current) {
                    inputFile.current.click();
                  }
                }}
              >
                {t('candidates.document.upload-document')}
                {isSending && (
                  <Loader
                    parentStyles={{
                      right: 12,
                      top: 18,
                    }}
                    size={30}
                    color="black"
                  />
                )}
                <input
                  data-testid="candidates-document-upload-input"
                  id="button--upload-file"
                  type="file"
                  className="hidden"
                  accept=".pdf,.docx"
                  ref={inputFile}
                  onChange={(e) => {
                    const { files } = e.target;
                    if (files.length) {
                      uploadDocument(files[0]);
                    }
                  }}
                />
              </NavLink>
            </NavItem>
          </Nav>

          {documents[activeTab] && get(documents[activeTab], 'url') && (
            <>
              {isIOSDevice ? (
                renderAltDocument(documents[activeTab])
              ) : (
                <iframe
                  className="documents-iframe"
                  src={documents[activeTab].url}
                  title={documents[activeTab].name}
                  style={{
                    width: '100%',
                    height: '800px',
                  }}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Documents;
