import React, { useEffect, createContext, useState, useContext, useRef, useMemo } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { log } from '../../helpers/utils';
import { selectTablePageSize } from '../../store/selectors/settings.selector';
import { useSelector as useSelectorToolkit } from '../../store';

export const ApiRequestContext = createContext(null);

export const ApiRequestProvider = ({
  children,
  api,
  defaultFilters,
  defaultSorts,
  withCancellation,
  useObjectFilter,
}) => {
  const location = useLocation();
  const { search } = location;
  const queryParams = useMemo(() => qs.parse(search), [search]);
  const tokenSource = useRef();
  const size = useSelectorToolkit(selectTablePageSize);
  const [loading, setLoading] = useState(true);
  const [isApiActive, setIsApiActive] = useState(false);
  const [data, setData] = useState(null);
  const [meta, setMeta] = useState({
    page: queryParams?.page || 1,
    filters: defaultFilters,
    pageSize: size || 20,
    sorts: defaultSorts || '',
  });

  const changeMeta = (value) => {
    if (isApiActive && !withCancellation) return;
    setMeta({ ...meta, ...value });
  };

  const parseFilters = () => {
    let pFilter = '';

    (get(meta, 'filters') || []).forEach((filter) => {
      if (filter.value) {
        if (pFilter !== '') pFilter += ',';

        if (filter.condition) {
          pFilter += `${filter.key}${filter.condition}${filter.value}`;
        } else {
          pFilter += `${filter.key}==${filter.value}`;
        }
      }
    });

    return pFilter;
  };

  const getData = async (params = {}) => {
    if (isApiActive && !withCancellation) return false;
    if (typeof tokenSource?.current !== typeof undefined) {
      tokenSource?.current?.cancel('Operation cancelled due to new request.');
    }

    tokenSource.current = axios.CancelToken.source();

    let fetchData = null;

    try {
      setIsApiActive(true);
      setLoading(true);

      const newMetas = { ...meta };
      const response = await api(
        useObjectFilter
          ? {
              filter: {
                ...(get(meta, 'filters') || []).reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {}),
              },
              page: {
                number: get(meta, 'page'),
                size: get(meta, 'pageSize'),
              },
            }
          : {
              ...newMetas,
              filters: parseFilters(),
            },
        tokenSource?.current,
        data,
        params,
        meta?.filters
      );
      setData(response);
      fetchData = response;
    } catch (error) {
      log('Getting of data error', error);
    } finally {
      setLoading(false);
      setIsApiActive(false);
    }

    return fetchData;
  };

  useEffect(() => {
    getData();

    return () => {
      tokenSource?.current?.cancel('Operation cancelled due to unloading the page.');
    };
  }, [meta]);

  useDidMountEffect(() => {
    setMeta({
      ...meta,
      page: 1,
      pageSize: size,
    });
  }, [size]);

  return (
    <ApiRequestContext.Provider
      value={{
        data,
        loading,
        meta,
        setMeta: changeMeta,
        pageSize: size,
        reloadData: getData,
      }}
    >
      {children}
    </ApiRequestContext.Provider>
  );
};

ApiRequestProvider.propTypes = {
  children: PropTypes.element.isRequired,
  api: PropTypes.func.isRequired,
  withCancellation: PropTypes.bool,
  defaultFilters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      condition: PropTypes.string,
      value: PropTypes.oneOfType(PropTypes.number, PropTypes.string).isRequired,
    })
  ),
  defaultSorts: PropTypes.string,
  useObjectFilter: PropTypes.bool,
};

ApiRequestProvider.defaultProps = {
  defaultFilters: [],
  defaultSorts: '',
  withCancellation: false,
  useObjectFilter: false,
};

export const useApiRequestContext = () => {
  const context = useContext(ApiRequestContext);

  if (context === undefined) {
    throw new Error('useApiRequest can only be used inside ApiRequestProvider');
  }
  return context;
};

export default ApiRequestProvider;
