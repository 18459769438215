import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import Select from '../Select/Select';

const SelectFilter = ({
  id,
  label,
  options,
  callback,
  searchable,
  value,
  api,
  searchKey,
  defaultFilters,
  objectFilters,
  keys,
  multiple,
  ...rest
}) => {
  const parsedValue = useMemo(() => {
    if (!value) {
      return [];
    }

    return isArray(value) ? value : [value];
  }, [value]);

  return (
    <div className="filter mb-4">
      {!!label && <p className="mb-2 text-sm font-bold">{label}</p>}
      <Select
        api={api}
        searchable={searchable}
        multiple={multiple}
        clearable
        id={id}
        searchKey={searchKey}
        defaultOptions={options}
        onSelect={(selected) => {
          if (!callback) return;
          if (multiple) {
            callback(selected || []);
            return;
          }

          callback(selected[0] || null);
        }}
        value={parsedValue}
        defaultFilters={defaultFilters}
        objectFilters={objectFilters}
        keys={keys}
        {...rest}
      />
    </div>
  );
};

SelectFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  callback: PropTypes.func.isRequired,
  searchable: PropTypes.bool,
  api: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  searchKey: PropTypes.string,
  defaultFilters: PropTypes.string,
  objectFilters: PropTypes.any,
  keys: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
};

SelectFilter.defaultProps = {
  id: null,
  label: '',
  searchable: true,
  api: null,
  value: [],
  searchKey: 'label',
  defaultFilters: '',
  objectFilters: null,
  keys: ['value', 'label'],
  multiple: false,
};

export default SelectFilter;
