import React, { useState, useCallback } from 'react';
import { Row, Col, FormGroup, Label, Input, Collapse, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Switch from '../../input/Switch';
import { createLinkSuffix, isValidHttpOrMailtoURL } from '../../../helpers/utils';
import { renderErrorField } from '../../../helpers/form';
import { deepLinkURL } from '../../../constants/Position';
import InputLoaderWrapper from '../../input/InputLoader';
import LineBehind from '../../LineBehind';
import { useSelector as useSelectorToolkit } from '../../../store';
import { selectClient } from '../../../store/selectors/client.selector';

const ExternalLink = ({ state, setState, isValidating, loading }) => {
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState(false);
  const [touchedFields, setTouchedFields] = useState({});
  const activeClient = useSelectorToolkit(selectClient);
  const { key: clientKey, customUrl } = activeClient;

  const isInvalid = useCallback(
    (key) => {
      const value = state[key];
      const touched = touchedFields[key];
      if (state.useAlternativeApplyForm === true && key === 'alternativeApplyFormLink' && value) {
        return (isValidating === true && !isValidHttpOrMailtoURL(value)) || (touched && !isValidHttpOrMailtoURL(value));
      }
      return (isValidating === true && (value === '' || !value)) || (touched && (value === '' || !value));
    },
    [state, touchedFields, isValidating, isValidHttpOrMailtoURL]
  );

  const displayError = (fieldKey) => renderErrorField(isInvalid(fieldKey), 'valid-link-to-form');

  return (
    <div className="pb-4">
      <LineBehind
        title={t('general.external-link')}
        icon={`angle-${isShown ? 'up' : 'down'}`}
        onClick={() => setIsShown(!isShown)}
        dataTestId="position-deep-link-line-behind"
      />
      <Collapse isOpen={isShown}>
        <Row className="position-form-holder">
          <Col xl={8} lg={8} md={12}>
            <FormGroup>
              <Label for="url">{t('edit-position.deep-link-to-position')}</Label>
              <InputLoaderWrapper condition={loading.data}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText data-testid="position-deep-link-prefix">
                      {deepLinkURL(clientKey, state.positionId, customUrl)}
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={state.publicLinkSuffix}
                    onChange={(e) => {
                      setState('publicLinkSuffix', createLinkSuffix(e.target.value));
                    }}
                    disabled={loading.data}
                    id="public-link-suffix"
                    data-testid="position-deep-link-suffix-input"
                  />
                </InputGroup>
              </InputLoaderWrapper>
            </FormGroup>
            <FormGroup>
              <Label for="alternative-apply-form">{t('edit-position.use-alternative-apply-form')}</Label>
              <Switch
                checked={state.useAlternativeApplyForm}
                onChange={(state) => {
                  setState('useAlternativeApplyForm', state);
                  setState('showDescription', !state);
                }}
                disabled={loading.data}
                id="use-alternative-apply-form"
                data-testid="position-alternative-apply-form-switch"
              />
            </FormGroup>
            {state.useAlternativeApplyForm && (
              <>
                <FormGroup>
                  <Label for="alternative-apply-form-link">{t('edit-position.link-to-form')}</Label>
                  <InputLoaderWrapper condition={loading.data}>
                    <Input
                      value={state.alternativeApplyFormLink}
                      onChange={(e) => {
                        setState('alternativeApplyFormLink', e.target.value);
                      }}
                      onBlur={() => {
                        setTouchedFields((state) => ({
                          ...state,
                          alternativeApplyFormLink: true,
                        }));
                      }}
                      invalid={isInvalid('alternativeApplyFormLink')}
                      id="alternative-apply-form"
                      data-testid="position-alternative-apply-form-input"
                    />
                  </InputLoaderWrapper>
                  {displayError('alternativeApplyFormLink')}
                </FormGroup>
                <FormGroup>
                  <Label for="show-position-description">
                    {t('edit-position.show-position-description-on-position-page')}
                  </Label>
                  <Switch
                    checked={state.showDescription}
                    onChange={(state) => setState('showDescription', state)}
                    disabled={loading.data || !state.useAlternativeApplyForm}
                    id="show-description"
                    data-testid="position-show-description-switch"
                  />
                </FormGroup>
              </>
            )}
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default ExternalLink;
