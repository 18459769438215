import ResponseListItem from '@ts/apicontracts/subscribers/response-list-item';
// import SearchSubscribersQuery from '@ts/apicontracts/subscribers/search-subscribers-query';
import { AxiosResponse } from 'axios';

import { api } from '../../helpers/api-v2';

const resource = '/subscribers';

export const getSubscribersList = async (queryParams, options = {}) => {
  // current search contract has missing filter value
  const response = await api.post(`${resource}/list`, queryParams, options);
  return response as AxiosResponse<ResponseListItem[]>;
};

export const deleteSubscriber = async (id: string) => {
  const response = await api.delete(`${resource}/${id}`);
  return response;
};
