import { createSlice } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';
import { CartItem } from '../models/cart.models';

export const initialState: CartItem[] = [];

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, { payload }) => {
      return [
        ...state,
        {
          positionId: 0,
          ...payload,
          key: payload.key || uniqueId(),
        },
      ];
    },
    updateItem: (state, { payload }) => {
      return state.map((item) => {
        if (item.key === payload.key) {
          return payload;
        }

        return item;
      });
    },
    removeItem: (state, { payload }) => {
      return state.filter((item) => {
        return item.key !== payload;
      });
    },
    resetCart: () => {
      return initialState;
    },
  },
});

export const { addItem, updateItem, removeItem, resetCart } = slice.actions;
export default slice.reducer;
