import React from 'react';
// import type { Node } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/layouts/default';

const faq = () => {
  const { t } = useTranslation();
  return <Layout>{t('general.faq')}</Layout>;
};

export default faq;
