import React, { useEffect, useRef, useState, useCallback } from 'react';
import classnames from 'classnames';

const Modal = ({ children, width, position }) => (
  <div
    className={classnames('custom-modal', {
      [`custom-modal-position--${position || ''}`]: !!position,
    })}
    style={{
      maxWidth: width,
    }}
  >
    {children}
  </div>
);

Modal.defaultProps = {
  width: null,
  position: null,
};

export const ModalHeader = ({ children }) => (
  <div className="custom-modal-header">{children}</div>
);

export const ModalBody = ({ children, noPadding }) => {
  const modalMaxHeight = window.innerHeight * 0.8 - 53;
  const modalBody = useRef();
  const [withOverflow, setWithOverflow] = useState(false);

  const checkModalBodyHeight = useCallback(() => {
    if (modalBody.current) {
      if (modalBody.current.clientHeight >= modalMaxHeight) {
        setWithOverflow(true);
      } else {
        setWithOverflow(false);
      }
    }
  }, [modalMaxHeight]);

  useEffect(() => {
    checkModalBodyHeight();
    window.addEventListener('resize', checkModalBodyHeight);

    return () => {
      window.removeEventListener('resize', checkModalBodyHeight);
    };
  }, [checkModalBodyHeight]);

  return (
    <div
      ref={modalBody}
      style={{ overflowY: withOverflow ? 'auto' : null }}
      className={classnames('custom-modal-body', {
        'custom-modal-body--no-padding': noPadding,
      })}
    >
      <div className="custom-modal-wrapper">{children}</div>
    </div>
  );
};

ModalBody.defaultProps = {
  noPadding: false,
};

export default Modal;
