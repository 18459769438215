import Tippy, { useSingleton } from '@tippyjs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { hideAll } from 'tippy.js';
import ApiRequestProvider, { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import { deletePipelineTemplate, getAllPipelineTemplates } from '../../../store/api/pipelines.api';
import Button from '../../Button';
import Card from '../../Card';
import DeleteModelTooltip from '../DeleteModelTooltip/DeleteModelTooltip';
import Icon from '../Icon';
import Paginator from '../Paginator/Paginator';
import Search from '../Search/Search';
import Table from '../Table/Table';

const PipelineTemplates = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [source, target] = useSingleton();
  const { loading, data, meta, setMeta, reloadData } = useApiRequestContext();

  return (
    <Card className="overflow-hidden" containerClassName="anim-table-delayed">
      <Tippy
        trigger="click mouseenter"
        singleton={source}
        theme="light"
        interactive
        maxWidth={200}
        appendTo={document.body}
        placement="bottom"
      />
      <Search
        hideShowAll
        label={t('general.pipeline-templates')}
        placeholder={t('general.search')}
        resource="name"
        meta={meta}
        setMeta={setMeta}
        filter={
          <Button
            className="text-xs !px-3 !py-1.5 ml-2 !rounded-lg !h-[30px]"
            color="primary"
            onClick={() => {
              history.push('/settings/pipeline/template/add');
            }}
            data-testid="pipeline-template-add"
          >
            <div className="flex items-center">
              <Icon name="plus" className="relative" />
              {t('settings.pipeline.add-pipeline-template')}
            </div>
          </Button>
        }
      />
      <Table
        isLoaded={!loading}
        activeSort={meta?.sorts || ''}
        items={data?.items || []}
        headers={[
          {
            label: t('general.name'),
            key: 'name',
            sort: false,
          },
          {
            label: t('general.actions'),
            key: 'actions',
            sticky: true,
            sort: false,
            width: 100,
            headerClassName: 'text-center',
          },
        ]}
        pageSize={meta?.pageSize}
        renderBody={(item, index) => {
          return (
            <tr key={item.id} id={`client-table-row--${index}`}>
              <td>{item?.name}</td>
              <td className="column-sticky">
                <div className="flex items-center justify-center gap-x-2">
                  <Tippy content={t('general.edit')} singleton={target}>
                    <button
                      type="button"
                      onClick={() => {
                        history.push({
                          pathname: `/settings/pipeline/template/edit/${item?.pipelineTemplateId || item?.id}`,
                          state: item,
                        });
                      }}
                      data-testid="pipeline-template-edit"
                    >
                      <Icon name="edit" />
                    </button>
                  </Tippy>
                  <DeleteModelTooltip
                    singleton={target}
                    content={
                      <p>
                        {t('general.pipeline-templates')}: {item?.name}
                      </p>
                    }
                    onDelete={async () => {
                      hideAll();
                      await deletePipelineTemplate(item);
                      reloadData();
                    }}
                  >
                    <button type="button" data-testid="pipeline-template-delete">
                      <Icon name="trash" />
                    </button>
                  </DeleteModelTooltip>
                </div>
              </td>
            </tr>
          );
        }}
        setMeta={setMeta}
      />
      {!loading && !!data?.items?.length && (
        <div className="h-14 flex items-center bg-white">
          <Paginator
            pageCount={data?.totalCount / meta?.pageSize}
            initialPage={meta?.page - 1}
            onPageChange={(value) => {
              if (meta.page !== value) {
                setMeta({
                  page: value,
                });
              }
            }}
            disableInitialCallback
          />
        </div>
      )}
    </Card>
  );
};

const PipelineTemplatesWrapper = () => {
  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source, ...rest) => {
        const newFilters = {};

        const filters = rest?.[2] || [];

        filters.forEach((item) => {
          newFilters[item.key] = item.value;
        });

        const sort = {
          by: meta?.sorts?.replace('-', ''),
          direction: meta.sorts.includes('-') ? 'desc' : 'asc',
        };
        const response = await getAllPipelineTemplates(
          {
            filter: newFilters,
            page: {
              number: meta.page?.number || meta.page || 1,
              size: meta.pageSize,
            },
            sort: meta.sorts ? sort : {},
          },
          {
            cancelToken: source.token,
            withPagination: true,
          }
        );

        return response;
      }}
    >
      <PipelineTemplates />
    </ApiRequestProvider>
  );
};

export default PipelineTemplatesWrapper;
