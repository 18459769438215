import React from 'react';
import PropTypes from 'prop-types';
import './Filter.sass';
import {
  useSpring,
  animated,
} from 'react-spring';

const Filter = ({
  show,
  children,
}) => {
  const props = useSpring({
    right: show ? 0 : -400,
    config: {
      duration: 300,
    },
  });

  return (
    <animated.div
      style={props}
      className="ts-filter"
    >
      {children}
    </animated.div>
  );
};

Filter.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default Filter;
