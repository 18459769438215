import { Card, CardBody, FormFeedback, Button, Col } from 'reactstrap';
import styled from 'styled-components';
import Select from '../input/Select';
import DatePicker from '../input/DatePicker';

export const StyledContainer = styled(Card)`
  max-width: ${(props) => props.maxwidth}px;
  margin-bottom: 0 !important;
`;

export const StyledForm = styled.div`
  max-width: ${(props) => props.maxwidth}px;
`;

export const StyledFormFeedback = styled(FormFeedback)`
  display: block !important;
`;

export const StyledDatePicker = styled(DatePicker)`
  border-radius: 0.375rem !important;
`;

export const StyledSelectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:first-child {
    flex: 1;
  }
`;

export const StyledButton = styled(Button)`
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
`;

export const StyledSelect = styled(Select)`
  flex-grow: 1;
  z-index: 3;

  .custom-react-select__control.custom-react-select__control {
    min-height: 38px !important;
    border-radius: 0.375rem !important;
    ${(props) => {
      return props.withButton ? 'border-top-right-radius: 0 !important;' : null;
    }}
    ${(props) => {
      return props.withButton
        ? 'border-bottom-right-radius: 0 !important;'
        : null;
    }}
    border-color: ${(props) => {
      return props.invalid ? '#f8bac3' : '#d2ddec';
    }} !important;
    &:hover {
      box-shadow: none !important;
    }
  }

  .custom-react-select__control.custom-react-select__control--is-focused {
    border-color: ${(props) => {
      return props.invalid ? '#f8bac3' : '#b9daf4';
    }} !important;
  }

  .custom-react-select__indicators {
    height: 36px;
  }
`;

export const StyledDraggableListItemCard = styled(Card)`
  margin-bottom: 10px !important;
`;

export const StyledDraggableListItemCardBody = styled(CardBody)`
  padding: 10px 15px 10px 10px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledDivider = styled(Col)`
  padding: 15px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 10px;

  h3 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    margin-right: 15px;
    color: #c3c3c3;
  }

  &:after {
    content: '';
    height: 1px;
    flex: 1;
    background: #d0d0d0;
  }
`;

export const StyledRatingContainer = styled.div`
  display: block;
`;
