import React from 'react';
import TextAligmentToolbar from './TextAligmentToolbar';
import TextSizeToolbar from './TextSizeToolbar';

const CareerTextToolbar = () => {
  return (
    <>
      <TextAligmentToolbar />
      <TextSizeToolbar />
    </>
  );
};

export default CareerTextToolbar;
