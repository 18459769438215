/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';

export type AxiosResponseDirect<T> = T;

const api = axios.create({
  baseURL: window.appConfig?.api_url || '',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

// Keeping it separate as the auth endpoint is outside domain
const ssoUrl = localStorage.getItem('ts-issuer');
const auth0Api = axios.create({
  baseURL: ssoUrl || window.appConfig?.login_url || '',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export { api, auth0Api };
