import React, { useMemo } from 'react';
import classnames from 'classnames';

const Loader = ({
  type,
  color,
  size,
  style,
  className,
  parentStyles,
  customLoaderStyles,
  ...rest
}) => {
  const setColorByType = () => {
    switch (type) {
      case 'primary':
        return 'var(--primary-color)';
      case 'danger':
        return '#e51c23';
      case 'success':
        return '#259b24';
      default:
        return '#fff';
    }
  };

  const containerStyles = useMemo(() => {
    const defaultStyle = { position: 'absolute' };

    if (parentStyles) {
      return {
        ...defaultStyle,
        ...parentStyles,
      };
    }

    return {
      ...defaultStyle,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    };
  }, [parentStyles]);

  return (
    <div {...rest} className={classnames(className)} style={containerStyles}>
      <div
        className="custom-loader"
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          ...customLoaderStyles,
        }}
      >
        <svg
          version="1.1"
          id="L9"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enableBackground="new 0 0 0 0"
          xmlSpace="preserve"
          style={{
            ...style,
            position: 'absolute',
            height: size || 38,
          }}
        >
          <path
            fill={color || setColorByType()}
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="500ms"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
    </div>
  );
};

Loader.defaultProps = {
  type: 'primary',
  color: null,
  size: null,
  style: null,
  className: '',
  parentStyles: null,
  customLoaderStyles: {},
};

export default Loader;
