/* eslint-disable jsx-a11y/control-has-associated-label */
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { fontSizeOptions, fontSuggestions, fontWeightOptions } from '../../../../../constants/Font';
import { useApiRequestContext } from '../../../../../context/@v2/ApiRequestContext';
import { dispatch, useSelector } from '../../../../../store';
import { updatePageSettings } from '../../../../../store/api/client.api';
import { selectClient } from '../../../../../store/selectors/client.selector';
import { setUpperBlock } from '../../../../../store/slices/settings.slice';
import { getPageSettingsThunk } from '../../../../../store/thunks/settings.thunks';
import Button from '../../../../Button';
import Field from '../../../Form/Field';
import BlockTitle from '../BlockTitle/BlockTitle';

const GeneralSettings = () => {
  const { t } = useTranslation();
  const { data, reloadData } = useApiRequestContext();
  const settings = useSelector((state) => state?.settings?.careerPage);
  const client = useSelector(selectClient);

  const GeneralSettingsFormSchema = Yup.object().shape({
    companyWebUrl: Yup.string().nullable().required(t('error-message.company-url-mandatory')),
    browserTabTitle: Yup.string()
      .nullable()
      .max(55, t('error-message.page-title-too-long'))
      .required(t('error-message.page-title-mandatory')),
    pageDescription: Yup.string().nullable().max(160, t('error-message.page-description-too-long')),
  });

  useEffect(() => {
    dispatch(getPageSettingsThunk());
  }, []);

  const onSubmit = async (values) => {
    const finalData = {
      ...values,
      generalStyling: {
        ...values?.generalStyling,
        header: {
          ...values?.generalStyling?.header,
          family: values?.generalStyling?.header?.family?.value || values?.generalStyling?.header?.family,
          weight: values?.generalStyling?.header?.weight?.value,
          size: values?.generalStyling?.header?.size?.value,
        },
        subTitle: {
          ...values?.generalStyling?.subTitle,
          family: values?.generalStyling?.subTitle?.family?.value || values?.generalStyling?.subTitle?.family,
          weight: values?.generalStyling?.subTitle?.weight?.value,
          size: values?.generalStyling?.subTitle?.size?.value,
        },
        body: {
          ...values?.generalStyling?.body,
          family: values?.generalStyling?.body?.family?.value || values?.generalStyling?.body?.family,
          weight: values?.generalStyling?.body?.weight?.value,
          size: values?.generalStyling?.body?.size?.value,
        },
      },
    };

    await updatePageSettings(finalData);
    dispatch(setUpperBlock(null));
    await reloadData();
    data?.renderSuccessNotification();
  };

  if (settings?.pageSettings?.loading) {
    return null;
  }

  const FONT_OPTIONS = fontSuggestions.map((item) => ({
    label: item,
    value: item,
  }));

  const getFont = (fontFamily) => {
    if (settings?.pageSettings?.generalStyling?.useCustomFonts) {
      return fontFamily || '';
    }

    return FONT_OPTIONS.find((item) => {
      return fontFamily === item?.value;
    });
  };

  return (
    <div className="flex flex-col gap-y-6">
      <BlockTitle
        label={t('general.general-settings')}
        onBack={() => {
          dispatch(setUpperBlock(null));
        }}
      />
      <Formik
        initialValues={{
          ...settings?.pageSettings,
          generalStyling: {
            ...settings?.pageSettings?.generalStyling,
            primaryColorHexActive: false,
            ctaColorHexActive: false,
            header: {
              ...settings?.pageSettings?.generalStyling?.header,
              family: getFont(settings?.pageSettings?.generalStyling?.header?.family),
              weight: fontWeightOptions.find(
                (item) => item.value === settings?.pageSettings?.generalStyling?.header?.weight
              ),
              size: fontSizeOptions.find((item) => item.value === settings?.pageSettings?.generalStyling?.header?.size),
            },
            subTitle: {
              ...settings?.pageSettings?.generalStyling?.subTitle,
              family: getFont(settings?.pageSettings?.generalStyling?.subTitle?.family),
              weight: fontWeightOptions.find(
                (item) => item.value === settings?.pageSettings?.generalStyling?.subTitle?.weight
              ),
              size: fontSizeOptions.find(
                (item) => item.value === settings?.pageSettings?.generalStyling?.subTitle?.size
              ),
            },
            body: {
              ...settings?.pageSettings?.generalStyling?.body,
              family: getFont(settings?.pageSettings?.generalStyling?.body?.family),
              weight: fontWeightOptions.find(
                (item) => item.value === settings?.pageSettings?.generalStyling?.body?.weight
              ),
              size: fontSizeOptions.find((item) => item.value === settings?.pageSettings?.generalStyling?.body?.size),
            },
          },
        }}
        onSubmit={onSubmit}
        validationSchema={GeneralSettingsFormSchema}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit} className="w-full">
              <div className="title-strikethrough">
                <span>{t('general.general')}</span>
              </div>
              <Field required name="companyWebUrl" label={t('pages.company-url')} />
              <Field required name="browserTabTitle" label={t('pages.page-title')} />
              <Field required name="pageDescription" label={t('pages.meta-description')} />
              <Field
                type="switch"
                name="allowRobotsToIndexPages"
                label={t('pages.allow-pages-to-be-indexed')}
                className="allow-robots"
              />
              {values?.allowRobotsToIndexPages && (
                <div className="bg-white !p-4 rounded-sm !mb-6" data-testid="seo-preview">
                  <p className="break-all mb-0 text-[#006621]">
                    {/* @ts-ignore */}
                    {global?.appConfig?.external_protocol}
                    {/* @ts-ignore */}
                    {client.key}.{global?.appConfig?.external_domain_name}/
                  </p>
                </div>
              )}
              <div className="title-strikethrough">
                <span>{t('pages.layout')}</span>
              </div>
              <Field type="switch" name="fullWidth" label={t('pages.allow-pages-to-be-wider')} />
              <div className="flex items-center justify-between !mb-4 !bg-white !border-career-page-header-button text-black rounded-sm h-[38px] text-sm !pl-3.5">
                <span>{t('pages.primary-color')}</span>
                <button
                  type="button"
                  className="rounded-sm h-[30px] w-[30px] mr-[4px]"
                  style={{
                    backgroundColor: values?.generalStyling?.primaryColorHex,
                  }}
                  onClick={() => {
                    setFieldValue('generalStyling.primaryColorHexActive', !values.generalStyling.primaryColorHexActive);
                  }}
                />
              </div>
              {values?.generalStyling?.primaryColorHexActive && (
                <ChromePicker
                  disableAlpha
                  className="!w-full !mb-6"
                  id="primary-color"
                  color={values?.generalStyling?.primaryColorHex}
                  onChangeComplete={(color) => {
                    setFieldValue('generalStyling.primaryColorHex', color?.hex);
                  }}
                />
              )}
              <div className="flex items-center justify-between !mb-6 !bg-white !border-career-page-header-button text-black rounded-sm h-[38px] text-sm !pl-3.5">
                <span>{t('pages.cta-color')}</span>
                <button
                  type="button"
                  className="rounded-sm h-[30px] w-[30px] mr-[4px]"
                  style={{
                    backgroundColor: values?.generalStyling?.ctaColorHex,
                  }}
                  onClick={() => {
                    setFieldValue('generalStyling.ctaColorHexActive', !values.generalStyling.ctaColorHexActive);
                  }}
                />
              </div>
              {values?.generalStyling?.ctaColorHexActive && (
                <ChromePicker
                  disableAlpha
                  className="!w-full !mb-6"
                  id="cta-color"
                  color={values?.generalStyling?.ctaColorHex}
                  onChangeComplete={(color) => {
                    setFieldValue('generalStyling.ctaColorHex', color?.hex);
                  }}
                />
              )}
              <div className="title-strikethrough">
                <span>{t('settings.career.header-font')}</span>
              </div>
              <Field
                type={values?.generalStyling?.useCustomFonts ? 'text' : 'select'}
                defaultOptions={FONT_OPTIONS}
                name="generalStyling.header.family"
                label={t('career.font')}
              />
              <div className="flex justify-between gap-x-4">
                <Field
                  type="select"
                  defaultOptions={fontWeightOptions}
                  name="generalStyling.header.weight"
                  label={t('career.weight')}
                />
                <Field
                  type="select"
                  defaultOptions={fontSizeOptions}
                  name="generalStyling.header.size"
                  label={t('career.size')}
                />
              </div>
              <div className="title-strikethrough">
                <span>{t('settings.career.subtitle-font')}</span>
              </div>
              <Field
                type={values?.generalStyling?.useCustomFonts ? 'text' : 'select'}
                defaultOptions={FONT_OPTIONS}
                name="generalStyling.subTitle.family"
                label={t('career.font')}
              />
              <div className="flex justify-between gap-x-4">
                <Field
                  type="select"
                  defaultOptions={fontWeightOptions}
                  name="generalStyling.subTitle.weight"
                  label={t('career.weight')}
                />
                <Field
                  type="select"
                  defaultOptions={fontSizeOptions}
                  name="generalStyling.subTitle.size"
                  label={t('career.size')}
                />
              </div>
              <div className="title-strikethrough">
                <span>{t('settings.career.body-font')}</span>
              </div>
              <Field
                type={values?.generalStyling?.useCustomFonts ? 'text' : 'select'}
                defaultOptions={FONT_OPTIONS}
                name="generalStyling.body.family"
                label={t('career.font')}
              />
              <div className="flex justify-between gap-x-4">
                <Field
                  type="select"
                  defaultOptions={fontWeightOptions}
                  name="generalStyling.body.weight"
                  label={t('career.weight')}
                />
                <Field
                  type="select"
                  defaultOptions={fontSizeOptions}
                  name="generalStyling.body.size"
                  label={t('career.size')}
                />
              </div>
              <div className="title-strikethrough">
                <span>{t('settings.career.custom-fonts')}</span>
              </div>
              <Field
                type="switch"
                name="generalStyling.useCustomFonts"
                label={t('settings.career.add-custom-fonts-link')}
                callback={(values, state) => {
                  const defaultFont = FONT_OPTIONS[0];

                  setFieldValue('generalStyling.header.family', state ? defaultFont.value : defaultFont);
                  setFieldValue('generalStyling.subTitle.family', state ? defaultFont.value : defaultFont);
                  setFieldValue('generalStyling.body.family', state ? defaultFont.value : defaultFont);
                }}
              />
              {values?.generalStyling.useCustomFonts && <Field name="generalStyling.customFontUrl" />}
              {/* @ts-ignore */}
              <Button
                type="submit"
                color="primary"
                className="w-full !rounded-full"
                disabled={isSubmitting}
                loading={isSubmitting}
                data-testid="career-page-block-form-submit"
              >
                {t('general.submit')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default GeneralSettings;
