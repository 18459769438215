import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { domToReact } from 'html-react-parser';
import axios from 'axios';
import Icon from './@v2/Icon';
import { removeItem as removeCartItem } from '../store/slices/cart.slice';
import { setPositionsEdited } from '../store/slices/settings.slice';
import { truncateText } from '../helpers/utils';
import events from '../helpers/events';
import { useAPI } from '../context/api';
import Loader from './Loader';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../store';
import { selectClient } from '../store/selectors/client.selector';

const testId = 'cartPopover';

const CartPopover = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [positionAPI] = useAPI('position');
  const [toggle, setToggle] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const cart = useSelectorToolkit(({ cart }) => cart);
  const positionsEdited = useSelectorToolkit(({ settings }) => get(settings, 'positionsEdited')) || [];
  const activeClient = useSelectorToolkit(selectClient);
  const clientSettings = get(activeClient, 'integrationSettings') || {};

  useEffect(() => {
    events.$on('cart--toggle-popover', (toggleCart) => {
      setToggle(toggleCart);
    });

    return () => {
      events.$off('cart--toggle-popover');
    };
  }, []);

  const parseOptions = {
    replace: ({ attribs, children }) => {
      if (!attribs || !children.length) return <></>;
      if (children[0].type === 'text' && children[0].data) {
        const truncatedText = truncateText(children[0].data, 4, false);
        return <>{truncatedText}</>;
      }
      return domToReact(children, parseOptions);
    },
  };

  const removeItem = (productKey) => {
    dispatchToolkit(removeCartItem(productKey));
  };

  const publishPosition = async (existingEditedPositions) => {
    setIsPublishing(true);
    let promises = [];
    const productsWithPositions = cart.filter(({ positionId }) => positionId !== 0).map(({ positionId }) => positionId);
    const filteredPositions = existingEditedPositions.filter(({ positionId }) =>
      productsWithPositions.includes(positionId)
    );

    filteredPositions.forEach((item) => {
      promises = [...promises, positionAPI.publishPosition(item)];
    });

    const [response] = await axios.all(promises);
    if (response) {
      setIsPublishing(false);
      dispatchToolkit(setPositionsEdited([]));
    }
  };

  return (
    <Dropdown
      isOpen={toggle}
      data-testid={testId}
      toggle={() => {
        setToggle((state) => !state);
      }}
      className="mr-3"
    >
      <DropdownToggle tag="span" data-testid={`${testId}-toggle`} data-toggle="dropdown" aria-expanded={toggle}>
        <Icon name="shopping-bag" fontSize={20} />
        {!!cart.length && (
          <Badge color="success" pill className="cart-badge" data-testid={`${testId}-badge`}>
            {cart.length}
          </Badge>
        )}
      </DropdownToggle>
      <DropdownMenu right className="cart-popover mt-3">
        <DropdownItem header>
          <div className="d-flex align-items-center justify-content-between cart-header">
            {t('cart.added-to-cart')}
            <Icon
              name="times"
              onClick={() => {
                setToggle((state) => !state);
              }}
            />
          </div>
        </DropdownItem>
        <DropdownItem divider />
        <div className="cart-summary">
          {cart.length ? (
            <>
              {cart
                .map((item, index) => (
                  <div
                    className="d-flex justify-content-center flex-column cart-item"
                    data-testid={`cart-item-${index}`}
                    key={item.key}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-between cart-item-title">
                        <div className="cart-item-title-text">{truncateText(item.title, 10, false)}</div>
                        <div style={{ padding: '0 5px' }}> | </div>
                        <div>
                          {get(clientSettings, 'showShopPrices')
                            ? `${item.price} kr`
                            : t('shop.price-according-to-contract')}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end" onClick={() => removeItem(item.key)}>
                        <Icon
                          data-testid={`cart-item-${index}-delete`}
                          className="delete-product-button"
                          name="trash"
                          color="#969696"
                        />
                      </div>
                    </div>
                  </div>
                ))
                .slice(0, 4)}
            </>
          ) : (
            <p>{t('cart.no-products')}</p>
          )}
        </div>
        <DropdownItem>
          <div className="d-flex align-items-center justify-content-start">
            {cart.length > 4 ? (
              <div className="cart-total-quantity" style={{ marginBottom: 10 }}>
                {parseInt(cart.length, 10) - 4} {t('cart.more')}
              </div>
            ) : (
              ''
            )}
          </div>
          <div
            className="btn btn-rounded btn btn-outline-success btn-xs"
            disabled={!cart.length}
            style={{
              height: 'auto',
              width: '100%',
              position: 'relative',
            }}
            role="button"
            data-testid="go-to-cart"
            onClick={() => {
              if (location.pathname.includes('/positions/')) {
                events.$emit('publish-position');
                return;
              }
              if (cart.length) {
                if (positionsEdited.length) {
                  publishPosition(positionsEdited);
                }
                if (!isPublishing) history.push('/cart');
              }
            }}
          >
            {t('cart.go-to')}
            {isPublishing && (
              <Loader
                size={24}
                color="#fff"
                style={{
                  right: '-5px',
                  top: null,
                }}
              />
            )}
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default CartPopover;
