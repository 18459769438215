import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Field from '../../../../components/@v2/Form/Field';
import Button from '../../../../components/Button';
import Layout from '../../../../components/layouts/default';
import ApiRequestProvider, {
  useApiRequestContext,
} from '../../../../context/@v2/ApiRequestContext';
import { useSelector } from '../../../../store';
import { selectUser } from '../../../../store/selectors/user.selector';
import {
  createOrUpdateDepartment,
  findDepartment,
} from '../../../../store/api/departments.api';

export const DepartmentFormSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().nullable().required(t('form-validator.required')),
  });
};

const DepartmentForm = () => {
  const { t } = useTranslation();
  const { loading, data } = useApiRequestContext();
  const history = useHistory();
  const currentUser = useSelector(selectUser);
  const clientId = useMemo(() => currentUser?.clientId, [currentUser]);

  const onSubmit = async (values) => {
    try {
      const finalData = {
        ...values,
        clientId,
      };

      await createOrUpdateDepartment(finalData);

      history.push('/settings/departments');
    } catch (error) {
      throw new Error(error);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Layout
      pretitle={t('general.settings')}
      title={t('general.department')}
      data-testid="settings-department-page-action-layout"
    >
      <Formik
        initialValues={data}
        validationSchema={DepartmentFormSchema(t)}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="anim-table-delayed max-w-2xl w-full shadow-sm p-4 bg-white rounded-lg"
            >
              <Field
                required
                name="name"
                testId="settings-department-name-input"
                label={t('settings.departments.name')}
              />
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                data-testid="settings-department-page-submit-button"
              >
                {t('general.submit')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

const DepartmentFormWrapper = () => {
  const { type, id } = useParams();

  const DEFAULT_DEPARTMENT_FORM = {
    name: '',
  };

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source) => {
        if (type === 'edit') {
          const response = await findDepartment(id, {
            cancelToken: source.token,
          });

          return {
            ...DEFAULT_DEPARTMENT_FORM,
            ...response,
          };
        }

        return DEFAULT_DEPARTMENT_FORM;
      }}
    >
      <DepartmentForm />
    </ApiRequestProvider>
  );
};

export default DepartmentFormWrapper;
