export const size = {
  mobileS: '320px',
  mobile: '576px',
  tablet: '768px',
  laptop: '992px',
  desktop: '1200px'
};

export const devices = {
  xs: `(max-width: ${size.mobile})`,
  sm: `(min-width: ${size.mobileS})`,
  md: `(min-width: ${size.tablet})`,
  lg: `(min-width: ${size.laptop})`,
  xl: `(min-width: ${size.desktop})`,
};
