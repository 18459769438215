import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { CancelToken } from 'axios';
import Modal, { ModalHeader, ModalBody } from '../../Modal';
import DeleteContainer from '../../DeleteContainer';
import events from '../../../../helpers/events';

const Templates = () => {
  const { t } = useTranslation();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const [source, setSource] = useState(CancelToken.source());
  const [sending, setSending] = useState(false);
  const [name, setName] = useState('');
  const queryParams = qs.parse(search);

  const closeModal = () => {
    const queryParamsCopy = { ...queryParams };
    Reflect.deleteProperty(queryParamsCopy, 'modal');

    history.push({
      location: pathname,
      search: qs.stringify(queryParamsCopy),
    });
  };

  const onDelete = async (e) => {
    e.preventDefault();
    if (sending) return;
    setSource(CancelToken.source());
    setSending(true);

    try {
      // await userApi.deleteUser({
      //   ...createParam(),
      //   userId: queryParams.id,
      // }, {
      //   cancelToken: source.token,
      // });

      events.$emit('refresh-table--admin-users');
      closeModal();
    } catch (error) {
      // log(error);
    }

    setSending(false);
  };

  useEffect(
    () => () => {
      source.cancel();
    },
    []
  );

  useEffect(() => {
    if (queryParams.type === 'add') return;
    const str =
      typeof queryParams.item === 'string'
        ? decodeURIComponent(queryParams.item)
        : queryParams.item;
    const item = JSON.parse(str);
    setName(`${item.firstName} ${item.lastName}`);
  }, [queryParams.item, queryParams.type]);

  return (
    <Modal width={600}>
      <ModalHeader>{t('admin.users.delete-user')}</ModalHeader>
      <ModalBody>
        {queryParams.type === 'delete' && (
          <DeleteContainer
            onDelete={onDelete}
            name={name}
            params={queryParams}
            sending={sending}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default Templates;
