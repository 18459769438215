// REDUX-TOOLKIT
import { createContext } from 'react';
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook, ReactReduxContextValue } from 'react-redux';
import { configureStore as configureStoreNative, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers, AnyAction } from 'redux';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import packageInfo from '../../package.json';
import {
  auth,
  authInitialState,
  user,
  userInitialState,
  cart,
  cartInitialState,
  positions,
  positionsInitialState,
  client,
  clientInitialState,
  settings,
  settingsInitialState,
} from './slices';

const persistConfig = {
  key: `ts-store-${packageInfo.version}-${window.__COMMIT_HASH__}`,
  storage,
  blacklist: ['settings'],
};

export const rootReducer = combineReducers({
  auth,
  user,
  cart,
  positions,
  client,
  settings,
});

const initialState = {
  auth: authInitialState,
  user: userInitialState,
  cart: cartInitialState,
  positions: positionsInitialState,
  client: clientInitialState,
  settings: settingsInitialState,
};
export type RootState = typeof initialState;

export const configureStoreToolkit = () => {
  const persistedToolkitReducer = persistReducer({ ...persistConfig }, (state, action) => {
    if (action && action.type === 'RESET_APP') {
      Object.entries(localStorage)
        .map((x) => x[0])
        .filter((x) => x.substring(0, 8) === 'persist:')
        .map((x) => localStorage.removeItem(x));

      return initialState;
    }

    return rootReducer(state as RootState, action);
  });

  const storeToolkit = configureStoreNative({
    reducer: persistedToolkitReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  });

  const persistorToolkit = persistStore(storeToolkit);
  return { storeToolkit, persistorToolkit };
};

export const { persistorToolkit, storeToolkit } = configureStoreToolkit();
export const { dispatch, getState } = storeToolkit;
export type AppDispatch = typeof dispatch;

export const storeToolkitContext = createContext({} as ReactReduxContextValue<any, AnyAction>);
export const useDispatch: () => AppDispatch = createDispatchHook(storeToolkitContext);
export const useSelector: TypedUseSelectorHook<RootState> = createSelectorHook(storeToolkitContext);

if (window.Cypress) {
  window.store = storeToolkit;
}
