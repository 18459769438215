import React from 'react';

const CircularLoader = ({ color, className, size, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    height={`${size}px`}
    className={className}
    data-testid="global-circular-loader"
    {...rest}
  >
    <path
      fill={color}
      d="M73 50c0-12.7-10.3-23-23-23S27 37.3 27 50m3.9 0c0-10.5 8.5-19.1 19.1-19.1S69.1 39.5 69.1 50"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="700ms"
        from="0 50 50"
        to="360 50 50"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

CircularLoader.defaultProps = {
  color: 'rgb(24,55,96)',
  className: '',
  size: 34,
};

export default CircularLoader;
