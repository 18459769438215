import React, { useState, useEffect } from 'react';
import NotFound from '../pages/NotFound';

const WithData = ({
  error,
  children,
  beforeApi,
  api,
  setItems,
  cannotBeFoundFn,
}) => {
  const [cannotBeFound, setCannotBeFound] = useState(false);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    let beforeApiResponse = null;

    if (beforeApi) {
      beforeApiResponse = await beforeApi();

      if (!api) {
        setItems(null, beforeApiResponse);
        setLoading(false);
        return;
      }
    }

    try {
      const response = await api();
      setItems(response, beforeApiResponse);
    } catch ({ status }) {
      if (status === 404 || status === 400) {
        setCannotBeFound(true);
        if (cannotBeFoundFn) cannotBeFoundFn(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const reload = () => {
    getData();
  };

  if (cannotBeFound || error) return <NotFound />;

  return children({ loading, reload });
};

WithData.defaultProps = {
  top: 0,
  api: null,
  beforeApi: null,
  error: false,
};

export default WithData;
