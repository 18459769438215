import React from 'react';
import { useTranslation } from 'react-i18next';
import { TYPES } from '../../../../../constants/CareerPage';
import { useApiRequestContext } from '../../../../../context/@v2/ApiRequestContext';
import BlockLayout from '../../BlockLayout';
import HeaderForm from './HeaderForm';

const HeaderBlock = () => {
  const { t } = useTranslation();
  const { data } = useApiRequestContext();
  const details = data?.details || {};
  const blocks = details?.blocks || [];
  const header = blocks?.find((item) => item?.type === TYPES.HEADER);

  if (!header) {
    return null;
  }

  return (
    <BlockLayout label={t('pages.header')} item={header} testId="career-page-block-header">
      <HeaderForm item={header} />
    </BlockLayout>
  );
};

export default HeaderBlock;
