/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import parse, { domToReact } from 'html-react-parser';
import { NotificationManager } from 'react-notifications';
import classNames from 'classnames';
import CustomButton from '../Button';
import '../../assets/sass/candidates/comments.sass';
import { useAPI } from '../../context/api';
import { log } from '../../helpers/utils';
import { selectUser } from '../../store/selectors/user.selector';
import { selectPosition } from '../../store/selectors/settings.selector';
import { useSelector as useSelectorToolkit } from '../../store';
import { updateComment } from '../../store/api/candidates.api';
import WysiwygEditor from '../wysiwyg/WysiwygEditor';

const CommentForm = ({ id, entry, candidates, selectAll, unchecked, filters, callback, isEdit }) => {
  const position = useSelectorToolkit(selectPosition);
  const { userId } = useSelectorToolkit(selectUser);
  const selected = useMemo(() => {
    if (!candidates) return [];
    return candidates.map((item) => {
      if (item && item.id) {
        return item.id;
      }

      return item;
    });
  }, [candidates]);
  const [valueMentions, setValueMentions] = useState('');
  const [positionAPI, bulkAPI] = useAPI('position', 'bulk');
  const [valueString, setValueString] = useState(entry.extraText || '');
  const [reset, setReset] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const { t } = useTranslation();

  const convertMentions = (stringValue, returnOnlyNames = true) => {
    const mentions = typeof stringValue === 'string' ? stringValue.match(/@mention\[(.*?)\]/g) : [];

    if (mentions && mentions.length) {
      let newText = stringValue;

      mentions.forEach((word) => {
        const parsedWord = word.match(/\[(.*?)\]/g)[0];
        const [id, name] = parsedWord.replace('[', '').replace(']', '').split(',');
        if (returnOnlyNames === true) {
          newText = newText.replace(word, name.trim());
        } else {
          newText = newText.replace(word, id.trim());
        }
      });

      return newText;
    }

    return '';
  };

  const rawText = useMemo(() => convertMentions(valueString), [valueString]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isSending) return;

    try {
      setIsSending(true);

      const payload = {
        ...entry,
        selectAll,
        rawText,
        valueString,
        valueMentions,
        candidates: selected,
        comment: valueString,
      };

      if (selectAll) {
        let payloadFilters = [];

        Object.keys(filters).forEach((key) => {
          if (filters[key]) {
            payloadFilters = [...payloadFilters, `${key}==${filters[key]}`];
          }
        });

        payload.filters = payloadFilters.join(',');

        if (unchecked.length) {
          payload.unchecked = unchecked.map((item) => item.id);
        }
      } else {
        payload.candidates = selected;
      }

      if (isEdit) {
        await updateComment({
          ...entry,
          userId,
          candidateHistoryEntryId: entry.id,
          valueString,
          valueMentions,
          candidateId: selected[0],
          editCount: 1,
          editedDateStamp: moment().utc().format(),
          editUserId: userId,
          deleteUserId: null,
        });
      } else {
        await bulkAPI.comment(payload);
      }

      NotificationManager.success(t('email.commented-successfully'));

      if (selected.length === 1) {
        callback(selected[0]);
      }
      setValueString('');
      setReset(new Date().getTime());
    } catch (error) {
      log(error);
    } finally {
      setIsSending(false);
    }
  };

  const parseOptions = {
    replace: ({ attribs, children, data }) => {
      if (data) return <>{data}</>;
      if (attribs.class === 'badge-mention') {
        return <>{`@mention[${attribs['data-id']},${children[0].data}]`}</>;
      }
      return domToReact(children, parseOptions);
    },
  };

  useEffect(() => {
    if (entry) {
      const parsedText = parse(entry?.extraText || '', parseOptions);

      const defaultString = Array.isArray(parsedText)
        ? parsedText
            .map(({ props }) => {
              if (props.children) {
                return props.children;
              }
              return '';
            })
            .join('')
        : parsedText.props.children;
      setValueString(defaultString);
      setValueMentions(convertMentions(defaultString, false));
    }
  }, [entry, setValueString]);

  const WysiwygEditorMemoized = useMemo(
    () => (
      <WysiwygEditor
        key={reset}
        defaultValue={valueString}
        onEditorStateChange={(content) => setValueString(content)}
        toolbar="comment"
        placeholder={t('candidates.comment-dot-dot-dot')}
        className="comment-form--text-area__control"
        onChange={setValueString}
        apiFunction={async (search) => {
          if (position.positionId) {
            const response = await positionAPI.mentionSearch(position.positionId, search);
            const mentions = response.map((item) => {
              item.value = `${item.firstName} ${item.lastName}`;
              return item;
            });
            return mentions;
          }

          return [];
        }}
        onMentionAdd={(id) => {
          if (valueMentions === '') {
            setValueMentions(id);
          } else {
            setValueMentions(`${valueMentions},${id}`);
          }
        }}
      />
    ),
    [reset]
  );

  return (
    <div className="comment-area">
      <form
        data-testid={`candidates-comment-${id}-form`}
        id={`comment-form-${id}`}
        className={classNames('comment-form', id !== 'main' && 'w-full p-0 mt-4 mb-2')}
        onSubmit={onSubmit}
      >
        {WysiwygEditorMemoized}
        <div className="d-flex justify-content-end">
          <CustomButton
            data-testid={`candidates-comment-${id}-button`}
            id={`comment-button-save-${id}`}
            color="success"
            loading={isSending}
            type="submit"
          >
            {t('candidates.comment.save-comment')}
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

CommentForm.propTypes = {
  id: PropTypes.string,
  entry: PropTypes.object,
  candidates: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectAll: PropTypes.bool.isRequired,
  unchecked: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  callback: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

CommentForm.defaultProps = {
  id: 'main',
  entry: {},
  isEdit: false,
};

export default CommentForm;
