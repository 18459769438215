import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AVAILABLE_BLOCKS, defaultBlock, TYPES } from '../../../constants/CareerPage';
import { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import { dispatch, useSelector } from '../../../store';
import { onBlockUpdate } from '../../../store/api/career-page.api';
import { setIsAddingBlock } from '../../../store/slices/settings.slice';
import { selectClientConfig } from '../../../store/selectors/client.selector';
import Icon from '../Icon';
import Loader from '../../Loader';
import BlockTitle from './Blocks/BlockTitle/BlockTitle';

const BlockSelector = () => {
  const { t } = useTranslation();
  const activeClient = useSelector(selectClientConfig);
  const { data, reloadData } = useApiRequestContext();
  const [blockType, setBlockType] = useState(null);
  const havePositionBlock = useMemo(() => {
    return !!data?.details?.blocks?.find((item) => item?.type === TYPES.POSITIONS);
  }, [data?.details?.blocks]);

  const disableSubAccountPositionBlock = useMemo(() => {
    const hasSubAccountPosition = !!data?.details?.blocks?.find((item) => item?.type === TYPES.SUB_ACCOUNT_POSITIONS);
    // @ts-ignore
    const isNotMainAccountInAnotherClient = !activeClient?.isMainClient;
    return hasSubAccountPosition || isNotMainAccountInAnotherClient;
  }, [data?.details?.blocks]);

  const blockSelection = AVAILABLE_BLOCKS(t, havePositionBlock, disableSubAccountPositionBlock) || [];

  const onCreateBlock = async (type) => {
    await onBlockUpdate(
      data?.details,
      {
        ...defaultBlock,
        type,
        sortOrder: data?.details?.blocks?.length + 1,
      },
      async () => {
        dispatch(setIsAddingBlock(false));
        await reloadData();
        data?.renderSuccessNotification();
      }
    );
  };

  return (
    <div className="flex flex-col gap-y-6">
      <BlockTitle
        label={t('pages.add-block')}
        onBack={() => {
          dispatch(setIsAddingBlock(false));
        }}
      />
      <div className="grid grid-cols-2 gap-6">
        {blockSelection?.map(({ label, value, icon, iconType, disabled, hidden }, index) => {
          if (hidden) return null;
          return (
            <button
              type="button"
              key={value}
              className={classNames(
                'career-page-settings-block flex items-center justify-center flex-col animate-fade-up opacity-0 text-black h-[130px] rounded-lg bg-white',
                disabled && '!bg-opacity-50'
              )}
              onClick={() => {
                if (disabled && !!blockType) return;
                setBlockType(value);
                onCreateBlock(value);
              }}
              style={{
                animationDelay: `${0.1 + 0.12 * (index + 1)}s`,
              }}
              disabled={disabled}
              data-testid={`add-block-${index}-button`}
            >
              <div className="h-[30px] flex items-center justify-center !mb-3">
                {value !== blockType && <Icon name={icon} type={iconType || 'fal'} fontSize={24} />}
                {/* @ts-ignore */}
                {value === blockType && <Loader color="black" size={50} style={{ marginTop: -35 }} />}
              </div>
              <span className="text-black">{label}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default BlockSelector;
