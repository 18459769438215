import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { getImageServiceMediaUrl } from '../../../../../helpers/image';
import { addSortOrder } from '../../../../../helpers/utils';
import Icon from '../../../Icon';
import Field from '../../../Form/Field';

const InformationItem = (props) => {
  const { t } = useTranslation();
  const { item, dragHandleProps, commonProps } = props;
  const [isEditing, setIsEditing] = useState(!!item?.isEditing);
  const index = item?.index;
  const image = item?.imageUpload ? item?.image : getImageServiceMediaUrl(item?.image);

  const onDrop = useCallback((files) => {
    const reader = new FileReader();

    reader.onload = () => {
      commonProps.setFieldValue(`informationItems[${index}].image`, reader.result);
      commonProps.setFieldValue(`informationItems[${index}].imageUpload`, files[0]);
    };
    reader.readAsDataURL(files[0]);
  }, []);

  useEffect(() => {
    setIsEditing(item?.isEditing);
  }, [item?.isEditing]);

  return (
    <div className="flex flex-col gap-y-4 bg-white rounded-sm" data-testid="information-item">
      <div className="flex items-center justify-between !px-4 py-[3px]">
        <div className="flex items-center flex-grow gap-x-2 truncate">
          <button type="button" {...dragHandleProps}>
            <Icon name="grip-vertical" fontSize={12} />
          </button>
          <div
            className={classNames(
              'w-[30px] min-w-[30px] h-[30px] min-h-[30px] overflow-hidden bg-career-page-light',
              item?.roundImage ? 'rounded-full' : 'rounded-sm'
            )}
          >
            <img src={image} className="object-cover object-center h-full" alt="" />
          </div>
          <p className="text-xs mb-0 truncate">{item?.title}</p>
        </div>
        <div className="flex items-center gap-x-2">
          <button
            type="button"
            onClick={() => {
              setIsEditing(!isEditing);
            }}
          >
            <Icon name="edit" color="var(--text-color-secondary)" fontSize={12} />
          </button>
          <button
            type="button"
            onClick={() => {
              commonProps.setFieldValue(
                'informationItems',
                addSortOrder(
                  commonProps.informationItems.filter((informationItem) => informationItem.sortOrder !== item.sortOrder)
                ).map((informationItem, index) => ({
                  ...informationItem,
                  index,
                }))
              );
            }}
          >
            <Icon name="trash" color="var(--text-color-secondary)" fontSize={12} />
          </button>
        </div>
      </div>
      {isEditing && (
        <div className="!px-4">
          <div className="flex justify-center !mb-4">
            <Dropzone
              maxFiles={1}
              multiple={false}
              onDrop={(files) => {
                onDrop(files);
              }}
              accept="image/png, image/jpeg, image/jpg, image/webp, image/gif"
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <div
                    className="grid place-items-center h-[180px] w-[180px] overflow-hidden bg-upload-gray"
                    style={{
                      borderRadius: item?.roundImage ? 99999 : 3,
                    }}
                  >
                    {image && <img src={image} className="object-cover object-center h-full" alt="" />}
                    {!image && <Icon name="upload" color="var(--text-color-secondary)" size="24px" />}
                    <input {...getInputProps()} data-testid="information-item-image-input" />
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
          <Field type="switch" name={`informationItems[${index}].roundImage`} label={t('pages.round-image')} />
          <Field type="switch" name={`informationItems[${index}].externalLink`} label={t('general.external-link')} />
          <Field name={`informationItems[${index}].title`} label={t('career-page.title')} />
          <Field name={`informationItems[${index}].subtitle`} label={t('career-page.subtitle')} />
          {item?.externalLink ? (
            <Field
              name={`informationItems[${index}].link`}
              label={t('general.external-link')}
              placeholder={`${global?.appConfig?.external_protocol}${global?.appConfig?.external_domain_name}/information-items/title`}
            />
          ) : (
            <Field
              dark
              id={`wysiwyg-${item?.uuid}`}
              type="wysiwyg"
              toolbar="career-text-image"
              placeholder={t('general.write-description')}
              name={`informationItems[${index}].description`}
              label={t('general.description')}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default InformationItem;
