import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { get, isNumber } from 'lodash';
import { Input, CustomInput } from 'reactstrap';
import Layout from '../../../components/layouts/default';
import WithData from '../../../components/WithData';
import Form from '../../../components/form/Form';
import ScreeningQuestionsType from '../../../constants/ScreeningQuestionsType';
import RadioButton from '../../../components/input/RadioButton';
import RangeSlider from '../../../components/input/RangeSlider';
import { useAPI } from '../../../context/api';
import { log } from '../../../helpers/utils';

const SettingsScreeningQuestionController = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { type, id } = useParams();
  const [screeningCriteriaAPI] = useAPI('screeningCriteriaV2');
  const [formDefaults, setFormDefaults] = useState({
    name: '',
    screeningCriteriaTypeId: 1,
    type: 'checkbox',
    archived: false,
    mandatory: false,
    screeningCriteriaId: 0,
    selections: [],
    multiselection: [],
    sliderMax: 10,
    sliderMin: 0,
  });
  const [value, setValue] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);

  const getOptions = async () => {
    try {
      const response = await screeningCriteriaAPI.getTypes();
      setTypeOptions([
        ...response.map((item) => ({
          ...item,
          value: item.type,
          label: t(`screening-types.${item.type}`),
        })),
      ]);
    } catch (e) {
      log('error', e);
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  const onSubmit = async (values) => {
    if (isSending) return;

    try {
      setIsSending(true);
      if (values.type === ScreeningQuestionsType.MULTI_SELECT) {
        values.selections = values.multiselection.filter((item) => item.name);
      }

      if (values.type === ScreeningQuestionsType.SELECTIONS_TYPE) {
        values.selections = values.selections.filter((item) => item.name);
      }

      await screeningCriteriaAPI.create(values);
      history.push('/settings/screening-questions');
    } catch (error) {
      log(error);
    }
  };

  return (
    <Layout pretitle={t('general.settings')} title={t('general.screening-questions')}>
      <WithData
        error={type === 'edit' && !id}
        api={type === 'edit' && id ? () => {} : null}
        setItems={(response) => {
          setFormDefaults({
            ...formDefaults,
            ...response,
          });
        }}
      >
        {({ loading }) => (
          <Form
            resourceName="screening-questions"
            card
            onSubmit={onSubmit}
            className="anim-table-delayed"
            defaultValues={formDefaults}
            button={{ loading: isSending }}
            maxWidth={600}
            inputs={[
              {
                key: 'name',
                label: t('general.headline'),
                rules: {
                  required: true,
                },
              },
              {
                key: 'screeningCriteriaTypeId',
                label: t('general.type'),
                type: 'component',
                customComponent: ({ form, setForm }) => (
                  <div>
                    {typeOptions.length &&
                      typeOptions.map((item, index) => (
                        <RadioButton
                          {...item}
                          isSelected={item.value === form.type}
                          onChange={(e) => {
                            const screeningType = get(e, 'target.value');
                            setForm({
                              ...form,
                              type: screeningType,
                              screeningCriteriaTypeId: item.screeningCriteriaTypeId,
                            });
                          }}
                          key={item.value}
                          data-testid={`screening-questions-type-radio-${index}`}
                        />
                      ))}
                  </div>
                ),
              },
              {
                key: 'settings',
                type: 'component',
                rules: {
                  showFn: (form) =>
                    form.type &&
                    form.type !== ScreeningQuestionsType.CHECKBOX_TYPE &&
                    form.type !== ScreeningQuestionsType.YEARS_TYPE,
                },
                customComponent: () => <h2 className="mb-0 mt-3">{t('general.settings')}</h2>,
              },
              {
                key: 'sliderMin',
                label: t('general.lowest-value'),
                type: 'number',
                rules: {
                  showFn: (form) => form.type === ScreeningQuestionsType.SLIDER_TYPE,
                },
              },
              {
                key: 'sliderMax',
                label: t('general.highest-value'),
                type: 'number',
                rules: {
                  showFn: (form) => form.type === ScreeningQuestionsType.SLIDER_TYPE,
                  custom: ({ sliderMin, sliderMax }) => sliderMax < sliderMin,
                },
                errors: {
                  custom: t('error-message.the-highest-value-cannot-be-lower-than-lowest'),
                },
              },
              {
                key: 'selections',
                label: t('general.selection'),
                type: 'selections',
                rules: {
                  showFn: (form) => form.type === ScreeningQuestionsType.SELECTIONS_TYPE,
                  custom: (form) =>
                    form.type === ScreeningQuestionsType.SELECTIONS_TYPE &&
                    form.selections.filter((item) => item.name).length < 2,
                },
                onAddDefaultValues: {
                  screeningCriteriaId: 0,
                  screeningCriteriaSelectionId: 0,
                },
                errors: {
                  custom: t('error-message.at-least-two-selection-must-be-added'),
                },
              },
              {
                key: 'multiselection',
                label: t('general.multiselection'),
                type: 'multiselection',
                rules: {
                  showFn: (form) => form.type === ScreeningQuestionsType.MULTI_SELECT,
                  custom: (form) =>
                    form.type === ScreeningQuestionsType.MULTI_SELECT &&
                    form.multiselection.filter((item) => item.name).length < 2,
                },
                onAddDefaultValues: {
                  screeningCriteriaId: 0,
                  screeningCriteriaSelectionId: 0,
                },
                errors: {
                  custom: t('error-message.at-least-two-selection-must-be-added'),
                },
              },
              {
                key: 'mandatory',
                label: t('general.mandatory'),
                type: 'switch',
                rules: {
                  showFn: (form) =>
                    form.type === ScreeningQuestionsType.TEXT_TYPE || form.type === ScreeningQuestionsType.VIDEO_TYPE,
                },
              },
              {
                key: 'preview',
                type: 'component',
                customComponent: ({ form }) => {
                  if (!form.type) {
                    return null;
                  }

                  return (
                    <div className="px-4 pt-3 pb-4 bg-gray-100 rounded-lg">
                      <h2 className="mt-3">{t('general.preview')}</h2>
                      <h4 className="my-3">{form.name || t('general.headline')}</h4>
                      {form.type === ScreeningQuestionsType.CHECKBOX_TYPE && (
                        <>
                          <RadioButton
                            label={t('general.yes')}
                            value="fake-value--1"
                            isSelected={value === 'fake-value--1'}
                            onChange={() => {
                              setValue('fake-value--1');
                            }}
                            key="fake-value--1"
                            data-testid="screening-questions-preview-checkbox-0"
                          />
                          <RadioButton
                            label={t('general.no')}
                            value="fake-value--0"
                            isSelected={value === 'fake-value--0'}
                            onChange={() => {
                              setValue('fake-value--0');
                            }}
                            key="fake-value--0"
                            data-testid="screening-questions-preview-checkbox-1"
                          />
                        </>
                      )}
                      {(form.type === ScreeningQuestionsType.YEARS_TYPE ||
                        form.type === ScreeningQuestionsType.SLIDER_TYPE) && (
                        <RangeSlider
                          value={isNumber(value) ? value : 0}
                          min={form.type === ScreeningQuestionsType.SLIDER_TYPE ? form.sliderMin || 0 : 0}
                          max={form.type === ScreeningQuestionsType.SLIDER_TYPE ? form.sliderMax || 10 : 10}
                          onChange={(state) => {
                            setValue(state);
                          }}
                          className="ml-2 mr-2 mt-0 mb-0"
                          data-testid="screening-questions-preview-range-slider"
                        />
                      )}
                      {form.type === ScreeningQuestionsType.SELECTIONS_TYPE && (
                        <>
                          {form.selections.map((item, index) => {
                            if (!item.name) return null;

                            return (
                              <RadioButton
                                label={item.name}
                                value={`${form.type}--${item.sortOrder}`}
                                isSelected={value === `${form.type}--${item.sortOrder}`}
                                onChange={() => {
                                  setValue(`${form.type}--${item.sortOrder}`);
                                }}
                                key={`${form.type}--${item.sortOrder}`}
                                data-testid={`screening-questions-preview-radio-${index}`}
                              />
                            );
                          })}
                        </>
                      )}
                      {form.type === ScreeningQuestionsType.MULTI_SELECT && (
                        <>
                          {form.multiselection.map((item, index) => {
                            if (!item.name) return null;

                            return (
                              <RadioButton
                                label={item.name}
                                value={`${form.type}--${item.sortOrder}`}
                                isSelected={value === `${form.type}--${item.sortOrder}`}
                                onChange={() => {
                                  setValue(`${form.type}--${item.sortOrder}`);
                                }}
                                key={`${form.type}--${item.sortOrder}`}
                                data-testid={`screening-questions-preview-radio-${index}`}
                              />
                            );
                          })}
                        </>
                      )}
                      {form.type === ScreeningQuestionsType.TEXT_TYPE && (
                        <Input
                          type="textarea"
                          placeholder={t('general.the-candidates-response')}
                          data-testid="screening-questions-preview-textarea"
                        />
                      )}
                      {form.type === ScreeningQuestionsType.VIDEO_TYPE && (
                        <CustomInput type="file" accept="video/mp4" data-testid="screening-questions-preview-video" />
                      )}
                    </div>
                  );
                },
              },
            ]}
            loading={loading}
          />
        )}
      </WithData>
    </Layout>
  );
};

export default SettingsScreeningQuestionController;
