import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { useLocation, useHistory } from 'react-router-dom';
import { get, uniqBy, omit } from 'lodash';
import Icon from './@v2/Icon';
import { getFormattedTotal } from '../helpers/product';
import CustomButton from './Button';
// import { useAPI } from '../context/api';
import { validate, checkout } from '../store/api/orders.api';
import { log, openModalLink } from '../helpers/utils';
import '../assets/sass/order-list.sass';
import { selectUser } from '../store/selectors/user.selector';
import { resetCart } from '../store/slices/cart.slice';
import { selectIntegrationSettings } from '../store/selectors/client.selector';
import { getUserByUserId } from '../store/thunks/user.thunks';
import { useDispatch, useSelector as useSelectorToolkit } from '../store';
import { getValidationError } from '../constants/Checkout';

const OrderList = ({ disableBackArrow, setIsValidating }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  // const [ordersAPI] = useAPI('orders');
  const testId = 'orderList';
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelectorToolkit(selectUser);
  const dispatch = useDispatch();
  const cart = useSelectorToolkit(({ cart }) => cart);
  const clientSettings = useSelectorToolkit(selectIntegrationSettings);

  const canContinueShopping = useMemo(() => {
    if (cart.length && uniqBy(cart, 'positionId').length === 1) {
      return true;
    }
    return false;
  }, [cart]);

  const activePosition = useMemo(() => {
    if (cart && cart.length) {
      return cart[0].positionId;
    }
    return null;
  }, [cart]);

  const [reference, setReference] = useState('');

  const getUserById = async (id) => {
    try {
      const response = await dispatch(getUserByUserId(id)).unwrap();
      if (response) {
        setReference(
          response?.costCenter && response?.costCenter !== '' ? response.costCenter : currentUser?.costCenter
        );
      }
    } catch (error) {
      log('error', error);
    }
  };

  useEffect(() => {
    const foundRecruiter = cart.find((item) => item.recruiterId);
    getUserById(foundRecruiter && foundRecruiter.recruiterId ? foundRecruiter.recruiterId : currentUser?.userId);
  }, [cart]);

  const handleOrder = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      setIsValidating(true);
      const { clientId, userId: purchasedById } = currentUser;

      const mappedProducts = cart.map((item, idx) => ({
        sortOrder: idx + 1,
        productId: item.id,
        customFields: item.customFields
          ? omit(
              {
                ...item.customFields,
              },
              ['amsOccupationOptions', 'amsGroupOptions']
            )
          : null,
        positionId: item.availableForPosition ? item.positionId : 0,
      }));

      const orderForm = {
        clientId,
        externalReferenceId: reference || '',
        purchasedById,
        purchasedDate: new Date(),
        items: mappedProducts,
      };
      const validateResponse = await validate(orderForm);

      if (validateResponse) {
        const errorResponse = validateResponse.find((item) => item.errorKey);
        if (errorResponse) {
          NotificationManager.warning(getValidationError(errorResponse?.errorKey, t));
        } else {
          const response = await checkout(orderForm);

          if (response) {
            dispatch(resetCart());

            const foundPosition = response.items?.find(({ positionId }) => positionId);

            if (foundPosition) {
              openModalLink(
                {
                  type: 'orders',
                  modal: 'success-checkout',
                  item: '',
                  // handle existence of positionId
                  positionId: foundPosition ? foundPosition.positionId : null,
                },
                location,
                history
              );
            } else {
              history.push('/positions');
            }
          }
        }
      }
    } catch (error) {
      log('error', error);
    } finally {
      setIsLoading(false);
      setIsValidating(false);
    }
  };

  return (
    <Card data-testid={`${testId}-card`} className="anim-table-delayed order-list" style={{ width: 340 }}>
      <CardBody data-testid={`${testId}-cardBody`}>
        <form onSubmit={handleOrder}>
          <div className="d-flex align-items-center justify-content-between">
            <div
              data-testid="continue-shopping-link"
              style={{
                cursor: 'pointer',
                opacity: '.6',
              }}
              onClick={() => {
                const continueShoppingURL =
                  activePosition === 0 ? '/shop' : `/positions/edit/${activePosition}?currentView=4`;
                history.push(canContinueShopping ? continueShoppingURL : '/cart');
              }}
            >
              {!disableBackArrow && <Icon name="long-arrow-left" className="mr-2" />}{' '}
              {canContinueShopping ? t('general.continue-shopping') : t('general.edit-shopping-cart')}
            </div>
          </div>
          <div className="order-list-publish-wrapper">
            <FormGroup>
              <Label className="font-weight-bold">{t('shop.cost-center-or-reference')}</Label>
              <Input
                data-testid="order-referrence-input"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
                <div className="font-weight-bold" style={{ marginBottom: 5 }}>
                  {t('general.total')}:{' '}
                  {get(clientSettings, 'showShopPrices')
                    ? `${getFormattedTotal(cart)} kr`
                    : t('shop.price-according-to-contract')}
                </div>
                <CustomButton
                  color="success"
                  className="btn-rounded"
                  style={{ color: '#fff' }}
                  loading={isLoading}
                  data-testid="order-button"
                  loaderColor="#fff"
                  disabled={!cart.length}
                  type="submit"
                >
                  {t('general.order')}
                </CustomButton>
              </div>
            </FormGroup>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default OrderList;
