import { exportCandidates } from '../store/api/positions.api';
import { log } from './utils';

export const onExportCandidates = async (position, setIsExporting = null) => {
  try {
    if (setIsExporting) {
      setIsExporting(true);
    }

    const response = await exportCandidates(position?.id);

    const data = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const href = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = href;
    tempLink.setAttribute(
      'download',
      `${position.position || position.name}.xlsx`
    );
    tempLink.click();

    window.URL.revokeObjectURL(href);
  } catch (error) {
    log(error);
  } finally {
    if (setIsExporting) {
      setIsExporting(false);
    }
  }
};
