import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import Field from '../../../../components/@v2/Form/Field';
import Button from '../../../../components/Button';
import {
  addOrUpdateIntegration,
  toggleIntegrationsActive,
} from '../../../../store/api/client-integrations.api';
import { dispatch as dispatchToolkit, useSelector } from '../../../../store';
import { setRefappQuestionnaires } from '../../../../store/thunks/settings.thunks';
import Divider from '../../../../components/@v2/Divider/Divider';
import { getClient } from '../../../../store/thunks/client.thunk';
import { selectClient } from '../../../../store/selectors/client.selector';
import { selectUser } from '../../../../store/selectors/user.selector';
import { getError } from '../../../../helpers/utils';

const ID = 'refapp';

const RefappForm = ({
  initialValues = {
    integrationKey: '',
    type: ID,
    isActive: false,
  },
}) => {
  const { id: clientId } = useSelector(selectClient);
  const { superAdmin } = useSelector(selectUser);
  const { t } = useTranslation();
  const history = useHistory();
  const isEditing = !!initialValues?.id;
  const [isIntegrationKeyEditable, setIsIntegrationKeyEditable] =
    useState(false);
  const [error, setError] = useState('');

  const RefappFormSchema = (t) => {
    return Yup.object().shape({
      [isEditing ? 'editedIntegrationKey' : 'integrationKey']: Yup.string()
        .nullable()
        .required(t('form-validator.required')),
    });
  };

  const handleEditableKey = () => {
    setIsIntegrationKeyEditable(!isIntegrationKeyEditable);
  };

  const onSubmit = async (values) => {
    const configModel = {
      isActive: values?.isActive,
      integrationKey: values?.integrationKey,
    };

    const finalData = {
      configModel,
      type: values?.type?.toLowerCase() || ID,
    };

    if (values?.id) {
      finalData.id = values?.id;
    }

    if (values?.type) {
      finalData.type = values?.type;
    }

    try {
      if (values?.id && initialValues?.isActive !== values?.isActive) {
        await toggleIntegrationsActive(
          ID,
          values?.isActive ? 'activate' : 'deactivate'
        );
      }
      await addOrUpdateIntegration(finalData);
      await getClient({
        superAdmin,
        id: clientId,
      });

      dispatchToolkit(setRefappQuestionnaires(finalData));

      if (isEditing) {
        history.push('/settings/integrations');

        return;
      }

      history.push(`/settings/integrations/edit/${ID}`);
    } catch (error) {
      const errorMsg = getError(error);
      // TODO: display actual translated error message once feature from BE for setting error keys is finished
      setError(errorMsg ? t('error-message.something-went-wrong') : '');
      throw error;
    }
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        editedIntegrationKey: initialValues?.integrationKey,
      }}
      validationSchema={RefappFormSchema(t)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            {!!error && (
              <Alert color="danger" className="mb-4">
                {error}
              </Alert>
            )}
            {isEditing && (
              <>
                <div className="flex items-center justify-between mb-5">
                  <img
                    className="logo w-[150px]"
                    src={`${global?.appConfig?.public_url}/img/integrations/${ID}.png`}
                    alt="logo"
                  />
                  <Field
                    name="isActive"
                    label={t('general.active')}
                    type="switch"
                    className="!w-auto !mb-0"
                  />
                </div>
                <Divider>
                  {t('settings.integrations.acceptance-threshold')}
                </Divider>
              </>
            )}
            <Field
              name={isEditing ? 'editedIntegrationKey' : 'integrationKey'}
              label={t('settings.integrations.provider-key')}
              disabled={isEditing ? !isIntegrationKeyEditable : false}
              inputGroupComponent={
                isEditing ? (
                  <Button
                    color="primary"
                    className="w-[100px]"
                    onClick={() => {
                      handleEditableKey();
                      setFieldValue(
                        'integrationKey',
                        values?.editedIntegrationKey
                      );
                    }}
                    disabled={!isEditing}
                  >
                    {!isIntegrationKeyEditable
                      ? t('general.edit')
                      : t('general.confirm')}
                  </Button>
                ) : null
              }
              inputGroupClassName={
                isEditing ? 'flex items-center gap-x-4' : null
              }
              required
            />
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="settings-integration-submit-button"
            >
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default RefappForm;
