import React, { useEffect, useState } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import CustomButton from '../Button';

const DeleteContainer = ({ onDelete, name, params, sending }) => {
  const [textName, setTextName] = useState(name);
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (name) setTextName(name);
  }, [name]);

  return (
    <Form onSubmit={onDelete}>
      <FormGroup>
        <p className="text-center">
          {t('modal.container.are-you-sure-delete')}:
          <span className="font-weight-bold !ml-1">{textName || ''}</span>?
        </p>
      </FormGroup>
      <div className="flex items-center justify-center gap-x-2">
        <CustomButton
          type="submit"
          color="danger"
          outline
          className="!rounded-full !px-6 !py-4 !h-auto"
          loading={sending}
          data-testid="modal-delete-submit"
        >
          {t('general.delete')}
        </CustomButton>
        <CustomButton
          type="button"
          color="light"
          outline
          className="!rounded-full !px-6 !py-4 !h-auto"
          onClick={() => {
            const queryParamsCopy = { ...params };
            Reflect.deleteProperty(queryParamsCopy, 'modal');
            Reflect.deleteProperty(queryParamsCopy, 'id');
            Reflect.deleteProperty(queryParamsCopy, 'name');
            Reflect.deleteProperty(queryParamsCopy, 'params');

            history.push({
              location: pathname,
              search: qs.stringify(queryParamsCopy),
            });
          }}
          data-testid="modal-delete-cancel"
        >
          {t('general.cancel')}
        </CustomButton>
      </div>
    </Form>
  );
};

export default DeleteContainer;
