import qs from 'query-string';
import ResponseListItem from '@ts/apicontracts/orders/search/response-list-item';
import SearchOrdersQuery from '@ts/apicontracts/orders/search/search-orders-query';
import OrderItem from '@ts/apicontracts/orders/order-item';
import CheckoutOrderCommand from '@ts/apicontracts/orders/checkout-order-command';
import PreValidateOrderCheckoutCommand from '@ts/apicontracts/orders/pre-validate-order-checkout-command';
import { AxiosResponse } from 'axios';

import { api } from '../../helpers/api-v2';

const resource = '/orders';

export const getList = async (queryParams: SearchOrdersQuery) => {
  const orders = await api.get(`${resource}?${qs.stringify(queryParams || {})}`);
  return orders as AxiosResponse<ResponseListItem>;
};

export const addOrder = async (values: OrderItem) => {
  try {
    await api.post(`${resource}/Add`, values);
  } catch (error) {
    throw error;
  }
};

export const checkout = async (values: CheckoutOrderCommand) => {
  try {
    const response = await api.post(`${resource}/Checkout`, values);
    return response as AxiosResponse<OrderItem>;
  } catch (error) {
    throw error;
  }
};

export const retry = async (id: string) => {
  try {
    await api.post(`${resource}/${id}/reprocess`);
  } catch (error) {
    throw error;
  }
};

export const getLog = async ({ orderId, orderItemId }: { orderId: string; orderItemId: string }) => {
  const orders = await api.get(`${resource}/${orderId}/items/${orderItemId}/logs`);
  return orders as AxiosResponse<unknown>;
};

export const validate = async (values: PreValidateOrderCheckoutCommand) => {
  try {
    const isValid = await api.post(`${resource}/checkout/validate`, values);
    return isValid as AxiosResponse<boolean>;
  } catch (error) {
    throw error;
  }
};
