import React from 'react';
import Icon from './@v2/Icon';

const Checkbox = ({ checked, readOnly, onClick, ...rest }) => {
  let dataTestId = 'checkbox-component';

  if (rest.testId) {
    dataTestId = `${rest.testId}-checkbox-component`;
  }

  return (
    <div
      className="checkbox"
      data-testid={dataTestId}
      onClick={() => {
        if (!readOnly) {
          if (onClick) onClick(!checked);
        }
      }}
      style={{
        ...rest.style,
        cursor: readOnly ? null : 'pointer',
      }}
    >
      {checked ? (
        <Icon name="check-square" type="fas" />
      ) : (
        <Icon name="square" />
      )}
    </div>
  );
};

Checkbox.defaultProps = {
  checked: false,
  readOnly: false,
  onClick: () => {},
  style: {},
};

export default Checkbox;
