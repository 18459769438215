import React from 'react';
import { useFormik, Formik } from 'formik';
import { Collapse, ListGroup, Input, Label, CustomInput, Row, Col, FormGroup } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { kebabCase, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Icon from '../../@v2/Icon';
import RangeSlider from '../../input/RangeSlider';
import RadioButton from '../../input/RadioButton';
import Select from '../../input/Select';
import ScreeningCriteria from './ScreeningCriteria';
import Category from './Category';
import SelectV2 from '../../@v2/Select/Select';
import Divider from '../../@v2/Divider/Divider';
import Field from '../../@v2/Form/Field';
import QuestionTypes from '../../../constants/ScreeningQuestionsType';
import { displaySectionLabel, questionJson } from '../../../helpers/form';
import { addSortOrder } from '../../../helpers/utils';
import { useSelector as useSelectorToolkit } from '../../../store';
import { transformApplySettingsToFormCompliantFormat } from '../../../helpers/apply-settings';
import { selectClientConfig } from '../../../store/selectors/client.selector';
import ApplySettings, { ShowHideOptions } from '../../../constants/ApplySettings';

const resourceName = 'settings-position-template-page-screening';

export const ScreeningForm = ({
  initialValues,
  setFormDefaults,
  addedQuestions,
  parsedQuestions,
  parsedQuestionsOptions,
  getQuestions,
  previewQuestion,
  setPreviewQuestion,
  editorTemplates,
  users,
}) => {
  const { t } = useTranslation('');
  const { applyFormSettings } = useSelectorToolkit(selectClientConfig);
  const applySettingsInitialValues = transformApplySettingsToFormCompliantFormat(
    initialValues.applySettings,
    [...ApplySettings, ...ShowHideOptions],
    applyFormSettings
  );
  const setKey = (key, value) => {
    setFormDefaults((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const onDragQuestionEnd = ({ destination, draggableId }) => {
    if (!destination) return;
    const question = addedQuestions.find((item) => item.screeningCriteriaId.toString() === draggableId);
    const questions = [...addedQuestions].filter((item) => item.screeningCriteriaId.toString() !== draggableId);

    questions.splice(destination.index, 0, question);
    setKey('criterias', addSortOrder(questions));
  };

  const onQuestionRemove = (index) => {
    const questions = [...parsedQuestions];
    questions.splice(index, 1);

    setKey('criterias', addSortOrder(questions));
  };

  const renderRangeSlider = (question, props = {}) => {
    const { screeningCriteriaId, sliderMin: questionSliderMin, sliderMax: questionSliderMax } = question;
    const { form, setFormValues: setForm } = props;
    const value = get(form, `${screeningCriteriaId}.value`) || 0;
    const sliderMin = parseInt(get(form, `${screeningCriteriaId}.sliderMin`) || questionSliderMin || 0, 10);
    const sliderMax = parseInt(get(form, `${screeningCriteriaId}.sliderMax`) || questionSliderMax || 0, 10);

    return (
      <RangeSlider
        value={value || sliderMin || 0}
        min={sliderMin || 0}
        max={sliderMax}
        onChange={(selected) => {
          setForm({
            ...form,
            [screeningCriteriaId]: {
              ...form[screeningCriteriaId],
              value: selected,
            },
          });
        }}
        {...props}
      />
    );
  };

  const displayQuestionBuilder = (question, form, setForm) => {
    const { type, screeningCriteriaId } = question;

    const setFormValues = (values) => {
      setForm(values);
      setFormDefaults(values);
    };

    const value = get(form, `${screeningCriteriaId}.value`) || get(question, 'value');
    const sliderMin = get(form, `${screeningCriteriaId}.sliderMin`) || get(question, 'sliderMin');
    const sliderMax = get(form, `${screeningCriteriaId}.sliderMax`) || get(question, 'sliderMax');

    switch (type) {
      case QuestionTypes.YEARS_TYPE:
        return renderRangeSlider(
          question,
          {
            subText:
              value && value > 1 ? t('position.screening-criteria.years') : t('position.screening-criteria.year'),
          },
          { form, setFormValues }
        );

      case QuestionTypes.SLIDER_TYPE:
        return (
          <>
            <Label for="min">{t('position.screening-criteria.lowest-value')}</Label>
            <Input
              type="number"
              defaultValue={sliderMin}
              placeholder={t('position.screening-criteria.min')}
              onChange={(e) => {
                setFormValues({
                  ...form,
                  [screeningCriteriaId]: {
                    ...form[screeningCriteriaId],
                    sliderMin: parseInt(get(e, 'target.value') || 0, 10) || null,
                  },
                });
              }}
            />
            <Label for="max" className="mt-2">
              {t('position.screening-criteria.highest-value')}
            </Label>
            <Input
              type="number"
              defaultValue={sliderMax}
              placeholder={t('position.screening-criteria.max')}
              onChange={(e) => {
                setFormValues({
                  ...form,
                  [screeningCriteriaId]: {
                    ...form[screeningCriteriaId],
                    sliderMax: parseInt(get(e, 'target.value') || 0, 10) || null,
                  },
                });
              }}
            />
            {renderRangeSlider(question, { form, setFormValues })}
          </>
        );

      case QuestionTypes.TEXT_TYPE:
        return (
          <Input
            type="textarea"
            name="text"
            id="text"
            defaultValue={value || ''}
            onChange={(e) => {
              setFormValues({
                ...form,
                [screeningCriteriaId]: {
                  ...form[screeningCriteriaId],
                  value: get(e, 'target.value') || '',
                },
              });
            }}
            style={{ height: 150 }}
            placeholder={t('edit-position.insert-text')}
            className="mb-2"
          />
        );

      case QuestionTypes.SELECTIONS_TYPE:
        return (
          <Select
            options={
              get(form, `${screeningCriteriaId}.selections`)
                ? get(form, `${screeningCriteriaId}.selections`).map((item) => ({
                    ...item,
                    label: item.label.replace('Must be chosen', ''),
                  }))
                : question?.selections.map((item) => ({
                    ...item,
                    label: item.name,
                  })) || []
            }
            className="mb-2"
          />
        );

      case QuestionTypes.MULTI_SELECT:
        return (
          <Select
            multiple
            options={
              get(form, `${screeningCriteriaId}.selections`)
                ? get(form, `${screeningCriteriaId}.selections`).map((item) => ({
                    ...item,
                    label: item.label.replace('Must be chosen', ''),
                  }))
                : question?.selections.map((item) => ({
                    ...item,
                    label: item.name,
                  })) || []
            }
            className="mb-2"
          />
        );

      case QuestionTypes.CHECKBOX_TYPE:
        return [
          {
            value: 'yes',
            label: t('general.yes'),
          },
          {
            value: 'no',
            label: t('general.no'),
          },
        ].map((item) => (
          <RadioButton
            key={item.value}
            value={item.value}
            onChange={(e) => {
              if (!get(e, 'target.value')) return;

              setFormValues({
                ...form,
                [screeningCriteriaId]: {
                  ...form[screeningCriteriaId],
                  value: get(e, 'target.value'),
                },
              });
            }}
            isSelected={value === item.value}
            label={item.label}
            className=""
          />
        ));

      case QuestionTypes.VIDEO_TYPE:
        return (
          <CustomInput
            className="mt-2"
            type="file"
            accept="video/mp4"
            data-testid="screening-questions-preview-video"
          />
        );

      default:
        return t('position.screening-criteria.no-question-builder');
    }
  };

  const formik = useFormik({
    ...initialValues,
    enableReinitialize: true,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        firstName: ApplySettings[1],
        lastName: ApplySettings[1],
        email: ApplySettings[1],
        ...applySettingsInitialValues,
        usePositionSpecificApplyForm:
          initialValues?.applySettings && initialValues?.applySettings?.usePositionSpecificApplyForm
            ? initialValues?.applySettings?.usePositionSpecificApplyForm
            : false,
      }}
      validate={({
        firstName,
        lastName,
        email,
        phoneNumber,
        birthDate,
        motivation,
        cv,
        showImage,
        usePositionSpecificApplyForm,
      }) => {
        setFormDefaults({
          ...initialValues,
          applySettings: {
            firstName: firstName?.value,
            lastName: lastName?.value,
            email: email?.value,
            phoneNumber: phoneNumber?.value,
            birthDate: birthDate?.value,
            motivation: motivation?.value,
            cv: cv?.value,
            showImage: showImage?.value,
            usePositionSpecificApplyForm,
          },
        });
      }}
    >
      {({ values }) => {
        const isSpecificPositionApplyFormEnabled =
          values?.usePositionSpecificApplyForm && values?.usePositionSpecificApplyForm === true;
        return (
          <>
            <Divider>{t('general.apply-form')}</Divider>
            <div>
              <div className="grid grid-cols-1">
                <Field
                  name="usePositionSpecificApplyForm"
                  type="switch"
                  label={t('general.use-specific-apply-form')}
                  callback={(formValues, switchValue) => {
                    setFormDefaults({
                      ...formValues,
                      usePositionSpecificApplyForm: switchValue,
                    });
                  }}
                  className="font-bold"
                />
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Field
                  disabled
                  name="firstName"
                  type="select"
                  label={t('general.first-name')}
                  defaultOptions={ApplySettings}
                />
                <Field
                  disabled
                  name="lastName"
                  type="select"
                  label={t('general.last-name')}
                  defaultOptions={ApplySettings}
                />
                <Field disabled name="email" type="select" label={t('general.email')} defaultOptions={ApplySettings} />
                <Field
                  disabled={!isSpecificPositionApplyFormEnabled}
                  name="phoneNumber"
                  type="select"
                  label={t('general.phone-number')}
                  defaultOptions={ApplySettings}
                />
                <Field
                  disabled={!isSpecificPositionApplyFormEnabled}
                  name="birthDate"
                  type="select"
                  label={t('general.date-of-birth')}
                  defaultOptions={ApplySettings}
                />
                <Field
                  disabled={!isSpecificPositionApplyFormEnabled}
                  name="motivation"
                  type="select"
                  label={t('general.motivation')}
                  defaultOptions={ApplySettings}
                />
                <Field
                  disabled={!isSpecificPositionApplyFormEnabled}
                  name="cv"
                  type="select"
                  label={t('general.at-least-one-attachment-cv')}
                  defaultOptions={ApplySettings}
                />
                <Field
                  disabled={!isSpecificPositionApplyFormEnabled}
                  name="showImage"
                  type="select"
                  label={t('general.image')}
                  defaultOptions={ShowHideOptions}
                />
              </div>
            </div>
            {displaySectionLabel(t('general.screening-questions'))}
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>{t('edit-position.select-question')}</Label>
                  <SelectV2
                    searchable
                    id={`${resourceName}-${kebabCase('selectedQuestion')}`}
                    resource={`${resourceName}-${kebabCase('selectedQuestion')}`}
                    defaultOptions={parsedQuestionsOptions}
                    onSelect={(selected) => {
                      const { screeningCriteriaId, value } = selected[0];
                      const selectedQuestions = parsedQuestionsOptions.find(
                        (item) => item.screeningCriteriaId === screeningCriteriaId
                      );

                      if (!selectedQuestions) return;

                      const sortedQuestion = addSortOrder([...parsedQuestions, questionJson(value)]);
                      const updatedForm = {
                        ...initialValues,
                        selectedQuestion: selected,
                        questionsJsonString: JSON.stringify(sortedQuestion),
                        parsedQuestions: sortedQuestion,
                        criterias: sortedQuestion,
                      };
                      setFormDefaults(updatedForm);
                      formik.setValues(updatedForm);
                    }}
                    value={values?.selectedQuestion}
                    keys={['value', 'label']}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ScreeningCriteria
                    questionTypes={QuestionTypes}
                    data-testid={`${resourceName}-criteria`}
                    onSave={(response) => {
                      const sortedQuestion = addSortOrder([
                        ...parsedQuestions,
                        questionJson(response.screeningCriteriaId),
                      ]);
                      const updatedForm = {
                        ...initialValues,
                        questionsJsonString: JSON.stringify(sortedQuestion),
                        parsedQuestions: addSortOrder(sortedQuestion),
                        criterias: addSortOrder(sortedQuestion),
                      };
                      setFormDefaults(updatedForm);
                      getQuestions();
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>{t('edit-position.chosen-questions')}</Label>
                  <div className="screening-questions-draggable" data-testid={`${resourceName}-questions-draggable`}>
                    <DragDropContext onDragEnd={onDragQuestionEnd}>
                      <Droppable droppableId="questions-droppable">
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            style={{
                              marginBottom: snapshot.isDraggingOver ? 64 : 0,
                            }}
                          >
                            {addedQuestions.map((question, index) => (
                              <Draggable
                                key={question.screeningCriteriaId}
                                draggableId={question.screeningCriteriaId.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className="card mb-2 pt-2 pb-2 pl-3 pr-3"
                                  >
                                    <div
                                      {...provided.dragHandleProps}
                                      className="d-flex align-items-center justify-content-between flex-row card p-0 mb-0"
                                      style={{
                                        boxShadow: 'none',
                                        border: 'none',
                                      }}
                                    >
                                      {question.name}
                                      <div className="d-flex align-items-center">
                                        <button
                                          type="button"
                                          data-testid={`position-sq-item-${index}-eye-icon`}
                                          onClick={() => {
                                            setPreviewQuestion((state) => {
                                              if (state.includes(question.screeningCriteriaId)) {
                                                return state.filter((item) => item !== question.screeningCriteriaId);
                                              }
                                              return [...state, question.screeningCriteriaId];
                                            });
                                          }}
                                        >
                                          <Icon
                                            className="ml-2 clickable"
                                            name={
                                              previewQuestion.includes(question.screeningCriteriaId)
                                                ? 'eye-slash'
                                                : 'eye'
                                            }
                                          />
                                        </button>
                                        <button
                                          data-testid={`position-sq-item-${index}-trash-icon`}
                                          type="button"
                                          onClick={() => {
                                            onQuestionRemove(index);
                                          }}
                                        >
                                          <Icon className="ml-2 clickable" name="trash" />
                                        </button>
                                      </div>
                                    </div>
                                    <Collapse isOpen={previewQuestion.includes(question.screeningCriteriaId)}>
                                      <div className="d-flex flex-column mt-3">
                                        {displayQuestionBuilder(question, initialValues, setFormDefaults)}
                                      </div>
                                    </Collapse>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="screening-inputs">
                  {displaySectionLabel(t('general.categorizing'))}
                  <p className="font-weight-bold">{t('edit-position.automatic-categorization')}</p>
                  <p>{t('edit-position.categorizing-description1')}</p>
                  <p>{t('edit-position.categorizing-description2')}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={9}>
                <ListGroup className="category-list">
                  {[
                    {
                      type: 'A',
                      color: '#00d97e',
                      backgroundColor: '#ccf7e5',
                      stateKey: 'categorySettingsAJsonString',
                      formKey: 'categorySettingsA',
                    },
                    {
                      type: 'B',
                      color: '#bcb80b',
                      backgroundColor: '#fffb95',
                      stateKey: 'categorySettingsBJsonString',
                      formKey: 'categorySettingsB',
                    },
                    {
                      type: 'C',
                      color: '#e63757',
                      backgroundColor: '#fad7dd',
                      stateKey: 'categorySettingsCJsonString',
                      formKey: 'categorySettingsC',
                    },
                    {
                      type: 'Other',
                      backgroundColor: 'transparent',
                      stateKey: 'categorySettingsOthersJsonString',
                      formKey: 'categorySettingsOthers',
                    },
                  ].map((item) => (
                    <Category
                      key={item.stateKey}
                      state={initialValues}
                      questions={addedQuestions}
                      setState={setFormDefaults}
                      setFormDefaults={setFormDefaults}
                      parsedQuestions={parsedQuestions}
                      editorTemplates={editorTemplates}
                      users={users}
                      {...item}
                    />
                  ))}
                </ListGroup>
              </Col>
            </Row>
          </>
        );
      }}
    </Formik>
  );
};
