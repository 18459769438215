import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import Tippy, { useSingleton } from '@tippyjs/react';
import Layout from '../../../../components/layouts/default';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import Button from '../../../../components/Button';
import Icon from '../../../../components/@v2/Icon';
import Card from '../../../../components/@v2/Card';
import Table from '../../../../components/@v2/Table/Table';
import { SiteType, ClientLanguage } from '../../../../constants/Client';
import Search from '../../../../components/@v2/Search/Search';
import Paginator from '../../../../components/@v2/Paginator/Paginator';
import ClientFilter from './ClientFilter';
import { useSelector } from '../../../../store';
import { selectClientTypes } from '../../../../store/selectors/settings.selector';
import DeleteModelTooltip from '../../../../components/@v2/DeleteModelTooltip/DeleteModelTooltip';
import { deleteClient, getAdminClients } from '../../../../store/api/client.api';

const Client = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const clientTypeOptions = useSelector(selectClientTypes);
  const { loading, data, meta, setMeta, pageSize } = useApiRequestContext();
  const [source, target] = useSingleton();

  return (
    <Layout
      pretitle={t('general.admin')}
      title={t('general.clients')}
      rightContent={
        <Button
          color="primary"
          className="d-flex align-items-center"
          onClick={() => {
            history.push('/admin/clients/add');
          }}
        >
          <Icon name="plus" />
          {t('New Client')}
        </Button>
      }
      className="clients-page"
    >
      <Card className="anim-table-delayed shadow-table !border !border-line-default">
        <Tippy
          trigger="click mouseenter"
          singleton={source}
          theme="light"
          interactive
          maxWidth={200}
          appendTo={document.body}
          placement="bottom"
        />
        <Search
          placeholder={t('general.search-using', { resource: 'name' })}
          resource="search"
          meta={meta}
          setMeta={setMeta}
          filter={<ClientFilter />}
        />
        <Table
          isLoaded={!loading}
          activeSort={get(meta, 'sorts') || ''}
          items={get(data, 'items')}
          headers={[
            {
              label: t('general.name'),
              key: 'name',
            },
            {
              label: t('clients.contact'),
              key: 'contact',
              sort: false,
            },
            {
              label: t('clients.users'),
              key: 'users',
              sort: false,
            },
            {
              label: t('clients.services'),
              key: 'services',
              sort: false,
            },
            {
              label: t('clients.week'),
              key: 'week',
              sort: false,
            },
            {
              label: t('clients.last-login'),
              key: 'lastLogin',
              sort: false,
            },
            {
              label: t('general.type'),
              key: 'type',
              sort: false,
            },
            {
              label: t('clients.site-type'),
              key: 'siteType',
              sort: false,
            },
            {
              label: t('general.language'),
              key: 'language',
              sort: false,
            },
            {
              label: t('general.actions'),
              key: 'actions',
              sticky: true,
              sort: false,
              width: 100,
              headerClassName: 'text-center',
            },
          ]}
          pageSize={pageSize}
          renderBody={(item, index) => {
            const language = ClientLanguage.find((lang) => lang.value === item.language);
            const siteType = SiteType.find((type) => parseInt(type.value, 10) === item.siteType);
            const clientType = clientTypeOptions.find(({ value }) => parseInt(value, 10) === item.type);

            const getLabel = (object) => {
              if (get(object, 'label')) return get(object, 'label');

              return '-';
            };

            return (
              <tr key={item.id} id={`client-table-row--${index}`}>
                <td>{item.name}</td>
                <td>{item.contact}</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>{getLabel(clientType)}</td>
                <td>{getLabel(siteType)}</td>
                <td>{getLabel(language)}</td>
                <td className="column-sticky">
                  <div className="flex items-center justify-center gap-x-2">
                    <Tippy content={t('general.edit')} singleton={target}>
                      <button
                        type="button"
                        onClick={() => {
                          history.push(`/admin/clients/edit/${item.id}`);
                        }}
                        data-testid="client-edit-button"
                      >
                        <Icon name="edit" />
                      </button>
                    </Tippy>
                    <DeleteModelTooltip
                      singleton={target}
                      content={
                        <p>
                          {t('admin.clients.delete-client')}: {item.name}
                        </p>
                      }
                      onDelete={() => {
                        deleteClient(item.id);
                      }}
                    >
                      <button type="button">
                        <Icon name="trash" />
                      </button>
                    </DeleteModelTooltip>
                    {/* {[
                      {
                        icon: 'edit',
                        type: 'edit',
                      },
                      {
                        icon: 'trash',
                        type: 'delete',
                      },
                    ].map(({ icon, type }) => (

                    ))} */}
                  </div>
                </td>
              </tr>
            );
          }}
          setMeta={setMeta}
        />
        <div className="h-14 flex items-center bg-white">
          <Paginator
            pageCount={data?.totalCount / meta?.pageSize}
            initialPage={meta.page - 1}
            onPageChange={(value) => {
              if (meta.page !== value) {
                setMeta({
                  page: value,
                });
              }
            }}
            disableInitialCallback
          />
        </div>
      </Card>
    </Layout>
  );
};

const ClientWrapper = () => {
  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source, ...rest) => {
        const newFilters = {};

        const filters = rest?.[2] || [];

        filters.forEach((item) => {
          newFilters[item.key] = item.key === 'status' ? parseInt(item.value, 10) : item.value;
        });

        const sort = {
          by: meta?.sorts?.replace('-', ''),
          direction: meta.sorts.includes('-') ? 'desc' : 'asc',
        };

        const data = await getAdminClients(
          {
            filter: newFilters,
            page: {
              number: meta.page,
              size: meta.pageSize,
            },
            sort: meta.sorts ? sort : {},
          },
          {
            cancelToken: source?.token,
          }
        );

        return data;
      }}
    >
      <Client />
    </ApiRequestProvider>
  );
};

export default ClientWrapper;
