import candidate from '../api/candidate';
import client from '../api/client';
import pipeline from '../api/pipeline';
import user from '../api/user';
import position from '../api/position';
import products from '../api/products';
import location from '../api/location';
import mail from '../api/mail';
import screeningCriteria from '../api/screening-criteria';
import screeningCriteriaV2 from '../api/screening-criteria-v2';
import shop from '../api/shop';
import template from '../api/template';
import orders from '../api/orders';
import department from '../api/department';
import bulk from '../api/bulk';
import positionTemplate from '../api/position-template';
import calendar from '../api/calendar';
import translations from '../api/translations';

export const repositories = {
  candidate,
  client,
  calendar,
  pipeline,
  user,
  position,
  products,
  location,
  mail,
  screeningCriteria,
  screeningCriteriaV2,
  shop,
  template,
  orders,
  department,
  bulk,
  positionTemplate,
  translations,
};

/**
 * @param {string} name
 * @returns {Object}
 */
export default (name) => repositories[name] || {};
