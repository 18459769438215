import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import { useAPI } from '../../../context/api';

const CandidateDeleteModal = (props) => {
  const { id, name, setIsOpen } = props;
  const { t } = useTranslation();
  const { reloadData } = useApiRequestContext();

  const [candidateAPI] = useAPI('candidate');

  const onSubmit = async () => {
    await candidateAPI.delete(id);

    setIsOpen(false);
    reloadData();
  };

  return (
    <Formik
      initialValues={{
        motivation: '',
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <p className="text-center mb-3">
              {t('candidates.the-candidate-x-y-is-deleted-permanently', {
                x: name,
                y: ''
              })}
            </p>
            <div className="flex items-center justify-center gap-x-2">
              <Button
                type="button"
                color="light"
                className="btn-rounded px-4 !h-8"
                outline
                disabled={isSubmitting}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t('candidate.cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                className="btn-rounded px-4 !h-8"
                outline
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {t('general.confirm')}
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default CandidateDeleteModal;
