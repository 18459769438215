import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from '..';

export const selectSelf = (state: RootState) => state;

export const selectLoading = createSelector(selectSelf, (state) => {
  return get(state, 'client.loading');
});

export const selectError = createSelector(selectSelf, (state) => {
  return get(state, 'client.error');
});

export const selectClient = createSelector(selectSelf, (state) => {
  return get(state, 'client.data');
});

export const selectGuid = createSelector(selectSelf, (state) => {
  const guid = get(state, 'client.data.guid');
  return guid || '';
});

export const selectIntegrationSettings = createSelector(selectSelf, (state) => {
  return get(state, 'client.data.integrationSettings');
});

export const selectClientConfig = createSelector(selectSelf, (state) => {
  return get(state, 'client.config') || {};
});
