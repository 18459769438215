import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApiRequestContext } from '../../../../../context/@v2/ApiRequestContext';
import { onBlockUpdate } from '../../../../../store/api/career-page.api';
import Button from '../../../../Button';
import Field from '../../../Form/Field';

interface Props {
  item: {
    title: string;
    pageBlockId: number;
    pageId: number;
    type: number;
    sortOrder: number;
    visible: boolean;
    backgroundColorHex: string;
    headerTextColorHex: string;
    subtitleTextColorHex: string;
    textColorHex: string;
    positionId: number;
    titleFontSizeScale: number;
    subtitleFontSizeScale: number;
    bodyFontSizeScale: number;
  };
}

const SubscribersForm = ({ item }: Props) => {
  const { t } = useTranslation();
  const { data, reloadData } = useApiRequestContext();

  const onSubmit = async (values) => {
    await onBlockUpdate(data?.details, values, async () => {
      await reloadData();
      data?.renderSuccessNotification();
    });
  };

  return (
    <Formik initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit} className="w-full">
            <Field dark name="title" label={t('general.headline')} />
            {/* @ts-ignore */}
            <Button
              type="submit"
              color="primary"
              className="w-full text-white"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="career-page-block-form-submit"
            >
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default SubscribersForm;
