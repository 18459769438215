import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { authorize, logout, refreshToken } from '../thunks/auth.thunks';

export interface InitialState {
  refreshToken: string | null;
  idToken: string | null;
  accessToken: string | null;
  loading: boolean;
  error: string | null;
  isTokenExpired: boolean;
  isTokenRefreshing: boolean;
}

export const initialState: InitialState = {
  accessToken: null,
  refreshToken: null,
  idToken: null,
  loading: false,
  error: null,
  isTokenExpired: true,
  isTokenRefreshing: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens: (state, { payload }) => {
      const { accessToken, refreshToken, idToken } = payload;

      return {
        ...state,
        accessToken,
        refreshToken,
        idToken,
        isTokenExpired: false,
        isTokenRefreshing: false,
      };
    },
  },
  extraReducers: (builder) => {
    // authorize
    builder
      .addCase(authorize.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(authorize.fulfilled, (state, { payload }) => {
        /* eslint-disable camelcase */
        const { access_token, id_token, refresh_token } = payload;

        return {
          ...state,
          loading: false,
          accessToken: access_token,
          refreshToken: refresh_token,
          idToken: id_token,
          isTokenExpired: false,
        };
      })
      .addCase(authorize.rejected, (state, { payload }) => {
        const error = get(payload, 'error_description');

        if (error) {
          return {
            ...state,
            loading: false,
            error: error as string,
          };
        }

        return {
          ...state,
          loading: false,
          error: 'unknown',
        };
      });

    // logout
    builder
      .addCase(logout.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(logout.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
        };
      });

    // refreshToken
    builder
      .addCase(refreshToken.pending, (state) => {
        return {
          ...state,
          isTokenRefreshing: true,
        };
      })
      .addCase(refreshToken.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isTokenExpired: payload && !payload.expires_in,
          isTokenRefreshing: false,
        };
      })
      .addCase(refreshToken.rejected, (state) => {
        return {
          ...state,
          isTokenExpired: true,
          isTokenRefreshing: false,
        };
      });
  },
});

export const { setTokens } = slice.actions;
export default slice.reducer;
