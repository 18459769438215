import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { uniqBy } from 'lodash';
import Button from '../components/Button';
import CartTable from '../components/tables/Cart';
import Layout from '../components/layouts/default';
import Icon from '../components/@v2/Icon';
import { useSelector as useSelectorToolkit } from '../store';

export const cart = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const cart = useSelectorToolkit(({ cart }) => cart);

  const hasInvalidProduct = useMemo(() => {
    if (
      cart.length === 0 ||
      cart.find((item) => !item.positionId && item.availableForPosition)
    ) {
      return true;
    }

    return false;
  }, [cart]);

  const hasOnlyOnePosition = useMemo(() => {
    if (cart.length && uniqBy(cart, 'positionId').length === 1) return true;
    return false;
  }, [cart]);

  const activePosition = useMemo(() => {
    if (cart && cart.length) {
      return cart[0].positionId;
    }
    return null;
  }, [cart]);

  return (
    <Layout
      testId="cart"
      title={t('general.shopping-cart')}
      rightContent={
        <Button
          className="btn-rounded d-flex align-items-center"
          color="primary"
          data-testid="go-to-checkout-button"
          onClick={() => {
            history.push('/checkout');
          }}
          disabled={hasInvalidProduct}
        >
          {t('general.go-to-checkout')}
          <Icon
            name="long-arrow-right"
            style={{
              position: 'relative',
              top: -1,
              fontSize: 18,
            }}
            className="ml-2"
          />
        </Button>
      }
    >
      <CartTable />
      {!!hasOnlyOnePosition && (
        <div className="anim-table-delayed">
          <Button
            className="btn-rounded d-flex align-items-center"
            color="primary"
            data-testid="continue-shopping-button"
            onClick={() => {
              history.push(
                activePosition === 0
                  ? '/shop'
                  : `/positions/edit/${activePosition}?currentView=4`
              );
            }}
          >
            <Icon
              name="long-arrow-left"
              style={{ fontSize: 18 }}
              className="mr-2"
            />
            {t('general.continue-shopping')}
          </Button>
        </div>
      )}
    </Layout>
  );
};

export default cart;
