import { FieldArray, Formik } from 'formik';
import { sortBy, uniqueId } from 'lodash';
import React, { useRef } from 'react';
import DraggableList from 'react-draggable-list';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { KEY_FIGURE_TYPE_OPTIONS } from '../../../../../constants/CareerPage';
import { useApiRequestContext } from '../../../../../context/@v2/ApiRequestContext';
import { addSortOrder } from '../../../../../helpers/utils';
import { onBlockUpdate } from '../../../../../store/api/career-page.api';
import Button from '../../../../Button';
import Icon from '../../../Icon';
import Field from '../../../Form/Field';
import KeyFigureItem from './KeyFigureItem';

const KeyFiguresForm = ({ item }) => {
  const { t } = useTranslation();
  const draggableContainer = useRef(null);
  const { data, reloadData } = useApiRequestContext();

  const onSubmit = async (values) => {
    await onBlockUpdate(
      data?.details,
      {
        ...values,
        keyFigures: values?.keyFigures.map((item) => {
          return {
            ...item,
            keyFigureType: item?.keyFigureType?.value,
            value1: item?.value1 || 0,
            value2: item?.value2 || 0,
          };
        }),
      },
      async () => {
        await reloadData();
        data?.renderSuccessNotification();
      }
    );
  };

  const KEY_FIGURE_OPTIONS = KEY_FIGURE_TYPE_OPTIONS(t);

  return (
    <Formik
      initialValues={{
        ...item,
        keyFigures: addSortOrder(sortBy(item?.keyFigures, 'sortOrder')).map(
          (item, index) => {
            return {
              ...item,
              index,
              uuid: uniqueId(),
              keyFigureType: KEY_FIGURE_OPTIONS.find(
                (keyFigure) => keyFigure.value === item?.keyFigureType
              ),
            };
          }
        ),
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
        const createNewKeyFigureItem = () => {
          return {
            index: values?.keyFigures?.length,
            uuid: uniqueId(),
            description: `Key Figure ${values?.keyFigures?.length + 1}`,
            value1: null,
            value2: null,
            keyFigureType: KEY_FIGURE_OPTIONS[1],
            sortOrder: values?.keyFigures?.length + 1,
            isEditing: true,
          };
        };

        return (
          <form onSubmit={handleSubmit} className="w-full">
            <Field dark name="title" label={t('general.headline')} />
            <div className="flex items-center justify-between !mb-4">
              <Label for="information-items" className="mb-0">
                {t('pages.key-figures')}
              </Label>
              <button
                type="button"
                data-testid="add-key-figure-button"
                onClick={() => {
                  setFieldValue('keyFigures', [
                    ...values?.keyFigures?.map((keyFigure) => {
                      return {
                        ...keyFigure,
                        isEditing: false,
                      };
                    }),
                    createNewKeyFigureItem(),
                  ]);
                }}
              >
                <Icon
                  name="plus"
                  color="var(--text-color-secondary)"
                  className="clickable"
                />
              </button>
            </div>
            <FieldArray
              name="keyFigures"
              render={() => (
                <div
                  className="flex flex-col gap-y-4 !mb-3"
                  ref={draggableContainer}
                >
                  <DraggableList
                    itemKey="uuid"
                    template={KeyFigureItem}
                    list={values?.keyFigures}
                    onMoveEnd={(newList) => {
                      const items = addSortOrder(
                        newList.map((item, index) => {
                          return {
                            ...item,
                            index,
                          };
                        })
                      );

                      setFieldValue('keyFigures', items);
                    }}
                    container={() => {
                      if (draggableContainer && draggableContainer.current) {
                        return draggableContainer.current;
                      }
                      return false;
                    }}
                    commonProps={{
                      setFieldValue,
                      keyFigures: values?.keyFigures,
                    }}
                  />
                  {!values?.keyFigures?.length && (
                    <p className="text-color-secondary">
                      {t('career.key-figures.no-items-found')}.
                    </p>
                  )}
                </div>
              )}
            />
            <Button
              type="submit"
              color="primary"
              className="w-full text-white"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="career-page-block-form-submit"
            >
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default KeyFiguresForm;
