import PositionTemplateItem from '@ts/apicontracts/positionTemplates/list/response-list-item';
import PositionTemplateDetailsResponse from '@ts/apicontracts/positionTemplates/details/response';
import UpdatePositionTemplateCommand from '@ts/apicontracts/positionTemplates/update-position-template-command';
import { AxiosResponseDirect, api } from '../../helpers/api-v2';

const resource = 'position-templates';

export const getPositionTemplates = async (options = {}) => {
  try {
    const response = await api.get(`${resource}/list`, options);
    return response as unknown as AxiosResponseDirect<PositionTemplateItem[]>;
  } catch (error) {
    throw error;
  }
};

export const getPositionTemplate = async (id: string) => {
  const template = await api.get(`${resource}/${id}`);
  return template as unknown as AxiosResponseDirect<PositionTemplateDetailsResponse>;
};

export const batchSortOrderUpdate = async (ordering: { id: number; order: number }[]) => {
  await api.put(`${resource}/batch-sort-order-update`, { ordering });
};

export const createOrUpdatePositionTemplate = async (values: UpdatePositionTemplateCommand) => {
  const isEditing = !!values?.id;
  const response = await api[isEditing ? 'put' : 'post'](resource, values);

  return response;
};

export const deletePositionTemplate = async (id: string, options = {}) => {
  const data = await api.delete(`${resource}/${id}`, options);
  return data;
};
