import { FieldArray, Formik } from 'formik';
import React, { useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Axios from 'axios';
import DraggableList from 'react-draggable-list';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import Tippy from '@tippyjs/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { NotificationManager } from 'react-notifications';
import Field from '../../../../components/@v2/Form/Field';
import Button from '../../../../components/Button';
import Layout from '../../../../components/layouts/default';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import { useSelector, dispatch as dispatchToolkit } from '../../../../store';
import { selectUser } from '../../../../store/selectors/user.selector';
import { getClientSettings, updateClientSettings } from '../../../../store/api/client.api';
import { getAllEmailTemplates } from '../../../../store/api/template.api';
import Divider from '../../../../components/@v2/Divider/Divider';
import { UserLanguage } from '../../../../constants/User';
import ApplySettings, { EmailDelayOptions, ShowHideOptions } from '../../../../constants/ApplySettings';
import Icon from '../../../../components/@v2/Icon';
import { addSortOrder, createLinkSuffix } from '../../../../helpers/utils';
import {
  createLinkFunctionOptions,
  createLinkTypeOptions,
  createTitleSizeOptions,
} from '../../../../constants/Options';
import { selectClient } from '../../../../store/selectors/client.selector';
import { setClientConfig } from '../../../../store/slices/client.slice';
import dispatchCustomEvent from '../../../../helpers/dispatch-custom-event';

export const SettingsCareerPageSchema = (t) => {
  return Yup.object().shape({
    positionListSettings: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().nullable().required(t('general.required')),
        key: Yup.string().nullable().required(t('general.required')),
      })
    ),
  });
};

export const OrderPosition = ({ item, dragHandleProps }) => {
  return (
    <div className="flex items-center gap-x-4 bg-white border rounded-lg !py-2 !px-4">
      <div {...dragHandleProps}>
        <Icon
          type="fas"
          name="grip-vertical"
          className="cursor-move"
          data-testid="form-position-order-draggable-handle"
        />
      </div>
      <p className="mb-0 subtitle">{item.name}</p>
    </div>
  );
};

export const PositionList = ({ settings, index = 0, setFieldValue, positionListSettings }) => {
  const client = useSelector(selectClient);
  const { data } = useApiRequestContext();
  const oldSettings = useRef(settings);
  const { t } = useTranslation();

  const hideAll = () => {
    [...document.querySelectorAll('*')].forEach((node) => {
      if (node._tippy) {
        node._tippy.hide();
      }
    });
  };

  const onHandleEdit = () => {
    setFieldValue(`positionListSettings[${index}].isEditing`, !settings?.isEditing);
    setFieldValue(`positionListSettings[${index}].saved`, false);
  };

  const onDelete = () => {
    setFieldValue(
      'positionListSettings',
      positionListSettings.filter((item, itemIndex) => {
        return itemIndex !== index;
      })
    );
  };

  const onCancel = () => {
    setFieldValue(`positionListSettings[${index}]`, {
      ...(oldSettings?.current || settings),
      isEditing: false,
      saved: true,
    });
  };

  const onSave = () => {
    setFieldValue(`positionListSettings[${index}].added`, false);
    setFieldValue(`positionListSettings[${index}].isEditing`, false);
    setFieldValue(`positionListSettings[${index}].saved`, true);
    oldSettings.current = settings;
  };

  useEffect(() => {
    oldSettings.current = settings;
  }, []);

  return (
    <div className={classNames(settings?.uniqueId && !settings?.saved ? '' : 'rounded-lg !px-4 bg-white !py-2 border')}>
      <div className="flex items-center justify-between">
        {settings?.saved && <p className="mb-0">{oldSettings?.current?.name || settings?.name}</p>}
        <div className="flex items-center gap-x-2">
          {settings?.saved && !settings?.added && (
            <Tippy
              content={
                <div className="!p-2 text-xs">
                  <p className="font-bold !mb-2">
                    {t('settings.position-list.share-confirm-title', {
                      name: settings.name,
                    })}
                  </p>
                  <div className="flex items-center w-full bg-gray-100 border rounded-md overflow-hidden">
                    <p className="truncate mb-0 !pl-2 h-[32px] leading-[32px]">
                      https://{client?.key}.weselect.com/?positions_list=
                      {settings.key}
                    </p>
                    <CopyToClipboard
                      text={`https://${client?.key}.weselect.com/?positions_list=
                      ${settings.key}`}
                      onCopy={(text) => {
                        dispatchCustomEvent('clipboard-copy', { text });
                        NotificationManager.success(t('general.copied-successfully'));
                        hideAll();
                      }}
                    >
                      <button
                        type="button"
                        className="px-3 border-l h-[32px] bg-white"
                        data-testid="form-position-list-copy"
                      >
                        {t('general.copy')}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              }
              maxWidth={350}
              interactive
              trigger="click"
              theme="light"
            >
              <button type="button" className="!mr-0.5" data-testid="form-position-list-share-handle">
                <Icon name="share-alt" />
              </button>
            </Tippy>
          )}
          {settings?.saved && (
            <>
              <Tippy
                trigger="click mouseenter"
                content={t('general.edit')}
                theme="light"
                onTrigger={() => {
                  hideAll();
                }}
              >
                <button type="button" onClick={onHandleEdit} data-testid="form-position-list-edit-handle">
                  <Icon name="edit" />
                </button>
              </Tippy>
              <Tippy
                maxWidth={150}
                interactive
                trigger="click"
                content={
                  <div className="!p-2 text-xs">
                    <p className="!mb-2">{t('settings.position-list.delete-confirm-text')}</p>
                    <div className="flex items-center justify-end gap-x-2">
                      <button
                        type="button"
                        className="clickable"
                        onClick={hideAll}
                        data-testid="form-position-list-cancel"
                      >
                        {t('general.cancel')}
                      </button>
                      <button
                        type="button"
                        className="clickable bg-red-500 text-white !px-3 !py-1.5 rounded-sm"
                        onClick={onDelete}
                        data-testid="form-position-list-delete"
                      >
                        {t('general.delete')}
                      </button>
                    </div>
                  </div>
                }
                theme="light"
              >
                <button type="button" data-testid="form-position-list-delete-handle">
                  <Icon name="trash" />
                </button>
              </Tippy>
            </>
          )}
        </div>
      </div>
      {settings?.isEditing && (
        <div className="form bg-gray-100 !p-4 rounded-lg !mt-2 !mb-2">
          <div className="grid grid-cols-2 gap-x-4">
            <Field
              required
              name={`positionListSettings[${index}].name`}
              label={t('general.name')}
              callback={(value) => {
                setFieldValue(`positionListSettings[${index}].key`, createLinkSuffix(value));
              }}
            />
            <Field required name={`positionListSettings[${index}].key`} label={t('general.key')} />
          </div>
          <div className="grid grid-cols-2 items-end gap-x-4">
            <div>
              <Field
                name={`positionListSettings[${index}].showTitle`}
                type="switch"
                label={t('settings.position-list.show-name-as-title')}
              />
              <Field
                name={`positionListSettings[${index}].showSpontaneous`}
                type="switch"
                label={t('settings.position-list.show-spontaneous-positions')}
              />
              <Field
                name={`positionListSettings[${index}].showHiddenPositions`}
                type="switch"
                label={t('settings.position-list.show-hidden-positions')}
              />
              <Field
                name={`positionListSettings[${index}].showColumnLocation`}
                type="switch"
                label={t('settings.position-list.show-column-for-location')}
              />
            </div>
            <div>
              <Field
                name={`positionListSettings[${index}].titleType`}
                type="select"
                defaultOptions={data?.options.TITLE_SIZE_OPTIONS}
                label={t('settings.position-list.title-size')}
              />
              <Field
                name={`positionListSettings[${index}].showNonSpontaneous`}
                type="switch"
                label={t('settings.position-list.show-non-spontaneous-positions')}
              />
              <Field
                name={`positionListSettings[${index}].showNotHiddenPositions`}
                type="switch"
                label={t('settings.position-list.show-not-hidden-positions')}
              />
              <Field
                name={`positionListSettings[${index}].showColumnLastDate`}
                type="switch"
                label={t('settings.position-list.show-column-for-last-application-date')}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-4">
            <Field
              name={`positionListSettings[${index}].linkType`}
              type="select"
              defaultOptions={data?.options?.LINK_TYPE_OPTIONS}
              label={t('settings.position-list.link-type')}
            />
            {settings?.linkType.value === data?.options.LINK_TYPE_OPTIONS[0].value && (
              <Field
                name={`positionListSettings[${index}].linkText`}
                label={t('settings.position-list.text-in-link-column')}
                placeholder="Apply"
              />
            )}
          </div>
          <Field
            name={`positionListSettings[${index}].linkFunction`}
            type="select"
            defaultOptions={data?.options?.LINK_FUNCTION_OPTIONS}
            label={t('settings.position-list.text-in-link-column')}
          />
          <Field
            name={`positionListSettings[${index}].customCss`}
            type="textarea"
            label={t('settings.position-list.custom-css-long')}
            placeholder="body { font-family: Lato; } table { width: 50%; } btn { text-transform: uppercase; }"
          />
          <div className="flex items-center">
            <Button
              type="button"
              color="danger"
              data-testid="form-cancel-button"
              onClick={settings?.added ? onDelete : onCancel}
            >
              {t('general.cancel')}
            </Button>
            <Button type="button" color="primary" data-testid="form-submit-button" onClick={onSave}>
              {t('general.submit')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const SettingsCareerPage = () => {
  const { t } = useTranslation();
  const { loading, data, reloadData } = useApiRequestContext();
  const currentUser = useSelector(selectUser);
  const client = useSelector(selectClient);
  const clientId = useMemo(() => currentUser?.clientId, [currentUser]);
  const draggableContainer = useRef(null);

  const onSubmit = async (values) => {
    try {
      const finalData = {
        ...values,
        clientId,
        language: values?.language?.value,
        globalEmailDelayHours: values?.globalEmailDelayHours?.value,
        applyFormSettings: {
          ...values?.applyFormSettings,
          birthDate: values?.applyFormSettings?.birthDate?.value,
          cv: values?.applyFormSettings?.cv?.value,
          email: values?.applyFormSettings?.email?.value,
          firstName: values?.applyFormSettings?.firstName?.value,
          lastName: values?.applyFormSettings?.lastName?.value,
          showImage: values?.applyFormSettings?.image?.value,
          showLinkedInButton: values?.applyFormSettings?.linkedIn?.value,
          motivation: values?.applyFormSettings?.motivation?.value,
          phoneNumber: values?.applyFormSettings?.phoneNumber?.value,
        },
        positionListSettings: values?.positionListSettings
          ?.filter((item) => {
            return !(item.added && !item.saved);
          })
          .map((item) => {
            if (!item?.saved) {
              return data?.form?.positionListSettings.find((listItem) => listItem?.uniqueId === item?.uniqueId);
            }

            return item;
          })
          .map((item) => {
            return {
              ...item,
              titleType: item?.titleType?.value,
              linkType: item?.linkType?.value,
              linkFunction: item?.linkFunction?.value,
            };
          }),
      };

      const response = await updateClientSettings(finalData);

      dispatchToolkit(
        setClientConfig({
          ...client?.config,
          applyFormSettings: response?.applyFormSettings || client?.applyFormSettings,
        })
      );
      reloadData();
    } catch (error) {
      throw new Error(error);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Layout title={t('general.career-page')} pretitle={t('general.settings')} data-testid="settings-career-page-layout">
      <Formik
        enableReinitialize
        initialValues={data?.form || {}}
        validationSchema={SettingsCareerPageSchema(t)}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="anim-table-delayed max-w-3xl w-full shadow-sm p-4 bg-white rounded-lg"
            >
              <div className="flex gap-x-4">
                <Field
                  className="w-1/2"
                  name="language"
                  type="select"
                  label={t('general.language')}
                  initialValue={data?.form?.language}
                  defaultOptions={data?.options?.LANGUAGE_OPTIONS}
                />
              </div>
              <Divider>{t('general.apply-form')}</Divider>
              <div className="grid grid-cols-2 gap-x-4">
                <Field
                  disabled
                  name="applyFormSettings.firstName"
                  type="select"
                  label={t('general.first-name')}
                  defaultOptions={ApplySettings}
                />
                <Field
                  disabled
                  name="applyFormSettings.lastName"
                  type="select"
                  label={t('general.last-name')}
                  defaultOptions={ApplySettings}
                />
                <Field
                  disabled
                  name="applyFormSettings.email"
                  type="select"
                  label={t('general.email')}
                  defaultOptions={ApplySettings}
                />
                <Field
                  name="applyFormSettings.phoneNumber"
                  type="select"
                  label={t('general.phone-number')}
                  defaultOptions={ApplySettings}
                />
                <Field
                  name="applyFormSettings.birthDate"
                  type="select"
                  label={t('general.date-of-birth')}
                  defaultOptions={ApplySettings}
                />
                <Field
                  name="applyFormSettings.motivation"
                  type="select"
                  label={t('general.motivation')}
                  defaultOptions={ApplySettings}
                />
                <Field
                  name="applyFormSettings.cv"
                  type="select"
                  label={t('general.at-least-one-attachment-cv')}
                  defaultOptions={ApplySettings}
                />
                <Field
                  name="applyFormSettings.linkedIn"
                  type="select"
                  label={t('general.apply-with-linkedin')}
                  defaultOptions={ShowHideOptions}
                />
                <Field
                  name="applyFormSettings.image"
                  type="select"
                  label={t('general.image')}
                  defaultOptions={ShowHideOptions}
                />
              </div>
              <Divider>{t('general.automatic-reply')}</Divider>
              <Field name="sendGlobalMailToCandidate" type="switch" label={t('settings.send-email-to-candidates')} />
              {values?.sendGlobalMailToCandidate && (
                <>
                  <Field required name="globalEmailSubject" label={t('general.subject')} />
                  <Field
                    required
                    name="globalEmailText"
                    type="wysiwyg"
                    toolbar="candidate-email-toolbar"
                    wysiwygOptions={{
                      options: {
                        listItems: data?.templates,
                      },
                      acceptValueFromApi: true,
                    }}
                    label={t('general.message')}
                  />
                  <Field
                    name="globalEmailDelayHours"
                    type="select"
                    defaultOptions={EmailDelayOptions}
                    label={t('general.email-delay')}
                  />
                </>
              )}
              <Divider>{t('settings.order-of-the-positions')}</Divider>
              <div ref={draggableContainer} className="!mb-4" data-testid="form-position-order-draggable">
                <DraggableList
                  itemKey="id"
                  template={OrderPosition}
                  list={values?.positions || []}
                  onMoveEnd={(newList) => {
                    const items = addSortOrder(
                      newList.map((item, index) => {
                        return {
                          ...item,
                          index,
                        };
                      })
                    );
                    setFieldValue('positions', items);
                  }}
                  container={() => {
                    if (draggableContainer && draggableContainer?.current) {
                      return draggableContainer?.current;
                    }
                    return document.body;
                  }}
                />
              </div>
              <Divider>{t('settings.position-list.iframe-settings')}</Divider>
              <div className="flex items-end justify-between !mb-4">
                <p className="mb-0">{t('settings.position-list.position-list')}</p>
                {!values?.positionListSettings?.find((item) => {
                  return item?.adding;
                }) && (
                  <Button
                    type="button"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setFieldValue('positionListSettings', [
                        {
                          customCss: '',
                          key: '',
                          linkFunction: data?.options?.LINK_FUNCTION_OPTIONS[0],
                          linkText: '',
                          linkType: data?.options?.LINK_TYPE_OPTIONS[0],
                          name: '',
                          showColumnLastDate: true,
                          showColumnLocation: true,
                          showHiddenPositions: false,
                          showNonSpontaneous: true,
                          showNotHiddenPositions: true,
                          showSpontaneous: true,
                          showTitle: true,
                          titleType: data?.options?.TITLE_SIZE_OPTIONS[0],
                          saved: false,
                          added: true,
                          isEditing: true,
                          uniqueId: uniqueId(),
                        },
                        ...values?.positionListSettings,
                      ]);
                    }}
                    data-testid="form-add-position-list-button"
                    className="!py-2"
                  >
                    <div className="flex items-center">
                      <Icon name="plus" /> {t('settings.position-list.add-position-list')}
                    </div>
                  </Button>
                )}
              </div>
              <FieldArray
                render={() => {
                  return (
                    <div className="flex flex-col gap-y-[10px] !mb-6">
                      {values?.positionListSettings?.map((settings, index) => (
                        <PositionList
                          key={settings?.uniqueId}
                          settings={settings}
                          index={index}
                          setFieldValue={setFieldValue}
                          positionListSettings={values?.positionListSettings}
                        />
                      ))}
                    </div>
                  );
                }}
              />
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                data-testid="form-submit-button"
              >
                {t('general.submit')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

const SettingsCareerPageWrapper = () => {
  const { t } = useTranslation();
  // const client = useSelector(selectClient);
  const DEFAULT_CAREERPAGE_FORM = {
    name: '',
  };

  const TITLE_SIZE_OPTIONS = createTitleSizeOptions(t);
  const LINK_TYPE_OPTIONS = createLinkTypeOptions(t);
  const LINK_FUNCTION_OPTIONS = createLinkFunctionOptions(t);
  const LANGUAGE_OPTIONS = [
    ...UserLanguage,
    {
      value: 'it-IT',
      label: 'Italian',
    },
  ];

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source) => {
        const [clientSettings, templates] = await Axios.all([
          getClientSettings({
            cancelToken: source.token,
          }),
          getAllEmailTemplates({
            cancelToken: source.token,
          }),
        ]);

        return {
          form: {
            ...DEFAULT_CAREERPAGE_FORM,
            ...clientSettings,
            language: LANGUAGE_OPTIONS.find(({ value }) => {
              return value === clientSettings?.language;
            }),
            positionListSettings: clientSettings?.positionListSettings?.map((item) => ({
              ...item,
              uniqueId: uniqueId(),
              saved: true,
              isEditing: false,
              titleType:
                TITLE_SIZE_OPTIONS.find((option) => {
                  return option?.value === item?.titleType;
                }) || TITLE_SIZE_OPTIONS[0],
              linkType:
                LINK_TYPE_OPTIONS.find((option) => {
                  return option?.value === item?.linkType;
                }) || LINK_FUNCTION_OPTIONS[0],
              linkFunction:
                LINK_FUNCTION_OPTIONS.find((option) => {
                  return option?.value === item?.linkFunction;
                }) || LINK_FUNCTION_OPTIONS[0],
            })),
            applyFormSettings: {
              ...clientSettings?.applyFormSettings,
              firstName: ApplySettings[1],
              lastName: ApplySettings[1],
              email: ApplySettings[1],
              phoneNumber: ApplySettings[clientSettings?.applyFormSettings?.phoneNumber || 0],
              birthDate: ApplySettings[clientSettings?.applyFormSettings?.birthDate],
              motivation: ApplySettings[clientSettings?.applyFormSettings?.motivation],
              cv: ApplySettings[clientSettings?.applyFormSettings?.cv],
              linkedIn:
                ShowHideOptions.find((item) => {
                  return item.value === clientSettings?.applyFormSettings?.showLinkedInButton;
                }) || null,
              image: ShowHideOptions.find((item) => {
                return item.value === clientSettings?.applyFormSettings?.showImage;
              }),
            },
            globalEmailDelayHours: EmailDelayOptions.find(
              (item) => item.value === (clientSettings?.globalEmailDelayHours || 0)
            ),
          },
          templates: templates?.items?.map((item) => ({
            value: item.text,
            label: item.name,
            id: item.templateId,
            item,
          })),
          options: {
            TITLE_SIZE_OPTIONS,
            LINK_TYPE_OPTIONS,
            LINK_FUNCTION_OPTIONS,
            LANGUAGE_OPTIONS,
          },
        };
      }}
    >
      <SettingsCareerPage />
    </ApiRequestProvider>
  );
};

export default SettingsCareerPageWrapper;
