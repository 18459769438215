import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { toggleMenuState } from '../../store/slices/settings.slice';
import menu from '../../constants/SidebarMenu';
import Icon from '../@v2/Icon';
import events from '../../helpers/events';
import { isPermitted } from '../../helpers/permission';
import '../../assets/sass/sidebar.sass';
import { selectUser } from '../../store/selectors/user.selector';
import { selectMenu } from '../../store/selectors/settings.selector';
import { selectClientConfig } from '../../store/selectors/client.selector';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../store';
import Logo from '../../assets/images/logo.svg';

const NavItem = ({ icon, label, dropdown, href, children, isMinimized, link, target }) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setIsMenuOpen(href && location.pathname.includes(href));
  }, [href, location]);

  const createMenuItemChildren = () => (
    <>
      {isMinimized && isHovered && <div className="animated-label">{label}</div>}
      <span className="icon">
        <Icon name={icon} />
      </span>
      <span className="nav-item-button-label">{label}</span>
    </>
  );

  const isHrefALink = () => {
    const props = {
      target,
      className: classnames('nav-item-button', {
        'nav-item-button--hovered': isHovered,
      }),
      onMouseEnter: () => setIsHovered(true),
      onMouseLeave: () => setIsHovered(false),
    };

    if (link) {
      return (
        <a href={href} {...props}>
          {createMenuItemChildren()}
        </a>
      );
    }

    return (
      <NavLink
        to={href || ''}
        activeClassName="nav-item-button--active"
        {...props}
        id={`nav-item-link-${href.replace(/\//g, '-')}`}
        data-testid={`nav-item${href.replace(/\//g, '-')}-link`}
      >
        {createMenuItemChildren()}
      </NavLink>
    );
  };

  return (
    <li className="nav-item">
      {dropdown ? (
        <>
          <button
            type="button"
            className={classnames('nav-item-button nav-item-button-dropdown', {
              'nav-item-button--active': location.pathname.toLowerCase().includes(label.toLowerCase()),
            })}
            id={`nav-item-dropdown-${href.replace(/\//g, '-')}`}
            data-testid={`nav-item${href.replace(/\//g, '-')}-dropdown`}
            onClick={() => {
              setIsMenuOpen((state) => !state);
            }}
          >
            <span className="icon">
              <Icon name={icon} />
            </span>
            <span className="nav-item-button-label">{label}</span>
            <Icon name="angle-down" size="lg" rotation={isMenuOpen ? 180 : null} />
          </button>
          <div
            style={{
              maxHeight: isMenuOpen || isMinimized ? 500 : 0,
              overflow: 'hidden',
              transition: 'max-height 0.3s linear',
            }}
          >
            {children}
          </div>
        </>
      ) : (
        isHrefALink()
      )}
    </li>
  );
};

NavItem.defaultProps = {
  dropdown: false,
  href: '',
  children: null,
  target: null,
};

const Sidebar = ({ isCareerPageActive }) => {
  const { minimized } = useSelectorToolkit(selectMenu);
  const isMinimized = minimized || isCareerPageActive;
  const minimizeFn = useCallback((state) => {
    dispatchToolkit(toggleMenuState(state));
  }, []);
  const { t } = useTranslation();
  const isMobile = useMemo(() => window.innerWidth >= 426, [window.innerWidth]);
  const [isActive, setIsActive] = useState(isMobile);
  const [showOverlay, setShowOverlay] = useState(false);
  const currentUser = useSelectorToolkit(selectUser);
  const activeClient = useSelectorToolkit(selectClientConfig);

  const onResize = () => {
    setIsActive(isMobile);
    setShowOverlay(false);
  };

  useEffect(() => {
    events.$on('sidebar-menu', () => {
      const currentState = isActive;
      setIsActive(!currentState);
      setShowOverlay(!currentState);
    });

    window.addEventListener('resize', debounce(onResize, 150));

    return () => {
      window.removeEventListener('resize', debounce(onResize, 150));
    };
  }, [isActive]);

  const parsedMenu = useMemo(() => {
    return menu.filter(({ access, showFn }) => {
      return isPermitted(access, currentUser, showFn, activeClient);
    });
  }, [menu, currentUser, activeClient]);

  return (
    <>
      {showOverlay && (
        <div
          className="sidebar-menu-overlay"
          onClick={() => {
            setIsActive(false);
            setShowOverlay(false);
          }}
        />
      )}
      <div
        className={classnames('sidebar-menu d-flex align-items-start flex-column relative', {
          'sidebar-menu--active': isActive,
          'sidebar-menu--minimized': isMinimized,
        })}
      >
        <div className="navbar-logo">
          <img className="logo" src={Logo} alt="logo" />
        </div>
        <div className="navbar-menu">
          <ul className="navbar-menu-items !mb-0 flex-grow-1">
            {parsedMenu.map(({ label, icon, href, dropdown, link, target, showFn }) => {
              if (showFn && !showFn({ ...currentUser, ...activeClient })) {
                return null;
              }

              return (
                <NavItem
                  key={label}
                  label={t(label)}
                  icon={icon}
                  href={href}
                  dropdown={dropdown}
                  isMinimized={isMinimized}
                  data-label={t(label)}
                  link={link}
                  target={target}
                >
                  {dropdown && (
                    <ul className="dropdown-item">
                      {isMinimized && <li className="small-title">{t(label)}</li>}
                      {dropdown.map(
                        (dropdownItem) =>
                          isPermitted(dropdownItem.access, currentUser, dropdownItem.showFn, activeClient) && (
                            <li key={dropdownItem.to}>
                              <NavLink
                                to={dropdownItem.to}
                                activeClassName="nav-item-button--active"
                                id={`nav-item-link-${dropdownItem.to.replace(/\//g, '-')}`}
                                data-testid={`nav-item${dropdownItem.to.replace(/\//g, '-')}-sub-link`}
                              >
                                <span className="nav-item-button-label">{t(dropdownItem.label)}</span>
                              </NavLink>
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </NavItem>
              );
            })}
          </ul>
        </div>
        {!isMinimized && (
          <div className="flex items-center gap-x-3 text-xs !px-4 !pb-12">
            <p className="mr-3 mb-0">Account</p>
            <p className="menu-client-name mb-0">{activeClient.name}</p>
          </div>
        )}
        {!isCareerPageActive && (
          <div className="absolute -right-[15px] bottom-[42px]">
            <button
              className={classnames('bg-primary-light grid place-items-center h-[32px] w-[32px] rounded-full', {
                'btn-menu--minimized': isMinimized,
              })}
              type="button"
              onClick={() => minimizeFn(!isMinimized)}
            >
              <Icon name={`chevron-${isMinimized ? 'right' : 'left'}`} className="text-primary" />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

Sidebar.propTypes = {
  isCareerPageActive: PropTypes.bool,
};

Sidebar.defaultProps = {
  isCareerPageActive: false,
};

export default Sidebar;
