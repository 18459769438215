import PipelineTemplatesResponseListItem from '@ts/apicontracts/pipeline-step-templates/list/response-list-item';
import PipelineStepResponseModel from '@ts/apicontracts/pipeline-steps/pipeline-step-response-model';
import GetPipelineStepsQuery from '@ts/apicontracts/pipeline-steps/list/get-pipeline-steps-query';
import UpdatePipelineStepsCommand from '@ts/apicontracts/pipeline-steps/update-pipeline-steps-command';
import UpdatePipelineStepTemplateCommand from '@ts/apicontracts/pipeline-step-templates/update-pipeline-steps-template-command';

import { AxiosResponseDirect, api } from '../../helpers/api-v2';

const resource = '/pipeline-steps';

export const getAllPipelineTemplates = async (queryParams = {}, options = {}) => {
  const pipelineTemplates = await api.post('/pipeline-step-templates/list', queryParams, options);

  // @ts-ignore
  if (options.withPagination) {
    return pipelineTemplates as unknown as AxiosResponseDirect<{
      items: PipelineTemplatesResponseListItem[];
      totalCount: number;
    }>;
  }

  return (pipelineTemplates as unknown as AxiosResponseDirect<{ items: PipelineTemplatesResponseListItem[] }>).items;
};

export const getAllPipelineSteps = async (queryParams?: GetPipelineStepsQuery, options = {}) => {
  const pipelineTemplates = await api.post(`${resource}/lookup`, { ...queryParams }, options);
  return pipelineTemplates as unknown as AxiosResponseDirect<PipelineStepResponseModel[]>;
};

export const listPipelineSteps = async (queryParams?: GetPipelineStepsQuery, options = {}) => {
  const pipelineTemplates = await api.post(`${resource}/list`, { ...queryParams }, options);
  return pipelineTemplates as unknown as AxiosResponseDirect<PipelineStepResponseModel[]>;
};

export const getPipelineStep = async (id: string, options = {}) => {
  const pipelineTemplates = await api.get(`${resource}/${id}`, options);
  return pipelineTemplates as unknown as AxiosResponseDirect<PipelineStepResponseModel>;
};

export const createOrUpdatePipelineStep = async (values: UpdatePipelineStepsCommand) => {
  const isEditing = !!values?.id;
  const response = await api[isEditing ? 'put' : 'post'](`${resource}`, values);

  return response;
};

export const getPipelineTemplate = async (id: string, options = {}) => {
  const pipelineTemplates = await api.get(`/pipeline-step-templates/${id}`, options);
  return pipelineTemplates as unknown as AxiosResponseDirect<PipelineTemplatesResponseListItem>;
};

export const createOrUpdatePipelineTemplate = async (values: UpdatePipelineStepTemplateCommand) => {
  const isEditing = !!values?.id;
  const response = await api[isEditing ? 'put' : 'post']('/pipeline-step-templates', values);

  return response;
};

export const deletePipelineTemplate = async (form: { id: string }, options = {}) => {
  const data = await api.delete(`/pipeline-step-templates/${form?.id}`, {
    data: {
      archived: false,
      ...form,
    },
    ...options,
  });
  return data;
};

export const deletePipelineStep = async (form: { id: string }, options = {}) => {
  const data = await api.delete(`${resource}/${form?.id}`, {
    data: {
      archived: false,
      ...form,
    },
    ...options,
  });
  return data;
};
