import React from 'react';
import classNames from 'classnames';

const RectangleLoader = ({ items }) => {
  return (
    <div className="rounded-xl p-4 shadow-xl bg-white max-w-2xl">
      <div className="flex items-center justify-between mb-4">
        <div className="h-10 w-[150px] bg-gray-200 rounded-lg" />
        <div className="flex items-center">
          <div className="h-[20px] w-[40px] bg-gray-100 rounded-full" />
          <div className="ml-3 h-[20px] w-20 bg-gray-100 rounded-sm" />
        </div>
      </div>
      <div className="mb-4 bg-gray-100 h-px" />
      {items.map((item) => {
        return (
          <div
            className={classNames(
              'h-[59px] bg-gray-100 rounded-xl flex items-center justify-between px-4',
              item !== 0 && 'mt-3'
            )}
            key={item}
          >
            <div className="h-[20px] w-28 bg-gray-200 rounded-md" />
            <div className="h-[35px] w-[35px] bg-gray-200 rounded-full" />
          </div>
        );
      })}
      <div className="flex items-center mt-4">
        <div className="h-[38px] w-20 bg-gray-100 rounded-md mr-3" />
        <div className="h-[38px] w-16 bg-gray-200 rounded-md" />
      </div>
    </div>
  );
};

RectangleLoader.defaultProps = {
  items: [0, 1, 2, 3, 4],
};

export default RectangleLoader;
