import React from 'react';
import { Button as CustomButton } from 'reactstrap';
import classnames from 'classnames';
import styled from 'styled-components';
import Loader from './Loader';

const CustomButtonStyle = styled(CustomButton)`
  svg {
    color: ${({ color }) => (color === 'primary' ? 'white' : null)};
  }
`;

const Button = React.forwardRef(
  (
    {
      loading,
      children,
      type,
      loaderColor,
      loaderStyles,
      round,
      color,
      ...rest
    },
    ref
  ) => {
    const createStyles = () => {
      const defaultStyles = {
        position: 'relative',
        lineHeight: 0,
        ...rest.style,
        borderRadius: round ? 100 : null,
        paddingLeft: round ? 25 : null,
        paddingRight: round ? 25 : null,
      };

      if (loading) {
        if (rest.size === 'sm') {
          defaultStyles.paddingRight = 28;
        } else {
          defaultStyles.paddingRight = 40;
        }
      }

      return defaultStyles;
    };

    return (
      <CustomButtonStyle
        {...rest}
        color={color && !color.includes('-2') ? color : null}
        className={classnames(
          'transition flex items-center justify-center',
          rest.className,
          !!(color && color.includes('-2')) && color
        )}
        style={createStyles()}
        disabled={loading || rest.disabled}
        type={type}
        ref={ref}
      >
        {children}
        {loading && (
          <Loader
            size={rest.size === 'sm' ? 25 : 38}
            color={
              color === 'primary' || color === 'success'
                ? '#ffffff'
                : loaderColor
            }
            style={{
              right: '-5px',
              top: null,
              ...loaderStyles,
            }}
          />
        )}
      </CustomButtonStyle>
    );
  }
);

Button.defaultProps = {
  loading: false,
  type: 'button',
  round: false,
  size: '',
  loaderColor: '#ffffff',
  loaderStyles: {},
  style: {},
  color: 'primary',
};

export default Button;
