import { AxiosResponse } from 'axios';
import ReportAccessDataModel from '@ts/apicontracts/analytics/report-access-data-model';
import { api } from '../../helpers/api-v2';

const resource = 'analytics';

export const getDashboardSettings = async (options = {}) => {
  try {
    const response = await api.get(`${resource}/dashboard`, options);
    return response as AxiosResponse<ReportAccessDataModel>;
  } catch (error) {
    throw error;
  }
};
