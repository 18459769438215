import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import Modal, { ModalHeader, ModalBody } from '../../Modal';
import DeleteContainer from '../../DeleteContainer';
import { useAPI, cancelRequests } from '../../../../context/api';
import { log } from '../../../../helpers/utils';

const Templates = () => {
  const [pipelineAPI] = useAPI('pipeline');
  const { pathname, search, state } = useLocation();
  const name = useMemo(() => (state && state.name ? state.name : ''), [state]);
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const { t } = useTranslation();

  const queryParams = qs.parse(search);

  const closeModal = (payload = {}) => {
    const queryParamsCopy = { ...queryParams };
    Reflect.deleteProperty(queryParamsCopy, 'modal');

    history.push({
      location: pathname,
      search: qs.stringify({ ...queryParamsCopy, ...payload }),
      state,
    });
  };

  const onDelete = async (e) => {
    e.preventDefault();
    if (sending) return;
    setSending(true);

    try {
      await pipelineAPI.deleteStep(state);

      closeModal({ refresh: true });
    } catch (error) {
      log(error);
    }

    setSending(false);
  };

  useEffect(() => cancelRequests(pipelineAPI), []);

  return (
    <Modal width={600}>
      <ModalHeader>{t('modal.pipelines.delete-pipeline-step')}</ModalHeader>
      <ModalBody>
        <DeleteContainer
          onDelete={onDelete}
          name={name}
          params={queryParams}
          sending={sending}
        />
      </ModalBody>
    </Modal>
  );
};

export default Templates;
