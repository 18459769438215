import React from 'react';
import { Badge } from 'reactstrap';
import qs from 'query-string';
import moment from 'moment';
import hexToRgba from 'hex-to-rgba';
import rgbHex from 'rgb-hex';
import psl from 'psl';
import { forEach, omit } from 'lodash';
import Loader from '../components/Loader';

export const log = (name = '', value = '') => {
  if (global?.appConfig?.environment === 'development') {
    // eslint-disable-next-line no-console
    console.log(name, value);
  }
};

export const getFullBlocks = (blocks, currentBlock) =>
  [...blocks].map((content) => {
    if (content.backgroundColorHex === null) {
      content.backgroundColorHex = '#ffffff';
    }
    if (content.headerTextColorHex === null) {
      content.headerTextColorHex = '#000000';
    }
    if (content.subtitleTextColorHex === null) {
      content.subtitleTextColorHex = '#000000';
    }

    if (content.pageBlockId === currentBlock.pageBlockId) return currentBlock;

    return content;
  });

export const urlPrefixer = (str) => {
  if (!str) return '';
  let url = str;
  const formattedUrl = url.replace(/^(?:https?:\/\/)?/i, '').split('/')[0];
  const parsedUrl = psl.parse(formattedUrl);

  if (!parsedUrl.tld) {
    url += '.com';
  }

  return url;
};

export const createLoader = (condition = false, loaderStyles = {}) => {
  if (!condition) return null;
  return (
    <Loader
      type="primary"
      className="input-loader"
      size={40}
      style={{
        top: -1,
        right: 0,
        ...loaderStyles,
      }}
    />
  );
};

export const addSortOrder = (arr) =>
  arr.map((item, index) => {
    item.sortOrder = index + 1;
    return item;
  });

export const openModalLink = (params, location, history) => {
  const query = qs.parse(location.search || '');
  const obj = {};

  Object.keys(params).forEach((key) => {
    obj[key] = encodeURI(params[key]);
  });

  if (history && history.push) {
    history.push({
      location: location.pathName,
      search: qs.stringify({
        ...query,
        ...obj,
      }),
      state: location.state,
    });
  }
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const translateRe = /[öäüÖÄÜ]/g;
const translate = {
  ä: 'a',
  ö: 'o',
  ü: 'u',
  Ä: 'A',
  Ö: 'O',
  Ü: 'U', // probably more to come
};

export const makeSortString = (string) => string.replace(translateRe, (match) => translate[match]);

export const exportFile = async (api, candidates = null) => {
  if (candidates && candidates.length === 0) return;
  try {
    let response;
    if (api) response = await api(candidates);

    if (!response) return;
    const blob = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${moment().toISOString()}.xlsx`;
    a.click();
  } catch (error) {
    // log(error);
  }
};

export const getBackgroundByCategory = (category) => {
  switch (category) {
    case 'A':
      return {
        background: '#ccf7e5',
        color: '#00D97E',
      };

    case 'B':
      return {
        background: '#fffb95',
        color: '#bcb80b',
      };

    case 'C':
      return {
        background: '#fad7dd',
        color: '#e63757',
      };

    default:
      return {};
  }
};

export const getCategory = (category, type = 'badge') => {
  if (!category) return '';

  // retain old converted func
  const getChar = () => {
    const parseNumber = parseInt(category, 10);
    return String.fromCharCode(97 + (parseNumber - 1)).toUpperCase();
  };

  const text = typeof category === 'string' ? category : getChar(category);

  if (type === 'badge' && category !== 'None') {
    return (
      <Badge pill style={getBackgroundByCategory(text)}>
        {(text === 'A' || text === 'B' || text === 'C') && text}
      </Badge>
    );
  }

  return '-';
};

export const hexaToRgbaConverter = (color) => {
  let converted = color;
  if (color && color.includes('#')) {
    // TODO: Temporary solution remove it when we will change to RGBA,
    // Maybe whole function will be also removed in the futrue
    if (color.length === 9) {
      const firstTwo = color.slice(1, 3);
      const lastSix = color.slice(-6);
      converted = `${lastSix}${firstTwo}`;
    }
    return hexToRgba(converted);
  }

  return color;
};

export const rgbaToHexaConverter = (color) => {
  if (color && color.startsWith('rgba') && !color.includes('NaN')) {
    const hexColor = rgbHex(color);
    let converted = hexColor;
    // TODO: Temporary solution remove it when we will change to RGBA,
    // Maybe whole function will be also removed in the futrue
    if (hexColor.length === 8) {
      const firstSix = hexColor.slice(0, 6);
      const lastTwo = hexColor.slice(-2);
      converted = `${lastTwo}${firstSix}`;
    }
    return `#${converted}`;
  }

  return color;
};

export const hslaStringToHslaObjConverter = (color) => {
  if (typeof color === 'object') return color;
  const splitString = color.split('hsl');
  const hslString = /\(([^)]+)\)/.exec(splitString[1]);
  if (!splitString || !hslString) return color;
  const obj = {};
  const [h, s, l, a] = hslString[1].replace(/\D+/g, ' ').split(' ');
  obj.h = Math.round(parseInt(h, 10));
  obj.s = parseFloat(`${s}%`) / 100;
  obj.l = parseFloat(`${l}%`) / 100;
  obj.a = parseFloat(`${a}%`) / 100;
  return obj;
};

export const hslaObjToRgbaConverter = (colorObj) => {
  if (!colorObj) {
    return null;
  }

  if (typeof colorObj === 'string') {
    return colorObj;
  }

  const { h, s, l, a } = colorObj;
  return `hsl(${h}deg ${Math.round(s * 100)}% ${Math.round(l * 100)}% / ${Math.round(a * 100)}%)`;
};

export const getDefaultFontScale = (number) => {
  if (number) {
    return Math.round(number * 100);
  }

  return 100;
};

export const truncateText = (text, length, hasEllipsis = true) => {
  if (!text) return '';
  if (text.length >= length) {
    return `${text.substring(0, length)}${hasEllipsis ? '...' : ''}`;
  }
  return text;
};

const umlautsText = {
  Á: 'A',
  Ă: 'A',
  Ắ: 'A',
  Ặ: 'A',
  Ằ: 'A',
  Ẳ: 'A',
  Ẵ: 'A',
  Ǎ: 'A',
  Â: 'A',
  Ấ: 'A',
  Ậ: 'A',
  Ầ: 'A',
  Ẩ: 'A',
  Ẫ: 'A',
  Ä: 'A',
  Ǟ: 'A',
  Ȧ: 'A',
  Ǡ: 'A',
  Ạ: 'A',
  Ȁ: 'A',
  À: 'A',
  Ả: 'A',
  Ȃ: 'A',
  Ā: 'A',
  Ą: 'A',
  Å: 'A',
  Ǻ: 'A',
  Ḁ: 'A',
  Ⱥ: 'A',
  Ã: 'A',
  Ꜳ: 'AA',
  Æ: 'AE',
  Ǽ: 'AE',
  Ǣ: 'AE',
  Ꜵ: 'AO',
  Ꜷ: 'AU',
  Ꜹ: 'AV',
  Ꜻ: 'AV',
  Ꜽ: 'AY',
  Ḃ: 'B',
  Ḅ: 'B',
  Ɓ: 'B',
  Ḇ: 'B',
  Ƀ: 'B',
  Ƃ: 'B',
  Ć: 'C',
  Č: 'C',
  Ç: 'C',
  Ḉ: 'C',
  Ĉ: 'C',
  Ċ: 'C',
  Ƈ: 'C',
  Ȼ: 'C',
  Ď: 'D',
  Ḑ: 'D',
  Ḓ: 'D',
  Ḋ: 'D',
  Ḍ: 'D',
  Ɗ: 'D',
  Ḏ: 'D',
  ǲ: 'D',
  ǅ: 'D',
  Đ: 'D',
  Ƌ: 'D',
  Ǳ: 'DZ',
  Ǆ: 'DZ',
  É: 'E',
  Ĕ: 'E',
  Ě: 'E',
  Ȩ: 'E',
  Ḝ: 'E',
  Ê: 'E',
  Ế: 'E',
  Ệ: 'E',
  Ề: 'E',
  Ể: 'E',
  Ễ: 'E',
  Ḙ: 'E',
  Ë: 'E',
  Ė: 'E',
  Ẹ: 'E',
  Ȅ: 'E',
  È: 'E',
  Ẻ: 'E',
  Ȇ: 'E',
  Ē: 'E',
  Ḗ: 'E',
  Ḕ: 'E',
  Ę: 'E',
  Ɇ: 'E',
  Ẽ: 'E',
  Ḛ: 'E',
  Ꝫ: 'ET',
  Ḟ: 'F',
  Ƒ: 'F',
  Ǵ: 'G',
  Ğ: 'G',
  Ǧ: 'G',
  Ģ: 'G',
  Ĝ: 'G',
  Ġ: 'G',
  Ɠ: 'G',
  Ḡ: 'G',
  Ǥ: 'G',
  Ḫ: 'H',
  Ȟ: 'H',
  Ḩ: 'H',
  Ĥ: 'H',
  Ⱨ: 'H',
  Ḧ: 'H',
  Ḣ: 'H',
  Ḥ: 'H',
  Ħ: 'H',
  Í: 'I',
  Ĭ: 'I',
  Ǐ: 'I',
  Î: 'I',
  Ï: 'I',
  Ḯ: 'I',
  İ: 'I',
  Ị: 'I',
  Ȉ: 'I',
  Ì: 'I',
  Ỉ: 'I',
  Ȋ: 'I',
  Ī: 'I',
  Į: 'I',
  Ɨ: 'I',
  Ĩ: 'I',
  Ḭ: 'I',
  Ꝺ: 'D',
  Ꝼ: 'F',
  Ᵹ: 'G',
  Ꞃ: 'R',
  Ꞅ: 'S',
  Ꞇ: 'T',
  Ꝭ: 'IS',
  Ĵ: 'J',
  Ɉ: 'J',
  Ḱ: 'K',
  Ǩ: 'K',
  Ķ: 'K',
  Ⱪ: 'K',
  Ꝃ: 'K',
  Ḳ: 'K',
  Ƙ: 'K',
  Ḵ: 'K',
  Ꝁ: 'K',
  Ꝅ: 'K',
  Ĺ: 'L',
  Ƚ: 'L',
  Ľ: 'L',
  Ļ: 'L',
  Ḽ: 'L',
  Ḷ: 'L',
  Ḹ: 'L',
  Ⱡ: 'L',
  Ꝉ: 'L',
  Ḻ: 'L',
  Ŀ: 'L',
  Ɫ: 'L',
  ǈ: 'L',
  Ł: 'L',
  Ǉ: 'LJ',
  Ḿ: 'M',
  Ṁ: 'M',
  Ṃ: 'M',
  Ɱ: 'M',
  Ń: 'N',
  Ň: 'N',
  Ņ: 'N',
  Ṋ: 'N',
  Ṅ: 'N',
  Ṇ: 'N',
  Ǹ: 'N',
  Ɲ: 'N',
  Ṉ: 'N',
  Ƞ: 'N',
  ǋ: 'N',
  Ñ: 'N',
  Ǌ: 'NJ',
  Ó: 'O',
  Ŏ: 'O',
  Ǒ: 'O',
  Ô: 'O',
  Ố: 'O',
  Ộ: 'O',
  Ồ: 'O',
  Ổ: 'O',
  Ỗ: 'O',
  Ö: 'O',
  Ȫ: 'O',
  Ȯ: 'O',
  Ȱ: 'O',
  Ọ: 'O',
  Ő: 'O',
  Ȍ: 'O',
  Ò: 'O',
  Ỏ: 'O',
  Ơ: 'O',
  Ớ: 'O',
  Ợ: 'O',
  Ờ: 'O',
  Ở: 'O',
  Ỡ: 'O',
  Ȏ: 'O',
  Ꝋ: 'O',
  Ꝍ: 'O',
  Ō: 'O',
  Ṓ: 'O',
  Ṑ: 'O',
  Ɵ: 'O',
  Ǫ: 'O',
  Ǭ: 'O',
  Ø: 'O',
  Ǿ: 'O',
  Õ: 'O',
  Ṍ: 'O',
  Ṏ: 'O',
  Ȭ: 'O',
  Ƣ: 'OI',
  Ꝏ: 'OO',
  Ɛ: 'E',
  Ɔ: 'O',
  Ȣ: 'OU',
  Ṕ: 'P',
  Ṗ: 'P',
  Ꝓ: 'P',
  Ƥ: 'P',
  Ꝕ: 'P',
  Ᵽ: 'P',
  Ꝑ: 'P',
  Ꝙ: 'Q',
  Ꝗ: 'Q',
  Ŕ: 'R',
  Ř: 'R',
  Ŗ: 'R',
  Ṙ: 'R',
  Ṛ: 'R',
  Ṝ: 'R',
  Ȑ: 'R',
  Ȓ: 'R',
  Ṟ: 'R',
  Ɍ: 'R',
  Ɽ: 'R',
  Ꜿ: 'C',
  Ǝ: 'E',
  Ś: 'S',
  Ṥ: 'S',
  Š: 'S',
  Ṧ: 'S',
  Ş: 'S',
  Ŝ: 'S',
  Ș: 'S',
  Ṡ: 'S',
  Ṣ: 'S',
  Ṩ: 'S',
  Ť: 'T',
  Ţ: 'T',
  Ṱ: 'T',
  Ț: 'T',
  Ⱦ: 'T',
  Ṫ: 'T',
  Ṭ: 'T',
  Ƭ: 'T',
  Ṯ: 'T',
  Ʈ: 'T',
  Ŧ: 'T',
  Ɐ: 'A',
  Ꞁ: 'L',
  Ɯ: 'M',
  Ʌ: 'V',
  Ꜩ: 'TZ',
  Ú: 'U',
  Ŭ: 'U',
  Ǔ: 'U',
  Û: 'U',
  Ṷ: 'U',
  Ü: 'U',
  Ǘ: 'U',
  Ǚ: 'U',
  Ǜ: 'U',
  Ǖ: 'U',
  Ṳ: 'U',
  Ụ: 'U',
  Ű: 'U',
  Ȕ: 'U',
  Ù: 'U',
  Ủ: 'U',
  Ư: 'U',
  Ứ: 'U',
  Ự: 'U',
  Ừ: 'U',
  Ử: 'U',
  Ữ: 'U',
  Ȗ: 'U',
  Ū: 'U',
  Ṻ: 'U',
  Ų: 'U',
  Ů: 'U',
  Ũ: 'U',
  Ṹ: 'U',
  Ṵ: 'U',
  Ꝟ: 'V',
  Ṿ: 'V',
  Ʋ: 'V',
  Ṽ: 'V',
  Ꝡ: 'VY',
  Ẃ: 'W',
  Ŵ: 'W',
  Ẅ: 'W',
  Ẇ: 'W',
  Ẉ: 'W',
  Ẁ: 'W',
  Ⱳ: 'W',
  Ẍ: 'X',
  Ẋ: 'X',
  Ý: 'Y',
  Ŷ: 'Y',
  Ÿ: 'Y',
  Ẏ: 'Y',
  Ỵ: 'Y',
  Ỳ: 'Y',
  Ƴ: 'Y',
  Ỷ: 'Y',
  Ỿ: 'Y',
  Ȳ: 'Y',
  Ɏ: 'Y',
  Ỹ: 'Y',
  Ź: 'Z',
  Ž: 'Z',
  Ẑ: 'Z',
  Ⱬ: 'Z',
  Ż: 'Z',
  Ẓ: 'Z',
  Ȥ: 'Z',
  Ẕ: 'Z',
  Ƶ: 'Z',
  Ĳ: 'IJ',
  Œ: 'OE',
  ᴀ: 'A',
  ᴁ: 'AE',
  ʙ: 'B',
  ᴃ: 'B',
  ᴄ: 'C',
  ᴅ: 'D',
  ᴇ: 'E',
  ꜰ: 'F',
  ɢ: 'G',
  ʛ: 'G',
  ʜ: 'H',
  ɪ: 'I',
  ʁ: 'R',
  ᴊ: 'J',
  ᴋ: 'K',
  ʟ: 'L',
  ᴌ: 'L',
  ᴍ: 'M',
  ɴ: 'N',
  ᴏ: 'O',
  ɶ: 'OE',
  ᴐ: 'O',
  ᴕ: 'OU',
  ᴘ: 'P',
  ʀ: 'R',
  ᴎ: 'N',
  ᴙ: 'R',
  ꜱ: 'S',
  ᴛ: 'T',
  ⱻ: 'E',
  ᴚ: 'R',
  ᴜ: 'U',
  ᴠ: 'V',
  ᴡ: 'W',
  ʏ: 'Y',
  ᴢ: 'Z',
  á: 'a',
  ă: 'a',
  ắ: 'a',
  ặ: 'a',
  ằ: 'a',
  ẳ: 'a',
  ẵ: 'a',
  ǎ: 'a',
  â: 'a',
  ấ: 'a',
  ậ: 'a',
  ầ: 'a',
  ẩ: 'a',
  ẫ: 'a',
  ä: 'a',
  ǟ: 'a',
  ȧ: 'a',
  ǡ: 'a',
  ạ: 'a',
  ȁ: 'a',
  à: 'a',
  ả: 'a',
  ȃ: 'a',
  ā: 'a',
  ą: 'a',
  ᶏ: 'a',
  ẚ: 'a',
  å: 'a',
  ǻ: 'a',
  ḁ: 'a',
  ⱥ: 'a',
  ã: 'a',
  ꜳ: 'aa',
  æ: 'ae',
  ǽ: 'ae',
  ǣ: 'ae',
  ꜵ: 'ao',
  ꜷ: 'au',
  ꜹ: 'av',
  ꜻ: 'av',
  ꜽ: 'ay',
  ḃ: 'b',
  ḅ: 'b',
  ɓ: 'b',
  ḇ: 'b',
  ᵬ: 'b',
  ᶀ: 'b',
  ƀ: 'b',
  ƃ: 'b',
  ɵ: 'o',
  ć: 'c',
  č: 'c',
  ç: 'c',
  ḉ: 'c',
  ĉ: 'c',
  ɕ: 'c',
  ċ: 'c',
  ƈ: 'c',
  ȼ: 'c',
  ď: 'd',
  ḑ: 'd',
  ḓ: 'd',
  ȡ: 'd',
  ḋ: 'd',
  ḍ: 'd',
  ɗ: 'd',
  ᶑ: 'd',
  ḏ: 'd',
  ᵭ: 'd',
  ᶁ: 'd',
  đ: 'd',
  ɖ: 'd',
  ƌ: 'd',
  ı: 'i',
  ȷ: 'j',
  ɟ: 'j',
  ʄ: 'j',
  ǳ: 'dz',
  ǆ: 'dz',
  é: 'e',
  ĕ: 'e',
  ě: 'e',
  ȩ: 'e',
  ḝ: 'e',
  ê: 'e',
  ế: 'e',
  ệ: 'e',
  ề: 'e',
  ể: 'e',
  ễ: 'e',
  ḙ: 'e',
  ë: 'e',
  ė: 'e',
  ẹ: 'e',
  ȅ: 'e',
  è: 'e',
  ẻ: 'e',
  ȇ: 'e',
  ē: 'e',
  ḗ: 'e',
  ḕ: 'e',
  ⱸ: 'e',
  ę: 'e',
  ᶒ: 'e',
  ɇ: 'e',
  ẽ: 'e',
  ḛ: 'e',
  ꝫ: 'et',
  ḟ: 'f',
  ƒ: 'f',
  ᵮ: 'f',
  ᶂ: 'f',
  ǵ: 'g',
  ğ: 'g',
  ǧ: 'g',
  ģ: 'g',
  ĝ: 'g',
  ġ: 'g',
  ɠ: 'g',
  ḡ: 'g',
  ᶃ: 'g',
  ǥ: 'g',
  ḫ: 'h',
  ȟ: 'h',
  ḩ: 'h',
  ĥ: 'h',
  ⱨ: 'h',
  ḧ: 'h',
  ḣ: 'h',
  ḥ: 'h',
  ɦ: 'h',
  ẖ: 'h',
  ħ: 'h',
  ƕ: 'hv',
  í: 'i',
  ĭ: 'i',
  ǐ: 'i',
  î: 'i',
  ï: 'i',
  ḯ: 'i',
  ị: 'i',
  ȉ: 'i',
  ì: 'i',
  ỉ: 'i',
  ȋ: 'i',
  ī: 'i',
  į: 'i',
  ᶖ: 'i',
  ɨ: 'i',
  ĩ: 'i',
  ḭ: 'i',
  ꝺ: 'd',
  ꝼ: 'f',
  ᵹ: 'g',
  ꞃ: 'r',
  ꞅ: 's',
  ꞇ: 't',
  ꝭ: 'is',
  ǰ: 'j',
  ĵ: 'j',
  ʝ: 'j',
  ɉ: 'j',
  ḱ: 'k',
  ǩ: 'k',
  ķ: 'k',
  ⱪ: 'k',
  ꝃ: 'k',
  ḳ: 'k',
  ƙ: 'k',
  ḵ: 'k',
  ᶄ: 'k',
  ꝁ: 'k',
  ꝅ: 'k',
  ĺ: 'l',
  ƚ: 'l',
  ɬ: 'l',
  ľ: 'l',
  ļ: 'l',
  ḽ: 'l',
  ȴ: 'l',
  ḷ: 'l',
  ḹ: 'l',
  ⱡ: 'l',
  ꝉ: 'l',
  ḻ: 'l',
  ŀ: 'l',
  ɫ: 'l',
  ᶅ: 'l',
  ɭ: 'l',
  ł: 'l',
  ǉ: 'lj',
  ſ: 's',
  ẜ: 's',
  ẛ: 's',
  ẝ: 's',
  ḿ: 'm',
  ṁ: 'm',
  ṃ: 'm',
  ɱ: 'm',
  ᵯ: 'm',
  ᶆ: 'm',
  ń: 'n',
  ň: 'n',
  ņ: 'n',
  ṋ: 'n',
  ȵ: 'n',
  ṅ: 'n',
  ṇ: 'n',
  ǹ: 'n',
  ɲ: 'n',
  ṉ: 'n',
  ƞ: 'n',
  ᵰ: 'n',
  ᶇ: 'n',
  ɳ: 'n',
  ñ: 'n',
  ǌ: 'nj',
  ó: 'o',
  ŏ: 'o',
  ǒ: 'o',
  ô: 'o',
  ố: 'o',
  ộ: 'o',
  ồ: 'o',
  ổ: 'o',
  ỗ: 'o',
  ö: 'o',
  ȫ: 'o',
  ȯ: 'o',
  ȱ: 'o',
  ọ: 'o',
  ő: 'o',
  ȍ: 'o',
  ò: 'o',
  ỏ: 'o',
  ơ: 'o',
  ớ: 'o',
  ợ: 'o',
  ờ: 'o',
  ở: 'o',
  ỡ: 'o',
  ȏ: 'o',
  ꝋ: 'o',
  ꝍ: 'o',
  ⱺ: 'o',
  ō: 'o',
  ṓ: 'o',
  ṑ: 'o',
  ǫ: 'o',
  ǭ: 'o',
  ø: 'o',
  ǿ: 'o',
  õ: 'o',
  ṍ: 'o',
  ṏ: 'o',
  ȭ: 'o',
  ƣ: 'oi',
  ꝏ: 'oo',
  ɛ: 'e',
  ᶓ: 'e',
  ɔ: 'o',
  ᶗ: 'o',
  ȣ: 'ou',
  ṕ: 'p',
  ṗ: 'p',
  ꝓ: 'p',
  ƥ: 'p',
  ᵱ: 'p',
  ᶈ: 'p',
  ꝕ: 'p',
  ᵽ: 'p',
  ꝑ: 'p',
  ꝙ: 'q',
  ʠ: 'q',
  ɋ: 'q',
  ꝗ: 'q',
  ŕ: 'r',
  ř: 'r',
  ŗ: 'r',
  ṙ: 'r',
  ṛ: 'r',
  ṝ: 'r',
  ȑ: 'r',
  ɾ: 'r',
  ᵳ: 'r',
  ȓ: 'r',
  ṟ: 'r',
  ɼ: 'r',
  ᵲ: 'r',
  ᶉ: 'r',
  ɍ: 'r',
  ɽ: 'r',
  ↄ: 'c',
  ꜿ: 'c',
  ɘ: 'e',
  ɿ: 'r',
  ś: 's',
  ṥ: 's',
  š: 's',
  ṧ: 's',
  ş: 's',
  ŝ: 's',
  ș: 's',
  ṡ: 's',
  ṣ: 's',
  ṩ: 's',
  ʂ: 's',
  ᵴ: 's',
  ᶊ: 's',
  ȿ: 's',
  ɡ: 'g',
  ᴑ: 'o',
  ᴓ: 'o',
  ᴝ: 'u',
  ť: 't',
  ţ: 't',
  ṱ: 't',
  ț: 't',
  ȶ: 't',
  ẗ: 't',
  ⱦ: 't',
  ṫ: 't',
  ṭ: 't',
  ƭ: 't',
  ṯ: 't',
  ᵵ: 't',
  ƫ: 't',
  ʈ: 't',
  ŧ: 't',
  ᵺ: 'th',
  ɐ: 'a',
  ᴂ: 'ae',
  ǝ: 'e',
  ᵷ: 'g',
  ɥ: 'h',
  ʮ: 'h',
  ʯ: 'h',
  ᴉ: 'i',
  ʞ: 'k',
  ꞁ: 'l',
  ɯ: 'm',
  ɰ: 'm',
  ᴔ: 'oe',
  ɹ: 'r',
  ɻ: 'r',
  ɺ: 'r',
  ⱹ: 'r',
  ʇ: 't',
  ʌ: 'v',
  ʍ: 'w',
  ʎ: 'y',
  ꜩ: 'tz',
  ú: 'u',
  ŭ: 'u',
  ǔ: 'u',
  û: 'u',
  ṷ: 'u',
  ü: 'u',
  ǘ: 'u',
  ǚ: 'u',
  ǜ: 'u',
  ǖ: 'u',
  ṳ: 'u',
  ụ: 'u',
  ű: 'u',
  ȕ: 'u',
  ù: 'u',
  ủ: 'u',
  ư: 'u',
  ứ: 'u',
  ự: 'u',
  ừ: 'u',
  ử: 'u',
  ữ: 'u',
  ȗ: 'u',
  ū: 'u',
  ṻ: 'u',
  ų: 'u',
  ᶙ: 'u',
  ů: 'u',
  ũ: 'u',
  ṹ: 'u',
  ṵ: 'u',
  ᵫ: 'ue',
  ꝸ: 'um',
  ⱴ: 'v',
  ꝟ: 'v',
  ṿ: 'v',
  ʋ: 'v',
  ᶌ: 'v',
  ⱱ: 'v',
  ṽ: 'v',
  ꝡ: 'vy',
  ẃ: 'w',
  ŵ: 'w',
  ẅ: 'w',
  ẇ: 'w',
  ẉ: 'w',
  ẁ: 'w',
  ⱳ: 'w',
  ẘ: 'w',
  ẍ: 'x',
  ẋ: 'x',
  ᶍ: 'x',
  ý: 'y',
  ŷ: 'y',
  ÿ: 'y',
  ẏ: 'y',
  ỵ: 'y',
  ỳ: 'y',
  ƴ: 'y',
  ỷ: 'y',
  ỿ: 'y',
  ȳ: 'y',
  ẙ: 'y',
  ɏ: 'y',
  ỹ: 'y',
  ź: 'z',
  ž: 'z',
  ẑ: 'z',
  ʑ: 'z',
  ⱬ: 'z',
  ż: 'z',
  ẓ: 'z',
  ȥ: 'z',
  ẕ: 'z',
  ᵶ: 'z',
  ᶎ: 'z',
  ʐ: 'z',
  ƶ: 'z',
  ɀ: 'z',
  ﬀ: 'ff',
  ﬃ: 'ffi',
  ﬄ: 'ffl',
  ﬁ: 'fi',
  ﬂ: 'fl',
  ĳ: 'ij',
  œ: 'oe',
  ﬆ: 'st',
  ₐ: 'a',
  ₑ: 'e',
  ᵢ: 'i',
  ⱼ: 'j',
  ₒ: 'o',
  ᵣ: 'r',
  ᵤ: 'u',
  ᵥ: 'v',
  ₓ: 'x',
};

export const latinize = (text) => text.replace(/[^A-Za-z0-9 ]/g, (match) => umlautsText[match] || match);

export function generateHtmlFriendlyKeyValue(text) {
  // http://stackoverflow.com/questions/1053902/how-to-convert-a-title-to-a-url-slug-in-jquery
  // copied from old portal
  return text
    .toLowerCase()
    .trim()
    .replace(/å/g, 'a')
    .replace(/ä/g, 'a')
    .replace(/ö/g, 'o')
    .replace(/[^\w- ]+/g, '')
    .replace(/ +/g, '-');
}

export const removeSpecialCharacters = (text) => text.replace(/[^a-zA-Z0-9\\-]/g, '');
export const convertSpacesToDash = (text) => text.replace(/\s+/g, '-');
export const createLinkSuffix = (text) => generateHtmlFriendlyKeyValue(text);

export const isValidHttpURL = (text) => {
  const pattern = new RegExp(
    '^https?:\\/\\/' + // protocol, require http
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%@_.~+&:]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i' // fragment locator
  );
  return !!pattern.test(text);
};

export const isValidHttpOrMailtoURL = (text) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/|mailto:)' + // protocol, allow http, https, or mailto
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name for http/https
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address for http/https
      '(\\:\\d+)?(\\/[-a-z\\d%@_.~+&:]*)*' + // port and path for http/https
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string for http/https
      '(\\#[-a-z\\d_]*)?$|^mailto:' + // fragment locator or mailto address start
      '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$', // valid email after mailto
    'i'
  );
  return !!pattern.test(text);
};

export const isBrowserIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');

  if (msie > 0) return true;
  return false;
};

export const getBrowser = () => {
  const ua = window.navigator.userAgent;
  let currentBrowser = 'Uknown';
  forEach(['Chrome', 'Firefox', 'MSIE ', 'Edge'], (item) => {
    if (ua.includes(item)) {
      currentBrowser = item;
    }
  });
  return currentBrowser;
};

export const appendRelOnYoutubeLinks = (text) => {
  const matches = text.match(/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gm);

  let str = text || '';

  (matches || []).forEach((item) => {
    if (item.includes('youtube.com') && !item.includes('rel=')) {
      if (item.includes('?')) {
        str = str.replace(item, `${item}&rel=0`);
      } else {
        str = str.replace(item, `${item}?rel=0`);
      }
    }
  });

  return str;
};

export const createClassName = (value = '', suffix = '') => {
  if (suffix) {
    return `${value}-${suffix}`;
  }

  return value;
};

export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    navigator.userAgent.includes('Mac')
  );
};

export const cleanUpFilter = (filterObj, keysForRemoval = []) => {
  if (!keysForRemoval.length) return filterObj;
  return omit(filterObj, keysForRemoval);
};

export const getError = (error) => {
  if (error?.response) {
    return error?.response.data?.errors?.[0]?.errorKey || error.response.data?.error?.message;
  }
  return error;
};

export const scrollElementIntoView = (elementRef, behavior = 'smooth') => {
  const { current } = elementRef;
     if (current !== null) {
       current.scrollIntoView({ behavior });
     }
};
