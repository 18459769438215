/* eslint-disable no-useless-escape */
import React, { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { log } from '../../../helpers/utils';
import Icon from '../Icon';
import { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import { onExportCandidates } from '../../../helpers/export';
import { restorePosition, duplicatePosition } from '../../../store/api/positions.api';

const MoreActionButton = ({ id, name, data, archived, index }) => {
  const { reloadData } = useApiRequestContext();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const queryParams = useMemo(() => qs.parse(location.search));

  const restorePositionData = async (id) => {
    try {
      await restorePosition(parseInt(id, 10));
      reloadData();
    } catch (error) {
      log('error', error);
    }
  };

  const duplicatePositionData = async (id) => {
    try {
      await duplicatePosition(id);
      reloadData();

      history.push({
        ...location,
        search: '?modal=position-duplicate-success',
      });
    } catch (error) {
      log('error', error);
    }
  };

  const onSelect = ({ key }) => {
    switch (key) {
      case 'restore': {
        restorePositionData(id);
        return;
      }

      case 'archive': {
        history.push({
          ...location,
          search: qs.stringify({
            ...queryParams,
            modal: 'archive-position',
            length: data.length,
            id,
          }),
        });
        return;
      }

      case 'export': {
        onExportCandidates({ id, name });
        return;
      }

      case 'duplicate': {
        duplicatePositionData(id);
        return;
      }

      default:
        log('cannot find an action');
        break;
    }
  };

  const menu = (
    <Menu onSelect={onSelect}>
      {!archived ? (
        <MenuItem key="archive">
          <button type="button" className="flex items-center" data-testid={`option-${index}-archive`}>
            <Icon name="archive" className="mr-2" /> {t('general.archive')}
          </button>
        </MenuItem>
      ) : (
        <MenuItem key="restore">
          <button type="button" className="flex items-center" data-testid={`option-${index}-restore`}>
            <Icon name="window-restore" className="mr-2" />
            {t('general.restore')}
          </button>
        </MenuItem>
      )}
      <MenuItem key="duplicate">
        <button type="button" className="flex items-center" data-testid={`option-${index}-duplicate`}>
          <Icon name="copy" className="mr-2" /> {t('general.duplicate')} {t('general.position')}
        </button>
      </MenuItem>
      <MenuItem key="export">
        <button type="button" className="flex items-center" data-testid={`option-${index}-export`}>
          <Icon name="file-export" className="mr-2" />
          {t('candidates.export')}
        </button>
      </MenuItem>
    </Menu>
  );

  return (
    <Dropdown trigger={['click']} overlay={menu} animation="slide-up">
      <button type="button" data-testid="position-more-actions">
        <Icon name="more-vertical" color="black" />
      </button>
    </Dropdown>
  );
};

export default MoreActionButton;
