/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import Layout from '../../../components/layouts/scheduler';
import Loader from '../../../components/Loader';

const NylasCallback = () => {
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const parsedQueryString = qs.parse(location.search);

      if (parsedQueryString.code) {
        localStorage.setItem('ts-scheduler-code', parsedQueryString.code);
        try {
          window.openRef = window.openRef
            ? window.openRef.close()
            : window.close();
        } catch (error) {
          console.error('Cannot close window', error);
        }
      }
    })();
  }, [location]);

  return (
    <Layout syncing>
      <div className="d-flex align-items-center justify-content-center h-100">
        <Loader
          type="primary"
          size={60}
          customLoaderStyles={{
            top: -37,
          }}
        />
      </div>
    </Layout>
  );
};

export default NylasCallback;
