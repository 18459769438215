import React, { useState, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import useElementClickHandlers from '../../../../hooks/useElementClickHandlers';
import Filter from '../../../../components/@v2/Filter/Filter';
import SelectFilter from '../../../../components/@v2/Filter/SelectFilter';
import ButtonFilter from '../../../../components/@v2/Button/ButtonFilter';
import useFilterModal from '../../../../hooks/useFilterModal';
import Icon from '../../../../components/@v2/Icon';
import { log } from '../../../../helpers/utils';
import { getClientAccessDetails } from '../../../../store/api/user.api';

const UserFilter = () => {
  const { t } = useTranslation();
  const [isFilterShown, setIsFilterShown] = useState(false);
  const { meta, setMeta } = useApiRequestContext();

  const defaultOption = {
    value: null,
    label: t('general.all'),
  };

  const DeletedOptions = [
    defaultOption,
    {
      value: 'true',
      label: t('general.yes'),
    },
    {
      value: 'false',
      label: t('general.no'),
    },
  ];

  useElementClickHandlers({
    condition: isFilterShown,
    callback: setIsFilterShown,
    elements: ['.ts-filter-button', '.ts-filter', '.rc-dropdown', '.searchable-select'],
  });

  useFilterModal({
    show: isFilterShown,
  });

  const changeMeta = (key, value) => {
    const currentFilters = [...meta.filters].filter((item) => item.key !== key);

    setMeta({
      ...meta,
      filters: value === '' || !!value ? [...currentFilters, { key, value }] : currentFilters,
      page: 1,
    });
    setIsFilterShown(false);
  };

  const [clients, setClients] = useState([]);

  const getClientOptions = async () => {
    try {
      const response = await getClientAccessDetails();

      setClients(
        response?.clients?.map((item) => ({
          ...item,
          value: item?.id,
          label: item?.name,
        }))
      );
    } catch (error) {
      log(error);
    }
  };

  const ClientsOptions = useMemo(() => {
    return [defaultOption, ...clients];
  }, [clients]);

  useEffect(() => {
    getClientOptions();
  }, []);

  const getFilterValue = (key, defaultValue, callback) => {
    const foundItem = meta?.filters?.find((item) => item?.key === key);

    if (callback) {
      const callbackValue = callback(foundItem);

      if (callbackValue?.value) {
        return callbackValue;
      }
    }

    if (foundItem?.value) {
      return foundItem;
    }

    return defaultValue;
  };

  const onClientSelect = (selected) => {
    changeMeta('name', selected?.id || null);
  };

  const onShowRemovedSelect = (selected) => {
    changeMeta('deleted', selected?.value || null);
  };

  return (
    <>
      <ButtonFilter isShown={isFilterShown} callback={setIsFilterShown} filters={get(meta, 'filters') || []} />
      {createPortal(
        <Filter show={isFilterShown}>
          <div className="flex justify-end">
            <button
              data-testid="filter-modal-close-button"
              type="button"
              onClick={() => {
                setIsFilterShown(false);
              }}
            >
              <Icon name="times" />
            </button>
          </div>
          <div className="user-filters">
            <SelectFilter
              id="user-filter--account"
              label={t('general.account')}
              searchKey="name"
              options={ClientsOptions}
              callback={onClientSelect}
              value={getFilterValue('name', ClientsOptions[0], (client) => {
                return ClientsOptions.find((item) => item?.value === client?.value);
              })}
            />
            <SelectFilter
              id="user-filter--show-removed"
              label={t('users.show-removed')}
              options={DeletedOptions}
              callback={onShowRemovedSelect}
              value={getFilterValue('deleted', DeletedOptions[0], (filter) => {
                return DeletedOptions.find((item) => item?.value === filter?.value);
              })}
            />
          </div>
        </Filter>,
        document.body
      )}
    </>
  );
};

export default UserFilter;
