import { isFunction, omit } from 'lodash';
import { TYPES } from '../../constants/CareerPage';
import { api } from '../../helpers/api-v2';

const resource = '/page';

export const checkClientUploadedFonts = async (clientId) => {
  const response = await api.get(`${resource}/${clientId}/HasClientAnUploadedFontCss`);
  return response;
};

export const createAndUploadFontCss = async (clientId, form) => {
  const response = await api.post(`${resource}/${clientId}/css-font-upload`, form);
  return response;
};

export const uploadFont = async ({ type, clientId, body, headers = {}, options = {} }) => {
  const response = await api.post(`${resource}/${clientId}/font-upload/${type}`, body, {
    ...headers,
    ...options,
  });

  return response;
};

export const getCareerPageDetails = async (options = {}) => {
  const response = await api.get(`${resource}/details`, options);

  return response;
};

export const onPublishMainPage = async (data, options = {}) => {
  const response = await api.post(
    `${resource}/publish`,
    {
      data: {
        ...data,
        id: data?.pageId,
      },
    },
    options
  );

  return response;
};

export const onUpdateMainPage = async (data, options = {}) => {
  const response = await api.post(`${resource}/update`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...options,
  });

  return response;
};

export const onBlockUpdate = async (details, item = null, callback = null) => {
  const formData = new FormData();
  const finalData: { blocks: any[]; id: string; pageId?: string } = {
    id: details?.pageId,
    ...omit(details, ['pageId']),
    blocks: [...details.blocks],
  };

  if (item?.pageBlockId === 0) {
    // is adding/duplicating
    finalData.blocks = [...finalData.blocks, item];
  } else if (item?.pageBlockId) {
    // updating block
    finalData.blocks = finalData?.blocks?.map((blockItem) => {
      if (blockItem?.pageBlockId === item?.pageBlockId) {
        if (item?.type === TYPES.FOOTER && item?.logoUpload) {
          formData.append('file-0', item?.logoUpload);

          return omit(item, ['logoUpload']);
        }

        if (item?.type === TYPES.HEADER) {
          if (item?.imageUpload) {
            formData.append('file-image', item?.imageUpload);
          }
          if (item?.videoUpload) {
            formData.append('file-video', item?.videoUpload);
          }
          if (item?.logoUpload) {
            formData.append('file-logo', item?.logoUpload);
          }

          return omit(item, ['imageUpload', 'videoUpload', 'logoUpload']);
        }

        if (item?.type === TYPES.TEXT_PICTURE && item?.imageUpload) {
          formData.append('file-0', item?.imageUpload);

          return omit(item, ['imageUpload']);
        }

        if (item?.type === TYPES.INFORMATION_ITEMS) {
          item?.informationItems?.forEach((item, index) => {
            if (item?.imageUpload) {
              formData.append(`file-${index}`, item?.imageUpload);
            }
          });

          return {
            ...item,
            informationItems: item?.informationItems?.map((infoItem) => {
              return omit(infoItem, ['imageUpload']);
            }),
          };
        }

        if (item?.type === TYPES.CAROUSEL) {
          item?.carouselItems?.forEach((item, index) => {
            if (item?.imageUpload) {
              formData.append(`file-${index}`, item?.imageUpload);
            }
          });

          return {
            ...item,
            carouselItems: item?.carouselItems?.map((infoItem) => {
              return omit(infoItem, ['imageUpload']);
            }),
          };
        }

        return item;
      }

      return blockItem;
    });
  }

  finalData.blocks.map((item) => {
    if (!item?.backgroundColorHex) {
      item.backgroundColorHex = '#ffffff';
    }

    if (!item?.headerTextColorHex) {
      item.headerTextColorHex = '#000000';
    }

    if (!item?.subtitleTextColorHex) {
      item.subtitleTextColorHex = '#000000';
    }

    if (!item?.textColorHex) {
      item.textColorHex = '#000000';
    }

    return item;
  });

  formData.append(
    'data',
    JSON.stringify({
      ...finalData,
      id: !finalData.id ? finalData?.pageId : finalData.id,
      blocks: finalData.blocks.map((item) => {
        if (item.pageId) {
          return {
            ...item,
            id: item.pageId,
          };
        }
        return item;
      }),
    })
  );

  const response = await onUpdateMainPage(formData);

  if (isFunction(callback)) {
    callback(response);
  }

  return response;
};

export const onUpdateResultBlocksOrder = async (details, callback = null) => {
  const response = await onBlockUpdate(details, null, callback);
  return response;
};

export const onDeleteBlock = async (details, item, callback) => {
  const finalData = {
    ...details,
    blocks: details.blocks.filter((blockItem) => {
      return blockItem?.pageBlockId !== item?.pageBlockId;
    }),
  };

  const formData = new FormData();
  formData.append(
    'data',
    JSON.stringify({
      ...finalData,
      id: !finalData.id ? finalData?.pageId : finalData.id,
      blocks: finalData.blocks.map((item) => {
        if (item.pageId) {
          return {
            ...item,
            id: item.pageId,
          };
        }
        return item;
      }),
    })
  );

  const response = await onUpdateMainPage(formData);

  if (isFunction(callback)) {
    callback(response);
  }

  return response;
};
