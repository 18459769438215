import { createAsyncThunk } from '@reduxjs/toolkit';
import UpdateClientGdprSettingsCommand from '@ts/apicontracts/clients/gdpr/update-client-gdpr-settings-command';
import {
  getClient as getClientRequest,
  updateClient as updateClientRequest,
  getClientSettings as getClientSettingsRequest,
  updateClientGdprSettings as updateClientGdprSettingsRequest,
  getClientsConfig as getClientsConfigRequest,
  getExternalConfig as getClientsExternalConfigRequest,
} from '../api/client.api';

interface GetClientPayload {
  superAdmin: boolean;
  id: number | { clientId?: number; id?: number };
}

interface UpdateClientPayload {
  id: string;
  form: {
    name?: string;
    domain?: string;
    logo?: string;
    contactEmail?: string;
  };
}

export const getClientSettings = createAsyncThunk('client/getClientSettings', async () => {
  const settings = await getClientSettingsRequest();

  return settings;
});

export const getClientsConfig = createAsyncThunk('client/getClientsConfig', async () => {
  const clientsConfig = await getClientsConfigRequest();
  return clientsConfig;
});

export const getClientsExternalConfig = createAsyncThunk('client/getClientsExternalConfig', async () => {
  const clientsConfig = await getClientsExternalConfigRequest();
  return clientsConfig;
});

export const getClient = createAsyncThunk('client/getClient', async ({ superAdmin, id }: GetClientPayload) => {
  const parsedId = typeof id === 'number' ? id : id?.clientId || id?.id;
  const client = await getClientRequest(superAdmin, parsedId);

  return {
    ...client,
    id: parsedId,
  };
});

export const updateClient = createAsyncThunk('client/updateClient', async ({ id, form }: UpdateClientPayload) => {
  const client = await updateClientRequest(id, form);

  return {
    ...client,
    id,
  };
});

export const updateGDPR = createAsyncThunk(
  'client/updateGDPR',
  async ({ form }: { form: UpdateClientGdprSettingsCommand }) => {
    const data = await updateClientGdprSettingsRequest(form);
    return data;
  }
);
