import React from 'react';
import { Col } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { StyledSelect } from './form/Form.styled';

const FilterAutocomplete = ({
  label,
  width,
  className,
  labelClassName,
  selectClassName,
  verticalAlign,
  ...params
}) => {
  const testId = 'filter-autocomplete-component';

  return (
    <Col
      className={classnames(
        !verticalAlign
          ? 'd-flex align-items-center mt-2 mb-2 mr-4 flex-grow-0 text-nowrap item-flex'
          : 'mb-4',
        className
      )}
      data-testid={testId}
      style={{
        ...params.style,
      }}
    >
      {label && <h5 className={classnames(labelClassName)}>{label}</h5>}
      <div style={{ width: !verticalAlign && width }}>
        <StyledSelect
          className={selectClassName}
          isClearable={
            get(params, 'value.value') !== null &&
            Object.keys(get(params, 'value') || {}).length
          }
          {...params}
        />
      </div>
    </Col>
  );
};

FilterAutocomplete.propTypes = {
  label: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  selectClassName: PropTypes.string,
  verticalAlign: PropTypes.bool,
};

FilterAutocomplete.defaultProps = {
  label: '',
  width: '150px',
  className: '',
  labelClassName: '',
  selectClassName: '',
  verticalAlign: false,
};

export default FilterAutocomplete;
