import React from 'react';
import { useTranslation } from 'react-i18next';
import ApplicationEvents from '../../../../components/@v2/ApplicationEvents/ApplicationEvents';
import PipelineSteps from '../../../../components/@v2/Pipelines/PipelineSteps';
import PipelineTemplates from '../../../../components/@v2/Pipelines/PipelineTemplates';
import Layout from '../../../../components/layouts/default';

const Pipelines = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('general.pipeline')} pretitle={t('general.settings')}>
      <div className="flex flex-col gap-y-10">
        <PipelineSteps />
        <PipelineTemplates />
        <ApplicationEvents />
      </div>
    </Layout>
  );
};

export default Pipelines;
