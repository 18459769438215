import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';
import qs from 'query-string';
import { get } from 'lodash';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { Formik } from 'formik';
import { useAPI } from '../context/api';
import Field from '../components/@v2/Form/Field';
import Button from '../components/Button';
import Logo from '../assets/svg/logo.svg';

YupPassword(Yup);

const changePassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [userAPI] = useAPI('user');
  const { search } = useLocation();
  const { token } = qs.parse(search);
  const [error, setError] = useState('');
  const [tokenLoading, setTokenLoading] = useState(false);
  const [tokenStatus, setTokenStatus] = useState({ valid: true });

  const onSubmit = async (values) => {
    try {
      await userAPI.changePasswordToken({
        token,
        newPassword: values.password,
      });

      history.push('/');
    } catch ({ data }) {
      setError(get(data, 'error.message') || '');
    }
  };

  useEffect(() => {
    if (!token) history.push('/');
  }, [token]);

  useEffect(() => {
    (async () => {
      if (tokenLoading) return;
      setTokenLoading(true);
      const response = await userAPI.checkPasswordToken(token);

      if (!response?.valid) {
        setTokenStatus(response);

        setTimeout(() => {
          history.push('/');
        }, 5000);
        setTokenLoading(false);
      }
    })();
  }, [tokenStatus]);

  const ChangePasswordFormSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('form-validator.required'))
      .min(8, t('form-validator.minlength', { length: 8 }))
      .minLowercase(1, t('form-validator.with-lowercase'))
      .minUppercase(1, t('form-validator.with-uppercase'))
      .minNumbers(1, t('form-validator.with-number')),
    newPassword: Yup.string()
      .nullable()
      .oneOf([Yup.ref('password'), null], t('form-validator.same-password')),
  });

  return (
    <div className="login-container" data-testid="change-password-container">
      <div className="sign-in">
        <div className="form">
          <p className="!text-black">{t('general.recover-account')}</p>
          <div
            className="input mb-20 flex flex-col items-center"
            data-testid="change-password-form"
          >
            {!tokenStatus.valid && (
              <div className="input text-center">
                <h1>{tokenStatus.message}</h1>
                <h2>{t('general.redirect-to-login-page')}</h2>
              </div>
            )}
            {!!error && <Alert color="danger">{error}</Alert>}
            {tokenStatus.valid && (
              <Formik
                initialValues={{
                  password: '',
                  newPassword: '',
                }}
                validationSchema={ChangePasswordFormSchema}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, isSubmitting }) => {
                  return (
                    <form onSubmit={handleSubmit} className="w-full">
                      <Field
                        type="password"
                        name="password"
                        placeholder={t('reset-password.password')}
                        required
                      />
                      <Field
                        type="password"
                        name="newPassword"
                        placeholder={t('reset-password.confirm-password')}
                        required
                      />
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                      >
                        {t('reset-password.reset-password')}
                      </Button>
                    </form>
                  );
                }}
              </Formik>
            )}
            <Link to="/">{t('general.login-here')}</Link>
          </div>
        </div>
        <img className="logo" src={Logo} alt="logo" />
      </div>
    </div>
  );
};

export default changePassword;
