import qs from 'query-string';
import helper, { api } from '../helpers/api';

const resource = 'Positions';

export default {
  ...helper(resource),
  searchV2: (meta) => api.get(`${resource}/List?${qs.stringify(meta)}`),
  mentionSearch: (positionId, search) => api.get(`${resource}/${positionId}/mentions?search=${search}`),
  getPositionsDetails: (headers = {}) => api.get(`${resource}/GetPositionsDetails`, headers),
  getPositionsList: (headers = {}) => api.get(`${resource}/careerpage-list`, headers),
  updatePositionsOrder: (form, headers = {}) => api.put(`${resource}/careerpage-list`, form, headers),
  getPosition: (id) => api.get(`${resource}/${id}`),
  addPosition: (form = {}, options = {}) => api.post(`${resource}`, { form }, options),
  previewPosition: (form = {}, options = {}) => api.get(`${resource}/${form.positionId}/preview`, options),
  updatePosition: (form = {}, options = {}) => api.put(`${resource}/Update`, { form }, options),
  saveDraftPosition: (form = {}, options = {}) => api.put(`${resource}/${form.positionId}/draft`, { form }, options),
  publishPosition: (form = {}, options = {}) => api.put(`${resource}/${form.positionId}/publish`, { form }, options),
  deletePosition: (form) => api.delete(`${resource}/Delete`, form),
  archivePosition: (form = {}, options = {}) => api.post(`${resource}/Archive/${form.id}`, form, options),
  restorePosition: (form = {}, options = {}) => api.post(`${resource}/Restore/${form.id}`, form, options),
  duplicate: (id) =>
    api.post(`${resource}/${id}/duplicate`, {
      headers: { 'Content-Type': 'application/json' },
    }),
  criterias: (id, headers = {}) => api.get(`${resource}/${id}/criterias`, headers),
  getRecruiters: (payload) => api.get(`${resource}/recruiters?${qs.stringify(payload, { encode: false })}`),
  getPipelineSteps: (id, filters = {}) => api.get(`${resource}/${id}/steps?${qs.stringify(filters)}`),
  republish: (form = {}, options = {}) => api.put(`${resource}/${form.form.positionId}/republish`, form, options),
};
