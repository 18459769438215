import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import Modal, { ModalHeader, ModalBody } from '../Modal';
import DeleteContainer from '../DeleteContainer';
import { deleteAdRule } from '../../../store/api/ad-rules.api';

const DeleteFacebookRule = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const [isSending, setIsSending] = useState(false);
  const queryParams = qs.parse(search);

  const closeModal = () => {
    history.push({
      ...location,
      search: qs.stringify({
        currentPage: queryParams.currentPage || 1,
      }),
    });
  };

  const onDelete = async (e) => {
    e.preventDefault();
    if (isSending) return;

    try {
      setIsSending(true);
      await deleteAdRule(queryParams.id);

      closeModal();
    } catch (error) {
      // log(error);
    }
  };

  return (
    <Modal width={600}>
      <ModalHeader>{t('facebook.delete-rule')}</ModalHeader>
      <ModalBody>
        <DeleteContainer onDelete={onDelete} name={queryParams.name} params={queryParams} sending={isSending} />
      </ModalBody>
    </Modal>
  );
};

export default DeleteFacebookRule;
