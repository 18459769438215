import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { debounce, get } from 'lodash';
import { useParams, useLocation } from 'react-router-dom';
import Wysiwyg from '../../wysiwyg/WysiwygEditor';
import Switch from '../../input/Switch';
import ImageDropzone from '../../input/ImageDropzone';
import Datepicker from '../../input/DatePicker';
import { createLinkSuffix, log } from '../../../helpers/utils';
import { renderErrorField } from '../../../helpers/form';
import InputLoaderWrapper from '../../input/InputLoader';
import TopImageConfigurator from '../../@v2/CareerPage/TopImageConfigurator';
import Icon from '../../@v2/Icon';
import Select from '../../@v2/Select/Select';
import LocationForm from './LocationForm';
import { getImageServiceMediaUrl } from '../../../helpers/image';
import { locationLookup } from '../../../store/api/location.api';
import { getAllEmailTemplates } from '../../../store/api/template.api';
import { selectClient } from '../../../store/selectors/client.selector';
import { useSelector as useSelectorToolkit } from '../../../store';

const VisiblePosition = ({ state, setState, isValidating, loading }) => {
  const { t } = useTranslation();
  const { type } = useParams();
  const location = useLocation();
  const activeClient = useSelectorToolkit(selectClient);
  const [editorTemplates, setEditorTemplates] = useState(null);
  const [isLocationFetching, setIsLocationFetching] = useState(false);
  const [touchedFields, setTouchedFields] = useState({});
  const [isAddingLocation, setIsAddingLocation] = useState(false);
  const testId = 'visiblePosition';
  const activeImageCoords = useMemo(() => {
    if (state.imageCoords) {
      return state.imageCoords;
    }

    return get(state, 'image.focusPointJsonString')
      ? JSON.parse(get(state, 'image.focusPointJsonString'))
      : {
          percentageX: 0,
          percentageY: 0,
          focusX: 0,
          focusY: 0,
        };
  }, [state.imageCoords, state.image]);

  const [defaultLocation, setDefaultLocation] = useState(location.pathname.includes('positions/add'));

  const getLocation = async (locationId) => {
    if (isLocationFetching) return;

    try {
      setIsLocationFetching(true);
      const response = await locationLookup({
        filters: {},
        page: 1,
        pageSize: 20,
        sort: {},
      });

      setState('selectedLocation', response?.find((item) => item.id === locationId) || null);
    } catch (error) {
      log(error);
    } finally {
      setDefaultLocation(true);
      setIsLocationFetching(false);
    }
  };

  useEffect(() => {
    if (defaultLocation) return;
    if (state.locationId) getLocation(state.locationId);
  }, [state.locationId]);

  const setPublicSuffix = debounce((positionName) => {
    setState('publicLinkSuffix', createLinkSuffix(positionName));
  }, 1500);

  useEffect(() => {
    const getAllTemplates = async () => {
      try {
        const { items } = await getAllEmailTemplates();
        if (Array.isArray(items)) {
          setEditorTemplates(
            items.filter((item) => item.type === 0).map((item) => ({ value: item.text, label: item.name, id: item.id || item.templateId }))
          );
        }
      } catch (e) {
        // console.error(e);
      }
    };
    getAllTemplates();
  }, []);

  const isInvalid = useCallback(
    (key) => {
      const value = state[key];
      const touched = touchedFields[key];
      const isValidValue = value === '' || !value || value === null;
      return (isValidating === true && isValidValue) || (touched && isValidValue);
    },
    [touchedFields, isValidating, state]
  );

  const displayError = (fieldKey) => renderErrorField(isInvalid(fieldKey));

  const onDrop = useCallback((acceptedFiles, valueSetter) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (valueSetter) valueSetter(reader.result);
    };

    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        zIndex: 2,
      }}
    >
      <h3 className="line-behind-title" style={{ marginTop: '10px' }}>
        {t('edit-position.visible-position-page')}
      </h3>
      <Row>
        <Col md="5">
          <FormGroup>
            <Label for="position-name">{t('position.visible-position-name')} *</Label>
            <InputLoaderWrapper condition={loading.data}>
              <Input
                value={state.name}
                data-testid="position-name-input"
                onChange={(e) => {
                  setState('name', e.target.value);
                  if (type === 'add') setPublicSuffix(e.target.value);
                }}
                onBlur={() => {
                  setTouchedFields((state) => ({ ...state, name: true }));
                }}
                invalid={isInvalid('name')}
                disabled={loading.data}
                id="position-name"
              />
            </InputLoaderWrapper>
            {displayError('name')}
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label for="publish-date">{t('general.publish-date')} *</Label>
            <InputLoaderWrapper data-testid={`${testId}--input--publishDate`} condition={loading.data}>
              <Datepicker
                className="input-normal-radius"
                customInput={<input data-testid="position-publish-date-input" type="text" />}
                date={state.publishDate}
                onChange={(value) => setState('publishDate', value)}
                onBlur={() => {
                  setTouchedFields((state) => ({
                    ...state,
                    publishDate: true,
                  }));
                }}
                invalid={isInvalid('publishDate')}
                disabled={loading.data}
                id="publish-date"
              />
            </InputLoaderWrapper>
            {displayError('publishDate')}
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label for="publish-date">{t('general.application-deadline')}</Label>
            <InputLoaderWrapper condition={loading.data} data-testid={`${testId}--applicationDeadlineInput`}>
              <Datepicker
                customInput={<input data-testid="position-application-date-input" type="text" />}
                className="input-normal-radius"
                date={state.applicationDeadline}
                onChange={(value) => setState('applicationDeadline', value)}
                minDate={new Date(state.publishDate)}
                disabled={loading.data}
                id="application-deadline"
              />
            </InputLoaderWrapper>
          </FormGroup>
        </Col>
      </Row>
      <Row className="position-form-holder">
        <Col md="8">
          <FormGroup
            style={
              loading.data
                ? {
                    height: 500,
                  }
                : null
            }
          >
            <Label for="description">{t('general.description')}</Label>
            <InputLoaderWrapper
              condition={loading.data}
              loaderStyles={{
                top: '50%',
                right: '50%',
                transform: 'translate(50%, -50%)',
              }}
            >
              <Wysiwyg
                key={state.positionId}
                defaultValue={state.description}
                toolbarId="position-wysiwyg-toolbar"
                onEditorStateChange={(desc) => setState('description', desc)}
                toolbar="position"
                options={{ listItems: editorTemplates }}
                bounds=".position-form-wysiwyg"
                className="position-form-wysiwyg"
                readOnly={loading.data}
                resource="position"
                editorName="description"
              />
            </InputLoaderWrapper>
          </FormGroup>
        </Col>
        <Col md="4">
          <Row>
            <Col md="9">
              <FormGroup>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <Label for="logo" className="mb-0">
                    {t('general.logo')}
                  </Label>
                  {get(state, 'picture') && (
                    <button
                      type="button"
                      data-testid="position-logo-delete-icon"
                      onClick={() => {
                        setState('deletePicture', true);
                        setState('pictureInfo', null);
                        setState('picture', null);
                        setState('pictureInput', null);
                      }}
                    >
                      <Icon name="trash" />
                    </button>
                  )}
                </div>
                <InputLoaderWrapper
                  condition={loading.data}
                  loaderStyles={{
                    top: '50%',
                    right: '50%',
                    transform: 'translate(50%, -50%)',
                  }}
                >
                  <ImageDropzone
                    value={state?.pictureInfo ? state?.pictureInfo : getImageServiceMediaUrl(state?.picture)}
                    keepAspectRatio
                    resource="position-logo"
                    divId="input-logo-value"
                    type="picture"
                    onChange={(files) => {
                      onDrop(files, (value) => {
                        setState('deletePicture', false);
                        setState('pictureInfo', value);
                        setState('picture', files[0]);
                        setState('pictureInput', {
                          FileName: files[0].path,
                          Content: value.split(',')[1],
                        });
                      });
                    }}
                    inputId="logo"
                  />
                </InputLoaderWrapper>
              </FormGroup>
              <InputLoaderWrapper
                condition={loading.data}
                loaderStyles={{
                  top: '50%',
                  right: '50%',
                  transform: 'translate(50%, -50%)',
                }}
              >
                <TopImageConfigurator
                  inputId="image"
                  imageDivId="input-image-value"
                  label={t('general.image')}
                  image={state?.imageInfo ? state?.imageInfo : getImageServiceMediaUrl(state?.image)}
                  activeImageCoords={activeImageCoords}
                  callback={(coords) => {
                    setState('imageCoords', coords);
                  }}
                  onDrop={(files) => {
                    onDrop(files, (value) => {
                      setState('deleteImage', false);
                      setState('imageInfo', value);
                      setState('image', files[0]);
                      setState('imageInput', {
                        FileName: files[0].path,
                        Content: value.split(',')[1],
                      });
                    });
                  }}
                  resource="position-image"
                  page="position"
                  headerActions={
                    state?.imageInfo || state?.image ? (
                      <button
                        type="button"
                        data-testid="position-image-delete-icon"
                        onClick={() => {
                          setState('deleteImage', true);
                          setState('imageInfo', '');
                          setState('image', null);
                          setState('imageInput', null);
                        }}
                      >
                        <Icon name="trash" />
                      </button>
                    ) : null
                  }
                  error={renderErrorField(get(state, 'image.size') / 1000 > 5000, 'file-size-is-too-big', {
                    size: '5mb',
                  })}
                />
              </InputLoaderWrapper>
            </Col>
          </Row>
          <FormGroup>
            <Label for="spontaneous-application">{t('general.spontaneous-application')}</Label>
            <Switch
              data-testid="position-spontaneous-application-switch"
              checked={state.spontaneous}
              onChange={(state) => setState('spontaneous', state)}
              disabled={loading.data}
              id="spontaneous-application"
            />
          </FormGroup>
          <FormGroup>
            <Label for="hide-on-career-page">{t('edit-position.hide-on-career-page-only-positionpage')}</Label>
            <Switch
              checked={state.hidden}
              onChange={(state) => {
                setState('publishToMasterClient', state === false);
                setState('hidden', state);
              }}
              disabled={loading.data}
              id="hide-on-career-page-only-position-page"
              data-testid="position-hide-career-page-switch"
            />
          </FormGroup>
          <FormGroup>
            <Label for="hide-after-deadline">{t('edit-position.hide-after-deadline')}</Label>
            <Switch
              data-testid="position-hide-after-deadline-switch"
              checked={state.hideAfterDeadline}
              onChange={(state) => setState('hideAfterDeadline', state)}
              disabled={loading.data}
              id="hide-after-deadline"
            />
          </FormGroup>
          {activeClient?.hasMainClient && activeClient?.mainClientName && (
            <FormGroup>
              <Label for="show-on-master-client">
                {t('edit-position.mark-position-to-be-published-on-main-client', {
                  clientName: activeClient?.mainClientName,
                })}
              </Label>
              <Switch
                data-testid="position-show-on-master-client"
                checked={state.publishToMasterClient}
                onChange={(state) => setState('publishToMasterClient', state)}
                disabled={loading.data || state.hidden}
                id="show-on-master-client"
              />
            </FormGroup>
          )}
        </Col>
      </Row>
      <Row className="position-form-holder">
        <Col md="8">
          <FormGroup>
            <Label for="pitch">{t('edit-position.pitch')}</Label>
            <p className="sub-label">{t('edit-position.pitch-description')}</p>
            <Input
              data-testid="position-pitch-input"
              value={state.pitch}
              onChange={(e) => setState('pitch', e.target.value)}
              autoComplete="off"
              type="textarea"
              onBlur={() => {
                setTouchedFields((state) => ({
                  ...state,
                  pitch: true,
                }));
              }}
              invalid={!!get(state, 'pitch') && state.pitch.length > 200}
              id="pitch"
            />
            {renderErrorField(get(state, 'pitch') && state.pitch.length > 200, 'maximum-x-characters', { x: 200 })}
          </FormGroup>
        </Col>
        <Col md="5">
          <FormGroup>
            <Label for="location">{t('general.location')} *</Label>
            <InputLoaderWrapper
              data-testid={`${testId}--location`}
              condition={isLocationFetching}
              loaderStyles={{
                right: 18,
              }}
            >
              <Select
                searchable
                id="location"
                searchKey="title"
                api={async (meta) => {
                  const response = await locationLookup({
                    page: meta.page,
                    pageSize: meta.pageSize,
                    filters: {
                      ...meta.filtersV2,
                      isActive: true,
                    },
                  });

                  return response;
                }}
                onSelect={([selected]) => {
                  setTouchedFields((state) => ({
                    ...state,
                    locationId: true,
                  }));
                  setState('locationId', selected.id || selected.locationId);
                  setState('locationName', selected.name);
                  setState('selectedLocation', selected);
                }}
                value={state?.selectedLocation ? [state?.selectedLocation] : []}
                keys={['id', 'title']}
                invalid={isInvalid('locationId')}
                disabled={isLocationFetching}
                resource="position-location"
                insertedElement={(handleOnVisibleChange) => {
                  return (
                    <div className="px-4 py-2">
                      <button
                        type="button"
                        className="border-dashed border-2 border-gray-200 w-full py-2 px-4 rounded-lg"
                        onClick={() => {
                          handleOnVisibleChange(false);
                          setIsAddingLocation(true);
                        }}
                      >
                        <div className="flex items-center justify-center gap-x-1">
                          <Icon name="plus" />
                          {t('general.new-location')}
                        </div>
                      </button>
                    </div>
                  );
                }}
              />
              <LocationForm isShown={isAddingLocation} setIsShown={setIsAddingLocation} setState={setState} />
            </InputLoaderWrapper>
            {displayError('locationId')}
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default VisiblePosition;
