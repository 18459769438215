import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../assets/sass/login.sass';
import { Alert, Button as ButtonNative } from 'reactstrap';
import { get } from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import Locales from '../constants/Locale';
import { log } from '../helpers/utils';
import i18n from '../i18n';
import { authorize } from '../store/thunks/auth.thunks';
import { selectLoading as selectAuthLoading, selectError } from '../store/selectors/auth.selector';
import { selectLoading as selectClientLoading } from '../store/selectors/client.selector';
import { getUser } from '../store/thunks/user.thunks';
import { changeLocale } from '../store/slices/user.slice';
// new store
import { dispatch as dispatchToolkit, useSelector as useSelectorToolkit } from '../store';
import Field from '../components/@v2/Form/Field';
import Button from '../components/Button';
import Logo from '../assets/svg/logo.svg';
import { getAllSettings } from '../store/thunks/settings.thunks';
import { toggleSplash } from '../helpers/toggle-splash';

YupPassword(Yup);

const login = () => {
  const { t } = useTranslation();
  const userLoading = useSelectorToolkit(selectAuthLoading);
  const clientLoading = useSelectorToolkit(selectClientLoading);
  const error = useSelectorToolkit(selectError);
  const loading = userLoading || clientLoading;
  const setDefaultLanguage = useCallback(
    (lang) => {
      const matchedLang = Locales.find(({ value }) => value === lang.split('-')[0]);

      if (matchedLang) {
        i18n.changeLanguage(matchedLang.value, (err) => {
          if (err) return log('error changing lang');
          return dispatchToolkit(changeLocale(lang));
        });
      }
    },
    [dispatchToolkit]
  );

  const onSubmit = async (values) => {
    try {
      const signInUser = await dispatchToolkit(authorize({ username: values.email, password: values.password }));

      if (get(signInUser, 'error')) {
        return;
      }

      const user = await dispatchToolkit(getUser()).unwrap();

      if (user) {
        toggleSplash(true);
        setDefaultLanguage(user.language);

        if (user.clientId !== 0) {
          await dispatchToolkit(getAllSettings(undefined));
          toggleSplash(false);
        }
      }
    } catch (e) {
      toggleSplash(false);
    }
  };

  const LoginFormSchema = Yup.object().shape({
    email: Yup.string().email(t('form-validator.email')).required(t('form-validator.required')),
    password: Yup.string().required(t('form-validator.required')),
  });

  const handleSignInWithSSO = () => {
    window.location.href = `${window.appConfig?.ws_identity_url}?origin=${window.location.origin}`;
  };

  return (
    <div className="login-container" data-testid="login--container">
      <div className="sign-in">
        <div className="form">
          <p className="font-semibold !text-black">{t('general.sign-in')}</p>
          <div className="input flex flex-col items-center" data-testid="login--form">
            {!!error && (
              <Alert data-testid="login--alert" color="danger">
                {error}
              </Alert>
            )}
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={LoginFormSchema}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, isSubmitting }) => {
                return (
                  <form onSubmit={handleSubmit} className="w-full">
                    <Field type="email" name="email" placeholder={t('general.placeholder-email')} required />
                    <Field type="password" name="password" placeholder={t('general.placeholder-password')} required />
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting || loading}
                      loading={isSubmitting || loading}
                    >
                      {t('general.sign-in')}
                    </Button>
                  </form>
                );
              }}
            </Formik>
            <ButtonNative
              onClick={handleSignInWithSSO}
              color="light"
              style={{
                color: 'black',
                lineHeight: '1',
              }}
            >
              {t('general.sign-in-sso')}
            </ButtonNative>
            <Link to="/forgot-password">{t('general.forgot-password')}</Link>
          </div>
        </div>
      </div>
      <img className="logo" src={Logo} alt="logo" />
    </div>
  );
};

export default login;
