import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import Tippy, { useSingleton } from '@tippyjs/react';
import { hideAll } from 'tippy.js';
import Layout from '../../../../components/layouts/default';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import Card from '../../../../components/@v2/Card';
import Table from '../../../../components/@v2/Table/Table';
import Search from '../../../../components/@v2/Search/Search';
import Paginator from '../../../../components/@v2/Paginator/Paginator';
import { useSelector } from '../../../../store';
import { selectUser } from '../../../../store/selectors/user.selector';
import { deleteSubscriber, getSubscribersList } from '../../../../store/api/subscriber.api';
import { format } from '../../../../helpers/date';
import Icon from '../../../../components/@v2/Icon';
import { log } from '../../../../helpers/utils';
import Select from '../../../../components/@v2/Select/Select';
import { SubscriptionDeliveryOptions } from '../../../../constants/Client';
import DeleteModelTooltip from '../../../../components/@v2/DeleteModelTooltip/DeleteModelTooltip';

const Subscription = () => {
  const { t } = useTranslation();
  const { loading, data, meta, setMeta, pageSize, reloadData } = useApiRequestContext();
  const currentUser = useSelector(selectUser);
  const [source, target] = useSingleton();
  const [deletingIds, setDeletingIds] = useState([]);

  const onDelete = async (id) => {
    if (deletingIds.includes(id)) {
      return;
    }

    try {
      setDeletingIds([...deletingIds, id]);
      await deleteSubscriber(id);
      hideAll();
      setDeletingIds(deletingIds.filter((itemId) => itemId !== id));
      setTimeout(() => {
        reloadData();
      }, 750);
    } catch (error) {
      log(error);
    }
  };

  const DELIVERY_OPTIONS = SubscriptionDeliveryOptions.map((item) => {
    return {
      ...item,
      label: t(item?.label),
    };
  });

  return (
    <Layout
      pretitle={t('general.settings')}
      title={t('general.subscriptions')}
      rightContent={
        <>
          {currentUser.permissionLevel >= 2 && (
            <div className="flex items-center gap-x-2">
              {t('settings.subscriptions.send-email')}:
              <Select defaultOptions={DELIVERY_OPTIONS} defaultValue={[DELIVERY_OPTIONS[2]]} />
            </div>
          )}
        </>
      }
    >
      <Card className="anim-table-delayed shadow-table !border !border-line-default">
        <Tippy
          trigger="click mouseenter"
          singleton={source}
          theme="light"
          interactive
          maxWidth={200}
          appendTo={document.body}
          placement="bottom"
        />
        <Search resource="name" meta={meta} setMeta={setMeta} />
        <Table
          isLoaded={!loading}
          activeSort={get(meta, 'sorts') || ''}
          items={get(data, 'items')}
          headers={[
            {
              label: t('general.name'),
              key: 'fullName',
            },
            {
              label: t('general.email'),
              key: 'email',
            },
            {
              label: t('general.subscription-date'),
              sort: false,
            },
            {
              label: t('general.actions'),
              key: 'actions',
              sticky: true,
              sort: false,
              width: 100,
              headerClassName: 'text-center',
            },
          ]}
          pageSize={pageSize}
          renderBody={(item, index) => {
            return (
              <tr key={item?.id} id={`product-table-row--${index}`}>
                <td>{item?.fullName}</td>
                <td>{item?.email}</td>
                <td>{format('date', item?.dateStamp)}</td>
                <td className="column-sticky">
                  <div className="w-full flex items-center justify-center gap-x-2">
                    <DeleteModelTooltip
                      content={<p>{t('settings.subscriptions.delete-subscribers')}</p>}
                      onDelete={() => {
                        onDelete(item?.id);
                      }}
                      singleton={target}
                    >
                      <button type="button" data-testid="delete-button">
                        <Icon name={item?.deleted ? 'archive-restore' : 'trash'} />
                      </button>
                    </DeleteModelTooltip>
                  </div>
                </td>
              </tr>
            );
          }}
          setMeta={setMeta}
        />
        <div className="h-14 flex items-center bg-white">
          <Paginator
            pageCount={Math.ceil(data?.totalCount / meta?.pageSize)}
            initialPage={meta.page - 1}
            onPageChange={(value) => {
              if (meta.page !== value) {
                setMeta({
                  page: value,
                });
              }
            }}
            disableInitialCallback
          />
        </div>
      </Card>
    </Layout>
  );
};

const SubscriptionWrapper = () => {
  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source, query, _, filter) => {
        const options = {
          cancelToken: source?.token,
        };

        const sort = {
          by: meta?.sorts?.replace('-', ''),
          direction: meta.sorts.includes('-') ? 'desc' : 'asc',
        };

        const response = await getSubscribersList(
          {
            filter: {
              name: filter[0]?.value,
            },
            page: {
              number: meta.page,
              size: meta.pageSize,
            },
            sort: meta.sorts ? sort : {},
          },
          {
            cancelToken: source.token,
          },
          options
        );
        return response;
      }}
    >
      <Subscription />
    </ApiRequestProvider>
  );
};

export default SubscriptionWrapper;
