import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../../components/@v2/Form/Field';
import Button from '../../../components/Button';
import { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import { useAPI } from '../../../context/api';
import { useSelector as useSelectorToolkit } from '../../../store';

const CandidateRejectModal = ({ rejected, setIsOpen, callback, selected }) => {
  const { t } = useTranslation();
  const { reloadData, data } = useApiRequestContext();

  const storeCandidates = useSelectorToolkit(({ settings }) => settings.candidates);
  const filters = useSelectorToolkit(({ settings }) => settings?.filters?.candidates || {});
  const [bulkAPI] = useAPI('bulk');

  const onSubmit = async ({ motivation }) => {
    const selectAll = storeCandidates.selectAll || false;

    const payload = {
      selectAll,
      reject: !rejected,
      rejectMessage: motivation,
    };

    if (selectAll) {
      let payloadFilters = [];

      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          payloadFilters = [...payloadFilters, `${key}==${filters[key]}`];
        }
      });

      payload.filters = payloadFilters.join(',');

      if (storeCandidates?.unchecked.length) {
        payload.unchecked = (storeCandidates?.unchecked || []).map((item) => item.id);
      }
    } else {
      payload.candidates = (storeCandidates?.selected || []).map((item) => item.id);
    }

    data.rejectedPipelinesRef.current = null;
    await bulkAPI.reject(payload);

    setIsOpen(false);
    if (selected && callback) {
      callback();
    }
    reloadData();
  };

  return (
    <div>
      <p>{t(rejected ? 'candidates.unreject' : 'candidate.reject-candidate')}</p>
      <p>{rejected && <p className="text-center mb-3">{t('general.are-you-sure')}</p>}</p>
      <Formik
        initialValues={{
          motivation: '',
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {!rejected && <Field type="textarea" name="motivation" />}
              <div className="flex items-center justify-center gap-x-2">
                <Button
                  type="button"
                  color="light"
                  outline
                  className="btn-rounded px-4 !h-8"
                  disabled={isSubmitting}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {t('candidate.cancel')}
                </Button>
                <Button
                  type="submit"
                  color="danger"
                  outline
                  className="btn-rounded px-4 !h-8"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  {t('general.submit')}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CandidateRejectModal;
