import React from 'react';
import { useTranslation } from 'react-i18next';
import { TYPES } from '../../../../../constants/CareerPage';
import { useApiRequestContext } from '../../../../../context/@v2/ApiRequestContext';
import BlockLayout from '../../BlockLayout';
import FooterForm from './FooterForm';

const FooterBlock = () => {
  const { t } = useTranslation();
  const { data } = useApiRequestContext();
  const details = data?.details || {};
  const blocks = details?.blocks || [];
  const footer = blocks?.find((item) => item?.type === TYPES.FOOTER);

  if (!footer) {
    return null;
  }

  return (
    <BlockLayout label={t('pages.footer')} item={footer} testId="career-page-block-footer">
      <FooterForm item={footer} />
    </BlockLayout>
  );
};

export default FooterBlock;
