import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { CardHeader, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { debounce, has, get, isArray, omitBy, isNull, isUndefined, isObject, omit } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import styled from 'styled-components';
import qs from 'query-string';
import classNames from 'classnames';
import StarRating from '../StarRating';
import FilterAutocomplete from '../FilterAutocomplete';
import Icon from '../@v2/Icon';
import events from '../../helpers/events';
import ModalFromRight from '../ModalFromRight';
import { setTablePageSize } from '../../store/slices/settings.slice';
import Button from '../Button';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../store';
import useDidMountEffect from '../../helpers/custom-hooks';

const CardHeaderWrapper = styled(CardHeader)`
  position: relative;
  z-index: 10;
`;

const TableHeader = ({
  clearFilters,
  filterType,
  search,
  isLoading,
  children,
  filterInput,
  filterDesign,
  clearEventKey,
  onClearFilterCallback,
  customTableHeader,
  stateFilter,
  filterEventsKeys,
  clearId,
  searchText,
  defaultSort,
  customTableHeaderButton,
  defaultSearchValue,
  customDataSetter,
}) => {
  const animatedFilter = useRef();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const urlParams = useMemo(() => qs.parse(get(location, 'search')), [get(location, 'search')]);
  const [showFilter, setShowFilter] = useState(false);
  const getDefaultValueOfFilters = (passedfilters = null) => {
    return [...filterInput].reduce((accumulator, currentValue) => {
      if (passedfilters && currentValue.changeByLocationState && passedfilters[currentValue.filterKey]) {
        if (isObject(currentValue.defaultValue)) {
          return {
            ...accumulator,
            [currentValue.filterKey]: currentValue.options.find(
              (item) => item.value === passedfilters[currentValue.filterKey]
            ),
          };
        }
      }

      return {
        ...accumulator,
        [currentValue.filterKey]: currentValue.defaultValue,
      };
    }, {});
  };

  const [filters, setFilters] = useState(getDefaultValueOfFilters());

  const filterLength = useMemo(() => {
    const f = { ...filters };

    Reflect.deleteProperty(f, 'pipelineStepId');

    const filterCount = Object.entries(f || {}).reduce((accumulator, [, currentValue]) => {
      if (isObject(currentValue)) {
        if (
          get(currentValue, 'value') !== null &&
          get(currentValue, 'value') !== undefined &&
          get(currentValue, 'value') !== ''
        ) {
          return (accumulator += 1);
        }
      } else if (currentValue !== null && currentValue !== undefined && currentValue !== '') {
        return (accumulator += 1);
      }

      return accumulator;
    }, 0);

    if (location.pathname.includes('positions') || location.pathname === '/') {
      return filterCount;
    }

    return filterCount;
  }, [filters]);

  const [sorts, setSorts] = useState(defaultSort || null);

  const debounceSearch = debounce((e) => {
    const state = { ...(location.state || {}) };
    let routeParams = { ...location };

    if (e.target.value === '') {
      const sFilters = get(state, 'filters') || {};
      routeParams = {
        ...location,
        state: {
          ...state,
          filters: omit(sFilters, search),
        },
        search: qs.stringify(urlParams),
        searchValue: e.target.value,
      };
    } else {
      routeParams = {
        ...location,
        state: {
          ...state,
          filters: {
            ...state.filters,
            [search]: e.target.value,
          },
        },
        search: qs.stringify({ ...urlParams, currentPage: 1 }),
        searchValue: e.target.value,
      };
    }

    history.push(routeParams);
  }, 500);

  const onSearch = (e) => {
    e.persist();
    debounceSearch(e);
  };

  const isDescendant = (parent, child) => {
    let node = child.parentNode;
    while (node !== null) {
      if (
        (node.classList &&
          (node.classList.contains('filter-modal') || node.classList.contains('custom-react-select__menu-list'))) ||
        node === parent
      ) {
        return true;
      }

      node = node.parentNode;
    }

    return false;
  };

  const checkIfDescendant = (e) => {
    if (!isDescendant(animatedFilter.current, e.target)) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    if (showFilter && animatedFilter.current && filterDesign === 'modal') {
      window.addEventListener('click', checkIfDescendant);
    }

    return () => {
      window.removeEventListener('click', checkIfDescendant);
    };
  }, [showFilter, animatedFilter]);

  useEffect(() => {
    events.$on(clearEventKey, () => {
      const nFilter = [...filterInput]
        .filter((f) => !f.persist)
        .reduce(
          (accumulator, currentValue) => ({
            ...accumulator,
            [currentValue.filterKey]: currentValue.isMulti ? [] : null,
          }),
          {}
        );

      setFilters((state) => ({
        ...state,
        ...nFilter,
      }));
    });

    return () => {
      events.$off(clearEventKey);
    };
  }, []);

  const hideShowAllButton = useMemo(() => {
    const inputs = [...filterInput]
      .filter((f) => !f.persist)
      .filter((f) => {
        if (f.filterKeys && f.filterKeys.length) {
          for (let i = 0; i < f.filterKeys.length; i += 1) {
            if (stateFilter[f.filterKeys[i]]) {
              return true;
            }
          }
        }

        if (stateFilter && f.filterKey && stateFilter[f.filterKey] !== null && stateFilter[f.filterKey] !== undefined) {
          return true;
        }
        return false;
      });

    return !!inputs.length;
  }, [filterInput]);

  const applyFilter = async () => {
    let newFilters = {};
    const pureFilters = {};

    // Save filters to local storage
    localStorage.setItem('positionsTableFilters', JSON.stringify(filters));

    const withOnChangeLength = filterInput.filter((item) => !!item.onChange).length;
    const filtersValuesOnChange = filterInput.filter((item) => !!item.valueOnFilter);
    let counter = 0;
    let isFinished = withOnChangeLength === 0;
    const sortedFilter = [...filterInput].sort((f) => (f.onChange ? 1 : -1));
    filterEventsKeys.forEach((key) => {
      if (filters[key]) {
        newFilters[key] = filters[key];
      }
    });

    const addHistoryState = () => {
      filtersValuesOnChange.forEach((item) => {
        Reflect.deleteProperty(newFilters, item.filterKey);
        if (filters[item.filterKey] && filters[item.filterKey].value) {
          newFilters = {
            ...newFilters,
            ...item.valueOnFilter[filters[item.filterKey].value],
          };
        }
      });

      history.push({
        ...location,
        state: {
          ...location.state,
          sorts,
          filters: omitBy(newFilters, (e) => isUndefined(e) || isNull(e) || e === ''),
          pure: omitBy(pureFilters, (e) => isUndefined(e) || isNull(e) || e === ''),
        },
        search: qs.stringify({
          ...urlParams,
          currentPage: 1,
          r: true,
        }),
      });
    };

    const callback = (f) => {
      newFilters = {
        ...newFilters,
        ...f,
      };

      counter += 1;

      if (counter === withOnChangeLength) {
        addHistoryState();
      }
    };

    sortedFilter.forEach((item, index) => {
      if (item.hide) {
        newFilters[item.filterKey] = get(location, `state.filters.${item.filterKey}`);
      } else {
        if (item.pure) {
          pureFilters[item.filterKey] = get(filters[item.filterKey], 'value');
          return;
        }
        if (item.filterKey) {
          const filterValue = get(filters[item.filterKey], 'value');

          if (isArray(filters[item.filterKey]) && filters[item.filterKey].length) {
            newFilters[item.filterKey] = filters[item.filterKey].reduce((accumulator, currentValue) => {
              if (accumulator !== '') {
                return `${accumulator}|${currentValue.label}`;
              }
              return currentValue.label;
            }, '');
          } else if (
            (filterValue !== undefined && filterValue !== null) ||
            (item.filterKey === 'stars' && filters[item.filterKey] !== undefined && filters[item.filterKey] !== null)
          ) {
            const checkedFilterValue = filterValue === false ? 'false' : filterValue;
            newFilters[item.filterKey] = item.filterKey === 'stars' ? filters[item.filterKey] : checkedFilterValue;
          } else if (filterValue === null) {
            Reflect.deleteProperty(newFilters, item.filterKey);
          }
        } else if (item.filterKeys) {
          item.filterKeys.forEach((key) => {
            if (filters[key] !== undefined && filters[key] !== null) {
              newFilters[key] = get(filters[key], 'value') || filters[key];
            }
          });
        }

        if (item.onChange) item.onChange(filters[item.filterKey], callback);
      }

      if (filterInput.length === index + 1) isFinished = true;
    });
  };

  useEffect(() => {
    const savedFilters = localStorage.getItem('positionsTableFilters');
    if (savedFilters) {
      setFilters(JSON.parse(savedFilters));
    }
  }, []);

  return (
    <CardHeaderWrapper className="d-block ts-custom-table-header">
      {customTableHeader}
      {!customTableHeader && (
        <div className="card-header-wrapper d-flex align-items-center justify-content-between">
          {!!search && (
            <div className="card-header-input d-flex align-items-center">
              {isLoading ? (
                <img
                  className="search-input-loader"
                  src={`${global?.appConfig?.public_url}/images/loader.svg`}
                  alt="loader"
                />
              ) : (
                <Icon name="search" />
              )}
              <input
                data-testid="table-search-input"
                placeholder={searchText || t('general.search')}
                defaultValue={defaultSearchValue || get(location, `state.filters.${[search]}`) || ''}
                onChange={onSearch}
                className="data-search"
              />
            </div>
          )}
          {filterType === 'server-side' && (
            <Dropdown
              style={{ marginRight: '0.500rem' }}
              isOpen={pageSizeOptionsOpen}
              toggle={() => setPageSizeOptionsOpen(!pageSizeOptionsOpen)}
              className="dropdown--per-page"
              data-testid="table-per-page-dropdown"
            >
              <DropdownToggle className="btn-naked button--per-page" caret>
                {t('general.items-per-page', { x: pageSize })}
              </DropdownToggle>
              <DropdownMenu right>
                {[10, 20, 50, 100].map((item, index) => (
                  <DropdownItem
                    key={item}
                    className={classNames('m-0', `dropdown--per-page-option-${index}`)}
                    style={{
                      height: 'auto',
                      lineHeight: 'auto',
                    }}
                    onClick={() => {
                      if (customDataSetter) {
                        customDataSetter(item);
                      }
                      history.push({
                        ...location,
                        search: qs.stringify({
                          ...urlParams,
                          currentPage: 1,
                          pageSize: item,
                        }),
                      });
                      dispatchToolkit(setTablePageSize(item));
                    }}
                  >
                    {item}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          )}
          {!!filterInput.length && (
            <div className="d-flex align-items-center">
              {filterInput.map((item, index) => (
                <FilterAutocomplete
                  key={uuidv4()}
                  filter={item}
                  filters={filters}
                  setFilters={setFilters}
                  isDisabled={isLoading}
                  resource={`filter-${index}`}
                />
              ))}
            </div>
          )}
          {customTableHeaderButton}
        </div>
      )}
    </CardHeaderWrapper>
  );
};

export default TableHeader;
