import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'react-simple-snackbar';
import styled from 'styled-components';
import { get, omit } from 'lodash';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import Icon from '../@v2/Icon';
import Layout from './default';
import events from '../../helpers/events';
import { useAPI } from '../../context/api';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../store';
import { selectPosition } from '../../store/selectors/settings.selector';
import { setActivePosition, setFiltersWithKey } from '../../store/slices/settings.slice';
import Button from '../Button';
import Loader from '../Loader';
import { onExportCandidates } from '../../helpers/export';

const BulkManageButton = styled.button`
  border: none;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 5px 10px;
  margin-left: 15px;
`;

const CandidatesLayout = ({ children, rightContent, testId }) => {
  const [candidateAPI] = useAPI('candidate');
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [importing, setImporting] = useState(false);
  const position = useSelectorToolkit(selectPosition);
  const positionName = useMemo(() => {
    return position?.position || position?.name || t('general.all');
  }, [position]);
  const candidatesSettings = useSelectorToolkit(({ settings }) => settings.candidates);
  const candidatesFilters = useSelectorToolkit(({ settings }) => settings.filters.candidates);

  const hasBackButton = useMemo(() => location.pathname.startsWith('/candidates/view/'), [location.pathname]);

  const importFile = async () => {
    if (importing) return;
    try {
      setImporting(true);
      const formData = new FormData();
      formData.append('file', file);

      await candidateAPI.import(formData);
      setFile(null);
      events.$emit('refresh-table--candidates');
    } catch (error) {
      // log(error);
    } finally {
      setImporting(false);
    }
  };

  useEffect(() => {
    if (file) importFile();
  }, [file]);

  const [openSnackbar, closeSnackbar] = useSnackbar({
    style: {
      minWidth: 160,
    },
  });

  useEffect(() => {
    return () => {
      closeSnackbar();
    };
  }, []);

  const redirectToCardView = () => {
    const firstCandidate =
      get(candidatesSettings, 'selected')[0] ||
      get(candidatesSettings, 'data').filter(
        (current) => !get(candidatesSettings, 'unchecked').find((item) => item.id === current.id)
      )[0];

    if (firstCandidate) {
      history.push(`/candidates/view/${firstCandidate.id}`);
    }
  };

  useEffect(() => {
    const count = candidatesSettings.selectAll
      ? candidatesSettings?.totalCount - (get(candidatesSettings, 'unchecked') || []).length
      : (get(candidatesSettings, 'selected') || []).length;

    if (
      (candidatesSettings?.selected.length || candidatesSettings?.selectAll) &&
      !location.pathname.includes('/view/') &&
      count > 1
    ) {
      openSnackbar(
        <div className="d-flex align-items-center justify-content-between">
          {t('general.number-selected', {
            selected: count,
          })}
          <BulkManageButton
            type="button"
            onClick={() => {
              redirectToCardView();
            }}
          >
            {t('candidates.candidate-name.bulk-manage')}
          </BulkManageButton>
        </div>,
        365 * 24 * 60 * 60 * 1000
      );
    } else {
      closeSnackbar();
    }
  }, [candidatesSettings?.selected, candidatesSettings?.selectAll, location]);

  const backButtonOnClick = async () => {
    if (!candidatesSettings.fromPosition) {
      await dispatchToolkit(setActivePosition({}));
      await dispatchToolkit(
        setFiltersWithKey({
          key: 'candidates',
          payload: omit(candidatesFilters, ['positionId', 'pipelineStepId']),
        })
      );
    }
    history.push('/candidates');
  };

  const [isExporting, setIsExporting] = useState(false);

  const onExport = () => {
    if (isExporting) return;
    onExportCandidates(position, setIsExporting);
  };

  return (
    <Layout
      title={
        <>
          {hasBackButton && (
            <button onClick={backButtonOnClick} type="button">
              <Icon data-testid="candidates-back-button" name="long-arrow-left" className="mr-3 clickable" />
            </button>
          )}
          {t('candidates.title', { positionName })}
        </>
      }
      testId={testId}
      rightContent={
        <div className="flex items-center gap-x-3 whitespace-nowrap">
          <Button
            color="primary"
            id="button--add-candidate"
            data-testid="candidates-add-button"
            onClick={() => {
              history.push(`/candidates/add?positionId=${position?.id}`);
            }}
          >
            <div className="flex items-center gap-x-1">
              <Icon name="plus" />
              {t('candidates.add-candidate')}
            </div>
          </Button>
          {!!(position?.id || position?.positionId) && (
            <Tippy content={t('candidates.export')} placement="bottom" theme="light">
              <button
                type="button"
                disabled={isExporting}
                data-testid="option-export"
                className={classNames('flex items-center gap-x-2', isExporting && 'pr-4')}
                onClick={onExport}
              >
                {isExporting ? (
                  <Loader
                    parentStyles={{
                      position: 'static',
                      marginLeft: 10,
                      marginRight: -10,
                    }}
                    color="var(--primary-color)"
                  />
                ) : (
                  <Icon name="file-export" />
                )}
              </button>
            </Tippy>
          )}

          {rightContent}
        </div>
      }
    >
      {children}
    </Layout>
  );
};

CandidatesLayout.defaultProps = {
  rightContent: null,
  testId: null,
};

export default CandidatesLayout;
