export default [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'de',
    label: 'Deutsch',
  },
  {
    value: 'sv',
    label: 'Svenska',
  },
  {
    value: 'da',
    label: 'Dansk',
  },
  {
    value: 'fi',
    label: 'Suomi',
  },
  {
    value: 'nb',
    label: 'Norsk',
  },
];
