import { range } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import { log } from '../../helpers/utils';
import CustomButton from '../Button';
import Form from '../form/Form';
import Icon from '../@v2/Icon';
import Layout from '../layouts/scheduler';

const FormContainer = styled.div`
  padding: 2.3rem 1.5rem;
  width: ${({ hasPreviewContainer }) => {
    return hasPreviewContainer ? 'calc(100% - 280px)' : '100%';
  }};
  min-width: 400px;
`;

const PreviewContainer = styled.div`
  background: #ececec;
  width: 280px;
  min-height: 400px;
`;

const PreviewListItem = styled.li`
  min-height: 65px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #cfcfcf;
`;

const ItemContainer = styled.div`
  background: #badaf4;
  min-height: 65px;
  border-left: 5px solid #000000;
  height: 100%;
  width: 100%;
  padding: 0 10px;
`;

const PreviewLabel = styled.p`
  color: #8491a6;
`;

const ItemTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #000000;
`;

const ItemDuration = styled.p`
  font-size: 13px;
  color: #8794ab;
`;

const TimeInput = styled(Input)`
  width: 70px;
`;

const SchedulerCreate = () => {
  const [values, setValues] = useState({
    name: '',
    place: '',
    time: null,
    unit: null,
    weeks: [
      {
        items: [],
        fromTime: '09:00',
        untilTime: '17:00',
      },
    ],
    future: {
      time: {
        label: 1,
        value: 1,
      },
      unit: {
        label: 'weeks',
        value: 'weeks',
      },
    },
    bookingPage: '',
  });
  const [view, setView] = useState(1);
  const [title, setTitle] = useState(['pages.scheduling-pages', 'Event name']);
  const history = useHistory();

  const inputs = useMemo(() => {
    if (view === 1) {
      return [
        {
          key: 'name',
          label: 'What should new events be named?',
          placeholder: 'Event Title',
          rules: {
            required: true,
          },
          messages: {
            required: 'kelangan to',
          },
        },
        {
          key: 'place',
          label: 'Where do events take place?',
          placeholder: 'Location',
        },
        {
          type: 'col',
          label: 'How long is each event?',
          className: 'position-form-holder',
          inputs: [
            {
              key: 'time',
              placeholder: 'Time',
              type: 'selectv2',
              options: range(1, 61).map((item) => ({
                label: item,
                value: item,
              })),
              cols: {
                sm: 12,
                md: 3,
              },
            },
            {
              key: 'unit',
              placeholder: 'Unit',
              type: 'selectv2',
              options: [
                {
                  label: 'mins',
                  value: 'mins',
                },
                {
                  label: 'hours',
                  value: 'hours',
                },
              ],
              cols: {
                sm: 12,
                md: 3,
              },
            },
          ],
        },
      ];
    }

    return [
      {
        type: 'component',
        label: 'When can events be booked?',
        customComponent: ({ form, setForm }) => (
          <>
            {form.weeks.map((week, index) => (
              <>
                <div className="d-flex align-items-center mb-3" key={uuidv4()}>
                  <div className="d-flex">
                    {[
                      {
                        label: 'Su',
                        value: 1,
                      },
                      {
                        label: 'M',
                        value: 2,
                      },
                      {
                        label: 'T',
                        value: 3,
                      },
                      {
                        label: 'W',
                        value: 4,
                      },
                      {
                        label: 'Th',
                        value: 5,
                      },
                      {
                        label: 'F',
                        value: 6,
                      },
                      {
                        label: 'Sa',
                        value: 7,
                      },
                    ].map((item) => (
                      <CustomButton
                        key={item.value}
                        outline={!week.items.find((w) => w === item.value)}
                        color={
                          !week.items.find((w) => w === item.value)
                            ? 'secondary'
                            : 'primary'
                        }
                        onClick={() => {
                          setForm({
                            ...form,
                            weeks: [
                              ...form.weeks.slice(0, index),
                              {
                                ...form.weeks[index],
                                items: form.weeks[index].items.find(
                                  (w) => w === item.value
                                )
                                  ? form.weeks[index].items.filter(
                                      (w) => w !== item.value
                                    )
                                  : [...form.weeks[index].items, item.value],
                              },
                              ...form.weeks.slice(index + 1),
                            ],
                          });
                        }}
                      >
                        {item.label}
                      </CustomButton>
                    ))}
                  </div>
                  <div className="d-flex align-items-center ml-4">
                    <TimeInput
                      defaultValue={week.fromTime}
                      onBlur={(e) => {
                        setForm({
                          ...form,
                          weeks: [
                            ...form.weeks.slice(0, index),
                            {
                              ...form.weeks[index],
                              fromTime: e.target.value,
                            },
                            ...form.weeks.slice(index + 1),
                          ],
                        });
                      }}
                    />
                    <span className="ml-2 mr-2">until</span>
                    <TimeInput
                      defaultValue={week.untilTime}
                      onBlur={(e) => {
                        setForm({
                          ...form,
                          weeks: [
                            ...form.weeks.slice(0, index),
                            {
                              ...form.weeks[index],
                              untilTime: e.target.value,
                            },
                            ...form.weeks.slice(index + 1),
                          ],
                        });
                      }}
                    />
                  </div>
                  {form.weeks.length > 1 && (
                    <Icon
                      name="times-circle"
                      className="clickable ml-3"
                      fontSize="20"
                      onClick={() => {
                        setForm({
                          ...form,
                          weeks: [...form.weeks].filter(
                            (item, subIndex) => index !== subIndex
                          ),
                        });
                      }}
                    />
                  )}
                </div>
              </>
            ))}
            <CustomButton
              color="primary"
              style={{
                height: 33,
              }}
              onClick={() => {
                setForm({
                  ...form,
                  weeks: [
                    ...form.weeks,
                    {
                      items: [],
                      fromTime: '09:00',
                      untilTime: '17:00',
                    },
                  ],
                });
              }}
            >
              Add
            </CustomButton>
          </>
        ),
      },
      {
        type: 'col',
        label: 'How far in the future can events be booked?',
        className: 'position-form-holder',
        inputs: [
          {
            key: 'future.time',
            placeholder: 'Time',
            type: 'selectv2',
            options: range(1, 61).map((item) => ({
              label: item,
              value: item,
            })),
            cols: {
              sm: 12,
              md: 2,
            },
          },
          {
            key: 'future.unit',
            placeholder: 'Unit',
            type: 'selectv2',
            options: [
              {
                label: 'weeks',
                value: 'weeks',
              },
              {
                label: 'months',
                value: 'months',
              },
            ],
            cols: {
              sm: 12,
              md: 2,
            },
          },
        ],
      },
      {
        type: 'component',
        label: 'Where should we create the booking page?',
        customComponent: ({ form, setForm }) => (
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                style={{
                  background: 'var(--secondary)',
                }}
              >
                schedule.weselect.com/
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Booking Page"
              defaultValue={form.bookingPage}
              onBlur={(e) => {
                setForm({
                  ...form,
                  bookingPage: e.target.value,
                });
              }}
            />
          </InputGroup>
        ),
      },
    ];
  }, [view]);

  return (
    <Form
      facc
      hasButton={false}
      defaultValues={values}
      inputs={inputs}
      resourceName="settings-scheduler-page-add"
    >
      {(inputs, data, checkForm) => (
        <>
          <Layout
            title={title}
            data-testid="settings-scheduler-page-add-schedule-layout"
            footer={
              <div className="d-flex align-center-center justify-content-end">
                <CustomButton
                  color="primary"
                  outline
                  round
                  data-testid="settings-scheduler-page-add-schedule-back-button"
                  onClick={() => {
                    if (view === 1) history.push('/settings/scheduler');
                    else {
                      setView(1);
                      setTitle(title.slice(0, -1));
                    }
                  }}
                >
                  {view === 1 ? 'Cancel' : 'Back'}
                </CustomButton>
                <CustomButton
                  color="success"
                  round
                  data-testid="settings-scheduler-page-add-schedule-next-button"
                  onClick={() => {
                    setValues(data);
                    if (view === 1) {
                      if (!checkForm()) return false;

                      setView(2);
                      setTitle([...title, 'Availability']);
                    }
                    if (view === 2) {
                      if (!checkForm()) return false;
                      log(data);
                    }

                    return true;
                  }}
                >
                  {view === 1 ? 'Next' : 'Create'}
                </CustomButton>
              </div>
            }
            bodyClassName="p-0"
          >
            <div className="d-flex justify-content-between flex-wrap">
              <FormContainer hasPreviewContainer={view === 1}>
                {inputs}
              </FormContainer>
              {view === 1 && (
                <PreviewContainer>
                  <ul className="mb-0">
                    <PreviewListItem>
                      <PreviewLabel className="mb-0">Preview</PreviewLabel>
                    </PreviewListItem>
                    <PreviewListItem>
                      <ItemContainer className="d-flex justify-content-center flex-column">
                        <ItemTitle className="mb-0">
                          Initial Interview
                        </ItemTitle>
                        <ItemDuration className="mb-0">
                          15 Minute Meeting - Location TBD
                        </ItemDuration>
                      </ItemContainer>
                    </PreviewListItem>
                  </ul>
                </PreviewContainer>
              )}
            </div>
          </Layout>
        </>
      )}
    </Form>
  );
};

export default SchedulerCreate;
