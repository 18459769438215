import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import Tippy, { useSingleton } from '@tippyjs/react';
import Layout from '../../../../components/layouts/default';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import Button from '../../../../components/Button';
import Icon from '../../../../components/@v2/Icon';
import Card from '../../../../components/@v2/Card';
import Table from '../../../../components/@v2/Table/Table';
import Search from '../../../../components/@v2/Search/Search';
import Paginator from '../../../../components/@v2/Paginator/Paginator';
import { deleteFbRule, getAdRulesList } from '../../../../store/api/fb-rules.api';
import { addFacebookToken } from '../../../../store/api/admin.api';
import FacebookLinkedAccount from '../../../../components/FacebookLinkedAccount';
import FacebookLogin from '../../../../components/FacebookLogin';
import useFbData from '../../../../hooks/useFbData';
import events from '../../../../helpers/events';
import DeleteModelTooltip from '../../../../components/@v2/DeleteModelTooltip/DeleteModelTooltip';

const Facebook = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, data, meta, setMeta, pageSize, reloadData } = useApiRequestContext();
  const { fbUser, loadFbData } = useFbData();
  const [source, target] = useSingleton();

  const getColor = (priority) => {
    if (priority >= 3) return 'red';
    if (priority === 2) return 'orange';
    return 'green';
  };

  const getPriority = (priority) => {
    if (priority >= 3) return t('facebook.high');
    if (priority === 2) return t('facebook.medium');
    return t('facebook.low');
  };

  const onFacebookResponse = async (fbResponse) => {
    if (fbResponse?.accessToken) {
      await addFacebookToken({
        FBShortLiveToken: fbResponse?.accessToken,
      });

      loadFbData();
    }
  };

  useEffect(() => {
    events.$on('facebook--token-deleted', () => {
      loadFbData();
    });

    return () => {
      events.$off('facebook--token-deleted');
    };
  }, []);

  return (
    <Layout
      testId="facebook"
      pretitle={t('general.admin')}
      title={t('general.facebook')}
      rightContent={
        <div className="flex gap-x-2 items-center">
          {fbUser?.isValid && <FacebookLinkedAccount fbUserData={fbUser} />}
          {!loading && !fbUser?.isValid && <FacebookLogin onFacebookResponse={onFacebookResponse} />}
          <Button
            color="primary"
            onClick={() => {
              history.push('/admin/facebook/add');
            }}
            data-testid="facebook-add-rule-button"
          >
            <div className="flex items-center gap-x-2">
              <Icon name="plus" />
              {t('facebook.add-rule')}
            </div>
          </Button>
        </div>
      }
      className="users-page"
    >
      <Card className="anim-table-delayed shadow-table !border !border-line-default">
        <Tippy
          trigger="click mouseenter"
          singleton={source}
          theme="light"
          interactive
          maxWidth={200}
          appendTo={document.body}
          placement="bottom"
        />
        <Search
          placeholder={t('general.search-using', { resource: 'name' })}
          resource="name"
          meta={meta}
          setMeta={setMeta}
        />
        <Table
          isLoaded={!loading}
          activeSort={get(meta, 'sorts') || ''}
          items={get(data, 'items')}
          headers={[
            {
              label: t('facebook.name'),
              key: 'name',
            },
            {
              label: t('facebook.priority'),
              key: 'priority',
            },
            {
              label: t('facebook.campaign-template-id'),
              key: 'campaignTemplateId',
            },
            {
              label: t('general.actions'),
              key: 'actions',
              sticky: true,
              sort: false,
              width: 100,
              headerClassName: 'text-center',
            },
          ]}
          pageSize={pageSize}
          renderBody={(item, index) => {
            return (
              <tr key={item?.id} id={`client-table-row--${index}`}>
                <td>{item?.name}</td>
                <td>
                  <div className="d-flex align-items-center">
                    <Icon
                      type="fas"
                      name={item?.priority <= '1' ? 'long-arrow-down' : 'long-arrow-up'}
                      color={getColor(item?.priority)}
                    />
                    <span className="ml-2">{getPriority(item?.priority)}</span>
                  </div>
                </td>
                <td>{item?.campaignTemplateId}</td>
                <td className="column-sticky">
                  <div className="flex items-center justify-center gap-x-2">
                    <Tippy content={t('general.edit')} singleton={target}>
                      <button
                        type="button"
                        onClick={() => {
                          history.push(`/admin/facebook/edit/${item?.id}`);
                        }}
                      >
                        <Icon name="edit" />
                      </button>
                    </Tippy>
                    <DeleteModelTooltip
                      singleton={target}
                      content={
                        <p>
                          {t('modal.container.are-you-sure-delete')}: {item.name}
                        </p>
                      }
                      onDelete={async () => {
                        await deleteFbRule(item?.id);
                        reloadData();
                      }}
                    >
                      <button type="button">
                        <Icon name="trash" />
                      </button>
                    </DeleteModelTooltip>
                  </div>
                </td>
              </tr>
            );
          }}
          setMeta={setMeta}
        />
        <div className="h-14 flex items-center bg-white">
          <Paginator
            pageCount={data?.totalCount / meta?.pageSize}
            initialPage={meta.page - 1}
            onPageChange={(value) => {
              if (meta.page !== value) {
                setMeta({
                  page: value,
                });
              }
            }}
            disableInitialCallback
          />
        </div>
      </Card>
    </Layout>
  );
};

const FacebookWrapper = () => {
  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source, ...rest) => {
        const newFilters = {};

        const filters = rest?.[2] || [];

        filters.forEach((item) => {
          newFilters[item.key] = item.value;
        });

        const sort = {
          by: meta?.sorts?.replace('-', ''),
          direction: meta.sorts.includes('-') ? 'desc' : 'asc',
        };

        const response = await getAdRulesList(
          {
            filter: newFilters,
            page: {
              number: meta.page,
              size: meta.pageSize,
            },
            sort: meta.sorts ? sort : {},
          },
          {
            cancelToken: source.token,
          }
        );
        return response;
      }}
    >
      <Facebook />
    </ApiRequestProvider>
  );
};

export default FacebookWrapper;
