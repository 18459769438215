import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ApiRequestProvider, { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import { getEventsSettings, updateEventsSettings } from '../../../store/api/client.api';
import Button from '../../Button';
import Card from '../../Card';
import Field from '../Form/Field';
import { selectOptions } from '../../../store/selectors/settings.selector';
import { useSelector as useSelectorToolkit } from '../../../store';

const ApplicationEventsSchema = Yup.object().shape({
  notifyRecruiter1: Yup.bool(),
  notifyRecruiter2: Yup.bool(),
  notifiedUsers: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
  ),
});

const ApplicationEvents = () => {
  const { t } = useTranslation();
  const { loading, data } = useApiRequestContext();
  const options = useSelectorToolkit(selectOptions);
  const recruiters = options?.recruiters;

  const onSubmit = async (values) => {
    try {
      const finalData = {
        alwaysNotifyResponsibleWhenCandidatesApply: values?.notifyRecruiter1,
        notifyResponsibleWhenPositionPublishEnds: values?.notifyRecruiter2,
        notifiedUsersIds: values?.notifiedUsers?.map((item) => item?.id),
      };

      await updateEventsSettings(finalData);
    } catch (error) {
      throw new Error(error);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Formik initialValues={data} validationSchema={ApplicationEventsSchema} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting }) => {
        return (
          <Card className="overflow-hidden" containerClassName="anim-table-delayed">
            <form onSubmit={handleSubmit} className="anim-table-delayed w-full !p-4">
              <p className="-mt-4 border-b -mx-4 px-4 !py-3">{t('application-events.label')}</p>
              <Field
                type="switch"
                name="notifyRecruiter1"
                label={t('application-events.inform-responsible-recruiter-candidate-applies')}
              />
              <Field
                type="switch"
                name="notifyRecruiter2"
                label={t('application-events.inform-responsible-recruiter-before-last-application')}
              />
              <Field
                searchable={false}
                multiple
                type="select"
                keys={['id', 'name']}
                name="notifiedUsers"
                label={t('general.informed-users')}
                defaultOptions={recruiters}
              />
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                data-testid="settings-application-events-submit-button"
              >
                {t('general.apply-changes')}
              </Button>
            </form>
          </Card>
        );
      }}
    </Formik>
  );
};

const ApplicationEventsWrapper = () => {
  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source) => {
        const response = await getEventsSettings({
          cancelToken: source.token,
        });

        return {
          notifyRecruiter1: response.alwaysNotifyResponsibleWhenCandidatesApply,
          notifyRecruiter2: response.notifyResponsibleWhenPositionPublishEnds,
          notifiedUsers: response?.notifiedUsers || [],
        };
      }}
    >
      <ApplicationEvents />
    </ApiRequestProvider>
  );
};

export default ApplicationEventsWrapper;
