import React from 'react';
import BaseTextToolbar from './BaseTextToolbar';
import AligmentToolbar from './AligmentToolbar';

const TextAligmentToolbar = ({ withVideo }) => {
  return (
    <>
      <BaseTextToolbar withVideo={withVideo} />
      <AligmentToolbar />
    </>
  );
};

TextAligmentToolbar.defaultProps = {
  withVideo: false,
};

export default React.memo(TextAligmentToolbar);
