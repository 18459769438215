import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { NotificationManager } from 'react-notifications';
import Axios from 'axios';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import Layout from '../../../../components/layouts/default';
import Button from '../../../../components/Button';
import Field from '../../../../components/@v2/Form/Field';
import { getImageServiceMediaUrl, getConvertedImageUrlString } from '../../../../helpers/image';
import { PermissionLevel, UserLanguage } from '../../../../constants/User';
import ImageDropzone from '../../../../components/input/ImageDropzone';
import { checkEmail, createUser, getUserByUserId, updateUser } from '../../../../store/api/user.api';
import { setLocale } from '../../../../store/slices/settings.slice';
import { setUserData } from '../../../../store/slices/user.slice';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../../store';
import { selectUser } from '../../../../store/selectors/user.selector';

const RECRUITER_MANAGER_VALUE = 1;
const PERMISSION_OPTIONS = Object.entries(PermissionLevel)
  .map((value, key) => ({
    label: value[1],
    value: parseInt(key, 10),
  }))
  .filter((item) => item.value !== RECRUITER_MANAGER_VALUE);

const SettingsUser = () => {
  const { t } = useTranslation();
  const { loading, data } = useApiRequestContext();
  const { type } = useParams();
  const history = useHistory();
  const isEdit = !!data?.userId;
  const user = useSelectorToolkit(selectUser);

  const getFullName = () => {
    if (type === 'edit') {
      return `${data?.firstName} ${data?.lastName}`;
    }

    return null;
  };

  const SettingsUserSchema = Yup.object().shape({
    firstName: Yup.string().required(t('form-validator.required')),
    lastName: Yup.string().required(t('form-validator.required')),
    email: Yup.string().email().required(t('form-validator.required')),
  });

  const onSubmit = async (values) => {
    try {
      const formData = {
        ...values,
        language: values?.language?.value || 'en-US',
        permissionLevel: values?.permissionLevel?.value || 0,
      };

      if (formData.avatar instanceof File) {
        const imageUrlString = await getConvertedImageUrlString(formData.avatar);

        formData.avatarUpload = {
          filename: formData.avatar?.path,
          content: imageUrlString.split('base64,')[1],
        };
      }

      if (isEdit) {
        const requests = [updateUser(formData)];

        formData.avatar = formData.avatar === null && data?.avatar ? '' : data?.existingAvatar;

        await Axios.all(requests);

        if (values?.userId === user.userId) {
          const userData = await getUserByUserId(values?.userId);

          dispatchToolkit(
            setUserData({
              ...user,
              ...userData,
              locale: userData.language,
            })
          );
          dispatchToolkit(setLocale(userData.language));
        }
      } else {
        const userExists = await checkEmail(formData.email);

        if (userExists === true) {
          NotificationManager.error(t('error-message.email-not-unique'));
        } else {
          await createUser(formData);
        }
      }
      history.push('/settings/users');
    } catch (error) {
      throw new Error(error);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Layout
      pretitle={t('general.settings')}
      title={type === 'add' ? t('admin.users.add-user') : `${t('general.edit')} ${getFullName()}`}
    >
      <Formik initialValues={data} validationSchema={SettingsUserSchema} onSubmit={onSubmit}>
        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="anim-table-delayed max-w-4xl w-full shadow-sm p-4 bg-white rounded-lg"
            >
              <div className="mb-4 flex justify-center">
                <ImageDropzone
                  avatar
                  value={values?.avatar}
                  onChange={([file]) => {
                    setFieldValue('avatar', file);
                  }}
                  onDeleteAvatar={() => {
                    setFieldValue('avatar', null);
                  }}
                  resource="users-avatar"
                />
              </div>
              <div className="flex items-start gap-x-4">
                <Field required name="firstName" label={t('general.first-name')} testId="users-first-name-input" />
                <Field required name="lastName" label={t('general.last-name')} testId="users-last-name-input" />
              </div>
              <div className="flex items-start gap-x-4">
                <Field required name="email" label={t('admin.users.email')} testId="users-email-input" />
                <Field
                  type="number"
                  name="phoneNumber"
                  label={t('admin.users.phone-number')}
                  testId="users-phone-number-input"
                />
              </div>
              <div className="flex items-start gap-x-4">
                <Field
                  type="select"
                  defaultOptions={UserLanguage}
                  name="language"
                  label={t('general.language')}
                  testId="users-language-select"
                />
                <Field
                  type="select"
                  defaultOptions={PERMISSION_OPTIONS}
                  name="permissionLevel"
                  label={t('settings.users.editor.permission-level')}
                  testId="users-permission-select"
                />
              </div>
              <Field type="text" name="costCenter" label={t('settings.reference')} />
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                data-testid="users-submit-button"
              >
                {t('general.submit')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

const SettingsUserFormWrapper = () => {
  const { type, id } = useParams();

  const DEFAULT_USER_FORM = {
    userId: 0,
    loginId: 0,
    avatar: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    costCenter: '',
    language: UserLanguage[0],
    permissionLevel: PERMISSION_OPTIONS[0],
  };

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source) => {
        if (type === 'edit' && id) {
          const response = await getUserByUserId(id, {
            cancelToken: source.token,
          });

          return {
            ...DEFAULT_USER_FORM,
            ...response,
            avatar: getImageServiceMediaUrl({
              ...response?.avatar,
              name: response?.avatar?.id,
            }),
            existingAvatar: response?.avatar,
            language: UserLanguage.find((item) => item?.value === response?.language),
            permissionLevel: PERMISSION_OPTIONS.find((item) => item?.value === response?.permissionLevel),
          };
        }

        return DEFAULT_USER_FORM;
      }}
    >
      <SettingsUser />
    </ApiRequestProvider>
  );
};

export default SettingsUserFormWrapper;
