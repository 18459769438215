import Axios from 'axios';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { FormGroup, Label } from 'reactstrap';
import * as Yup from 'yup';
import Field from '../../../../components/@v2/Form/Field';
import Button from '../../../../components/Button';
import Icon from '../../../../components/@v2/Icon';
import Layout from '../../../../components/layouts/default';
import { createLinkSuffix, log } from '../../../../helpers/utils';
import { checkClientUploadedFonts, createAndUploadFontCss, uploadFont } from '../../../../store/api/career-page.api';
import { getClient, getAdminClients, getPageSettings, updatePageSettings } from '../../../../store/api/client.api';
import File from '../../../../components/File';

const CustomFontForm = () => {
  const { t } = useTranslation();
  const [savingToAccount, setSavingToAccount] = useState(false);
  const [automaticSaveToAccount, setAutomaticSaveToAccount] = useState(false);

  const CustomFontFormSchema = Yup.object().shape({
    account: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .default(null)
      .nullable()
      .required(t('form-validator.required')),
    agreement: Yup.bool().oneOf([true], t('form-validator.required')),
  });

  const uploadFontURLs = async ({ downloadName, downloadLink, clientId }) => {
    const fontURLs = {
      titleFontUrl: downloadName ? `${downloadLink || ''}-title.woff` : null,
      subtitleFontUrl: downloadName ? `${downloadLink || ''}-subtitle.woff` : null,
      bodyFontUrl: downloadName ? `${downloadLink || ''}-body.woff` : null,
    };

    return createAndUploadFontCss(clientId, fontURLs);
  };

  const upload = async (type, file, clientId) => {
    const formData = new FormData();
    formData.append('file', file);

    return uploadFont({
      type,
      clientId,
      body: formData,
    });
  };

  const onSubmit = async (values, { setFieldValue }) => {
    try {
      let requests = [uploadFontURLs(values)];

      if (values?.headingFont) {
        requests = [...requests, upload('title', values?.headingFont, values?.clientId)];
      }

      if (values?.subtitleFont) {
        requests = [...requests, upload('subtitle', values?.subtitleFont, values?.clientId)];
      }

      if (values?.bodyFont) {
        requests = [...requests, upload('body', values?.bodyFont, values?.clientId)];
      }

      await Axios.all(requests);
      NotificationManager.success(t('general.success'));

      setFieldValue('headingFont', null);
      setFieldValue('subtitleFont', null);
      setFieldValue('bodyFont', null);
      setAutomaticSaveToAccount(true);
    } catch (error) {
      // log(error);
    }
  };

  const checkUploadedFonts = async (clientId, callback) => {
    try {
      const hasUploaded = await checkClientUploadedFonts(clientId);
      const client = await getClient(true, clientId);

      callback({
        hasUploaded,
        client,
      });
    } catch (error) {
      log(error);
    }
  };

  const setToActiveClient = async ({ downloadName }, setFieldValue) => {
    if (savingToAccount) return;

    try {
      setSavingToAccount(true);

      const pageSettings = await getPageSettings();

      await updatePageSettings({
        ...pageSettings,
        generalStyling: {
          ...pageSettings?.generalStyling,
          header: {
            family: `${downloadName}-title-font`,
            weight: 400,
            size: '40px',
          },
          subTitle: {
            family: `${downloadName}-subtitle-font`,
            weight: 700,
            size: '28px',
          },
          body: {
            family: `${downloadName}-body-font`,
            weight: 700,
            size: '16px',
          },
        },
      });

      NotificationManager.success(t('general.success'));

      setFieldValue('automaticallyUpdated', true);
    } catch (error) {
      // log(error);
    } finally {
      setSavingToAccount(false);
    }
  };

  const onPreview = (guid) => {
    const newTab = window.open(`${global?.appConfig?.api_url}/page/${guid}`, '_blank');
    newTab.focus();
  };

  return (
    <Layout pretitle={t('general.admin')} title={t('admin.tool.fonts')}>
      <Formik
        initialValues={{
          account: null,
          clientId: null,
          hasUploaded: false,
          automaticallyUpdated: false,
          downloadName: '',
          clientGuid: null,
          agreement: false,
        }}
        validationSchema={CustomFontFormSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="anim-table-delayed max-w-4xl w-full shadow-sm p-4 bg-white rounded-lg"
            >
              <h3>{t('tool.create-css')}</h3>
              <Field
                name="account"
                label={t('general.account')}
                testId="custom-font-account-input"
                placeholder={t('admin.tool.search-client')}
                type="select"
                api={getAdminClients}
                objectFilters={{
                  page: {
                    size: 10,
                    number: 1,
                  },
                }}
                keys={['id', 'name']}
                searchKey="search"
                callback={(selected) => {
                  if (!selected?.[0]?.id || !selected?.[0]?.name) {
                    return;
                  }

                  const { id, name, guid } = selected?.[0];

                  setFieldValue('clientId', id);
                  setFieldValue('key', createLinkSuffix(name));
                  setFieldValue('automaticallyUpdated', false);
                  setFieldValue('agreement', false);
                  setFieldValue('clientGuid', guid || null);

                  checkUploadedFonts(id, (item) => {
                    const downloadName = item?.client?.key;

                    setFieldValue('hasUploaded', item?.hasUploaded);
                    setFieldValue('downloadName', downloadName);
                    setFieldValue(
                      'downloadLink',
                      (() => {
                        if (downloadName && global?.appConfig?.storage_url) {
                          return `${global?.appConfig?.storage_url}/fonts/${downloadName}`;
                        }
                        return null;
                      })()
                    );
                  });
                }}
                helperText={
                  values?.hasUploaded &&
                  !values?.automaticallyUpdated && (
                    <div data-testid="tool-has-existing" className="text-red-500 !mt-2">
                      <Icon name="exclamation-triangle" style={{ marginRight: 5 }} />
                      {t('tool.already-has-existing')}
                    </div>
                  )
                }
                required
              />
              {!values?.automaticallyUpdated && (
                <FormGroup>
                  <Label>{t('general.files')}</Label>
                  <div className="flex items-center">
                    <File
                      label="H1 (Headings)"
                      onChange={(fonts) => {
                        setFieldValue('headingFont', fonts?.[0]);
                      }}
                      downloadableLink={values?.downloadName ? `${values?.downloadLink || ''}-title.woff` : null}
                      testId="tool-h1-button"
                    />
                    <File
                      label="H2 (Subtitle)"
                      onChange={(fonts) => {
                        setFieldValue('subtitleFont', fonts?.[0]);
                      }}
                      downloadableLink={values?.downloadName ? `${values?.downloadLink || ''}-title.woff` : null}
                      testId="tool-h2-button"
                    />
                    <File
                      label="A (Body)"
                      onChange={(fonts) => {
                        setFieldValue('bodyFont', fonts?.[0]);
                      }}
                      downloadableLink={values?.downloadName ? `${values?.downloadLink || ''}-title.woff` : null}
                      testId="tool-h3-button"
                    />
                    {values?.hasUploaded && (
                      <File
                        label="CSS"
                        icon="file-code"
                        copyLink={values?.downloadName ? `${values?.downloadLink || ''}.css` : null}
                        testId="tool-css"
                      />
                    )}
                  </div>
                </FormGroup>
              )}
              {!values?.automaticallyUpdated && values?.hasUploaded && (
                <Field
                  name="agreement"
                  label={t('tool.acknowledge')}
                  testId="custom-font-agreement-switch"
                  type="switch"
                />
              )}
              {values?.automaticallyUpdated && (
                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    onPreview(values?.clientGuid);
                  }}
                  data-testid="tool-preview-button"
                >
                  {t('general.preview-the-main-page')}
                </Button>
              )}
              {automaticSaveToAccount && !values?.automaticallyUpdated && (
                <>
                  <p>
                    {t('admin.tool.use-the-filling-informaiton-account', {
                      downloadName: values?.downloadName,
                    })}
                  </p>
                  <Button
                    type="button"
                    onClick={() => {
                      setToActiveClient(values, setFieldValue);
                    }}
                    color="primary"
                    loading={savingToAccount}
                    data-testid="tool-automatically-save-button"
                  >
                    {t('tool.automatically-save')}
                  </Button>
                </>
              )}
              {!automaticSaveToAccount && (
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  data-testid="products-submit-button"
                >
                  {t('tool.upload-fonts')}
                </Button>
              )}
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default CustomFontForm;
