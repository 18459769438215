import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import Layout from '../components/layouts/default';
import { getDashboardSettings } from '../store/api/analytics.api';
import '../assets/sass/analytics.sass';
import { log } from '../helpers/utils';

const analytics = () => {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const [dashboardSettings, setDashboardSettings] = useState({
    type: 'report',
    embedUrl: '',
    accessToken: '',
    id: '',
    settings: {
      panes: {
        filters: {
          visible: false,
          expanded: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
    },
    tokenType: models.TokenType.Embed,
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await getDashboardSettings();
        if (!response.accessToken) {
          setLoaded(false);
          return;
        }

        setLoaded(true);

        setDashboardSettings({
          ...dashboardSettings,
          ...{
            accessToken: response.accessToken,
            id: response.embedReportId,
            embedUrl: response.embedUrl,
          },
        });
      } catch (error) {
        log(error);
      }
    })();
  }, []);

  return (
    <Layout title={t('general.analytics')}>
      <div className="anim-table-delayed">
        {loaded && (
          <PowerBIEmbed
            embedConfig={dashboardSettings}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  () => {
                    log('Report loaded');
                  },
                ],
                [
                  'rendered',
                  () => {
                    log('Report rendered');
                  },
                ],
                [
                  'error',
                  (event) => {
                    log(event);
                  },
                ],
              ])
            }
            cssClassName="analytics--report"
          />
        )}
        {!loaded && t('analytics.no-report')}
      </div>
    </Layout>
  );
};

export default analytics;
