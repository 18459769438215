import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import Icon from '../Icon';

interface Props {
  publishedDate: string;
  publishedBy: string;
  changedDate: string;
  changedBy: string;
  clientGuid?: string;
  clientKey?: string;
}

const CareerPageHeader = ({
  publishedDate,
  publishedBy,
  changedDate,
  changedBy,
  clientGuid = '',
  clientKey = '',
}: Props) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const ref = useRef(null);
  // @ts-ignore
  const [style, animate] = useSpring(() => ({ height: '0px' }), []);

  const onPreview = () => {
    // @ts-ignore
    const newTab = window.open(`${global?.appConfig?.api_url}/page/${clientGuid}`, '_blank');
    newTab.focus();
  };

  const onShowCurrentCareerSite = () => {
    // @ts-ignore
    const newTab = window.open(`https://${clientKey}.${global?.appConfig?.career_page_url}`, '_blank');
    newTab.focus();
  };

  useEffect(() => {
    animate({
      minHeight: `${showDetails ? ref.current.offsetHeight + 10 : 0}px`,
      height: `${showDetails ? ref.current.offsetHeight + 10 : 0}px`,
    });
  }, [animate, ref, showDetails]);

  return (
    <div className="flex items-center flex-col overflow-hidden transition-all border-b border-gray-badge !px-6 !pt-6 flex-shrink-0">
      <p className="career-page-actions-header-label text-lg w-full text-center text-black font-bold">
        {t('career-page.page-editor')}
      </p>
      <animated.div
        style={{
          overflow: 'hidden',
          ...style,
        }}
      >
        <div ref={ref} className="w-full overflow-hidden transition-all">
          <div className="text-career-page-text-light text-[12px] !pb-2 border-b-2 border-[#DFE1E2]">
            <p data-testid="career-page-published" className="mb-0">
              {t('career-page.published')}: {moment.utc(publishedDate).fromNow()} ({publishedBy})
            </p>
            <p data-testid="career-page-last-change" className="mb-0">
              {t('career-page.last-change')}: {moment.utc(changedDate).fromNow()} ({changedBy})
            </p>
          </div>
          <div className="flex items-center justify-between gap-x-2 text-[13px] text-career-page-header-label !my-3">
            <button
              className="rounded-sm p-2 flex-grow bg-primary text-white"
              type="button"
              onClick={onPreview}
              data-testid="career-page-preview-button"
            >
              {t('career-page.preview-in-tab')}
            </button>
            <button
              className="rounded-sm p-2 flex-grow bg-primary text-white"
              type="button"
              onClick={onShowCurrentCareerSite}
              data-testid="career-page-show-current-career-site-button"
            >
              {t('career-page.show-current-career-site')}
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between gap-x-2 text-[13px] text-career-page-header-label !my-3">
          <button
            className="rounded-sm p-2 flex-grow bg-primary text-white"
            type="button"
            onClick={onPreview}
            data-testid="career-page-preview-button"
          >
            {t('career-page.preview-in-tab')}
          </button>
          <button
            className="rounded-sm p-2 flex-grow bg-primary text-white"
            type="button"
            onClick={onShowCurrentCareerSite}
            data-testid="career-page-show-current-career-site-button"
          >
            {t('career-page.show-current-career-site')}
          </button>
        </div>
      </animated.div>
      <button
        type="button"
        className={classNames(showDetails ? 'translate-y-0' : '-translate-y-2')}
        onClick={() => {
          setShowDetails(!showDetails);
        }}
      >
        <Icon name={showDetails ? 'chevron-up' : 'chevron-down'} color="#575a5d" />
      </button>
    </div>
  );
};

export default CareerPageHeader;
