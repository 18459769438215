import SearchLocationsQuery from '@ts/apicontracts/locations/search/search-locations-query';
import ResponseListItem from '@ts/apicontracts/locations/search/response-list-item';
import { AxiosResponse } from 'axios';

import { api } from '../../helpers/api-v2';

const resource = '/locations';

export const locationLookup = async (query, options = {}) => {
  const searchLocationQuery: SearchLocationsQuery = {
    filter: query.filters,
    sort: query.sort,
    page: {
      number: query.page || 1,
      size: query.pageSize || 20,
    },
    ...query,
  };

  const response = await api.post(`${resource}/lookup`, searchLocationQuery, options);
  return response as AxiosResponse<ResponseListItem[]>;
};

export const getAllLocations = async (query, options = {}) => {
  const searchLocationQuery: SearchLocationsQuery = {
    filter: query.filters,
    page: {
      number: query.page || 1,
      size: query.pageSize || 20,
    },
    sort: query.sort,
  };

  const response = await api.post(`${resource}/list`, searchLocationQuery, options);
  return response as AxiosResponse<ResponseListItem[]>;
};

export const restoreLocation = async (id: string) => {
  const response = await api.post(`${resource}/${id}/restore`);
  return response as AxiosResponse<ResponseListItem>;
};

export const deleteLocation = async (id: string) => {
  const response = await api.delete(`${resource}/${id}`);
  return response as AxiosResponse<ResponseListItem>;
};

export const createOrUpdateLocation = async (values) => {
  try {
    if (values?.id) {
      await api.put(resource, values);
    } else {
      await api.post(resource, values);
    }

    return true;
  } catch (error) {
    return false;
  }
};
