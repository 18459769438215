export const formatTotal = (total) => {
  if (!total) return '0';
  return total
    .toLocaleString('se', { maximumFractionDigits: 2 })
    .replace(/,/g, ' ');
};

export const getTotal = (productArr) => {
  if (!productArr) return 0;
  return productArr.reduce((acc, curr) => acc + curr.price, 0);
};

export const getFormattedTotal = (productArr) => {
  if (!productArr) return '0';
  return formatTotal(getTotal(productArr));
};

export default {};
