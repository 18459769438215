import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { useSpring, animated } from 'react-spring';
import { get, isFunction, isString } from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import moment from 'moment';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { CancelToken } from 'axios';
import {
  Popover,
  PopoverHeader,
  Button,
  PopoverBody,
  UncontrolledTooltip,
  Input,
} from 'reactstrap';
import { capitalizeFirstLetter, log } from '../../helpers/utils';
import Icon from '../@v2/Icon';
import CustomButton from '../Button';
import events from '../../helpers/events';
import i18n from '../../i18n';

const ButtonPopper = ({ icon, id, popper, index, resource, ...rest }) => {
  const { t } = useTranslation();
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [afterBodyData, setAfterBodyData] = useState(null);
  const isDisabled = useMemo(
    () =>
      popper &&
      get(popper, 'button.disabled') &&
      rest[get(popper, 'button.disabled')],
    [popper]
  );

  const getAfterBodyData = async () => {
    try {
      const response = await get(popper, 'afterBody.api')(rest);
      setAfterBodyData(response);
    } catch (error) {
      log(error);
    }
  };

  useEffect(() => {
    if (isPopperOpen && get(popper, 'afterBody.api')) getAfterBodyData();
  }, [isPopperOpen]);
  const parsedIcon = isString(icon) ? icon : icon(rest);

  const getPopperData = (item, key) => {
    if (item) {
      if (isString(get(item, key))) {
        return get(item, key);
      }

      return get(item, key)(rest);
    }
    return '';
  };

  const parsedHeader = useMemo(() => getPopperData(popper, 'header'), [popper]);
  const parsedBody = useMemo(() => getPopperData(popper, 'body'), [popper]);
  const actionButtonLabel = useMemo(() => {
    if (get(popper, 'button') && get(popper, 'button.label')) {
      return getPopperData(popper, 'button.label');
    }

    return '';
  }, [popper]);

  const renderChild = (param) => {
    if (isString(param)) return t(param);
    return param;
  };

  return (
    <>
      <button
        disabled={
          isFunction(rest.disabled)
            ? rest.disabled({ ...rest, id })
            : rest.disabled
        }
        key={`button-${parsedIcon}--${index}`}
        type="button"
        className="btn-naked"
        id={`${parsedIcon}-popover--${index}`}
        data-testid={`${resource}-${parsedIcon}-popover--${index}`}
        onClick={() => {
          if (popper) {
            return;
          }

          if (rest.onClick) {
            rest.onClick({
              ...rest,
              id,
            });
          }
        }}
      >
        {rest.tooltip && (
          <UncontrolledTooltip
            placement="top"
            target={`${parsedIcon}-popover--${index}`}
          >
            {isString(rest.tooltip) ? t(rest.tooltip) : rest.tooltip(rest)}
          </UncontrolledTooltip>
        )}
        <Icon name={parsedIcon} color={isDisabled ? '#c1c1c1' : null} />
      </button>
      {popper && (
        <Popover
          key={`popover-${parsedIcon}--${index}`}
          isOpen={isPopperOpen}
          placement={popper.placement || 'bottom'}
          trigger="legacy"
          target={`${parsedIcon}-popover--${index}`}
          toggle={() => {
            const isPopoverDisabled = isFunction(rest.disabled)
              ? rest.disabled({ ...rest, id })
              : rest.disabled;
            if (isPopoverDisabled === true) return;
            setIsPopperOpen(!isPopperOpen);
          }}
          boundariesElement="window"
          className={get(popper, 'className') || null}
        >
          {popper.header && (
            <PopoverHeader>{renderChild(parsedHeader)}</PopoverHeader>
          )}
          {popper.body && (
            <PopoverBody>
              {renderChild(parsedBody)}
              {isPopperOpen &&
                get(popper, 'afterBody.render') &&
                get(popper, 'afterBody.render')(afterBodyData || {})}
              {popper && popper.button && (
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <Button
                    color="link"
                    onClick={() => setIsPopperOpen(false)}
                    data-testid="popover-cancel-button"
                  >
                    {t('general.cancel')}
                  </Button>
                  <CustomButton
                    color={
                      isString(get(popper, 'button.type'))
                        ? get(popper, 'button.type')
                        : get(popper, 'button.type')(rest)
                    }
                    onClick={async () => {
                      popper.onClick({
                        ...rest,
                        id,
                      });

                      setIsPopperOpen(false);
                    }}
                    data-testid="popover-submit-button"
                  >
                    {renderChild(actionButtonLabel)}
                  </CustomButton>
                </div>
              )}
            </PopoverBody>
          )}
        </Popover>
      )}
    </>
  );
};

const TableBody = ({
  api,
  filterType,
  filterCondition,
  isLoading,
  setIsLoading,
  callback,
  headers,
  pageSize,
  emptyItemText,
  stateCallback,
  data,
  defaultFilter,
  defaultSort,
  resource,
}) => {
  const { body } = document;
  const animatedFilter = useRef();
  const { t } = useTranslation();
  const testId = 'tableBody';
  const history = useHistory();
  const location = useLocation();
  const [isRendered, setIsRendered] = useState(false);
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const animatedProps = useSpring({
    config: { duration: 150 },
    right: showModal ? 0 : -525,
  });

  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  const clientSideItems = useMemo(() => {
    if (filterType === 'client-side') {
      let list = Array.isArray(items) ? [...items] : [];
      const sFilters = get(location, 'state.filters');
      const csFilters = Object.keys(sFilters || {});

      if (csFilters.length > 0) {
        csFilters.forEach((item) => {
          list = [...list].filter((rowItem) => {
            if (
              item === 'name' &&
              rowItem.firstName !== undefined &&
              rowItem.lastName !== undefined
            ) {
              const name =
                `${rowItem.firstName} ${rowItem.lastName}`.toLowerCase();

              return name.includes(sFilters[item].toString().toLowerCase());
            }

            return get(rowItem, item)
              .toString()
              .toLowerCase()
              .includes(sFilters[item].toString().toLowerCase());
          });
        });

        return list;
      }
    }

    return items;
  }, [location.state, items]);

  const queryParams = useMemo(
    () => qs.parse(location.search),
    [location.search]
  );

  const callAPI = useCallback(
    async (params = {}, source = null) => {
      if ((!api && !data) || (!api && data && !data.length)) {
        setIsLoading(false);
      }
      if (api || (data && data.length)) {
        try {
          let response = null;

          if (filterType === 'server-side') {
            response = await api(
              {
                ...params,
                pageSize,
                page: parseInt(queryParams.currentPage || 1, 10),
              },
              source
                ? {
                    cancelToken: source.token,
                  }
                : {}
            );
          } else if (data && data.length) {
            response = data;
          } else {
            response = await api();
          }

          if (callback) callback(response, params);
          const items =
            get(response, 'candidates') ||
            get(response, 'items') ||
            get(response, 'data') ||
            get(response, 'payload') ||
            response;

          if (Array.isArray(items)) {
            setItems(items);
            stateCallback(response.totalCount || 0);
          }
        } catch (error) {
          setItems([]);
          stateCallback(0);
          // log(error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [queryParams, pageSize]
  );

  const getFiltersAndCallApi = (source = {}) => {
    let filters = '';
    const pureFilters = {};
    const sorts = get(location, 'state.sorts') || '';

    if (get(location, 'state.filters')) {
      const sFilters = get(location, 'state.filters');
      const pFilters = get(location, 'state.pure', {});

      Object.keys(pFilters).forEach((key) => {
        pureFilters[key] = pFilters[key];
      });

      Object.keys(sFilters).forEach((key) => {
        if (filters !== '') {
          filters += ',';
        }
        filters += `${key}${filterCondition[key] || '=='}${sFilters[key]}`;
      });
    }

    setTimeout(() => {
      if (!isLoading) setIsLoading(true);
      callAPI(
        {
          filters,
          ...pureFilters,
          sorts,
          searchValue: location.searchValue || '',
        },
        source
      );
    }, 150);
  };

  useEffect(() => {
    const source = CancelToken.source();

    if (!get(queryParams, 'modal') && get(queryParams, 'refresh')) {
      callAPI({}, source);
    }

    return () => {
      source.cancel();
    };
  }, [queryParams]);

  useEffect(() => {
    const source = CancelToken.source();

    if (get(location, 'state.type') !== 'modal') {
      if (isRendered) {
        if (filterType === 'server-side') {
          if (location.state) {
            getFiltersAndCallApi(source);
          }
        }
      } else if (!isRendered) {
        setIsRendered(true);

        history.push({
          ...location,
          state: {
            ...location.state,
            sorts: defaultSort || '',
            filters:
              defaultFilter && Object.keys(defaultFilter).length
                ? defaultFilter
                : {},
          },
          search: location.search || '',
          searchValue: location.searchValue || '',
        });
      }
    }
  }, [location.state, pageSize]);

  useEffect(() => {
    if (resource) {
      events.$on(`refresh-table--${resource}`, () => {
        setIsLoading(true);
        getFiltersAndCallApi();
      });
    }

    return () => {
      if (resource) events.$off(`refresh-table--${resource}`);
    };
  }, [location, pageSize, resource]);

  useEffect(() => {
    const source = CancelToken.source();

    if (filterType === 'client-side') {
      history.push({
        ...location,
        state: {
          ...location.state,
          filters: {},
        },
      });

      callAPI({}, source);
    }

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    const source = CancelToken.source();

    if (
      !queryParams.modal &&
      queryParams.currentPage &&
      !get(queryParams, 'r') &&
      isRendered
    ) {
      getFiltersAndCallApi(source);
    }

    return () => {
      source.cancel();
    };
  }, [queryParams]);

  useEffect(() => {
    events.$on('table-body-remove-selected', () => {
      setShowModal(false);
      setTimeout(() => {
        setSelected(null);
      }, 150);
    });
  }, [events]);

  const getColumnData = (
    item,
    { date, humanReadable, key, customBody, emptyText, buttons },
    index
  ) => {
    if (customBody) {
      return customBody(item, index);
    }
    const itemKey = get(item, key);

    if (date && itemKey) {
      moment.locale(i18n.language);

      if (humanReadable) {
        const fromNow = moment.utc(itemKey).fromNow();
        return capitalizeFirstLetter(fromNow);
      }

      return moment.utc(itemKey).format('YYYY-MM-DD');
    }

    if (buttons) {
      return (
        <>
          {buttons.map((button) => {
            if (button.hide && button.hide(item)) return null;

            if (button.type !== 'modal') {
              return (
                <ButtonPopper
                  key={`popover-${item.id}--${button.icon}`}
                  data-testid={`${resource}-popover-${button.icon}--${index}`}
                  api={getFiltersAndCallApi}
                  index={index}
                  resource={resource}
                  {...item}
                  {...button}
                />
              );
            }

            return (
              <button
                disabled={
                  isFunction(button.disabled)
                    ? button.disabled(item)
                    : button.disabled
                }
                key={`button-${button.icon(item)}`}
                data-testid={`${resource}-button-${button.icon(
                  item
                )}--${index}`}
                type="button"
                className="btn-naked"
                onClick={() => {
                  setSelected({
                    ...item,
                    element: button.modal({
                      ...item,
                      isOpen: true,
                    }),
                  });
                  setShowModal(true);
                }}
              >
                <Icon name={button.icon(item)} />
              </button>
            );
          })}
        </>
      );
    }

    return itemKey || emptyText || '-';
  };

  const createRowData = (item, rowIndex) => (
    <tr data-testid={`${testId}-${rowIndex}`} key={item.id || uuidv4()}>
      {headers.map((header, colIndex) => {
        if (header.hide) return null;
        if (header.type === 'checkbox') {
          return (
            <td
              data-testid={`${testId}-td-checkbox-${rowIndex}`}
              key={uuidv4()}
              style={{
                verticalAlign: 'middle',
              }}
            >
              <div className="d-flex align-items-center justify-content-center">
                <Input
                  type="checkbox"
                  data-testid={`${resource}-checkbox-${rowIndex}`}
                  checked={
                    (header.all ||
                      !!header.selected.find(
                        (current) => current.id === item.id
                      )) &&
                    !header.unchecked.find((current) => current.id === item.id)
                  }
                  onChange={() => {
                    let checked = true;
                    const foundItemFromSelected = header.selected.find(
                      (current) => current.id === item.id
                    );
                    const foundItemFromUnchecked = header.unchecked.find(
                      (current) => current.id === item.id
                    );

                    if (header.all) {
                      if (!foundItemFromUnchecked) checked = false;
                    } else if (foundItemFromSelected) checked = false;

                    header.onClick(item, false, checked);
                  }}
                />
              </div>
            </td>
          );
        }
        return (
          <td
            data-testid={`${resource}-td-${rowIndex}-${colIndex}`}
            key={uuidv4()}
            id={header.sticky ? `table-header-${item.id || uuidv4()}` : null}
            onClick={() => {
              if (!header.onClick) return;

              header.onClick(item);
            }}
            style={{
              height: 1,
              position: header.sticky ? 'sticky' : null,
              right: header.sticky ? 0 : null,
              background: header.sticky ? '#f9fbfd' : null,
              color: header.sticky ? '#696969' : null,
              borderLeft: header.sticky ? '1px solid #e1e2e2' : null,
              verticalAlign: 'middle',
              whiteSpace: 'nowrap',
            }}
            className={header.bodyClassName}
          >
            {header.sticky && (
              <div className="d-flex align-items-center justify-content-center">
                {getColumnData(item, header, rowIndex)}
              </div>
            )}
            {!header.sticky && getColumnData(item, header, rowIndex)}
          </td>
        );
      })}
    </tr>
  );

  useEffect(() => {
    if (showModal) document.body.classList.add('filter-modal-active');

    return () => {
      document.body.classList.remove('filter-modal-active');
    };
  }, [showModal]);

  if (isLoading) return null;

  return (
    <>
      {get(selected, 'element') &&
        createPortal(
          <animated.div
            className="card-header--filter-modal"
            style={{
              right: animatedProps.right,
            }}
            ref={animatedFilter}
          >
            {get(selected, 'element')}
          </animated.div>,
          body
        )}
      <tbody data-testid={testId}>
        {(filterType === 'server-side' ? items : clientSideItems).map(
          (item, index) => createRowData(item, index)
        )}
        {!isLoading && (items.length === 0 || clientSideItems.length === 0) && (
          <tr data-testid={`${resource}-table-empty`}>
            <td colSpan={headers.length} style={{ textAlign: 'center' }}>
              {emptyItemText || t('general.table-no-data')}
            </td>
          </tr>
        )}
      </tbody>
    </>
  );
};

TableBody.defaultProps = {
  emptyItemText: '',
  resource: 'general',
};

export default TableBody;
