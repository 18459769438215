import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import qs from 'query-string';
import Modal, { ModalBody } from '../Modal';

const PositionPublished = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { search, state, pathname } = location;
  const history = useHistory();
  const queryParams = qs.parse(search);

  const { positionId, urlText, activeClientKey } = queryParams;
  const isPublishOnly = queryParams.isPublishOnly === 'true';
  const isPublished = queryParams.isPublished === 'true';
  const isGettingPublished = queryParams.isGettingPublished === 'true';
  const positionFormUrl = `/p/${queryParams.positionId}-${urlText}`;

  const closeModal = () => {
    const queryParamsCopy = { ...queryParams };
    Reflect.deleteProperty(queryParamsCopy, 'modal');
    Reflect.deleteProperty(queryParamsCopy, 'positionId');
    Reflect.deleteProperty(queryParamsCopy, 'isPublishOnly');
    history.push({
      location: pathname,
      state,
    });
  };

  const defaultPublishText = useMemo(() => {
    return isGettingPublished
      ? t('edit-position.position-is-published')
      : t('positions.has-been-updated');
  }, [isGettingPublished]);

  return (
    <Modal width={500}>
      <ModalBody>
        <div className="flex justify-center items-center flex-column p-4">
          <h2>
            {isPublishOnly || positionId !== 'null'
              ? defaultPublishText
              : t('general.order-completed')}
          </h2>
          <p>{t('shop.add-advertising-anytime')}</p>
          <div className="flex justify-center items-center gap-x-2">
            {positionId !== 'null' && (
              <Button
                className="btn-rounded px-4"
                type="button"
                color="success"
                onClick={() => {
                  closeModal();
                  history.push('/positions');
                }}
              >
                {t('general.ok')}
              </Button>
            )}
            {isPublished && (
              <Button
                className="btn-rounded px-4"
                type="button"
                color="light"
                outline
                onClick={() => {
                  const urlToOpen = `${global?.appConfig?.external_protocol}${activeClientKey}.${global?.appConfig?.external_domain_name}${positionFormUrl}`;
                  window.open(urlToOpen, '_blank');
                  closeModal();
                  history.push('/positions');
                }}
              >
                {t('edit-position.view-position')}
              </Button>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PositionPublished;
