import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, FormGroup, Label, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { debounce } from 'lodash';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import Layout from '../../../../components/layouts/default';
import Field from '../../../../components/@v2/Form/Field';
import Icon from '../../../../components/@v2/Icon';
import { createOrUpdateFbRules, findFbRulesById } from '../../../../store/api/fb-rules.api';
import { deformatJsonLogic, formatJsonLogic } from '../../../../helpers/json-logic';
import QueryBuilder from '../../../../components/query-builder/QueryBuilder';
import Button from '../../../../components/Button';

const PRIORITY_OPTIONS = [
  {
    value: 3,
    label: 'facebook.high',
  },
  {
    value: 2,
    label: 'facebook.medium',
  },
  {
    value: 1,
    label: 'facebook.low',
  },
];

const TYPE_OPERATOR_OPTIONS = (t) => [{ label: t('general.url'), value: 'url' }];

const RETARGETING_RULE = (t) => {
  return {
    field: TYPE_OPERATOR_OPTIONS(t)[0],
    operator: null,
    value: null,
  };
};

const TRANSLATE_PRIORITY_OPTIONS = (t) => {
  return PRIORITY_OPTIONS.map((option) => ({
    ...option,
    label: t(option.label),
  }));
};

const FacebookForm = () => {
  const { t } = useTranslation();
  const { loading, data } = useApiRequestContext();

  const [apiErrors, setErrors] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const history = useHistory();
  const isEditing = !!data?.id;
  const toggleTab = (tab) => {
    if (selectedTab !== tab) setSelectedTab(tab);
  };

  const RETARGETING_OPERATOR_OPTIONS = [
    {
      value: 'contains',
      label: t('general.contains'),
    },
    {
      value: 'not-contains',
      label: t('general.notContains'),
    },
  ];

  const FacebookFormSchema = Yup.object().shape({
    name: Yup.string().nullable().required(t('form-validator.required')),
    campaignTemplateId: Yup.string().nullable().required(t('form-validator.required')),
    priority: Yup.object()
      .shape({
        label: Yup.string().required(t('form-validator.required')),
        value: Yup.number().required(t('form-validator.required')),
      })
      .nullable()
      .required(t('form-validator.required')),
    rules: Yup.array().of(
      Yup.object().shape({
        operator: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .nullable()
          .required(t('form-validator.required')),
        value: Yup.string().nullable().required(t('general.required')),
      })
    ),
  });

  const mapRetargetingFilters = (ruleArr) => {
    // mapping only for rule type 'Retargeting'
    return [
      {
        id: ruleArr?.[0].id || 0,
        type: ruleArr?.[0].type || 'Retargeting',
        filter: {
          // modify for nested filters support
          operator: 'and',
          filters: (ruleArr || []).map((item) => ({
            operator: item?.operator?.value,
            value: item?.value,
            field: 'url', // temp static value
          })),
        },
      },
    ];
  };

  const onSubmit = async (values) => {
    try {
      const finalData = {
        ...values,
        priority: values?.priority?.value || 0,
        jsonLogic: values?.jsonLogic ? formatJsonLogic(values?.jsonLogic) : '',
        rules: values?.rules && values?.rules?.length ? mapRetargetingFilters(values?.rules) : null,
      };

      await createOrUpdateFbRules(finalData);

      history.push('/admin/facebook');
    } catch (error) {
      setErrors(error?.response?.data?.error?.details || []);
      throw error;
    }
  };

  if (loading) {
    return null;
  }

  const Tabs = () => {
    return (
      <Nav tabs className="mb-4">
        <NavItem>
          <NavLink className={classnames({ active: selectedTab === 1 })} onClick={() => toggleTab(1)}>
            Broad
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: selectedTab === 2 })} onClick={() => toggleTab(2)}>
            Retargeting
          </NavLink>
        </NavItem>
      </Nav>
    );
  };

  const TabsContent = ({ values, setFieldValue }) => {
    return (
      <TabContent activeTab={selectedTab}>
        <TabPane tabId={1}>
          <Row>
            <Col sm="12">
              <Field
                required
                name="campaignTemplateId"
                type="number"
                label={t('facebook.campaign-template-id')}
                testId="facebook-campaign-template-id-input"
              />
              <FormGroup>
                <Label>{t('facebook.rule')}</Label>
                <p className="italic mb-0">{t('facebook.rule-instructions')}</p>
              </FormGroup>
              <Field
                required
                name="isQueryBuilder"
                type="switch"
                label={t('general.toggle-query-builder')}
                testId="facebook-campaign-template-id-input"
              />
              {values?.isQueryBuilder ? (
                <QueryBuilder
                  setQuery={debounce((value) => {
                    setFieldValue('jsonLogic', value);
                  }, 500)}
                  queryValue={values?.jsonLogic}
                />
              ) : (
                <Field required name="jsonLogic" type="textarea" testId="facebook-campaign-template-id-input" />
              )}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>
          <Row className="p-2 m-1 mb-4 bg-gray-50 rounded">
            <Col sm="12">
              <Row className="mb-2 p-2 justify-end">
                <Button
                  color="primary"
                  onClick={() => {
                    setFieldValue('rules', [...values.rules, RETARGETING_RULE(t)]);
                  }}
                >
                  {t('facebook.add-row')}
                </Button>
              </Row>
              {values.rules.map((_, index) => {
                return (
                  <Row>
                    <Col sm="3">
                      <Field
                        type="select"
                        searchable={false}
                        name={`rules[${index}].field`}
                        defaultOptions={TYPE_OPERATOR_OPTIONS(t)}
                        label={t('general.type')}
                        testId="rule-type-input"
                      />
                    </Col>
                    <Col sm="4">
                      <Field
                        type="select"
                        searchable={false}
                        defaultOptions={RETARGETING_OPERATOR_OPTIONS}
                        name={`rules[${index}].operator`}
                        label={t('general.operator')}
                        testId="rule-url-input-operator"
                      />
                    </Col>
                    <Col sm="4" className="pr-0">
                      <Field
                        name={`rules[${index}].value`}
                        type="string"
                        label={t('general.value')}
                        placeholder={t('general.value')}
                        testId="rule-url-input-value"
                      />
                    </Col>
                    <Col sm="1" className="pt-2">
                      <Button
                        disabled={values.rules.length === 1}
                        color="link"
                        className="mt-4 p-0"
                        onClick={() => {
                          setFieldValue('rules', [...values.rules.filter((_, ruleIndex) => ruleIndex !== index)]);
                        }}
                      >
                        <Icon name="trash" />
                      </Button>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    );
  };

  return (
    <Layout pretitle={t('general.admin')} title={isEditing ? t('facebook.edit-rule') : t('facebook.add-rule')}>
      <Formik
        initialValues={{
          ...data,
          rules:
            data?.rules && data?.rules?.length
              ? (data?.rules?.[0].filter?.filters || []).map((item) => {
                  return {
                    id: data?.rules?.[0].id || null,
                    type: data?.rules?.[0].type || 'Retargeting',
                    field: RETARGETING_RULE(t).field,
                    operator: RETARGETING_OPERATOR_OPTIONS.find(({ value }) => value === item.operator) || null,
                    value: item.value || null,
                  };
                })
              : [RETARGETING_RULE(t)],
        }}
        validationSchema={FacebookFormSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="anim-table-delayed max-w-4xl w-full shadow-sm p-4 bg-white rounded-lg"
            >
              {!!apiErrors?.length && (
                <Alert color="danger" className="mb-4 flex flex-col gap-y-4">
                  {apiErrors?.map((error) => (
                    <p className="mb-0" key={error}>
                      {error}
                    </p>
                  ))}
                </Alert>
              )}

              <div className="flex items-start gap-x-4">
                <Field required name="name" label={t('facebook.name')} testId="facebook-name-input" />
                <Field
                  required
                  type="select"
                  defaultOptions={TRANSLATE_PRIORITY_OPTIONS(t)}
                  name="priority"
                  label={t('facebook.priority')}
                  testId="facebook-priority-select"
                />
              </div>
              <Tabs />
              <TabsContent values={values} setFieldValue={setFieldValue} />
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                data-testid="facebook-submit-button"
              >
                {t('general.submit')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

const FacebookFormFormWrapper = () => {
  const { t } = useTranslation();
  const { type, id } = useParams();

  const DEFAULT_FACEBOOK_FORM = {
    name: '',
    priority: null,
    campaignTemplateId: '',
    isQueryBuilder: false,
    toggleRetargetingQueryBuilder: false,
  };

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source) => {
        if (type === 'edit' && id) {
          const response = await findFbRulesById(id, {
            cancelToken: source.token,
          });

          return {
            ...DEFAULT_FACEBOOK_FORM,
            ...response,
            priority: TRANSLATE_PRIORITY_OPTIONS(t)?.find((option) => option?.value === response?.priority),
            jsonLogic: deformatJsonLogic(response?.jsonLogic),
          };
        }

        return DEFAULT_FACEBOOK_FORM;
      }}
    >
      <FacebookForm />
    </ApiRequestProvider>
  );
};

export default FacebookFormFormWrapper;
