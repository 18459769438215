/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-shadow */
/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { debounce, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CommentForm from './CommentForm';
import Icon from '../@v2/Icon';
import { getLabel } from '../../constants/CandidatesHistoryTypes';
import Placeholder from '../@v2/Placeholder';
import events from '../../helpers/events';
import { useAPI } from '../../context/api';
import { log } from '../../helpers/utils';
import { selectUser } from '../../store/selectors/user.selector';
import { useSelector as useSelectorToolkit } from '../../store';

const Timeline = ({ callback, noData, entries, loading, activeCandidate, ...rest }) => {
  const [candidateAPI] = useAPI('candidate');
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [timelineItems, setTimeLineItems] = useState(entries);
  const timelineContainer = useRef(null);
  const lineElement = useRef(null);
  const currentUser = useSelectorToolkit(selectUser);

  const MessageTypeTextNotForDisplay = [7, 31];

  const permissionLevel = useMemo(() => {
    if (currentUser) {
      return currentUser.permissionLevel;
    }
    return 0;
  }, [currentUser]);

  const onResize = () => {
    const container = timelineContainer.current;
    const line = lineElement.current;
    if (container && line) {
      const arr = Array.from(container.children);
      arr.pop();
      line.style.height = `${arr.reduce(
        (accumulator, currentItem) => accumulator + currentItem.clientHeight + 25,
        0
      )}px`;
    }
  };

  const shownItems = useMemo(() => {
    if (showMore) return timelineItems;
    return timelineItems.slice(0, 5);
  }, [showMore, timelineItems]);

  useEffect(() => {
    window.addEventListener('resize', debounce(onResize, 150));

    return () => {
      events.$off('candidates-history--refresh');
    };
  }, []);

  function processMentions(timeline) {
    return timeline.map((item) => {
      const newItem = { ...item };
      const mentions = (item?.extraText || '').match(/@mention\[(.*?)\]/g);

      if (mentions && mentions.length) {
        let newText = item?.extraText || '';

        mentions.forEach((word) => {
          const parsedWord = word.match(/\[(.*?)\]/g);

          if (parsedWord[0]) {
            const [id, name] = parsedWord[0].replace('[', '').replace(']', '').split(',');

            newText = newText.replace(word, `<span class="badge-mention" data-id="${id}">${name.trim()}</span>`);
          }
        });

        newItem.extraText = newText;
      }

      return newItem;
    });
  }

  useEffect(() => {
    setTimeLineItems(
      processMentions(
        entries.map((item) => {
          if (item.type === 23 || item.type === 24) {
            return {
              ...item,
              name: activeCandidate.name,
            };
          }

          return item;
        })
      )
    );
  }, [entries]);

  useEffect(() => {
    if (!loading && lineElement.current) {
      onResize();
    }
  }, [shownItems, loading, lineElement]);

  const getTranslation = (text) => {
    const regex = /(\${)(.*?)(})/g;

    return text.replace(regex, (match) => t(`general.${match.replace(regex, '$2')}`));
  };

  const canEditComment = (item) => !!(currentUser.userId === item.userId || permissionLevel === 2);

  const editComment = (id) => {
    let selectedId = null;
    if (id !== selectedComment) selectedId = id;
    setSelectedComment(selectedId);
  };

  const deleteComment = async (item) => {
    try {
      if (item) {
        await candidateAPI.deleteComment({
          ...item,
          candidateHistoryEntryId: item.id,
          valueMentions: item.mentionsAuth0Id,
          valueString: item?.extraText,
          candidateId: get(activeCandidate, 'id', ''),
          userId: currentUser.userId,
          deleted: true,
          deletedDateStamp: moment().utc().format(),
          deleteUserId: currentUser.userId,
          editUserId: item.editUserId || currentUser.userId,
        });

        if (callback && get(activeCandidate, 'id', '')) {
          callback(get(activeCandidate, 'id', ''));
        }
      }
    } catch (error) {
      log('error', error);
    }
  };

  const commentDeleted = () => (
    <span style={{ color: 'var(--text-color)', fontStyle: 'italic' }}>{t('general.comment-deleted')}</span>
  );

  const displayRefappErrorMessage = (item) => {
    // possibly more incoming
    return item.extraParameter === 'invalid-email-domain'
      ? t('candidates.historyentry-name.invalid-email-domain')
      : '';
  };

  const displayTimelineOptions = (item, edit = true, index) => {
    if (item.deleted) {
      return commentDeleted();
    }

    return (
      <>
        {canEditComment(item) && (
          <>
            <div className="timeline-item-comment-link">
              {edit && (
                <>
                  <span
                    data-testid={`candidates-timeline-edit-${index}`}
                    id={`pptr-comment-edit-${index}`}
                    onClick={() => editComment(item.id)}
                    className="clickable button--timeline-edit"
                  >
                    {t('general.edit')}
                  </span>
                  <span>•</span>
                </>
              )}
              {((item.type === 2 && item.extraText !== '') || item.type === 4) && (
                <span
                  data-testid={`candidates-timeline-delete-${index}`}
                  id={`pptr-comment-delete-${index}`}
                  onClick={() => deleteComment(item)}
                  className="clickable button--timeline-delete"
                >
                  {t('general.delete')}
                </span>
              )}
            </div>

            {selectedComment && selectedComment === item.id && (
              <CommentForm
                id={index}
                entry={item}
                key={item.id}
                candidates={[activeCandidate]}
                isEdit
                {...rest}
                callback={(id) => {
                  if (callback) callback(id);
                  setSelectedComment(null);
                }}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className="tab-content--timeline">
      {!!shownItems.length && !loading && <span className="line" ref={lineElement} />}
      <div className="timeline" ref={timelineContainer}>
        {!loading &&
          shownItems.map((item, index) => {
            const name = get(activeCandidate, 'name');
            const historyItem = getLabel({ ...item, activeCandidate: `${name}` }, t);

            return (
              <div className="timeline-item" key={JSON.stringify(item)}>
                <div className="timeline-item--icon d-flex align-items-center justify-content-center">
                  <Icon name={historyItem.icon} color="white" />
                </div>
                <div className="d-flex flex-wrap">
                  <span className="font-weight-bold mr-2">{historyItem.tr}</span>
                  <div className="timeline-date">
                    <span>{moment.utc(item.dateStamp).fromNow()}</span>
                    <span>{moment.utc(item.dateStamp).format('YYYY-MM-DD HH:mm')}</span>
                  </div>
                </div>
                {item.deleted === false &&
                  !MessageTypeTextNotForDisplay.includes(item.type) &&
                  item?.extraText &&
                  item?.extraText.length > 1 && (
                    <pre
                      style={{ whiteSpace: 'pre-wrap' }}
                      dangerouslySetInnerHTML={{
                        __html: getTranslation(item?.extraText?.replace(new RegExp('\\${no-motivation}', 'g'), '')),
                      }}
                    />
                  )}
                {item.type === 4 && displayTimelineOptions(item, true, index)}
                {item.type === 2 && displayTimelineOptions(item, false, index)}
                {item.type === 31 && displayRefappErrorMessage(item)}
                {item.numberOfLikes > 0 && (
                  <span>
                    {item.numberOfLikes} {item.numberOfLikes > 1 ? 'likes' : 'like'}
                  </span>
                )}
              </div>
            );
          })}
        {loading &&
          [0, 1, 2, 3, 4, 5].map((item) => (
            <div className="timeline-item -mt-1" key={item}>
              <Placeholder className="timeline-item--icon" width={40} circle />
              <div className="flex gap-x-3 mb-4">
                <Placeholder height={20} width={500} />
                <Placeholder height={20} width={100} />
              </div>
              <Placeholder height={80} width={800} />
            </div>
          ))}
        {!loading && !shownItems.length && noData && (
          <div className="text-center">{t('candidates.timeline-no-data')}.</div>
        )}
      </div>
      {!showMore && shownItems.length < timelineItems.length && (
        <button
          type="button"
          className="timeline-show-more"
          data-testid="candidates-timeline-show-more"
          onClick={() => setShowMore(true)}
        >
          {`${t('general.show-more')}...`}
        </button>
      )}
    </div>
  );
};

Timeline.defaultProps = {
  callback: null,
  noData: false,
};

export default Timeline;
