import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { LOGO_POSITION_OPTIONS } from '../../../../../constants/Options';
import { useApiRequestContext } from '../../../../../context/@v2/ApiRequestContext';
import {
  defaultFocusPointCoords,
  defaultImageUpload,
  getImageServiceMediaUrl,
  getMediaUrl,
} from '../../../../../helpers/image';
import { onBlockUpdate } from '../../../../../store/api/career-page.api';
import Button from '../../../../Button';
import MediaUploader from '../../MediaUploader';
import TopImageConfigurator from '../../TopImageConfigurator';
import Icon from '../../../Icon';
import Field from '../../../Form/Field';
import { IFile } from '../../../../../interfaces/IFile';

interface Props {
  item: {
    title: string;
    subtitle: string;
    jobsButtonText: string;
    textShadowColorHex: string;
    hero: boolean;
    useTextShadow: boolean;
    image?: IFile;
    video?: IFile;
    logo?: IFile;
    logoPosition: number;
    logoLink: string;
    useOverlay: boolean;
    overlayColorHex: string;
    pageBlockId: number;
    pageId: number;
    type: number;
    sortOrder: number;
    visible: boolean;
    backgroundColorHex: string;
    headerTextColorHex: string;
    subtitleTextColorHex: string;
    textColorHex: string;
    positionId?: string | null;
    titleFontSizeScale: number;
    subtitleFontSizeScale: number;
    bodyFontSizeScale: number;
  };
}

const HeaderForm = ({ item }: Props) => {
  const { t } = useTranslation();
  const { data, reloadData } = useApiRequestContext();
  const POSITION_OPTIONS = LOGO_POSITION_OPTIONS(t);

  const onSubmit = async (values) => {
    await onBlockUpdate(
      data?.details,
      {
        ...values,
        logo: values?.logoUpload ? defaultImageUpload : values?.logo,
        video: values?.videoUpload ? defaultImageUpload : values?.video,
        image: values?.imageUpload ? defaultImageUpload : values?.image,
        logoPosition: values?.logoPosition?.value,
      },
      async () => {
        await reloadData();
        data?.renderSuccessNotification();
      }
    );
  };

  const onDrop = useCallback(
    (acceptedFiles, callback = null) => {
      const reader = new FileReader();

      reader.onload = () => {
        const dataURL = reader.result;
        if (callback) callback(dataURL);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={{
        ...item,
        logoPosition: POSITION_OPTIONS.find((option) => option?.value === item?.logoPosition),
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
        const imageCoords = values?.image?.focusPointJsonString
          ? JSON.parse(values?.image?.focusPointJsonString)
          : defaultFocusPointCoords;

        return (
          <form onSubmit={handleSubmit} className="w-full">
            <TopImageConfigurator
              label={t('pages.text-pic')}
              image={values?.image?.url || getImageServiceMediaUrl(values?.image)}
              activeImageCoords={imageCoords}
              callback={(coords) => {
                setFieldValue('image', {
                  ...values?.image,
                  focusPointJsonString: JSON.stringify(coords),
                });
              }}
              onDrop={(files) => {
                onDrop(files, (value) => {
                  setFieldValue('image', {
                    url: value,
                  });
                  setFieldValue('imageUpload', files[0]);
                });
              }}
              resource="form-header-picture"
              headerActions={
                values?.image && (
                  <Icon
                    name="trash"
                    className="clickable"
                    color="var(--text-color-secondary)"
                    onClick={() => {
                      setFieldValue('image', null);
                      setFieldValue('imageUpload', null);
                    }}
                    data-testid="header-picture-trash-icon"
                  />
                )
              }
            />
            <MediaUploader
              video
              accept="video/mp4"
              label={t('pages.video')}
              media={values?.video?.url || getMediaUrl(values?.video)}
              // @ts-ignore
              placeholder={t('pages.video')}
              onDrop={(files) => {
                onDrop(files, (value) => {
                  setFieldValue('video', {
                    url: value,
                  });
                  setFieldValue('videoUpload', files[0]);
                });
              }}
              headerActions={
                values?.video && (
                  <Icon
                    name="trash"
                    className="clickable"
                    color="var(--text-color-secondary)"
                    onClick={() => {
                      setFieldValue('video', null);
                      setFieldValue('videoUpload', null);
                    }}
                  />
                )
              }
              resource="form-header-video"
            />
            {/* @ts-ignore */}
            <Field name="hero" type="switch" color="secondary" label={t('pages.full-screen')} />
            <MediaUploader
              labelElem={
                <div className="flex items-center justify-between mb-2">
                  <Label for="logo" className="mb-0">
                    {t('general.logo')}
                  </Label>
                  {values?.logo && (
                    <button type="button">
                      <Icon
                        name="trash"
                        color="var(--text-color-secondary)"
                        className="clickable"
                        onClick={() => {
                          setFieldValue('logo', null);
                        }}
                      />
                    </button>
                  )}
                </div>
              }
              media={values?.logo && values.logoUpload ? values?.logo : getImageServiceMediaUrl(values?.logo)}
              // @ts-ignore
              placeholder={t('general.image')}
              onDrop={(files) => {
                onDrop(files, (url) => {
                  setFieldValue('logo', url);
                  setFieldValue('logoUpload', files[0]);
                });
              }}
              resource="form-header-image"
            />
            <Field
              dark
              type="select"
              name="logoPosition"
              defaultOptions={POSITION_OPTIONS}
              label={t('pages.logo-position')}
            />
            <Field dark name="logoLink" label={t('pages.logo-url')} />
            <Field dark name="title" label={t('career-page.title')} />
            <Field dark name="subtitle" label={t('career-page.subtitle')} />
            <Field dark name="jobsButtonText" label={t('pages.job-button-text')} />
            {/* @ts-ignore */}
            <Button
              type="submit"
              color="primary"
              className="w-full text-white"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="career-page-block-form-submit"
            >
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default HeaderForm;
