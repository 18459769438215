import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';
import Tippy, { useSingleton } from '@tippyjs/react';
import { hideAll } from 'tippy.js';
import Layout from '../../../../components/layouts/default';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import Button from '../../../../components/Button';
import Icon from '../../../../components/@v2/Icon';
import Card from '../../../../components/@v2/Card';
import Table from '../../../../components/@v2/Table/Table';
import Search from '../../../../components/@v2/Search/Search';
import Paginator from '../../../../components/@v2/Paginator/Paginator';
import UserFilter from './UserFilter';
import i18n from '../../../../i18n';
import { PermissionLevel } from '../../../../constants/User';
import { getImageServiceMediaUrl } from '../../../../helpers/image';
import Avatar from '../../../../components/Avatar';
import UserDeleteModal from './UserDeleteModal';
import { log } from '../../../../helpers/utils';
import { restoreUser } from '../../../../store/api/admin.api';
import { getAdminUserList } from '../../../../store/api/user.api';
import DeleteModelTooltip from '../../../../components/@v2/DeleteModelTooltip/DeleteModelTooltip';

const User = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, data, meta, setMeta, pageSize, reloadData } = useApiRequestContext();
  const [deleteItem, setDeleteItem] = useState(null);
  const [source, target] = useSingleton();

  const parseDate = (date) => {
    if (!date) return '-';
    moment.locale(i18n.language);
    return moment.utc(date).format('YYYY-MM-DD');
  };

  const onRestoreUser = async (item) => {
    try {
      await restoreUser(item);
      reloadData();
    } catch (error) {
      log(error);
    }
  };

  return (
    <Layout
      pretitle={t('general.admin')}
      title={t('Users')}
      rightContent={
        <Button
          color="primary"
          onClick={() => {
            history.push('/admin/users/add');
          }}
          data-testid="add-user-button"
        >
          <div className="flex items-center gap-x-1">
            <Icon name="plus" />
            {t('general.new-user')}
          </div>
        </Button>
      }
      className="users-page"
    >
      <Card className="anim-table-delayed shadow-table !border !border-line-default">
        <Tippy
          trigger="click mouseenter"
          singleton={source}
          theme="light"
          interactive
          maxWidth={200}
          appendTo={document.body}
          placement="bottom"
        />
        <Search
          placeholder={t('general.search-using', { resource: 'name' })}
          resource="name"
          meta={meta}
          setMeta={setMeta}
          filter={<UserFilter />}
        />
        <Table
          isLoaded={!loading}
          activeSort={get(meta, 'sorts') || ''}
          items={get(data, 'items')}
          headers={[
            {
              label: t('general.name'),
              key: 'name',
            },
            {
              label: t('general.email'),
              key: 'email',
            },
            {
              label: t('general.phone-number'),
              key: 'phoneNumber',
              sort: false,
            },
            {
              label: t('users.customer'),
              key: 'clientName',
            },
            {
              label: t('users.customer-accounts'),
              key: 'superAdmin',
            },
            {
              label: t('users.last-login'),
              key: 'lastLogin',
            },
            {
              label: t('users.number-of-logins'),
              key: 'loginCount',
            },
            {
              label: t('users.construction-date-client'),
              key: 'buildDate',
            },
            {
              label: t('users.browser'),
              key: 'browserInfo',
            },
            {
              label: t('general.type'),
              key: 'permissionLevel',
            },
            {
              label: t('general.actions'),
              key: 'actions',
              sticky: true,
              sort: false,
              width: 100,
              headerClassName: 'text-center',
            },
          ]}
          pageSize={pageSize}
          renderBody={(item, index) => {
            const getPermissionType = (permission) => {
              if (permission === '') return '-';
              return PermissionLevel[String(permission)];
            };

            return (
              <tr key={item.id} id={`client-table-row--${index}`}>
                <td>
                  <div className="flex items-center gap-x-3">
                    <Avatar
                      src={getImageServiceMediaUrl({
                        ...item?.avatar,
                        name: item?.avatar?.id,
                      })}
                      size={30}
                    />
                    {item?.firstName} {item?.lastName}
                  </div>
                </td>
                <td>{item?.email}</td>
                <td>{item?.phoneNumber || '-'}</td>
                <td>{item?.clientName || '-'}</td>
                <td>{item?.superAdmin ? <Icon name="hand-spock" type="fal" /> : 1}</td>
                <td>{parseDate(item?.lastLogin)}</td>
                <td>{item?.loginCount || '-'}</td>
                <td>{parseDate(item?.buildDate)}</td>
                <td>{item?.browserInfo || '-'}</td>
                <td>{getPermissionType(item.permissionLevel)}</td>
                <td className="column-sticky">
                  <div className="flex items-center justify-center gap-x-2">
                    <Tippy content={t('general.edit')} singleton={target}>
                      <button
                        type="button"
                        onClick={() => {
                          history.push(`/admin/users/edit/${item.loginId}`);
                        }}
                      >
                        <Icon name="edit" />
                      </button>
                    </Tippy>
                    <DeleteModelTooltip
                      singleton={target}
                      content={
                        <div>
                          {item?.deleted ? t('settings.users.restore-body') : t('settings.users.delete-body')}
                          <p>{item?.fullName}</p>
                        </div>
                      }
                      onDelete={async () => {
                        if (item?.deleted) {
                          await onRestoreUser(item);
                          return;
                        }
                        setDeleteItem(item);
                        hideAll();
                      }}
                      deleteButtonLabel={item?.deleted ? t('general.restore') : t('general.delete')}
                    >
                      <button type="button">
                        {item?.deleted ? <Icon name="archive-restore" /> : <Icon name="trash" />}
                      </button>
                    </DeleteModelTooltip>
                  </div>
                </td>
              </tr>
            );
          }}
          setMeta={setMeta}
        />
        <div className="h-14 flex items-center bg-white">
          <Paginator
            pageCount={data?.totalCount / meta?.pageSize}
            initialPage={meta.page - 1}
            onPageChange={(value) => {
              if (meta.page !== value) {
                setMeta({
                  page: value,
                });
              }
            }}
            disableInitialCallback
          />
        </div>
      </Card>
      <UserDeleteModal
        id={deleteItem?.userId}
        name={`${deleteItem?.firstName} ${deleteItem?.lastName}`}
        loginId={deleteItem?.loginId}
        closeModal={() => {
          setDeleteItem(null);
        }}
      />
    </Layout>
  );
};

const UserWrapper = () => {
  return (
    <ApiRequestProvider
      useObjectFilter
      withCancellation
      api={async (meta, source) => {
        const data = await getAdminUserList(meta, source);
        return data;
      }}
      defaultFilters={[
        {
          value: '',
          key: 'name',
        },
        {
          value: 'false',
          key: 'deleted',
        },
      ]}
    >
      <User />
    </ApiRequestProvider>
  );
};

export default UserWrapper;
