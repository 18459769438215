import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import useElementClickHandlers from '../../../../hooks/useElementClickHandlers';
import Filter from '../../../../components/@v2/Filter/Filter';
import { GenericYesNo } from '../../../../constants/Options';
import SelectFilter from '../../../../components/@v2/Filter/SelectFilter';
import ButtonFilter from '../../../../components/@v2/Button/ButtonFilter';
import useFilterModal from '../../../../hooks/useFilterModal';
import Icon from '../../../../components/@v2/Icon';
import { useSelector } from '../../../../store';
import { selectClientTypes } from '../../../../store/selectors/settings.selector';
import { ClientLanguage } from '../../../../constants/Client';

const ClientFilter = () => {
  const { t } = useTranslation();
  const GenericOptions = GenericYesNo(t);
  const [isFilterShown, setIsFilterShown] = useState(false);
  const { meta, setMeta } = useApiRequestContext();
  const clientTypes = useSelector(selectClientTypes);

  useElementClickHandlers({
    condition: isFilterShown,
    callback: setIsFilterShown,
    elements: [
      '.ts-filter-button',
      '.ts-filter',
      '.rc-dropdown',
      '.searchable-select',
    ],
  });

  useFilterModal({
    show: isFilterShown,
  });

  const changeMeta = (key, value) => {
    const currentFilters = [...meta.filters].filter((item) => item.key !== key);

    setMeta({
      ...meta,
      filters:
        value === '' || !!value
          ? [...currentFilters, { key, value }]
          : currentFilters,
      page: 1,
    });
    setIsFilterShown(false);
  };

  return (
    <>
      <ButtonFilter
        isShown={isFilterShown}
        callback={setIsFilterShown}
        filters={get(meta, 'filters') || []}
      />
      {createPortal(
        <Filter show={isFilterShown}>
          <div className="flex justify-end">
            <button
              data-testid="filter-modal-close-button"
              type="button"
              onClick={() => {
                setIsFilterShown(false);
              }}
            >
              <Icon name="times" />
            </button>
          </div>
          <div className="client-filters">
            <SelectFilter
              searchable
              id="client-filter--type"
              label={t('clients.type')}
              options={clientTypes}
              callback={(selected) => {
                changeMeta('type', selected?.value || null);
              }}
            />
            <SelectFilter
              id="client-filter--active"
              label={t('general.active')}
              options={GenericOptions}
              callback={(selected) => {
                changeMeta('active', selected?.value || null);
              }}
            />
            <SelectFilter
              searchable
              id="client-filter--language"
              label={t('general.language')}
              options={ClientLanguage}
              callback={(selected) => {
                changeMeta('language', selected?.value || null);
              }}
            />
            <SelectFilter
              id="client-filter--force-https"
              label={t('clients.encryption-status')}
              options={GenericOptions}
              callback={(selected) => {
                changeMeta('forceHttps', selected?.value || null);
              }}
            />
            <SelectFilter
              id="client-filter--use-new-page"
              label={t('clients.use-new-public-page')}
              options={GenericOptions}
              callback={(selected) => {
                changeMeta(
                  'clientSettings.useNewPublicPage',
                  selected?.value || null
                );
              }}
            />
          </div>
        </Filter>,
        document.body
      )}
    </>
  );
};

export default ClientFilter;
