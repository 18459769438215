import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Icon from '../@v2/Icon';
import { useApiRequestContext } from '../../context/@v2/ApiRequestContext';
import Assessments from '../../constants/Assessments';

ChartJS.register(ArcElement, Tooltip, Legend);

const References = () => {
  const { t } = useTranslation();
  const [isRefAppOpen, setIsRefAppOpen] = useState(true);
  const apiRequestContext = useApiRequestContext();
  const { data } = apiRequestContext;
  const evaluation =
    data?.evaluationResults?.find((item) => item.type === Assessments.refapp) ||
    {};

  const chartBGColors = useMemo(() => {
    if (evaluation?.assessment?.score <= 40) {
      return ['#fe4848', '#ec8989'];
    }

    if (evaluation?.assessment?.score <= 70) {
      return ['#d6dc19', '#edf17f'];
    }

    return ['#74bc74', '#cfeacf'];
  }, []);

  return (
    <div className="references px-2 pb-4">
      <div
        className={classNames(
          'bg-white border border-gray-50 shadow-xl rounded-lg pt-4 px-4 mb-4 transition-all',
          !isRefAppOpen ? 'pb-0' : 'pb-4'
        )}
      >
        <div
          className={classNames(
            'flex items-center justify-between pb-4 border-b transition-all',
            isRefAppOpen ? 'border-gray-100' : 'border-white'
          )}
        >
          <img src="/img/integrations/refapp.png" alt="" className="h-12" />
          <button type="button" onClick={() => setIsRefAppOpen(!isRefAppOpen)}>
            <Icon name={isRefAppOpen ? 'chevron-up' : 'chevron-down'} />
          </button>
        </div>
        <Collapse isOpen={isRefAppOpen}>
          <div className="refapp-details mt-4">
            <div className="flex justify-between flex-wrap">
              <div className="flex-1 pr-10">
                <ul className="flex">
                  <li className="text-gray-400 min-w-[100px]">
                    {t('general.status')}
                  </li>
                  <li className="text-green-500 font-semibold">
                    {evaluation?.status || '-'}
                  </li>
                </ul>
                <ul className="flex">
                  <li className="text-gray-400 min-w-[100px]">
                    {t('candidates.tests.summary')}
                  </li>
                  <li className="text-primary font-semibold">
                    {evaluation?.summary || '-'}
                  </li>
                </ul>
                <ul className="flex">
                  <li className="text-gray-400 min-w-[100px]">
                    {t('general.references')}
                  </li>
                  <li className="text-primary font-semibold">
                    {evaluation?.assessment?.completed || '-'}/
                    {evaluation?.assessment?.total || '-'}
                  </li>
                </ul>
                {!!evaluation?.attachments?.length && (
                  <ul className="flex">
                    <li className="text-gray-400 min-w-[100px]">
                      {t('candidates.tests.attachments')}
                    </li>
                    <ul className="flex">
                      {(evaluation?.attachments || []).map(
                        ({ url, description }, index) => (
                          <>
                            <li
                              key={url || uniqueId()}
                              className="text-primary font-semibold cursor--pointer active pr-2"
                            >
                              <a
                                href={url || ''}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {description || '-'}
                              </a>
                              {evaluation?.attachments?.length > 1 &&
                                !(
                                  evaluation?.attachments?.length - 1 ===
                                  index
                                ) && <span> | </span>}
                            </li>
                          </>
                        )
                      )}
                      {(!evaluation?.attachments ||
                        evaluation?.attachments?.length === 0) && (
                        <li className="text-primary">{t('general.none')}</li>
                      )}
                    </ul>
                  </ul>
                )}
              </div>
              {!!evaluation?.assessment?.score && (
                <div className="w-[150px] h-[150px] relative">
                  <div
                    className="absolute h-full w-full flex items-center justify-center text-[34px] pt-2.5"
                    style={{
                      color: chartBGColors[0],
                    }}
                  >
                    {(evaluation?.assessment?.score || 0).toFixed(0)}%
                  </div>
                  <Doughnut
                    type="doughnut"
                    data={{
                      datasets: [
                        {
                          data: [
                            evaluation?.assessment?.score,
                            100 - evaluation?.assessment?.score,
                          ],
                          backgroundColor: chartBGColors,
                          borderWidth: 0,
                        },
                      ],
                    }}
                    options={{
                      cutout: 55,
                    }}
                  />
                </div>
              )}
            </div>
            <div className="flex text-secondary pt-3">
              <a
                href={evaluation?.profileUrl?.url || null}
                target="_blank"
                rel="noreferrer"
                className={classNames(
                  'flex text-secondary',
                  evaluation?.profileUrl?.url && 'active'
                )}
              >
                <p>
                  {evaluation?.profileUrl?.description ||
                    t('candidates.tests.no-profile')}
                </p>
                <p className="px-1">|</p>
                <p>Refapp</p>
              </a>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default References;
