import auth, { initialState as authInitialState } from './auth.slice';
import user, { initialState as userInitialState } from './user.slice';
import cart, { initialState as cartInitialState } from './cart.slice';
import client, { initialState as clientInitialState } from './client.slice';
import settings, {
  initialState as settingsInitialState,
} from './settings.slice';
import positions, {
  initialState as positionsInitialState,
} from './positions.slice';

export {
  auth,
  authInitialState,
  user,
  userInitialState,
  client,
  clientInitialState,
  settings,
  settingsInitialState,
  cart,
  cartInitialState,
  positions,
  positionsInitialState,
};
