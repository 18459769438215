/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import { CardBody, Row, Col, FormGroup, Label, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { get, set, omit, compact, isArray, isFunction, kebabCase } from 'lodash';
import classnames from 'classnames';
import uuidv4 from 'uuid/v4';
import { useTranslation } from 'react-i18next';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import Wysiwyg from '../wysiwyg/WysiwygEditor';
import Switch from '../input/Switch';
import DraggableList from '../DraggableList';
import Icon from '../@v2/Icon';
import CustomButton from '../Button';
import ImageDropzone from '../input/ImageDropzone';
import InputLoaderWrapper from '../input/InputLoader';
import {
  StyledContainer,
  StyledForm,
  StyledFormFeedback,
  StyledDatePicker,
  StyledSelectButton,
  StyledButton,
  StyledSelect,
  StyledDraggableListItemCard,
  StyledDraggableListItemCardBody,
  StyledDivider,
} from './Form.styled';
import '../../assets/sass/gmap.sass';
import SelectV2 from '../@v2/Select/Select';

export const ListItem = ({ item, provided, callback, items, ...rest }) => {
  const [winReady, setwinReady] = useState(false);

  useEffect(() => {
    setwinReady(true);
  }, []);

  if (!item.label || !winReady) return null;

  return (
    <StyledDraggableListItemCard
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{ backgroundColor: 'white' }}
    >
      <StyledDraggableListItemCardBody
        id={`draggable-item--${item.sortOrder - 1}`}
        data-testid={`draggable-item--${item.sortOrder - 1}`}
      >
        {item.label}
        <div className="d-flex align-items-center justify-content-between">
          {get(rest, 'deletable') && (
            <Icon
              name="trash"
              className="flex-grow-0 mr-2 position-relative cursor-pointer"
              style={{ top: -1 }}
              onClick={() => {
                if (callback) {
                  callback(items.filter((current) => current.uuid !== item.uuid));
                }
              }}
              id={`draggable-item-delete--${item.sortOrder - 1}`}
              data-testid={`draggable-item-delete--${item.sortOrder - 1}`}
            />
          )}
          <div
            {...provided.dragHandleProps}
            id={`draggable-item-handle--${item.sortOrder - 1}`}
            data-testid={`draggable-item-handle--${item.sortOrder - 1}`}
          >
            <Icon type="fas" name="grip-vertical" className="flex-grow-0" />
          </div>
        </div>
      </StyledDraggableListItemCardBody>
    </StyledDraggableListItemCard>
  );
};

const Form = ({
  defaultValues,
  enableReinitialize,
  facc,
  onSubmit,
  inputs,
  button,
  maxWidth,
  card,
  children,
  hasButton,
  customTable,
  loading,
  resourceName,
  setInputData,
  getErrorMessages,
  ...rest
}) => {
  const { t } = useTranslation();
  const [form, setForm] = useState(defaultValues);
  const [isTouched, setIsTouched] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    if (!enableReinitialize && isInitialized) {
      return;
    }

    if (defaultValues && JSON.stringify(defaultValues) !== JSON.stringify(form)) {
      setForm(defaultValues);
      setIsTouched(false);
      setErrorMessages({});
    }
  }, [defaultValues]);

  const defaultMessages = {
    required: t('form-validator.required'),
    minlength: (length) => t('form-validator.minlength', { length }),
    maxlength: (length) => t('form-validator.maxlength', { length }),
    email: t('form-validator.email'),
    url: t('form-validator.url'),
    checked: t('form-validator.checked'),
    withNumber: t('form-validator.with-number'),
    withUpperCaseLetter: t('form-validator.with-uppercase'),
    withLowerCaseLetter: t('form-validator.with-lowercase'),
    date: t('form-validator.date-should-follow-format'),
  };

  const validateEmail = (email) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!pattern.test(String(email).toLowerCase());
  };

  const validateURL = (str) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
      'i'
    );
    return !!pattern.test(str);
  };

  const validateDate = (dateString) => {
    const pattern = /^\d{4}-\d{2}-\d{2}$/;
    return dateString.match(pattern) != null;
  };

  const setMessage = (key, value, rule, rules) => {
    setErrorMessages((currentState) => ({
      ...currentState,
      [key]:
        rule === 'minlength' || rule === 'maxlength' ? defaultMessages[rule](rules) : value || defaultMessages[rule],
    }));
  };

  const checkForError = (key, rules, messages, value = null) => {
    let hasError = false;
    if (!rules) return true;
    Object.keys(rules).forEach((rule) => {
      if (hasError) return;
      const formValue = get(form, key || '');

      if (rule !== 'showFn') {
        if (rule === 'required' && rules[rule]) {
          if (rules.showFn) {
            if (rules.showFn(form) && !formValue) {
              hasError = true;
            }
          } else if (!formValue) {
            hasError = true;
          }
        }

        if (rules.required || formValue) {
          if (Array.isArray(formValue) && !formValue.length) {
            hasError = true;
          }

          if (rule === 'minlength' && formValue.length < rules[rule]) {
            hasError = true;
          }

          if (rule === 'withNumber' && rules[rule] && !/\d/.test(formValue)) {
            hasError = true;
          }

          if (rule === 'withUpperCaseLetter' && rules[rule] && !/[A-Z]/.test(formValue)) {
            hasError = true;
          }

          if (rule === 'withLowerCaseLetter' && rules[rule] && !/[a-z]/.test(formValue)) {
            hasError = true;
          }

          if (rule === 'maxlength' && formValue.length > rules[rule]) {
            hasError = true;
          }

          if (rule === 'email' && rules[rule] && !validateEmail(formValue)) {
            hasError = true;
          }

          if (rule === 'url' && rules[rule] && !validateURL(formValue)) {
            hasError = true;
          }

          if (rule === 'date' && rules[rule] && !validateDate(formValue)) {
            hasError = true;
          }

          if (rule === 'checked') {
            const nVal = value || formValue;

            if (!nVal) hasError = true;
          }
        }

        if (rule === 'custom' && rules[rule](value || form)) {
          hasError = true;
        }
      }

      if (hasError) setMessage(key, get(messages, rule), rule, rules[rule]);
    });

    if (!hasError && isTouched) {
      setErrorMessages(omit(errorMessages, key));
    }

    return !hasError;
  };

  const checkForm = () => {
    let isFormOk = true;
    setErrorMessages({});

    inputs
      .filter(({ rules }) => {
        if (rules?.showFn === undefined) return true;
        if (rules?.showFn(form)) {
          return true;
        }

        return false;
      })
      .forEach(({ key, rules, errors, inputs }) => {
        if (inputs) {
          inputs.forEach(({ key: k, rules: r, errors: e }) => {
            if (isFormOk) {
              isFormOk = checkForError(k, r, e);
            }

            checkForError(k, r, e);
          });
        } else {
          if (isFormOk) {
            isFormOk = checkForError(key, rules, errors);
          }

          checkForError(key, rules, errors);
        }
      });

    return isFormOk;
  };

  const onKeyUp = (key, rules, errors, updateAlso, value) => {
    if (isTouched) {
      checkForError(key, rules, errors, value);

      if (updateAlso) {
        updateAlso.forEach((item) => {
          const findItem = inputs.find((input) => input.key === item);

          if (findItem) {
            checkForError(item, findItem.rules, findItem.errors);
          }
        });
      }
    }
  };

  const renderHeader = ({ header }) => {
    if (!header) return null;
    return header();
  };

  const renderFooter = ({ footer }) => {
    if (!footer) return null;
    return footer();
  };

  const renderInput = ({
    type: typeInput,
    key,
    rules,
    errors,
    updateAlso,
    label,
    actionButton,
    customComponent,
    name,
    dataTestId,
    ...rest
  }) => {
    const formKey = get(form, key || '');
    const inputId = (name || key || '')
      .replace(/\./g, '-')
      .split(/[\s_\b]|(?=[A-Z])/)
      .join('-')
      .toLowerCase();
    const type = isFunction(typeInput) ? typeInput(form) : typeInput;

    if (type === 'component' && customComponent) {
      return customComponent({ form, setForm });
    }

    if (type === 'selections') {
      const onChange = (e, index) => {
        const selections = [...form[key]].map((item, fIndex) => {
          if (index === fIndex) {
            return {
              ...item,
              name: e.target.value,
            };
          }
          return item;
        });

        setForm({
          ...form,
          [key]: selections,
        });

        if (isTouched) {
          checkForError(key, rules, errors, {
            ...form,
            [formKey]: selections,
          });
        }
      };

      return (
        <div>
          {formKey.map((item, index) => (
            <FormGroup key={item.sortOrder} className="d-flex align-items-center justify-content-between">
              <InputGroup>
                <InputLoaderWrapper condition={loading}>
                  <Input
                    data-testid={`${resourceName}-${kebabCase(key)}`}
                    defaultValue={item.name}
                    onChange={(e) => {
                      if (facc) {
                        onChange(e, index);
                      }
                    }}
                    onBlur={(e) => onChange(e, index)}
                    id={inputId}
                    name={inputId}
                  />
                </InputLoaderWrapper>
                <InputGroupAddon addonType="append">
                  <CustomButton
                    color="danger"
                    onClick={() => {
                      const selections = [...formKey];
                      selections.splice(index, 1);

                      setForm({
                        ...form,
                        [key]: selections,
                      });

                      if (isTouched) {
                        checkForError(key, rules, errors, {
                          ...form,
                          selections,
                        });
                      }
                    }}
                    data-testid={`${resourceName}-${kebabCase(key)}-delete-button`}
                  >
                    <Icon name="trash" className="mr-0" color="white" />
                  </CustomButton>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          ))}
          <CustomButton
            type="primary"
            onClick={() => {
              const defaultValues = rest?.onAddDefaultValues || {};
              setForm({
                ...form,
                ...set(form, key, [
                  ...form[key],
                  {
                    name: '',
                    sortOrder: formKey.length + 1,
                    ...defaultValues,
                  },
                ]),
              });
            }}
            data-testid={`${resourceName}-${kebabCase(key)}-add-button`}
          >
            <div className="flex items-center">
              <Icon name="plus" />
              {t('general.add-selection')}
            </div>
          </CustomButton>
        </div>
      );
    }

    if (type === 'multiselection') {
      const onChange = (e, index) => {
        const selections = [...form[key]].map((item, fIndex) => {
          if (index === fIndex) {
            return {
              ...item,
              name: e.target.value,
            };
          }
          return item;
        });

        setForm({
          ...form,
          [key]: selections,
        });

        if (isTouched) {
          checkForError(key, rules, errors, {
            ...form,
            [formKey]: selections,
          });
        }
      };

      return (
        <div>
          {formKey.map((item, index) => (
            <FormGroup key={item.sortOrder} className="d-flex align-items-center justify-content-between">
              <InputGroup>
                <InputLoaderWrapper condition={loading}>
                  <Input
                    data-testid={`${resourceName}-${kebabCase(key)}`}
                    defaultValue={item.name}
                    onChange={(e) => {
                      if (facc) {
                        onChange(e, index);
                      }
                    }}
                    onBlur={(e) => onChange(e, index)}
                    id={inputId}
                    name={inputId}
                  />
                </InputLoaderWrapper>
                <InputGroupAddon addonType="append">
                  <CustomButton
                    color="danger"
                    onClick={() => {
                      const selections = [...formKey];
                      selections.splice(index, 1);

                      setForm({
                        ...form,
                        [key]: selections,
                      });

                      if (isTouched) {
                        checkForError(key, rules, errors, {
                          ...form,
                          selections,
                        });
                      }
                    }}
                    data-testid={`${resourceName}-${kebabCase(key)}-delete-button`}
                  >
                    <Icon name="trash" className="mr-0" color="white" />
                  </CustomButton>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          ))}
          <CustomButton
            type="primary"
            onClick={() => {
              const defaultValues = rest?.onAddDefaultValues || {};
              setForm({
                ...form,
                ...set(form, key, [
                  ...form[key],
                  {
                    name: '',
                    sortOrder: formKey.length + 1,
                    ...defaultValues,
                  },
                ]),
              });
            }}
            data-testid={`${resourceName}-${kebabCase(key)}-add-button`}
          >
            <div className="flex items-center">
              <Icon name="plus" />
              {t('general.add-selection')}
            </div>
          </CustomButton>
        </div>
      );
    }

    if (type === 'selectv2') {
      let value = formKey;

      if (!isArray(formKey)) {
        value = [formKey];
      }

      return (
        <SelectV2
          clearable={rest.clearable}
          searchable={!get(rest, 'isNotSearchable')}
          multiple={rest.multiple}
          id={dataTestId || rest.resource}
          resource={dataTestId || rest.resource}
          defaultFilters={rest.defaultFilters}
          searchKey={rest.searchKey || 'name'}
          defaultOptions={rest.options}
          api={rest.api ? (meta) => rest.api(meta) : null}
          onSelect={(selected) => {
            if (rest.multiple) {
              setForm({ ...set(form, key, selected) });
            } else {
              setForm({ ...set(form, key, selected[0] || null) });
            }

            if (rest.onSelect) {
              rest.onSelect(selected, setForm, form);
            }
          }}
          value={value}
          keys={rest.keys || ['value', 'label']}
          disabled={rest.disabled}
        />
      );
    }

    if (type === 'avatar') {
      return (
        <InputLoaderWrapper
          condition={loading.data}
          loaderStyles={{
            top: '50%',
            right: '50%',
            transform: 'translate(50%, -50%)',
          }}
        >
          <ImageDropzone
            data-testid={`${resourceName}-${kebabCase(key)}`}
            resource={`${resourceName}-${key}`}
            avatar
            value={formKey}
            onChange={([file]) => setForm({ ...set(form, key, file) })}
            {...rest}
            onDeleteAvatar={() => {
              rest.onDeleteAvatar(form, setForm);
            }}
            inputId={inputId}
            name={inputId}
          />
        </InputLoaderWrapper>
      );
    }

    if (type === 'dropzone') {
      return (
        <InputLoaderWrapper
          condition={loading.data}
          loaderStyles={{
            top: '50%',
            right: '50%',
            transform: 'translate(50%, -50%)',
          }}
        >
          <ImageDropzone
            data-testid={`${resourceName}-${kebabCase(key)}`}
            resource={`${resourceName}-${key}`}
            value={formKey}
            onChange={([file]) => {
              if (rest.onImageChange) {
                rest.onImageChange(form, setForm, file);
              }
              setForm({ ...set(form, key, file) });
            }}
            canvasStyle={{
              width: 'auto',
              cursor: 'pointer',
              ...(rest.style || {}),
            }}
            {...rest}
            inputId={inputId}
            name={inputId}
          />
        </InputLoaderWrapper>
      );
    }

    if (type === 'wysiwyg') {
      return (
        <InputLoaderWrapper
          condition={loading}
          loaderStyles={{
            top: '50%',
            right: '50%',
            transform: 'translate(50%, -50%)',
          }}
        >
          <Wysiwyg
            key={`${resourceName}-${kebabCase(key)}`}
            data-testid={dataTestId || `${resourceName}-${key}`}
            resource={dataTestId || `${resourceName}-${key}`}
            defaultValue={formKey || ''}
            invalid={!!get(errorMessages, key)}
            onChange={(value, delta, source) => {
              if (!rest.acceptValueFromApi && source !== 'user') return;
              const modifiedValue = value;
              const modifiedStringValue = modifiedValue;
              if (rules && rules.onInput && rules.onInput === true) {
                setIsTouched(true);
              }
              const isEmpty = modifiedStringValue === '<p><br></p>' || modifiedStringValue === '';

              setForm({ ...set(form, key, isEmpty ? '' : modifiedStringValue) });

              if (rest.callback) {
                rest.callback({
                  selected: modifiedStringValue,
                  setForm,
                  form,
                });
              }
            }}
            onKeyUp={() => onKeyUp(key, rules, errors, updateAlso)}
            id={inputId || rest.id || key}
            toolbarId={inputId || rest.id || key}
            toolbar={rest.toolbar || 'base'}
            theme={rest.theme || 'snow'}
            {...rest}
            name={inputId}
          />
        </InputLoaderWrapper>
      );
    }

    if (type === 'date') {
      return (
        <InputLoaderWrapper condition={loading}>
          <StyledDatePicker
            data-testid={`${resourceName}--${kebabCase(key)}`}
            date={formKey ? new Date(formKey) : null}
            invalid={!!get(errorMessages, key)}
            onChange={(item) => {
              if (item) {
                setErrorMessages(omit(errorMessages, key));
              }

              setForm({ ...set(form, key, item) });
            }}
            {...rest}
            id={inputId}
            name={inputId}
          />
        </InputLoaderWrapper>
      );
    }

    if (type === 'select') {
      if (actionButton && actionButton.actionKey) {
        const options = rest.options.filter(
          (oItem) => !form[actionButton.actionKey].find((fItem) => fItem.value === oItem.value)
        );

        return (
          <StyledSelectButton>
            <InputLoaderWrapper condition={loading}>
              <StyledSelect
                {...rest}
                options={options}
                withButton
                value={formKey || null}
                onChange={(item) => {
                  if (facc && typeof setInputData === 'function') {
                    setInputData(key, item);
                  }
                  setForm({ ...set(form, key, item) });
                }}
                isDisabled={rest.disabled || false}
                tooltip={false}
                id={inputId}
                name={inputId}
                resource={`${resourceName}-${kebabCase(key)}`}
              />
            </InputLoaderWrapper>
            <StyledButton
              type="button"
              onClick={() => {
                if (!formKey) return;
                const updatedForm = {
                  ...set(form, actionButton.actionKey, [
                    ...get(form, actionButton.actionKey),
                    {
                      ...formKey,
                      uuid: uuidv4(),
                      sortOrder: form[actionButton.actionKey].length + 1,
                    },
                  ]),
                  [key]: null,
                };
                setForm(updatedForm);
                onKeyUp(key, rules, errors, updateAlso);
                if (rest.onSelect) {
                  rest.onSelect(updatedForm, setForm);
                }
              }}
              data-testid={`${resourceName}-${kebabCase(key)}-button`}
            >
              {actionButton.label || 'Add'}
            </StyledButton>
          </StyledSelectButton>
        );
      }

      const getOptions = (key) => {
        if (!rest[key]) return null;

        const arr = isArray(rest[key]) ? rest[key] : rest[key](form);
        if (!rest.excludeFromOptions) return arr;
        const excludedIds = (form[rest.excludeFromOptions] || []).map((item) => item.id || item.clientId);

        return arr.filter((item) => !excludedIds.includes(item.id || item.clientId));
      };

      return (
        <InputLoaderWrapper condition={loading}>
          <StyledSelect
            {...rest}
            className={classnames({ 'input-is-loading': loading })}
            resource={`${resourceName}-${kebabCase(key)}`}
            autoComplete="off"
            value={formKey || null}
            defaultOptions={getOptions('defaultOptions')}
            options={getOptions('options')}
            invalid={!!get(errorMessages, key)}
            onChange={(item) => {
              if (item && (item.value || item.length)) {
                setErrorMessages(omit(errorMessages, key));
              }

              if (rest.onSelect) {
                rest.onSelect(item, setForm, form);
              }

              if (facc && typeof setInputData === 'function') {
                setInputData(key, item);
              }

              setForm({ ...set(form, key, item) });
            }}
            isDisabled={isFunction(rest.disabled) ? rest.disabled(form) : rest.disabled || false}
            tooltip={false}
            id={inputId}
            name={inputId}
          />
        </InputLoaderWrapper>
      );
    }

    if (type === 'draggable') {
      return (
        <DraggableList
          items={formKey}
          component={ListItem}
          callback={(newList) => {
            setForm({
              ...set(
                form,
                key,
                compact(newList).map((item, index) => {
                  return {
                    ...item,
                    sortOrder: index + 1,
                  };
                })
              ),
            });
          }}
          {...rest}
        />
      );
    }

    if (type === 'switch') {
      return (
        <Switch
          data-testid={`${resourceName}-${kebabCase(key)}-switch`}
          checked={formKey || (rest.checkedFn && rest.checkedFn(form)) || false}
          disabled={rest.disabled || (rest.disabledFn && rest.disabledFn(form)) || false}
          label={typeof label === 'string' ? t(label) : label}
          onChange={(item) => {
            if (item) {
              setErrorMessages(omit(errorMessages, key));
            } else {
              onKeyUp(key, rules, errors, updateAlso, item);
            }

            setForm({ ...set(form, key, item) });
            if (rest.onSwitch) {
              rest.onSwitch(form, setForm, item);
            }

            if (facc && typeof setInputData === 'function') {
              setInputData(key, item);
            }
          }}
          id={inputId}
          name={inputId}
        />
      );
    }

    if (type === 'gmapplaces') {
      const setData = ({ placeId, description }) => {
        const item = {};
        const { keys } = rest;

        if (key) item[key] = description || '';
        item.gmapplacesName = description || '';
        if (keys.placeId) item[keys.placeId] = placeId || '';
        if (keys.latitude) item[keys.latitude] = '';
        if (keys.longitude) item[keys.longitude] = '';

        if (placeId) {
          geocodeByPlaceId(placeId)
            .then(([result]) => getLatLng(result))
            .then(({ lat, lng }) => {
              item[keys?.latitude || 'latitude'] = lat;
              item[keys?.longitude || 'longitude'] = lng;

              setForm({ ...form, ...item });
              setErrorMessages(omit(errorMessages, key));
            });
        } else {
          setForm({ ...form, ...item });
        }
      };

      return (
        <InputLoaderWrapper condition={loading}>
          <GooglePlacesAutocomplete
            data-testid={`${resourceName}-${kebabCase(key)}`}
            apiKey="AIzaSyB0Jwl1rPEQnqtrAa_qgZyqka5Ok9nW8lE"
            debounce={200}
            initialValue={form?.gmapplacesName}
            renderInput={(props) => (
              <Input
                autoComplete="off"
                invalid={!!get(errorMessages, key)}
                {...props}
                placeholder=""
                onChange={(e) => {
                  props.onChange(e);

                  setData({
                    description: get(e, 'target.value', ''),
                    placeId: '',
                  });
                }}
                onKeyUp={() => onKeyUp(key, rules, errors, updateAlso)}
                data-testid={`${resourceName}-${kebabCase(key)}-input`}
              />
            )}
            onSelect={({ description, place_id }) => {
              setData({ description, placeId: place_id });
            }}
            id={inputId}
            name={inputId}
          />
        </InputLoaderWrapper>
      );
    }

    if (type === 'mobile') {
      return (
        <InputLoaderWrapper condition={loading}>
          <Input
            data-testid={`${resourceName}-${kebabCase(key)}-input`}
            autoComplete="off"
            value={formKey}
            type="text"
            invalid={!!get(errorMessages, key)}
            onChange={(e) => {
              const { value } = e.target;
              const lastChar = value !== '' ? value.charAt(value.length - 1) : '';
              const beforeLastChar = value.charAt(value.length - 2) || null;

              if (value === ' ' || (beforeLastChar === ' ' && lastChar === ' ')) {
                return;
              }
              if (value === '' || value === '+' || lastChar.match(/[0-9 ]+/)) {
                setForm({ ...set(form, key, value.replace(/\./g, '')) });
              }
            }}
            onBlur={(e) => {
              const { value } = e.target;
              setForm({ ...set(form, key, value.replace(/\./g, '')) });
              onKeyUp(key, rules, errors, updateAlso);
            }}
            onKeyUp={() => onKeyUp(key, rules, errors, updateAlso)}
            {...rest}
            id={inputId}
            name={inputId}
          />
        </InputLoaderWrapper>
      );
    }

    if (type === 'number') {
      return (
        <InputLoaderWrapper condition={loading}>
          <Input
            data-testid={dataTestId || `${resourceName}-${kebabCase(key)}-input`}
            autoComplete="off"
            value={formKey}
            type="text"
            invalid={!!get(errorMessages, key)}
            onChange={(e) => {
              const { value } = e.target;
              if (!value.match(/^[0-9]*\.?[0-9]*$/) && value !== '') return;

              setForm({ ...set(form, key, value) });
            }}
            onBlur={(e) => {
              const { value } = e.target;
              setForm({ ...set(form, key, value) });
              onKeyUp(key, rules, errors, updateAlso);
            }}
            onKeyUp={() => onKeyUp(key, rules, errors, updateAlso)}
            {...rest}
            id={inputId}
            name={inputId}
          />
        </InputLoaderWrapper>
      );
    }

    if (type === 'textarea') {
      return (
        <InputLoaderWrapper condition={loading}>
          <Input
            data-testid={`${resourceName}--${kebabCase(key)}`}
            autoComplete="off"
            type="textarea"
            value={formKey}
            onChange={({ target }) => {
              setForm({ ...set(form, key, target.value) });
            }}
            style={{ height: 150 || rest.textAreaHeight }}
            {...rest}
            id={inputId}
            name={inputId}
          />
        </InputLoaderWrapper>
      );
    }

    return (
      <InputLoaderWrapper condition={loading}>
        <Input
          data-testid={dataTestId || `${resourceName}-${kebabCase(key)}-input`}
          autoComplete="off"
          value={formKey || ''}
          type={type || 'text'}
          onBlur={(e) => {
            const { value } = e.target;
            setForm({ ...set(form, key, value) });
            onKeyUp(key, rules, errors, updateAlso);
          }}
          invalid={!!get(errorMessages, key)}
          onChange={(e) => {
            setForm({ ...set(form, key, e.target.value) });
            if (rest.onInputChange) {
              rest.onInputChange(form, setForm, e.target.value);
            }
          }}
          onKeyUp={() => onKeyUp(key, rules, errors, updateAlso)}
          disabled={rest.isDisabled ? rest.isDisabled(form, setForm) : rest.disabled || false}
          {...rest}
          id={inputId}
          name={inputId}
        />
      </InputLoaderWrapper>
    );
  };

  const shouldRenderButton = () => {
    if (isFunction(hasButton)) return hasButton(form);

    return hasButton;
  };

  const renderForm = useMemo(
    () => (
      <StyledForm
        className={!card ? classnames('form-validator', rest.className) : ''}
        maxwidth={card && (maxWidth || 1000)}
        noValidate
        data-testid={`${resourceName}-${rest.testId || ''}`}
      >
        {children}
        <Row>
          {inputs.map((i) => {
            if (get(i, 'rules.showFn') && !get(i, 'rules.showFn')(form)) {
              return null;
            }
            if (i.type === 'divider') {
              return (
                <StyledDivider xs="12" key={uuidv4()} style={get(i, 'style') || null}>
                  <h3>{i.label}</h3>
                </StyledDivider>
              );
            }

            if (i.hide && i.hide === true) return null;

            if (i.type === 'col') {
              return (
                <Col
                  key={i.key}
                  {...(i.cols || {
                    xs: 12,
                  })}
                  style={{
                    ...(i.style || {}),
                  }}
                  className={i.className || ''}
                >
                  <Row>
                    {i.label && (
                      <Col xs={12}>
                        <Label>{i.label}</Label>
                      </Col>
                    )}
                    {(i.inputs || []).map((x) => {
                      if (get(x, 'rules.showFn') && !get(x, 'rules.showFn')(form)) {
                        return null;
                      }

                      return (
                        <Col key={x.key || uuidv4()} {...(x.cols || {})}>
                          <FormGroup {...(x.formGroup || {})}>
                            {x.header ? renderHeader(x) : null}
                            {x.type !== 'switch' &&
                              x.type !== 'input-with-color-size' &&
                              x.type !== 'avatar' &&
                              x.label && (
                                <Label className={x.labelclass || ''}>
                                  {typeof x.label === 'string' ? t(x.label) : x.label} {get(x, 'rules.required') && '*'}
                                </Label>
                              )}
                            {x.subLabel ? <p className="sub-label">{x.subLabel}</p> : null}
                            {renderInput(x)}
                            {x.footer ? renderFooter(x) : null}
                            {get(errorMessages, x.key) && (
                              <StyledFormFeedback>{get(errorMessages, x.key)}</StyledFormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              );
            }

            return (
              <Col
                key={i.key || uuidv4()}
                {...(i.cols || {
                  xs: 12,
                })}
                style={{
                  ...(i.style || {}),
                }}
              >
                <FormGroup {...(i.formGroup || {})}>
                  {i.header ? renderFooter(i) : null}
                  {i.type !== 'switch' && i.type !== 'input-with-color-size' && i.type !== 'avatar' && i.label && (
                    <Label className={i.labelclass || ''}>
                      {typeof i.label === 'string' ? t(i.label) : i.label} {get(i, 'rules.required') && '*'}
                    </Label>
                  )}
                  {i.subLabel ? <p className="sub-label">{i.subLabel}</p> : null}
                  {renderInput(i)}
                  {i.footer ? i.footer() : null}
                  {get(errorMessages, i.key) && <StyledFormFeedback>{get(errorMessages, i.key)}</StyledFormFeedback>}
                </FormGroup>
              </Col>
            );
          })}
        </Row>
        {customTable && customTable(form, setForm, loading)}
        {shouldRenderButton() && (
          <div className={classnames('d-flex align-items-center', button.parentClass)}>
            {button.cancelCallback && (
              <CustomButton
                color="danger"
                outline
                data-testid={`${resourceName}-cancel-button`}
                className="!rounded-full !px-4"
                onClick={() => button.cancelCallback()}
                disabled={button.loading}
                {...button.cancelProps}
              >
                {button.cancelLabel || t('general.cancel')}
              </CustomButton>
            )}
            <CustomButton
              disabled={!!Object.keys(errorMessages).length || button.loading}
              loading={button.loading}
              loaderStyles={button.loaderStyles || null}
              loaderColor={button.color || null}
              data-testid={`${resourceName}-submit-button`}
              className="!rounded-full px-4"
              color="light"
              outline
              type="submit"
              {...button.submitProps}
              onClick={async (e) => {
                e.preventDefault();
                if (onSubmit && (checkForm() || rest.skipValidation)) {
                  onSubmit(form, setForm);
                }

                setIsTouched(true);
              }}
            >
              {button.label || t('general.submit')}
            </CustomButton>
          </div>
        )}
      </StyledForm>
    ),
    [form, errorMessages, inputs]
  );

  useEffect(() => {
    if (getErrorMessages) {
      getErrorMessages(errorMessages);
    }
  }, [errorMessages]);

  const displayForm = useMemo(() => {
    if (facc) {
      if (card) {
        return children(
          <StyledContainer className={classnames('form-validator', rest.className)} maxwidth={maxWidth || 1000}>
            <CardBody>{renderForm}</CardBody>
          </StyledContainer>,
          form,
          checkForm
        );
      }

      return children(renderForm, form, checkForm);
    }

    if (card) {
      return (
        <StyledContainer className={classnames('form-validator', rest.className)} maxwidth={maxWidth || 1000}>
          <CardBody>{renderForm}</CardBody>
        </StyledContainer>
      );
    }

    return renderForm;
  }, [card, renderForm]);

  return displayForm;
};

Form.defaultProps = {
  defaultValues: {},
  enableReinitialize: true,
  facc: false,
  button: {},
  maxWidth: null,
  card: false,
  inputs: [],
  children: null,
  hasButton: true,
  loading: false,
  getErrorMessages: null,
  resourceName: 'general',
};

export default Form;
