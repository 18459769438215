import React, { useMemo, useState } from 'react';
import { get } from 'lodash';
import Select from '../input/Select';
import { useAPI } from '../../context/api';

const PipelineSelect = ({ steps, selected, selectAll, unchecked, callback, positionId, filters, disabled }) => {
  const [bulkAPI] = useAPI('bulk');
  const [isUpdating, setIsUpdating] = useState(false);
  const selectedIds = useMemo(() => [...selected].filter((item) => item).map((item) => item.id), [selected]);

  const bulkUpdate = async (params = {}) => {
    if (isUpdating) return;
    try {
      setIsUpdating(true);

      const payload = { ...params };

      if (selectAll) {
        let payloadFilters = [];

        Object.keys(filters).forEach((key) => {
          if (filters[key]) {
            payloadFilters = [...payloadFilters, `${key}==${filters[key]}`];
          }
        });

        payload.filters = payloadFilters.join(',');
        if (unchecked.length) {
          payload.unchecked = unchecked.map((item) => item.id);
        }
      } else {
        payload.candidates = selectedIds;
      }

      await bulkAPI.stepUpdate({
        selectAll,
        ...payload,
      });

      if (callback) callback(params);
    } catch (error) {
      // log(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const pipelineStepsOptions = useMemo(
    () =>
      steps.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [steps]
  );

  const defaultValue = useMemo(() => {
    const activePipelineStepId = get(filters, 'pipelineStepId');
    const pipelines = [...selected]
      .filter((item) => item)
      .reduce((acc, item) => {
        if (acc.includes(item.pipelineStepId)) return acc;
        return [...acc, item.pipelineStepId];
      }, []);

    if (selected.length === 1 || pipelines.length === 1 || activePipelineStepId) {
      const foundOption = pipelineStepsOptions.find((item) => {
        if (activePipelineStepId) {
          return item.value === activePipelineStepId;
        }

        return item.value === get(selected[0], 'pipelineStepId');
      });

      if (foundOption) return foundOption;
    }

    return null;
  }, [selected, pipelineStepsOptions]);

  return (
    pipelineStepsOptions.length && (
      <Select
        resource="candidates-pipeline"
        placeholder="--------"
        className="pipeline-selectbox"
        options={pipelineStepsOptions}
        value={defaultValue}
        onChange={(e) => {
          bulkUpdate({
            positionId,
            pipelineStepId: e.value,
          });
        }}
        isDisabled={isUpdating || disabled}
      />
    )
  );
};

PipelineSelect.defaultProps = {
  callback: null,
};

export default PipelineSelect;
