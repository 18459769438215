import { range } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import { log } from '../../helpers/utils';
import CustomButton from '../Button';
import Form from '../form/Form';
import Icon from '../@v2/Icon';
import Layout from '../layouts/scheduler';

const TabContainer = styled.div`
  background: #ececec;
  width: 230px;
  min-height: 400px;
`;

const FormContainer = styled.div`
  padding: 2.3rem 1.5rem;
  width: calc(100% - 230px);
  min-width: 400px;
`;

const SchedulerEdit = ({ id }) => {
  const [values, setValues] = useState({
    name: '',
    place: '',
    time: null,
    unit: null,
    weeks: [
      {
        items: [],
        fromTime: '09:00',
        untilTime: '17:00',
      },
    ],
    future: {
      time: {
        label: 1,
        value: 1,
      },
      unit: {
        label: 'weeks',
        value: 'weeks',
      },
    },
    bookingPage: '',
  });
  const [view, setView] = useState(1);
  const history = useHistory();

  const inputs = useMemo(() => {
    if (view === 1) {
      return [
        {
          key: 'name',
          label: 'What should new events be named?',
          placeholder: 'Event Title',
        },
        {
          key: 'place',
          label: 'Where do events take place?',
          placeholder: 'Location',
        },
        {
          type: 'col',
          label: 'How long is each event?',
          className: 'position-form-holder',
          inputs: [
            {
              key: 'time',
              placeholder: 'Time',
              type: 'selectv2',
              options: range(1, 61).map((item) => ({
                label: item,
                value: item,
              })),
              cols: {
                sm: 12,
                md: 3,
              },
            },
            {
              key: 'unit',
              placeholder: 'Unit',
              type: 'selectv2',
              options: [
                {
                  label: 'mins',
                  value: 'mins',
                },
                {
                  label: 'hours',
                  value: 'hours',
                },
              ],
              cols: {
                sm: 12,
                md: 3,
              },
            },
          ],
        },
      ];
    }
    if (view === 2) {
      return [
        {
          type: 'component',
          label: 'When can events be booked?',
          customComponent: ({ form, setForm }) => (
            <>
              {form.weeks.map((week, index) => (
                <>
                  <div
                    className="d-flex align-items-center mb-3"
                    key={uuidv4()}
                  >
                    <div className="d-flex">
                      {[
                        {
                          label: 'Su',
                          value: 1,
                        },
                        {
                          label: 'M',
                          value: 2,
                        },
                        {
                          label: 'T',
                          value: 3,
                        },
                        {
                          label: 'W',
                          value: 4,
                        },
                        {
                          label: 'Th',
                          value: 5,
                        },
                        {
                          label: 'F',
                          value: 6,
                        },
                        {
                          label: 'Sa',
                          value: 7,
                        },
                      ].map((item) => (
                        <CustomButton
                          key={item.value}
                          outline={!week.items.find((w) => w === item.value)}
                          color={
                            !week.items.find((w) => w === item.value)
                              ? 'secondary'
                              : 'primary'
                          }
                          onClick={() => {
                            setForm({
                              ...form,
                              weeks: [
                                ...form.weeks.slice(0, index),
                                {
                                  ...form.weeks[index],
                                  items: form.weeks[index].items.find(
                                    (w) => w === item.value
                                  )
                                    ? form.weeks[index].items.filter(
                                        (w) => w !== item.value
                                      )
                                    : [...form.weeks[index].items, item.value],
                                },
                                ...form.weeks.slice(index + 1),
                              ],
                            });
                          }}
                        >
                          {item.label}
                        </CustomButton>
                      ))}
                    </div>
                    {form.weeks.length > 1 && (
                      <Icon
                        name="times-circle"
                        className="clickable ml-3"
                        fontSize="20"
                        onClick={() => {
                          setForm({
                            ...form,
                            weeks: [...form.weeks].filter(
                              (item, subIndex) => index !== subIndex
                            ),
                          });
                        }}
                      />
                    )}
                  </div>
                </>
              ))}
              <CustomButton
                color="primary"
                style={{
                  height: 33,
                }}
                onClick={() => {
                  setForm({
                    ...form,
                    weeks: [
                      ...form.weeks,
                      {
                        items: [],
                        fromTime: '09:00',
                        untilTime: '17:00',
                      },
                    ],
                  });
                }}
              >
                Add
              </CustomButton>
            </>
          ),
        },
        {
          type: 'col',
          label: 'How far in the future can events be booked?',
          className: 'position-form-holder',
          inputs: [
            {
              key: 'future.time',
              placeholder: 'Time',
              type: 'selectv2',
              options: range(1, 61).map((item) => ({
                label: item,
                value: item,
              })),
              cols: {
                sm: 12,
                md: 2,
              },
            },
            {
              key: 'future.unit',
              placeholder: 'Unit',
              type: 'selectv2',
              options: [
                {
                  label: 'weeks',
                  value: 'weeks',
                },
                {
                  label: 'months',
                  value: 'months',
                },
              ],
              cols: {
                sm: 12,
                md: 2,
              },
            },
          ],
        },
        {
          type: 'component',
          label: 'Where should we create the booking page?',
          customComponent: ({ form, setForm }) => (
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText
                  style={{
                    background: 'var(--secondary)',
                  }}
                >
                  schedule.weselect.com/
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Booking Page"
                defaultValue={form.bookingPage}
                onBlur={(e) => {
                  setForm({
                    ...form,
                    bookingPage: e.target.value,
                  });
                }}
              />
            </InputGroup>
          ),
        },
      ];
    }

    return [];
  }, [view]);

  return (
    <Form
      facc
      hasButton={false}
      defaultValues={values}
      inputs={inputs}
      resourceName="settings-scheduler-page-edit"
    >
      {(inputs, data) => (
        <>
          <Layout
            title={[
              'pages.scheduling-pages',
              id === 1 ? 'Initial Interview' : 'Technical Interview',
            ]}
            data-testid="settings-scheduler-page-edit-schedule-layout"
            footer={
              <div className="d-flex align-center-center justify-content-end">
                <CustomButton
                  color="primary"
                  outline
                  round
                  onClick={() => {
                    if (view === 1) history.push('/settings/scheduler');
                    else setView(1);
                  }}
                  data-testid="settings-scheduler-page-edit-schedule-back-button"
                >
                  {view === 1 ? 'Cancel' : 'Back'}
                </CustomButton>
                <CustomButton
                  color="success"
                  round
                  data-testid="settings-scheduler-page-edit-schedule-next-button"
                  onClick={() => {
                    setValues(data);
                    if (view === 1) {
                      setView(2);
                    }
                    if (view === 2) log(data);
                  }}
                >
                  {view === 1 ? 'Next' : 'Create'}
                </CustomButton>
              </div>
            }
            bodyClassName="p-0"
          >
            <div className="d-flex justify-content-between flex-wrap">
              <TabContainer>
                <ul>
                  {[
                    {
                      label: 'Event Info',
                      icon: 'pencil',
                    },
                    {
                      label: 'Calendar',
                      icon: 'calendar',
                    },
                    {
                      label: 'Availability',
                      icon: 'clock',
                    },
                    {
                      label: 'Booking Flow',
                      icon: 'project-diagram',
                    },
                    {
                      label: 'Reminders',
                      icon: 'bell',
                    },
                    {
                      label: 'Custom Fields',
                      icon: 'wrench',
                    },
                  ].map((item, index) => {
                    const isActive = view === index + 1;

                    return (
                      <li
                        className="d-flex align-items-center clickable"
                        style={{
                          background: isActive ? 'var(--primary)' : null,
                          color: isActive ? 'white' : 'var(--primary)',
                          lineHeight: '50px',
                          padding: '0 1.5rem',
                        }}
                        key={item.label}
                        onClick={() => {
                          setView(index + 1);
                        }}
                      >
                        <div style={{ width: 30 }}>
                          <Icon name={item.icon} />
                        </div>
                        <span>{item.label}</span>
                      </li>
                    );
                  })}
                </ul>
              </TabContainer>
              <FormContainer hasPreviewContainer={view === 1}>
                {inputs}
              </FormContainer>
            </div>
          </Layout>
        </>
      )}
    </Form>
  );
};

export default SchedulerEdit;
