import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiRequestContext } from '../../../../../context/@v2/ApiRequestContext';
import { defaultImageUpload, getImageServiceMediaUrl } from '../../../../../helpers/image';
import { onBlockUpdate } from '../../../../../store/api/career-page.api';
import Button from '../../../../Button';
import TopImageConfigurator from '../../TopImageConfigurator';
import Divider from '../../../Divider/Divider';
import Field from '../../../Form/Field';
import Icon from '../../../Icon';
import { IFile } from '../../../../../interfaces/IFile';

interface Props {
  item: {
    htmlText: string;
    image: IFile;
    flipped: boolean;
    pageBlockId: number;
    pageId: number;
    type: number;
    sortOrder: number;
    visible: boolean;
    backgroundColorHex: string;
    headerTextColorHex: string;
    subtitleTextColorHex: string;
    textColorHex: string;
    positionId?: number;
    titleFontSizeScale: number;
    subtitleFontSizeScale: number;
    bodyFontSizeScale: number;
  };
}

const TextPictureForm = ({ item }: Props) => {
  const { t } = useTranslation();
  const { data, reloadData } = useApiRequestContext();

  const onSubmit = async (values) => {
    const { imageUpload, image } = values || {};
    const imageObj = image || {};

    const formattedJsonString =
      typeof imageObj === 'object' && imageObj !== null ? JSON.stringify(imageObj.focusPointJsonString) : imageObj;

    const imageValue = imageUpload
      ? defaultImageUpload
      : {
          ...(image || { url: '' }),
          focusPointJsonString: imageObj.focusPointJsonString ? formattedJsonString : null,
        };
    await onBlockUpdate(
      data?.details,
      {
        ...values,
        image: imageValue,
      },
      async () => {
        await reloadData();
        data?.renderSuccessNotification();
      }
    );
  };

  const onDrop = useCallback(
    (acceptedFiles, callback = null) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (callback) callback(reader.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
    [onSubmit]
  );

  const getFocusPoint = useMemo(() => {
    if (!item.image || !item.image.focusPointJsonString) return {};
    return JSON.parse(item.image.focusPointJsonString);
  }, [item.image]);

  return (
    <Formik initialValues={item} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
        return (
          <form onSubmit={handleSubmit} className="w-full">
            <Field
              dark
              id={`wysiwyg-${item?.pageBlockId}`}
              name="htmlText"
              type="wysiwyg"
              toolbar="career-text-image"
              readOnly={isSubmitting}
            />
            <Divider className="!opacity-50" />
            <TopImageConfigurator
              label={t('pages.text-pic')}
              accept="image/png, image/jpeg, image/jpg, image/webp, image/gif"
              image={values.image?.url ? values.image.url : getImageServiceMediaUrl(values.image)}
              activeImageCoords={values.image?.focusPointJsonString || getFocusPoint}
              callback={(coords) => {
                setFieldValue('image', {
                  ...values.image,
                  focusPointJsonString: coords,
                });
              }}
              onDrop={(files) => {
                if (files.length === 0) {
                  setFieldValue('image', null);
                  setFieldValue('imageUpload', null);
                  return;
                }

                onDrop(files, (url) => {
                  setFieldValue('image', {
                    url,
                  });
                  setFieldValue('imageUpload', files[0]);
                });
              }}
              resource="text-picture-image"
              headerActions={
                values?.image && (
                  <Icon
                    name="trash"
                    className="clickable"
                    color="var(--text-color-secondary)"
                    onClick={() => {
                      setFieldValue('image', null);
                      setFieldValue('imageUpload', null);
                    }}
                    data-testid="header-picture-trash-icon"
                  />
                )
              }
            />

            <Field name="flipped" type="switch" label={t('pages.picture-to-the-left')} color="secondary" />
            {/* @ts-ignore */}
            <Button
              type="submit"
              color="primary"
              className="w-full text-white"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="career-page-block-form-submit"
            >
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default TextPictureForm;
