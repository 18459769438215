export const toggleSplash = (state: boolean): void => {
  const splash = document.querySelector('#splash') as HTMLElement;
  if (state) {
    splash.style.opacity = '1';
    splash.style.height = 'auto';
    splash.style.zIndex = '9999';
    return;
  }

  // Timeout to avoid loader flickering and to make sure animation completes in order
  setTimeout(() => {
    splash.style.opacity = '0';
    setTimeout(() => {
      splash.style.height = '0';
      splash.style.zIndex = '-1';
    }, 500);
  }, 3400);
};
