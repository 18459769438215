import React, { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CardHeader, CardBody, Form, Row, Col, FormGroup, Label } from 'reactstrap';
import { kebabCase, sortBy } from 'lodash';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Icon from '../../@v2/Icon';
import PipelineStepForm from '../PipelineStep';
import SelectV2 from '../../@v2/Select/Select';
import { displaySectionLabel } from '../../../helpers/form';
import { addSortOrder } from '../../../helpers/utils';
import { getPipelineTemplate } from '../../../store/api/pipelines.api';

const resourceName = 'settings-position-template-page-pipeline-steps';
export const PipelineStepsForm = ({
  initialValues,
  stepForm,
  addStep,
  setAddStep,
  defaultTemplates,
  testOptions,
  onDragStepEnd,
  onSaveSteps,
  getPipelineSteps,
  openedItems,
  setOpenedItems,
  setFormDefaults,
  clientId,
  adjustPipelinesteps,
  getTemplateOptions,
  defaultSteps
}) => {
  const { t } = useTranslation('');
  const formik = useFormik({
    initialValues: {
      selectedTemplate: initialValues.selectedTemplate || '',
      pipelineSteps: defaultSteps
    },
    enableReinitialize: true,
  });

  const onTemplateChange = useCallback(async (selected) => {
    if (!selected) return;

    const response = await getPipelineTemplate(selected.id);
    if (response && response?.pipelineSteps) {
      const mappedValues = addSortOrder(getTemplateOptions(adjustPipelinesteps(response?.pipelineSteps)));
      setFormDefaults({
        ...initialValues,
        pipelineSteps: mappedValues,
        selectedTemplate: {
          ...selected[0],
          pipelineTemplateConnections: mappedValues,
          pipelineSteps: mappedValues,
        },
      });
    }
  }, []);

  return (
    <Form className="form-validator anim-table-delayed">
      {displaySectionLabel(t('edit-position.recruitment-process-steps'))}
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>{t('edit-position.choose-template-process-steps')} *</Label>
            <SelectV2
              id={`${resourceName}-${kebabCase('selectedTemplate')}`}
              resource={`${resourceName}-${kebabCase('selectedTemplate')}`}
              defaultOptions={defaultTemplates}
              onSelect={(selected) => {
                onTemplateChange(selected[0]);
                formik.setFieldValue('selectedTemplate', selected);
              }}
              value={formik.values.selectedTemplate}
              keys={['id', 'name']}
              searchKey="name"
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="field-blanket" />
      <Row>
        <Col md={8}>
          <FormGroup>
            <Label>{t('edit-position.arrange-process-steps')}</Label>
            <div className="position-form-holder" data-testid={resourceName}>
              <div
                className="position-form-holder form-group"
                style={{
                  position: 'relative',
                  zIndex: 2,
                }}
              >
                <DragDropContext
                  onDragEnd={(val) => {
                    onDragStepEnd(val, initialValues, setFormDefaults);
                  }}
                >
                  <Droppable droppableId="pipeline-steps-droppable">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={{
                          marginBottom: snapshot.isDraggingOver ? 71 : 0,
                        }}
                      >
                        {sortBy(getPipelineSteps(initialValues) || [], 'sortOrder').map((pipeline, index) => (
                          <Draggable key={pipeline.uuid} draggableId={pipeline.uuid} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="card position-form-draggable-card"
                              >
                                <CardHeader
                                  onClick={() => {
                                    setOpenedItems(() => {
                                      if (openedItems.find((item) => item === pipeline.uuid)) {
                                        const test = openedItems.filter((item) => item !== pipeline.uuid);
                                        return test;
                                      }

                                      return [...openedItems, pipeline.uuid];
                                    });
                                  }}
                                  className="d-flex align-items-center justify-content-between"
                                >
                                  <div className="flex items-center gap-x-1">
                                    <Icon type="fas" name="grip-vertical" className="flex-grow-0" />
                                    {pipeline.name}
                                  </div>
                                  <Icon type="fas" name="sort-down" className="flex-grow-0" style={{ marginTop: -5 }} />
                                </CardHeader>
                                {!!openedItems.find((item) => item === pipeline.uuid) && (
                                  <CardBody>
                                    <PipelineStepForm
                                      card={false}
                                      form={pipeline}
                                      pipelineSteps={getPipelineSteps(initialValues)}
                                      testOptions={testOptions}
                                      onSubmit={(val) => {
                                        onSaveSteps({
                                          clientId,
                                          values: val,
                                          form: initialValues,
                                          setForm: setFormDefaults,
                                          setFormDefaults,
                                          setAddStep,
                                        });
                                      }}
                                      isPositionTemplateForm
                                      buttonProps={{
                                        cancelLabel: t('general.delete'),
                                        cancelCallback: () => {
                                          const pipelines = getPipelineSteps(initialValues).filter(
                                            ({ uuid }) => uuid !== pipeline.uuid
                                          );

                                          setFormDefaults({
                                            ...initialValues,
                                            pipelineSteps: pipelines,
                                            selectedTemplate: {
                                              ...initialValues?.selectedTemplate,
                                              pipelineTemplateConnections: pipelines,
                                            },
                                          });
                                        },
                                        cancelProps: {
                                          outline: true,
                                        },
                                      }}
                                    />
                                  </CardBody>
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <div className="form-group">
            {!addStep && (
              <div
                className="question-adder"
                onClick={() => {
                  setAddStep(true);
                }}
              >
                <p className="faded">
                  <Icon name="plus" />
                  {t('general.add-step')}
                </p>
              </div>
            )}
            {addStep && (
              <PipelineStepForm
                form={stepForm}
                testOptions={testOptions}
                pipelineSteps={getPipelineSteps(initialValues)}
                onSubmit={(val) => {
                  onSaveSteps({
                    clientId,
                    values: val,
                    form: initialValues,
                    setForm: setFormDefaults,
                    setFormDefaults,
                    setAddStep,
                  });
                }}
                isPositionTemplateForm
                buttonProps={{
                  cancelLabel: t('general.cancel'),
                  cancelCallback: () => {
                    setAddStep(false);
                  },
                  cancelProps: {
                    outline: true,
                  },
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
};
