import moment from 'moment';
import { useEffect, useState } from 'react';
import { log } from '../helpers/utils';
import { getFacebookToken } from '../store/api/admin.api';

const DEFAULT_FB_DATA = {
  token: null,
  expiry: null,
  userName: null,
  avatarUrl: null,
  isValid: false,
};

const useFbData = () => {
  const [fbData, setFbData] = useState(DEFAULT_FB_DATA);

  const loadFbUserData = async () => {
    try {
      const fbData = await getFacebookToken();
      const isValid = moment().isBefore(new Date(fbData.expiry));
      setFbData({ ...fbData, isValid });
    } catch (error) {
      log(error);
    }
  };

  useEffect(() => {
    loadFbUserData();
  }, []);

  return {
    fbUser: fbData,
    loadFbData: loadFbUserData,
  };
};

export default useFbData;
