import { useEffect, FC } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logout } from '../store/thunks/auth.thunks';
import { selectUser } from '../store/selectors/user.selector';
import {
  useSelector as useSelectorToolkit,
  dispatch as dispatchToolkit,
} from '../store';

export type ContainerProps = {
  authenticated: boolean,
  top?: number,
};

const Container = styled.div<ContainerProps>`
  height: calc(
    100vh - ${(props) => (props.authenticated ? 70 + (props.top || 0) : 0)}px
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

const TitleText = styled.h1`
  font-size: 40px;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TextSpan = styled.span`
  margin-bottom: 50px;
  font-size: 20px;
`;

const StyledButton = styled.button`
  font-size: 16px !important;
  outline: none;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 8px 20px;
  transition: all 0.15s linear;

  &:hover {
    background: var(--primary-color);
    color: white;
  }
`;

export type NotFoundProps = {
  error: Error,
  resetErrorBoundary: (...args: Array<unknown>) => void,
};

const NotFound: FC<NotFoundProps> = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();
  const { authenticated } = useSelectorToolkit(selectUser);
  const history = useHistory();

  useEffect(() => {
    if (!authenticated) {
      dispatchToolkit(logout());
      history.replace('/');
    }
  }, [authenticated]);

  return (
    <Container className="anim-table-delayed" authenticated={authenticated}>
      <TitleText>{t('404.title')}</TitleText>
      <LinkContainer>
        <TextSpan>{error ? error.message : t('404.subtitle')}</TextSpan>
        <StyledButton
          className="btn-rounded d-flex align-items-center"
          onClick={() => {
            if (resetErrorBoundary) {
              resetErrorBoundary();
              return;
            }

            if (history) {
              history.push('/');
            }
          }}
        >
          {t('404.redirection-button')}
        </StyledButton>
      </LinkContainer>
    </Container>
  );
};

export default NotFound;
