import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import Assessments from '../../../constants/Assessments';
import { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';

const MAX_SCORE = 100;
const DEFAULT_SCORE = 0;

const Alva = () => {
  const { t } = useTranslation();
  const apiRequestContext = useApiRequestContext();
  const { data } = apiRequestContext;
  const evaluationResults = data?.evaluationResults || [];
  const config = data?.alvaLabsConfig?.configModel?.assessmentConfig || {};
  const evaluation = evaluationResults?.find(
    (item) => item?.type === Assessments.alvaLabs
  );
  const candidateScore = evaluation?.assessment?.score || 0;
  const score = candidateScore?.toFixed(2) || DEFAULT_SCORE;
  const yellowScoreBoundary = config?.yellowScoreLowerBoundary * 10;
  const greenScoreBoundary = config?.greenScoreLowerBoundary * 10;

  const colors = useMemo(() => {
    if (candidateScore < yellowScoreBoundary) {
      return ['#dc2626', '#fee2e2'];
    }
    if (candidateScore < greenScoreBoundary) {
      return ['#facc15', '#fef9c3'];
    }

    return ['#74bc74', '#cfeacf'];
  }, [candidateScore, yellowScoreBoundary, greenScoreBoundary]);

  return (
    <div className="alva-details mt-4">
      <div className="flex justify-between flex-wrap">
        <div className="flex-1 pr-10">
          <ul className="flex">
            <li className="text-gray-400 min-w-[100px]">
              {t('general.status')}
            </li>
            <li
              className={classNames('font-semibold', {
                'text-green-500': evaluation?.status === 'Completed',
                'text-primary': evaluation?.status !== 'Completed',
              })}
            >
              {evaluation?.status || '-'}
            </li>
          </ul>
          <ul className="flex">
            <li className="text-gray-400 min-w-[100px]">
              {t('candidates.tests.score')}
            </li>
            <li className="text-primary font-semibold">
              {evaluation?.assessment?.completed &&
              evaluation?.assessment?.completed !== false
                ? `${score}%`
                : '-'}
            </li>
          </ul>
          <ul className="flex">
            <li className="text-gray-400 min-w-[100px]">
              {t('candidates.tests.profile-url')}
            </li>
            <li
              className="text-primary font-semibold cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                if (evaluation?.profileUrl) {
                  const newTab = window.open(evaluation?.profileUrl, '_blank');
                  newTab.focus();
                }
              }}
            >
              {evaluation?.profileUrl
                ? evaluation?.profileUrl
                : t('candidates.tests.no-profile')}
            </li>
          </ul>
        </div>
        {!!candidateScore && (
          <div className="w-[150px] h-[150px] relative">
            <div
              className="absolute h-full w-full flex items-center justify-center text-[28px] pt-2.5"
              style={{
                color: colors[0],
              }}
            >
              {evaluation?.assessment?.completed ? `${score}%` : ''}
            </div>
            {!!candidateScore && (
              <Doughnut
                type="doughnut"
                data={{
                  datasets: [
                    {
                      data: [score, MAX_SCORE - score],
                      backgroundColor: colors,
                      borderWidth: 0,
                    },
                  ],
                }}
                options={{
                  cutout: 55,
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Alva;
