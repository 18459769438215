export const TYPES = {
  HEADER: 0,
  FOOTER: 1,
  TEXT: 100,
  TEXT_PICTURE: 104,
  CAROUSEL: 102,
  INFORMATION_ITEMS: 103,
  KEY_FIGURES: 101,
  POSITIONS: 2,
  SUBSCRIPTIONS: 3,
  SUB_ACCOUNT_POSITIONS: 25,
};

export const KEY_FIGURE_TYPE_OPTIONS = (t) => {
  return [
    {
      label: t('career-page.singular'),
      value: 3,
    },
    {
      label: t('career-page.percent'),
      value: 1,
    },
    {
      label: t('career-page.quota'),
      value: 2,
    },
    {
      label: t('career-page.year'),
      value: 4,
    },
  ];
};

export const TEXT_ORIENTATION_OPTIONS = (t) => {
  return [
    {
      label: t('text-orientation.left'),
      value: 0,
    },
    {
      label: t('text-orientation.center'),
      value: 1,
    },
    {
      label: t('text-orientation.right'),
      value: 2,
    },
    {
      label: t('text-orientation.justified'),
      value: 3,
    },
  ];
};

export const AVAILABLE_BLOCKS = (t, havePositionBlock, disableSubAccountPositionBlock) => [
  {
    label: t('pages.text'),
    value: TYPES.TEXT,
    icon: 'align-left',
  },
  {
    label: t('pages.text-picture'),
    value: TYPES.TEXT_PICTURE,
    icon: 'image',
  },
  {
    label: t('pages.carousel'),
    value: TYPES.CAROUSEL,
    icon: 'images',
  },
  {
    label: t('pages.information-items'),
    value: TYPES.INFORMATION_ITEMS,
    icon: 'list-ul',
  },
  {
    label: t('pages.key-figures'),
    value: TYPES.KEY_FIGURES,
    icon: 'list-ol',
  },
  {
    label: t('pages.positions'),
    value: TYPES.POSITIONS,
    icon: 'layer-plus',
    disabled: havePositionBlock,
  },
  {
    label: t('pages.subscriptions'),
    value: TYPES.SUBSCRIPTIONS,
    icon: 'envelope',
  },
  // {
  //   label: t('career-page.instagram'),
  //   value: 5,
  //   icon: 'instagram',
  //   iconType: 'fab',
  //   disabled: haveInstagramBlock,
  // },
  {
    label: t('pages.positions-sub-accounts'),
    value: TYPES.SUB_ACCOUNT_POSITIONS,
    icon: 'layer-group',
    hidden: disableSubAccountPositionBlock,
  },
];

export const defaultBlock = {
  pageBlockId: 0,
  keyFigures: [],
  informationItems: [],
  title: '',
  backgroundColorHex: '#ffffff',
  headerTextColorHex: '#000000',
  subtitleTextColorHex: '#000000',
  textColorHex: '#000000',
  titleFontSizeScale: 1,
  subtitleFontSizeScale: 1,
  bodyFontSizeScale: 1,
};
