import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { log } from '../../helpers/utils';
import CustomButton from '../Button';

const FormSubmit = ({ canBeSavedAsDraft, saveAsDraft, btnDisabled }) => {
  const { t } = useTranslation();
  const [isSaveAsDraftLoading, setIsSaveAsDraftLoading] = useState(false);
  const saveAsDraftFn = async () => {
    if (isSaveAsDraftLoading) return;

    try {
      setIsSaveAsDraftLoading(true);
      await saveAsDraft();
    } catch (error) {
      log(error);
    } finally {
      setIsSaveAsDraftLoading(false);
    }
  };

  return (
    <CustomButton
      outline
      color="primary"
      type="submit"
      onClick={saveAsDraftFn}
      loading={isSaveAsDraftLoading}
      disabled={btnDisabled}
      id="draft-button"
      data-testid="position-draft-button"
      className="btn-rounded"
    >
      {canBeSavedAsDraft
        ? t('general.save-as-draft')
        : t('general.save-changes')}
    </CustomButton>
  );
};

export default FormSubmit;
