import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import '../assets/sass/avatar.sass';

const DefaultAvatarSrc = `${global?.appConfig?.public_url}/images/avatars/default-avatar.svg`;

const Avatar = ({ src, size, square, ...rest }) => {
  const [bgImage, setBgImage] = useState(DefaultAvatarSrc);

  useEffect(() => {
    setBgImage(src);
    const img = new Image();
    img.src = src;
    img.onerror = () => {
      setBgImage(DefaultAvatarSrc);
    };
  }, [src]);

  return (
    <div
      data-testid="avatar"
      {...rest}
      className={classnames('custom-avatar bg-cover', rest.className)}
      style={{
        ...rest.style,
        height: size,
        minWidth: size,
        width: size,
        backgroundImage: `url(${bgImage})`,
        borderRadius: square ? 3 : null,
      }}
    />
  );
};

Avatar.defaultProps = {
  size: 20,
  className: '',
  style: {},
  square: false,
};

export default Avatar;
