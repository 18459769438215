/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { logInfo, logError } from '../services/app-insights.service';

const onFullFilled = (config) => {
  const token = localStorage.getItem('ts-api-token') || '';
  const customMessage = {
    url: config.url,
    data: config.data,
    headers: config.headers,
  };

  logInfo(`request sent: ${config.url}`, customMessage);
  config.headers.Authorization = `Bearer ${token}`;

  return config;
};

const onRejected = (error) => {
  const axiosError =
    error && error.response ? error.response.data : 'Unknown error';
  logError(axiosError);
  return Promise.reject(error);
};

export { onFullFilled, onRejected };
