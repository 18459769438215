import { createSlice } from '@reduxjs/toolkit';
import { get, sortBy } from 'lodash';
import { ActiveCandidatePosition } from '../models/positions.models';

export interface InitialState {
  loading: boolean;
  data: any[];
  activePositionForCandidates: ActiveCandidatePosition | {};
  isAlreadySet: boolean;
  form: any;
}

export const initialState: InitialState = {
  loading: true,
  data: [],
  activePositionForCandidates: {},
  isAlreadySet: false,
  form: {},
};

const slice = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    setPositions: (state, { payload }: { payload: ActiveCandidatePosition[] }) => {
      return {
        ...state,
        loading: false,
        data: payload || [],
        activePositionForCandidates: state.isAlreadySet
          ? state.activePositionForCandidates
          : sortBy(
              payload.filter((item) => !item.hidden && !item.archived),
              'name'
            )[0],
        isAlreadySet: true,
      };
    },
    setPositionForm: (state, { payload }) => {
      return {
        ...state,
        form: payload,
      };
    },
    setCandidatePosition: (state, { payload }) => {
      return {
        ...state,
        activePositionForCandidates: {
          ...state.activePositionForCandidates,
          ...payload,
          pipelines: get(state, 'activePositionForCandidates.pipelines') || [],
        },
      };
    },
    setCandidatePositionPipelineSteps: (state, { payload }) => {
      return {
        ...state,
        activePositionForCandidates: {
          ...state.activePositionForCandidates,
          ...payload,
        },
      };
    },
    resetAlreadySet: (state) => {
      return {
        ...state,
        data: [],
        isAlreadySet: false,
      };
    },
  },
});

export const {
  setPositions,
  setPositionForm,
  setCandidatePosition,
  setCandidatePositionPipelineSteps,
  resetAlreadySet,
} = slice.actions;
export default slice.reducer;
