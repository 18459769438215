import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import classnames from 'classnames';
import { devices } from '../../constants/Devices';
import { BottomMenuItems } from '../../constants/Position';
import Button from '../Button';
import Icon from '../@v2/Icon';

const PositionFooter = styled.div`
  z-index: 20;

  @media ${devices.sm} {
    justify-content: center !important;
  }

  @media ${devices.lg} {
    justify-content: space-between !important;
  }
`;

const FooterButton = styled(Button)`
  @media ${devices.lg} {
    order: 0;
    margin-right: 0;
  }
`;

const FooterOL = styled.ol`
  @media ${devices.sm} {
    order: 1;
    width: 100%;
    margin-top: 10px !important;
  }

  @media ${devices.lg} {
    order: 0;
    width: auto;
    margin-bottom: 0 !important;
    flex: 1;
  }
`;

const PositionTemplateFooter = ({
  currentView,
  goToView,
  onSubmit,
  canGoNext,
  data,
  isSending,
}) => {
  const { t } = useTranslation();
  const nextBtnText = useMemo(() => {
    if (currentView === 3) {
      return t('general.save');
    }

    return t('positions.next-step');
  }, [currentView, t]);

  return (
    <PositionFooter
      className="form-position-footer sticky-bottom-menu d-flex align-items-center justify-content-between align-items-center flex-wrap"
      data-testid="settings-position-template-page-position-footer"
    >
      <div className="d-flex align-items-center">
        <FooterButton
          outline
          type="button"
          onClick={() => {
            if (currentView > 1) goToView(currentView - 1);
          }}
          disabled={currentView === 1}
          className="form-position-back-button"
          data-testid="settings-position-template-page-back-button"
        >
          {t('positions.back')}
        </FooterButton>
      </div>
      <FooterOL className="d-flex align-items-center justify-content-center">
        {BottomMenuItems.filter(({ component }) => component !== 'Promote').map(
          (item, index) => {
            const viewIndex = index + 1;
            return (
              <li
                key={item.label}
                onClick={() => {
                  // validate();
                  if (!canGoNext(data)) return;
                  goToView(viewIndex);
                }}
                data-testid={`settings-position-template-page-actionPosition--${item.label}`}
              >
                <div
                  className={classnames(
                    'd-flex align-items-center justify-content-center',
                    {
                      active: viewIndex === currentView,
                    }
                  )}
                >
                  {viewIndex < currentView ? (
                    <svg
                      version="1.1"
                      id="c1"
                      className="checkmark"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        className="checkmark--circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        className="checkmark--check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  ) : (
                    <Icon name="circle" color="#d2ddec" />
                  )}
                  <span className="number">{viewIndex}. </span>
                  <span className="label">{t(item.label)} </span>
                </div>
              </li>
            );
          }
        )}
      </FooterOL>
      <Button
        color="success"
        type="button"
        onClick={() => {
          // validate();
          if (canGoNext(data)) {
            if (currentView < 3) {
              goToView(currentView + 1);
            } else {
              onSubmit(data);
            }
          }
        }}
        disabled={!canGoNext(data)}
        className="form-position-next-button ml-2"
        data-testid="settings-position-template-page-next-button"
        loading={isSending}
      >
        {nextBtnText}
      </Button>
    </PositionFooter>
  );
};

export default PositionTemplateFooter;
