import { useEffect, useCallback, useState } from 'react';
import { ListGroup, ListGroupItem, Card, CardHeader, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, sortBy } from 'lodash';
import { setCandidatePosition, resetAlreadySet } from '../store/slices/positions.slice';
import Select from '../components/@v2/Select/Select';
import Icon from '../components/Icon';
import Loader from '../components/Loader';
import { log } from '../helpers/utils';
import events from '../helpers/events';
import Placeholder from '../components/Placeholder';
import { setClientsOptions as setClients } from '../store/thunks/settings.thunks';
import { selectUser } from '../store/selectors/user.selector';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../store';
import { selectClient } from '../store/selectors/client.selector';
import { selectOptions } from '../store/selectors/settings.selector';
import { getImageServiceMediaUrl } from '../helpers/image';
import { useSelectClient } from '../hooks/useSelectClient';
import '../assets/sass/select-client.sass';
import { getClientAccessDetails } from '../store/api/user.api';

const SelectClientComponent = () => {
  const { t } = useTranslation();
  const cart = useSelectorToolkit(({ cart }) => cart);
  const activeClient = useSelectorToolkit(selectClient);
  const setCandidatesPositionAction = useCallback(
    (item) => {
      dispatchToolkit(resetAlreadySet());
      dispatchToolkit(setCandidatePosition(item));
    },
    [dispatchToolkit]
  );

  useEffect(() => {
    setCandidatesPositionAction({});
  }, []);

  const history = useHistory();
  const currentUser = useSelectorToolkit(selectUser);
  const options = useSelectorToolkit(selectOptions);
  const clients = options.clients || [];
  const { selectClientById, loading, selectClientId } = useSelectClient();

  useEffect(() => {
    events.$on('get-client', (id) => {
      selectClientById(id);
    });

    return () => {
      events.$off('get-client');
    };
  }, []);

  const [isFetchingClients, setIsFetchingClients] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await getClientAccessDetails();
        const clients = response.clients.map((item) => {
          const foundClientIndex = response.lastVisited.findIndex((current) => current === item.id);

          item.order = foundClientIndex !== -1 ? foundClientIndex + 1 : null;
          item.value = item.id;
          item.label = item.name;

          return item;
        });

        dispatchToolkit(setClients({ clients, lastVisited: response.lastVisited }));
      } catch (error) {
        log(error);
      } finally {
        setIsFetchingClients(false);
      }
    })();
  }, []);

  const setClient = async (id) => {
    if (id === get(activeClient, 'id')) {
      history.push('/positions');
      return;
    }

    if (cart.length) {
      history.push({
        search: 'modal=cart-confirmation',
        state: {
          type: 'select-client',
          clientId: id,
        },
      });
    }
    
    localStorage.removeItem('positionFilters');
    selectClientById(id);
  };

  const renderClientList = () => {
    if (
      sortBy(
        clients.filter((item) => item.order !== null),
        'order'
      ).length === 0
    ) {
      return <p className="empty-list text-black">{t('client-access.empty-latest-visited')}</p>;
    }

    return sortBy(
      clients.filter((item) => item.order !== null),
      'order'
    )
      .slice(0, 5)
      .map(({ id, name, logo }) => {
        const parsedLogo = getImageServiceMediaUrl(logo);

        return (
          <ListGroupItem
            key={id}
            action
            tag="button"
            className="list-group-item d-flex align-items-center justify-content-between"
            onClick={() => {
              selectClientById(id);
            }}
            disabled={loading}
          >
            <div className="flex items-center gap-x-2 flex-grow">
              <div
                className="logo"
                style={{
                  width: 40,
                  background: `#fff url(${parsedLogo}) no-repeat center`,
                  backgroundSize: 'contain',
                }}
              >
                {!parsedLogo && <Icon name="question-circle" size="2x" />}
              </div>
              <p className="text-black mb-0">{name}</p>
            </div>
            {loading && selectClientId === id && (
              <Loader
                type="primary"
                size={40}
                parentStyles={{
                  position: 'relative',
                  height: 40,
                  width: 40,
                  marginRight: -10,
                }}
              />
            )}
          </ListGroupItem>
        );
      });
  };

  const renderPlaceholder = () => (
    <div style={{ flex: 1 }}>
      <Placeholder height={25} />
    </div>
  );

  const renderListLoaders = () =>
    [1, 2, 3, 4, 5].map((item) => (
      <ListGroupItem key={item} className="d-flex">
        <Placeholder square height={25} style={{ marginRight: 10 }} />
        {renderPlaceholder()}
      </ListGroupItem>
    ));

  return (
    <div className="select-client">
      <div className="container d-block d-sm-flex">
        <div className="welcome-note">
          <h1>
            {t('client-access.welcome')}
            {currentUser.firstName ? `, ${currentUser.firstName}!` : '!'}
          </h1>
          <p>{t('client-access.description')}</p>
        </div>
        <Card className="search-accounts">
          <CardHeader className="text-black">
            <div className="flex items-center">
              <Icon name="search" className="w-[2.5px]" color="var(--text-color-secondary)" />
              <span className="ml-2">{t('general.all')}</span>
            </div>
          </CardHeader>
          <CardBody>
            <Select
              searchable
              multiple={false}
              id="client-selectbox"
              className="client-selectbox"
              defaultOptions={clients || []}
              disabled={loading}
              onSelect={([selected]) => {
                setCandidatesPositionAction({});
                setClient(selected.id);
              }}
              keys={['id', 'name']}
              defaultOptionText={t('general.select-client')}
              searchKey="label"
            />
          </CardBody>
        </Card>
        <Card className="last-visited">
          <CardHeader className="text-black">
            <div className="flex items-center">
              <Icon name="clock" className="w-[2.5rem]" color="var(--text-color-secondary)" />
              <span className="ml-2">{t('client-access.latest-visited')}</span>
            </div>
          </CardHeader>
          <CardBody style={{ padding: 0 }}>
            <ListGroup flush>{isFetchingClients ? renderListLoaders() : renderClientList()}</ListGroup>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default SelectClientComponent;
