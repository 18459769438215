import React, { useMemo, useState } from 'react';
import qs from 'query-string';
import { sortBy, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { useApiRequestContext } from '../../context/@v2/ApiRequestContext';
import { dispatch as dispatchToolkit } from '../../store';
import { setCandidatesWithKey, setFiltersWithKey } from '../../store/slices/settings.slice';

import PipelineSelect from './PipelineSelect';
import CandidateAction from './CandidateAction';
import Icon from '../@v2/Icon';
import Modal from '../@v2/Modal';
import CandidateRejectModal from '../../pages/@v2/candidates/CandidateRejectModal';
import CandidateDeleteModal from '../../pages/@v2/candidates/CandidateDeleteModal';

const CandidateButtons = ({
  candidatesFilters,
  candidatesSettings,
  calendarSettings,
  position,
  user,
  getCandidateDetails,
  activeCandidate
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { id: activeIdInUrl } = useParams();
  const { meta, setMeta, data, reloadData } = useApiRequestContext();
  const [isRejectedButton, setIsRejectedButton] = useState(false);

  const { search } = location;
  const queryParams = useMemo(() => qs.parse(search), [search]);

  const isTabEqualsToRejected = useMemo(() => {
    const { status } = candidatesFilters;
    return status === 'Rejected';
  }, [candidatesFilters]);

  const isAllSelected = candidatesSettings?.selectAll;
  const isMultipleSelected = candidatesSettings?.selected?.length >= 2 || isAllSelected;
  const isRejected = candidatesSettings?.selected?.[0]?.rejected;

  return (
    <div className="flex flex-wrap items-start buttons candidate-card-view-buttons gap-y-2 mb-2">
      {candidatesFilters?.positionId &&
          (isMultipleSelected || candidatesFilters?.pipelineStepId || candidatesSettings?.selected?.length === 1) && (
            <>
              <div id="candidate-buttons-pipeline-select">
                <PipelineSelect
                  steps={sortBy(position.pipelines, 'sortOrder')}
                  filters={candidatesFilters}
                  selected={candidatesSettings?.selected || []}
                  selectAll={!!candidatesSettings?.selectAll}
                  unchecked={candidatesSettings?.unchecked || []}
                  positionId={candidatesFilters?.positionId}
                  callback={async (params) => {
                if (
                  candidatesSettings?.selectAll ||
                  candidatesSettings?.selected?.length === data?.totalCount
                ) {
                  await dispatchToolkit(
                    setFiltersWithKey({
                      key: 'candidates',
                      payload: {
                        ...candidatesFilters,
                        pipelineStepId: params.pipelineStepId,
                      },
                    })
                  );

                  const filteredMeta = meta?.filters?.filter((item) => item.key !== 'pipelineStepId');

                  setMeta({
                    ...meta,
                    filters: [
                      ...filteredMeta,
                      {
                        key: 'pipelineStepId',
                        value: params.pipelineStepId,
                      },
                    ],
                  });
                  return;
                }

                await dispatchToolkit(
                  setCandidatesWithKey({
                    key: 'selectAll',
                    payload: false,
                  })
                );

                await dispatchToolkit(
                  setCandidatesWithKey({
                    key: 'selected',
                    payload: [],
                  })
                );

                reloadData();
              }}
                  disabled={isAllSelected && !get(candidatesFilters, 'pipelineStepId')}
                />
              </div>
              {(isAllSelected && !get(candidatesFilters, 'pipelineStepId')) && (
                <UncontrolledTooltip
                  placement="top"
                  target="candidate-buttons-pipeline-select"
                  style={{ textAlign: 'left', padding: 10 }}
                  className="pipeline-steps-popover"
                >
                  {t('candidates.cannot-move-to-pipeline-step-tooltip')}
                </UncontrolledTooltip>
              )}
            </>
          )}

      {(isMultipleSelected ||
          (calendarSettings?.accessToken &&
            (calendarSettings?.status === 'Running' || calendarSettings?.status === 'Connected') &&
            !candidatesSettings?.selected.find((item) => item.rejected) &&
            !isTabEqualsToRejected)
          ) && (
            <Button
              data-testid="candidates-schedule-button"
              type="button"
              className="flex items-center"
              color="primary"
              outline
              onClick={() => {
                history.push({
                  search: qs.stringify({
                    ...queryParams,
                    modal: 'schedule-interview',
                  }),
                });
              }}
            >
              <Icon name="calendar" className="relative -top-px" />
              {t('general.schedule')}
            </Button>
          )}

      {(isMultipleSelected || candidatesFilters?.pipelineStepId) && (
      <Modal
        content={({ setIsOpen }) => (
          <CandidateRejectModal
            rejected={isRejectedButton}
            setIsOpen={setIsOpen}
            callback={() => {
                  getCandidateDetails(activeIdInUrl);
                  dispatchToolkit(
                    setCandidatesWithKey({
                      key: 'selected',
                      payload: [data?.candidates.find((item) => item.id === parseInt(activeIdInUrl, 10))],
                    })
                  );
                }}
            selected={activeIdInUrl || null}
          />
            )}
      >
        {({ setIsOpen }) => (
          <>
            {(isMultipleSelected || candidatesFilters?.pipelineStepId) && (
              <>
                {(isMultipleSelected || (!isMultipleSelected && isRejected)) && (
                  <Button
                    data-testid="candidates-unreject-button"
                    type="button"
                    className="flex items-center"
                    color="outline-light-2"
                    onClick={() => {
                              setIsRejectedButton(true);
                              setIsOpen(true);
                            }}
                  >
                    <Icon
                      name="thumbs-up"
                      className="relative -top-px mr-2"
                    />
                    {t('candidates.unreject')}
                  </Button>
                )}
                {(isMultipleSelected || (!isMultipleSelected && !isRejected)) && (
                <Button
                  data-testid="candidates-reject-button"
                  type="button"
                  className="flex items-center"
                  color="outline-light-2"
                  onClick={() => {
                            setIsRejectedButton(false);
                            setIsOpen(true);
                          }}
                >
                  <Icon
                    name="thumbs-down"
                    className="relative -top-px mr-2"
                  />
                  {t('candidates.reject')}
                </Button>
                )}
              </>
            )}
          </>
            )}
      </Modal>
        )}
      {
          !isMultipleSelected &&
          (user.superAdmin || user.permissionLevel === 2) &&
          candidatesSettings?.selected?.[0]?.rejected &&
          (
            <Modal
              content={({ setIsOpen }) => (
                <CandidateDeleteModal
                  id={candidatesSettings?.selected?.[0]?.id}
                  name={candidatesSettings?.selected?.[0]?.name}
                  setIsOpen={setIsOpen}
                />
              )}
            >
              {({ setIsOpen }) => (
                <Button
                  data-testid="candidates-delete-button"
                  type="button"
                  color="outline-secondary"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <Icon name="trash" className="relative -top-px" />
                  {t('general.delete')}
                </Button>
              )}
            </Modal>
          )
        }
      <CandidateAction
        all={candidatesSettings.selectAll}
        selected={candidatesSettings.selected}
        activeCandidate={activeCandidate}
      />
    </div>
  );
};

export default CandidateButtons;
