import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

export const selectSelf = (state: RootState) => state;

export const selectLoading = createSelector(selectSelf, (state) => {
  return state.user.loading;
});

export const selectError = createSelector(selectSelf, (state) => {
  return state.user.error;
});

export const selectUser = createSelector(selectSelf, (state) => {
  return state.user.userData;
});
