export const getOrderStatus = (key) => {
  switch (key) {
    case 0:
      return 'received';

    case 1:
      return 'processing';

    case 2:
      return 'success';

    case 3:
      return 'failed';

    default:
      return 'received';
  }
};
