import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListGroup } from 'reactstrap';
import Category from './Category';
import { selectRecruiters } from '../../../store/selectors/settings.selector';
import { useSelector as useSelectorToolkit } from '../../../store';
import { getAllEmailTemplates } from '../../../store/api/template.api';

const Categorizing = ({ state, setState, addedQuestions, parsedQuestions }) => {
  const { t } = useTranslation();
  const recruiters = useSelectorToolkit(selectRecruiters);
  const [editorTemplates, setEditorTemplates] = useState(null);

  const getAllTemplates = async () => {
    try {
      const { items } = await getAllEmailTemplates();
      if (Array.isArray(items)) {
        setEditorTemplates(
          items
            .map((item) => ({
              value: item.text,
              label: item.name,
              id: item.id || item.templateId,
              item,
            }))
        );
      }
    } catch (e) {
      // console.error(e);
    }
  };

  useEffect(() => {
    getAllTemplates();
  }, []);

  return (
    <div className="position-form">
      <h3 className="line-behind">{t('general.categorizing')}</h3>
      <p className="font-weight-bold">
        {t('edit-position.automatic-categorization')}
      </p>
      <p>{t('edit-position.categorizing-description1')}</p>
      <p>{t('edit-position.categorizing-description2')}</p>
      <ListGroup className="category-list">
        {[
          {
            type: 'A',
            color: '#00d97e',
            backgroundColor: '#ccf7e5',
            stateKey: 'categorySettingsAJsonString',
          },
          {
            type: 'B',
            color: '#bcb80b',
            backgroundColor: '#fffb95',
            stateKey: 'categorySettingsBJsonString',
          },
          {
            type: 'C',
            color: '#e63757',
            backgroundColor: '#fad7dd',
            stateKey: 'categorySettingsCJsonString',
          },
          {
            type: 'Other',
            backgroundColor: 'transparent',
            stateKey: 'categorySettingsOthersJsonString',
          },
        ].map((item) => (
          <Category
            key={item.stateKey}
            state={state}
            questions={addedQuestions}
            setState={setState}
            parsedQuestions={parsedQuestions}
            editorTemplates={editorTemplates}
            users={recruiters}
            {...item}
          />
        ))}
      </ListGroup>
    </div>
  );
};

export default Categorizing;
