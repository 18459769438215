/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import uuidv4 from 'uuid/v4';
import Tippy from '@tippyjs/react';
import { remove } from 'piexif-ts';
import { getImageServiceMediaUrl } from '../../helpers/image';
import Icon from '../@v2/Icon';

const key = uuidv4();
const ImageDropzone = ({
  value,
  onChange,
  className,
  keepAspectRatio = false,
  rounded,
  avatar,
  inputId,
  divId,
  resource,
  canvasStyle,
  onDeleteAvatar,
}) => {
  const { t } = useTranslation();
  const [image, setImage] = useState('');

  const getImage = () => {
    if (!value) {
      setImage(null);
      return;
    }

    if (typeof value === 'string') {
      setImage(value);
      return;
    }

    if (value && (value.uploadedDate || value.pictureUploaded)) {
      setImage(getImageServiceMediaUrl(value));
      return;
    }

    if (value && value.size) {
      const reader = new FileReader();
      reader.onload = () => {
        const removed = remove(reader.result);
        setImage(removed);
      };
      reader.readAsDataURL(value);
    }
  };

  useEffect(() => {
    getImage();
  }, [value]);

  return (
    <div data-testid={resource} className="relative">
      <Dropzone
        key={key}
        maxFiles={1}
        multiple={false}
        onDrop={(files) => {
          onChange(files);
        }}
        accept="image/png, image/jpeg, image/jpg, image/webp"
        className={className}
        addRemoveLinks
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={{ width: avatar ? 150 : null }} className="relative">
            <input {...getInputProps()} id={inputId ? `input-${inputId}` : null} data-testid={`${resource}-input`} />
            <div
              id={divId}
              className="relative mb-0 d-flex align-items-center justify-content-center text-center image-upload-container cursor-pointer border-dashed border-gray-badge bg-dropzone-bg"
              data-testid={`${resource}-canvas`}
              style={{
                marginTop: 1,
                backgroundImage: image ? `url(${image})` : null,
                backgroundSize: image && keepAspectRatio ? 'contain' : 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: rounded || avatar ? 150 : 200,
                width: rounded || avatar ? 150 : null,
                borderWidth: '1px',
                borderStyle: 'dashed',
                borderRadius: rounded || avatar ? 1000 : 6,
                ...canvasStyle,
              }}
            />
            {inputId && (
              <UncontrolledTooltip
                placement="auto"
                target={`input-${inputId}`}
                style={{ textAlign: 'left', padding: 10 }}
              >
                {t('general.image')}
              </UncontrolledTooltip>
            )}
            {(image === '' || !image) && (
              <div className="absolute top-0 right-0 h-full w-full">
                <div className="flex items-center flex-col h-full justify-center">
                  <Icon id="upload" className="p-2" color="var(--text-color-secondary)" name="upload" size={30} />
                  <p className="text-color-secondary">{t('general.upload')}</p>
                </div>
              </div>
            )}
          </div>
        )}
      </Dropzone>
      {avatar && !!value && !!onDeleteAvatar && (
        <div className="flex avatar-image absolute top-2 left-[115px]" style={{ width: 150 }}>
          <Tippy content={<span>{t('general.delete')}</span>} theme="light">
            <button
              type="button"
              className="z-10 group p-2 rounded-full overflow-hidden shadow-sm hover:bg-error hoverborder-error transition border"
              onClick={() => {
                if (onDeleteAvatar) {
                  setImage(null);
                  onDeleteAvatar();
                }
              }}
            >
              <Icon name="trash" className="transition stroke-error group-hover:stroke-white !bg-transparent" />
            </button>
          </Tippy>
        </div>
      )}
    </div>
  );
};

ImageDropzone.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  keepAspectRatio: PropTypes.bool,
  className: PropTypes.string,
  rounded: PropTypes.bool,
  avatar: PropTypes.string,
  inputId: PropTypes.string,
  divId: PropTypes.string,
  resource: PropTypes.string,
  canvasStyle: PropTypes.object,
};

ImageDropzone.defaultProps = {
  value: '',
  keepAspectRatio: false,
  className: '',
  rounded: false,
  avatar: '',
  inputId: null,
  divId: null,
  resource: 'general',
  canvasStyle: {},
};

export default ImageDropzone;
