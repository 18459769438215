import React from 'react';
import { BasicConfig } from 'react-awesome-query-builder';
import i18n from '../../i18n';
import {
  MultiSelectWidget, SelectWidget, SelectValueWidget, TextWidget
} from './Widgets';

export const defaultFields = [
  {
    value: 'name',
    label: 'position.visible-position-name',
  },
  {
    value: 'company',
    label: 'general.client',
  },
  {
    value: 'location',
    label: 'general.location',
  }
];

const textFields = defaultFields
  .map(({ value, label }) => ({
    [value]: {
      label: i18n.t(label),
      type: 'text',
      excludeOperators: ['proximity', 'not_like', 'starts_with', 'ends_with', 'is_empty', 'is_not_empty'],
      valueSources: ['value'],
      mainWidgetProps: {
        valuePlaceholder: `Enter ${i18n.t(label)}`,
      },
    }
  }))
  .reduce((acc, curr) => {
    const key = Object.keys(curr)[0];
    acc[key] = curr[key];
    return acc;
  }, {});

export const config = {
  ...BasicConfig,
  settings: {
    ...BasicConfig.settings,
    showNot: false,
    renderField: (props) => <SelectWidget {...props} />,
    renderOperator: (props) => <SelectWidget {...props} />,
    renderFunc: (props) => <SelectWidget {...props} />,
  },
  operators: {
    ...BasicConfig.operators,
    select_any_in: {
      ...BasicConfig.operators.select_any_in,
      label: i18n.t('general.contain'),
    },
    multiselect_equals: {
      ...BasicConfig.operators.multiselect_equals,
      label: i18n.t('general.contain'),
      // jsonLogic: (field, op, value, opDef, operatorOptions) => {
      //   const fieldArr = [field];
      //   value.forEach((item) => fieldArr.push(item));
      //   return { some: fieldArr };
      // },
    },
  },
  widgets: {
    ...BasicConfig.widgets,
    text: {
      ...BasicConfig.widgets.text,
      factory: (props) => <TextWidget {...props} />,
    },
    multiselect: {
      ...BasicConfig.widgets.multiselect,
      factory: (props) => <MultiSelectWidget {...props} />,
    },
    select: {
      ...BasicConfig.widgets.select,
      factory: (props) => <SelectValueWidget {...props} />,
    },
  },
  fields: {
    ...BasicConfig.fields,
    ...textFields
  }
};
