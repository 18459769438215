import { AxiosResponse } from 'axios';
import UpdateFBAdCampaignTemplateCommand from '@ts/apicontracts/fbAdCampaignTemplates/edit/update-fb-ad-campaign-template-command';
import FBAdRuleResponseModel from '@ts/apicontracts/fbAdCampaignTemplates/fb-ad-rule-response-model';
import ResponseListItem from '@ts/apicontracts/fbAdCampaignTemplates/search/response-list-item';

import { api } from '../../helpers/api-v2';

const resource = 'fbrules';

export const findFbRulesById = async (id: string, options = {}) => {
  try {
    const response = await api.get(`${resource}/${id}`, options);
    return response as AxiosResponse<FBAdRuleResponseModel>;
  } catch (error) {
    throw error;
  }
};

export const createOrUpdateFbRules = async (values: UpdateFBAdCampaignTemplateCommand) => {
  try {
    const response = await api[values?.id ? 'put' : 'post'](resource, values);
    return response as AxiosResponse<FBAdRuleResponseModel>;
  } catch (error) {
    throw error;
  }
};

export const deleteFbRule = async (id: string) => {
  try {
    await api.delete(`${resource}/${id}`);
  } catch (error) {
    throw error;
  }
};

export const getAdRulesList = async (params = {}, options = {}) => {
  try {
    const response = await api.post(`${resource}/list`, params, options);

    return response as AxiosResponse<ResponseListItem[]>;
  } catch (error) {
    throw error;
  }
};
