import React from 'react';
import { Quill } from 'react-quill';
import { useTranslation } from 'react-i18next';
import SelectListToolbar from './SelectListToolbar';
import Icon from '../../@v2/Icon';

Quill.register('modules/custom', true);

const EmailTemplateToolbar = ({ listItems }) => {
  const { t } = useTranslation();
  const testId = 'emailTemplateToolbar';

  return (
    <SelectListToolbar
      listItems={listItems}
      data-testid={testId}
      className="template"
      tooltipText={t('html-editor.load-template')}
      label={<Icon name="file-alt" id="before-select" />}
    />
  );
};

export default EmailTemplateToolbar;
