import { api } from '../helpers/api';

const resource = 'calendar';

export default {
  getDetails: () => api.get(`/${resource}`),
  authenticateToNylas: (code) => api.post(`${resource}/auth`, { code }),
  pages: () => api.get(`${resource}/pages`),
  interview: (form) => api.post(`${resource}/interview`, form),
};
