import React, { useState, useMemo, useEffect } from 'react';
import { ListGroupItem, Collapse, Card, CardBody, Col, Label, FormGroup, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { get, filter, has, isArray } from 'lodash';
import Switch from '../../input/Switch';
import Icon from '../../@v2/Icon';
import Select from '../../input/Select';
import WysiwygEditor from '../../wysiwyg/WysiwygEditor';
import ScreeningQuestionsType from '../../../constants/ScreeningQuestionsType';
import { selectOptions } from '../../../store/selectors/settings.selector';
import { useSelector as useSelectorToolkit } from '../../../store';
import SelectV2 from '../../@v2/Select/Select';

const CustomBadge = ({ color, label, textColor }) => (
  <div className="custom-badge" style={{ backgroundColor: color }}>
    <span style={{ color: textColor || 'var(--text-color)' }}>{label || ''} </span>
  </div>
);

CustomBadge.defaultProps = {
  textColor: 'var(--text-color)',
};

const defaultAvatarURL = '/images/avatars/default-avatar.svg';

const Category = ({
  type,
  color,
  backgroundColor,
  stateKey,
  formKey,
  state,
  setState,
  questions,
  parsedQuestions,
  editorTemplates,
  setFormDefaults,
}) => {
  const { t } = useTranslation();
  const toolbarId = useMemo(() => `toolbar-${type}`, []);
  const testId = 'settings-position-template-page-category';
  const settings = useMemo(() => (state[formKey] ? state[formKey] : {}), [stateKey, state]);
  const [notifiedUsers, setNotifiedUsers] = useState(settings.notifiedUsers || []);
  const [currentCategoryValues, setCurrentCategoryValues] = useState({});
  const [emailText, setEmailText] = useState(settings.candidateEmailText || '');

  const options = useSelectorToolkit(selectOptions);
  const recruiters = options?.recruiters;

  const users = useMemo(
    () =>
      recruiters.map((item) => ({
        ...item,
        id: 0,
        userId: item.id,
        value: item.id,
        label: item.name,
        imageUrl: item.avatarUrl || defaultAvatarURL,
        positionId: state.positionId,
      })),
    [recruiters, state.positionId]
  );

  useEffect(() => {
    if (isArray(get(settings, 'notifiedUsers')) && get(settings, 'notifiedUsers').length) {
      setNotifiedUsers(
        get(settings, 'notifiedUsers').map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [get(settings, 'notifiedUsers')]);

  const defaultAnswer = {
    value: null,
    label: t('edit-position.does-not-affect-categorization'),
  };

  const emailDelayOptions = [
    {
      value: 0,
      label: t('general.send-directly'),
    },
    {
      value: 4,
      label: t('general.send-four-hours-after-application'),
    },
    {
      value: 24,
      label: t('general.send-24-hours-after-application'),
    },
  ];

  const setCategoryForm = (values) => {
    setFormDefaults({ ...state, ...values });
  };

  const onTemplateChange = (item) => {
    if (item) {
      const updatedSettings = {
        [formKey]: {
          ...settings,
          candidateEmailSubject: item?.subject,
          candidateEmailText: item?.text || emailText,
        },
      };
      setState({ ...state, ...updatedSettings });
      setCategoryForm(updatedSettings);
      setEmailText(item.text || '');
    }
  };

  const getOptions = (question) => {
    if (question.type === ScreeningQuestionsType.CHECKBOX_TYPE) {
      return [
        { label: t('general.yes-must-be-selected'), name: '1', value: 1 },
        { label: t('general.no-must-be-selected'), name: '0', value: 0 },
      ];
    }

    if (question.type === ScreeningQuestionsType.YEARS_TYPE || question.type === ScreeningQuestionsType.SLIDER_TYPE) {
      const key = question.sliderMax - question.sliderMin;
      let arr = [question.sliderMin];

      if (key >= 0) {
        arr = [
          ...arr,
          ...Array.from(Array(question.sliderMax - question.sliderMin).keys()).map(
            (item) => question.sliderMin + item + 1
          ),
        ];
      }

      return arr
        .filter((item) => item !== 0)
        .map((obj) => ({
          label: t(
            question.type === ScreeningQuestionsType.YEARS_TYPE ? 'general.atleast-x-years' : 'general.atleast-x',
            { x: obj }
          ),
          name: obj,
          value: obj,
        }));
    }

    if (question.type === ScreeningQuestionsType.SELECTIONS_TYPE) {
      return [
        ...question.selections.map((item) => {
          item.label = `${item.name} ${t('general.must-be-chosen')}`;
          return item;
        }),
      ];
    }

    return [];
  };

  return (
    <div className="position-form-holder form-template-with-variable" data-testid={testId}>
      <ListGroupItem>
        <div className="custom-badge-wrapper">
          <CustomBadge color={backgroundColor} textColor={color} label={type !== 'Other' ? type : ''} />
          <span>{type !== 'Other' ? t('general.candidates') : t('general.other')} </span>
        </div>
        <Switch
          checked={settings.active}
          label={
            settings.active ? t('position.category.currently-active') : t('position.category.currently-not-active')
          }
          onChange={(choice) => {
            setCategoryForm({
              [formKey]: {
                ...settings,
                active: choice,
              },
            });
          }}
          disabled={false}
        />
        <div
          className="icon-wrapper"
          onClick={() => {
            setCategoryForm({
              [formKey]: {
                ...settings,
                isOpen: !settings.isOpen,
              },
            });
          }}
        >
          <Icon name="sort-down" size="1x" type="fas" />
        </div>
      </ListGroupItem>
      <Collapse isOpen={settings.isOpen}>
        <Card>
          <CardBody>
            <Col md="12" sm="12" xs="12">
              {type !== 'Other' &&
                questions.map((question, index) => (
                  <div key={question.screeningCriteriaId} className="category-questions-list">
                    <Label for={`${question.screeningCriteriaId}`}>
                      {index + 1}. {question.label}
                    </Label>
                    {question.type &&
                      question.type !== ScreeningQuestionsType.TEXT_TYPE &&
                      question.type !== ScreeningQuestionsType.MULTI_SELECT && (
                        <Select
                          value={
                            question[`requirement${type}`] !== null
                              ? getOptions(question).find(
                                  (item) => item.screeningCriteriaSelectionId === question[`requirement${type}`] || item.value === question[`requirement${type}`]
                                )
                              : defaultAnswer
                          }
                          options={[defaultAnswer, ...getOptions(question)]}
                          onChange={(selected) => {
                            const cloneQuestions = filter([...parsedQuestions], (questionObj) =>
                              has(questionObj, 'screeningCriteriaId')
                            );
                            cloneQuestions.map((item) => {
                              if (item.screeningCriteriaId === question.screeningCriteriaId) {
                                item[`requirement${type}`] = selected.value || null;
                              }
                              return item;
                            });
                            const updatedFormData = {
                              ...state,
                              criterias: cloneQuestions,
                              questionsJsonString: JSON.stringify(cloneQuestions),
                            };
                            setCategoryForm(updatedFormData);
                          }}
                        />
                      )}
                  </div>
                ))}
              <FormGroup>
                <Label for="send-email-to-the-candidate">{t('edit-position.send-email-to-the-candidate')}</Label>
                <Switch
                  checked={settings.sendMailToCandidate}
                  onChange={(choice) => {
                    setCategoryForm({
                      [formKey]: {
                        ...settings,
                        sendMailToCandidate: choice,
                      },
                    });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="reject-candidate-automatically">{t('edit-position.reject-candidate-automatically')}</Label>
                <Switch
                  checked={settings.autoRejectCandidate}
                  onChange={(choice) => {
                    setCategoryForm({
                      [formKey]: {
                        ...settings,
                        autoRejectCandidate: choice,
                      },
                    });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="subject">{t('general.subject')}</Label>
                <Input
                  id={`category-subject-${type}`}
                  name={`category-subject-${type}`}
                  data-testid={`position-template-category-${type}-subject-input`}
                  value={settings.candidateEmailSubject}
                  onChange={(e) => {
                    setCurrentCategoryValues({
                      ...(currentCategoryValues || {}),
                      candidateEmailSubject: e.target.value,
                    });
                    setCategoryForm({
                      [formKey]: {
                        ...settings,
                        candidateEmailSubject: e.target.value,
                      },
                    });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="message">{t('general.message')}</Label>
                <WysiwygEditor
                  key={`message-category-${toolbarId}`}
                  id={toolbarId}
                  toolbarId={toolbarId}
                  toolbar="candidate-email-toolbar"
                  placeholder={t('candidates.email.write-email')}
                  options={{ listItems: editorTemplates }}
                  defaultValue={emailText}
                  onEditorStateChange={(content, source) => {
                    if (source === 'silent') return;
                    const updatedSettings = {
                      [formKey]: {
                        ...settings,
                        candidateEmailText: content,
                      },
                    };
                    setCategoryForm(updatedSettings);
                    setEmailText(content);
                  }}
                  onTemplateChange={onTemplateChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email-delay">{t('general.email-delay')}</Label>
                <Select
                  value={emailDelayOptions.find((item) => item.value === settings.candidateEmailDelayHours)}
                  onChange={(selected) => {
                    setCategoryForm({
                      [formKey]: {
                        ...settings,
                        candidateEmailDelayHours: selected.value,
                      },
                    });
                  }}
                  options={emailDelayOptions}
                />
              </FormGroup>
              <FormGroup>
                <Label for="informed-users">{t('general.informed-users')}</Label>
                <SelectV2
                  multiple
                  clearable
                  searchable={false}
                  defaultOptions={users}
                  onSelect={(selected) => {
                    const selectedUsers = selected || [];
                    const updatedSettings = {
                      [formKey]: {
                        ...settings,
                        notifiedUserIdJsonString: JSON.stringify(
                          selectedUsers.map((item) => item.userId || item.value || item.id)
                        ),
                        notifiedUsers: selectedUsers.map((item) => ({
                          ...item,
                          id: item.value,
                          name: item.label,
                        })),
                      },
                    };
                    setNotifiedUsers(selectedUsers);
                    setCategoryForm(updatedSettings);
                  }}
                  value={notifiedUsers}
                  keys={['value', 'label']}
                />
              </FormGroup>
            </Col>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default Category;
