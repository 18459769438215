import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import { Button, Form, FormGroup } from 'reactstrap';
import Modal, { ModalHeader, ModalBody } from '../Modal';
import { deleteFacebookToken } from '../../../store/api/admin.api';
import CustomButton from '../../Button';
import events from '../../../helpers/events';
import { log } from '../../../helpers/utils';

const DeleteFacebookToken = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { search, pathname } = location;
  const [isSending, setIsSending] = useState(false);
  const queryParams = qs.parse(search);

  const closeModal = () => {
    history.push({
      ...location,
      search: qs.stringify({
        currentPage: queryParams.currentPage || 1,
      }),
    });
  };

  const onDelete = async (e) => {
    e.preventDefault();
    if (isSending) return;

    try {
      setIsSending(true);
      await deleteFacebookToken();
      events.$emit('facebook--token-deleted');
      closeModal();
    } catch (error) {
      log(error);
    }
  };

  return (
    <Modal width={600}>
      <ModalHeader>{t('facebook.delete-token')}</ModalHeader>
      <ModalBody>
        <Form onSubmit={onDelete}>
          <FormGroup>
            <p className="text-center">{t('modal.container.are-you-sure-delete')}?</p>
          </FormGroup>
          <div className="d-flex align-items-center justify-content-center">
            <CustomButton type="submit" color="danger" style={{ marginRight: 5 }} loading={isSending}>
              {t('general.delete')}
            </CustomButton>
            <Button
              type="button"
              color="light"
              onClick={() => {
                const queryParamsCopy = { ...queryParams };
                Reflect.deleteProperty(queryParamsCopy, 'modal');

                history.push({
                  location: pathname,
                  search: qs.stringify(queryParamsCopy),
                });
              }}
            >
              {t('general.cancel')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default DeleteFacebookToken;
