import React from 'react';
import AligmentToolbar from './AligmentToolbar';
import BaseTextToolbar from './BaseTextToolbar';
import TextSizeToolbar from './TextSizeToolbar';

const CareerTextImageToolbar = () => {
  return (
    <>
      <AligmentToolbar />
      <BaseTextToolbar withVideo />
      <TextSizeToolbar />
    </>
  );
};

export default React.memo(CareerTextImageToolbar);
