import Tippy from '@tippyjs/react';
import React from 'react';

const elipsisStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const PIXELS_PER_CHAR = 8;

const PositionLocation = ({ id, location, width = 300 }) => {
  return location.length > width / PIXELS_PER_CHAR ? (
    <Tippy content={location} placement="top" key={`position-location-tooltip-${id}`} theme="light">
      <p style={{ maxWidth: width, ...elipsisStyle }}>{location}</p>
    </Tippy>
  ) : (
    <p style={{ maxWidth: width, ...elipsisStyle }}>{location}</p>
  );
};

export default PositionLocation;
