import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { get, forEach } from 'lodash';
import { removeItem } from '../store/slices/cart.slice';
import { dispatch as dispatchToolkit } from '../store';
import CartTable from '../components/@v2/Cart';
import Layout from '../components/layouts/default';
import Icon from '../components/@v2/Icon';
import OrderList from '../components/OrderList';
import Button from '../components/Button';

const checkout = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [checkedItems, setCheckedItems] = useState([]);
  const [isValidating, setIsValidating] = useState(false);

  return (
    <Layout
      testId="checkout"
      title={
        <Button
          className="btn-rounded d-flex align-items-center"
          color="primary"
          data-testid="checkout-back-button"
          onClick={() => {
            history.push({
              pathname: '/cart',
              state: {
                filterPersist: true,
                sorts: get(location, 'state.sorts'),
              },
            });
          }}
        >
          <Icon name="long-arrow-left" className="mr-3 clickable" />
          {t('general.view-cart')}
        </Button>
      }
      rightContent={
        <Button
          className="btn-rounded"
          color="danger"
          data-testid="checkout-delete-item"
          onClick={() => {
            if (checkedItems.length) {
              forEach(checkedItems, (item) => {
                dispatchToolkit(removeItem(item));
              });
              setCheckedItems([]);
            }
          }}
          id="checkout-delete-item"
          disabled={!checkedItems.length}
        >
          <div className="flex items-center">
            <Icon name="trash" />
            {t('general.delete')}
          </div>
        </Button>
      }
    >
      <div className="d-flex justify-content-between align-items-start flex-wrap anim-table-delayed">
        <div style={{ marginRight: 20, flex: 1 }} className="overflow-x-auto">
          <CartTable setCheckedItems={setCheckedItems} isValidating={isValidating} checkout />
        </div>
        <div className="anim-table-delayed">
          <OrderList setIsValidating={setIsValidating} disableBackArrow />
        </div>
      </div>
    </Layout>
  );
};

export default checkout;
