import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import { dispatch } from '../../../store';
import { onPublishMainPage } from '../../../store/api/career-page.api';
import { setIsAddingBlock } from '../../../store/slices/settings.slice';

const CareerPageFooter = () => {
  const { t } = useTranslation();
  const { data, reloadData } = useApiRequestContext();

  const onAddBlock = () => {
    dispatch(setIsAddingBlock(true));
  };

  const onPublish = async () => {
    if (data?.details) {
      await onPublishMainPage(data?.details);
      await reloadData();
      data?.renderSuccessNotification();
    }
  };

  return (
    <div className="flex min-h-[51px] border-t border-career-page-footer-border text-[13px]">
      <button
        type="button"
        className="text-white w-1/2 h-full flex items-center bg-primary justify-center gap-x-2"
        onClick={onAddBlock}
        data-testid="career-page-add-block"
      >
        {t('pages.add-block')}
      </button>
      <button
        type="button"
        className="text-white w-1/2 h-full flex items-center justify-center gap-x-2 border-l bg-success border-career-page-footer-border"
        onClick={onPublish}
        data-testis="career-page-publish"
      >
        {t('general.publish')}
      </button>
    </div>
  );
};

export default CareerPageFooter;
