import React from 'react';
import TextAligmentToolbar from './TextAligmentToolbar';
import TextSizeToolbar from './TextSizeToolbar';
import EmailTemplateToolbarToolbar from './EmailTemplateToolbar';

const PositionToolbar = ({ listItems }) => {
  return (
    <>
      <TextAligmentToolbar withVideo />
      <TextSizeToolbar />
      <EmailTemplateToolbarToolbar listItems={listItems} className="position" />
    </>
  );
};

export default React.memo(PositionToolbar);
