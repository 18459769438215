import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { NotificationManager } from 'react-notifications';
import * as Yup from 'yup';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import { dispatch as dispatchToolkit, useSelector as useSelectorToolkit } from '../../../../store';
import { selectOptions } from '../../../../store/selectors/settings.selector';
import { getClientsOptions } from '../../../../store/thunks/settings.thunks';
import { useAPI } from '../../../../context/api';
import Layout from '../../../../components/layouts/default';
import Button from '../../../../components/Button';
import Field from '../../../../components/@v2/Form/Field';
import ADVERTISEMENT_TYPE_OPTIONS from '../../../../constants/AdvertisementType';
import { createOrUpdateProduct } from '../../../../store/api/products.api';
import { getAdminClients } from '../../../../store/api/client.api';

const FACEBOOK = 200;
const FINN = 900;

const ProductForm = () => {
  const { t } = useTranslation();
  const { loading, data } = useApiRequestContext();
  const { type } = useParams();
  const history = useHistory();
  const isEditing = !!data?.id;

  useEffect(() => {
    dispatchToolkit(getClientsOptions());
  }, []);

  const ProductFormSchema = Yup.object().shape({
    title: Yup.string().required(t('form-validator.required')),
    description: Yup.string().required(t('form-validator.required')),
    icons: Yup.string().required(t('form-validator.required')),
    lifetimeBudget: Yup.number().when('marketings', (marketings) => {
      if (marketings?.find((item) => item.value === FACEBOOK)) {
        return Yup.number()
          .min(1, t('form-validator.min-number', { number: 1 }))
          .required(t('form-validator.required'));
      }

      return Yup.number().optional();
    }),
    externalLink: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        { message: t('form-validator.url'), excludeEmptyString: true }
      )
      .nullable(),
    price: type === 'add' ? Yup.number().required(t('form-validator.required')) : Yup.number().optional(),
    sortOrder: Yup.number().integer().nullable(),
  });

  const onSubmit = async (values) => {
    try {
      await createOrUpdateProduct(values);
      history.push('/admin/products');
    } catch (error) {
      const errorMsg = () => {
        if (error.response) {
          return error.response.data?.error?.details?.[0] || error.response.data?.error?.message;
        }
        return error;
      };
      NotificationManager.error(errorMsg() || '');
      throw new Error(error);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Layout
      pretitle={t('general.admin')}
      title={isEditing ? t('admin.products.edit-product') : t('admin.products.add-product')}
      rightContent={
        isEditing && (
          <Button
            color="primary"
            onClick={() => {
              history.push(`/admin/products/translation/${data?.id}`);
            }}
          >
            {t('admin.products.translation.add-translation')}
          </Button>
        )
      }
    >
      <Formik initialValues={data} validationSchema={ProductFormSchema} onSubmit={onSubmit}>
        {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="anim-table-delayed max-w-4xl w-full shadow-sm p-4 bg-white rounded-lg"
            >
              <Field name="title" label={t('general.title')} testId="products-title-input" required />
              <Field
                toolbar="base"
                type="wysiwyg"
                name="description"
                label={t('general.description')}
                testId="products-description"
                required
              />
              <Field
                type="wysiwyg"
                toolbar="product-add"
                name="icons"
                label={t('general.icon')}
                testId="products-icons"
                required
              />
              <div className="flex items-center gap-x-4">
                <Field
                  type="text"
                  name="specialOffer"
                  label={t('admin.products.special-offer')}
                  testId="products-special-offer-input"
                />
                <Field
                  type="number"
                  name="expiration"
                  label={t('admin.products.expiration')}
                  testId="products-expiration-input"
                />
              </div>
              <Field
                type="number"
                name="price"
                label={t('general.price')}
                disabled={type === 'edit'}
                required={type === 'add'}
                testId="products-price-input"
              />
              <Field
                type="number"
                name="sortOrder"
                label={t('admin.products.sort-order')}
                testId="products-sort-order"
              />
              <Field
                multiple
                type="select"
                name="allowedClientIds"
                label={t('admin.products.allowed-clients')}
                testId="products-allowed-clients"
                keys={['id', 'name']}
                api={getAdminClients}
                searchKey="search"
                useObjectFilters
                disabled={values?.excludedClientIds?.length}
              />
              <Field
                multiple
                type="select"
                name="excludedClientIds"
                label={t('admin.products.excluded-clients')}
                testId="products-excluded-clients"
                keys={['id', 'name']}
                api={getAdminClients}
                searchKey="search"
                useObjectFilters
                disabled={values?.allowedClientIds?.length}
              />
              <Field
                multiple
                defaultOptions={ADVERTISEMENT_TYPE_OPTIONS}
                type="select"
                name="marketings"
                label={t('admin.products.marketings')}
                callback={(values) => {
                  if (values?.find((item) => item.value === FINN)) {
                    setFieldValue('availableForPosition', true);
                  }
                }}
                testId="products-marketings"
              />
              <Field name="externalLink" label={t('admin.shop.external-link')} />
              <Field name="ctaText" label={t('admin.shop.cta-text')} />
              {values?.marketings?.find((item) => item.value === FACEBOOK) && (
                <Field
                  type="number"
                  name="lifetimeBudget"
                  label={t('admin.products.lifetime-budget')}
                  testId="products-lifetime-budget-input"
                  required
                />
              )}
              <Field type="switch" name="isActive" label={t('admin.products.active')} testId="switch-component" />
              <Field
                type="switch"
                name="availableForPosition"
                label={t('admin.products.linked-to-position')}
                disabled={values?.marketings?.find((item) => item.value === FINN)}
                testId="switch-component"
              />
              <Field type="switch" name="promote" label={t('general.promote')} testId="switch-component" />
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                data-testid="products-submit-button"
              >
                {t('general.submit')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

const ProductFormWrapper = () => {
  const { t } = useTranslation();
  const options = useSelectorToolkit(selectOptions);
  const DEFAULT_CLIENT_OPTIONS = options?.clients || [];

  const DEFAULT_PRODUCT_FORM = {
    title: '',
    description: '',
    specialOffer: '',
    expiration: '',
    price: 0,
    icons: '',
    isActive: false,
    availableForPosition: false,
    promote: false,
    allowedClientIds: [],
    excludedClientIds: [],
    marketings: [],
    lifetimeBudget: '',
    ctaText: t('shop.order-now'),
    sortOrder: null,
  };

  const [productsAPI] = useAPI('products');
  const { type, id } = useParams();

  const findSelected = (arr = [], OPTIONS = [], useId = true) => {
    if (useId) {
      return OPTIONS.filter((item) => arr.includes(item.id));
    }
    return arr.map((item) => OPTIONS.find((option) => option.value === parseInt(item, 10)));
  };

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source) => {
        if (type === 'edit' && id) {
          const response = await productsAPI.find(id, {
            cancelToken: source.token,
          });

          return {
            ...DEFAULT_PRODUCT_FORM,
            ...response,
            excludedClientIds: findSelected(response?.excludedClientIds || [], DEFAULT_CLIENT_OPTIONS),
            allowedClientIds: findSelected(response?.allowedClientIds || [], DEFAULT_CLIENT_OPTIONS),
            expiration: response?.expiration || 0,
            marketings: findSelected(response?.marketings || [], ADVERTISEMENT_TYPE_OPTIONS, false),
            icons: response?.icons || '',
            description: response?.description || '',
            lifetimeBudget: response?.lifetimeBudget || 0,
          };
        }

        return DEFAULT_PRODUCT_FORM;
      }}
    >
      <ProductForm />
    </ApiRequestProvider>
  );
};

export default ProductFormWrapper;
