/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { get } from 'lodash';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

const TOKEN_EXPIRATION_TIME_IN_SECONDS = 300;
const TOKEN_REFRESH_RATE = TOKEN_EXPIRATION_TIME_IN_SECONDS / 2;

const getTokenExpiration = (token) => {
  const decoded = jwt_decode(token);
  const iat = moment(decoded.iat);
  const exp = moment(decoded.exp);
  const diff = exp.diff(iat);

  return moment()
    .add(diff - TOKEN_REFRESH_RATE, 'seconds')
    .toDate();
};

const setDataInLocalStorage = (data) => {
  const newToken = get(data, 'id_token');
  localStorage.setItem('ts-api-token', newToken);

  if (data.refresh_token) {
    localStorage.setItem('ts-refresh-token', data.refresh_token);
  }

  if (data.issuer) {
    localStorage.setItem('ts-issuer', data.issuer);
  }

  if (newToken) {
    localStorage.setItem('ts-access-token-expiration', getTokenExpiration(newToken));
  }

  localStorage.setItem('ts-login-date', new Date());

  return newToken;
};

const removeDataFromLocalStorage = () => {
  localStorage.removeItem('ts-api-token');
  localStorage.removeItem('ts-refresh-token');
  localStorage.removeItem('ts-access-token-expiration');
  localStorage.removeItem('ts-issuer');
  localStorage.removeItem('ts-login-date');
};

export {
  getTokenExpiration,
  setDataInLocalStorage,
  removeDataFromLocalStorage,
  TOKEN_EXPIRATION_TIME_IN_SECONDS,
  TOKEN_REFRESH_RATE,
};
