/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import uuidv4 from 'uuid/v4';
import events from '../../../helpers/events';

const id = uuidv4();

const BaseToolbar = ({ withVideo }) => {
  const { t } = useTranslation();
  const testId = 'baseToolbar';

  return (
    <>
      <div
        className="ql-formats"
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      >
        <button
          type="button"
          className="ql-bold"
          data-testid={`${testId}--bold`}
          id={`bold-${id}`}
          href="#"
        >
          <UncontrolledTooltip
            placement="top"
            data-testid={`${testId}--bold--tooltip`}
            target={`bold-${id}`}
          >
            {t('html-editor.bold')}
          </UncontrolledTooltip>
        </button>
        <button
          type="button"
          data-testid={`${testId}--italic`}
          className="ql-italic"
          id={`italic-${id}`}
          href="#"
        >
          <UncontrolledTooltip
            placement="top"
            data-testid={`${testId}--italic--tooltip`}
            target={`italic-${id}`}
          >
            {t('html-editor.italic')}
          </UncontrolledTooltip>
        </button>
        <button
          type="button"
          data-testid={`${testId}--underline`}
          className="ql-underline"
          id={`underline-${id}`}
          href="#"
        >
          <UncontrolledTooltip
            placement="top"
            data-testid={`${testId}--underline--tooltip`}
            target={`underline-${id}`}
          >
            {t('html-editor.underline')}
          </UncontrolledTooltip>
        </button>
      </div>
      <div
        className="ql-formats"
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      >
        <button
          type="button"
          className="ql-list"
          data-testid={`${testId}--numbered`}
          value="ordered"
          id={`numbered-list-${id}`}
          href="#"
        >
          <UncontrolledTooltip
            placement="top"
            data-testid={`${testId}--numbered--tooltip`}
            target={`numbered-list-${id}`}
          >
            {t('html-editor.numbered-list')}
          </UncontrolledTooltip>
        </button>
        <button
          type="button"
          className="ql-list"
          data-testid={`${testId}--bullet`}
          value="bullet"
          id={`bullet-list-${id}`}
          href="#"
        >
          <UncontrolledTooltip
            placement="top"
            data-testid={`${testId}--bullet--tooltip`}
            target={`bullet-list-${id}`}
          >
            {t('html-editor.bulleted-list')}
          </UncontrolledTooltip>
        </button>
      </div>
      <div
        className="ql-formats"
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      >
        <button
          type="button"
          className="ql-link"
          data-testid={`${testId}--link`}
          id={`insert-link-${id}`}
          href="#"
        >
          <UncontrolledTooltip
            placement="top"
            data-testid={`${testId}--link--tooltip`}
            target={`insert-link-${id}`}
          >
            {t('html-editor.insert-link')}
          </UncontrolledTooltip>
        </button>
        {withVideo && (
          <button
            type="button"
            data-tip={t('html-editor.insert-video')}
            data-testid={`${testId}--video`}
            className="ql-video"
            id={`insert-video-${id}`}
            href="#"
            onClick={() => {
              events.$emit('wysiwyg-insert-video');
            }}
          >
            <UncontrolledTooltip
              placement="top"
              data-testid={`${testId}--video--tooltip`}
              target={`insert-video-${id}`}
            >
              {t('html-editor.insert-video')}
            </UncontrolledTooltip>
          </button>
        )}
      </div>
    </>
  );
};

export default React.memo(BaseToolbar);
