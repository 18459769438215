import React from 'react';
import qs from 'query-string';
import { Button } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal, { ModalBody } from '../Modal';

const Text = styled.p`
  margin: 12px 0 25px;
`;

const DuplicatePosition = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { state, search } = useLocation();
  const queryParams = qs.parse(search);

  const closeModal = () => {
    const queryParamsCopy = { ...queryParams };

    Reflect.deleteProperty(queryParamsCopy, 'modal');
    history.push({
      search: qs.stringify(queryParamsCopy),
      state,
    });
  };
  return (
    <Modal width={600}>
      <ModalBody>
        <Text className="text-center">{t('position.duplicate-success')}</Text>
        <div className="d-flex align-items-center justify-content-center">
          <Button
            data-testid="close-duplicate-success-modal"
            type="button"
            color="light"
            onClick={() => {
              closeModal();
            }}
          >
            {t('general.close')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DuplicatePosition;
