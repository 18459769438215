/* eslint-disable react/forbid-prop-types */
import { debounce, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../Dropdown/DropdownMenu';
import { setTablePageSize } from '../../../store/slices/settings.slice';
import Icon from '../Icon';
import {
  useSelector as useSelectorToolkit,
  dispatch as dispatchToolkit,
} from '../../../store';

const Search = ({
  meta,
  setMeta,
  onChange,
  resource,
  placeholder,
  filter: filterComponent,
  sort: sortComponent,
  whitelist,
  withPageSize = true,
  label = '',
  debounceTimeout = 1000,
  hideShowAll = false,
}) => {
  const { t } = useTranslation();
  const pageSize = useSelectorToolkit(
    ({ settings }) => settings?.table?.pageSize
  );
  const onSearch = (value) => {
    if (!onChange) {
      if (value !== '') {
        setMeta({
          filters: [
            ...meta.filters.filter((item) => item.key !== resource),
            {
              key: resource,
              value,
              condition: '@=',
            },
          ],
          page: 1,
        });

        return;
      }

      setMeta({
        filters: [...meta.filters.filter((item) => item.key !== resource)],
        page: 1,
      });
    } else {
      onChange(value);
    }
  };

  const debounceSearch = debounce((value) => {
    onSearch(value);
  }, debounceTimeout);

  return (
    <div className="h-14 flex items-center gap-x-2 px-4 z-10 bg-white rounded-t-lg">
      <div className="flex items-center gap-x-6 flex-grow">
        {label && (
          <>
            <p className="mb-0">{label}</p>
            <div className="w-[1px] h-[30px] bg-gray-200" />
          </>
        )}
        <div className="flex items-center gap-x-2 flex-grow">
          <Icon name="search" size={20} color="var(--text-color-secondary)" />
          <input
            name="search"
            data-testid="table-search-input"
            type="text"
            placeholder={placeholder}
            defaultValue={
              meta?.filters?.find((filter) => filter.key === resource)?.value ||
              ''
            }
            className="h-full flex-grow border-0 rounded-none pl-0 text-base outline-none border-none"
            onChange={(e) => {
              debounceSearch(get(e, 'target.value'));
            }}
          />
        </div>
      </div>
      {withPageSize && (
        <DropdownMenu
          onSelect={(item) => {
            const selected = parseInt(item, 10);
            dispatchToolkit(setTablePageSize(selected));
          }}
          placement="bottomRight"
          menuItems={[
            { label: '10', value: 10 },
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
          ]}
          buttonProps={{
            'data-testid': 'table-per-page-dropdown',
            className: 'transition text-gray-400 hover:text-black',
          }}
        >
          <div className="flex items-center">
            <span className="text-xs mr-1">{pageSize} per page</span>
            <Icon name="chevron-down" className="ml-2" fontSize={14} />
          </div>
        </DropdownMenu>
      )}
      {sortComponent}
      {!!meta?.filters?.length && !hideShowAll && (
        <button
          type="button"
          className="ts-filter-button hover:bg-table-hover hover:text-black transition text-xs border px-3 py-1.5 rounded-lg flex items-center relative"
          onClick={() => {
            setMeta({
              ...meta,
              filters: meta?.filters?.filter((item) =>
                whitelist?.includes(item.key)
              ),
              page: 1,
            });
          }}
          data-testid="table-show-all-button"
        >
          {t('general.show-all')}
        </button>
      )}
      {filterComponent}
    </div>
  );
};

Search.propTypes = {
  meta: PropTypes.shape({
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        condition: PropTypes.string,
      })
    ),
  }),
  setMeta: PropTypes.func,
  resource: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  filter: PropTypes.element,
  whitelist: PropTypes.array,
  withPageSize: PropTypes.bool,
  debounceTimeout: PropTypes.number,
};

Search.defaultProps = {
  meta: {},
  setMeta: () => {},
  placeholder: 'Search...',
  filter: null,
  whitelist: [],
  withPageSize: false,
  debounceTimeout: 1000,
};

export default Search;
