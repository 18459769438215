import React from 'react';
import uuidv4 from 'uuid/v4';
import { range, random, get } from 'lodash';
import Placeholder from '../Placeholder';

const arrayLoader = range(50).map(() => range(20).map(() => random(40, 90)));

const TableLoader = ({ headers, pageSize }) => (
  <tbody>
    {range(pageSize).map((item, index) => (
      <tr key={uuidv4()}>
        {headers.map((header, headerIndex) => (
          <td
            key={uuidv4()}
            style={{
              position: header.sticky ? 'sticky' : null,
              right: header.sticky ? 0 : null,
              background: header.sticky ? '#f9fbfd' : null,
              color: header.sticky ? '#696969' : null,
              borderLeft: header.sticky ? '1px solid #e1e2e2' : null,
              verticalAlign: 'middle',
              whiteSpace: 'nowrap',
            }}
          >
            <Placeholder
              height={22}
              width={
                !get(header, 'sticky') ? arrayLoader[headerIndex][index] : null
              }
              random={
                header.sticky
                  ? {
                      min: 50,
                      max: 50,
                    }
                  : {}
              }
              unitType={header.sticky ? 'px' : '%'}
            />
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

export default TableLoader;
