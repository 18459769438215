import React, { useState, useEffect, Fragment } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { debounce, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import uuidv4 from 'uuid/v4';
import qs from 'query-string';
import Icon from '../@v2/Icon';
import Avatar from '../Avatar';
import '../../assets/sass/topbar.sass';
import events from '../../helpers/events';
import { setPositions } from '../../store/slices/positions.slice';
import {
  setFiltersWithKey,
  setActivePosition,
} from '../../store/slices/settings.slice';
import CartPopover from '../CartPopover';
import { useAPI } from '../../context/api';
import { getImageServiceMediaUrl } from '../../helpers/image';
import { getBrowser, log } from '../../helpers/utils';
import { selectUser } from '../../store/selectors/user.selector';
import { logout } from '../../store/thunks/auth.thunks';
import {
  useSelector as useSelectorToolkit,
  dispatch as dispatchToolkit,
} from '../../store';
import { selectClient } from '../../store/selectors/client.selector';

const DropdownInstance = ({
  children,
  icon,
  custom,
  className,
  count,
  clearCount,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => {
        setDropdownOpen((state) => !state);
        clearCount();
      }}
      className={className}
    >
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        {custom || <Icon name={icon || ''} fontSize="20" />}
        {!!count && (
          <p className="absolute py-2 bg-red-600 rounded-sm text-xs text-whit mb-0 bottom-1 left-1/2 -translate-x-1/2">
            {count}
          </p>
        )}
      </DropdownToggle>
      {children}
    </Dropdown>
  );
};

DropdownInstance.defaultProps = {
  children: null,
  icon: '',
  custom: null,
  className: '',
  count: 0,
  clearCount: () => {},
};

const Topbar = () => {
  const publicUrl = global?.appConfig?.public_url;
  const newFeaturesLink =
    global?.appConfig?.new_features_link ||
    'https://weselect.com/talentsoftware/newfeatures';
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const isMinimized = useSelectorToolkit(({ settings }) =>
    get(settings, 'menu.minimized')
  );
  const currentUser = useSelectorToolkit(selectUser);
  const cart = useSelectorToolkit(({ cart }) => cart);
  const activeClient = useSelectorToolkit(selectClient);
  const activePosition = useSelectorToolkit(({ settings }) =>
    get(settings, 'position')
  );
  const [positionAPI, userAPI] = useAPI('position', 'user');

  const namePlaceholder = [
    {
      id: 1,
      icon: 'user-friends',
      name: 'Jonathan',
    },
    {
      id: 2,
      icon: 'user-friends',
      name: 'Mattias',
    },
    {
      id: 3,
      icon: 'user-friends',
      name: 'Per Oddmund',
    },
    {
      id: 4,
      icon: 'briefcase',
      name: 'Software Development',
    },
    {
      id: 5,
      icon: 'user-friends',
      name: 'Herman Niño',
    },
    {
      id: 6,
      icon: 'user-friends',
      name: 'Konstantin',
    },
  ];
  const [searchResult, setSearchResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [, setSearchSelected] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const debouncedSearchText = debounce((target) => {
    const response = namePlaceholder.filter(({ name }) =>
      name.toLowerCase().includes(target.toLowerCase())
    );
    setSearchResult(response);
  }, 1000);

  useEffect(() => {
    setIsSearching(false);
  }, [searchResult]);

  const handleClick = () => {
    setSearchResult([]);
  };

  const getPosition = async (id) => {
    try {
      const { items } = await positionAPI.getList({
        filters: id ? `id==${id}` : '',
        pageSize: id ? 1 : 10,
      });
      return items;
    } catch (error) {
      log(error);
    }

    return null;
  };

  const getPositionData = async (candidatePositionId) => {
    const positions = await getPosition();
    dispatchToolkit(setPositions(positions));

    let activePosition = positions.find(
      (item) => item.id === candidatePositionId
    );

    if (!activePosition) {
      const [position] = await getPosition(candidatePositionId);

      activePosition = position;
    }

    if (activePosition) {
      const pipelines = await positionAPI.getPipelineSteps(activePosition.positionId || activePosition.id);

      dispatchToolkit(
        setActivePosition({
          ...activePosition,
          pipelines,
          activePipelineStepId: null,
        })
      );

      dispatchToolkit(
        setFiltersWithKey({
          key: 'candidates',
          payload: {
            positionId: activePosition.positionId || activePosition.id,
          },
        })
      );
    }
  };

  const getClient = async (id) => {
    try {
      await userAPI.setActiveUser({
        clientId: id,
        browser: getBrowser(),
      });

      dispatchToolkit(
        getClient({
          superAdmin: currentUser.superAdmin,
          id,
        })
      );
    } catch (error) {
      log(error);
    }
  };

  const getNeededData = async ({
    id,
    clientId,
    candidatePositionId,
    candidateId,
  }) => {
    try {
      if (clientId !== activeClient.id) {
        await getClient(clientId, candidatePositionId);
      }

      if (candidatePositionId !== activePosition.positionId || activePosition.id) {
        await getPositionData(candidatePositionId);
      }

      history.push({
        pathname: `/candidates/view/${candidateId}`,
        search: qs.stringify({
          tab: 'comments',
          refresh: true,
          'from-notif': true,
        }),
        state: {
          ...location.state,
          filters: {
            positionId: candidatePositionId,
          },
        },
      });

      setNotifications((currentState) =>
        currentState.filter((state) => state.id !== id)
      );
    } catch (error) {
      log(error);
    }
  };

  useEffect(() => {
    window.removeEventListener('click', handleClick);
    window.addEventListener('click', handleClick);

    events.$off('socket-mention');
    events.$on('socket-mention', (payload) => {
      const message = `${payload.userName} ${t('general.mention')}`;
      NotificationManager.info(message);

      setNotifications((state) => [
        ...state,
        {
          message,
          id: state.length + 1,
          candidatePositionId: payload.candidatePositionId,
          onClick: () => {
            getNeededData(payload);
          },
          read: false,
        },
      ]);
    });

    return () => {
      window.removeEventListener('click', handleClick);
      events.$off('socket-mention');
    };
  }, [activePosition, activeClient]);

  const renderDropDown = () => (
    <>
      <CartPopover />
      <DropdownInstance
        icon="bell"
        className="mr-3"
        count={notifications.filter((item) => !item.read).length}
        clearCount={() => {
          setNotifications((currentState) =>
            currentState.map((item) => {
              item.read = true;
              return item;
            })
          );
        }}
      >
        <DropdownMenu className="mt-3" right>
          {!notifications.length && (
            <DropdownItem header>{t('general.no-notifications')}</DropdownItem>
          )}
          {notifications.map((item, index) => (
            <Fragment key={uuidv4()}>
              {index !== 0 && <DropdownItem divider />}
              <DropdownItem
                onClick={() => {
                  if (!item.onClick) return;
                  item.onClick();
                }}
              >
                {item.message}
              </DropdownItem>
            </Fragment>
          ))}
        </DropdownMenu>
      </DropdownInstance>
      <DropdownInstance
        custom={
          <Avatar
            src={getImageServiceMediaUrl({
              ...currentUser?.avatar,
              name: currentUser?.avatar?.id,
            })}
            size={35}
          />
        }
      >
        <DropdownMenu className="dropdown-menu--no-padding mt-3" right>
          {currentUser.hasClients && (
            <DropdownItem
              className="dropdown-header d-flex align-items-center"
              onClick={() => {
                history.push({
                  pathname: '/select-client',
                });
              }}
            >
              <div className="icon-container">
                <Icon name="key" />
              </div>
              {t('navbar-titles.switch-account')}
            </DropdownItem>
          )}
          <DropdownItem divider />
          <DropdownItem
            className="dropdown-header d-flex align-items-center"
            onClick={() => {
              history.push({
                pathname: '/settings/profile',
              });
            }}
          >
            <div className="icon-container">
              <Icon name="id-card" />
            </div>
            {t('navbar-titles.profile')}
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem className="dropdown-header dropdown-link d-flex align-items-center">
            <a href={newFeaturesLink} target="_blank" rel="noopener noreferrer">
              <div className="icon-container">
                <Icon name="bullhorn" />
              </div>
              {t('navbar-titles.new-features')}
            </a>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            className="dropdown-header d-flex align-items-center"
            tag="button"
            onClick={async () => {
              if (cart.length) {
                history.push({
                  search: 'modal=cart-confirmation',
                  state: {
                    type: 'logout',
                  },
                });
                return;
              }

              await dispatchToolkit(logout());
              history.replace('/');
            }}
          >
            <div className="icon-container">
              <Icon name="sign-out" />
            </div>
            {t('navbar-titles.logout')}
          </DropdownItem>
        </DropdownMenu>
      </DropdownInstance>
    </>
  );

  return (
    <div
      className="topbar"
      id="ts-topbar"
      style={{
        width: isMinimized ? 'calc(100% - 60px)' : '100%',
      }}
    >
      <div className="topbar-input d-none d-sm-block">
        {isSearching ? (
          <img
            className="topbar-input-loader"
            src={`${publicUrl}/images/loader.svg`}
            alt="loader"
          />
        ) : (
          <Icon name="search" size="1x" />
        )}
        <input
          placeholder="Search"
          defaultValue={searchTerm}
          onInput={({ target }) => {
            setSearchTerm(target.value);
            if (target.value === '') return setSearchResult([]);
            setIsSearching(true);
            return debouncedSearchText(target.value);
          }}
        />
        {!!searchResult.length && (
          <ul className="search-results">
            {searchResult.map((item) => (
              <li
                key={item.id}
                onClick={() => {
                  setSearchSelected(item);
                  setSearchTerm(item.name);
                }}
              >
                <Icon name={item.icon} size="1x" />
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="topbar-menu d-flex d-sm-none align-items-center">
        <Icon
          name="bars"
          style={{ fontSize: 26 }}
          onClick={() => {
            events.$emit('sidebar-menu');
          }}
        />
      </div>
      {renderDropDown()}
      <div
        id="topbar-portal"
        className="align-items-center justify-content-end"
      />
    </div>
  );
};

export default Topbar;
