import React from 'react';
import classNames from 'classnames';

const Placeholder = ({
  width,
  height,
  className,
  widthUnit,
  circle,
  rounded,
}) => (
  <div
    className={classNames(
      'bg-gray-300',
      className,
      circle || rounded ? 'rounded-full' : 'rounded-sm'
    )}
    style={{
      width: widthUnit === 'px' ? width : `${width}%`,
      height: circle ? width : height,
    }}
  />
);

Placeholder.defaultProps = {
  width: 50,
  height: 20,
  className: '',
  widthUnit: 'px',
  circle: false,
  rounded: false,
};

export default Placeholder;
