import Tippy, { useSingleton } from '@tippyjs/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { hideAll } from 'tippy.js';
import Card from '../../../../components/@v2/Card';
import Paginator from '../../../../components/@v2/Paginator/Paginator';
import Search from '../../../../components/@v2/Search/Search';
import Table from '../../../../components/@v2/Table/Table';
import Button from '../../../../components/Button';
import Icon from '../../../../components/@v2/Icon';
import Layout from '../../../../components/layouts/default';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import { log } from '../../../../helpers/utils';
import { browseDepartments, deleteDeparment, restoreDeparment } from '../../../../store/api/departments.api';
import DepartmentFilter from './DepartmentFilter';
import DeleteModelTooltip from '../../../../components/@v2/DeleteModelTooltip/DeleteModelTooltip';

const Department = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, data, meta, setMeta, pageSize, reloadData } = useApiRequestContext();

  const [source, target] = useSingleton();
  const [deletingIds, setDeletingIds] = useState([]);

  const onDelete = async (id, isDeleted) => {
    if (deletingIds.includes(id)) {
      return;
    }

    try {
      setDeletingIds([...deletingIds, id]);
      if (isDeleted) {
        await restoreDeparment(id);
      } else {
        await deleteDeparment(id);
      }

      hideAll();
      setDeletingIds(deletingIds.filter((itemId) => itemId !== id));
      setTimeout(() => {
        reloadData();
      }, 750);
    } catch (error) {
      log(error);
    }
  };

  return (
    <Layout
      pretitle={t('general.settings')}
      title={t('general.departments')}
      rightContent={
        <Button
          color="primary"
          className="d-flex align-items-center"
          onClick={() => {
            history.push('/settings/departments/add');
          }}
          id="button--add-department"
          data-testid="settings-department-page-add-button"
        >
          <Icon name="plus" />
          {t('settings.departments.add-department')}
        </Button>
      }
    >
      <Card className="anim-table-delayed shadow-table !border !border-line-default">
        <Tippy
          trigger="click mouseenter"
          singleton={source}
          theme="light"
          interactive
          maxWidth={200}
          appendTo={document.body}
          placement="bottom"
        />
        <Search
          placeholder={t('general.search-using', { resource: 'name' })}
          resource="name"
          meta={meta}
          setMeta={setMeta}
          filter={<DepartmentFilter />}
        />
        <Table
          isLoaded={!loading}
          activeSort={meta?.sorts || ''}
          items={data?.items || []}
          headers={[
            {
              label: t('general.name'),
              key: 'name',
            },
            {
              label: t('general.actions'),
              key: 'actions',
              sticky: true,
              sort: false,
              width: 100,
              headerClassName: 'text-center',
            },
          ]}
          pageSize={pageSize}
          renderBody={(item, index) => {
            return (
              <tr key={item?.id} id={`department-table-row--${index}`}>
                <td>{item?.name}</td>
                <td className="column-sticky">
                  <div className="flex items-center justify-center gap-x-2">
                    <Tippy content={t('general.edit')} singleton={target}>
                      <button
                        type="button"
                        data-testid="edit"
                        onClick={() => {
                          history.push(`/settings/departments/edit/${item?.id}`);
                        }}
                      >
                        <Icon name="edit" />
                      </button>
                    </Tippy>
                    <DeleteModelTooltip
                      content={
                        <p>
                          {t(
                            item?.deleted
                              ? 'settings.departments.restore-department'
                              : 'settings.departments.delete-department'
                          )}
                        </p>
                      }
                      onDelete={async () => {
                        await onDelete(item?.id, item?.deleted);
                      }}
                      deleteButtonLabel={t(!item?.deleted ? 'general.delete' : 'general.restore')}
                      singleton={target}
                    >
                      <button type="button" data-testid="delete">
                        <Icon name={item?.deleted ? 'archive-restore' : 'trash'} />
                      </button>
                    </DeleteModelTooltip>
                  </div>
                </td>
              </tr>
            );
          }}
          setMeta={setMeta}
        />
        <div className="h-14 flex items-center bg-white">
          <Paginator
            pageCount={data?.totalCount / meta?.pageSize}
            initialPage={meta?.page - 1}
            onPageChange={(value) => {
              if (meta?.page !== value) {
                setMeta({
                  page: value,
                });
              }
            }}
            disableInitialCallback
          />
        </div>
      </Card>
    </Layout>
  );
};

const DepartmentWrapper = () => {
  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source, ...rest) => {
        const newFilters = {};

        const filters = rest?.[2] || [];

        filters.forEach((item) => {
          newFilters[item.key] = item.value;
        });

        const sort = {
          by: meta?.sorts?.replace('-', ''),
          direction: meta.sorts.includes('-') ? 'desc' : 'asc',
        };

        const response = await browseDepartments(
          {
            filter: newFilters,
            page: {
              number: meta.page,
              size: meta.pageSize,
            },
            sort: meta.sorts ? sort : {},
          },
          {
            cancelToken: source.token,
          }
        );

        return response;
      }}
    >
      <Department />
    </ApiRequestProvider>
  );
};

export default DepartmentWrapper;
