/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import uuidv4 from 'uuid/v4';

const id = uuidv4();
const AligmentToolbar = () => {
  const { t } = useTranslation();
  const testId = 'alignmentToolbar';

  return (
    <>
      <div
        className="ql-formats"
        data-testid={testId}
        href="#"
        id={`alignment-${id}`}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      >
        <UncontrolledTooltip
          placement="top"
          data-testid={`${testId}--tooltip`}
          target={`alignment-${id}`}
        >
          {t('html-editor.align-text')}
        </UncontrolledTooltip>
        <select
          data-testid={`${testId}--select`}
          className="ql-align"
          defaultValue=""
        >
          <option value="" />
          <option data-testid={`${testId}--select--center`} value="center" />
          <option data-testid={`${testId}--select--right`} value="right" />
          <option data-testid={`${testId}--select--justify`} value="justify" />
        </select>
      </div>
    </>
  );
};

export default AligmentToolbar;
