import React, { useMemo } from 'react';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';

const withFilters = (Component) => (props) => {
  const location = useLocation();
  const locationFilters = get(location, 'state.filters') || {};

  const filters = useMemo(() => {
    let filters = [];

    Object.keys(locationFilters).forEach((key) => {
      filters = [...filters, `${key}==${locationFilters[key]}`];
    });

    return filters.join(',');
  }, [locationFilters]);

  return <Component {...props} filters={filters} />;
};

export default withFilters;
