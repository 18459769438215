import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { RootState } from '..';

export const selectSelf = (state: RootState) => state;

export const selectLoading = createSelector(selectSelf, (state) => {
  return get(state, 'auth.loading');
});

export const selectError = createSelector(selectSelf, (state) => {
  return get(state, 'auth.error');
});

export const selectIsTokenExpires = createSelector(selectSelf, (state) => {
  return get(state, 'auth.isTokenExpired');
});

export const selectIsTokenRefreshing = createSelector(selectSelf, (state) => {
  return get(state, 'auth.isTokenRefreshing');
});
