import React, { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAPI } from '../../../context/api';
import { log } from '../../../helpers/utils';
import { selectClient } from '../../../store/selectors/client.selector';
import { useSelector as useSelectorToolkit } from '../../../store';
import Field from '../../@v2/Form/Field';
import Button from '../../Button';
import { LocationFormSchema } from '../../../pages/@v2/settings/location/LocationForm';

const defaultForm = {
  id: 0,
  name: '',
  placeId: null,
  latitude: null,
  longitude: null,
};

const LocationForm = ({ isShown, setIsShown, setState }) => {
  const { t } = useTranslation();
  const [isLocationSending, setIsLocationSending] = useState(false);
  const [locationAPI] = useAPI('location');
  const activeClient = useSelectorToolkit(selectClient);

  const [form, setForm] = useState({
    ...defaultForm,
    clientId: activeClient?.id,
  });

  const onSubmit = async (values) => {
    if (isLocationSending) return;

    try {
      setIsLocationSending(true);
      setForm(values);
      const response = await locationAPI.add(values);

      setState('locationId', response.id);
      setState('locationName', response.title);
      setState('selectedLocation', response);
      setIsShown(false);
      setForm({
        ...form,
        ...defaultForm,
      });
    } catch (error) {
      log(error);
    } finally {
      setIsLocationSending(false);
    }
  };

  if (!isShown) return null;

  return (
    <Formik
      initialValues={form}
      validationSchema={LocationFormSchema(t)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, submitCount, errors }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className="anim-table-delayed mt-4 w-full shadow-sm p-4 bg-white rounded-lg"
          >
            <Field
              name="name"
              testId="settings-location-page-place-id-input"
              initialValue={form?.name || ''}
              label={`${t('general.street-address')} / ${t('general.city')}`}
              type="gmapplaces"
              dirty={!!submitCount}
              overrideError={errors?.name || errors?.placeId}
              required
            />
            <Field
              name="title"
              testId="settings-location-page-title-input"
              label={t('settings.location.name')}
              dirty={!!submitCount}
              required
            />
            <div className="flex gap-x-4">
              <Button
                color="danger"
                disabled={isSubmitting}
                data-testid="settings-location-page-cancel-button"
                onClick={() => {
                  setIsShown(false);
                }}
              >
                {t('general.cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                data-testid="settings-location-page-submit-button"
              >
                {t('general.submit')}
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default LocationForm;
