export default [
  {
    label: 'AMS',
    value: 100,
  },
  {
    label: 'Facebook',
    value: 200,
  },
  {
    label: 'Instagram',
    value: 300,
  },
  {
    label: 'LinkedIn Jobs',
    value: 400,
  },
  {
    label: 'Snapchat Ads',
    value: 500,
  },
  {
    label: 'Youtube Ads',
    value: 600,
  },
  {
    label: 'Google Ads',
    value: 700,
  },
  {
    label: 'Blocket Job',
    value: 800,
  },
  {
    label: 'Finn',
    value: 900,
  },
];
