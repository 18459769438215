// eslint-disable-next-line no-unused-vars
import styled from 'styled-components';

const SpecialOffer = styled.span`
  top: 15px;
  right: 0px;
  left: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px;
  position: absolute;
  padding-left: 0px;
  font-size: 0.8rem;
  line-height: 2em;
  height: 1.5rem;
  width: 120px;
  perspective: 300px;
  -moz-perspective: 300px;
  -webkit-perspective: 300px;
  -webkit-transform: rotate(40deg);
  background-color: #7fd58f;
  color: #fff;
  -moz-transform: rotate(40deg);
  transform: rotate(40deg);
  filter: progid:DXImageTransform.Microsoft.Matrix(M11='0.7071067811865476', M12='-0.7071067811865475', M21='0.7071067811865475', M22='0.7071067811865476', sizingMethod='auto expand');
`;

export default SpecialOffer;
