import React from 'react';
// import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SchedulerCreate from '../../../components/scheduler/add';
import SchedulerEdit from '../../../components/scheduler/edit';

const action = () => {
  // const { t } = useTranslation();
  const { type, id } = useParams();

  return (
    <>
      {type === 'add' && <SchedulerCreate />}
      {type === 'edit' && <SchedulerEdit id={id} />}
    </>
  );
};

export default action;
