import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from '../../../components/layouts/default';
import Product from '../../../components/Product';
import Button from '../../../components/Button';

const tool = () => {
  const { state } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Layout
      pretitle={t('general.admin')}
      title={state.title}
      rightContent={
        <Button
          color="primary"
          onClick={() => {
            history.push(`/admin/products/edit/${state.id}`);
          }}
          data-testid="products-edit-button"
        >
          {t('admin.products.edit-product')}
        </Button>
      }
    >
      <div className="anim-table-delayed">
        <Product {...state} />
      </div>
    </Layout>
  );
};

export default tool;
