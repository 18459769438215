import React, { useEffect, useMemo, useState } from 'react';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';
import Select from '../input/Select';

export const MultiSelectWidget = ({
  listValues,
  value,
  setValue,
  placeholder,
}) => {
  const { t } = useTranslation();
  const [multiSelectValue, setMultiSelectValue] = useState([]);

  const multiSelectOptions = useMemo(() => {
    if (listValues) {
      return listValues.map(({ title, value }) => ({
        label: title,
        value,
      }));
    }
    return [];
  }, [listValues]);

  // log('multiSelectOptions', multiSelectOptions);

  useEffect(() => {
    if (value) {
      const updatedValues = value.map((item) => {
        const itemId = item.in ? item.in[0] : item;
        const foundLabel = multiSelectOptions.find(
          (option) => option.value === itemId
        );
        return {
          label: foundLabel ? foundLabel.label : '',
          value: foundLabel ? foundLabel.value : itemId,
        };
      });
      setMultiSelectValue(updatedValues);
    }
  }, [value]);

  return (
    <Select
      isMulti
      async
      className="query-builder-multiselect"
      value={multiSelectValue}
      onChange={(selected) => {
        if (selected) {
          const updatedValues = uniqBy(
            [...multiSelectValue, ...selected],
            'value'
          );
          setMultiSelectValue(updatedValues);
          setValue(selected.map(({ value }) => value));
        }
      }}
      defaultOptions={multiSelectOptions}
      placeholder={placeholder || t('general.select')}
    />
  );
};

export const SelectWidget = ({
  items,
  setField,
  selectedKey,
  readonly,
  placeholder,
}) => {
  const { t } = useTranslation();
  const selectOptions = useMemo(() => {
    if (items) {
      return items.map(({ key, label, path }) => ({
        label,
        value: key,
        key: path,
      }));
    }
    return [];
  }, [items]);

  return (
    <Select
      className="query-builder-select"
      value={
        selectedKey
          ? selectOptions.find(({ value }) => value === selectedKey)
          : null
      }
      onChange={(selected) => {
        if (selected) setField(selected.value);
      }}
      disabled={readonly}
      options={selectOptions}
      placeholder={placeholder || t('general.select')}
    />
  );
};

export const TextWidget = ({ value, setValue, readonly, placeholder }) => (
  <Input
    value={value}
    onChange={(e) => {
      let val = e.target.value;
      if (val === '') {
        val = undefined;
      }
      setValue(val);
    }}
    placeholder={placeholder}
    disabled={readonly}
  />
);

// selectable value field after operator field
export const SelectValueWidget = ({
  listValues,
  value,
  setValue,
  readonly,
  placeholder,
}) => {
  const { t } = useTranslation();

  const selectValueOptions = useMemo(() => {
    if (listValues) {
      return listValues.map(({ title, value }) => ({
        label: title,
        value,
      }));
    }
    return [];
  }, [listValues]);

  return (
    <Select
      className="query-builder-select"
      value={
        value ? selectValueOptions.find((item) => item.value === value) : null
      }
      onChange={(selected) => {
        if (selected) {
          setValue(selected.value);
        }
      }}
      disabled={readonly}
      options={selectValueOptions}
      placeholder={placeholder || t('general.select')}
    />
  );
};
