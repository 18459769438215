import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Field from '../../../../components/@v2/Form/Field';
import Button from '../../../../components/Button';
import ImageDropzone from '../../../../components/input/ImageDropzone';
import { UserLanguage } from '../../../../constants/User';
import { getImageServiceMediaUrl, getConvertedImageUrlString } from '../../../../helpers/image';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../../store';
import { getUser, updateUserProfile } from '../../../../store/api/user.api';
import { selectUser } from '../../../../store/selectors/user.selector';
import { setLocale } from '../../../../store/slices/settings.slice';
import { setUserData } from '../../../../store/slices/user.slice';

const AccountDetailsForm = () => {
  const { t } = useTranslation();
  const currentUser = useSelectorToolkit(selectUser);

  const AccountDetailsFormSchema = Yup.object().shape({
    firstName: Yup.string().required(t('form-validator.required')),
    lastName: Yup.string().required(t('form-validator.required')),
  });

  const ValidAuth0Url = 'weselect.com';

  const INITIAL_VALUES = {
    ...currentUser,
    avatar: getImageServiceMediaUrl({
      ...currentUser?.avatar,
      name: currentUser?.avatar?.id,
    }),
    language: UserLanguage.find((item) => item.value === currentUser.language) || null,
    firstName: currentUser?.firstName || '',
    lastName: currentUser?.lastName || '',
    phoneNumber: currentUser?.phoneNumber || '',
    signatureHtml: currentUser?.signatureHtml || '',
    userId: currentUser?.userId || currentUser?.activeUserId,
    costCenter: currentUser?.costCenter || '',
    existingAvatar: currentUser?.avatar,
  };

  const setUserStoreData = async (values) => {
    dispatchToolkit(setUserData({ ...values, locale: values.language }));
    dispatchToolkit(setLocale(values.language));
  };

  const hasIssUrl = !!(currentUser?.iss && currentUser.iss?.includes(ValidAuth0Url));

  const onSubmit = async (values) => {
    try {
      if (values.avatar instanceof File) {
        const imageUrlString = await getConvertedImageUrlString(values.avatar);

        values.avatarUpload = {
          filename: values.avatar?.path,
          content: imageUrlString.split('base64,')[1],
        };
      }
      if (!values?.avatarUpload) {
        values.avatar = values.existingAvatar;
      }
      const [response] = await updateUserProfile(values);
      if (response) {
        const updatedUser = await getUser();

        const newUserData = {
          ...values,
          ...response,
          ...updatedUser,
          costCenter: response?.costCenter || updatedUser?.costCenter || currentUser?.costCenter,
          language: values?.language?.value || currentUser?.language,
        };
        await setUserStoreData(newUserData);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <Formik initialValues={INITIAL_VALUES} validationSchema={AccountDetailsFormSchema} onSubmit={onSubmit}>
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className="anim-table-delayed max-w-4xl w-full shadow-sm p-4 bg-white rounded-lg"
          >
            <div className="mb-4">
              <ImageDropzone
                avatar
                value={values?.avatar}
                onChange={([file]) => {
                  setFieldValue('avatar', file);
                  setFieldValue('avatar', file);
                }}
                onDeleteAvatar={() => {
                  setFieldValue('avatar', null);
                }}
                resource="form-avatar"
              />
            </div>
            <div className="flex items-start gap-x-4">
              <Field required disabled={hasIssUrl} type="text" name="firstName" label={t('general.first-name')} />
              <Field required disabled={hasIssUrl} type="text" name="lastName" label={t('general.last-name')} />
            </div>
            <div className="flex items-start gap-x-4">
              <Field disabled={hasIssUrl} type="tel" name="phoneNumber" label={t('general.phone-number')} />
              <Field type="select" defaultOptions={UserLanguage} name="language" label={t('general.language')} />
            </div>
            <Field type="text" name="costCenter" label={t('settings.reference')} />
            <Field type="wysiwyg" name="signatureHtml" label={t('general.profile-email-signature')} />
            <Button type="submit" color="primary" disabled={isSubmitting} loading={isSubmitting}>
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default AccountDetailsForm;
