import PropTypes from 'prop-types';
import { useEffect } from 'react';

const useElementClickHandlers = ({ condition, callback, elements }) => {
  const handleWindowClick = (window) => {
    const { target } = window;

    if (target && condition) {
      let isInsideTheElement = false;

      elements.forEach((item) => {
        if (target.closest(item)) isInsideTheElement = true;
      });

      if (isInsideTheElement) return;

      callback(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);

    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, [condition]);
};

useElementClickHandlers.propTypes = {
  condition: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  elements: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default useElementClickHandlers;
