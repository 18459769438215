import { createAsyncThunk } from '@reduxjs/toolkit';
import UpdateCandidateCommand from '@ts/apicontracts/candidates/update-candidate-command';
import AddCandidateManuallyCommand from '@ts/apicontracts/candidates/add-candidate-manually-command';

import {
  updateCandidate as updateCandidateRequest,
  addManual as addManualRequest,
  getCandidate as getCandidateRequest,
  setCandidateRating as setCandidateRatingRequest,
} from '../api/candidates.api';

interface SetCandidateRatingParams {
  candidateId: number;
  ratingId: string;
  type: string;
  value: number;
  [key: string]: any;
}

export const updateCandidate = createAsyncThunk(
  'candidates/updateCandidate',
  async (payload: UpdateCandidateCommand) => {
    const data = await updateCandidateRequest(payload);
    return data;
  }
);

export const addManual = createAsyncThunk('candidates/addManual', async (payload: AddCandidateManuallyCommand) => {
  const data = await addManualRequest(payload);
  return data;
});

export const getCandidateDetails = createAsyncThunk('candidates/getCandidateDetails', async (id: string) => {
  const data = await getCandidateRequest(id);
  return data;
});

export const setCandidateRating = createAsyncThunk(
  'candidates/setCandidateRating',
  async ({ params, type }: SetCandidateRatingParams) => {
    try {
      const data = await setCandidateRatingRequest({ params, type });
      return data;
    } catch (error) {
      throw error;
    }
  }
);
