import React from 'react';
import VisiblePosition from './VisiblePosition';
import NotVisiblePosition from './NotVisiblePosition';
import ExternalLink from './ExternalLink';

const Position = ({ ...rest }) => (
  <div className="position-form">
    <VisiblePosition {...rest} />
    <NotVisiblePosition {...rest} />
    <ExternalLink {...rest} />
  </div>
);

export default Position;
