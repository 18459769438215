import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { getImageServiceMediaUrl } from '../../helpers/image';
import Icon from './Icon';
import Avatar from '../Avatar';

const TeamMemberList = ({ items, onRemove }) => {
  return (
    <div className="flex items-center flex-wrap flex-grow">
      {items.map((teamMember, index) => {
        return (
          <>
            <div className="mr-2 my-1 text-xs bg-gray-200 flex items-center justify-between px-2 py-1 rounded-full">
              <p id={`team-member-${index}`} className="mb-0">
                {teamMember?.name}
              </p>
              <div
                onClick={() => {
                  onRemove(teamMember);
                }}
                data-testid={`position-team-members-${index}-delete-icon`}
              >
                <Icon name="times" size={12} className="ml-2" />
              </div>
            </div>
            <UncontrolledTooltip placement="top" target={`team-member-${index}`} autohide={false}>
              <div className="p-2 flex">
                <Avatar
                  src={getImageServiceMediaUrl({ ...teamMember?.avatar, name: teamMember.avatar?.id })}
                  size={60}
                  className="mr-3"
                />
                <div className="text-left">
                  {teamMember?.name && <p className="font-bold text-base mb-0">{teamMember?.name}</p>}
                  {teamMember?.email && <p className="opacity-40">{teamMember?.email}</p>}
                </div>
              </div>
            </UncontrolledTooltip>
          </>
        );
      })}
    </div>
  );
};

export default TeamMemberList;
