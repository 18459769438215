import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApiRequestContext } from '../../../../../context/@v2/ApiRequestContext';
import { onBlockUpdate } from '../../../../../store/api/career-page.api';
import Button from '../../../../Button';
import Field from '../../../Form/Field';

const SubAccountPositionsForm = ({ item }) => {
  const { t } = useTranslation();
  const { data, reloadData } = useApiRequestContext();

  const onSubmit = async (values) => {
    await onBlockUpdate(
      data?.details,
      {
        ...values,
        grouping: values?.grouping?.value,
      },
      async () => {
        await reloadData();
        data?.renderSuccessNotification();
      }
    );
  };

  const GROUPING_OPTIONS = [
    {
      value: 0,
      label: t('general.none'),
    },
    {
      value: 1,
      label: t('general.client-name'),
    },
  ];

  return (
    <Formik
      initialValues={{
        ...item,
        grouping: GROUPING_OPTIONS.find((option) => option.value === item?.grouping),
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit} className="w-full">
            <Field dark name="title" label={t('general.headline')} />
            <Field dark name="spontaneousTitle" label={t('career-page.spontaneous-title')} />
            <Field dark type="select" name="grouping" label={t('general.grouping')} defaultOptions={GROUPING_OPTIONS} />
            <Button
              type="submit"
              color="primary"
              className="w-full text-white"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="career-page-block-form-submit"
            >
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default SubAccountPositionsForm;
