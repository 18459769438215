import React from 'react';
import Icon from '../../../Icon';

interface Props {
  label: string;
  onBack(): void;
}

const BlockTitle = ({ label, onBack }: Props) => {
  return (
    <div className="flex items-center border-b border-opacity-20 !pb-4 !-mt-3 !-mx-6 !px-6 gap-x-4">
      <button type="button" className="flex" onClick={onBack}>
        <Icon name="long-arrow-left" color="black" fontSize={20} />
      </button>
      <p className="mb-0 uppercase text-black font-bold truncate">{label}</p>
    </div>
  );
};

export default BlockTitle;
