import { startCase, toLower } from 'lodash';
import * as Icons from 'lucide-react';
import OldIcon from '../Icon';

interface Props {
  name: string;
  color?: string;
  size?: number | string;
  fontSize?: number | string;
  style?: any;
  className?: string;
  type?: string;
  onClick?: () => void;
}

const Icon = ({ name, color, size = 16, fontSize, style, className, type, onClick }: Props) => {
  const parsedName = startCase(toLower(name)).replace(' ', '');
  const LucideIcon = Icons?.[parsedName];

  if (LucideIcon) {
    return <LucideIcon className={className} color={color} size={size} onClick={onClick} />;
  }

  return (
    // @ts-ignore
    <OldIcon
      name={name}
      type={type}
      className={className}
      onClick={onClick}
      color={color}
      size="1x"
      style={{
        fontSize,
        ...(style || {}),
      }}
    />
  );
};

export default Icon;
