/* eslint-disable no-useless-escape */
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Field from '../../../components/@v2/Form/Field';
import Button from '../../../components/Button';
import Layout from '../../../components/layouts/default';
import ApiRequestProvider, { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import { getGDPRSettings } from '../../../store/api/client.api';
import Divider from '../../../components/@v2/Divider/Divider';
import { updateGDPR } from '../../../store/thunks/client.thunk';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../store';
import { selectClient } from '../../../store/selectors/client.selector';

const GdprForm = () => {
  const { t } = useTranslation();
  const { loading, data } = useApiRequestContext();
  const activeClient = useSelectorToolkit(selectClient);
  const validationSchema = Yup.object().shape({
    gdprCustomPrivacyPolicyLink: Yup.string().nullable().url(),
    gdprHideDirectApplicationMonths: Yup.number().nullable().min(1, t('error-message.has-atleast-one-month')),
    gdprHideSpontaneousApplicationMonths: Yup.number().nullable().min(1, t('error-message.has-atleast-one-month')),
  });

  const onSubmit = async (values) => {
    try {
      await dispatchToolkit(
        updateGDPR({
          id: activeClient.id,
          form: {
            ...values,
            gdprHideDirectApplicationMonths: values?.gdprHideDirectApplicationMonths || 1,
            gdprHideSpontaneousApplicationMonths: values?.gdprHideSpontaneousApplicationMonths || 1,
          },
        })
      ).unwrap();
    } catch (error) {
      throw new Error(error);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Layout title={t('settings.gdpr.label')} pretitle={t('general.settings')} data-testid="settings-gpdr-page-layout">
      <Formik initialValues={data} validationSchema={validationSchema} onSubmit={onSubmit} validateOnChange={false}>
        {({ handleSubmit, isSubmitting }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="anim-table-delayed max-w-2xl w-full shadow-sm p-4 bg-white rounded-lg"
            >
              <Divider>{t('settings.gdpr.general-settings')}</Divider>
              <Field
                name="privacyPolicy"
                label={t('settings.gdpr.privacy-policy')}
                type="wysiwyg"
                toolbar="career-text-image"
              />
              <Field name="gdprCustomPrivacyPolicyLink" label={t('settings.gdpr.privacy-policy-link')} />
              <Field
                name="showApprovalToBeContactedRegardingOtherPositions"
                label={t('settings.gdpr.show-approval-regarding-other-positions')}
                type="switch"
              />
              <Divider className="mt-5">{t('settings.gdpr.filter-and-deletion')}</Divider>
              <Field
                name="gdprAutoHideCandidates"
                label={t('settings.gdpr.hide-candidates-automatically')}
                type="switch"
              />
              <div className="flex gap-x-4">
                <Field
                  name="gdprHideDirectApplicationMonths"
                  label={t('settings.gdpr.direct-application-months')}
                  type="number"
                />
                <Field
                  name="gdprHideSpontaneousApplicationMonths"
                  label={t('settings.gdpr.spontaneous-application-months')}
                  type="number"
                />
              </div>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                data-testid="settings-location-page-submit-button"
              >
                {t('general.submit')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

const GdprFormWrapper = () => {
  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source) => {
        const response = await getGDPRSettings({
          cancelToken: source.token,
        });

        return response;
      }}
    >
      <GdprForm />
    </ApiRequestProvider>
  );
};

export default GdprFormWrapper;
