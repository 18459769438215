import { AxiosResponse } from 'axios';
import ResponseListItem from '@ts/apicontracts/products/search/response-list-item';
import SearchProductsAdminQuery from '@ts/apicontracts/products/search/search-products-admin-query';
import UpdateProductCommand from '@ts/apicontracts/products/update-product-command';
import AddProductCustomFieldCommand from '@ts/apicontracts/productsCustomFields/create-product-custom-field-command';
import UpdateProductCustomFieldCommand from '@ts/apicontracts/productsCustomFields/update-product-custom-field-command';
import DeleteProductCustomFieldCommand from '@ts/apicontracts/productsCustomFields/delete-product-custom-field-command';
import { compact } from 'lodash';
import { api } from '../../helpers/api-v2';

const resource = '/products';

interface UpdateProductCommandExtended
  extends Omit<UpdateProductCommand, 'allowedClientIds' | 'excludedClientIds' | 'marketings'> {
  marketings: { value: string }[];
  allowedClientIds: { id: number }[];
  excludedClientIds: { id: number }[];
}

export const getProductsList = async (params: SearchProductsAdminQuery, options = {}) => {
  const response = await api.post(`${resource}/list`, params, options);
  return response as AxiosResponse<ResponseListItem[]>;
};

export const createOrUpdateProduct = async (values: UpdateProductCommandExtended) => {
  try {
    const form = {
      ...values,
      expiration: values?.expiration || 0,
      marketings: (values?.marketings || []).map(({ value }) => value),
      allowedClientIds: compact(values?.allowedClientIds || []).map(({ id }) => {
        return id;
      }),
      excludedClientIds: compact(values?.excludedClientIds || []).map(({ id }) => {
        return id;
      }),
    };

    const response = await api[form?.id ? 'put' : 'post'](resource, form);

    return response as AxiosResponse<ResponseListItem>;
  } catch (error) {
    throw error;
  }
};

export const addProductCustomFields = async (values: AddProductCustomFieldCommand) => {
  const response = await api.post(`${resource}/custom-fields`, values);

  return response;
};

export const updateProductCustomFields = async (values: UpdateProductCustomFieldCommand) => {
  const response = await api.put(`${resource}/custom-fields`, values);

  return response;
};

export const deleteProductCustomFields = async (values: DeleteProductCustomFieldCommand) => {
  const options = {
    data: values
  };

  const response = await api.delete(`${resource}/custom-fields`, options);

  return response;
};
