import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import { useParams } from 'react-router-dom';
import { Input, Row, Col, Label, CardBody } from 'reactstrap';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  updateClientMarketingSettings,
  getClientMarketingSettings,
  ClientCustomizeColorsValues,
} from '../../../../store/api/client.api';

import Button from '../../../../components/Button';
import Divider from '../../../../components/@v2/Divider/Divider';
import Card from '../../../../components/@v2/Card';

import ClientPreviewCustomizedColors from './ClientPreviewCustomizedColors';

const colorValidation = Yup.string()
  .matches(/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/, 'Must be a valid hex color or null')
  .nullable();

const numberValidation = Yup
  .number()
  .label('value')
  .positive()
  .min(1)
  .max(100)
  .nullable();

const validationSchema = Yup.object().shape({
  imageBackgroundTitleColor: colorValidation,
  imageBackgroundLocationColor: colorValidation,
  imageBackgroundCTAButtonColor: colorValidation,
  imageBackgroundCTAButtonTextColor: colorValidation,
  imageBackgroundCTAButtonBorderColor: colorValidation,
  imageOverlayOpacity: numberValidation,
  imageOverlayColor: colorValidation,
  solidBackgroundColor: colorValidation,
  solidBackgroundTitleColor: colorValidation,
  solidBackgroundLocationColor: colorValidation,
  solidBackgroundCTAButtonColor: colorValidation,
  solidBackgroundCTAButtonTextColor: colorValidation,
  solidBackgroundCTAButtonBorderColor: colorValidation,
});

const solidBackgroundColors = {
  solidBackgroundColor: null,
  solidBackgroundTitleColor: null,
  solidBackgroundLocationColor: null,
  solidBackgroundCTAButtonColor: null,
  solidBackgroundCTAButtonTextColor: null,
  solidBackgroundCTAButtonBorderColor: null,
};

const imageBackgroundColors = {
  imageBackgroundTitleColor: null,
  imageBackgroundLocationColor: null,
  imageBackgroundCTAButtonColor: null,
  imageBackgroundCTAButtonTextColor: null,
  imageBackgroundCTAButtonBorderColor: null,
  imageOverlayOpacity: null,
  imageOverlayColor: null,
};

const clientCustomizeColorsInitialValues: ClientCustomizeColorsValues = {
  ...imageBackgroundColors,
  ...solidBackgroundColors,
};

const ClientCustomizeColors: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams() as { id: string };
  const [initialValues, setInitialValues] = React.useState<ClientCustomizeColorsValues>(
    clientCustomizeColorsInitialValues
  );

  useEffect(() => {
    (async () => {
      if (params.id) {
        const marketingSettings = await getClientMarketingSettings(params.id);
        if (marketingSettings?.imageOverlayOpacity) {
          marketingSettings.imageOverlayOpacity *= 100;
        }
        setInitialValues(marketingSettings || clientCustomizeColorsInitialValues);
      }
    })();
  }, [getClientMarketingSettings, params.id]);

  const displayPlaceHolder = (isOpacityField: boolean): string => {
    return isOpacityField ? t('admin.client.enter-opacity-value') : t('admin.client.enter-valid-hex-color');
  };

  const isOpacityField = (key: string): boolean => !!(key === 'imageOverlayOpacity');

  return (
    <Card className="anim-table-delayed mb-4 bg-white">
      <CardBody>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            const valuesObj = { ...values };
            if (values?.imageOverlayOpacity) {
              valuesObj.imageOverlayOpacity = (values?.imageOverlayOpacity / 100).toFixed(2);
            }
            await updateClientMarketingSettings(params.id, { ...valuesObj });
            actions.setSubmitting(false);
          }}
        >
          {({ errors, touched, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Divider>{t('admin.client.imageBackgroundColors')}</Divider>
              <Row form className="mb-2">
                {Object.keys(imageBackgroundColors).map((key, index) => (
                  <Col md={6} key={`${key}-color`} style={{ marginBottom: 24, paddingRight: index % 2 === 0 ? 12 : 0 }}>
                    <Label for={key}>{t(`admin.client.${key}`)}</Label>
                    <Field id={key} name={key}>
                      {({ field }) => (
                        <Input
                          {...field}
                          type={isOpacityField(key) ? 'number' : 'text'}
                          placeholder={displayPlaceHolder(isOpacityField(key))}
                        />
                      )}
                    </Field>
                    {errors[key] && touched[key] && <span className="text-invalid">{errors[key]}</span>}
                  </Col>
                ))}
              </Row>
              <Divider>{t('admin.client.solidBackgroundColors')}</Divider>
              <Row form>
                {Object.keys(solidBackgroundColors).map((key, index) => (
                  <Col md={6} key={`${key}-color`} style={{ marginBottom: 24, paddingRight: index % 2 === 0 ? 12 : 0 }}>
                    <Label for={key}>{t(`admin.client.${key}`)}</Label>
                    <Field id={key} name={key}>
                      {({ field }) => <Input {...field} type="text" placeholder={displayPlaceHolder(false)} />}
                    </Field>
                    {errors[key] && touched[key] && <span className="text-invalid">{errors[key]}</span>}
                  </Col>
                ))}
              </Row>
              {/* @ts-ignore */}
              <Button
                type="submit"
                color="primary"
                data-testid="clients-colors-submit-button"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {t('admin.client.save-colors')}
              </Button>
            </form>
          )}
        </Formik>
        <ClientPreviewCustomizedColors clientId={params.id} />
      </CardBody>
    </Card>
  );
};

export default ClientCustomizeColors;
