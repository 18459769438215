import helper, { api } from '../helpers/api';

const resource = 'Orders';

export default {
  ...helper(resource),
  addOrder: (payload = {}) => api.post(`${resource}/Add`, payload),
  checkout: (payload = {}) => api.post(`${resource}/Checkout`, payload),
  retry: (orderId) => api.post(`${resource}/${orderId}/reprocess`),
  getLog: ({ orderId, orderItemId }) => api.get(`${resource}/${orderId}/items/${orderItemId}/logs`),
};
