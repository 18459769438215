import React, { createContext, useContext } from 'react';
import { CancelToken } from 'axios';

const createToken = () => CancelToken.source();

const createSources = (fns) => {
  const sources = [];
  Object.keys(fns).forEach((key) => {
    sources[key] = createToken();
  });

  return sources;
};

export const ApiContext = createContext();

export const ApiProvider = ({ value, children }) => (
  <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
);

export const cancelRequests =
  (...params) =>
  () => {
    params.forEach((item) => {
      Object.keys(item.sources).forEach((key) => {
        item.sources[key].cancel();
      });
    });
  };

export const useAPI = (...params) => {
  const context = useContext(ApiContext);
  const desiredRepositories = {};

  params.forEach((key) => {
    if (key && context && context[key]) desiredRepositories[key] = context[key];
  });

  return Object.keys(desiredRepositories).map((key) => ({
    ...desiredRepositories[key],
    sources: createSources(desiredRepositories[key]),
  }));
};
