import LookupResponseListItem from '@ts/apicontracts/screening-criterias/lookup/response-list-item';
import TypesResponseListItem from '@ts/apicontracts/screening-criterias/types/response-list-item';
import SearchResponseListItem from '@ts/apicontracts/screening-criterias/search/response-list-item';
import SearchScreeningCriteriasQuery from '@ts/apicontracts/screening-criterias/search/search-screening-criterias-query';
import { AxiosResponseDirect, api } from '../../helpers/api-v2';

const resource = '/screening-criteria';

export const getAllScreeningQuestions = async (queryForm: SearchScreeningCriteriasQuery, options = {}) => {
  const response = await api.post(`${resource}/list?`, queryForm || {}, options);
  return response as unknown as AxiosResponseDirect<SearchResponseListItem>;
};

export const restoreScreeningQuestion = async (id: string) => {
  const response = await api.post(`${resource}/${id}/restore`);
  return response;
};

export const archiveScreeningQuestion = async (id: string) => {
  const response = await api.delete(`${resource}/${id}`);
  return response;
};

export const getTypes = async () => {
  const response = api.get(`${resource}/types`);
  return response as unknown as AxiosResponseDirect<TypesResponseListItem>;
};

export const lookup = async () => {
  const response = api.get(`${resource}/lookup`);
  return response as unknown as AxiosResponseDirect<LookupResponseListItem[]>;
};
