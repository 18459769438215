import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import { useAPI } from '../../../context/api';
import Field from '../../../components/@v2/Form/Field';
import { dispatch as dispatchToolkit, useSelector as useSelectorToolkit } from '../../../store';
import { setActivePosition, setFiltersWithKey } from '../../../store/slices/settings.slice';
import { getPositionListV2 } from '../../../store/api/positions.api';

const CandidateMoveModal = (props) => {
  const { id, setIsOpen } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [candidateAPI] = useAPI('candidate', 'mail');
  const candidatesFilter = useSelectorToolkit(({ settings }) => settings?.filters?.candidates);

  const onSubmit = async ({ selectedPosition, notifyRecruiter }) => {
    await candidateAPI.moveToPosition({
      candidateId: id,
      positionId: selectedPosition?.id,
      sendMailToRecruiter: notifyRecruiter,
    });

    dispatchToolkit(setActivePosition(selectedPosition));
    dispatchToolkit(
      setFiltersWithKey({
        key: 'candidates',
        payload: {
          positionId: selectedPosition?.id,
          status: candidatesFilter?.status || null,
        },
      })
    );

    setIsOpen(false);
    history.push('/candidates');
  };

  return (
    <Formik
      initialValues={{
        selectedPosition: null,
        notifyRecruiter: false,
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <p>{t('modal.candidates.move-candidate-header')}</p>
            <Field
              preload
              name="selectedPosition"
              type="select"
              label={t('modal.candidates.position')}
              searchKey="name"
              keys={['id', 'name']}
              api={(meta) => {
                return getPositionListV2({
                  filter: {
                    statuses: [0, 1],
                    archived: false,
                    ...meta.filtersV2,
                  },
                  page: {
                    number: meta.page,
                    size: meta.pageSize,
                  },
                  sort: {},
                });
              }}
            />
            <Field name="notifyRecruiter" type="switch" label={t('modal.candidates.send-email-to-recruiter')} />
            <div className="flex items-center justify-center gap-x-2">
              <Button
                type="button"
                color="light"
                className="!rounded-full px-4"
                outline
                disabled={isSubmitting}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t('candidate.cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                className="!rounded-full px-4"
                outline
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {t('general.submit')}
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default CandidateMoveModal;
