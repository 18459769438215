import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../assets/sass/login.sass';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../components/Button';
import { useAPI } from '../context/api';
import { log } from '../helpers/utils';
import Field from '../components/@v2/Form/Field';
import Logo from '../assets/svg/logo.svg';

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const EmailNotFoundContainer = styled.span`
  color: red;
  display: block;
  font-size: 14px;
  margin-top: 5px;
`;

const forgotPassword = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const [userAPI] = useAPI('user');
  const [sentEmail, setSendEmail] = useState(false);
  const [emailNotFound, setEmailNotFound] = useState(false);
  const testId = 'forgotPassword';

  const onSubmit = async ({ email }) => {
    try {
      const response = await userAPI.resetPassword(email);

      if (response) {
        setSendEmail(true);
      } else {
        setEmailNotFound(true);
      }
    } catch (error) {
      log(error);
    }
  };

  const ForgotPasswordFormSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form-validator.email'))
      .required(t('form-validator.required')),
  });

  return (
    <div className="login-container">
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={ForgotPasswordFormSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, values }) => {
          return (
            <div className="sign-in">
              <div className="form">
                <p className="!text-black">{t('general.recover-account')}</p>
                <div className="input mb-20" data-testid="login--form">
                  {!sentEmail && (
                    <form
                      onSubmit={handleSubmit}
                      className="flex flex-col items-center"
                    >
                      <InputContainer className="position-relative w-full mb-0">
                        <Field
                          type="email"
                          name="email"
                          placeholder={t('general.placeholder-email')}
                          required
                        />
                        {emailNotFound && (
                          <EmailNotFoundContainer
                            data-testid={`${testId}-emailNotFoundContainer`}
                          >
                            {t('general.email-not-found')}
                          </EmailNotFoundContainer>
                        )}
                      </InputContainer>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                      >
                        {t('general.forgot-password')}
                      </Button>
                      <Link to="/">{t('general.login-here')}</Link>
                    </form>
                  )}
                </div>
                {sentEmail && (
                  <div className="input text-center">
                    <h2>{t('general.reset-link-has-been-sent-to')}</h2>
                    <h1>{values?.email}</h1>
                    <Button
                      type="button"
                      data-testid={`${testId}-button`}
                      loaderColor="#fff"
                      style={{ fontSize: '16px !important' }}
                      onClick={() => push('/')}
                    >
                      {t('general.login-here')}
                    </Button>
                  </div>
                )}
              </div>
              <img className="logo" src={Logo} alt="logo" />
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default forgotPassword;
