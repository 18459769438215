import React from 'react';

const Toolbar = ({ children, toolbarId }) => {
  const testId = 'toolbar';

  return (
    <div data-testid={testId} id={toolbarId}>
      {children}
    </div>
  );
};

export default React.memo(Toolbar);
