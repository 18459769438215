import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import useElementClickHandlers from '../../../../hooks/useElementClickHandlers';
import Filter from '../../../../components/@v2/Filter/Filter';
import SelectFilter from '../../../../components/@v2/Filter/SelectFilter';
import ButtonFilter from '../../../../components/@v2/Button/ButtonFilter';
import useFilterModal from '../../../../hooks/useFilterModal';
import Icon from '../../../../components/@v2/Icon';

const ProductFilter = () => {
  const { t } = useTranslation();
  const [isFilterShown, setIsFilterShown] = useState(false);
  const { meta, setMeta } = useApiRequestContext();

  const ACTIVE_OPTIONS = [
    {
      label: t('general.active'),
      value: 'true',
    },
    {
      label: t('general.inactive'),
      value: 'false',
    },
  ];

  useElementClickHandlers({
    condition: isFilterShown,
    callback: setIsFilterShown,
    elements: [
      '.ts-filter-button',
      '.ts-filter',
      '.rc-dropdown',
      '.searchable-select',
    ],
  });

  useFilterModal({
    show: isFilterShown,
  });

  const changeMeta = (key, value) => {
    const currentFilters = [...meta.filters].filter((item) => item.key !== key);

    setMeta({
      ...meta,
      filters:
        value === '' || !!value
          ? [...currentFilters, { key, value }]
          : currentFilters,
      page: 1,
    });
    setIsFilterShown(false);
  };

  const getValue = (filterKey, options) => {
    const metaFiltersSelected = meta?.filters?.find(
      ({ key }) => key === filterKey
    );

    if (metaFiltersSelected) {
      return (
        options?.find(
          (option) => option?.value === metaFiltersSelected?.value
        ) || null
      );
    }

    return null;
  };

  return (
    <>
      <ButtonFilter
        isShown={isFilterShown}
        callback={setIsFilterShown}
        filters={get(meta, 'filters') || []}
      />
      {createPortal(
        <Filter show={isFilterShown}>
          <div className="flex justify-end">
            <button
              data-testid="filter-modal-close-button"
              type="button"
              onClick={() => {
                setIsFilterShown(false);
              }}
            >
              <Icon name="times" />
            </button>
          </div>
          <div className="departments-filters">
            <SelectFilter
              searchable
              id="departments-filter--show-archived"
              label={t('admin.products.active')}
              options={ACTIVE_OPTIONS}
              callback={(selected) => {
                changeMeta('isActive', selected?.value || null);
              }}
              value={getValue('isActive', ACTIVE_OPTIONS)}
            />
          </div>
        </Filter>,
        document.body
      )}
    </>
  );
};

export default ProductFilter;
