export const convertMultipartToJson = async (formData: FormData) => {
  const entriesArray = Array.from(formData.entries());

  const promises = entriesArray.map(([key, value]) => {
    if (value instanceof File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64Content = (event.target.result as string).split(',')[1];
          resolve({ [key]: { filename: value.name, content: base64Content } });
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(value);
      });
    }

    return Promise.resolve({ [key]: value });
  });

  return Promise.all(promises).then((objects) => {
    return Object.assign({}, ...objects);
  });
};
