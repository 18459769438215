import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'reactstrap/lib/Card';
import { get } from 'lodash';
import Icon from '../../../components/@v2/Icon';
import Layout from '../../../components/layouts/default';
import Button from '../../../components/Button';
import { useAPI } from '../../../context/api';
import { log } from '../../../helpers/utils';
import { selectUser } from '../../../store/selectors/user.selector';
import { useSelector as useSelectorToolkit } from '../../../store';
import { selectClient } from '../../../store/selectors/client.selector';

const SchedulerLayout = ({ syncing }) => {
  const { t } = useTranslation();
  const [calendarAPI] = useAPI('calendar');
  const [details, setDetails] = useState(null);
  const { email } = useSelectorToolkit(selectUser);
  const activeClient = useSelectorToolkit(selectClient);
  const [isGettingDetails, setIsGettingDetails] = useState(true);

  const authorizeToNylas = () => {
    try {
      window.openRef = window.open(
        `https://ireland.api.nylas.com/oauth/authorize?client_id=${
          global?.appConfig?.nylas_client_id
        }&redirect_uri=${
          global?.appConfig?.nylas_redirect_uri
        }&response_type=code&scopes=calendar${
          email ? `&login_hint=${email}` : ''
        }`,
        'Nylas',
        'width=550,height=500,toolbar=0,status=0'
      );
    } catch (error) {
      log(error);
    }
  };

  const getDetails = async () => {
    try {
      const response = await calendarAPI.getDetails();
      setDetails(response);
    } catch (error) {
      log(error);
    } finally {
      setIsGettingDetails(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const rightContent = useMemo(() => {
    if (syncing || !get(details, 'accessToken')) return <></>;

    const getColorByStatus = () => {
      const status = (get(details, 'status') || '').toLowerCase();
      if (status === 'connected' || status === 'running') {
        return 'var(--success)';
      }
      return 'var(--danger)';
    };

    return (
      <div className="d-flex align-items-center">
        <div className="flex flex-column align-items-end">
          <div className="flex align-items-center">
            <Icon
              fontSize={18}
              type="fas"
              name={
                getColorByStatus().includes('success')
                  ? 'check-circle'
                  : 'times-circle'
              }
              color={getColorByStatus()}
            />
            <span className="ml-2">{get(details, 'status')}</span>
          </div>
          <span>{email}</span>
        </div>
      </div>
    );
  }, [details]);

  const ref = useRef();
  const [isSendingCode, setIsSendingCode] = useState(false);

  const sendCodeToBackend = async (code) => {
    try {
      setIsSendingCode(true);
      await calendarAPI.authenticateToNylas(code);

      getDetails();
    } catch (error) {
      log(error);
    } finally {
      localStorage.removeItem('ts-scheduler-code');
      setIsSendingCode(false);
    }
  };

  useEffect(() => {
    if (!get(details, 'accessToken')) {
      ref.current = setInterval(() => {
        log('trying to get code...');
        if (localStorage.getItem('ts-scheduler-code')) {
          log('sending it to backend');
          sendCodeToBackend(localStorage.getItem('ts-scheduler-code'));
          clearInterval(ref.current);
        }
      }, 2000);
    } else if (ref.current) clearInterval(ref.current);

    return () => {
      clearInterval(ref.current);
    };
  }, [details]);

  if (isGettingDetails) return null;

  return (
    <Layout
      pretitle={t('general.settings')}
      title={t('pages.scheduler')}
      rightContent={rightContent}
      data-testid="settings-scheduler-page-view-layout"
    >
      <div
        style={{
          height: '72vh',
        }}
      >
        <Card
          className="p-5 anim-table-delayed text-center"
          data-testid="settings-scheduler-page-view-card"
        >
          <p className="ts-card-heading text-primary">
            {t(
              get(details, 'accessToken')
                ? 'settings.scheduler.launch-scheduler.title'
                : 'settings.scheduler.connect-account.title'
            )}
          </p>
          <p className="ts-card-text mt-4 text-primary">
            {t(
              get(details, 'accessToken')
                ? 'settings.scheduler.launch-scheduler.text'
                : 'settings.scheduler.connect-account.text'
            )}
          </p>
          <div className="flex justify-content-center mt-5 mb-3">
            {!!get(details, 'accessToken') && (
              <Button
                round
                color="primary"
                data-testid="settings-scheduler-page-launch-scheduler-button"
                onClick={() => {
                  // eslint-disable-next-line no-undef
                  nylas.scheduler.show({
                    auth: {
                      accessToken: get(details, 'accessToken'),
                    },
                    locale12HourTime: false,
                    behavior: {
                      displayOnly: [
                        'reminders',
                        'event-info',
                        'calendars',
                        'opening-hours',
                      ],
                    },
                    style: {
                      tintColor: '#68B1B6',
                      backgroundColor: 'white',
                    },
                    defaults: {
                      appearance: {
                        logo: get(details, 'schedulingPageLogoUrl'),
                        company_name: get(activeClient, 'name'),
                        show_nylas_branding: false,
                        color: get(details, 'schedulingPageColorHex'),
                        thank_you_redirect: `${global?.appConfig?.api_url}/calendar/interview/thanks`,
                      },
                    },
                  });
                }}
              >
                {t('settings.scheduler.launch-scheduler.button')}
              </Button>
            )}
            {!get(details, 'accessToken') && (
              <Button
                round
                color="primary"
                className="py-3 px-5"
                onClick={() => {
                  authorizeToNylas();
                }}
                loading={isSendingCode}
                data-testid="settings-scheduler-page-connect-account-button"
              >
                {t('settings.scheduler.connect-account.button')}
              </Button>
            )}
          </div>
        </Card>
      </div>
    </Layout>
  );
};

SchedulerLayout.defaultProps = {
  syncing: false,
};

export default SchedulerLayout;
