export const PermissionLevel = {
  0: 'Recruiter',
  1: 'Recruiting Manager',
  2: 'Admin',
};

export const UserFormDefaults = {
  userId: 0,
  loginId: 0,
  firstName: '',
  lastName: '',
  pictureUploaded: false,
  pictureFileExt: '',
  email: '',
  phoneNumber: '',
  superAdmin: false,
  pictureGuid: '',
  lastLogin: new Date(),
  loginCount: 0,
  buildDate: new Date(),
  browserInfo: '',
  clientId: 0,
  auth0UserId: '',
  password: '',
  errorMessage: '',
  created: new Date(),
  deleted: false,
  visible: true,
  signatureHtml: '',
  language: 'en-US',
  clientAccessesJsonString: '',
  permissionLevel: 0,
  seenChangelogVersion: 0,
  intercomUserHash: '',
};

export const UserLanguage = [
  {
    value: 'en-US',
    label: 'English',
  },
  {
    value: 'de-DE',
    label: 'Deutsch',
  },
  {
    value: 'sv-SE',
    label: 'Svenska',
  },
  {
    value: 'da-DK',
    label: 'Dansk',
  },
  {
    value: 'fi-FI',
    label: 'Suomi',
  },
  {
    value: 'nb-NO',
    label: 'Norsk',
  }
  // {
  //   value: 'it-IT',
  //   label: 'Italian',
  // },
];

export default {
  PermissionLevel,
  UserLanguage,
  UserFormDefaults,
};
