import { createAsyncThunk } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { getBrowser } from '../../helpers/utils';
import {
  getUser as getUserRequest,
  setUser as setUserRequest,
  getUserByUserId as getUserByUserIdRequest,
} from '../api/user.api';
import { getClientsConfig } from './client.thunk';

interface SetUserPayload {
  clientId: number;
  browser?: string;
  iss?: string;
}

interface IAvatar {
  fileExt: string;
  focusPointJsonString?: string | null;
  guid: string;
  id: number;
  name: string;
  path: string;
}
interface GetUserResult {
  activeUserId: number;
  auth0UserId: string;
  permissionLevel: number;
  clientId: number;
  companyName: string;
  companyWebsite: string;
  costCenter: string;
  userId: number;
  hasClients: boolean;
  superAdmin: boolean;
  avatar: IAvatar | null;
  email: string;
  firstName: string;
  lastName: string;
  intercomUserHash: string;
  language: string;
  loginId: string;
  phoneNumber: string;
  signatureHtml: string;
}

export const setUser = createAsyncThunk('user/setUser', async ({ clientId }: SetUserPayload) => {
  const data = await setUserRequest({ clientId });
  return data;
});

export const getUser = createAsyncThunk('user/getUser', async (_: void, { dispatch }) => {
  // @ts-ignore
  let data = (await getUserRequest()) as GetUserResult;
  if (!data.activeUserId && data.clientId !== 0) {
    const userData = {
      clientId: data.clientId,
      browser: getBrowser(),
    };

    // @ts-ignore
    data = await dispatch(
      setUser(userData)
    ).unwrap();
  }

  dispatch(getClientsConfig());

  const result = {
    activeUserId: data.activeUserId,
    email: data.email,
    auth0UserId: data.auth0UserId,
    permissionLevel: data.permissionLevel,
    clientId: data.clientId,
    companyName: data.companyName,
    companyWebsite: data.companyWebsite,
    costCenter: data.costCenter,
    userId: data.activeUserId,
    hasClients: data.hasClients,
    superAdmin: data.superAdmin,
    avatar: data.avatar,
    firstName: data.firstName,
    lastName: data.lastName,
    intercomUserHash: data.intercomUserHash,
    language: data.language,
    loginId: data.loginId,
    phoneNumber: data.phoneNumber,
    signatureHtml: data.signatureHtml,
  };

  const tsApiToken = localStorage.getItem('ts-api-token');
  if (tsApiToken) {
    // need to check if SSO type and only the token returned contains value we need
    const decoded: { iss: string } = jwtDecode(tsApiToken);
    if (decoded.iss) {
      // @ts-ignore
      result.iss = decoded.iss;
    }
  }

  return result;
});

export const getUserByUserId = createAsyncThunk('user/getUserByUserId', async (userId: number) => {
  const data = await getUserByUserIdRequest(userId);
  return data;
});
