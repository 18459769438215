import { FieldArray, Formik } from 'formik';
import { sortBy, uniqueId } from 'lodash';
import React, { useRef } from 'react';
import DraggableList from 'react-draggable-list';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { useApiRequestContext } from '../../../../../context/@v2/ApiRequestContext';
import { defaultImageUpload } from '../../../../../helpers/image';
import { addSortOrder } from '../../../../../helpers/utils';
import { onBlockUpdate } from '../../../../../store/api/career-page.api';
import Button from '../../../../Button';
import Icon from '../../../Icon';
import Field from '../../../Form/Field';
import InformationItem from './InformationItem';

const InformationItemsForm = ({ item }) => {
  const { t } = useTranslation();
  const draggableContainer = useRef(null);
  const { data, reloadData } = useApiRequestContext();

  const onSubmit = async (values) => {
    await onBlockUpdate(
      data?.details,
      {
        ...values,
        informationItems: addSortOrder(
          values?.informationItems?.map((item) => {
            if (item?.imageUpload) {
              return {
                ...item,
                image: defaultImageUpload,
              };
            }

            return item;
          })
        ),
      },
      async () => {
        await reloadData();
        data?.renderSuccessNotification();
      }
    );
  };

  return (
    <Formik
      initialValues={{
        ...item,
        informationItems: sortBy(item?.informationItems, 'sortOrder').map(
          (item, index) => ({
            ...item,
            index,
            uuid: uniqueId(),
          })
        ),
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
        const createNewInformationItem = () => {
          return {
            index: values?.informationItems?.length,
            sortOrder: values?.informationItems?.length + 1,
            uuid: uniqueId(),
            image: null,
            imageUpload: false,
            roundImage: false,
            externalLink: false,
            title: '',
            subtitle: '',
            link: '',
            description: '',
            isEditing: true,
          };
        };

        return (
          <form onSubmit={handleSubmit} className="w-full">
            <Field dark name="title" label={t('general.headline')} />
            <div className="flex items-center justify-between !mb-4">
              <Label for="information-items" className="mb-0">
                {t('pages.information-items')}
              </Label>
              <button
                type="button"
                data-testid="add-information-item-button"
                onClick={() => {
                  setFieldValue('informationItems', [
                    ...values?.informationItems?.map((informationItem) => {
                      return {
                        ...informationItem,
                        isEditing: false,
                      };
                    }),
                    createNewInformationItem(),
                  ]);
                }}
              >
                <Icon
                  name="plus"
                  color="var(--text-color-secondary)"
                  className="clickable"
                />
              </button>
            </div>
            <FieldArray
              name="informationItems"
              render={() => (
                <div
                  className="flex flex-col gap-y-4 !mb-3"
                  ref={draggableContainer}
                >
                  <DraggableList
                    itemKey="informationItemId"
                    template={InformationItem}
                    list={values?.informationItems || []}
                    onMoveEnd={(newList) => {
                      const items = addSortOrder(
                        newList.map((item, index) => {
                          return {
                            ...item,
                            index,
                          };
                        })
                      );

                      setFieldValue('informationItems', items);
                    }}
                    container={() => {
                      if (draggableContainer && draggableContainer.current) {
                        return draggableContainer.current;
                      }
                      return false;
                    }}
                    commonProps={{
                      setFieldValue,
                      informationItems: values?.informationItems,
                    }}
                  />
                  {!values?.informationItems?.length && (
                    <p className="text-color-secondary">
                      {t('general.no-items-found')}.
                    </p>
                  )}
                </div>
              )}
            />
            <Button
              type="submit"
              color="primary"
              className="w-full text-white"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="career-page-block-form-submit"
            >
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default InformationItemsForm;
