import i18n from '../i18n';

export const Tags = [
  {
    key: 'fb',
    icon: 'facebook-square',
    name: 'Facebook',
    color: '#3b5998',
  },
  {
    key: 'ig',
    icon: 'instagram',
    name: 'Instagram',
    color: '#55acee',
  },
  {
    key: 'in',
    icon: 'linkedin',
    name: 'LinkedIn',
    color: '#0077b5',
  },
];

export const BoostAutoProduct = {
  title: i18n.t('general.boost-auto'),
  expiration: 14,
  isBoostAuto: true,
  description: i18n.t('general.boost-auto-description'),
  icons: `<p><img src="${global?.appConfig?.public_url}/img/facebook-logo-icon-small.png" /><img src="${global?.appConfig?.public_url}/img/instagram-logo-icon-small.png" /></p>`,
};

export const AmsProductFields = (t) => [
  {
    value: 'amsGroupOptions',
    label: `${t('general.select')} ${t('general.area')}`,
  },
  {
    value: 'amsOccupationOptions',
    label: `${t('general.select')} ${t('general.group')}`,
  },
  {
    value: 'occupationNameId',
    label: `${t('general.select')} ${t('general.function')}`,
  },
];

export default {};
