import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import Modal, { ModalHeader, ModalBody } from '../Modal';
import Form from '../../form/Form';
import { useAPI } from '../../../context/api';
import Icon from '../../@v2/Icon';
import { log } from '../../../helpers/utils';
import { selectCandidates, selectOptions, selectPosition } from '../../../store/selectors/settings.selector';
import { useSelector as useSelectorToolkit } from '../../../store';
import { getAllEmailTemplates } from '../../../store/api/template.api';

const ScheduleInterview = () => {
  const history = useHistory();
  const location = useLocation();
  const { state, search, pathname } = location;
  const queryParams = qs.parse(search);
  const candidates = useSelectorToolkit(selectCandidates);
  const candidatesSelected = get(candidates, 'selectAll') ? get(candidates, 'data') : get(candidates, 'selected');
  const defaultValues = {
    schedule: 0,
    eventType: null,
    candidates: [...candidatesSelected].map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
    })),
    teamMembers: [],
    title: '',
    message: '',
  };
  const { t } = useTranslation();
  const position = useSelectorToolkit(selectPosition);
  const [candidateAPI, calendarAPI] = useAPI('candidate', 'calendar');
  const options = useSelectorToolkit(selectOptions);
  const recruiters = options?.recruiters;

  const closeModal = () => {
    const queryParamsCopy = { ...queryParams };
    Reflect.deleteProperty(queryParamsCopy, 'modal');
    history.push({
      state,
      location: pathname,
      search: qs.stringify(queryParamsCopy),
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    if (isLoading) return;

    const payload = {
      SchedulingPage: get(values, 'eventType.value'),
      CandidateIds: (get(values, 'candidates') || []).map((item) => item.id),
      TeamMemberIds: (get(values, 'teamMembers') || []).map((item) => item.id),
      MessageSubject: values.title,
      MessageBody: values.message,
      PositionId: position.id || position.positionId,
    };

    try {
      setIsLoading(true);
      await calendarAPI.interview(payload);
      closeModal();
    } catch (error) {
      log(error);
    }
  };

  const [defaultTeamMembersOptions, setDefaultTeamMembersOptions] = useState([]);
  const [defaultCandidatesOptions, setDefaultCandidatesOptions] = useState([]);
  const [defaultEventTypeOptions, setDefaultEventTypeOptions] = useState([]);
  const [editorTemplates, setEditorTemplates] = useState([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);

  const getAllTemplates = async () => {
    try {
      const { items } = await getAllEmailTemplates();
      if (Array.isArray(items)) {
        setEditorTemplates(
          items.filter((item) => item.type !== 0).map((item) => ({ ...item, value: item.text, label: item.name }))
        );
      }
    } catch (e) {
      // console.error(e);
    } finally {
      setIsLoadingTemplates(false);
    }
  };

  const getEventType = async () => {
    try {
      const response = await calendarAPI.pages();

      if (response.pages) {
        setDefaultEventTypeOptions(
          response.pages.map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        );
      }
    } catch (error) {
      log(error);
    }
  };
  const getDefaultTeamMembers = async () => {
    try {
      setDefaultTeamMembersOptions(recruiters.filter((item) => item.hasCalendarSynced));
    } catch (error) {
      log(error);
    }
  };

  const getDefaultCandidates = async () => {
    try {
      const { candidates } = await candidateAPI.getList({
        filters: `status==Active,positionId==${position.id || position.positionId}`,
      });

      setDefaultCandidatesOptions(
        candidates.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      log(error);
    }
  };

  useEffect(() => {
    getDefaultTeamMembers();
    getDefaultCandidates();
    getEventType();
    getAllTemplates();
  }, []);

  return (
    <Modal width={600}>
      <ModalHeader>
        <div className="d-flex align-items-center justify-content-between">
          <span>{t('modal.calendar.schedule-interview')}</span>
        </div>
      </ModalHeader>
      <ModalBody>
        <Form
          className="anim-table-delayed"
          maxWidth="none"
          defaultValues={defaultValues}
          enableReinitialize={false}
          onSubmit={onSubmit}
          loading={isLoadingTemplates}
          button={{
            parentClass: 'justify-center gap-x-2',
            label: t('modal.calendar.schedule-interview-button'),
            cancelLabel: t('general.cancel'),
            cancelCallback: () => {
              closeModal();
            },
            cancelProps: {
              color: 'light',
              outline: true,
            },
            submitProps: {
              color: 'primary',
              outline: true,
            },
            loading: isLoading,
          }}
          inputs={[
            {
              type: 'select',
              label: t('general.event-type'),
              key: 'eventType',
              options: defaultEventTypeOptions,
            },
            {
              type: 'component',
              rules: {
                showFn: (form) => form.schedule === 0 && !!form.eventType,
              },
              customComponent: ({ form }) => (
                <>
                  <div className="d-flex align-items-center">
                    <Icon name="eye" className="mr-2" />
                    {t('general.view-page')}
                  </div>
                  <a
                    className="text-secondary mt-2 cursor-pointer"
                    href={get(form, 'eventType.publicUrl')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    /{get(form, 'eventType.slug')}
                  </a>
                </>
              ),
            },
            {
              async: true,
              type: 'select',
              label: t('general.candidates'),
              key: 'candidates',
              callback: (value) =>
                candidateAPI.getList({
                  filters: `name@=${value},status==Active,positionId==${position.id}`,
                }),
              isMulti: true,
              defaultOptions: defaultCandidatesOptions,
              keys: ['id', 'name'],
            },
            {
              async: true,
              type: 'select',
              label: t('positions.team-members'),
              key: 'teamMembers',
              isMulti: true,
              defaultOptions: defaultTeamMembersOptions,
              keys: ['id', 'name'],
            },
            {
              label: t('general.subject'),
              key: 'title',
              placeholder: t('general.subject'),
              rules: {
                required: true,
              },
            },
            {
              type: 'wysiwyg',
              label: t('general.message'),
              placeholder: t('general.message'),
              key: 'message',
              toolbar: 'schedule-interview',
              callback: ({ selected, setForm, form }) => {
                const foundTemplate = editorTemplates.find((item) => item.value === selected);

                if (foundTemplate) {
                  setForm({
                    ...form,
                    title: foundTemplate.subject,
                  });
                }
              },
              options: {
                listItems: editorTemplates,
              },
              acceptValueFromApi: true,
              hide: !editorTemplates.length,
            },
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

export default ScheduleInterview;
