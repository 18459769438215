/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const RadioButton = ({
  id,
  onChange,
  value,
  isSelected,
  label,
  className,
  name,
  ...rest
}) => (
  <div
    className={classnames('radio-button d-flex align-items-center', className)}
    data-testid={`${value}-radio-button-component`}
  >
    <input
      id={id || value}
      onChange={onChange}
      value={value}
      checked={isSelected}
      name={name || null}
      {...rest}
      type="radio"
    />
    <label
      htmlFor={id || value}
      className="clickable"
      style={{ lineHeight: '21px' }}
    >
      {label}
    </label>
  </div>
);

RadioButton.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
};

RadioButton.defaultProps = {
  id: '',
  className: '',
  name: '',
};

export default RadioButton;
