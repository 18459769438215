export const DEFAULT_FONT_FAMILY = 'Oswald';

export const fontSuggestions = ['Adamina', 'Alegreya Sans', 'Arvo', 'Lato', 'Libre Baskerville', 'Merriweather', 'Nunito', 'Open Sans', 'Oswald', 'Playfair Display', 'Roboto', 'Source Sans Pro', 'Varela Round'];

export const fontWeightOptions = [
  {
    label: '100 (Thin)',
    value: 100,
  },
  {
    label: '200 (Extra Light)',
    value: 200,
  },
  {
    label: '300 (Light)',
    value: 300,
  },
  {
    label: '400 (Normal)',
    value: 400,
  },
  {
    label: '500 (Medium)',
    value: 500,
  },
  {
    label: '600 (Semi Bold)',
    value: 600,
  },
  {
    label: '700 (Bold)',
    value: 700,
  },
  {
    label: '800 (Extra Bold)',
    value: 800,
  },
  {
    label: '900 (Black)',
    value: 900,
  },
];

export const fontSizeOptions = [
  {
    label: '8px',
    value: '8px',
  },
  {
    label: '9px',
    value: '9px',
  },
  {
    label: '10px',
    value: '10px',
  },
  {
    label: '11px',
    value: '11px',
  },
  {
    label: '12px',
    value: '12px',
  },
  {
    label: '14px',
    value: '14px',
  },
  {
    label: '16px',
    value: '16px',
  },
  {
    label: '18px',
    value: '18px',
  },
  {
    label: '20px',
    value: '20px',
  },
  {
    label: '22px',
    value: '22px',
  },
  {
    label: '24px',
    value: '24px',
  },
  {
    label: '26px',
    value: '26px',
  },
  {
    label: '28px',
    value: '28px',
  },
  {
    label: '32px',
    value: '32px',
  },
  {
    label: '36px',
    value: '36px',
  },
  {
    label: '40px',
    value: '40px',
  },
  {
    label: '48px',
    value: '48px',
  },
  {
    label: '72px',
    value: '72px',
  },
];
