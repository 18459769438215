import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink } from 'reactstrap';
import Layout from '../../../../components/layouts/default';
import AccountDetailsForm from './AccountDetailsForm';
import PasswordForm from './PasswordForm';
import '../../../../assets/sass/setting-form.sass';

const tabs = [
  {
    label: 'account-details',
    value: 0,
  },
  {
    label: 'password',
    value: 1,
  },
];

const Profile = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  return (
    <Layout pretitle={t('general.overview')} title={t('general.your-profile')}>
      <div className="user-profile-form anim-table-delayed">
        <div className="!mb-6">
          <Nav tabs>
            {tabs.map((item) => (
              <NavItem key={item}>
                <NavLink
                  className={classNames({
                    active: item.value === activeTab,
                  })}
                  onClick={() => {
                    setActiveTab(item.value);
                  }}
                >
                  {t(`general.${item.label}`)}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
        {activeTab === 0 && <AccountDetailsForm />}
        {activeTab === 1 && <PasswordForm />}
      </div>
    </Layout>
  );
};

export default Profile;
