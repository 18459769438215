import Axios from 'axios';
import { Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import Field from '../../../components/@v2/Form/Field';
import Button from '../../../components/Button';
import Layout from '../../../components/layouts/default';
import ApiRequestProvider, { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';
import { useAPI } from '../../../context/api';
import { capitalizeFirstLetter } from '../../../helpers/utils';
import { createOrUpdateCandidate } from '../../../store/api/candidates.api';
import { getPositionListV2 } from '../../../store/api/positions.api';

const CandidatesForm = () => {
  const { t } = useTranslation();
  const { loading, data } = useApiRequestContext();
  const history = useHistory();
  const { type } = useParams();
  const { search } = useLocation();
  const positionId = new URLSearchParams(search).get('positionId');

  const SampleLinkedInURL = 'e.g., https://www.linkedin.com/in/name';

  const CandidatesFormSchema = Yup.object().shape({
    firstName: Yup.string().required(t('form-validator.required')),
    lastName: Yup.string().required(t('form-validator.required')),
    email: Yup.string().email(t('form-validator.email')).required(t('form-validator.required')),
    linkedInProfileUrl: Yup.string()
      .matches(
        /https?:\/\/(?:www\.)?linkedin\.com\/in\/\w+/i, // any string after /in/ is accepted
        { message: `${t('form-validator.url')}, ${SampleLinkedInURL} `, excludeEmptyString: true }
      )
      .nullable(),
  });

  const onSubmit = async (values) => {
    try {
      const data = await createOrUpdateCandidate(values);

      if (data) {
        history.push('/candidates');
      }
    } catch (error) {
      throw error;
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Layout testId="actionCandidate" title={`${capitalizeFirstLetter(type || '')} Candidate`} rightContent={<></>}>
      <Formik initialValues={data} validationSchema={CandidatesFormSchema} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="anim-table-delayed max-w-4xl w-full shadow-sm p-4 bg-white rounded-lg"
            >
              <div className="flex items-start gap-x-4">
                <Field type="text" name="firstName" label={t('general.first-name')} required />
                <Field type="text" name="lastName" label={t('general.last-name')} required />
              </div>
              <div className="flex items-start gap-x-4">
                <Field type="email" name="email" label={t('general.email')} required />
                <Field type="text" name="linkedInProfileUrl" label={t('candidates.linkedin-profile')} />
              </div>
              <div className="flex items-start gap-x-4">
                <Field type="date" name="dateOfBirth" label={t('general.date-of-birth')} placeholder="YYYY-MM-DD" />
                <Field type="tel" name="phoneNumber" label={t('general.phone-number')} />
              </div>
              <Field type="wysiwyg" name="motivation" label={t('candidates.application-motivation')} />
              <Field
                preload
                type="select"
                name="position"
                initialValue={positionId && positionId !== 'undefined' ? positionId : data?.defaultPosition}
                label={t('general.position')}
                keys={['id', 'name']}
                searchKey="name"
                api={(meta) => {
                  return getPositionListV2({
                    filter: {
                      draft: false,
                      archived: false,
                      ...meta.filtersV2,
                    },
                    page: {
                      number: meta.page,
                      size: meta.pageSize,
                    },
                    sort: {},
                  });
                }}
                defaultFilters="draft==false,archived==false"
              />
              <Button type="submit" color="primary" disabled={isSubmitting} loading={isSubmitting}>
                {t('general.submit')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

const CandidatesFormWrapper = () => {
  const { type, id } = useParams();
  const [candidateAPI] = useAPI('candidate');
  const { search } = useLocation();
  const positionId = new URLSearchParams(search).get('positionId');

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, token) => {
        const { items } = await getPositionListV2({
          filter: {
            draft: false,
            archived: false,
            ...meta.filtersV2,
          },
          page: {
            number: meta.page,
            size: meta.pageSize,
          },
          sort: {},
        });
        if (type === 'add' && items) {
          let foundPosition = null;
          if (positionId && positionId !== 'undefined') {
            foundPosition = items.find((item) => item.id.toString() === positionId);
          }
          return { position: foundPosition || items[0], defaultPosition: items[0]?.id.toString() };
        }
        if (type === 'edit' && id) {
          const [candidate, screening] = await Axios.all([
            candidateAPI.find(id, token),
            candidateAPI.getScreenings(id),
          ]);

          return {
            ...candidate,
            candidateId: candidate.id,
            position: {
              id: candidate.positionId,
              name: candidate.positionName,
            },
            motivation: screening.motivation,
            dateOfBirth: candidate.dateOfBirth ? moment(candidate.dateOfBirth).format('YYYY-MM-DD') : '',
          };
        }

        return {};
      }}
    >
      <CandidatesForm />
    </ApiRequestProvider>
  );
};

export default CandidatesFormWrapper;
