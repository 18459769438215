import qs from 'query-string';
import helper, { api } from '../helpers/api';

const resource = 'users';

export default {
  ...helper(resource, 'restful'),
  changePassword: (auth = false, data) =>
    api[auth ? 'put' : 'post'](`${resource}/password`, data),
  getAdminList: (queryParams = {}, options = {}) =>
    api.get(`${resource}/login?${qs.stringify(queryParams)}`, options),
  getCurrentUser: () => api.get(`${resource}/active`),
  setActiveUser: (form, options = {}) =>
    api.post(`${resource}/active`, form, options),
  getUser: (id) => api.get(`${resource}/${id}`),
  getAdminUser: (id) => api.get(`${resource}/login/${id}`),
  addAdminUser: (form, options = {}) => api.post(`${resource}/`, form, options),
  updateAdminUser: (form, options = {}) =>
    api.put(`${resource}/login/${form.loginId}`, form, options),
  addUser: (form, options = {}) => api.post(`${resource}`, form, options),
  checkEmail: (email, options = {}) =>
    api.post(`${resource}/exists`, { email }, options),
  sendWelcomeEmail: (id, options = {}) =>
    api.post(`${resource}/login/${id}/send-welcome-email`, options),
  updateUser: (form, options = {}) =>
    api.put(`${resource}/${form.userId}`, form, options),
  deleteUser: (form) => api.delete(`${resource}/by-client`, form),
  uploadPicture: (userId, form, options = {}) =>
    api.post(`${resource}/${userId}/picture`, form, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary',
      },
    }),
  deletePicture: (userId) => api.delete(`${resource}/${userId}/picture`),
  resetPassword: (email) =>
    api.post(`${resource}/password/send-token`, { emailAddress: email }),
  changePasswordToken: (payload) => api.post(`${resource}/password`, payload),
  checkPasswordToken: (pwdToken) => api.post(`${resource}/password/check-token`, { token: pwdToken }),
  checkForDelete: (id) => api.get(`${resource}/${id}/check-for-delete`),
  softDelete: (id) => api.delete(`${resource}/login/${id}`),
  restore: (id) => api.post(`${resource}/${id}/restore`),
};
