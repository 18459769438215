import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputRange from 'react-input-range';
import { CreateIntegrationsHrsOptions } from '../../../constants/ClientIntegration';
import Icon from '../Icon';
import Divider from '../Divider/Divider';
import Field from '../Form/Field';

const Item = (props) => {
  const {
    defaultOpen,
    testName,
    setFieldValue,
    index,
    inputData,
    yellowScoreLowerBoundary,
    greenScoreLowerBoundary,
    isDisabled,
  } = props;
  const { t } = useTranslation();
  const [defaultIsActive] = useState(inputData.isActive);
  const [isShown, setIsShown] = useState(defaultOpen);
  const [sliderValue, setSliderValue] = useState({
    min: yellowScoreLowerBoundary,
    max: greenScoreLowerBoundary,
  });
  const HRS_OPTIONS = CreateIntegrationsHrsOptions(t);

  const onToggle = useCallback(() => {
    setIsShown(!isShown);
  }, [isShown]);

  return (
    <div className="bg-gray-100 mb-3 p-3 rounded-xl">
      <div className={classNames('flex items-center justify-between', isDisabled ? 'text-gray-400' : 'faded-gray-100')}>
        {testName}
        <div className="flex items-center justify-between">
          <div className="flex items-center w-[70px]">
            <span
              className={classNames(
                'h-[7px] w-[7px] rounded-full mr-2',
                defaultIsActive ? 'bg-green-500' : 'bg-gray-500'
              )}
            />
            <span className={classNames('text-sm mb-0', isDisabled ? 'text-gray-400' : 'faded-gray-100')}>
              {defaultIsActive ? t('general.active') : t('general.disabled')}
            </span>
          </div>
          {inputData.isCollapsible && (
            <button
              type="button"
              className="transition h-[35px] w-[35px] leading-[35px] bg-gray-200 hover:bg-gray-300 rounded-full ml-4 grid place-content-center"
              onClick={onToggle}
              disabled={isDisabled}
            >
              <Icon name={isShown ? 'chevron-up' : 'chevron-down'} />
            </button>
          )}
        </div>
      </div>
      {isShown && (
        <div className="form mt-4">
          <div className="pr-2 pl-2 mb-2 flex items-center justify-between relative">
            <div
              style={{
                width: `${sliderValue.min * 10}%`,
                height: 5,
                background: '#FF4365',
                zIndex: 40,
                transition: 'width 0.25s linear',
              }}
              className="relative"
            />
            <div
              className="absolute w-100"
              style={{
                marginLeft: -5,
                marginRight: -5,
              }}
            >
              <InputRange
                maxValue={10}
                minValue={0}
                value={sliderValue}
                onChange={(value) => {
                  setSliderValue(value);
                  if (setFieldValue) {
                    setFieldValue(`tests[${index}].yellowScoreLowerBoundary`, value?.min || 0);
                    setFieldValue(`tests[${index}].greenScoreLowerBoundary`, value?.max || 10);
                  }
                }}
              />
            </div>
            <div
              style={{
                width: `${(10 - sliderValue.max) * 10}%`,
                height: 5,
                background: '#7ED58F',
                transition: 'width 0.25s linear',
              }}
              className="z-40 relative"
            />
          </div>
          <div className="mb-2 flex items-center justify-between mt-3">
            <p
              style={{
                width: `${sliderValue.min * 10}%`,
                right: sliderValue.min === 0 ? 5 : -5,
                transition: 'width 0.25s linear',
              }}
              className="text-right relative text-primary font-semibold"
            >
              {sliderValue.min}
            </p>
            <p
              style={{
                width: `${(10 - sliderValue.max) * 10}%`,
                left: sliderValue.max === 10 ? -10 : -5,
                transition: 'width 0.25s linear',
              }}
              className="relative text-primary font-semibold whitespace-nowrap"
            >
              {sliderValue.max}
            </p>
          </div>
          <Divider>{t('settings.integrations.acceptance-threshold')}</Divider>
          <Field name={`tests[${index}].isActive`} label={t('general.active')} type="switch" />
          <Field
            name={`tests[${index}].autoMoveGreenCandidatesToNextStep`}
            label={t('settings.tests.move-automatically-for-green')}
            type="switch"
          />
          <Field
            name={`tests[${index}].autoMoveYellowCandidatesToNextStep`}
            label={t('settings.tests.move-automatically-for-yellow')}
            type="switch"
          />
          <Field
            name={`tests[${index}].autoRejectRedCandidates`}
            label={t('settings.tests.reject-automatically-for-red')}
            type="switch"
          />
          <Field
            name={`tests[${index}].reminderDelayHours`}
            type="select"
            label={t('settings.integrations.remind-email-candidate-after')}
            defaultOptions={HRS_OPTIONS}
          />
        </div>
      )}
    </div>
  );
};

export default Item;
