import React from 'react';
import classNames from 'classnames';

const Card = ({ children, className, containerClassName, ...rest }) => (
  <div className={classNames(containerClassName, 'h-full flex w-full')}>
    <div
      {...rest}
      className={classNames(
        className,
        '!bg-white rounded-xl w-full h-full',
        !className?.includes('shadow') && 'shadow-xl'
      )}
    >
      {children}
    </div>
  </div>
);

Card.defaultProps = {
  className: 'p-8',
  containerClassName: 'mx-4',
};

export default Card;
