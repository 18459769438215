import React, { useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import Modal, { ModalHeader, ModalBody } from '../Modal';
import Form from '../../form/Form';
import CustomButton from '../../Button';
import { useAPI } from '../../../context/api';
import { setFiltersWithKey, setActivePosition } from '../../../store/slices/settings.slice';
import { log } from '../../../helpers/utils';
import { selectUser } from '../../../store/selectors/user.selector';
import { selectClient } from '../../../store/selectors/client.selector';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../store';
import { getPositionListV2 } from '../../../store/api/positions.api';

const DRAFT_POSITION_STATUS = 3;

const Move = () => {
  const history = useHistory();
  const location = useLocation();
  const { state, search, pathname } = location;
  const queryParams = qs.parse(search);
  const [sending, setSending] = useState(false);
  const positionOptions = useSelectorToolkit(({ settings }) => get(settings, 'options.positions') || []);
  const candidate = useSelectorToolkit(({ settings }) => get(settings, 'candidates.active'));
  const candidateFilters = useSelectorToolkit(({ settings }) => get(settings, 'filters.candidates'));
  const defaultValues = useMemo(
    () => ({
      selectedPosition: positionOptions.find((current) => current.id === get(candidateFilters, 'positionId')) || null,
    }),
    []
  );
  const [candidateAPI, positionAPI, mailAPI] = useAPI('candidate', 'position', 'mail');
  const { t } = useTranslation();
  const currentUser = useSelectorToolkit(selectUser);
  const currentAccount = useSelectorToolkit(selectClient);

  const closeModal = (params = {}) => {
    const queryParamsCopy = { ...queryParams };
    Reflect.deleteProperty(queryParamsCopy, 'modal');
    history.push({
      state: {
        ...state,
        filters: {
          ...state.filters,
          ...params,
        },
      },
      location: pathname,
      search: qs.stringify(queryParamsCopy),
    });
  };

  const onMove = async ({ selectedPosition, notifyRecruiter }) => {
    if (sending && !selectedPosition) return;

    try {
      setSending(true);

      await candidateAPI.moveToPosition({
        candidateId: candidate.id,
        positionId: selectedPosition.value,
      });

      const positionPipelines = await positionAPI.getPipelineSteps(selectedPosition.value);
      const firstPipeLineStepId = positionPipelines[0].id;

      if (notifyRecruiter) {
        const { recruiter } = selectedPosition;
        const senderName = `${currentUser.firstName} ${currentUser.lastName}`;
        const senderMail = currentUser.email;
        await mailAPI.sendEmail({
          subject: `${t('modal.candidates.send-email-to-recruiter')} (${currentAccount.name})`,
          text: t('email.moved-candidate-to', {
            recipient: recruiter.name,
            candidateName: candidate.name,
            candidatePath: `${window.location.origin}/candidates/view/${candidate.id}`,
            account: currentAccount.name,
            senderName,
            senderMail,
          }),
          recipient: recruiter.email,
          senderName,
          senderMail,
        });
      }

      closeModal({
        pipelineStepId: firstPipeLineStepId,
        positionId: selectedPosition.value,
      });

      await dispatchToolkit(
        setFiltersWithKey({
          positionId: selectedPosition.value,
          pipelineStepId: firstPipeLineStepId || null,
        })
      );

      await dispatchToolkit(
        setActivePosition({
          ...selectedPosition,
          pipelines: positionPipelines || [],
          activePipelineStepId: firstPipeLineStepId || null,
        })
      );

      history.push('/candidates');
    } catch (error) {
      log(error);
      closeModal();
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal width={600}>
      <ModalHeader>{t('modal.candidates.move-candidate-header')}</ModalHeader>
      <ModalBody>
        <Form
          className="anim-table-delayed"
          maxWidth="none"
          defaultValues={defaultValues}
          onSubmit={onMove}
          hasButton={false}
          inputs={[
            {
              key: 'selectedPosition',
              label: t('modal.candidates.position'),
              type: 'select',
              async: true,
              keys: ['id', 'position'],
              defaultOptions: positionOptions.filter((position) => position.status !== DRAFT_POSITION_STATUS),
              callback: (name) => getPositionListV2({ status: 0, name }),
              isLoading: !positionOptions.length,
            },
            {
              key: 'notifyRecruiter',
              type: 'switch',
              label: t('modal.candidates.send-email-to-recruiter'),
              cols: {
                md: 6,
                className: 'd-flex align-items-end',
              },
            },
            {
              type: 'component',
              customComponent: ({ form }) => (
                <div className="d-flex align-items-center justify-content-center">
                  <CustomButton
                    color="primary"
                    outline
                    style={{ marginRight: 5 }}
                    loading={sending}
                    type="submit"
                    onClick={() => onMove({ ...form })}
                  >
                    {t('modal.candidates.update-candidate')}
                  </CustomButton>
                  <CustomButton
                    type="button"
                    outline
                    color="light"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    {t('modal.candidates.cancel')}
                  </CustomButton>
                </div>
              ),
            },
          ]}
        />
      </ModalBody>
    </Modal>
  );
};

export default Move;
