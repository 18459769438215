import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { get } from 'lodash';
import { format } from '../../../helpers/date';
import useAssessment from '../../../hooks/useAssessment';
import Loader from '../../Loader';
import { useApiRequestContext } from '../../../context/@v2/ApiRequestContext';

const Assessio = () => {
  const { t } = useTranslation();
  const { convertFromIdToName } = useAssessment();
  const apiRequestContext = useApiRequestContext();
  const { data, loading } = apiRequestContext;
  const results = data?.testResults?.map((item) => ({
    ...item,
    type: convertFromIdToName(item.type),
  }));

  const getScoreState = (state) => {
    if (state === 5) return 'text-red-500';
    if (state === 6) return 'text-yellow-500';
    if (state === 7) return 'text-green-500';
    return 'text-primary';
  };

  const getData = (payload) => {
    if (get(payload, 'completionDate')) {
      return {
        date: get(payload, 'completionDate'),
        tr: 'candidates.tests.completed',
      };
    }

    if (
      get(payload, 'lastReminderDate') &&
      get(payload, 'numberOfSentReminders') === 1
    ) {
      return {
        date: get(payload, 'initialSendDate'),
        tr: 'candidates.tests.sent',
        multipleSentReminders: true,
      };
    }

    return {
      date: get(payload, 'initialSendDate'),
      tr: 'candidates.tests.sent',
    };
  };

  const getLabel = (state) => {
    if (state === 1) return t('candidates.tests.pending-state');

    return t('candidates.tests.timed-out-state');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-24 relative mt-4">
        <Loader size={50} />
      </div>
    );
  }

  if (!loading && !results?.length) {
    return (
      <p className="no-results-text mt-4 mb-0">
        {t('candidates.tests.no-tests-yet')}
      </p>
    );
  }

  return (
    <div
      className="mt-4 grid"
      style={{
        gridTemplateColumns: 'repeat(auto-fill, 220px)',
        gridGap: 20,
      }}
    >
      {!loading &&
        results.map(({ type, id, score, state, ...rest }, index) => {
          const { date, tr, multipleSentReminders } = getData(rest);
          const hasScore = state !== 1 && state !== 4;

          return (
            <div
              className="flex items-center justify-center p-4 shadow-sm border border-gray-100 flex-column"
              key={id}
              data-testid={`candidates-test-${index}`}
            >
              <div className="flex items-center flex-wrap">
                <ul className="w-full">
                  <li className="text-gray-400 text-sm mb-2">Test</li>
                  <li className="text-primary font-semibold">{type}</li>
                </ul>
                <ul className="w-full">
                  <li className="text-gray-400 text-sm mb-2">Score</li>
                  <li
                    className={classNames(
                      'font-semibold',
                      getScoreState(state) || 'text-primary',
                      hasScore && 'text-3xl'
                    )}
                    style={{
                      height: hasScore && 45,
                    }}
                  >
                    {hasScore ? score : getLabel(state)}
                  </li>
                </ul>
                <ul
                  className={classNames(
                    'w-full',
                    !multipleSentReminders && 'mb-0'
                  )}
                >
                  <li className="text-gray-400 text-sm mb-2">Date {t(tr)}</li>
                  <li className="text-primary font-semibold">
                    {format('date', date)}
                  </li>
                </ul>
                {multipleSentReminders && (
                  <ul className="w-full mb-0">
                    <li className="text-gray-400 text-sm mb-2">
                      {t('candidates.tests-reminder-sent')}
                    </li>
                    <li className="text-primary font-semibold">
                      {get(rest, 'numberOfSentReminders') > 1 &&
                        `(${get(rest, 'numberOfSentReminders')})`}
                      : {format('date', get(rest.lastReminderDate))}
                    </li>
                  </ul>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Assessio;
