import React from 'react';
import DatePickerComponent from 'react-datepicker';
import '../../assets/sass/date-picker.sass';
import classnames from 'classnames';
import { defaultDatePickerFormat } from '../../helpers/date';
import Icon from '../@v2/Icon';

const DatePicker = ({
  date,
  onChange,
  format,
  invalid,
  className,
  testId,
  ...rest
}) => {
  return (
    <div
      className="datepicker-container relative"
      data-testid={testId || 'datepicker'}
    >
      <div className="absolute top-1/2 -translate-y-1/2 left-3 z-50">
        <Icon name="calendar" />
      </div>
      <DatePickerComponent
        dateFormat={format}
        selected={date}
        onChange={onChange}
        className={classnames(
          className,
          { 'is-invalid form-control': invalid },
          'pl-5 rounded-lg'
        )}
        {...rest}
      />
    </div>
  );
};

DatePicker.defaultProps = {
  format: defaultDatePickerFormat,
  invalid: false,
  className: '',
};

export default DatePicker;
