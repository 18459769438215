import React, { useState, useEffect, useMemo } from 'react';
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { QueryBuilderStyled } from './QueryBuilder.styled';
import { config } from './QueryBuilderConfig';
import { useSelector as useSelectorToolkit } from '../../store';
import {
  selectCompanyIndustries,
  selectJobFunctions,
} from '../../store/selectors/settings.selector';
import 'react-awesome-query-builder/lib/css/styles.css';
import { log } from '../../helpers/utils';

const emptyQueryValue = { id: QbUtils.uuid(), type: 'group' };

const QueryBuilder = ({ queryValue, setQuery }) => {
  const { t } = useTranslation();
  const [treeJSON, setTreeJSON] = useState(null);
  const [treeConfig, setTreeConfig] = useState(config);
  const [resultingJSON, setResultingJSON] = useState(null);

  const companyIndustries = useSelectorToolkit(selectCompanyIndustries);
  const jobFunctions = useSelectorToolkit(selectJobFunctions);

  const jobFunctionsOptions = useMemo(() => {
    const mappedOptions = jobFunctions.map((code) => ({
      title: t(`jobFunction.${code}`),
      value: code,
    }));
    return orderBy(mappedOptions, 'title');
  }, [t]);

  const industryOptions = useMemo(() => {
    const mappedOptions = companyIndustries.map(({ label, value }) => ({
      title: label,
      value: value.toString(),
    }));
    return orderBy(mappedOptions, 'title');
  }, [t]);

  const replaceAllText = (jsonString) => {
    if (!jsonString) return '';
    return jsonString.replace(/"all"/g, '"some"');
  };

  const formatJsonlogic = (val) => {
    const formattedVal = Array.isArray(val)
      ? val.map((value) => {
          if (value.in) {
            return value;
          }
          return { in: [value, { var: '' }] };
        })
      : { in: [val, { var: '' }] };
    return formattedVal;
  };

  useEffect(() => {
    const updatedConfig = {
      ...treeConfig,
      fields: {
        ...treeConfig.fields,
        jobfunction: {
          label: t('general.job-function'),
          type: 'select',
          excludeOperators: [
            'select_not_equals',
            'select_any_in',
            'select_not_any_in',
          ],
          fieldSettings: {
            listValues: jobFunctionsOptions,
            allowCustomValues: true,
          },
        },
        industries: {
          label: t('edit-client.company-industry'),
          type: 'multiselect',
          excludeOperators: ['multiselect_not_equals'],
          fieldSettings: {
            listValues: industryOptions,
            allowCustomValues: true,
            jsonLogic: (val) => formatJsonlogic(val),
          },
        },
      },
    };
    setTreeJSON(
      QbUtils.checkTree(QbUtils.loadTree(emptyQueryValue), updatedConfig)
    );
    setTreeConfig(updatedConfig);
  }, []);

  const getDefaultValues = (query, config) => {
    const initLogic =
      query && Object.keys(query).length > 0 ? query : undefined;
    const initTree = QbUtils.checkTree(
      QbUtils.loadFromJsonLogic(initLogic, config),
      config
    );
    return initTree;
  };

  useEffect(() => {
    try {
      if (queryValue) {
        const existingTreeJSON = getDefaultValues(
          JSON.parse(queryValue),
          treeConfig
        );
        setTreeJSON(existingTreeJSON);
        setResultingJSON(JSON.parse(queryValue));
      }
    } catch (error) {
      log(error);
    }
  }, [queryValue, treeConfig]);

  const renderBuilder = (props) => (
    <div className="query-builder">
      <Builder {...props} />
    </div>
  );

  return (
    <QueryBuilderStyled>
      <Query
        {...treeConfig}
        renderBuilder={renderBuilder}
        value={treeJSON}
        onChange={(tree, config) => {
          const { logic } = QbUtils.jsonLogicFormat(tree, config);
          if (logic) {
            setResultingJSON(logic);
            if (setQuery) {
              const stringified = JSON.stringify(logic);
              setQuery(stringified);
            }
          }
          setTreeJSON(tree);
        }}
      />
      {resultingJSON && (
        <div>
          {`JsonLogic ${t('general.preview')}`}{' '}
          <pre className="query-preview">
            {replaceAllText(JSON.stringify(resultingJSON, null, 2))}
          </pre>
        </div>
      )}
    </QueryBuilderStyled>
  );
};

export default QueryBuilder;
