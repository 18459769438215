import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import Field from '../../../../components/@v2/Form/Field';
import Button from '../../../../components/Button';
import { updatePassword } from '../../../../store/api/user.api';
import { useSelector as useSelectorToolkit } from '../../../../store';
import { selectUser } from '../../../../store/selectors/user.selector';

YupPassword(Yup);

const PasswordForm = () => {
  const { t } = useTranslation();
  const currentUser = useSelectorToolkit(selectUser);
  const INITIAL_VALUES = {
    password: '',
    confirmPassword: '',
  };

  const PasswordFormSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('form-validator.required'))
      .min(8, t('form-validator.minlength', { length: 8 }))
      .minLowercase(1, t('form-validator.with-lowercase'))
      .minUppercase(1, t('form-validator.with-uppercase'))
      .minNumbers(1, t('form-validator.with-number')),
    confirmPassword: Yup.string()
      .required(t('form-validator.required'))
      .oneOf([Yup.ref('password'), null], t('form-validator.same-password')),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      await updatePassword({
        auth0UserId: currentUser?.auth0UserId,
        newPassword: values?.password,
      });
      resetForm(INITIAL_VALUES);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={PasswordFormSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className="anim-table-delayed max-w-xl w-full shadow-sm p-4 bg-white rounded-lg"
          >
            <Field
              required
              type="password"
              name="password"
              placeholder={t('general.password')}
              label={t('general.password')}
            />
            <Field
              required
              type="password"
              name="confirmPassword"
              placeholder={t('general.confirm-password')}
              label={t('general.confirm-password')}
            />
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default PasswordForm;
