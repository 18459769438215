import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { getUser } from '../thunks/user.thunks';
import { UserData } from '../models/user.models';

export interface InitialState {
  userData: UserData;
  loading: boolean;
  error: string | null;
}

export const initialState: InitialState = {
  userData: {
    auth0UserId: '',
    superAdmin: false,
    loginId: 0,
    clientId: 0,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    language: '',
    costCenter: null,
    signatureHtml: '',
    avatar: null,
    hasClients: false,
    permissionLevel: 0,
    activeUserId: 0,
    intercomUserHash: '',
    createdAt: '',
    companyName: '',
    companyWebsite: '',
    userId: 0,
    authenticated: false,
    locale: 'en',
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, { payload }) => {
      return { ...state, userData: payload };
    },
    changeLocale: (state, { payload }) => {
      return { ...state, userData: { ...state.userData, locale: payload } };
    },
    setUserClientId: (state, { payload }) => {
      return {
        ...state,
        userData: {
          ...state.userData,
          clientId: payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    // getUser
    builder
      .addCase(getUser.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          userData: { ...state.userData, ...payload, authenticated: true },
        };
      })
      .addCase(getUser.rejected, (state, { payload }) => {
        const error = get(payload, 'error_description');
        return {
          ...state,
          loading: false,
          error: error ? error as string : null,
        };
      });
  },
});

export const { setUserData, setUserClientId, changeLocale } = slice.actions;
export default slice.reducer;
