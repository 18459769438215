import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Jumbotron } from 'reactstrap';
import uuid from 'uuid';
import { useAPI } from '../../context/api';
import ScreeningQuestionsType from '../../constants/ScreeningQuestionsType';
import '../../assets/sass/candidates/screening.sass';

function mergeMultiselectionObjects(objects) {
  const mergedObjects = {};

  objects.forEach((obj) => {
    if (obj.type === 'multiselections') {
      const key = obj.question;

      if (mergedObjects[key]) {
        mergedObjects[key].answer += `,${obj.answer}`;
      } else {
        mergedObjects[key] = { ...obj };
      }
    } else {
      mergedObjects[obj.id] = { ...obj };
    }
  });

  return Object.values(mergedObjects);
}

const Screening = ({ activeItem }) => {
  const { t } = useTranslation();
  const [screenings, setScreenings] = useState([]);
  const [motivation, setMotivation] = useState('');
  const [candidateAPI] = useAPI('candidate');

  const getUserCandidates = async () => {
    try {
      if (activeItem.id || activeItem.candidateId) {
        const { motivation, answers } = await candidateAPI.getScreenings(activeItem.id || activeItem.candidateId);

        setScreenings(mergeMultiselectionObjects(answers));
        setMotivation(motivation);
      }
    } catch (e) {
      // console.error(e);
    }
  };

  useEffect(() => {
    getUserCandidates();
  }, [activeItem]);

  return (
    <div className="screening">
      {!!(motivation && motivation !== 'undefined') && (
        <Jumbotron>
          <div className="screening-motivation" data-testid="candidates-motivation">
            <h2>{t('general.motivation')}</h2>
            <div dangerouslySetInnerHTML={{ __html: motivation }} className="screening-motivation-content" />
          </div>
        </Jumbotron>
      )}
      <div className="screening-table">
        <div className="screening-table-body">
          {screenings.map((screening, index) => (
            <div key={uuid.v4()} className="mt-4">
              <p data-testid={`candidates-screening-question-${index}`} className="font-weight-bold">
                {screening.question}
              </p>
              {screening.type === ScreeningQuestionsType.VIDEO_TYPE ? (
                <video controls width={480} height={240} data-testid={`candidates-screening-video-answer-${index}`}>
                  <source src={screening.answer} type="video/mp4" />
                </video>
              ) : (
                <p data-testid={`candidates-screening-answer-${index}`}>
                  {screening.answer || t('candidates.screening.no-answer')}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Screening;
