import { AxiosResponse } from 'axios';
import { api } from '../../helpers/api-v2';

interface ConfigResponse {
  payload: {
    applyFormSettings: {
      phoneNumber: number;
      birthDate: number;
      motivation: number;
      cv: number;
      showLinkedInButton: boolean;
      showImage: boolean;
    };
  };
  errors: null | string;
}

const resource = '/config';

export const getConfig = async () => {
  const config = await api.get(`${resource}`);

  return config as AxiosResponse<ConfigResponse>;
};
