import { get } from 'lodash';
import { auth0Api } from '../../helpers/api-v2';

interface LoginResponse {
  access_token: string;
  refresh_token: string;
  id_token: string;
  scope: string;
  expires_in: number;
  token_type: string;
}

const auth = async (username: string, password: string) => {
  try {
    const { data } = await auth0Api.post('', {
      username,
      password,
      client_id: window?.appConfig?.clien_id,
      grant_type: window?.appConfig?.login_grant_type,
      realm: 'Username-Password-Authentication',
      scope: 'openid profile email offline_access',
    });

    return data as LoginResponse;
  } catch (error) {
    throw get(error, 'response.data');
  }
};

const refresh = async () => {
  if (!localStorage.getItem('ts-refresh-token')) {
    return null;
  }

  try {
    const { data } = await auth0Api.post(
      '',
      {
        grant_type: 'refresh_token',
        refresh_token: localStorage.getItem('ts-refresh-token'),
        client_id: window?.appConfig?.clien_id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ts-api-token')}`,
        },
      }
    );

    return data as LoginResponse;
  } catch (error) {
    return get(error, 'response.data');
  }
};

export { auth, refresh };
