import { useEffect } from 'react';
import PropTypes from 'prop-types';

const useFilterModal = ({
  show
}) => {
  useEffect(() => {
    if (show) document.body.classList.add('filter-modal-active');

    return () => {
      document.body.classList.remove('filter-modal-active');
    };
  }, [show]);
};

useFilterModal.propTypes = {
  show: PropTypes.bool.isRequired
};

export default useFilterModal;
