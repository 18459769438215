import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { isEmpty, keys, upperFirst, lowerFirst } from 'lodash';
import Layout from '../../../components/layouts/default';
import Button from '../../../components/Button';
import Field from '../../../components/@v2/Form/Field';
import { useAPI } from '../../../context/api';

const AdminProductTranslation = () => {
  const { t } = useTranslation();
  const [translationsAPI] = useAPI('translations');
  const { id } = useParams();
  const history = useHistory();
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: 'en-US',
    label: t('admin.products.translation.english'),
  });
  const [translations, setTranslations] = useState([]);
  const [product, setProduct] = useState({
    title: '',
    description: '',
    specialOffer: '',
    languageCode: selectedLanguage,
  });

  const ProductTranslationSchema = Yup.object().shape({
    title: Yup.string().required(t('form-validator.required')),
    description: Yup.string().required(t('form-validator.required')),
    specialOffer: Yup.string().required(t('form-validator.required')),
    languageCode: Yup.object()
      .shape({
        value: Yup.string().required(t('form-validator.required')),
        label: Yup.string().required(t('form-validator.required')),
      })
      .required(t('form-validator.required')),
  });

  const options = useMemo(
    () => [
      {
        value: 'en-US',
        label: t('admin.products.translation.english'),
      },
      {
        value: 'da-DK',
        label: t('admin.products.translation.danish'),
      },
      {
        value: 'de-DE',
        label: t('admin.products.translation.german'),
      },
      {
        value: 'fi-FI',
        label: t('admin.products.translation.finnish'),
      },
      {
        value: 'nb-NO',
        label: t('admin.products.translation.norwegian'),
      },
      {
        value: 'sv-SE',
        label: t('admin.products.translation.swedish'),
      },
    ],
    []
  );

  const loadTranslationsForSelectedLangauge = useCallback(
    (selectedLanguageFromForm, setFieldValue) => {
      translationsAPI
        .search(
          `entityId==${id},entityType==product,languageCode==${
            selectedLanguageFromForm
              ? selectedLanguageFromForm?.value
              : selectedLanguage?.value
          }&page=1&pageSize=10&sorts=`
        )
        .then((productTranslations) => {
          let translatedProduct = {};
          translatedProduct = productTranslations.items.reduce(
            (previous, item) => ({
              ...previous,
              ...{ [lowerFirst(item.entityField)]: item.value },
            }),
            {}
          );
          setTranslations(productTranslations.items);
          if (setFieldValue) {
            setFieldValue('title', translatedProduct.title);
            setFieldValue('description', translatedProduct.description);
            setFieldValue('specialOffer', translatedProduct.specialOffer);
          } else {
            setProduct({
              ...translatedProduct,
              languageCode: selectedLanguageFromForm || selectedLanguage,
            });
          }
        });
    },
    [selectedLanguage]
  );

  useEffect(() => {
    loadTranslationsForSelectedLangauge();
  }, []);

  const onSubmit = async (values) => {
    const foundKeys = keys(values);
    const arrayOfCalls = foundKeys
      .filter((key) => key !== 'languageCode')
      .map((key) => {
        let apiCall = null;
        const keyStartCase = upperFirst(key);

        const foundObject = translations.find(
          (translation) => keyStartCase === translation.entityField
        );

        if (isEmpty(foundObject)) {
          apiCall = translationsAPI.create({
            entityId: id,
            entityType: 'Product',
            entityField: `${keyStartCase}`,
            languageCode: `${selectedLanguage.value}`,
            value: `${values[key]}`,
          });
        } else {
          apiCall = translationsAPI.update(
            { value: values[key] },
            foundObject.id
          );
        }
        return apiCall;
      });
    await Promise.all(arrayOfCalls);
    history.push('/admin/products');
  };

  return (
    <Layout
      pretitle={t('general.admin')}
      title={t('admin.products.translation.add-translation')}
      validationSchema={ProductTranslationSchema}
    >
      <Formik initialValues={product} enableReinitialize onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="anim-table-delayed max-w-4xl w-full shadow-sm p-4 bg-white rounded-lg"
            >
              <Field
                type="select"
                defaultOptions={options}
                name="languageCode"
                label={t('admin.products.translation.choose-language')}
                callback={(selected) => {
                  if (selected) {
                    setFieldValue('description', values?.description);
                    setSelectedLanguage(selected?.[0]);
                    loadTranslationsForSelectedLangauge(
                      selected?.[0],
                      setFieldValue
                    );
                  }
                }}
                required
              />
              <Field name="title" label={t('general.title')} required />
              <Field
                type="wysiwyg"
                name="description"
                label={t('general.description')}
                placeholder={t('admin.products.start-typing')}
                toolbar="base"
                readOnly={isSubmitting}
                required
              />
              <Field
                name="specialOffer"
                label={t('admin.products.special-offer')}
                required
              />
              <Button
                type="submit"
                color="primary"
                className="text-white"
                disabled={isSubmitting}
                loading={isSubmitting}
                data-testid="admin-products-translation-form-submit"
              >
                {t('general.submit')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default AdminProductTranslation;
