import React, { useState, useMemo } from 'react';
import { Card, CardBody, Table, Badge } from 'reactstrap';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import uuid from 'uuid';
import qs from 'query-string';
import styled from 'styled-components';
import Layout from '../../../components/layouts/default';
import { useAPI } from '../../../context/api';
import { truncateText } from '../../../helpers/utils';
import { format } from '../../../helpers/date';
import WithData from '../../../components/WithData';
import Placeholder from '../../../components/Placeholder';
import Icon from '../../../components/@v2/Icon';
import Loader from '../../../components/Loader';
import { selectClient } from '../../../store/selectors/client.selector';
import { useSelector as useSelectorToolkit } from '../../../store';
import { findOrder } from '../../../store/api/order.api';
import { getOrderStatus } from '../../../constants/Order';

const StyledLoader = styled.div`
  div:first-child {
    position: relative !important;
    left: 4px !important;
  }
`;

const OrderView = () => {
  const [ordersAPI] = useAPI('orders');
  const queryParams = useParams();
  const { id } = queryParams;
  const history = useHistory();
  const { t } = useTranslation();
  const { state } = useLocation();
  const [order, setOrder] = useState({ id });
  const [isGettingLogs, setIsGettingLogs] = useState(false);
  const [isGettingStatus, setIsGettingStatus] = useState(false);
  const currentClient = useSelectorToolkit(selectClient);

  const getLogs = async (orderId) => {
    setIsGettingLogs(true);
    const response = await ordersAPI.getLog(orderId);

    if (response) {
      setIsGettingLogs(false);
      history.push({
        search: qs.stringify({
          ...queryParams,
          modal: 'integration-logs',
        }),
        state: {
          ...state,
          orderLogs: response,
        },
      });
    }
  };

  const orderStatus = useMemo(() => getOrderStatus(order.status || 1), [order.status]);

  return (
    <Layout title={`${t('order.label')} #${id}`} pretitle={t('general.admin')}>
      <WithData
        api={id ? () => findOrder(id) : null}
        setItems={(response) => {
          setOrder(response);
        }}
      >
        {({ loading }) => (
          <Card className="anim-table-delayed order d-flex">
            <CardBody className="d-flex flex-column flex-grow-1 p-5">
              <div className="d-flex justify-content-center align-items-end flex-column">
                {!isGettingStatus && (
                  <Badge
                    id="retryOrderStatusInfo"
                    className={`ts-table-badge ${orderStatus === 'failed' && 'cursor-pointer'}`}
                    style={{
                      color: orderStatus === 'failed' ? '#e63757' : '#00d97e',
                      backgroundColor: orderStatus === 'failed' ? '#fad7dd' : '#ccf7e5',
                    }}
                    pill
                    onClick={async () => {
                      if (orderStatus === 'failed') {
                        setIsGettingStatus(true);
                        await ordersAPI.retry(order.orderId);
                        const response = await ordersAPI.find(id);
                        if (response) {
                          setIsGettingStatus(false);
                          setOrder(response);
                        }
                      }
                    }}
                  >
                    {t(`general.${orderStatus}`)} {orderStatus === 'failed' && `- ${t('general.click-to-retry')}`}
                  </Badge>
                )}
                {isGettingStatus && (
                  <StyledLoader>
                    <Loader
                      size={32}
                      color="black"
                      style={{
                        right: 0,
                      }}
                    />
                  </StyledLoader>
                )}
              </div>
              <div className="d-flex justify-content-center align-items-center flex-column" style={{ padding: '20px' }}>
                <h2>
                  {order.clientName ? (
                    `${t('order.from')} ${order.clientName}`
                  ) : (
                    <Placeholder height="24px" width="120px" />
                  )}
                </h2>
                <p className="text-muted">
                  {order.orderId ? (
                    `${t('order.label')}# ${order.orderId || ''}`
                  ) : (
                    <Placeholder height="20px" width="80px" />
                  )}
                </p>
              </div>
              <div className="d-flex flex-column">
                <h6 className="text-uppercase text-muted">{t('order.to')}</h6>
                <p className="mb-4 font-weight-bold">
                  {order.purchasedBy ? order.purchasedBy : <Placeholder height="24px" width="130px" />}
                </p>
                <h6 className="text-uppercase text-muted"> {t('order.purchase-date')} </h6>
                <p className="mb-4">
                  {order.dateOfPurchase ? (
                    format('date', order.dateOfPurchase)
                  ) : (
                    <Placeholder height="20px" width="90px" />
                  )}
                </p>
                <h6 className="text-uppercase text-muted">{t('order.reference')}</h6>
                <p className="mb-4">{order.referenceId || '-'}</p>
              </div>
              <Table responsive hover size="sm">
                <thead>
                  <tr>
                    {['name', 'media', 'position', 'price'].map((item) => (
                      <th
                        key={item}
                        className={classnames({
                          'text-right': item === 'price',
                        })}
                      >
                        {t(`order.${item}`)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {order.products &&
                    order.products.length &&
                    order.products.map((item) => (
                      <tr key={uuid.v4()}>
                        <td>
                          <div
                            className="flex justify-content-start align-items-center"
                            style={{ position: 'relative' }}
                          >
                            {truncateText(item.productTitle, 10)}
                            {(orderStatus === 'failed' || orderStatus === 'success') && !isGettingLogs && (
                              <Icon
                                name="question-circle"
                                size="1x"
                                type="fas"
                                className="clickable"
                                color="var(--primary-color)"
                                style={{ marginLeft: 2 }}
                                onClick={() => {
                                  getLogs({
                                    orderId: id,
                                    orderItemId: item.orderItemId,
                                  });
                                }}
                              />
                            )}
                            {isGettingLogs && (
                              <StyledLoader>
                                <Loader size={24} color="black" />
                              </StyledLoader>
                            )}
                          </div>
                        </td>
                        <td>{item.media || '-'}</td>
                        <td
                          className={classnames({ clickable: item.position })}
                          onClick={() => {
                            if (item.position && order.clientName === currentClient.name) {
                              history.push({
                                pathname: `/positions/edit/${item.positionId}`,
                                state: {
                                  section: 'VisiblePosition',
                                  fromOrder: 1,
                                },
                              });
                            } else {
                              NotificationManager.error(
                                t('general.currently-on-another-client', {
                                  x: order.clientName,
                                })
                              );
                            }
                          }}
                        >
                          {item.position || '-'}
                        </td>
                        <td className="text-right">{item.price}</td>
                      </tr>
                    ))}
                  {loading &&
                    [1, 2, 3, 4, 5].map(() => (
                      <tr key={uuid.v4()}>
                        {[1, 2, 3].map((item) => (
                          <td key={item}>
                            <Placeholder height="20px" />
                          </td>
                        ))}
                        <td className="text-right">
                          <div className="d-flex align-items-center justify-content-end">
                            <Placeholder height="20px" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  <tr className="border-top border-top-2">
                    <td className="font-weight-bold">{t('order.total')}</td>
                    <td colSpan="3" className="font-weight-bold text-right">
                      {!loading ? (
                        order.total
                      ) : (
                        <div className="d-flex align-items-center justify-content-end">
                          <Placeholder height="20px" width="70px" />
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        )}
      </WithData>
    </Layout>
  );
};

export default OrderView;
