import React from 'react';
import RCDropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import 'rc-dropdown/assets/index.css';
import './Dropdown.sass';
import Icon from '../Icon';

const DropdownMenu = ({
  onSelect,
  menuItems,
  placement,
  children,
  buttonProps,
  ...rest
}) => {
  const menu = (
    <Menu onClick={({ key }) => onSelect(key)}>
      {menuItems.map(({ value, label, icon }) => (
        <MenuItem key={value} id={`pptr--menu-${value}`}>
          <div className="flex items-center pr-3">
            {icon && <Icon name={icon} className="mr-2" size={14} />}
            <span>{label}</span>
          </div>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <RCDropdown
      trigger={['click']}
      overlay={menu}
      placement={placement}
      overlayClassName="ts-dropdown-overlay"
      {...rest}
    >
      <button type="button" {...buttonProps}>
        {children}
      </button>
    </RCDropdown>
  );
};

DropdownMenu.defaultProps = {
  placement: 'bottomLeft',
};

export default DropdownMenu;
