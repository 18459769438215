import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KEY_FIGURE_TYPE_OPTIONS } from '../../../../../constants/CareerPage';
import { addSortOrder } from '../../../../../helpers/utils';
import Icon from '../../../Icon';
import Field from '../../../Form/Field';

const KeyFigureItem = (props) => {
  const { t } = useTranslation();
  const { item, dragHandleProps, commonProps } = props;
  const { value1, value2, description } = item;
  const index = item?.index;
  const [isEditing, setIsEditing] = useState(!!item?.isEditing);
  const KEY_FIGURE_OPTIONS = KEY_FIGURE_TYPE_OPTIONS(t);

  useEffect(() => {
    setIsEditing(item?.isEditing);
  }, [item?.isEditing]);

  return (
    <div
      className="flex flex-col gap-y-4 bg-white rounded-sm"
      data-testid="key-figure-item"
    >
      <div className="flex items-center justify-between !py-2 !px-4">
        <div className="flex items-center flex-grow gap-x-2 truncate">
          <button type="button" {...dragHandleProps}>
            <Icon name="grip-vertical" fontSize={12} />
          </button>
          <div className="text-xs">
            <p className="mb-0">
              {value1}
              {!!value2 && `/${value2}`}
            </p>
            <p className="mb-0 max-w-item-description truncate ...">
              {description}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <button
            type="button"
            onClick={() => {
              setIsEditing(!isEditing);
            }}
          >
            <Icon
              name="edit"
              color="var(--text-color-secondary)"
              fontSize={12}
            />
          </button>
          <button
            type="button"
            onClick={() => {
              commonProps.setFieldValue(
                'keyFigures',
                addSortOrder(
                  commonProps.keyFigures.filter(
                    (keyFigure) => keyFigure.sortOrder !== item.sortOrder
                  )
                ).map((keyFigure, index) => ({
                  ...keyFigure,
                  index,
                }))
              );
            }}
          >
            <Icon
              name="trash"
              color="var(--text-color-secondary)"
              fontSize={12}
            />
          </button>
        </div>
      </div>
      {isEditing && (
        <div className="!px-4">
          <Field
            type="select"
            defaultOptions={KEY_FIGURE_OPTIONS}
            name={`keyFigures[${index}].keyFigureType`}
            label={t('general.type')}
          />
          <Field
            type="number"
            name={`keyFigures[${index}].value1`}
            label={`${t('general.value')} 1`}
          />
          {item?.keyFigureType?.value === 2 && (
            <Field
              type="number"
              name={`keyFigures[${index}].value2`}
              label={`${t('general.value')} 2`}
            />
          )}
          <Field
            name={`keyFigures[${index}].description`}
            label={t('general.description')}
          />
        </div>
      )}
    </div>
  );
};

export default KeyFigureItem;
