import Axios from 'axios';
import CreateUserCommand from '@ts/apicontracts/users/create-user-command';
import UpdateUserCommand from '@ts/apicontracts/users/update-user-command';
import UserDetailsResponse from '@ts/apicontracts/users/details/response';
import ActiveUserDetailsResponse from '@ts/apicontracts/users/active/response';
import ReferencesResponse from '@ts/apicontracts/users/references/response';
import Filter from '@ts/apicontracts/users/list/filters';
import ResponseListItem from '@ts/apicontracts/users/list/response-list-item';

import { AxiosResponseDirect, api } from '../../helpers/api-v2';
import { getBrowser } from '../../helpers/utils';

const resource = '/users';

export const updatePassword = async (payload: string) => {
  try {
    const data = await api.put(`${resource}/password`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const uploadPicture = async (userId: string, form: typeof FormData, options = {}) => {
  try {
    const data = await api.post(`${resource}/${userId}/picture`, form, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary',
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const deletePicture = async (userId: string) => {
  try {
    const data = await api.delete(`${resource}/${userId}/picture`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const checkEmail = async (email: string, options = {}) => {
  try {
    const data = await api.post(`${resource}/exists`, { email }, options);
    return data;
  } catch (error) {
    throw error;
  }
};

export const addAdminUser = async (form: CreateUserCommand, options = {}) => {
  try {
    const data = await api.post(`${resource}/login`, form, options);
    return data;
  } catch (error) {
    throw error;
  }
};

export const sendWelcomeEmail = async (id: string, options = {}) => {
  try {
    const data = await api.post(`${resource}/login/${id}/send-welcome-email`, options);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateAdminUser = async (form: UpdateUserCommand & { id: string }, options = {}) => {
  try {
    const data = await api.put(`${resource}/login`, form, options);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAdminUser = async (id: number, options = {}) => {
  try {
    const response = await api.get(`${resource}/login/${id}`, options);
    return response as unknown as AxiosResponseDirect<UserDetailsResponse>;
  } catch (error) {
    throw error;
  }
};

export const getUserByUserId = async (id: number, options = {}) => {
  try {
    const response = await api.get(`${resource}/${id}`, options);
    return response as unknown as AxiosResponseDirect<UserDetailsResponse>;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (form: CreateUserCommand, options = {}) => {
  try {
    const response = await api.post(resource, form, options);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (form: UpdateUserCommand, options = {}) => {
  try {
    const response = await api.put(`${resource}`, form, options);
    return response;
  } catch (error) {
    throw error;
  }
};

// TODO missing in apicontracts
export const updateUserProfile = async (payload) => {
  try {
    const requests = [
      api.put(`${resource}`, {
        ...payload,
        language: payload?.language?.value,
      }),
    ];

    const response = await Axios.all(requests);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUser = async () => {
  try {
    const data = await api.get(`${resource}/active`);
    return data as unknown as AxiosResponseDirect<ActiveUserDetailsResponse>;
  } catch (error) {
    throw error;
  }
};

export const setUser = async ({ clientId }: { clientId: number }) => {
  try {
    const data = await api.post(`${resource}/active`, {
      clientId,
      browser: getBrowser(),
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserListSettings = async (queryForm: any, options = {}) => {
  try {
    const data = await api.post(`${resource}/list`, queryForm, options);
    return data as unknown as AxiosResponseDirect<ResponseListItem[]>;
  } catch (error) {
    throw error;
  }
};

export const getUserReferences = async (id: number, options = {}) => {
  try {
    const response = await api.get(`${resource}/${id}/references`, options);
    return response as unknown as AxiosResponseDirect<ReferencesResponse>;
  } catch (error) {
    throw error;
  }
};

export const getAdminUserList = async (queryForm: Filter = { name: '', deleted: false }, options = {}) => {
  try {
    const data = await api.post(`${resource}/login/list`, queryForm, options);
    return data as unknown as AxiosResponseDirect<ResponseListItem[]>;
  } catch (error) {
    throw error;
  }
};

// TODO: missing in apicontracts
export const getClientAccessDetails = async () => {
  const details = await api.get(`${resource}/active/assignments`);
  return details;
};
