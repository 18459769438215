import OrderItem from '@ts/apicontracts/orders/order-item';
import { AxiosResponseDirect, api } from '../../helpers/api-v2';

const resource = '/orders';

export const findOrder = async (id: string) => {
  const response = await api.get(`${resource}/${id}`);

  return response;
};

export const getOrdersList = async (params = {}, options = {}) => {
  const response = await api.post(`${resource}/list`, params, options);

  return response as unknown as AxiosResponseDirect<OrderItem[]>;
};

export const retryOrder = async (id: string) => {
  const response = await api.post(`${resource}/${id}/reprocess`);
  return response;
};
