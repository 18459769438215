import React, { useState } from 'react';
import InputRange from 'react-input-range';
import '../../assets/sass/range-slider.sass';
import 'react-input-range/lib/css/index.css';
import classnames from 'classnames';

const RangeSlider = ({
  value,
  onChange,
  min,
  max,
  subText,
  className,
  ...rest
}) => {
  const [localValue, setLocalValue] = useState(parseInt(min, 10));

  return (
    <div id={rest.id} {...rest}>
      <div
        className={classnames(
          'range-slider',
          {
            'has-sub-text': !!subText,
          },
          className
        )}
        data-testid="range-slider-component"
      >
        <InputRange
          formatLabel={(rangeValue) => `${rangeValue} ${subText || ''}`}
          maxValue={parseInt(max, 10)}
          minValue={parseInt(min, 10)}
          value={value || localValue}
          onChange={
            onChange ||
            ((selected) => {
              setLocalValue(selected);
            })
          }
          disabled={parseInt(min, 10) > parseInt(max, 10)}
        />
      </div>
    </div>
  );
};

RangeSlider.defaultProps = {
  min: 0,
  max: 10,
  subText: '',
  value: null,
  onChange: null,
};

export default RangeSlider;
