import React, { useState } from 'react';
import classnames from 'classnames';
import Icon from './@v2/Icon';

const StarRating = ({ rate, onClick, clickable, style, ...rest }) => {
  const [hoverNumber, setHoverNumber] = useState(0);

  const getType = (number, rate) => {
    if (hoverNumber > 0) {
      if (hoverNumber >= number) return 'fas';
    } else if (number <= rate) {
      return 'fas';
    }

    return 'fal';
  };

  return (
    <div
      {...rest}
      className={classnames('d-flex align-items-center star-rating', rest.className)}
      style={style}
      data-testid="star-rating-container"
    >
      {[1, 2, 3, 4, 5].map((item) => (
        <button
          type="button"
          onClick={() => {
            if (clickable) onClick(item);
          }}
          onMouseEnter={() => {
            if (!clickable) return;
            if (hoverNumber !== item) setHoverNumber(item);
          }}
          onMouseLeave={() => {
            if (!clickable) return;
            setHoverNumber(0);
          }}
          data-testid={`star-${item}`}
          data-star-selected={item === rate}
        >
          <Icon
            key={item}
            name="star"
            color={getType(item, rate) === 'fal' ? 'var(--gray-badge)' : 'var(--warning-color)'}
            type={getType(item, rate)}
            data-star-color={getType(item, rate) === 'fal' ? 'white' : 'yellow'}
            className={classnames(getType(item, rate) === 'fal' ? 'fill-medium-gray' : 'fill-warning')}
            title="star"
          />
        </button>
      ))}
    </div>
  );
};

StarRating.defaultProps = {
  rate: 0,
  onClick: () => {},
  clickable: false,
  style: {},
};

export default StarRating;
